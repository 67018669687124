import { useMemo } from 'react';
import { useMarketplacePaymentsTableColumnsConfig } from 'src/tables/MarketplacePaymentsTable';
import { getEventsColumnConfigById } from 'src/utils/columns/columnUtils';
import { MarketplacePaymentsTableColumnId } from 'src/utils/columns/marketplacePayments/marketplacePaymentsColumnsUtils.types';

export const useDefaultPaymentsColumnConfig = () => {
  const paymentColumnConfig = useMarketplacePaymentsTableColumnsConfig();
  return useMemo(
    () =>
      Object.values(paymentColumnConfig)
        .filter((item) => {
          const columnConfig = getEventsColumnConfigById(
            item.id as MarketplacePaymentsTableColumnId
          );
          return (
            !columnConfig.personalization.isHiddenByDefault &&
            (columnConfig.personalization.isConfigurable ||
              columnConfig.personalization.isRequired)
          );
        })
        .map((column) => column.id as MarketplacePaymentsTableColumnId),
    [paymentColumnConfig]
  );
};
