import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { coalesceUndefinedOnly } from 'src/utils/miscUtils';
import {
  AutoPricingInputs,
  AutoPricingOutlierMode,
} from 'src/WebApiController';

export interface OnOutlierSettingsChangeArgs {
  outlierModeNew: AutoPricingOutlierMode | null;
  outlierKthLowestLimitNew?: number | null;
  outlierKthLowestLimitRelativeSpacingNew?: number | null;
  outlierKthLowestLimitAbsoluteSpacingNew?: number | null;
  outlierStandardDeviationsNew?: number | null;
}

export const useAutoPricingSettingsFormContext = () => {
  const methods = useFormContext<AutoPricingInputs>();
  const { setValue, watch } = methods;

  const {
    outlierMode,
    outlierKthLowestLimit,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierStandardDeviations,
  } = watch();

  const onOutlierSettingsChange = useCallback(
    (input: OnOutlierSettingsChangeArgs) => {
      const {
        outlierModeNew,
        outlierKthLowestLimitNew,
        outlierKthLowestLimitRelativeSpacingNew,
        outlierKthLowestLimitAbsoluteSpacingNew,
        outlierStandardDeviationsNew,
      } = input;

      // Covers both null and undefined cases
      if (outlierModeNew !== undefined) {
        setValue(
          'outlierMode',
          coalesceUndefinedOnly(outlierModeNew, outlierMode) ?? null
        );

        switch (outlierModeNew) {
          case AutoPricingOutlierMode.StandardDeviations:
            setValue(
              'outlierStandardDeviations',
              coalesceUndefinedOnly(
                outlierStandardDeviationsNew,
                outlierStandardDeviations
              ) ?? null
            );
            setValue('outlierKthLowestLimit', null);
            setValue('outlierKthLowestLimitRelativeSpacing', null);
            setValue('outlierKthLowestLimitAbsoluteSpacing', null);
            break;
          case AutoPricingOutlierMode.KthLowest:
            setValue('outlierStandardDeviations', null);
            setValue(
              'outlierKthLowestLimit',
              coalesceUndefinedOnly(
                outlierKthLowestLimitNew,
                outlierKthLowestLimit
              ) ?? null
            );

            setValue(
              'outlierKthLowestLimitRelativeSpacing',
              coalesceUndefinedOnly(
                outlierKthLowestLimitRelativeSpacingNew,
                outlierKthLowestLimitRelativeSpacing
              ) ?? null
            );

            setValue(
              'outlierKthLowestLimitAbsoluteSpacing',
              coalesceUndefinedOnly(
                outlierKthLowestLimitAbsoluteSpacingNew,
                outlierKthLowestLimitAbsoluteSpacing
              ) ?? null
            );
            break;
          case AutoPricingOutlierMode.SkipLogic:
            setValue('outlierStandardDeviations', null);
            setValue('outlierKthLowestLimit', null);
            setValue('outlierKthLowestLimitRelativeSpacing', null);
            setValue('outlierKthLowestLimitAbsoluteSpacing', null);
        }
      } else {
        setValue('outlierMode', null);
        setValue('outlierStandardDeviations', null);
        setValue('outlierKthLowestLimit', null);
        setValue('outlierKthLowestLimitRelativeSpacing', null);
        setValue('outlierKthLowestLimitAbsoluteSpacing', null);
      }
    },
    [
      setValue,
      outlierMode,
      outlierKthLowestLimit,
      outlierKthLowestLimitRelativeSpacing,
      outlierKthLowestLimitAbsoluteSpacing,
      outlierStandardDeviations,
    ]
  );

  return {
    ...methods,
    onOutlierSettingsChange,
  };
};
