/* IMPORTANT - whenever you add a new one here
   You need to add a handling for it in the BulkEditStatusPurchasePopover.tsx
  */
export const PURCHASE_BULK_UPDATE_GENERAL_SETTINGS_UPDATE_KEY =
  'general-settings-event-purchases';
export const PURCHASE_BULK_UPDATE_COMMISSION_UPDATE_KEY =
  'commission-event-purchases';
export const PURCHASE_BULK_UPDATE_TAGS_UPDATE_KEY = 'tags-event-purchases';
export const PURCHASE_BULK_UPDATE_INTERNAL_NOTES_UPDATE_KEY =
  'update-internal-notes-purchases';
export const PURCHASE_BULK_UPLOAD_PURCHASE_KEY = `upload-csv-puchases`;
export const PURCHASE_BULK_CANCEL_UPDATE_KEY = `cancel-puchases`;
export const PURCHASE_BULK_UPDATE_DELIVERY_TYPE_UPDATE_KEY =
  'update-delivery-type-purchases';
export const PURCHASE_BULK_UPDATE_SEAT_TRAITS_UPDATE_KEY =
  'update-seat-traits-purchases';
export const TICKET_GROUP_BULK_DELETE_UPDATE_KEY = `delete-ticket-groups`;
export const PURCHASE_BULK_BACKFILL_DELIVERY_SETTINGS_KEY = `backfill-delivery-settings-purchases`;
export const PURCHASE_BULK_CONVERT_PAYMENTS_KEY = 'convert-payments-purchases';

export const PURCHASE_BULK_MARK_AS_PAID_KEY = 'bulk-mark-as-paid-purchases';
export const PURCHASE_BULK_UPDATE_PURCHASE_CURRENCY_CODE_KEY =
  'bulk-update-purchase-currency-code';

// There's a very similar contentId: TicketGroupBulkEditPurchaseWarning
// and this one is not worthy to be a content
// This is a temporary message that lives with BulkUpdateIndividualTicketGroup feature flag
// for a temporary fix. Can be removed once BulkUpdateIndividualTicketGroup is GA
export const TicketGroupBulkEditPurchaseWarningTemporaryMessage =
  'Performing a bulk action on the selected purchase lines will affect the entire purchase order.';
