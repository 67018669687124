import { useMutation, UseQueryResult } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  EventMapContextProvider,
  useEventMapContext,
} from 'src/contexts/EventMapContext';
import { useGetVenueZoneMapConfigInfo } from 'src/contexts/EventMapContext/useGetVenueZoneMapConfigInfo';
import { useGetVenueZoneMapInfoOverrides } from 'src/contexts/EventMapContext/useGetVenueZoneMapConfigInfoOverrides';
import { useVenueZoneMapIntegration } from 'src/contexts/EventMapContext/useVenueZoneMapIntegration';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import {
  AutoPricingInputs,
  Event,
  EventConfigScoreOverride,
  PricingClient,
  SectionSectionGroup,
  VenueMapZoneConfigInfo,
  VenueZoneConfigOverride,
  VenueZoneConfigType,
} from 'src/WebApiController';

import { createGetColor } from '../utils/colorUtils';
import { EventVenueMapContent } from './components/EventVenueMapContent';
import { EventVenueMapDetailsPanel } from './components/EventVenueMapDetailsPanel';
import * as styles from './EventVenueMapModal.css';

export type EventVenueMapFormType = {
  sectionSectionGroupLookup: { [key: string]: SectionSectionGroup };
  customSectionGroups: SectionSectionGroup[];
  eventScoreOverride?: EventConfigScoreOverride | null;
  customRank: boolean;
  setMapAsDefault: boolean;
  name: string;
};

export const EventVenueMapModal = ({ event }: { event: Event }) => {
  const { mapConfigOverridesQuery, isMapLoading } = useEventMapContext();

  const { pricingSettingsQuery } = useGetEventAutoPricingSettings(
    event!,
    false
  );

  const venueZoneMapConfigInfoQuery = useGetVenueZoneMapConfigInfo(
    event!.viagId,
    event!.venueCfgId
  );

  const venueZoneMapConfigOverrideInfoQuery = useGetVenueZoneMapInfoOverrides(
    event!.viagId,
    event!.venueCfgId
  );

  const methods = useForm<EventVenueMapFormType>({
    defaultValues: {
      sectionSectionGroupLookup: {},
      customSectionGroups: [],
      eventScoreOverride: null,
      customRank: false,
      setMapAsDefault: false,
      name: undefined,
    },
  });

  const venueZoneConfigs = useVenueZoneMapIntegration(event ?? null);

  useEffect(() => {
    if (venueZoneConfigs?.sectionSectionGroupLookup) {
      methods.reset({
        sectionSectionGroupLookup: venueZoneConfigs.sectionSectionGroupLookup,
        customSectionGroups: [],
        eventScoreOverride: null,
        customRank: false,
        setMapAsDefault: false,
        name: venueZoneConfigs?.name,
      });
    }
  }, [methods, methods.reset, venueZoneConfigs]);

  if (
    mapConfigOverridesQuery.isLoading ||
    isMapLoading ||
    venueZoneMapConfigInfoQuery.isLoading ||
    venueZoneMapConfigOverrideInfoQuery.isLoading ||
    pricingSettingsQuery.isLoading
  ) {
    return <PosSpinner />;
  }

  return (
    <EventMapContextProvider event={event}>
      <FormProvider {...methods}>
        <EventVenueMapBody
          event={event}
          pricingSettingsQuery={pricingSettingsQuery}
          venueZoneMapConfigInfoQuery={venueZoneMapConfigInfoQuery}
          venueZoneMapConfigOverrideInfoQuery={
            venueZoneMapConfigOverrideInfoQuery
          }
        />
      </FormProvider>
    </EventMapContextProvider>
  );
};

export const EventVenueMapBody = ({
  event,
  pricingSettingsQuery,
  venueZoneMapConfigInfoQuery,
  venueZoneMapConfigOverrideInfoQuery,
}: {
  event: Event;
  pricingSettingsQuery: UseQueryResult<AutoPricingInputs | null, Error>;
  venueZoneMapConfigInfoQuery: UseQueryResult<
    VenueMapZoneConfigInfo | null,
    Error
  >;
  venueZoneMapConfigOverrideInfoQuery: UseQueryResult<
    {
      [key: string]: VenueZoneConfigOverride;
    } | null,
    Error
  >;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const [selectedConfigType, setSelectedConfigType] =
    useState<VenueZoneConfigType | null>(
      pricingSettingsQuery.data?.venueZoneConfigType ??
        venueZoneMapConfigInfoQuery?.data?.defaultVenueZoneConfigType ??
        null
    );

  const [selectedOverrideId, setSelectedOverrideId] = useState<number | null>(
    pricingSettingsQuery.data?.venueZoneOverrideId ?? null
  );
  const setVenueZoneConfigTypeOverrideMutation = useMutation({
    mutationFn: (request: {
      eventId?: number | null;
      venueZoneOverrideId?: number | null;
      venueZoneConfigType?: VenueZoneConfigType | null;
      setMapAsDefault?: boolean | null;
    }) => {
      if (request.eventId) {
        return new PricingClient(
          activeAccountWebClientConfig
        ).setVenueZoneSelectedConfigType(
          request.eventId,
          request.venueZoneOverrideId,
          request.venueZoneConfigType,
          request.setMapAsDefault
        );
      }

      return Promise.resolve();
    },
    onError: (err: ErrorTypes, request) => {
      showErrorDialog('PricingClient.setVenueZoneSelectedConfigType', err, {
        trackErrorData: request,
      });
    },
    onSettled: () => {
      pricingSettingsQuery.refetch();
    },
  });

  if (!event) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.mapPanel}>
        <EventVenueMapContent createGetColor={createGetColor} />
      </div>
      <div className={styles.settingsPanel}>
        <EventVenueMapDetailsPanel
          event={event}
          selectedOverrideId={selectedOverrideId}
          setSelectedOverrideId={setSelectedOverrideId}
          selectedConfigType={selectedConfigType}
          setSelectedConfigType={setSelectedConfigType}
          createGetColor={createGetColor}
          pricingSettingsQuery={pricingSettingsQuery}
          venueZoneMapConfigInfoQuery={venueZoneMapConfigInfoQuery}
          venueZoneMapConfigOverrideInfoQuery={
            venueZoneMapConfigOverrideInfoQuery
          }
          setVenueZoneConfigTypeOverrideMutation={
            setVenueZoneConfigTypeOverrideMutation
          }
        />
      </div>
    </div>
  );
};
