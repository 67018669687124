import { ReactNode, useContext, useEffect, useRef } from 'react';
import { useGetMarketplacePaymentIdQueryParam } from 'src/components/MarketplacePayments/hooks/useGetMarketplacePaymentIdQueryParam';
import { ModalContext } from 'src/contexts/ModalContext';
import { getMarketplacePaymentDetailsModalConfigWithDeepLink } from 'src/utils/marketplacePaymentUtils';

interface ModalRefProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

interface MarketplacePaymentDeeplinkListenerProps {
  children: ReactNode;
}

export const MarketplacePaymentDeeplinkListener = ({
  children,
}: MarketplacePaymentDeeplinkListenerProps) => {
  const { setModal, closeModal, isModalOpen } = useContext(ModalContext);
  const marketplacePaymentId = useGetMarketplacePaymentIdQueryParam();
  const modalRefProps: ModalRefProps = {
    isModalOpen,
    closeModal,
  };

  const ref = useRef<ModalRefProps>(modalRefProps);
  ref.current = modalRefProps;

  useEffect(() => {
    if (marketplacePaymentId && !isModalOpen) {
      setModal(
        getMarketplacePaymentDetailsModalConfigWithDeepLink(
          marketplacePaymentId
        )
      );
    }
  }, [closeModal, isModalOpen, marketplacePaymentId, setModal]);

  // Close modal on unmount
  useEffect(() => {
    return () => {
      if (ref.current?.isModalOpen) {
        ref.current?.closeModal();
      }
    };
  }, []);

  return <>{children}</>;
};
