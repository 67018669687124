import { ComponentProps, useCallback, useState } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { AddAdminHoldDialog } from 'src/dialogs/AddAdminHoldDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { SplitListingModal } from 'src/modals/SplitListing';
import { AdminHoldIcon } from 'src/svgs/AdminHoldIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { SQL_MAX_DATEITME } from 'src/utils/dateTimeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingMetrics,
} from 'src/WebApiController';

export const LaunchUpdateAdminHold = ({
  listingId,
  isAddHold,
  variant,
  iconOnlyMode,
  ...buttonProps
}: {
  listingId: number;
  isAddHold?: boolean;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
} & ComponentProps<typeof SplitListingModal>) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext<ListingMetrics>();
  const { showErrorDialog } = useErrorBoundaryContext();

  const { posEntity: listing, setActivePosEntity } =
    useActivePosEntityContext<ListingDetails>();

  const adminHoldDialog = useBasicDialog();
  const [isLoading, setIsLoading] = useState(false);

  const onAddHold = useCallback(
    async (
      adminHoldExpirationDate: Date | null,
      adminHoldNotes: string | null
    ) => {
      setIsLoading(true);
      return await tryInvokeApi(
        async () => {
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).updateListingAdminHold(
            listingId,
            (adminHoldExpirationDate ?? SQL_MAX_DATEITME).toISOString(),
            adminHoldNotes
          );
          if (result) {
            refreshExpandedListItems();
            refreshMetrics?.();
            if (listing) {
              await setActivePosEntity(listing.id, listing.idOnMkp, true, [
                ListingDetailDataField.Basic,
              ]);
            }
          }
        },
        (error) => {
          showErrorDialog('ListingClient.updateListingAdminHold', error);
        },
        () => {
          setIsLoading(false);
          adminHoldDialog.closeDialog();
        }
      );
    },
    [
      activeAccountWebClientConfig,
      adminHoldDialog,
      listing,
      listingId,
      refreshExpandedListItems,
      refreshMetrics,
      setActivePosEntity,
      showErrorDialog,
    ]
  );

  const onRemoveHold = useCallback(async () => {
    return await tryInvokeApi(
      async () => {
        const result = await new ListingClient(
          activeAccountWebClientConfig
        ).updateListingAdminHold(listingId, null, null);
        if (result) {
          refreshExpandedListItems();
          refreshMetrics?.();
          if (listing) {
            await setActivePosEntity(listing.id, listing.idOnMkp, true, [
              ListingDetailDataField.Basic,
            ]);
          }
        }
      },
      (error) => {
        showErrorDialog('ListingClient.updateListingAdminHold', error);
      }
    );
  }, [
    activeAccountWebClientConfig,
    listing,
    listingId,
    refreshExpandedListItems,
    refreshMetrics,
    setActivePosEntity,
    showErrorDialog,
  ]);

  const onClickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (isAddHold) {
        e.preventDefault();
        e.stopPropagation();
        adminHoldDialog.launchDialog();
      } else {
        onRemoveHold();
      }
    },
    [adminHoldDialog, isAddHold, onRemoveHold]
  );

  return (
    <>
      <ButtonWithIcon
        {...buttonProps}
        variant={variant}
        iconOnlyMode={iconOnlyMode}
        onClick={onClickHandler}
        textContentId={isAddHold ? ContentId.PutOnHold : ContentId.RemoveHold}
        icon={
          <AdminHoldIcon
            withHoverEffect
            fill={
              iconOnlyMode || (variant && variant !== 'regular')
                ? vars.color.textBrand
                : vars.color.textInverted
            }
            size={vars.iconSize.m}
          />
        }
      />
      <AddAdminHoldDialog
        {...adminHoldDialog.dialogProps}
        onCancel={adminHoldDialog.closeDialog}
        onOkay={onAddHold}
        isLoading={isLoading}
      />
    </>
  );
};
