import { parseInt } from 'lodash-es';
import { Marketplace } from 'src/WebApiController';

export const STUBHUB_LOGO_IMAGE =
  'https://img.vggcdn.net/images/Assets/Icons/bfx/stubhub-logo-merch-purple-mweb.440b3765.svg';

// Regex for URL validation, basically any printable ASCII characters followed by a dot and then more printable ASCII characters
// Intentionally not using a more complex regex to avoid false negatives
// This is enough to filter out barcodes and other non-URL strings
export const URL_REGEX = /^([ -~])+\.([ -~])+$/;

export const SaleDeeplinkQueryParam = 'uniqueSaleId';
export const InventoryDeeplinkQueryParam = 'uniqueInventoryId';
export const PurchaseDeeplinkQueryParam = 'uniquePurchaseId';
export const AdCampaignQueryParam = 'adCampaignId';
export const AdGroupListingsQueryParam = 'adGroupId';
export const PurchaseFromEmailPoQueryParam = 'fromEmailPo';
export const EventIdQueryParam = 'eventId';
export const VendorIdQueryParam = 'vendorId';
export const MarketplacePaymentIdDeeplinkQueryParam =
  'uniqueMarketplacePaymentId';

export const EventSessionIdQueryParam = 'sessionId';
export const EventSessionRoleQueryParam = 'sessionRole';

export const TICKETMASTER_APP_ID = 2;
export const EMPTY_SELLER_USER_ID = '00000000-0000-0000-0000-000000000000';

export const FULFILLMENT_PROVIDER_SELLER_USER_IDS = new Set(['']); // TODO: populate this after fulfillment providers are created

export const REACT_QUERY_PERSIST_TO_STORAGE_INTERVAL_IN_MS = 30000; // 30 sec
export const REACT_QUERY_CACHE_MAX_AGE_IN_MS = 1000 * 60 * 60 * 24; // 1 day

// http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.1
export const MAX_DATE = new Date(8640000000000000);
export const MIN_DATE = new Date(-8640000000000000);

export const DATA_REFRESH_RATE_IN_MILLIS_FAST = 5000; // 5 sec
export const DATA_REFRESH_RATE_IN_MILLIS_SHORT = 2 * 60 * 1000; // 2 min;
export const DATA_REFRESH_RATE_IN_MILLIS_LONG = 5 * 60 * 5000; // 5 min;

export const UK_COUNTRY_CODES = ['GB', 'GBR']; // the 2 and 3 letter codes for Great Britain
export const US_COUNTRY_CODES = ['US', 'USA', 'CA', 'PR'];

export const SuspiciousPriceChangeThreshold = 25;

export const MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS =
  parseInt(process.env.MAX_NUM_OF_ITEMS_IN_TABLES ?? '') || 10000; // This seems to be a good thredshold where bulk-edit and other operations would still perform
export const MAX_NUM_OF_ITEMS_FOR_REPORT_VIEWS =
  parseInt(process.env.MAX_NUM_OF_ITEMS_IN_REPORTS ?? '') || 10000;

export const VenueMapConfigPlaceholder = 'VENUE_MAP_CONFIG';
export const VenueMapVersionPlaceholder = 'VENUE_MAP_VERSION';
export const VenueMapUrl = `https://img.vggcdn.net/svgv2/${VenueMapConfigPlaceholder}.svg?v=${VenueMapVersionPlaceholder}`;
export const VenueMapV3Url = `https://img.vggcdn.net/svgv3/${VenueMapConfigPlaceholder}.svg?v=${VenueMapVersionPlaceholder}`;

// process.env.**constants** go here
export const REACT_APP_STUBHUB_BASE_URL =
  process.env.REACT_APP_STUBHUB_BASE_URL;
export const REACT_APP_VIAGOGO_BASE_URL =
  process.env.REACT_APP_VIAGOGO_BASE_URL;
export const REACT_APP_CLOUDINARY_BASE_ADDRESS =
  process.env.REACT_APP_CLOUDINARY_BASE_ADDRESS;
export const REACT_APP_ASSETS_URL = process.env.REACT_APP_ASSETS_URL;
export const REACT_APP_APP_INSIGHTS = process.env.REACT_APP_APP_INSIGHTS;
export const REACT_APP_NAME =
  process.env.REACT_APP_NAME ?? 'StubHub.PointOfSale.Site';
export const REACT_APP_STUBHUB_MY_HUB_BASE_URL =
  process.env.REACT_APP_STUBHUB_MY_HUB_BASE_URL;
export const REACT_APP_STUBHUB_UK_URL = process.env.REACT_APP_STUBHUB_UK_URL;
export const REACT_APP_MESSAGES_BRIDGE_URL =
  process.env.REACT_APP_MESSAGES_BRIDGE_URL;
export const REACT_APP_REPORTS_BRIDGE_URL =
  process.env.REACT_APP_REPORTS_BRIDGE_URL;

export const DEFAULT_AUTO_GENERATED_PAYMENT_METHOD =
  'Unknown_Payment_Auto_Generated';

// Marketplace Event URL
export const MarketplaceEventIdPlaceholder = 'MKP_EVENT_ID';
export const MarketplaceEventUrlTemplate = {
  [Marketplace.Ticketmaster]: `https://www.ticketmaster.com/event/${MarketplaceEventIdPlaceholder}`,
  [Marketplace.VividSeats]: `https://www.vividseats.com/production/${MarketplaceEventIdPlaceholder}`,
  [Marketplace.SeatGeek]: `https://seatgeek.com/e/events/${MarketplaceEventIdPlaceholder}`,
  [Marketplace.Gametime]: `https://gametime.co/events/${MarketplaceEventIdPlaceholder}`,
} as Record<string, string>;
