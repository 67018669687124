import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QuickFloorInput } from 'src/components/AutoPricing/QuickFloorInput';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { FloatingToolbar } from 'src/core/ui/FloatingToolbar';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { QuickAtionShieldIcon } from 'src/svgs/QuickAtionShieldIcon';
import { ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { posField } from 'src/utils/posFieldUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Listing,
  ListingClient,
  ListingDetailsAutoPricingSectionUpdatesV2,
  PricingFloorCeilingAdjustmentType,
  UserSetting,
} from 'src/WebApiController';

export const QuickFloorToolbar = ({ listing }: { listing: Listing }) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { isDarkMode } = useSiteTheme();
  const { showErrorDialog } = useErrorBoundaryContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const { value: quickFloorCollapsed, setUserSetting: setQuickFloorCollapsed } =
    useServerUserSetting<boolean>({
      id: UserSetting.QuickFloorPromptDismissal,
    });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>(
    quickFloorCollapsed
  );

  useEffect(() => {
    if (isCollapsed == null) {
      if (quickFloorCollapsed != null) {
        // if there is user settings, use is
        setIsCollapsed(quickFloorCollapsed);
      }
    }
  }, [isCollapsed, listing, quickFloorCollapsed]);

  const quickFloorForm = useForm<ListingDetailsAutoPricingSectionUpdatesV2>({
    defaultValues: {
      id: listing.id,
      rowVersion: listing.rowVer,
      currencyCode: listing.currency,
      netProceedsFloor: posField(listing.procsFloor),
      netProceedsFloorAdjustmentType: listing.undNewPrice
        ? PricingFloorCeilingAdjustmentType.MarketPrice
        : listing.procsFloor
        ? PricingFloorCeilingAdjustmentType.CurrentFloor
        : listing.unitCst
        ? PricingFloorCeilingAdjustmentType.UnitCost
        : null,
      netProceedsFloorAdjustmentRelative: false,
    },
  });

  const { watch, getValues, reset } = quickFloorForm;

  const floor = watch('netProceedsFloor');
  const netProceedsFloorAdjustmentAmount = watch(
    'netProceedsFloorAdjustmentAmount'
  );

  const hasChanged =
    (floor?.value != null && floor?.hasChanged) ||
    netProceedsFloorAdjustmentAmount != null;

  const onSave = useCallback(() => {
    const input = getValues();

    tryInvokeApi(
      async () => {
        if (isSubmitting) return;

        setIsSubmitting(true);

        const result = await new ListingClient(
          activeAccountWebClientConfig
        ).updateListingAutoPricingSectionV2(input);

        if (isSuccess(result)) {
          await refreshExpandedListItems();
          reset({
            ...input,
            // Reset these so they become unchanged
            netProceedsFloorAdjustmentAmount: null,
            netProceedsFloor: posField(input.netProceedsFloor?.value),
          });
        } else {
          showErrorDialog('Unable to save changes.', result, {
            trackErrorData: input,
          });
        }
      },
      (error) => {
        showErrorDialog(
          'ListingClient.updateListingAutoPricingSectionV2',
          error,
          { trackErrorData: input }
        );
      },
      () => {
        setIsSubmitting(false);
      }
    );
  }, [
    activeAccountWebClientConfig,
    getValues,
    isSubmitting,
    refreshExpandedListItems,
    reset,
    showErrorDialog,
  ]);

  return (
    <FloatingToolbar
      disabled={isSubmitting}
      isCollapsed={isCollapsed}
      onToggleCollapsed={(collapsed) => {
        if (collapsed == null) {
          collapsed = !isCollapsed; // toggle previous state
        }
        setIsCollapsed(collapsed);
        setQuickFloorCollapsed(collapsed);
      }}
    >
      <FormProvider {...quickFloorForm}>
        <Stack gap="l" alignItems="center">
          <Stack gap="m" alignItems="center" justifyContent="center">
            <QuickAtionShieldIcon
              fill={
                isDarkMode ? vars.color.textPrimary : vars.color.textInverted // we display inverse of the main color
              }
            />
            <Content
              id={
                listing.isAutoPrc
                  ? ContentId.SetAFloor
                  : ContentId.SetAPriceFloor
              }
            />
          </Stack>
          <QuickFloorInput
            listing={listing}
            disabled={isSubmitting}
            onEnterKeyPressed={onSave}
          />
          <Button disabled={!hasChanged || isSubmitting} onClick={onSave}>
            {isSubmitting ? (
              <RotatingWrapper>
                <ProcessingIcon size={vars.iconSize.m} />
              </RotatingWrapper>
            ) : (
              <Content id={ContentId.Save} />
            )}
          </Button>
        </Stack>
      </FormProvider>
    </FloatingToolbar>
  );
};
