import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  EventAccordionItemProps,
  translateEventToInventoryAccordionItem,
} from 'src/components/Accordions';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import { BackSection } from 'src/components/common/BackSection';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { StubHubListingManualPricingContextProvider } from 'src/components/Listings/InventoryEventPage/MarketListings/StubHubListingsManualPricingContext';
import { ActiveFocusContextProvider } from 'src/contexts/ActiveFocusContext/ActiveFocusContext';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { CollapsableViewProvider } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { InputPriceFocusProvider } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { SidePanelProvider } from 'src/contexts/SidePanelContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useQuickFilterSearchParam } from 'src/hooks/useQuickFilters';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import {
  getAppliedFilterCounts,
  QueryWithViewMode,
} from 'src/utils/eventQueryUtils';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';
import {
  EntityWithTicketsQuery,
  EventWithData,
  ListingDetailDataField,
  ListingDetails,
  UserSetting,
} from 'src/WebApiController';

import * as styles from './InventoryEventPage.css';
import { InventoryEventPageHeader } from './InventoryEventPageHeader';
import { InventoryEventDashboardSection } from './Sections/InventoryEventDashboardSection';
import { TabWithStarIcon } from './TabWithStarIcon';

export function InventoryEventPageV2({
  viagVirtualId,
  returnUrl,
}: {
  viagVirtualId: string;
  returnUrl?: string;
}) {
  const {
    eventsTransformed,
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  useEffect(() => {
    setListItemExpansion(true, [viagVirtualId]);
  }, [viagVirtualId, setListItemExpansion]);

  const accordionItemProps = useMemo(
    () =>
      eventsTransformed?.find(
        (e) =>
          e.event.viagVirtualId.toLowerCase() === viagVirtualId.toLowerCase()
      ),

    [eventsTransformed, viagVirtualId]
  );

  const { setAllGroupsIds } = useMultiSelectionContext();

  const inventoryAccordionItem = useMemo(
    () =>
      accordionItemProps &&
      translateEventToInventoryAccordionItem(accordionItemProps, 0, true),
    [accordionItemProps]
  );

  useEffect(() => {
    if (inventoryAccordionItem) {
      setAllGroupsIds([inventoryAccordionItem.itemId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryAccordionItem]);

  return (
    <SidePanelProvider sidePanelId="inventory-event">
      <ActiveFocusContextProvider>
        <EventMapContextProvider event={accordionItemProps?.event}>
          <InputPriceFocusProvider
            disablePagination
            pageSize={accordionItemProps?.listCnt ?? 0}
          >
            <StubHubListingManualPricingContextProvider
              groupId={accordionItemProps?.event.viagVirtualId}
            >
              <Stack direction="column" height="full">
                <InventoryEventContent
                  accordionItemProps={accordionItemProps}
                  inventoryAccordionItem={inventoryAccordionItem}
                  returnUrl={returnUrl}
                />
              </Stack>
            </StubHubListingManualPricingContextProvider>
          </InputPriceFocusProvider>
        </EventMapContextProvider>
      </ActiveFocusContextProvider>
    </SidePanelProvider>
  );
}

type InventoryEventContentProps = {
  accordionItemProps?: EventWithData;
  inventoryAccordionItem?: EventAccordionItemProps;
  returnUrl?: string;
};

export const enum InventoryEventV2Tabs {
  Dashboard = 'dashboard',
  None = 'none',
}

function InventoryEventContent({
  accordionItemProps,
  inventoryAccordionItem,
  returnUrl,
}: InventoryEventContentProps) {
  const [selectedListingIds, setSelectedListingIds] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState(InventoryEventV2Tabs.Dashboard);

  const { isLoading } = useCatalogDataContext();
  const isMobile = useMatchMedia('mobile');

  const quickFilterSearchParams = useQuickFilterSearchParam();
  const { tempQuery, initialQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();

  const { setModal } = useContext(ModalContext);
  const { posEntity: listing, setActivePosEntity } =
    useActivePosEntityContext<ListingDetails>();

  const {
    value: defaultInventoryTabUserSetting,
    setUserSetting: setDefaultInventoryTabUserSetting,
    isLoading: defaultInventoryTabLoading,
  } = useServerUserSetting<InventoryEventV2Tabs>({
    id: UserSetting.InventoryV2DefaultTab,
  });

  const activeId = getDeepLinkIdFromUrl(
    InventoryDeeplinkQueryParam,
    window.location.href
  );

  useEffect(() => {
    if (activeId?.length && !listing) {
      setActivePosEntity(parseInt(activeId), undefined, true, [
        ListingDetailDataField.Basic,
      ]);
      setModal(getListingDetailsModalConfigWithDeepLink(activeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  const [defaultTab, setDefaultTab] = useState<
    InventoryEventV2Tabs | undefined
  >(defaultInventoryTabUserSetting);

  useEffect(() => {
    if (
      defaultInventoryTabUserSetting != null &&
      defaultInventoryTabUserSetting !== activeTab &&
      defaultInventoryTabUserSetting !== InventoryEventV2Tabs.None &&
      defaultInventoryTabLoading === false
    ) {
      setActiveTab(defaultInventoryTabUserSetting);
    }
    if (
      defaultInventoryTabUserSetting != null &&
      defaultInventoryTabUserSetting !== defaultTab &&
      defaultInventoryTabUserSetting !== InventoryEventV2Tabs.None &&
      defaultInventoryTabLoading === false
    ) {
      setDefaultTab(defaultInventoryTabUserSetting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultInventoryTabLoading, defaultInventoryTabUserSetting]);

  const onSetDefaultTabClick = useCallback(
    async (tab: InventoryEventV2Tabs) => {
      if (tab !== InventoryEventV2Tabs.None) {
        if (tab !== activeTab) {
          setActiveTab(tab);
        }
      }
      if (tab !== defaultTab) {
        setDefaultTab(tab);
      }
      setDefaultInventoryTabUserSetting(tab);
    },
    [activeTab, defaultTab, setDefaultInventoryTabUserSetting]
  );

  const event = accordionItemProps?.event;

  if (!event) {
    if (
      quickFilterSearchParams ||
      getAppliedFilterCounts(tempQuery, initialQuery)
    )
      return (
        <EmptySectionContent.Root
          icon={
            <EmptySectionContent.SolidIconContainer>
              <SearchSolidIcon />
            </EmptySectionContent.SolidIconContainer>
          }
        >
          <EmptySectionContent.Label>
            <Content id={ContentId.NoResultFound} />
          </EmptySectionContent.Label>
          <EmptySectionContent.DetailMessage>
            <Content id={ContentId.NoFilteredNotifications} />
          </EmptySectionContent.DetailMessage>
        </EmptySectionContent.Root>
      );
  }

  if (isLoading || !event) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.leftContainer} style={{ height: '100%' }}>
        {isMobile && (
          <Stack
            justifyContent="spaceBetween"
            alignItems="center"
            style={{
              padding: `0 ${vars.spacing['xl']} ${vars.spacing['lg']} ${vars.spacing['xl']}`,
            }}
          >
            <BackSection
              returnUrl={returnUrl ?? '/inventory'}
              state={{
                fromPosEventId: event.viagVirtualId,
              }}
            />
            <SwiperButton dir="right" />
          </Stack>
        )}
        <CollapsableViewProvider isEnabled={true}>
          <InventoryEventPageHeader
            event={event}
            inventoryAccordionItem={inventoryAccordionItem}
          />
          <Tabs.Root
            value={activeTab}
            onValueChange={(value) =>
              setActiveTab(value as InventoryEventV2Tabs)
            }
            className={styles.tabsSection}
          >
            <Tabs.List
              style={{
                padding: `${vars.spacing['lg']} ${vars.spacing['xl']} 0 ${vars.spacing['xl']}`,
              }}
            >
              <Tabs.Trigger value={InventoryEventV2Tabs.Dashboard}>
                <TabWithStarIcon
                  onIconClick={onSetDefaultTabClick}
                  defaultTab={defaultTab}
                  tabType={InventoryEventV2Tabs.Dashboard}
                >
                  <Content id={ContentId.Dashboard} />
                </TabWithStarIcon>
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content
              value={InventoryEventV2Tabs.Dashboard}
              className={styles.tabContent}
            >
              <InventoryEventDashboardSection
                accordionItemProps={accordionItemProps}
              />
            </Tabs.Content>
          </Tabs.Root>
        </CollapsableViewProvider>
      </div>
    </div>
  );
}
