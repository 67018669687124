import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useUserCanUpdateSale } from 'src/hooks/useUserHasSalePermissions';
import { DetailSection } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ListingNotesSection } from 'src/modals/common/ListingNotesSection';
import { ModalBody } from 'src/modals/Modal';
import { SeatsSoldAs } from 'src/modals/SaleDetails/components/SeatsSoldAs/SeatsSoldAs';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { SaleDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  createSaleEventPageUrl,
  getSaleDetailsUpdateInput,
} from 'src/utils/saleUtils';
import {
  AutoPoClient,
  Feature,
  Permission,
  SaleDetails,
  SaleInput,
  SaleLineItem,
} from 'src/WebApiController';

import { PurchaseInfoSection } from '../common/PurchaseInfoSection';
import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';
import { CommentSection } from './components/CommentSection';
import { DeliverySection } from './components/DeliverySection';
import { FulfillmentSection } from './components/FulfillmentSection';
import { MarketplacePaymentSection } from './components/MarketplacePaymentSection';
import { PaymentSection } from './components/PaymentSection/PaymentSection';
import { TagsSection } from './components/TagsSection';
import { TicketsSection } from './components/TicketsSection';
import { YourSaleSection } from './components/YourSaleSection';
import { SaleDetailsFooter } from './SaleDetailsFooter';

export const SaleDetailsModal = () => {
  const activeId = getDeepLinkIdFromUrl(
    SaleDeeplinkQueryParam,
    window.location.href
  );
  const {
    isLoading,
    posEntity: sale,
    loadingState,
  } = useEventItemLoadingDisplay<SaleDetails>(
    FormatContentId.LoadingSaleId,
    FormatContentId.SearchingForSaleId,
    FormatContentId.CouldNotFindSaleId,
    activeId
  );

  if (!sale) {
    // If we don't have a sale yet, either return loading state or nothing
    return loadingState;
  }

  // If we do have a sale already, only return loadingState if it is not loading (ie, it's something else wrong)
  if (!isLoading && loadingState) return loadingState;

  return <SaleDetailsModalForm sale={sale} />;
};

const SaleDetailsModalForm = ({ sale }: { sale: SaleDetails }) => {
  const methods = useForm<SaleInput>({
    defaultValues: getSaleDetailsUpdateInput(sale),
  });

  return (
    <FormProvider {...methods}>
      <CancellableFormHeader>
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.ViewDetails} />}
        />
      </CancellableFormHeader>

      <SaleDetailsModalBody />

      <SaleDetailsFooter />
    </FormProvider>
  );
};

const SaleDetailsModalBody = () => {
  const { event, posEntity: sale } = useActivePosEntityContext<SaleDetails>();
  const hasAutoPoAccess = useUserHasFeature(Feature.AutoPO);
  const hasSaleSkyboxNotesSectionFeature = useUserHasFeature(
    Feature.SaleSkyboxNotesSection
  );
  const hasCreatePermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Create
  );
  const hasSaleSeatsSoldAs = useUserHasFeature(Feature.SaleSeatsSoldAs);

  const { activeAccountWebClientConfig } = useAppContext();
  const navigate = useNavigate();
  const { trackError } = useErrorBoundaryContext();
  const { watch, setValue } = useFormContext<SaleInput>();
  const internalNotes = watch('internalNotes');
  const lineItems = watch('lineItems');

  const canUpdate = useUserCanUpdateSale(sale);

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null && sale?.id != null;
  const inboundEmailId = useQuery({
    queryKey: [
      'AutoPoClient.getAutoPoClassifiedInboundEmailIdForEntity',
      activeAccountWebClientConfig,
      sale?.id,
    ],
    async queryFn() {
      if (!shouldQuery) {
        return null;
      }
      return new AutoPoClient(
        activeAccountWebClientConfig
      ).getAutoPoClassifiedInboundEmailIdForEntity(null, sale!.id);
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'AutoPoClient.getAutoPoClassifiedInboundEmailIdForEntity',
          error,
          {
            saleId: sale?.id,
          }
        );
      },
    },
  });

  const onLineItemChange = useCallback(
    (item: SaleLineItem, isDelete?: boolean) => {
      if (isDelete) {
        setValue(
          'lineItems',
          posChangedField(
            lineItems?.value?.filter((l) => l.id !== item.id) ?? []
          ),
          {
            shouldDirty: true,
          }
        );
      } else {
        const indexExisting = lineItems?.value?.findIndex(
          (l) => l.id === item.id
        );
        if ((indexExisting ?? -1) >= 0) {
          lineItems!.value![indexExisting!] = item;
          setValue('lineItems', posChangedField([...lineItems!.value!]), {
            shouldDirty: true,
          });
        } else {
          item.id = -((lineItems?.value?.length ?? 0) + 1);
          setValue(
            'lineItems',
            posChangedField([...(lineItems?.value ?? []), item]),
            {
              shouldDirty: true,
            }
          );
        }
      }
    },
    [lineItems, setValue]
  );

  const onInternalNotesChanged = useCallback(
    (notes?: string | null) => {
      if (notes !== internalNotes?.value) {
        setValue('internalNotes', posChangedField(notes ?? null), {
          shouldDirty: true,
        });
      }
    },
    [internalNotes?.value, setValue]
  );

  const saleEventUrl = useMemo(() => {
    const eventId = sale?.viagVirtualId;
    if (eventId) {
      return createSaleEventPageUrl(eventId, [SaleDeeplinkQueryParam]);
    }
    return undefined;
  }, [sale]);

  return (
    <ModalBody>
      <ModalBodyHeaderContainer>
        <Summary
          event={event!}
          posEntity={sale!}
          eventNameLink={saleEventUrl}
          actions={
            Boolean(inboundEmailId.data) &&
            hasAutoPoAccess &&
            hasCreatePermission && (
              <PosDropdown
                trigger={
                  <MoreIcon
                    withHoverEffect
                    fill={IconsFill.textBrand}
                    align="middle"
                  />
                }
                align="end"
              >
                <PosDropdownItem
                  onClick={() => {
                    navigate(`/messages/${inboundEmailId.data}`);
                  }}
                >
                  <Content id={ContentId.GoToMessageThread} />
                </PosDropdownItem>
              </PosDropdown>
            )
          }
        />
      </ModalBodyHeaderContainer>

      <ModalBodyDataContainer>
        <DetailSection name={<Content id={ContentId.YourSale} />}>
          <YourSaleSection
            saleId={sale!.id}
            marketplaceSaleId={sale!.idOnMkp}
            marketplace={sale!.mkp}
            saleDate={sale!.saleDate}
            insertBy={sale!.insertBy}
            status={sale!.status}
          />
        </DetailSection>

        {hasSaleSeatsSoldAs ? (
          <SeatsSoldAs sale={sale} />
        ) : (
          <DetailSection name={<Content id={ContentId.Tickets} />}>
            <TicketsSection
              {...sale!.seating}
              quantity={sale!.qtySold}
              tickets={sale!.tickets}
              listingId={sale!.listingId}
              originatedFromListingId={sale!.originatedFromListingId}
              isSeatSaver={sale?.isSeatSaver}
            />
          </DetailSection>
        )}

        <DetailSection name={<Content id={ContentId.Notes} />}>
          <ListingNotesSection
            listingNotes={sale!.seatTraits}
            internalNotes={internalNotes?.value}
            onInternalNotesChanged={onInternalNotesChanged}
            disabled={!canUpdate}
          />
        </DetailSection>

        <DetailSection name={<Content id={ContentId.Earnings} />}>
          <Stack direction="column" gap="l">
            <PaymentSection
              lineItems={lineItems?.value}
              onLineItemChange={onLineItemChange}
              sale={sale}
            />

            <MarketplacePaymentSection sale={sale} />
          </Stack>
        </DetailSection>

        <DetailSection name={<Content id={ContentId.BuyerDelivery} />}>
          <DeliverySection sale={sale} />
        </DetailSection>
        <DetailSection name={<Content id={ContentId.Fulfillment} />}>
          <FulfillmentSection sale={sale} />
        </DetailSection>

        {!hasSaleSeatsSoldAs && (
          <PurchaseInfoSection purchaseInfos={sale!.poInfos} />
        )}

        <DetailSection name={<Content id={ContentId.Tags} />}>
          <TagsSection sale={sale} />
        </DetailSection>

        {hasSaleSkyboxNotesSectionFeature && (
          <DetailSection name={<Content id={ContentId.Comments} />}>
            <Stack direction="column" gap="l">
              <CommentSection commentNotes={sale?.comNotes} />
            </Stack>
          </DetailSection>
        )}
      </ModalBodyDataContainer>
    </ModalBody>
  );
};
