import { isEqual } from 'lodash-es';
import {
  FieldValues,
  Path,
  UseFormClearErrors,
  UseFormSetValue,
} from 'react-hook-form';
import {
  ButtonWithIcon,
  IconButton,
  UploadButton,
} from 'src/components/Buttons';
import { AdminHoldBadge } from 'src/components/Listings/AdminHoldBadge';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { ListingDetailsModalConfig } from 'src/modals/ListingDetails';
import { LaunchBroadcastListing } from 'src/modals/ListingDetails/actions/LaunchBroadcastListing';
import { LaunchCreateMarketplaceSale } from 'src/modals/ListingDetails/actions/LaunchCreateMarketplaceSale';
import { LaunchCreateOfflineSale } from 'src/modals/ListingDetails/actions/LaunchCreateOfflineSale';
import { LaunchDownloadUserDocuments } from 'src/modals/ListingDetails/actions/LaunchDownloadUserDocuments';
import { LaunchSplitListing } from 'src/modals/ListingDetails/actions/LaunchSplitListing';
import { LaunchSplitListingToOriginal } from 'src/modals/ListingDetails/actions/LaunchSplitListingToOriginal';
import { LaunchUpdateAdminHold } from 'src/modals/ListingDetails/actions/LaunchUpdateAdminHold';
import { ModalProps } from 'src/modals/Modal';
import { LaunchUploadBarcodes } from 'src/modals/UploadBarcodes/LaunchUploadBarcodes';
import { LaunchUploadETickets } from 'src/modals/UploadETickets/LaunchUploadETickets';
import { LaunchUploadExternalIds } from 'src/modals/UploadExternalIds/LaunchUploadExternalIds';
import { LaunchUploadTicketUrls } from 'src/modals/UploadTicketUrls/LaunchUploadTicketUrls';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { BroadcastIcon, UnbroadcastIcon } from 'src/svgs';
import { AnimatedBroadcastIcon } from 'src/svgs/AnimatedBroadcastIcon';
import { AnimatedUnbroadcastIcon } from 'src/svgs/AnimatedUnbroadcastIcon';
import { BroadcastFailedIcon } from 'src/svgs/BroadcastFailedIcon';
import { BroadcastWithErrorIcon } from 'src/svgs/BroadcastWithErrorIcon';
import { DuplicateOutlinedIcon } from 'src/svgs/DuplicateOutlinedIcon';
import { FullySoldIcon } from 'src/svgs/FullySoldIcon';
import { PendingQCIcon } from 'src/svgs/PendingQC';
import { PosIconProps } from 'src/svgs/SvgWrapper';
import { ClockIcon } from 'src/svgs/Viagogo';
import {
  EMPTY_SELLER_USER_ID,
  InventoryDeeplinkQueryParam,
  REACT_APP_STUBHUB_BASE_URL,
  REACT_APP_VIAGOGO_BASE_URL,
  SuspiciousPriceChangeThreshold,
} from 'src/utils/constants/constants';
import {
  ActionOutboxEntityType,
  AutoPricingCompListingMode,
  AutoPricingInputs,
  AutoPricingOutlierMode,
  AutoPricingUndercutMode,
  DocumentProcessorClient,
  DocumentUploadInfo,
  Event,
  EventWithData,
  FileParameter,
  GroupType,
  Listing,
  ListingActionType,
  ListingBroadcastStatus,
  ListingClient,
  ListingDetailsUpdateInput,
  ListingGroup,
  ListingMarketplacePriceUpdateInput,
  ListingStatus,
  Marketplace,
  MarketplaceAccountSetting,
  PendingProofState,
  PosClientConfig,
  QualityControlState,
  Tag,
  TicketBarcode,
  TicketExternalId,
  TicketType,
  TicketUrl,
  UiCurrency,
  UserDocument,
} from 'src/WebApiController';

import { ContentId } from './constants/contentId';
import { LISTING_STATUS_WITH_ACTION_TO_CID } from './constants/contentIdMaps';
import { FormatContentId } from './constants/formatContentId';
import { getFullUrl, getPathFromMainRoute } from './deepLinkUtils';
import { compareMarketplace } from './eventWithDataUtils';
import { roundToPrecision } from './numberFormatter';
import { tagHasChanges } from './tagUtils';

export const getListingDetailsModalConfigWithDeepLink = (
  deepLinkId?: string | number,
  doTrackView = false
) => {
  return {
    ...ListingDetailsModalConfig,
    deepLinkValue: deepLinkId,
    trackViewEntityType: doTrackView
      ? ActionOutboxEntityType.Listing
      : undefined,
  };
};

export const ListingCatalogQueryKey = 'CatalogClient.getCatalogForListing';
export const ListingCatalogMetricsQueryKey =
  'CatalogClient.getCatalogListingMetrics';

export const getListingDetailsDeepLinkUrl = (deepLinkId?: string | number) => {
  return getFullUrl(
    MainRoute.Inventory,
    InventoryDeeplinkQueryParam,
    deepLinkId
  );
};

export const getListingDetailsRelativeUrl = (deepLinkId?: string | number) => {
  const navigateUrl = `${getPathFromMainRoute(
    MainRoute.Inventory
  )}?${InventoryDeeplinkQueryParam}=${deepLinkId}`;

  return navigateUrl;
};

/**
 * Get all listing ids excluding listing groups
 * @param listings
 * @returns
 */
export const getFlattenedListingIds = (listings: Listing[]) => {
  return [
    ...listings.filter((n) => !n.isLtGrp).map((l) => l.id),
    ...listings
      .filter((n) => n.isLtGrp)
      .flatMap((g) =>
        (g as ListingGroup).groupItems
          .filter((gi) => !(gi as ListingGroup).groupItems)
          .map((gi) => gi.id)
      ),
    // this is the second level group
    ...listings
      .filter((n) => n.isLtGrp)
      .flatMap((g) =>
        (g as ListingGroup).groupItems
          .filter((gi) => (gi as ListingGroup).groupItems)
          .flatMap((gi) => (gi as ListingGroup).groupItems.map((gi2) => gi2.id))
      ),
  ];
};

const getListingUploadDropdownItems = (
  listing: Listing,
  actions: ListingActionType[],
  variant?: ButtonProps['variant'],
  cancelTo?: ModalProps,
  disabled?: boolean
) => {
  const dropdownItems = [];

  const barcodeAction = actions.find(
    (a) =>
      a === ListingActionType.PredeliverBarcodes ||
      a === ListingActionType.RedeliverBarcodes
  ) as
    | ListingActionType.PredeliverBarcodes
    | ListingActionType.RedeliverBarcodes
    | undefined;

  if (barcodeAction) {
    dropdownItems.push(
      <LaunchUploadBarcodes
        disabled={disabled}
        key={`${listing.id}_${barcodeAction}`}
        onUploadBarcodes={(
          clientConfig: PosClientConfig,
          listingId: number,
          barcodes: TicketBarcode[]
        ) =>
          new ListingClient(clientConfig).updateBarcodesForListing(
            listingId,
            barcodes
          )
        }
        dropdownItemMode={true}
        action={barcodeAction}
        entityId={listing.id}
        idOnMkp={listing.idOnMkp}
        iconOnlyMode={false}
        cancelTo={cancelTo}
        variant={variant}
        loadingContentId={FormatContentId.LoadingListingId}
        errorContentId={FormatContentId.CouldNotFindListingId}
        searchingContentId={FormatContentId.SearchingForListingId}
      />
    );
  }

  const ticketUrlAction = actions.find(
    (a) =>
      a === ListingActionType.PredeliverTicketUrls ||
      a === ListingActionType.RedeliverTicketUrls
  ) as
    | ListingActionType.PredeliverTicketUrls
    | ListingActionType.RedeliverTicketUrls
    | undefined;
  if (ticketUrlAction) {
    dropdownItems.push(
      <LaunchUploadTicketUrls
        disabled={disabled}
        key={`${listing.id}_${ticketUrlAction}`}
        onUploadTicketUrls={(
          clientConfig: PosClientConfig,
          listingId: number,
          ticketUrls: TicketUrl[]
        ) =>
          new ListingClient(clientConfig).updateTicketUrlsForListing(
            listingId,
            ticketUrls
          )
        }
        dropdownItemMode={true}
        action={ticketUrlAction}
        entityId={listing.id}
        idOnMkp={listing.idOnMkp}
        iconOnlyMode={false}
        cancelTo={cancelTo}
        variant={variant}
        loadingContentId={FormatContentId.LoadingListingId}
        errorContentId={FormatContentId.CouldNotFindListingId}
        searchingContentId={FormatContentId.SearchingForListingId}
      />
    );
  }

  const externalIdAction = actions.find(
    (a) =>
      a === ListingActionType.PredeliverExternalIds ||
      a === ListingActionType.RedeliverExternalIds
  ) as
    | ListingActionType.PredeliverExternalIds
    | ListingActionType.RedeliverExternalIds
    | undefined;
  if (externalIdAction) {
    dropdownItems.push(
      <LaunchUploadExternalIds
        disabled={disabled}
        key={`${listing.id}_${externalIdAction}`}
        onUploadExternalIds={(
          clientConfig: PosClientConfig,
          listingId: number,
          externalIds: TicketExternalId[]
        ) =>
          new ListingClient(clientConfig).updateExternalIdsForListing(
            listingId,
            externalIds
          )
        }
        dropdownItemMode={true}
        action={externalIdAction}
        entityId={listing.id}
        idOnMkp={listing.idOnMkp}
        iconOnlyMode={false}
        cancelTo={cancelTo}
        variant={variant}
        loadingContentId={FormatContentId.LoadingListingId}
        errorContentId={FormatContentId.CouldNotFindListingId}
        searchingContentId={FormatContentId.SearchingForListingId}
      />
    );
  }

  const createEticketDropdown = (
    ticketType: TicketType.ETicket | TicketType.QRCode,
    action:
      | ListingActionType.PredeliverETickets
      | ListingActionType.RedeliverETickets
      | ListingActionType.PredeliverQRCode
      | ListingActionType.RedeliverQRCode
  ) => {
    dropdownItems.push(
      <LaunchUploadETickets
        disabled={disabled}
        key={`${listing.id}_${eticketAction}`}
        dropdownItemMode={true}
        entityId={listing.id}
        marketplaceSaleId={listing.idOnMkp}
        iconOnlyMode={false}
        cancelTo={cancelTo}
        variant={variant}
        ticketType={ticketType}
        action={action}
        onUploadETickets={(
          clientConfig: PosClientConfig,
          entityId: number,
          etickets: UserDocument[]
        ) =>
          ticketType === TicketType.ETicket
            ? new ListingClient(clientConfig).updateETicketsForListing(
                entityId,
                etickets
              )
            : new ListingClient(clientConfig).updateMobileQRCodesForListing(
                entityId,
                etickets
              )
        }
        onUploadDocuments={(
          client: DocumentProcessorClient,
          docUploadInfo: DocumentUploadInfo,
          file: FileParameter
        ) =>
          client.uploadDocumentForListing(
            docUploadInfo.entityId,
            ActionOutboxEntityType.Listing,
            docUploadInfo.documentType,
            docUploadInfo.blobName,
            docUploadInfo.documentId,
            docUploadInfo.contentType,
            file
          )
        }
        loadingContentId={FormatContentId.LoadingListingId}
        errorContentId={FormatContentId.CouldNotFindListingId}
        searchingContentId={FormatContentId.SearchingForListingId}
      />
    );
  };

  const eticketAction = actions.find(
    (a) =>
      a === ListingActionType.PredeliverETickets ||
      a === ListingActionType.RedeliverETickets
  ) as
    | ListingActionType.PredeliverETickets
    | ListingActionType.RedeliverETickets
    | undefined;
  if (eticketAction) {
    createEticketDropdown(TicketType.ETicket, eticketAction);
  }

  const qrCodeAction = actions.find(
    (a) =>
      a === ListingActionType.PredeliverQRCode ||
      a === ListingActionType.RedeliverQRCode
  ) as
    | ListingActionType.PredeliverQRCode
    | ListingActionType.RedeliverQRCode
    | undefined;
  if (qrCodeAction) {
    createEticketDropdown(TicketType.QRCode, qrCodeAction);
  }

  return dropdownItems;
};

export const getListingActionButtons = (
  forTable: boolean,
  listing: Listing,
  actions: ListingActionType[],
  event?: Event | null,
  variant?: ButtonProps['variant'],
  iconOnlyMode?: boolean,
  cancelTo?: ModalProps,
  disabled?: boolean,
  lockListingGroupFromEdits?: (lock: boolean) => void,
  onSetListingActiveInGroup?: () => void
) => {
  const actionElements: JSX.Element[] = [];

  const dropdownItems = [];

  if (iconOnlyMode) {
    if (!actions.includes(ListingActionType.DownloadUserDocuments)) {
      dropdownItems.push(
        ...getListingUploadDropdownItems(
          listing,
          actions,
          variant,
          cancelTo,
          disabled
        )
      );
    }
    const hasNoBroadcastActions =
      !listing.actions.includes(ListingActionType.Broadcast) &&
      !listing.actions.includes(ListingActionType.Unbroadcast);

    if (listing.isAdminHold) {
      actionElements.push(
        <IconButton
          icon={
            <AdminHoldBadge
              adminHoldExpirationDate={listing.adminHoldExpiredOn}
              adminHoldNotes={listing.adminHoldNotes}
              showDetailOnHover
              iconOnlyMode
            />
          }
        />
      );
    } else if (hasNoBroadcastActions && !listing.isAdminHold) {
      const { iconComponent: ListingBroadcastIcon, contentId } =
        getListingBroadcastIcon(
          listing.status,
          listing.qcState,
          !!listing.dupListingId,
          !!listing.ignoreDup,
          listing.isUndeliv,
          listing.brdcstStatus,
          listing.pendProofState
        );
      actionElements.push(
        <IconButton
          icon={<ListingBroadcastIcon size={vars.iconSize.m} />}
          titleContentId={contentId}
        />
      );
    } else if (
      listing.ltGrpId &&
      !listing.ltGrpActive &&
      !listing.isAdminHold
    ) {
      actionElements.push(
        <IconButton
          icon={
            <ClockIcon size={vars.iconSize.m} withHoverEffect={!disabled} />
          }
          disabled={disabled}
          onClick={onSetListingActiveInGroup}
          titleContentId={ContentId.SetActiveInGroup}
        />
      );
    }
  }

  actions.forEach((action) => {
    switch (action) {
      case ListingActionType.FullySoldNoAction:
        actionElements.push(
          <ButtonWithIcon
            onClick={undefined}
            disabled={disabled}
            textContentId={LISTING_STATUS_WITH_ACTION_TO_CID[listing.status]}
            icon={<FullySoldIcon size={vars.iconSize.m} />}
            variant={variant}
            iconOnlyMode={iconOnlyMode}
          />
        );
        break;
      case ListingActionType.Broadcast:
      case ListingActionType.Unbroadcast:
        actionElements.push(
          <LaunchBroadcastListing
            key={`${listing.id}_${action}`}
            forTable={forTable}
            event={event}
            listing={listing}
            lockListingGroupFromEdits={lockListingGroupFromEdits}
            iconOnlyMode={iconOnlyMode}
            variant={variant}
            disabled={disabled}
          />
        );
        break;
      case ListingActionType.Split:
        actionElements.push(
          <LaunchSplitListing
            key={`${listing.id}_${action}`}
            listingId={listing.id}
            marketplaceListingId={listing.idOnMkp}
            iconOnlyMode={iconOnlyMode}
            variant={variant}
            cancelTo={cancelTo}
            disabled={disabled}
          />
        );
        break;
      case ListingActionType.SplitToOriginal:
        actionElements.push(
          <LaunchSplitListingToOriginal
            key={`${listing.id}_${action}`}
            listingId={listing.id}
            iconOnlyMode={iconOnlyMode}
            variant={variant}
            cancelTo={cancelTo}
            disabled={disabled}
          />
        );
        break;

      case ListingActionType.AddOfflineSale:
        actionElements.push(
          <LaunchCreateOfflineSale
            key={`${listing.id}_${action}`}
            listingId={listing.id}
            marketplaceListingId={listing.idOnMkp}
            cancelTo={cancelTo}
            disabled={disabled}
            iconOnlyMode={iconOnlyMode}
            variant={variant}
          />
        );
        break;

      case ListingActionType.AddMarketplaceSale:
        actionElements.push(
          <LaunchCreateMarketplaceSale
            key={`${listing.id}_${action}`}
            listingId={listing.id}
            marketplaceListingId={listing.idOnMkp}
            cancelTo={cancelTo}
            disabled={disabled}
            iconOnlyMode={iconOnlyMode}
            variant={variant}
          />
        );
        break;
      case ListingActionType.PredeliverBarcodes:
      case ListingActionType.RedeliverBarcodes:
        // Show a dropdown to select different upload types if iconOnlyMode is true
        if (
          iconOnlyMode &&
          (dropdownItems.length > 1 ||
            actions.includes(ListingActionType.DownloadUserDocuments))
        )
          break;
        actionElements.push(
          <LaunchUploadBarcodes
            disabled={disabled}
            key={`${listing.id}_${action}`}
            onUploadBarcodes={(
              clientConfig: PosClientConfig,
              listingId: number,
              barcodes: TicketBarcode[]
            ) =>
              new ListingClient(clientConfig).updateBarcodesForListing(
                listingId,
                barcodes
              )
            }
            action={action}
            entityId={listing.id}
            idOnMkp={listing.idOnMkp}
            iconOnlyMode={iconOnlyMode}
            cancelTo={cancelTo}
            variant={variant}
            loadingContentId={FormatContentId.LoadingListingId}
            errorContentId={FormatContentId.CouldNotFindListingId}
            searchingContentId={FormatContentId.SearchingForListingId}
          />
        );
        break;
      case ListingActionType.PredeliverTicketUrls:
      case ListingActionType.RedeliverTicketUrls:
        // Show a dropdown to select different upload types if iconOnlyMode is true
        if (
          iconOnlyMode &&
          (dropdownItems.length > 1 ||
            actions.includes(ListingActionType.DownloadUserDocuments))
        )
          break;
        actionElements.push(
          <LaunchUploadTicketUrls
            disabled={disabled}
            key={`${listing.id}_${action}`}
            onUploadTicketUrls={(
              clientConfig: PosClientConfig,
              listingId: number,
              ticketUrls: TicketUrl[]
            ) =>
              new ListingClient(clientConfig).updateTicketUrlsForListing(
                listingId,
                ticketUrls
              )
            }
            action={action}
            entityId={listing.id}
            idOnMkp={listing.idOnMkp}
            iconOnlyMode={iconOnlyMode}
            cancelTo={cancelTo}
            variant={variant}
            loadingContentId={FormatContentId.LoadingListingId}
            errorContentId={FormatContentId.CouldNotFindListingId}
            searchingContentId={FormatContentId.SearchingForListingId}
          />
        );
        break;
      case ListingActionType.PredeliverExternalIds:
      case ListingActionType.RedeliverExternalIds:
        // Show a dropdown to select different upload types if iconOnlyMode is true
        if (
          iconOnlyMode &&
          (dropdownItems.length > 1 ||
            actions.includes(ListingActionType.DownloadUserDocuments))
        )
          break;
        actionElements.push(
          <LaunchUploadExternalIds
            disabled={disabled}
            key={`${listing.id}_${action}`}
            onUploadExternalIds={(
              clientConfig: PosClientConfig,
              listingId: number,
              externalIds: TicketExternalId[]
            ) =>
              new ListingClient(clientConfig).updateExternalIdsForListing(
                listingId,
                externalIds
              )
            }
            action={action}
            entityId={listing.id}
            idOnMkp={listing.idOnMkp}
            iconOnlyMode={iconOnlyMode}
            cancelTo={cancelTo}
            variant={variant}
            loadingContentId={FormatContentId.LoadingListingId}
            errorContentId={FormatContentId.CouldNotFindListingId}
            searchingContentId={FormatContentId.SearchingForListingId}
          />
        );
        break;
      case ListingActionType.PredeliverETickets:
      case ListingActionType.RedeliverETickets:
      case ListingActionType.PredeliverQRCode:
      case ListingActionType.RedeliverQRCode: {
        // Show a dropdown to select different upload types if iconOnlyMode is true
        if (
          iconOnlyMode &&
          (dropdownItems.length > 1 ||
            actions.includes(ListingActionType.DownloadUserDocuments))
        )
          break;
        const ticketType =
          action === ListingActionType.PredeliverETickets ||
          action === ListingActionType.RedeliverETickets
            ? TicketType.ETicket
            : TicketType.QRCode;

        actionElements.push(
          <LaunchUploadETickets
            disabled={disabled}
            key={`${listing.id}_${action}`}
            entityId={listing.id}
            marketplaceSaleId={listing.idOnMkp}
            iconOnlyMode={iconOnlyMode}
            cancelTo={cancelTo}
            variant={variant}
            ticketType={ticketType}
            action={action}
            onUploadETickets={(
              clientConfig: PosClientConfig,
              entityId: number,
              etickets: UserDocument[]
            ) =>
              ticketType === TicketType.ETicket
                ? new ListingClient(clientConfig).updateETicketsForListing(
                    entityId,
                    etickets
                  )
                : new ListingClient(clientConfig).updateMobileQRCodesForListing(
                    entityId,
                    etickets
                  )
            }
            onUploadDocuments={(
              client: DocumentProcessorClient,
              docUploadInfo: DocumentUploadInfo,
              file: FileParameter
            ) =>
              client.uploadDocumentForListing(
                docUploadInfo.entityId,
                ActionOutboxEntityType.Listing,
                docUploadInfo.documentType,
                docUploadInfo.blobName,
                docUploadInfo.documentId,
                docUploadInfo.contentType,
                file
              )
            }
            loadingContentId={FormatContentId.LoadingListingId}
            errorContentId={FormatContentId.CouldNotFindListingId}
            searchingContentId={FormatContentId.SearchingForListingId}
          />
        );
        break;
      }

      case ListingActionType.AddAdminHold:
      case ListingActionType.RemoveAdminHold:
        actionElements.push(
          <LaunchUpdateAdminHold
            key={`${listing.id}_${action}`}
            listingId={listing.id}
            isAddHold={action === ListingActionType.AddAdminHold}
            iconOnlyMode={iconOnlyMode}
            variant={variant}
            cancelTo={cancelTo}
            disabled={disabled}
          />
        );
        break;
      case ListingActionType.DownloadUserDocuments:
        actionElements.unshift(
          <LaunchDownloadUserDocuments
            key={`${listing.id}_${action}`}
            listingId={listing.id}
            iconOnlyMode={iconOnlyMode}
            variant={variant}
            cancelTo={cancelTo}
            disabled={disabled}
          />
        );
        break;
      default:
        break;
    }
  });

  if (dropdownItems.length > 1) {
    // Insert upload actions always at the beginning
    actionElements.unshift(
      <PosDropdown
        key="event-action-dropdown"
        trigger={
          <UploadButton
            disabled={disabled}
            variant={variant}
            iconOnlyMode={iconOnlyMode}
          />
        }
        align="end"
        triggerProps={{ disabled: disabled }}
      >
        {dropdownItems}
      </PosDropdown>
    );
  }

  if (actionElements.length) {
    return actionElements;
  } else {
    return null;
  }
};

type IconComponent = (props: Omit<PosIconProps, 'viewBox'>) => JSX.Element;

const BROADCAST_STATE_TO_ICON: Partial<Record<ListingStatus, IconComponent>> = {
  [ListingStatus.FullySold]: FullySoldIcon,
  [ListingStatus.Listed]: BroadcastIcon,
  [ListingStatus.ListingPending]: AnimatedBroadcastIcon,
  [ListingStatus.DelistingPending]: AnimatedUnbroadcastIcon,
  [ListingStatus.ListingFailed]: BroadcastFailedIcon,
  [ListingStatus.DelistingFailed]: BroadcastWithErrorIcon,
  [ListingStatus.Delisted]: UnbroadcastIcon,
};
const PENDING_QC_ICON = {
  iconComponent: PendingQCIcon,
  contentId: ContentId.PendingQC,
};

/**
 * Gets the applicable icon for a listing status (and optionally a QC state), along with its contentId
 * @param status
 * @param qcState
 * @returns
 */
export const getListingBroadcastIcon = (
  status?: ListingStatus,
  qcState?: QualityControlState | null,
  isDuplicate?: boolean,
  ignoreDuplicate?: boolean,
  isUndeliverable?: boolean | null,
  broadcastStatus?: ListingBroadcastStatus | null,
  pendingProofState?: PendingProofState | null
): {
  iconComponent: IconComponent;
  contentId?: ContentId;
} => {
  const isPendingQc = qcState === QualityControlState.Pending;
  const isListed = status === ListingStatus.Listed;

  if (isDuplicate && !ignoreDuplicate) {
    return {
      iconComponent: DuplicateOutlinedIcon,
      contentId: ContentId.DuplicatedListing,
    };
  }

  if (!isListed && isPendingQc) return PENDING_QC_ICON;

  if (!status) {
    return {
      iconComponent: UnbroadcastIcon,
      contentId: ContentId.Unbroadcasted,
    };
  }

  if (broadcastStatus === ListingBroadcastStatus.BroadcastWithError) {
    return {
      iconComponent: BroadcastWithErrorIcon,
      contentId: ContentId.SyncFailedClickToDelist,
    };
  }

  if (broadcastStatus === ListingBroadcastStatus.UnbroadcastWithError) {
    return {
      iconComponent: UnbroadcastIcon,
      contentId: ContentId.DelistedClickToRelist,
    };
  }

  const contentId = LISTING_STATUS_WITH_ACTION_TO_CID[status];
  const iconComponent = BROADCAST_STATE_TO_ICON[status] || UnbroadcastIcon;

  if (status === ListingStatus.Listed && isUndeliverable) {
    return {
      iconComponent: BroadcastWithErrorIcon,
      contentId: ContentId.Undeliverable,
    };
  }

  if (
    status === ListingStatus.Listed &&
    pendingProofState === PendingProofState.PendingProof
  ) {
    return {
      iconComponent: BroadcastWithErrorIcon,
      contentId,
    };
  }

  return { iconComponent, contentId };
};

export const getAllInPriceFromListPrice = (
  listPrice?: number | null,
  listing?: Listing | null,
  fallbackFee?: number | null,
  uiCurrency?: UiCurrency | null
) => {
  if (listPrice == null) {
    return listPrice;
  }
  const stubHubMarketplace = listing?.mkpListings?.find(
    (m) => m.mkp === Marketplace.StubHub
  );

  const fee =
    stubHubMarketplace?.sellerFee != null
      ? stubHubMarketplace.sellerFee
      : fallbackFee;

  const allInRatio = 1 / (1 - (fee ?? 0));

  return roundToPrecision(listPrice * allInRatio, uiCurrency?.dec ?? 4);
};

export const getListPriceFromAllinPrice = (
  allInPrice?: number | null,
  listing?: Listing | null,
  fallbackFee?: number | null
) => {
  if (allInPrice == null) {
    return allInPrice;
  }
  const stubHubMarketplace = listing?.mkpListings?.find(
    (m) => m.mkp === Marketplace.StubHub
  );

  const fee =
    stubHubMarketplace?.sellerFee != null
      ? stubHubMarketplace.sellerFee
      : fallbackFee;

  const listRatio = 1 - (fee ?? 0);

  return roundToPrecision(allInPrice * listRatio, 4);
};

export const getAllInPriceFromListPriceForListing = (
  listing?: Listing | null,
  uiCurrency?: UiCurrency | null
) => {
  return listing?.listPrice
    ? getAllInPriceFromListPrice(listing.listPrice, listing, null, uiCurrency)
    : listing?.allInPrice;
};

export const getListingDetailsUpdateInput = (
  listing?: Listing | null,
  uiCurrency?: UiCurrency | null,
  accountMarketplaces?: MarketplaceAccountSetting[],
  accountPricingSettings?: AutoPricingInputs | null,
  groupPricingSettings?: AutoPricingInputs | null,
  isBulkEdit?: boolean,
  listingPricingSettings?: AutoPricingInputs | null
) => {
  const marketplacePriceUpdates = listing?.mkpListings
    ? listing.mkpListings
        ?.filter((m) => m.mkp !== Marketplace.StubHub)
        ?.sort((a, b) => compareMarketplace(a.mkp, b.mkp))
        ?.map((x) => {
          const listPriceMarkup = x.markup ?? x.sellerAccountDefaultMarkup ?? 0;

          return {
            allInPrice: x.isPriceByMkp
              ? x.webPrice ?? listing?.allInPrice
              : null,
            listPrice: x.isPriceByMkp
              ? x.netProcs ?? listing?.listPrice
              : (listing?.listPrice ?? 0) * (1 + listPriceMarkup),
            priceByMarketplace: x.isPriceByMkp,
            marketplace: x.mkp,
            markup:
              x.markup != null ? roundToPrecision(x.markup * 100, 2) : null,
          } as ListingMarketplacePriceUpdateInput;
        })
    : accountMarketplaces
    ? accountMarketplaces
        ?.filter((m) => m.mkp !== Marketplace.StubHub)
        ?.sort((a, b) => compareMarketplace(a.mkp, b.mkp))
        ?.map(
          (x) =>
            ({
              allInPrice: null,
              listPrice: null,
              priceByMarketplace: false,
              marketplace: x.mkp,
            }) as ListingMarketplacePriceUpdateInput
        )
    : [];

  const quantityScoreOverrideJson = JSON.parse(
    listing?.compQtyScrAdjJson ?? 'null'
  );

  // Listing is totally null for bulk updates
  const defaultQuantityScoreOverrideJson =
    listing == null
      ? null
      : JSON.stringify({
          ...quantityScoreOverrideJson,
          quantityFilter: null,
        });

  // If SectionId or RowId filters are set, make sure we alwayws set compListingOnlyForSelectedSectionsEnabled to true
  // If a user decides to disable this setting, we need to make it known that the selected sections are not being used
  const defaultCompListingOnlyForSelectedSectionsEnabled =
    !!listing?.compSectionSettings?.sectionIdFilter?.length;

  const defaultCurrencyCode = listing?.currency ?? uiCurrency?.code;

  return {
    id: listing?.id,
    rowVersion: listing?.rowVer,
    splitType: listing?.splitType,
    hideSeatsFromMarketplace: listing?.hideSeats,
    pricerSellerUserId:
      listing == null ? EMPTY_SELLER_USER_ID : listing?.pricerId,
    currencyCode: defaultCurrencyCode,

    inHandDate: listing?.inHandAt,
    isNoFulfill: listing?.isNoFulfill,
    enableNativeSync: listing?.enableNativeSync,

    delivType: listing?.delivType,
    seatTraits: listing?.seatTraits,

    section: listing?.seating?.section,
    sectionId: listing?.seating?.sectionId,
    row: listing?.seating?.row,
    rowId: listing?.seating?.rowId,

    listPrice: listing?.listPrice,
    allInPrice: getAllInPriceFromListPriceForListing(listing, uiCurrency),
    faceValue: listing?.faceValue?.amt,
    manualMaxDisplayQuantity: listing?.manMaxDispQty ?? listing?.maxDispQty,

    marketplacePriceUpdates,

    marketplaceSeatOverrides:
      listing?.mkpListings
        ?.filter((m) => m.mkp && m.mkp !== Marketplace.Offline)
        ?.sort((a, b) => compareMarketplace(a.mkp, b.mkp))
        ?.map((m) => ({
          marketplace: m.mkp,
          overrideSection: m.overrideSection,
          overrideRow: m.overrideRow,
          overrideSeats: m.overrideSeats,
          overrideRowId: m.overrideRowId,
          overrideSectionId: m.overrideSectionId,
        })) ?? [],

    tags: listing?.tags,
    internalNotes: listing?.privNotes,

    netProceedsFloor: listing == null ? -1 : listing?.procsFloor,
    netProceedsCeiling: listing == null ? -1 : listing?.procsCeil,

    autoPricingEnabled: listing?.isAutoPrc ?? (isBulkEdit ? true : false),

    compListingFloor:
      listingPricingSettings?.compListingFloor ??
      listing?.compFloor ??
      groupPricingSettings?.compListingFloor ??
      accountPricingSettings?.compListingFloor ??
      1,
    compListingCeiling:
      listingPricingSettings?.compListingCeiling ??
      listing?.compCeil ??
      groupPricingSettings?.compListingCeiling ??
      accountPricingSettings?.compListingCeiling ??
      6,
    compListingMode:
      listingPricingSettings?.compListingMode ??
      listing?.compMode ??
      groupPricingSettings?.compListingMode ??
      accountPricingSettings?.compListingMode ??
      AutoPricingCompListingMode.QualityScore,
    compListingOnlyForSameZoneEnabled:
      listingPricingSettings?.compListingOnlyForSameZoneEnabled ??
      listing?.isCompForSameZone ??
      groupPricingSettings?.compListingOnlyForSameZoneEnabled ??
      accountPricingSettings?.compListingOnlyForSameZoneEnabled ??
      !defaultCompListingOnlyForSelectedSectionsEnabled, // Default to false if we have selected sections enabled
    compListingOnlyForSelectedSectionsEnabled:
      listingPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
      listing?.isCompSelectedSections ??
      groupPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
      accountPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
      defaultCompListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory:
      listingPricingSettings?.compListingExcludeFanInventory ??
      listing?.compExclFanInv ??
      groupPricingSettings?.compListingExcludeFanInventory ??
      accountPricingSettings?.compListingExcludeFanInventory ??
      null,
    compListingExcludeDefects:
      listingPricingSettings?.compListingExcludeDefects ??
      listing?.compExclDefects ??
      groupPricingSettings?.compListingExcludeDefects ??
      accountPricingSettings?.compListingExcludeDefects ??
      null,
    compListingQuantityScoreAdjustmentEnabled:
      listingPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
      listing?.isCompQtyScrAdj ??
      groupPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
      accountPricingSettings?.compListingQuantityScoreAdjustmentEnabled,
    compListingQuantityScoreAdjustmentOverrideJson:
      listingPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson ??
      listing?.compQtyScrAdjJson ??
      groupPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson ??
      defaultQuantityScoreOverrideJson,
    compListingSelectedSectionSettings:
      listingPricingSettings?.compListingSelectedSectionSettings ??
      listing?.compSectionSettings ??
      groupPricingSettings?.compListingSelectedSectionSettings ??
      accountPricingSettings?.compListingSelectedSectionSettings,
    compListingQuantityFilters:
      listingPricingSettings?.compListingQuantityFilters ??
      groupPricingSettings?.compListingQuantityFilters ??
      accountPricingSettings?.compListingQuantityFilters,
    undercutMode:
      listingPricingSettings?.undercutMode ??
      listing?.undMode ??
      groupPricingSettings?.undercutMode ??
      accountPricingSettings?.undercutMode ??
      AutoPricingUndercutMode.Simple,
    undercutAbsoluteAmount:
      listingPricingSettings?.undercutAbsoluteAmount ??
      listing?.undAbsAmt ??
      groupPricingSettings?.undercutAbsoluteAmount ??
      accountPricingSettings?.undercutAbsoluteAmount ??
      0.01,
    undercutRelativeAmount:
      listingPricingSettings?.undercutRelativeAmount ??
      listing?.undRelAmt ??
      groupPricingSettings?.undercutRelativeAmount ??
      accountPricingSettings?.undercutRelativeAmount ??
      0,
    circuitBreakerMaxDiscountVelocityPercent:
      listingPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
      listing?.cirBrMaxDiscVelocPercent ??
      groupPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
      accountPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
      0.8,
    circuitBreakerMaxDiscountVelocityTicksInHours:
      listingPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
      listing?.cirBrMaxDiscVelocTicksInHrs ??
      groupPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
      accountPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
      6,
    circuitBreakerMinCompListingCount:
      listingPricingSettings?.circuitBreakerMinCompListingCount ??
      listing?.cirBrMinCompCount ??
      groupPricingSettings?.circuitBreakerMinCompListingCount ??
      accountPricingSettings?.circuitBreakerMinCompListingCount ??
      10,
    circuitBreakerRelativeCeiling:
      listingPricingSettings?.circuitBreakerRelativeCeiling ??
      listing?.cirBrRelCeil ??
      groupPricingSettings?.circuitBreakerRelativeCeiling ??
      accountPricingSettings?.circuitBreakerRelativeCeiling ??
      100,
    circuitBreakerRelativeFloor:
      listingPricingSettings?.circuitBreakerRelativeFloor ??
      listing?.cirBrRelFloor ??
      groupPricingSettings?.circuitBreakerRelativeFloor ??
      accountPricingSettings?.circuitBreakerRelativeFloor ??
      1,
    outlierMode:
      listingPricingSettings?.outlierMode ??
      listing?.outMode ??
      groupPricingSettings?.outlierMode ??
      accountPricingSettings?.outlierMode ??
      AutoPricingOutlierMode.StandardDeviations,
    outlierStandardDeviations:
      listingPricingSettings?.outlierStandardDeviations ??
      listing?.outlStdDev ??
      groupPricingSettings?.outlierStandardDeviations ??
      accountPricingSettings?.outlierStandardDeviations ??
      3,
    outlierKthLowestLimit:
      listingPricingSettings?.outlierKthLowestLimit ??
      listing?.outKthLowestLim ??
      groupPricingSettings?.outlierKthLowestLimit ??
      accountPricingSettings?.outlierKthLowestLimit,
    outlierKthLowestLimitRelativeSpacing:
      listingPricingSettings?.outlierKthLowestLimitRelativeSpacing ??
      listing?.outKthLowestLimRelSpc ??
      groupPricingSettings?.outlierKthLowestLimitRelativeSpacing ??
      accountPricingSettings?.outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing:
      listingPricingSettings?.outlierKthLowestLimitAbsoluteSpacing ??
      listing?.outKthLowestLimAbsSpc ??
      groupPricingSettings?.outlierKthLowestLimitAbsoluteSpacing ??
      accountPricingSettings?.outlierKthLowestLimitAbsoluteSpacing,

    adminHoldNotes: listing?.adminHoldNotes,
    adminHoldExpirationDate: listing?.adminHoldExpiredOn,
    ticketTypeRules: listing?.tktTypeRules,

    // If listing id is null, we're creating the default input for bulk edit - default to skipping everything
    skipAdvancePricing: !listing?.id,
    skipAutoPricing: !listing?.id,
    skipCompListing: !listing?.id,
    skipPriceProtection: !listing?.id,
    skipUndercut: !listing?.id,
  } as ListingDetailsUpdateInput;
};

export const isListingDetailsInputChanged = (
  input: ListingDetailsUpdateInput,
  initial?: ListingDetailsUpdateInput,
  tagsMetadata?: Tag[] | null
) => {
  const hasChanged =
    initial?.splitType !== input.splitType ||
    initial?.hideSeatsFromMarketplace !== input.hideSeatsFromMarketplace ||
    initial?.delivType !== input.delivType ||
    !isEqual(initial?.seatTraits, input.seatTraits) ||
    initial?.isNoFulfill !== input.isNoFulfill ||
    initial?.inHandDate !== input.inHandDate ||
    initial?.allInPrice !== input.allInPrice ||
    initial?.listPrice !== input.listPrice ||
    !isEqual(
      initial?.marketplacePriceUpdates,
      input?.marketplacePriceUpdates
    ) ||
    !isEqual(
      initial?.marketplaceSeatOverrides,
      input?.marketplaceSeatOverrides
    ) ||
    initial?.pricerSellerUserId !== input.pricerSellerUserId ||
    initial?.autoPricingEnabled !== input.autoPricingEnabled ||
    initial?.netProceedsFloor !== input.netProceedsFloor ||
    initial?.netProceedsCeiling !== input.netProceedsCeiling ||
    initial?.compListingMode !== input.compListingMode ||
    initial?.compListingFloor !== input.compListingFloor ||
    initial?.compListingCeiling !== input.compListingCeiling ||
    initial?.compListingQuantityScoreAdjustmentEnabled !==
      input.compListingQuantityScoreAdjustmentEnabled ||
    initial?.compListingQuantityScoreAdjustmentOverrideJson !==
      input.compListingQuantityScoreAdjustmentOverrideJson ||
    initial?.compListingOnlyForSameZoneEnabled !==
      input.compListingOnlyForSameZoneEnabled ||
    initial?.compListingOnlyForSelectedSectionsEnabled !==
      input.compListingOnlyForSelectedSectionsEnabled ||
    initial?.compListingExcludeFanInventory !==
      input.compListingExcludeFanInventory ||
    initial?.compListingExcludeDefects !== input.compListingExcludeDefects ||
    !isEqual(
      initial?.compListingSelectedSectionSettings,
      input?.compListingSelectedSectionSettings
    ) ||
    initial?.undercutMode !== input.undercutMode ||
    initial?.undercutAbsoluteAmount !== input.undercutAbsoluteAmount ||
    initial?.undercutRelativeAmount !== input.undercutRelativeAmount ||
    initial?.circuitBreakerMaxDiscountVelocityPercent !==
      input.circuitBreakerMaxDiscountVelocityPercent ||
    initial?.circuitBreakerMaxDiscountVelocityTicksInHours !==
      input.circuitBreakerMaxDiscountVelocityTicksInHours ||
    initial?.circuitBreakerMinCompListingCount !==
      input.circuitBreakerMinCompListingCount ||
    initial?.circuitBreakerRelativeCeiling !==
      input.circuitBreakerRelativeCeiling ||
    initial?.circuitBreakerRelativeFloor !==
      input.circuitBreakerRelativeFloor ||
    initial?.outlierMode !== input.outlierMode ||
    initial?.outlierStandardDeviations !== input.outlierStandardDeviations ||
    initial?.adminHoldNotes !== input.adminHoldNotes ||
    initial?.enableNativeSync !== input.enableNativeSync ||
    tagHasChanges(
      (initial?.tags ?? []) as Tag[],
      input.tags,
      tagsMetadata ?? []
    ) ||
    initial?.internalNotes !== input.internalNotes ||
    !isEqual(initial?.ticketTypeRules, input?.ticketTypeRules) ||
    initial?.manualMaxDisplayQuantity !== input.manualMaxDisplayQuantity ||
    initial?.section != input.section ||
    initial?.row != input.row ||
    initial?.rowId != input.rowId ||
    initial?.sectionId != input.sectionId ||
    initial?.allInPrice != input.allInPrice ||
    initial?.faceValue != input.faceValue ||
    initial?.buyerUserId != input.buyerUserId;

  return hasChanged;
};

export const onPriceFieldChange = <T extends FieldValues>(
  newValue: number,
  field: Path<T>,
  fieldPrice: number | null,
  otherField: Path<T>,
  otherFieldPrice: number | null,
  /* This multiplier is used to get the other value due to this field value's newValue */
  otherToFieldRatio: number,
  clearErrors: UseFormClearErrors<T>,
  setValue: UseFormSetValue<T>
): void => {
  if (Number.isNaN(newValue)) {
    newValue = 0;
  } else if (newValue > Infinity) {
    newValue = Number.MAX_VALUE;
  }

  if (newValue >= 0 && newValue <= Number.MAX_VALUE) {
    clearErrors(field);
    clearErrors(otherField);

    if (newValue !== fieldPrice) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValue(field, newValue as any);
    }

    const newOtherValue = newValue * otherToFieldRatio;
    if (newOtherValue !== otherFieldPrice) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValue(otherField, newOtherValue as any);
    }
  }
};

export const isSuspiciousPriceChange = (
  newListPrice: number | null | undefined,
  oldListPrice: number | null | undefined
) => {
  let result = false;
  if (oldListPrice && newListPrice && oldListPrice !== newListPrice) {
    // if both of these are non-zeros, check their change
    if (isSuspiciousPriceChangeHelper(newListPrice, oldListPrice)) {
      result = true;
    }
  }

  return result;
};

const isSuspiciousPriceChangeHelper = (
  newPrice: number | null | undefined,
  oldPrice: number | null | undefined
) => {
  if (!newPrice || !oldPrice) {
    // If either are null or 0, return false, zero has it's own validations
    return false;
  }

  const diff = Math.abs(newPrice - oldPrice);
  const percentChange = (diff / oldPrice) * 100;

  return percentChange >= SuspiciousPriceChangeThreshold;
};

export const getStubhubEventWithPricingUrl = (
  eventId?: number | null,
  isInternationalEvent?: boolean
) => {
  const baseUrl = isInternationalEvent
    ? REACT_APP_VIAGOGO_BASE_URL
    : REACT_APP_STUBHUB_BASE_URL;

  if (eventId) {
    // If we could not get anything, just go to the event vanilla page
    return new URL(`/E-${eventId}?multi=true`, baseUrl);
  } else {
    return new URL(baseUrl ?? '.');
  }
};

export const getListingGroupForListing = (
  viagogoEventId?: number | null,
  ltGrpId?: string | null,
  events?: EventWithData[] | null
) => {
  if (!ltGrpId || !viagogoEventId) {
    return null;
  }

  const eventData = events?.find((ev) => ev.event.viagId === viagogoEventId);
  const listingGroup = eventData?.listings?.find(
    (l) => l.isLtGrp && l.ltGrpId === ltGrpId
  ) as ListingGroup;

  return listingGroup;
};

export const getListingGroupItemCount = (lg: ListingGroup) => {
  let count = lg.groupItems.length ?? 0;

  lg.groupItems.forEach((l) => {
    if (l.isLtGrp) {
      count--; // take out the count that is this listing-group and add in the sub-counts instead
      count += getListingGroupItemCount(l as ListingGroup);
    }
  });

  return count;
};

export const isListingGroupLeader = (listing?: Listing | null) => {
  return (listing as ListingGroup)?.groupType === GroupType.Leader;
};

export const DefaultQuantityFilterOption = 'Default';

export const getMinQuantityOptions = () =>
  [
    DefaultQuantityFilterOption,
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
  ].reduce(
    (r, c) => {
      r[c] = c;
      return r;
    },
    {} as Record<string, string>
  );

export const getCompQuantitiesOptions = () => {
  return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'].reduce(
    (r, c) => {
      r[c] = c;
      return r;
    },
    {} as Record<string, string>
  );
};
