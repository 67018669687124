import { ListingDetailDataField } from 'src/WebApiController';

export const enum ListingTab {
  Broadcast = 'broadcast',
  Tickets = 'tickets',
  Pricing = 'pricing',
  Sales = 'sales',
  Purchase = 'purchase',
  Tags = 'tags',
  AutoPricing = 'autopricing',
}

export const ListingTabOrder = [
  ListingTab.Broadcast,
  ListingTab.Tickets,
  ListingTab.Pricing,
  ListingTab.AutoPricing,
  ListingTab.Sales,
  ListingTab.Purchase,
  ListingTab.Tags,
];

export const getListingDataFieldsForTab = (tab: ListingTab) => {
  switch (tab) {
    case ListingTab.AutoPricing:
      return [ListingDetailDataField.AutoPricing];
    case ListingTab.Broadcast:
      return [ListingDetailDataField.Basic];
    case ListingTab.Pricing:
      return [ListingDetailDataField.Pricing];
    case ListingTab.Purchase:
      return [ListingDetailDataField.Purchase];
    case ListingTab.Sales:
      return [ListingDetailDataField.Sales];
    case ListingTab.Tags:
      return [ListingDetailDataField.Tags];
    case ListingTab.Tickets:
      return [ListingDetailDataField.Tickets];

    default:
      console.log('Warning: unhandled listing tab for data: ' + tab);
      return [] as string[]; // we if we don't recognize the tab, return empty so we'll get all data
  }
};
