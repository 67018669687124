import clsx from 'clsx';
import { ReactNode } from 'react';
import * as styles from './PosPill.css';

export enum PosPillVariant {
  Brand = 'Brand',
  LightGreen = 'LightGreen',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
  Info = 'Info',
  Neutral = 'Neutral',
}

const getVariantClassName = (variant?: PosPillVariant): string => {
  switch (variant) {
    case PosPillVariant.Success:
      return styles.successVariant;
    case PosPillVariant.Warning:
      return styles.warningVariant;
    case PosPillVariant.Info:
      return styles.infoVariant;
    case PosPillVariant.Neutral:
      return styles.neutralVariant;
    case PosPillVariant.Error:
      return styles.errorVariant;
    case PosPillVariant.LightGreen:
      return styles.lightGreenVariant;
    case PosPillVariant.Brand:
    default:
      return styles.brandVariant;
  }
};

interface PosPillProps {
  className?: string;
  children?: ReactNode;
  variant?: PosPillVariant;
}

export const PosPill = ({ children, variant }: PosPillProps) => {
  return (
    <div className={clsx(styles.wrapper, getVariantClassName(variant))}>
      <span className={styles.defaultStateLabel}>{children}</span>
    </div>
  );
};
