import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { vars } from 'src/core/themes';
import { Button, Switch } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import {
  FormatOption,
  FormatOptionEntries,
  FormatOptionPill,
  LISTING_TABLE_COLUMN_DEFAULT_PRECISION,
} from 'src/modals/EditTableColumns';
import { DeleteIcon, EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { getColumnConfigById } from 'src/utils/columns/columnUtils';
import { CustomEventsColumn } from 'src/utils/columns/events/eventsCustomColumnUtils.types';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID } from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { ListingTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { CustomMarketplacePaymentsColumn } from 'src/utils/columns/payments/paymentsCustomColumnUtils.types';
import { CustomPurchasesColumn } from 'src/utils/columns/purchases/purchasesCustomColumnUtils.types';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { ReportGroupBy, Tag, UserSetting } from 'src/WebApiController';

import { ColumnsSection } from './ColumnsSectionV2';
import { useAdaptedColumns } from './hooks/useAdaptedColumns';
import * as styles from './TableColumns.css';
import { TableCustomColumnModal } from './TableCustomColumnModal';

type TableColumnsInputProps<T> = {
  // Override of required column ids - additive to the default required columns
  requiredColumns?: string[];
  userColumnSelection: string[];
  onUserColumnSelectionChange: (newSelection: string[]) => void;
  userColumnSorting: string[];
  onUserColumnSortingChange: (newSorting: string[]) => void;
  userEditableColumns?: string[];
  onUserEditableColumnsChange?: (newEditableColumns: string[]) => void;
  customColumns: T[];
  onCustomColumnsChange?: (newCustomColumns: T[]) => void;
  reportingTags?: Tag[];
  enableConfigureEditability?: boolean;
  sectionType?: SectionType;
  groupBy?: ReportGroupBy;
  // Only used with SectionType.Listings
  // We only want to show this column for setting if it's visible in the current view
  showTicketClassColor?: boolean;
  style?: React.CSSProperties;
  // Only supported for Purchase for now, used for filtering visible columns
  viewMode?: string | null;
};

export function TableColumnsInput<
  T extends
    | CustomListingColumn
    | CustomSalesColumn
    | CustomEventsColumn
    | CustomPurchasesColumn
    | CustomMarketplacePaymentsColumn,
>({
  requiredColumns,
  userColumnSelection,
  onUserColumnSelectionChange,
  userColumnSorting,
  onUserColumnSortingChange,
  userEditableColumns,
  onUserEditableColumnsChange,
  customColumns,
  onCustomColumnsChange,
  reportingTags,
  enableConfigureEditability,
  sectionType = SectionType.Listings,
  groupBy,
  showTicketClassColor,
  viewMode,
  style,
}: TableColumnsInputProps<T>) {
  const [isCustomColumnModalOpen, toggleCustomColumnModal] = useToggle(false);
  const [customColumnToEditId, setCustomColumnToEditId] = useState<string>();
  const [customColumnToDeleteId, setCustomColumnToDeleteId] =
    useState<string>();
  const [highlightedItemIndex, setHighlightedItemIndex] = useState<number>(-1);

  const deleteCustomColumnConfirmDialog = useBasicDialog();

  const columnNumberPrecisionSettingId = {
    [SectionType.Events]: UserSetting.EventsColumnNumberPrecision,
    [SectionType.Listings]: UserSetting.InventoryColumnNumberPrecision,
    [SectionType.ListingsFlattened]:
      UserSetting.InventoryFlattenedColumnNumberPrecision,
    [SectionType.ListingsReport]: UserSetting.InventoryColumnNumberPrecision,
    [SectionType.InventorySideTable]:
      UserSetting.InventorySideTableColumnNumberPrecision,
    [SectionType.Sales]: UserSetting.SaleColumnNumberPrecision,
    [SectionType.SalesFlattened]:
      UserSetting.SaleFlattenedColumnNumberPrecision,
    [SectionType.SalesReport]: UserSetting.SaleColumnNumberPrecision,
    [SectionType.SalesSideTable]:
      UserSetting.SalesSideTableColumnNumberPrecision,
    [SectionType.Purchases]: UserSetting.PurchaseColumnNumberPrecision,
    [SectionType.PurchaseEvent]: UserSetting.PurchaseEventColumnNumberPrecision,
    [SectionType.PurchaseSideTable]:
      UserSetting.PurchaseSideTableColumnNumberPrecision,
    [SectionType.MarketplacePaymentsTable]:
      UserSetting.MarketplacePaymentsColumnNumberPrecision,
  }[sectionType];

  const { adaptedColumns, flattenColumns } = useAdaptedColumns({
    columns: userColumnSorting,
    sectionType,
    viewMode,
  });

  const {
    value: storedColumnNumberPrecisions = {},
    setUserSetting: setNumberPrecisionSetting,
  } = useServerUserSetting<FormatOptionEntries>({
    id: columnNumberPrecisionSettingId,
  });

  const updateNumberPrecisionSetting = useCallback(
    (id: string, precision: FormatOption) => {
      setNumberPrecisionSetting({
        ...storedColumnNumberPrecisions,
        [id]: precision,
      });
    },
    [setNumberPrecisionSetting, storedColumnNumberPrecisions]
  );

  const isTagColumn = useCallback(
    (tagName: string) => (reportingTags ?? []).some((t) => t.key === tagName),
    [reportingTags]
  );

  const moveColumnUnderLastEnabled = useCallback(
    (id: string) => {
      let updatedItems = [...adaptedColumns];
      let currentPosition: number | null = null;
      let lastEnabledPosition: number | null = null;

      // Check if the column is part of a group
      const activeGroupIndex = adaptedColumns.findIndex(
        (item) =>
          item.children &&
          item.children.length > 0 &&
          item.children.includes(id)
      );

      if (activeGroupIndex !== -1) {
        // The item is part of a group
        const group = updatedItems[activeGroupIndex];
        if (group.children) {
          currentPosition = group.children.indexOf(id);

          lastEnabledPosition = group.children.findLastIndex((col) =>
            userColumnSelection.includes(col)
          );

          if (
            currentPosition === -1 ||
            lastEnabledPosition === -1 ||
            currentPosition === lastEnabledPosition
          ) {
            return;
          }

          const newPosition =
            currentPosition < lastEnabledPosition
              ? lastEnabledPosition
              : lastEnabledPosition + 1;

          const updatedGroup = arrayMove(
            group.children,
            currentPosition,
            newPosition
          );

          updatedItems[activeGroupIndex].children = updatedGroup;
        }
      } else {
        // The item is a top-level item (not in a group)
        currentPosition = adaptedColumns.findIndex((item) => item.id === id);

        lastEnabledPosition = adaptedColumns.findLastIndex((item) =>
          userColumnSelection.includes(item.id)
        );

        if (
          currentPosition === -1 ||
          lastEnabledPosition === -1 ||
          currentPosition === lastEnabledPosition
        ) {
          return;
        }

        const newPosition =
          currentPosition < lastEnabledPosition
            ? lastEnabledPosition
            : lastEnabledPosition + 1;

        updatedItems = arrayMove(adaptedColumns, currentPosition, newPosition);
      }

      const flattenedColumns = flattenColumns(updatedItems);

      // Flatten the updated structure and apply the changes
      onUserColumnSortingChange(flattenedColumns);

      // Highlight the moved item
      const flatIndex = flattenedColumns.indexOf(id);
      setHighlightedItemIndex(flatIndex);

      // Reset the highlight after a timeout
      setTimeout(() => {
        setHighlightedItemIndex(-1);
      }, 1100);
    },
    [
      onUserColumnSortingChange,
      userColumnSelection,
      adaptedColumns,
      flattenColumns,
    ]
  );

  const addColumn = useCallback(
    (id: string) => {
      moveColumnUnderLastEnabled(id);
      onUserColumnSelectionChange([...userColumnSelection, id]);
    },
    [
      moveColumnUnderLastEnabled,
      onUserColumnSelectionChange,
      userColumnSelection,
    ]
  );

  const removeColumn = useCallback(
    (id: string) => {
      moveColumnUnderLastEnabled(id);
      onUserColumnSelectionChange(
        userColumnSelection?.filter((cId) => cId !== id)
      );
    },
    [
      moveColumnUnderLastEnabled,
      onUserColumnSelectionChange,
      userColumnSelection,
    ]
  );

  const deleteCustomColumn = useCallback(
    (id: string) => {
      removeColumn(id);
      const remainingColumns = customColumns.filter(
        (c) => (c as T & { id: string }).id !== id
      ) as T[];
      onCustomColumnsChange?.(remainingColumns);
    },
    [customColumns, removeColumn, onCustomColumnsChange]
  );

  const editCustomColumn = useCallback(
    (id: string) => {
      if (!isCustomColumnModalOpen) {
        setCustomColumnToEditId(id);
        toggleCustomColumnModal();
      }
    },
    [isCustomColumnModalOpen, toggleCustomColumnModal]
  );

  const renderColumnRowActions = useCallback(
    (id: string /*isColumnAdded?: boolean*/) => {
      const personalization = getColumnConfigById({
        id,
        customColumns,
        sectionType,
        reportingTags,
      }).personalization;

      const {
        isNumeric,
        isDateTime,
        isCurrency,
        isPercent,
        isEditabilityConfigurable,
        isInteger,
      } = personalization;

      let isRequired = personalization.isRequired;
      if (groupBy) {
        if (sectionType === SectionType.ListingsReport) {
          isRequired = GROUP_BY_TO_PRIMARY_COLUMN_ID[groupBy] === id;
        }
      }
      if (requiredColumns?.includes(id)) {
        isRequired = true;
      }

      const isCustom = customColumns.find(
        (c) => (c as T & { id: string }).id === id
      );

      const toggleColumn = (isChecked: boolean) => {
        if (isChecked) {
          if (!userColumnSelection.includes(id)) {
            addColumn(id);
          }
        } else {
          if (!isRequired) removeColumn(id);
        }
      };

      return (
        <div
          className={styles.actions}
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
        >
          {isCustom && (
            <div className={styles.badge}>
              <Content id={ContentId.Custom} />
            </div>
          )}
          {isTagColumn(id) && (
            <div className={styles.badge}>
              <Content id={ContentId.Tag} />
            </div>
          )}
          {isEditabilityConfigurable && enableConfigureEditability ? (
            <div
              className={styles.editableSwitchContainer}
              style={
                userEditableColumns!.includes(id)
                  ? {}
                  : { color: vars.color.textDisabled }
              }
            >
              <Checkbox
                checked={userEditableColumns!.includes(id)}
                onChange={(e) => {
                  e.stopPropagation();
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    onUserEditableColumnsChange?.([
                      ...userEditableColumns!,
                      id,
                    ]);
                  } else {
                    onUserEditableColumnsChange?.(
                      userEditableColumns!.filter((cId) => cId !== id)
                    );
                  }
                }}
                label={
                  userEditableColumns!.includes(id) ? (
                    <Content id={ContentId.Editable} />
                  ) : (
                    <Content id={ContentId.Readonly} />
                  )
                }
                labelPosition="right"
              />
            </div>
          ) : null}
          {isNumeric || isDateTime ? (
            <FormatOptionPill
              id={id}
              onSave={updateNumberPrecisionSetting}
              option={
                storedColumnNumberPrecisions[id] ??
                LISTING_TABLE_COLUMN_DEFAULT_PRECISION[id] ??
                (isInteger ? FormatOption.Whole : undefined)
              }
              flags={{ isDateTime, isCurrency, isPercent }}
              isSelectable={true}
              isSelected
              sectionType={sectionType}
            />
          ) : null}

          {isCustom && onCustomColumnsChange && (
            <>
              <EditIcon
                fill={IconsFill.textPrimary}
                withHoverEffect
                onClick={() => {
                  editCustomColumn(id);
                }}
              />
              <DeleteIcon
                fill={IconsFill.textPrimary}
                withHoverEffect
                onClick={() => {
                  setCustomColumnToDeleteId(id);
                  deleteCustomColumnConfirmDialog.launchDialog();
                }}
              />
            </>
          )}

          <Switch
            checked={userColumnSelection.includes(id)}
            disabled={isRequired && userColumnSelection.includes(id)}
            onCheckedChange={toggleColumn}
          />
        </div>
      );
    },
    [
      addColumn,
      customColumns,
      deleteCustomColumnConfirmDialog,
      editCustomColumn,
      enableConfigureEditability,
      groupBy,
      isTagColumn,
      onCustomColumnsChange,
      onUserEditableColumnsChange,
      removeColumn,
      reportingTags,
      requiredColumns,
      sectionType,
      storedColumnNumberPrecisions,
      updateNumberPrecisionSetting,
      userColumnSelection,
      userEditableColumns,
    ]
  );

  const renderToggleButton = useCallback(
    (id: string) => renderColumnRowActions(id),
    [renderColumnRowActions]
  );

  /**
   * Handler that sets the column order based on the drag drop event
   */
  const onDragEndHandler = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over) return;

      const activeId = active.id as string;
      const overId = over.id as string;

      // Determine if the dragged item is in a group
      const activeGroup = adaptedColumns.findIndex(
        (item) =>
          item.children &&
          item.children.length > 0 &&
          item.children.includes(activeId)
      );
      const overGroup = adaptedColumns.findIndex(
        (item) =>
          item.children &&
          item.children.length > 0 &&
          item.children.includes(overId)
      );

      let updatedItems = [...adaptedColumns];

      if (activeGroup !== -1 && activeGroup === overGroup) {
        const group = updatedItems[activeGroup];
        if (group.children) {
          // Move within the same group
          const updatedGroup = arrayMove(
            group.children,
            group.children.indexOf(activeId),
            group.children.indexOf(overId)
          );

          updatedItems[activeGroup].children = updatedGroup;
        }
        // Update items with the new order
      } else if (activeGroup === -1 && overGroup === -1) {
        // Move top-level items (including entire groups)
        updatedItems = arrayMove(
          adaptedColumns,
          adaptedColumns.findIndex((item) => item.id === activeId),
          adaptedColumns.findIndex((item) => item.id === overId)
        );
      }
      // Update items with the new order
      onUserColumnSortingChange(flattenColumns(updatedItems));
    },
    [onUserColumnSortingChange, adaptedColumns, flattenColumns]
  );

  const openAddColumnModal = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (!isCustomColumnModalOpen) {
        setCustomColumnToEditId(undefined);
        toggleCustomColumnModal();
      }
    },
    [isCustomColumnModalOpen, toggleCustomColumnModal]
  );

  const hiddenItemIds = useMemo(() => {
    if (showTicketClassColor) {
      return [];
    }

    return [ListingTableColumnId.TicketClassColor.toString()];
  }, [showTicketClassColor]);

  const shouldHideItem = useCallback(
    (id: string) => {
      if (hiddenItemIds?.includes(id)) {
        return true;
      }

      const { visibleViewModes } = getColumnConfigById({
        id,
        customColumns,
        sectionType,
        reportingTags,
      }).personalization;

      if (
        visibleViewModes.length > 0 &&
        viewMode &&
        !visibleViewModes.includes(viewMode)
      ) {
        return true;
      }

      return false;
    },
    [customColumns, hiddenItemIds, reportingTags, sectionType, viewMode]
  );

  return (
    <>
      <div className={styles.bodyContainer} style={style}>
        <ColumnsSection
          items={userColumnSorting}
          renderActions={renderToggleButton}
          onDragEnd={onDragEndHandler}
          sectionType={sectionType}
          viewMode={viewMode}
          highlightIndex={highlightedItemIndex}
          shouldHideItem={shouldHideItem}
        />
        {onCustomColumnsChange && (
          <Button
            variant="text"
            style={{ width: 'fit-content' }}
            onClick={openAddColumnModal}
          >
            <PlusIcon size={vars.iconSize.s} fill={IconsFill.textBrand} />
            <Content id={ContentId.AddCustomColumn} />
          </Button>
        )}
      </div>
      {isCustomColumnModalOpen && (
        <TableCustomColumnModal
          columnToEditId={customColumnToEditId}
          onClose={toggleCustomColumnModal}
          onSave={(newColumns: T[]) => {
            onCustomColumnsChange?.(newColumns);
          }}
          sectionType={sectionType}
        />
      )}
      <ConfirmDialog
        {...deleteCustomColumnConfirmDialog.dialogProps}
        headerText={<Content id={ContentId.DeleteCustomColumn} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={() => {
          if (customColumnToDeleteId) {
            deleteCustomColumn(customColumnToDeleteId);
            setCustomColumnToDeleteId(undefined);
          }

          deleteCustomColumnConfirmDialog.closeDialog();
        }}
        onCancel={() => deleteCustomColumnConfirmDialog.closeDialog()}
      />
    </>
  );
}
