import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { ROW_COMP_SETTING_TO_CID } from 'src/utils/constants/contentIdMaps';
import { ListingDetailsAutoPricingSectionUpdatesV2 } from 'src/WebApiController';

import * as styles from '../AutoPricingSettings.css';
import { PricingSetting } from '../PricingSetting';

export const RowComp = () => {
  const { watch, setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();
  const rowCompOffset = watch('rowCompOffset');

  return (
    <PricingSetting
      header={<Content id={ContentId.RowComps} />}
      detail={
        <Stack direction="row" gap="xl" alignItems="center">
          {Object.entries(ROW_COMP_SETTING_TO_CID).map(
            ([option, contentId]) => {
              return (
                <label
                  key={option}
                  className={`${styles.radioOption} ${
                    rowCompOffset === parseInt(option) ? styles.selected : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={option}
                    checked={rowCompOffset === parseInt(option)}
                    onChange={(e) =>
                      setValue('rowCompOffset', parseInt(e.target.value))
                    }
                    className={styles.hiddenRadio}
                  />
                  <Content id={contentId} />
                </label>
              );
            }
          )}
        </Stack>
      }
      hasChange={rowCompOffset != 0}
      hasConflict={false}
      onReset={() => setValue('rowCompOffset', 0)}
    />
  );
};
