// Please keep this enum in alphabetical order
export enum FormatContentId {
  ActivityLogChangeFromTo = 'cid$#3070963#',
  ActivityLogChangeFrom = 'cid$#3201701#',
  ActivityLogChangeTo = 'cid$#3201702#',
  AddAnother = 'cid$#3408221#',
  AndXMore = 'cid$#2982718#',
  ApplyFilterToReduceCountsToSeeData = 'cid$#3543237#',
  ApplyToAllEventsConfirmation = 'cid$#3361820#',
  BulkEditMaxItemPreviewDisplayMessage = 'cid$#____BulkEditMaxItemPreviewDisplayMessage#',
  BulkEditSkippedItemsPrompt = 'cid$#____BulkEditSkippedItemsPrompt#',
  ClassifyAs = 'cid$#3373032#',
  CopyOf = 'cid$#3512120#',
  CouldNotFindListingId = 'cid$#3037606#',
  CouldNotFindOrderId = 'cid$#3037607#',
  CouldNotFindSaleId = 'cid$#2977369#',
  DaysBeforeEvent = 'cid$#3266231#',
  DuplicateListingDetails = 'cid$#3543238#',
  DuplicatingSeats = 'cid$#2975370#',
  EmailAddressFrom = 'cid$#3261278#',
  EmailAddressTo = 'cid$#3261279#',
  EmptyContent = '' /* Used for useContent with an optional content-id */,
  EventsSelected = 'cid$#3361821#',
  ExtraBarcodes = 'cid$#2972953#',
  ExtraTicketIds = 'cid$#3389420#',
  ExtraTicketUrls = 'cid$#3389421#',
  Filled = 'cid$#2971998#',
  FirstTimeVenueEventSetup = 'cid$#____FirstTimeVenueEventSetup#',
  FulfilledExternalMarketplace = 'cid$#3070964#',
  GoTo = 'cid$#3172497#',
  HurdleN = 'cid$#____HurdleN#',
  InNDays = 'cid$#2993455#',
  InNHours = 'cid$#3008672#',
  InNMinutes = 'cid$#3008673#',
  InvalidTagCharacter = 'cid$#3373033#',
  ItemsSelected = 'cid$#3195388#',
  LastNDays = 'cid$#2992158#',
  LastNHours = 'cid$#3008674#',
  LastNMinutes = 'cid$#3008675#',
  LastNMonths = 'cid$#3578114#',
  ListingInUKNotification = 'cid$#3172498',
  LoadingListingId = 'cid$#3037602#',
  LoadingOrderId = 'cid$#3037604#',
  LoadingSaleId = 'cid$#2983916#',
  Margin = 'cid$#2971997#',
  MaxAllowedFileSizeMb = 'cid$#3678672#',
  MaxReportSizeWarningMessage = 'cid$#3678673#',
  MissingBarcodes = 'cid$#2972954#',
  MissingTicketIds = 'cid$#3389422#',
  MissingTicketUrls = 'cid$#3389423#',
  NCharactersRemaining = 'cid$#3512121#',
  NCompleted = 'cid$#3373034#',
  NFailed = 'cid$#3373035#',
  NIncreaseBy = 'cid$#3172500#',
  NDays = 'cid$#3008676#',
  NDaysBefore = 'cid$#3008669#',
  NHours = 'cid$#3008677#',
  NHoursBefore = 'cid$#3008670#',
  NMinutes = 'cid$#3008678#',
  NMinutesBefore = 'cid$#3008671#',
  NoDataAvailable = 'cid$#3045423#',
  NotificationTypeListingBroadcastFailed = 'cid$#3180468#',
  NotificationTypeListingUnbroadcastFailed = 'cid$#3180475#',
  NotificationTypeListingUpdateAllInPriceFailed = 'cid$#3180469#',
  NotificationTypeListingUpdateInHandDateFailed = 'cid$#3180470#',
  NotificationTypeListingUpdateSplitTypeFailed = 'cid$#3180471#',
  NotificationTypeListingUpdateTicketTypeFailed = 'cid$#3180472#',
  NotificationTypeListingUpdateUnitNetProceedsFailed = 'cid$#3180474#',
  NotificationTypeSellerSupportCaseNewReply = 'cid$#3180476#',
  NotUnique = 'cid$#3110513#',
  NSelectedSections = 'cid$#3389424#',
  NUpdated = 'cid$#____NUpdated#',
  NWarnings = 'cid$#3678674#',
  OnlyForMarketplaces = 'cid$#____OnlyForMarketplaces#',
  PacksOf = 'cid$#3543239#',
  PageNum = 'cid$#2975357#',
  PercentageRelativeTo = 'cid$#3432394#',
  ProofOfPurchasedRequiredDetailMessage = 'cid$#3512122#',
  RequestState = 'cid$#3512123#',
  Row = 'cid$#3261280#',
  RowRangeInputError = 'cid$#____RowRangeInputError#',
  SalesForEditTickets = 'cid$#3678675#',
  SalesForRemovedTickets = 'cid$#3678676#',
  SearchingForListingId = 'cid$#3037603#',
  SearchingForOrderId = 'cid$#3037605#',
  SearchingForSaleId = 'cid$#2977368#',
  SearchItem = 'cid$#2973458#',
  SearchResultPick = 'cid$#2983917#',
  SeatAllocationChangeWarning = 'cid$#3089846#',
  SeatNumber = 'cid$#2972034#',
  Section = 'cid$#3261281#',
  ShareQuoted = 'cid$#3432395#',
  SwitchTo = 'cid$#____SwitchTo#',
  TakeMeThereInSeconds = 'cid$#3543240#',
  TheseAreThePastSalesOnTheWebsiteAndApp = 'cid$#3432396#',
  TicketCount = 'cid$#3261282#',
  TicketNumber = 'cid$#2975378#',
  TicketPricePercentile = 'cid$#____TicketPricePercentile#',
  TicketTypePriority = 'cid$#3243235#',
  Uploading = 'cid$#2975358#',
  UpToXTagsCanBeIncluded = 'cid$#3408222#',
  UrlNumber = 'cid$#2973462#',
  WelcomeUserMessage = 'cid$#2992157#',
  WillDeliverToSecondaryAccount = 'cid$#3261283#',
  YourSaleMetricsPart2 = 'cid$#2992159#',
  YourSaleWithStatus = 'cid$#2992160#',
}
