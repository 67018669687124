import clsx from 'clsx';
import { union } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { SimplifiedAutopricingSection } from 'src/components/AutoPricing/SimplifiedAutopricingSection';
import { SimplifiedAutopricingSection as SimplifiedAutopricingSectionV2 } from 'src/components/AutoPricing/SimplifiedAutopricingSectionV2';
import { ColorBandProps } from 'src/components/Events/VenueMap';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { VenueMapContentProps } from 'src/components/Events/VenueMap/VenueMapContent';
import {
  ConnectedComparableListings,
  ListingWithSeatScore,
} from 'src/components/Listings/ConnectedComparableListings';
import { Content } from 'src/contexts/ContentContext';
import {
  EventSessionRole,
  useEventHubContext,
} from 'src/contexts/EventHubContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { getListPriceFromAllinPrice } from 'src/utils/inventoryUtils';
import {
  getCompleteEventConfigScoreOverrides,
  getMatchingSectionRow,
  getSeatScore,
} from 'src/utils/seatScoreUtils';
import {
  AutoPricingUndercutMode,
  CompListing,
  Feature,
  Listing,
  ListingDetailsPricingUpdates,
  ListingPriceCalculation,
  SectionInfo,
} from 'src/WebApiController';

import * as styles from './MapWithCompListingsPreview.css';
import { useVenueMapHandlers } from './MapWithCompListingsPreview.hooks';
import { QuickFloorToolbar } from './QuickFloorToolbar';

type MapWithCompListingsPreviewProps = {
  listing?: Listing;
  listingInput: ListingDetailsPricingUpdates;
  pricingPreview?: ListingPriceCalculation;
  compListings: CompListing[];
  compListingsFromListgingGroup?: ListingWithSeatScore[];
  showSimplifiedSettings?: boolean;
  statsContent?: React.ReactNode;
  isLoading?: boolean;
  isInModal?: boolean;
  colorBand?: ColorBandProps;
  getColor?: VenueMapContentProps['getColor'];
  onSelectedSectionsChange?: (selectedSections: SectionInfo[]) => void;
};

export const MapWithCompListingsPreview: React.FC<
  MapWithCompListingsPreviewProps
> = ({
  listing,
  listingInput,
  pricingPreview,
  compListings,
  compListingsFromListgingGroup,
  showSimplifiedSettings,
  statsContent,
  isLoading,
  isInModal = false,
  colorBand,
  getColor,
  onSelectedSectionsChange,
}) => {
  const { venueMapInfo, activeConfigOverride } = useEventMapContext();
  const { sessionRole, enabled } = useEventHubContext();
  const hasSimplifiedPricingV2Feature = useUserHasFeature(
    Feature.SimplifiedAutoPricingUiV2
  );
  const hasQuickFloorToolbarFeature = useUserHasFeature(
    Feature.QuickFloorToolbar
  );

  const sessionView = sessionRole === EventSessionRole.SidePanel;

  const filterSections = useMemo(() => {
    const selectedSectionId =
      listingInput?.compListingSelectedSectionSettings?.sectionIdFilter ?? [];
    return (
      venueMapInfo?.sections?.filter(({ id }) =>
        selectedSectionId.includes(id)
      ) ?? []
    );
  }, [
    listingInput?.compListingSelectedSectionSettings?.sectionIdFilter,
    venueMapInfo?.sections,
  ]);

  const { onSectionClicked, onToggleMirrors, onSectionHovered } =
    useVenueMapHandlers(
      filterSections,
      pricingPreview,
      venueMapInfo,
      onSelectedSectionsChange
    );

  const selectedSectionIds = useMemo(() => {
    if (!listing?.seating) {
      return [];
    }

    const { section } = getMatchingSectionRow(
      listing.seating,
      venueMapInfo?.sections
    );
    return section ? [section.id] : [];
  }, [listing?.seating, venueMapInfo?.sections]);

  const scoreOverrides = useMemo(
    () =>
      getCompleteEventConfigScoreOverrides(
        venueMapInfo?.sectionScores,
        activeConfigOverride?.scoreOverrides,
        false
      ),
    [activeConfigOverride?.scoreOverrides, venueMapInfo?.sectionScores]
  );

  const populateListingWithSeatScore = useCallback(
    (listing?: Listing) => {
      if (!listing) return;

      const seatScore = getSeatScore(
        listing.seating,
        scoreOverrides,
        venueMapInfo?.sections
      );
      if (seatScore != null) {
        const listPrice = getListPriceFromAllinPrice(
          pricingPreview?.websitePrice?.amt,
          listing
        );
        return {
          ...listing,
          // This is to make sure we use the auto-preview price instead of the listing's price when showing comparables
          // And we have to override listPrice because allIn is calculated from listPrice (instead of being read from the listing)
          listPrice: listPrice ?? listing.listPrice,
          seatScore,
        };
      }

      return listing;
    },
    [pricingPreview?.websitePrice?.amt, scoreOverrides, venueMapInfo?.sections]
  );

  const compListingsWithSeatScore: CompListing[] | undefined = useMemo(() => {
    return (
      compListings?.map((l) => {
        const seatScore = getSeatScore(
          l,
          scoreOverrides,
          venueMapInfo?.sections
        );
        if (seatScore != null) return { ...l, seatScore };

        return l;
      }) ?? []
    );
  }, [compListings, scoreOverrides, venueMapInfo?.sections]);

  const compListingSectionIds = useMemo(() => {
    const sectionNames = (pricingPreview?.compListings ?? []).map(
      (l) => l.section
    );
    const sectionIds = (venueMapInfo?.sections ?? [])
      .filter((section) => sectionNames.includes(section.name))
      .map(({ id }) => id);

    const selected = filterSections.map((section) => section.id);

    return union(sectionIds, selected);
  }, [pricingPreview?.compListings, venueMapInfo?.sections, filterSections]);

  return (
    <Stack
      direction={sessionView ? 'row' : 'column'}
      gap={isInModal ? 'xl' : 'm'}
      width={isInModal ? undefined : 'full'}
      height={isInModal ? undefined : 'full'}
      className={isInModal ? styles.sectionContainer : undefined}
    >
      <div
        className={clsx(styles.venueMapContainer, {
          [styles.mapContainerFull]: isInModal,
          [styles.companionVenueMapContainer]:
            enabled && sessionRole === EventSessionRole.SidePanel,
        })}
      >
        <EventVenueMap
          selectedSectionIds={selectedSectionIds}
          onSectionClicked={onSectionClicked}
          onSectionHovered={onSectionHovered}
          onToggleMirrors={onToggleMirrors}
          setSelectedSections={onSelectedSectionsChange}
          markedSectionIds={compListingSectionIds}
          colorBandProps={colorBand}
          getColor={getColor}
          statsContent={statsContent}
          infoIconContent={<Content id={ContentId.AutoPricingDisclaimer} />}
        />
      </div>
      <Stack
        direction="column"
        gap="m"
        className={clsx(styles.contentContainer, {
          [styles.compsContainerFull]: isInModal,
          [styles.companionContentContainer]:
            enabled && sessionRole === EventSessionRole.SidePanel,
        })}
      >
        {showSimplifiedSettings &&
          (hasSimplifiedPricingV2Feature ? (
            <SimplifiedAutopricingSectionV2 listing={listing} />
          ) : (
            <SimplifiedAutopricingSection listing={listing} />
          ))}
        <ConnectedComparableListings
          currentListing={populateListingWithSeatScore(listing)}
          currentListingFromListingGroup={compListingsFromListgingGroup}
          comparableListings={compListingsWithSeatScore}
          sortBy={
            listingInput.undercutMode === AutoPricingUndercutMode.Deal &&
            listingInput.autoPricingEnabled
              ? 'dealScore'
              : 'price'
          }
          isSortDescending={false}
          isLoading={isLoading}
          listings={undefined}
          showAutoPriceResults={Boolean(
            listingInput.autoPricingEnabled === true
          )}
          listingPriceCalc={pricingPreview}
        />
        {hasQuickFloorToolbarFeature && listing && !isInModal && (
          <QuickFloorToolbar listing={listing} />
        )}
      </Stack>
    </Stack>
  );
};
