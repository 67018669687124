import { VenueMapColorSelector } from 'src/components/Events/VenueMap/VenueMap.types';
import { VenueMapContentProps } from 'src/components/Events/VenueMap/VenueMapContent';
import { SectionSectionGroup } from 'src/WebApiController';

const baseColors = [
  '#228B22', // Forest Green
  '#8B3A62', // Deep Rose
  '#008B8B', // Deep Cyan
  '#8B864E', // Dark Khaki
  '#8B3A8B', // Deep Magenta
  '#1F4F1F', // Deep Forest Green
  '#8B7355', // Deep Wheat
  '#8B4C39', // Deep Salmon
  '#8B7E66', // Deep Moccasin
  '#8B3E2F', // Deep Coral
  '#8B668B', // Deep Plum
  '#8B8B00', // Deep Yellow
  '#27408B', // Royal Blue
  '#8B3A3A', // Deep Red
  '#8B7B8B', // Deep Purple
  '#8B4726', // Deep Sienna
  '#704214', // Deep Sepia
  '#614051', // Deep Mauve
  '#683A5E', // Deep Wine
  '#4A2B45', // Deep Aubergine
  '#454B1B', // Deep Olive
  '#4B0082', // Deep Indigo
  '#2F4F4F', // Dark Slate Gray
  '#3C1414', // Deep Burgundy
  '#1B1B1B', // Almost Black
  '#3D1C02', // Deep Coffee
  '#2C1810', // Deep Brown
  '#371F1C', // Deep Mahogany
  '#302112', // Deep Umber
  '#32174D', // Russian Violet
  '#2B0202', // Dark Blood Red
  '#1A2421', // Dark Forest
  '#2C1608', // Deep Chocolate
  '#2F1B0C', // Dark Cinnamon
  '#251607', // Deep Espresso
  '#2C1103', // Dark Roast
  '#1F1200', // Deep Bronze
  '#251F1F', // Dark Charcoal
  '#28282B', // Dark Gunmetal
  '#483D8B', // Deep Slate Blue
];

export const getRandomColor = (index: number): string => {
  if (index < baseColors.length) {
    return baseColors[index];
  }

  // Only pick a random one if we've run out of base colors to use for top level zones
  return baseColors[Math.floor(Math.random() * baseColors.length)];
};

export const createGetColor = (
  sectionSectionGroupLookup?: { [key: string]: SectionSectionGroup } | null
): NonNullable<VenueMapContentProps['getColor']> => {
  return ({ sectionId }: VenueMapColorSelector) => {
    if (!sectionId || sectionSectionGroupLookup == null) {
      return { fill: `#FFFFFF`, stroke: 'black' };
    }

    const colorMap = Object.fromEntries(
      Object.entries(sectionSectionGroupLookup).map(
        ([sectionId, sectionSectionGroup]) => [
          sectionId,
          {
            ...sectionSectionGroup,
            color: sectionSectionGroup.sectionGroupColor,
          },
        ]
      )
    );

    const sectionGroup = colorMap[sectionId];
    return { fill: `${sectionGroup?.color ?? '#FFFFFF'}`, stroke: 'black' };
  };
};
