import { Slider } from 'src/core/ui/Slider';

import { useAutoPricingSettingsFormContext } from '../useAutoPricingSettingsFormContext';

export const AutoPricingMinCompListingsSlider = ({
  disabled,
}: {
  disabled: boolean;
}) => {
  const { watch, setValue } = useAutoPricingSettingsFormContext();

  const { circuitBreakerMinCompListingCount } = watch();

  return (
    <Slider.Root
      min={1}
      max={30}
      step={1}
      value={[circuitBreakerMinCompListingCount ?? 1]}
      onValueChange={([count]) => {
        setValue('circuitBreakerMinCompListingCount', Math.round(count));
      }}
      disabled={disabled}
    />
  );
};
