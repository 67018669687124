import { FormEvent } from 'react';
import { vars } from 'src/core/themes';
import { ArrowDownIcon, ArrowUpIcon } from 'src/svgs/Viagogo';

import { Button } from '../Button';
import { Stack } from '../Stack';
import * as styles from './UpDownComboButton.css';

export const UpDownComboButton = ({
  disabled,
  onUpClick,
  onDownClick,
}: {
  disabled?: boolean;
  onUpClick: (e: FormEvent<HTMLButtonElement>) => void;
  onDownClick: (e: FormEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <Stack direction="column">
      <Button
        className={styles.upButtonStyle}
        variant="outline"
        disabled={disabled}
        onClick={onUpClick}
      >
        <ArrowUpIcon
          withHoverEffect
          size={vars.iconSize.xs}
          disabled={disabled}
        />
      </Button>
      <Button
        className={styles.downButtonStyle}
        variant="outline"
        disabled={disabled}
        onClick={onDownClick}
      >
        <ArrowDownIcon
          withHoverEffect
          size={vars.iconSize.xs}
          disabled={disabled}
        />
      </Button>
    </Stack>
  );
};
