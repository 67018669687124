import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingDetailsTagsSectionUpdates,
} from 'src/WebApiController';

const getListingDetailTagsInput = (
  listing: ListingDetails | null | undefined
): ListingDetailsTagsSectionUpdates => {
  return {
    id: listing?.id ?? 0,
    rowVersion: null,
    tags: listing?.tags ?? null,
  };
};

export const useTagsForm = (
  listing: ListingDetails | null | undefined,
  onSubmitStart: () => void,
  onSubmitDone: (success?: boolean) => Promise<void>
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const methods = useForm<ListingDetailsTagsSectionUpdates>({
    defaultValues: listing?.dataIsLoaded[ListingDetailDataField.Tags]
      ? getListingDetailTagsInput(listing)
      : undefined,
  });

  useEffect(() => {
    if (
      methods.formState.defaultValues == null &&
      listing?.dataIsLoaded[ListingDetailDataField.Tags]
    ) {
      methods.reset(getListingDetailTagsInput(listing));
    }
  }, [methods, listing]);

  const onSubmitHandler = useCallback(
    async (tagsSectionForm: ListingDetailsTagsSectionUpdates) => {
      tryInvokeApi(
        async () => {
          onSubmitStart();
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).updateListingTagSection(tagsSectionForm);

          if (isSuccess(result)) {
            // Reset the form to latest changed
            methods.reset(tagsSectionForm);
            await onSubmitDone();
          } else {
            showErrorDialog(
              'ListingClient.updateListingTagSection',
              { message: result.message, status: result.status },
              {
                trackErrorData: {
                  tagsSectionForm,
                },
              }
            );
          }
        },
        (error) => {
          showErrorDialog('ListingClient.updateListingTagSection', error, {
            trackErrorData: tagsSectionForm,
          });
        },
        () => {
          onSubmitDone(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      methods,
      onSubmitDone,
      onSubmitStart,
      showErrorDialog,
    ]
  );

  const onSubmit = useCallback(() => {
    methods.handleSubmit(onSubmitHandler)();
  }, [methods, onSubmitHandler]);

  const onReset = useCallback(() => {
    methods.reset(getListingDetailTagsInput(listing));
  }, [listing, methods]);

  const input = methods.watch();

  const hasChanges = useMemo(() => {
    const defaultValues = methods.formState.defaultValues;
    return !isEqual(input.tags, defaultValues?.tags);
  }, [input.tags, methods.formState.defaultValues]);

  return {
    hasChanges,
    methods,
    onSubmit,
    onReset,
  };
};
