import * as RadixTooltip from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import {
  ComponentPropsWithoutRef,
  CSSProperties,
  forwardRef,
  ReactNode,
} from 'react';
import { mergeProps, RADIX_COLLISION_PADDING } from 'src/core/utils';

import * as styles from './Tooltip.css';

export const Provider = RadixTooltip.Provider;

export const Root = RadixTooltip.Root;

export const Trigger = RadixTooltip.Trigger;

export const Content = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof RadixTooltip.Content> & {
    contentVariant?: 'theme' | 'light' | 'dark' | 'darkGray';
  }
>(function Content({ children, contentVariant, ...props }, ref) {
  return (
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        ref={ref}
        sideOffset={4}
        collisionPadding={RADIX_COLLISION_PADDING}
        {...mergeProps(
          {
            className: clsx(styles.tooltipContent, {
              [styles.lightContentWrapper]: contentVariant === 'light',
              [styles.darkContentWrapper]: contentVariant === 'dark',
              [styles.darkGrayContentWrapper]: contentVariant === 'darkGray',
            }),
          },
          props
        )}
      >
        {children}
        <RadixTooltip.Arrow
          className={clsx(styles.tooltipArrow, {
            [styles.lightContentArrow]: contentVariant === 'light',
            [styles.darkContentArrow]: contentVariant === 'dark',
            [styles.darkGrayContentArrow]: contentVariant === 'darkGray',
          })}
        />
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  );
});

export const Tooltip = ({
  triggerContent,
  children,
  delayDuration = 300,
  align = 'start',
  contentVariant,
  contentStyle,
  open,
  ...rest
}: RadixTooltip.TooltipTriggerProps & {
  triggerContent: ReactNode;
  delayDuration?: RadixTooltip.TooltipProps['delayDuration'];
  onOpenChange?: RadixTooltip.TooltipProps['onOpenChange'];
  open?: RadixTooltip.TooltipProps['open'];
  align?: RadixTooltip.TooltipContentProps['align'];
  contentVariant?: 'theme' | 'light' | 'dark' | 'darkGray';
  contentStyle?: CSSProperties;
}) => {
  return (
    <Provider>
      <Root delayDuration={delayDuration} open={open}>
        <Trigger asChild {...rest}>
          {triggerContent}
        </Trigger>
        <Content
          align={align}
          contentVariant={contentVariant}
          style={contentStyle}
        >
          {children}
        </Content>
      </Root>
    </Provider>
  );
};
