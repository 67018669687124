import { useState } from 'react';
import { useBulkBroadcastActionsPermission } from 'src/components/common/BulkActions/BulkBroadcastActions/useBulkBroadcastActionsPermission';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { useBulkAddHoldAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkAddHoldAction';
import { useBulkEditBroadcastSettingsAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkEditBroadcastSettingsAction/useBulkEditBroadcastSettingsAction';
import { useBulkRemoveHoldAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkRemoveHoldAction';
import { useBulkUngroupListings } from 'src/components/Listings/InventoryActionDropdown/components/LaunchUngroupListingsAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { EventWithData } from 'src/WebApiController';

export interface BroadcastActionsProps {
  event: EventWithData['event'];
  isEventPage: boolean;
  listingCount: number;
}

export const BroadcastActions = ({ event }: BroadcastActionsProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);
  const { affectedVisibleListingIds, filterQueryWithEventIds } =
    useInventoryEventBulkActionsState(event);

  const { hasBroadcastPermission } = useBulkBroadcastActionsPermission();

  const bulkAddHold = useBulkAddHoldAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  const bulkRemoveHold = useBulkRemoveHoldAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  const bulkUngroupListings = useBulkUngroupListings(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  const bulkEditBroadcastSettings = useBulkEditBroadcastSettingsAction({
    affectedVisibleListingIds,
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event,
  });

  return (
    <>
      <PosDropdown
        key="event-broadcast-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Broadcast} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {hasBroadcastPermission && bulkEditBroadcastSettings.dropDown}
        {bulkAddHold.dropDown}
        {bulkRemoveHold.dropDown}
      </PosDropdown>
    </>
  );
};
