import React from 'react';
import { Stack } from 'src/core/ui';

import * as styles from './SettingsTitle.css';

type SettingsTitleProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
};

export const SettingsTitle: React.FC<SettingsTitleProps> = ({
  title,
  subtitle,
}) => {
  return (
    <Stack direction="column" alignItems="start">
      <div className={styles.SettingsTitle}>{title}</div>
      {subtitle && <div className={styles.SettingsSubTitle}>{subtitle}</div>}
    </Stack>
  );
};
