import { uniqBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { ListingNoteSelectionInput } from 'src/components/Input/ListingNoteSelectionInput';
import { VenueRowInput } from 'src/components/Input/VenueRowInput';
import { VenueSectionInput } from 'src/components/Input/VenueSectionInput';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { FormPage } from 'src/core/POS/FormPage';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FieldWrapper } from 'src/modals/common';
import {
  addSectionButton,
  column0,
  gridHeader,
  removeButton,
  seatingSection,
} from 'src/modals/SeatSaverListingWizard/components/SeatingSection.css';
import { CrossIcon, IconsFill, SeatIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getAllInPriceFromListPrice,
  getListPriceFromAllinPrice,
} from 'src/utils/inventoryUtils';
import { INT_MAX_VALUE } from 'src/utils/numberFormatter';
import {
  EventListingConstraints,
  Feature,
  ListingNote,
  Marketplace,
  RowInfo,
  SeatSaverSeating,
  SectionInfo,
} from 'src/WebApiController';

import { SeatSaverListingDetailsInputForm } from '../SeatSaverListingWizard.types';

const tableHeaderContent: (ContentId | null)[] = [
  ContentId.Section,
  ContentId.Row,
  ContentId.TotalQty,
  ContentId.ShownQty,
  ContentId.Proceeds,
  ContentId.Price,
  ContentId.FaceValue,
  ContentId.SeatTraits,
  null,
];

export const SeatingSection = ({
  inputIndeces,
  disabled,
  eventListingConstraints,
}: {
  inputIndeces: number[];
  disabled?: boolean;
  eventListingConstraints?: EventListingConstraints | null;
}) => {
  const { ticketClasses, venueMapInfo } = useEventMapContext();
  const { watch, setValue, clearErrors, getFieldState } =
    useFormContext<SeatSaverListingDetailsInputForm>();

  const { loginContext } = useAppContext();
  const stubHubMarketplace =
    loginContext?.user?.activeAccount.marketplaceSettings.find(
      (mps) => mps.mkp === Marketplace.StubHub
    );

  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = useMemo(
    () =>
      getUiCurrency(loginContext?.user?.activeAccount?.currencyCode ?? 'USD'),
    [getUiCurrency, loginContext?.user?.activeAccount?.currencyCode]
  );

  const inputs = watch('inputs');

  const removeSection = useCallback(
    (index: number) => {
      inputIndeces.forEach((i) => {
        const input = inputs[i];
        const curSections = [...input.seatings];

        if (curSections.length) {
          // Existing - remove
          curSections.splice(index, 1);
          setValue(`inputs.${i}.seatings`, curSections);
        }
      });
    },
    [inputIndeces, inputs, setValue]
  );

  const onSectionClicked = useCallback(
    (e: MouseEvent, selectedSection: SectionInfo) => {
      inputIndeces.forEach((index) => {
        const input = inputs[index];
        const curSections = input.seatings.filter(
          (s) => s.section === selectedSection.name
        );
        clearErrors(`inputs.${index}.seatings`);
        if (curSections.length) {
          // Existing - remove
          const newSections = input.seatings.filter(
            (s) => s.section !== selectedSection.name
          );
          setValue(`inputs.${index}.seatings`, newSections);
        } else {
          // New - add
          const selectedRow = selectWorstRow(selectedSection);

          const newSections = [
            ...input.seatings,
            {
              section: selectedSection.name,
              sectionId: selectedSection.id,
              row: selectedRow?.isSpeculative ? '' : selectedRow?.name,
              rowId: selectedRow?.id ?? selectedSection.specRow?.id,
            } as SeatSaverSeating,
          ];
          setValue(`inputs.${index}.seatings`, newSections);
        }
      });
    },
    [clearErrors, inputIndeces, inputs, setValue]
  );

  const onToggleMirrors = useCallback(
    (mirrors: SectionInfo[], exclude?: boolean) => {
      if (exclude) {
        const idsToExclude = mirrors.map(({ id }) => id);
        inputIndeces.forEach((index) => {
          const input = inputs[index];
          const newSections = uniqBy(
            input.seatings.filter(
              ({ sectionId }) => sectionId && !idsToExclude.includes(sectionId)
            ),
            'sectionId'
          );
          setValue(`inputs.${index}.seatings`, newSections);
        });
        return;
      }

      const seatSaverSections = mirrors.map((section) => {
        const selectedRow = selectWorstRow(section);
        return {
          section: section.name,
          sectionId: section.id,
          row: selectedRow?.isSpeculative ? '' : selectedRow?.name,
          rowId: selectedRow?.id ?? section.specRow?.id,
        } as SeatSaverSeating;
      });
      inputIndeces.forEach((index) => {
        const input = inputs[index];
        const newSections = uniqBy(
          input.seatings.concat(seatSaverSections),
          'sectionId'
        );
        setValue(`inputs.${index}.seatings`, newSections);
      });
    },
    [inputIndeces, inputs, setValue]
  );

  const selectedSectionIds = useCallback(() => {
    const ids = inputIndeces
      .flatMap((index) => {
        const input = inputs[index];
        return input.seatings.map((s) => s.sectionId);
      })
      .filter((id) => id)
      .map((id) => id!);

    return ids;
  }, [inputIndeces, inputs]);

  const onAddSection = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      clearErrors();
      inputIndeces.forEach((index) => {
        const input = inputs[index];
        // New - add
        const newSections = [
          ...input.seatings,
          {
            section: '',
            sectionId: null,
            row: '',
            rowId: null,
          } as SeatSaverSeating,
        ];
        clearErrors(`inputs.${index}.seatings`);
        setValue(`inputs.${index}.seatings`, newSections);
      });
    },
    [clearErrors, inputIndeces, inputs, setValue]
  );
  const inputToDisplay = inputs[inputIndeces[0]];
  const seatingsError = getFieldState(`inputs.${inputIndeces[0]}.seatings`)
    .error?.message;

  const selectWorstRow = (section: SectionInfo) => {
    let selectedRow: {
      id: number;
      name: string | null;
      isSpeculative: boolean;
    } | null = section.specRow;
    const rowSorted = section?.rows
      .filter((r) => !r.isSpeculative)
      .sort(
        (r1, r2) => (r1.seatQualityScore ?? 0) - (r2.seatQualityScore ?? 0)
      );
    if (rowSorted?.length) {
      selectedRow = rowSorted?.[0];
    }

    return selectedRow;
  };

  const onTicketClassChange = useCallback(
    (selectedTicketClassIds: string[]) => {
      console.log('ticketclass change', selectedTicketClassIds);
      // TODO: https://thestubhub.atlassian.net/browse/POS-3548
    },
    []
  );

  const onSectionChange = useCallback(
    (
      seatIndex: number,
      sectionName?: string | null,
      section?: SectionInfo | null
    ) => {
      inputIndeces.forEach((i) => {
        clearErrors(`inputs.${i}.seatings.${seatIndex}.section`);
        if (section) {
          setValue(`inputs.${i}.seatings.${seatIndex}.section`, section.name);
          setValue(`inputs.${i}.seatings.${seatIndex}.sectionId`, section.id);

          const selectedRow = selectWorstRow(section);

          if (selectedRow?.id) {
            // Setting the section automatically set the speculative row id
            setValue(`inputs.${i}.seatings.${seatIndex}.rowId`, selectedRow.id);
            setValue(
              `inputs.${i}.seatings.${seatIndex}.row`,
              selectedRow?.isSpeculative ? '' : selectedRow?.name
            );
          }
        } else {
          setValue(
            `inputs.${i}.seatings.${seatIndex}.section`,
            sectionName ?? ''
          );

          // Free-text section do not have speculative row info
          setValue(`inputs.${i}.seatings.${seatIndex}.rowId`, null);
          setValue(`inputs.${i}.seatings.${seatIndex}.row`, '');
        }
      });
    },
    [clearErrors, inputIndeces, setValue]
  );

  const onRowChange = useCallback(
    (seatIndex: number, rowName?: string | null, row?: RowInfo | null) => {
      inputIndeces.forEach((i) => {
        clearErrors(`inputs.${i}.seatings.${seatIndex}.row`);
        if (row) {
          setValue(
            `inputs.${i}.seatings.${seatIndex}.row`,
            row.isSpeculative ? '' : row.name
          );
          setValue(`inputs.${i}.seatings.${seatIndex}.rowId`, row.id);
        } else {
          // If we can't find the row - that mean they typed it in
          // so just keep with they type as the row
          setValue(`inputs.${i}.seatings.${seatIndex}.row`, rowName ?? '');
          setValue(`inputs.${i}.seatings.${seatIndex}.rowId`, null);
        }
      });
    },
    [clearErrors, inputIndeces, setValue]
  );

  const onQuantityChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      seatIndex: number,
      maxDisplayQuantity: number | null
    ) => {
      inputIndeces.forEach((i) => {
        const v = parseInt(e.target.value) || 0;
        if (v >= 0 && v <= INT_MAX_VALUE) {
          clearErrors(`inputs.${i}.seatings.${seatIndex}.quantity`);
          setValue(`inputs.${i}.seatings.${seatIndex}.quantity`, v);

          if (maxDisplayQuantity != null && maxDisplayQuantity > v) {
            clearErrors(`inputs.${i}.seatings.${seatIndex}.maxDisplayQuantity`);
            setValue(`inputs.${i}.seatings.${seatIndex}.maxDisplayQuantity`, v);
          }
        } else {
          setValue(`inputs.${i}.seatings.${seatIndex}.quantity`, null);
        }
      });
    },
    [clearErrors, inputIndeces, setValue]
  );

  const onMaxQuantityChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      seatIndex: number,
      quantity: number | null
    ) => {
      inputIndeces.forEach((i) => {
        let v = parseInt(e.target.value) || 0;
        if (v >= 0) {
          v = Math.min(v, quantity || INT_MAX_VALUE);

          clearErrors(`inputs.${i}.seatings.${seatIndex}.maxDisplayQuantity`);
          setValue(`inputs.${i}.seatings.${seatIndex}.maxDisplayQuantity`, v);
          if (!quantity) {
            clearErrors(`inputs.${i}.seatings.${seatIndex}.quantity`);
            setValue(`inputs.${i}.seatings.${seatIndex}.quantity`, v);
          }
        } else {
          setValue(
            `inputs.${i}.seatings.${seatIndex}.maxDisplayQuantity`,
            null
          );
        }
      });
    },
    [clearErrors, inputIndeces, setValue]
  );

  const onListingNotesChange = useCallback(
    (seatIndex: number, listingNotes: ListingNote[]) => {
      inputIndeces.forEach((i) => {
        setValue(
          `inputs.${i}.seatings.${seatIndex}.listingNotes`,
          listingNotes
        );
      });
    },
    [inputIndeces, setValue]
  );

  const onUnitNetProceedsChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      const v = parseFloat(e.target.value);
      if (v >= 0 && v <= Number.MAX_VALUE) {
        inputIndeces.forEach((i) => {
          clearErrors(`inputs.${i}.seatings.${index}.unitNetProceeds`);
          setValue(`inputs.${i}.seatings.${index}.unitNetProceeds`, v);
          setValue(
            `inputs.${i}.seatings.${index}.websitePrice`,
            getAllInPriceFromListPrice(
              v,
              null,
              stubHubMarketplace?.sellerFee
            ) ?? null
          );
        });
      } else {
        inputIndeces.forEach((i) => {
          setValue(`inputs.${i}.seatings.${index}.unitNetProceeds`, null);
          setValue(`inputs.${i}.seatings.${index}.websitePrice`, null);
        });
      }
    },
    [clearErrors, inputIndeces, setValue, stubHubMarketplace?.sellerFee]
  );

  const onWebsitePriceChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      const v = parseFloat(e.target.value);
      if (v >= 0 && v <= Number.MAX_VALUE) {
        inputIndeces.forEach((i) => {
          clearErrors(`inputs.${i}.seatings.${index}.unitNetProceeds`);
          setValue(`inputs.${i}.seatings.${index}.websitePrice`, v);
          setValue(
            `inputs.${i}.seatings.${index}.unitNetProceeds`,
            getListPriceFromAllinPrice(
              v,
              null,
              stubHubMarketplace?.sellerFee
            ) ?? null
          );
        });
      } else {
        inputIndeces.forEach((i) => {
          setValue(`inputs.${i}.seatings.${index}.unitNetProceeds`, null);
          setValue(`inputs.${i}.seatings.${index}.websitePrice`, null);
        });
      }
    },
    [clearErrors, inputIndeces, setValue, stubHubMarketplace?.sellerFee]
  );

  const onFaceValueChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      const v = parseFloat(e.target.value);
      if (v >= 0 && v <= Number.MAX_VALUE) {
        inputIndeces.forEach((i) => {
          setValue(`inputs.${i}.seatings.${index}.faceValue`, v);
        });
      } else {
        inputIndeces.forEach((i) => {
          setValue(`inputs.${i}.seatings.${index}.faceValue`, null);
        });
      }
    },
    [inputIndeces, setValue]
  );

  const selectedTicketClassIds = useMemo(() => {
    const result = inputToDisplay.seatings.map((s) => {
      const selectedSection = venueMapInfo?.sections.find(
        (vs) =>
          (s.sectionId != null && s.sectionId === vs.id) ||
          (s.sectionId == null && s.section === vs.name)
      );
      const selectedRow = selectedSection?.rows?.find(
        (r) =>
          (s.rowId != null && s.rowId === r.id) ||
          (s.rowId == null && s.row === r.name)
      );

      return (
        selectedRow?.tktClass?.id ??
        selectedSection?.specRow?.tktClass?.ticketClassId ??
        null
      );
    });

    return result.filter((id) => !id).map((id) => id!);
  }, [inputToDisplay.seatings, venueMapInfo?.sections]);

  return (
    <FormPage.Section
      icon={<SeatIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />}
      title={<Content id={ContentId.Seating} />}
    >
      <Stack direction="column" gap="m">
        {Boolean(venueMapInfo?.sections?.length) && (
          <FieldWrapper style={{ height: '35vh' }}>
            <EventVenueMap
              onSectionClicked={disabled ? undefined : onSectionClicked}
              onToggleMirrors={disabled ? undefined : onToggleMirrors}
              selectedSectionIds={selectedSectionIds()}
              getColor={(inf) => ({
                fill: inf.isSelected
                  ? vars.color.textBrandActive
                  : vars.color.backgroundHighlight,
                stroke: inf.isSelected
                  ? vars.color.textBrand
                  : vars.color.aqua100,
                textColor: inf.isSelected
                  ? vars.color.textInverted
                  : vars.color.textBrand,
              })}
            />
          </FieldWrapper>
        )}
      </Stack>

      <PosFormField
        label={<Content id={ContentId.Seating} />}
        errors={seatingsError}
      >
        <div className={seatingSection}>
          {inputToDisplay.seatings.length > 0 && (
            <>
              {tableHeaderContent.map((headerCellContentId) => (
                <div key={headerCellContentId} className={gridHeader}>
                  {headerCellContentId && <Content id={headerCellContentId} />}
                </div>
              ))}
              {inputToDisplay.seatings.map((s, j) => {
                const sectionError = getFieldState(
                  `inputs.${inputIndeces[0]}.seatings.${j}.section`
                ).error?.message;
                const rowError = getFieldState(
                  `inputs.${inputIndeces[0]}.seatings.${j}.row`
                ).error?.message;
                const quantityError = getFieldState(
                  `inputs.${inputIndeces[0]}.seatings.${j}.quantity`
                ).error?.message;
                const proceedsError = getFieldState(
                  `inputs.${inputIndeces[0]}.seatings.${j}.unitNetProceeds`
                ).error?.message;
                const websiteError = getFieldState(
                  `inputs.${inputIndeces[0]}.seatings.${j}.websitePrice`
                ).error?.message;

                const selectedSection = venueMapInfo?.sections.find(
                  (vs) =>
                    (s.sectionId != null && s.sectionId === vs.id) ||
                    (s.sectionId == null && s.section === vs.name)
                );
                const selectedRow = selectedSection?.rows?.find(
                  (r) =>
                    (s.rowId != null && s.rowId === r.id) ||
                    (s.rowId == null && s.row === r.name)
                );

                return (
                  <>
                    <div className={column0}>
                      <PosFormField
                        errors={sectionError}
                        showErrorsInline={true}
                      >
                        <VenueSectionInput
                          fieldError={sectionError}
                          section={s.section}
                          selectedSection={selectedSection}
                          disabled={disabled}
                          onChange={(sectionName, section) => {
                            onSectionChange(j, sectionName, section);
                          }}
                        />
                      </PosFormField>
                    </div>
                    <PosFormField errors={rowError} showErrorsInline={true}>
                      <VenueRowInput
                        fieldError={rowError}
                        row={s.row}
                        selectedRow={selectedRow}
                        selectedSection={selectedSection}
                        disabled={disabled}
                        onChange={(rowName, row) => {
                          onRowChange(j, rowName, row);
                        }}
                      />
                    </PosFormField>
                    <PosFormField
                      errors={quantityError}
                      showErrorsInline={true}
                    >
                      <PosTextField
                        disabled={disabled}
                        rootProps={{
                          state: getTextFieldState(quantityError),
                        }}
                        type="number"
                        inputMode="numeric"
                        value={s.quantity || ''}
                        onChange={(e) =>
                          onQuantityChange(e, j, s.maxDisplayQuantity)
                        }
                      />
                    </PosFormField>
                    <PosFormField>
                      <PosTextField
                        disabled={disabled}
                        type="number"
                        inputMode="numeric"
                        value={s.maxDisplayQuantity || ''}
                        onChange={(e) => onMaxQuantityChange(e, j, s.quantity)}
                      />
                    </PosFormField>

                    <PosFormField
                      showErrorsInline={true}
                      errors={proceedsError}
                    >
                      <PosCurrencyField
                        rootProps={{
                          state: getTextFieldState(proceedsError),
                        }}
                        disabled={disabled}
                        uiCurrency={uiCurrency}
                        value={s.unitNetProceeds ?? ''}
                        onChange={onUnitNetProceedsChange(j)}
                      />
                    </PosFormField>
                    <PosFormField showErrorsInline={true} errors={websiteError}>
                      <PosCurrencyField
                        rootProps={{
                          state: getTextFieldState(websiteError),
                        }}
                        disabled={disabled}
                        uiCurrency={uiCurrency}
                        value={s.websitePrice ?? ''}
                        onChange={onWebsitePriceChange(j)}
                      />
                    </PosFormField>
                    <PosFormField>
                      <PosCurrencyField
                        disabled={disabled}
                        uiCurrency={uiCurrency}
                        value={s.faceValue ?? ''}
                        onChange={onFaceValueChange(j)}
                      />
                    </PosFormField>

                    <PosFormField>
                      <ListingNoteSelectionInput
                        showPills={false}
                        avoidCollisions={false}
                        fullWidth={true}
                        disabled={disabled}
                        listingNotes={s.listingNotes}
                        validListingNoteIds={
                          eventListingConstraints?.validListingNoteIds
                        }
                        onChange={(listingNotes) =>
                          onListingNotesChange(j, listingNotes)
                        }
                      />
                    </PosFormField>
                    <div className={removeButton}>
                      <CrossIcon
                        withHoverEffect
                        onClick={() => {
                          removeSection(j);
                        }}
                      />
                    </div>
                  </>
                );
              })}
            </>
          )}
          <Button
            onClick={onAddSection}
            variant="link"
            size="unset"
            style={{ width: 'max-content' }}
            className={addSectionButton}
          >
            + <Content id={ContentId.AddSection} />
          </Button>
        </div>
      </PosFormField>
    </FormPage.Section>
  );
};
