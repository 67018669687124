import { useCallback, useContext, useState } from 'react';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { modalDetails } from 'src/modals/common/Modals.css';
import { Summary } from 'src/modals/common/Summary';
import { SaleWasteInfo } from 'src/modals/ListingDetails/components/WasteUnsoldTickets/SaleWasteInfo';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ModalBodyHeaderContainer } from 'src/modals/Modal/Modal.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isSuccess } from 'src/utils/errorUtils';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  ApiException,
  ListingDetails,
  SaleClient,
  SaleDetails,
} from 'src/WebApiController';
import * as styles from './WasteUnsoldTickets.css';

type WasteUnsoldTicketsProps = {
  cancelTo?: ModalProps;
  onWastedSuccess: (saleId: number) => void;
};

export const WasteUnsoldTickets = ({
  cancelTo,
  onWastedSuccess,
}: WasteUnsoldTicketsProps) => {
  const { posEntity, event, posEntityDisplayId, posEntityId } =
    useActivePosEntityContext<EntityWithRealTickets>();
  const listing =
    posEntity?.entityType === ActionOutboxEntityType.Listing
      ? (posEntity as ListingDetails)
      : undefined;

  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { setModal, closeModal } = useContext(ModalContext);

  const { isLoading: entityIsLoading, loadingState } =
    useEventItemLoadingDisplay<SaleDetails>(
      FormatContentId.LoadingSaleId,
      FormatContentId.SearchingForSaleId,
      FormatContentId.CouldNotFindSaleId,
      posEntityDisplayId ?? posEntityId?.toString()
    );

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    tryInvokeApi(
      async () => {
        if (!listing) {
          return null;
        }
        const client = new SaleClient(activeAccountWebClientConfig);

        const r = await client.wasteListingTickets({
          listingId: listing.id,
        });

        if (isSuccess(r) && r.entityId) {
          onWastedSuccess(r.entityId);
          if (cancelTo) {
            setModal(cancelTo);
          } else {
            closeModal(true);
          }
        } else {
          showErrorDialog(
            'SaleClient.mergePosManagedMarketplaceSale',
            {
              message: r.message ?? genericError,
              status: r.status!,
            } as ApiException,
            {
              trackErrorData: {
                listing: listing?.id,
              },
            }
          );
        }
      },
      (error) => {
        showErrorDialog('SaleClient.mergePosManagedMarketplaceSale', error, {
          trackErrorData: {
            listing: listing?.id,
          },
        });
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [
    activeAccountWebClientConfig,
    cancelTo,
    closeModal,
    genericError,
    listing,
    onWastedSuccess,
    setModal,
    showErrorDialog,
  ]);

  if (!listing) {
    return loadingState;
  }

  return (
    <>
      <CancellableFormHeader
        cancelTo={cancelTo}
        disabled={isLoading}
        showDialogOnCancel={true}
      >
        <ConnectedEventEntityHeader
          title={
            <>
              <Content id={ContentId.WasteSale} />
            </>
          }
        />
      </CancellableFormHeader>

      <ModalBody>
        <div className={modalDetails}>
          {event && listing && (
            <ModalBodyHeaderContainer>
              <Summary event={event} posEntity={listing} />
            </ModalBodyHeaderContainer>
          )}
          {entityIsLoading || !listing || !event || isLoading ? (
            <PosSpinner />
          ) : (
            <div className={styles.content}>
              <SaleWasteInfo
                eventDate={event.dates.start}
                seating={listing.seating}
                quantity={listing.availQty}
              />
            </div>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          cancelTo={cancelTo}
          disabled={isLoading || entityIsLoading}
          showDialogOnCancel={true}
        >
          <OkButton
            onClick={onSubmit}
            disabled={isLoading || entityIsLoading}
            textContentId={ContentId.ConfirmWaste}
          />
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
