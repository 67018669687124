import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { Feature, PurchaseViewMode } from 'src/WebApiController';

import { ColumnPersonalizationFlags } from '../columnUtils.types';

export const PURCHASES_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES: Record<
  PurchasesTableColumnId,
  Partial<ColumnPersonalizationFlags>
> = {
  [PurchasesTableColumnId.BuyerUserId]: {},
  [PurchasesTableColumnId.Checkbox]: {
    isHiddenByDefault: true,
    isConfigurable: false,
  },
  [PurchasesTableColumnId.AccordianToggle]: {
    visibleViewModes: [PurchaseViewMode.TileView],
    isConfigurable: false,
  },
  [PurchasesTableColumnId.PurchaseDate]: { isDateTime: true },
  [PurchasesTableColumnId.VendorOrderId]: { isRequired: true },
  [PurchasesTableColumnId.Id]: { isHiddenByDefault: true },
  [PurchasesTableColumnId.Payment]: {},
  [PurchasesTableColumnId.PaymentStatus]: {},
  [PurchasesTableColumnId.QualityControlState]: {},
  [PurchasesTableColumnId.TotalCost]: {},
  [PurchasesTableColumnId.UnitCost]: {},
  [PurchasesTableColumnId.Tickets]: {},
  [PurchasesTableColumnId.Orders]: {},
  [PurchasesTableColumnId.Shipment]: {},
  [PurchasesTableColumnId.Cost]: {},
  [PurchasesTableColumnId.Actions]: { isConfigurable: false, isRequired: true },
  [PurchasesTableColumnId.VendorAccountEmail]: { isHiddenByDefault: true },
  [PurchasesTableColumnId.CreatedBy]: { isHiddenByDefault: true },
  [PurchasesTableColumnId.Event]: {
    isHiddenByDefault: true,
    visibleViewModes: [PurchaseViewMode.TileView],
  },
  [PurchasesTableColumnId.Venue]: {
    isHiddenByDefault: true,
    visibleViewModes: [PurchaseViewMode.TileView],
  },
  [PurchasesTableColumnId.EventDate]: {
    isHiddenByDefault: true,
    visibleViewModes: [PurchaseViewMode.TileView],
  },
  [PurchasesTableColumnId.Seating]: {
    isHiddenByDefault: true,
    isNumeric: true, // Not actually numeric, but hack for numeric pills
    visibleViewModes: [
      PurchaseViewMode.TileView,
      PurchaseViewMode.EventTileView,
    ],
  },
  [PurchasesTableColumnId.Row]: {
    isHiddenByDefault: true,
    visibleViewModes: [
      PurchaseViewMode.TileView,
      PurchaseViewMode.EventTileView,
    ],
    requiredFeatures: [Feature.RowSeatColumnSalePurchase],
  },
  [PurchasesTableColumnId.Seats]: {
    isHiddenByDefault: true,
    visibleViewModes: [
      PurchaseViewMode.TileView,
      PurchaseViewMode.EventTileView,
    ],
    requiredFeatures: [Feature.RowSeatColumnSalePurchase],
  },
  [PurchasesTableColumnId.TotalPayment]: {
    isHiddenByDefault: true,
    visibleViewModes: [
      PurchaseViewMode.TileView,
      PurchaseViewMode.FlattenedView,
    ],
    requiredFeatures: [Feature.PurchasePaymentColumns],
  },
  [PurchasesTableColumnId.TotalConvertedPayment]: {
    isHiddenByDefault: true,
    visibleViewModes: [
      PurchaseViewMode.TileView,
      PurchaseViewMode.FlattenedView,
    ],
    requiredFeatures: [Feature.PurchasePaymentColumns],
  },
  [PurchasesTableColumnId.PaymentConversionRate]: {
    isHiddenByDefault: true,
    visibleViewModes: [
      PurchaseViewMode.TileView,
      PurchaseViewMode.FlattenedView,
    ],
    requiredFeatures: [Feature.PurchasePaymentColumns],
  },
  [PurchasesTableColumnId.Commissions]: {
    isHiddenByDefault: true,
    visibleViewModes: [
      PurchaseViewMode.TileView,
      PurchaseViewMode.FlattenedView,
    ],
  },
};

export const PURCHASES_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG: Partial<ColumnPersonalizationFlags> =
  {
    isHiddenByDefault: true,
    isNumeric: true,
  };
