import { useQuery } from '@tanstack/react-query';
import { PricingClient } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { ErrorTypes, useErrorBoundaryContext } from '../ErrorBoundaryContext';

export const useGetVenueZoneMapInfoOverrides = (
  eventId?: number | null,
  configId?: number | null,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled &&
    activeAccountWebClientConfig.activeAccountId != null &&
    eventId != null &&
    configId != null;

  const venueMapZoneInfoOverrideQuery = useQuery({
    queryKey: [
      'PricingClient.GetVenueMapZoneInfoOverrides',
      activeAccountWebClientConfig.activeAccountId,
      eventId,
      disabled,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const venueMapZoneInfoOverrides = await new PricingClient(
        activeAccountWebClientConfig
      ).getVenueMapZoneInfoOverrides(eventId!, configId!);

      return venueMapZoneInfoOverrides;
    },

    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.GetVenueMapZoneInfoOverrides', error, {
          eventId,
          configId,
        });
      },
    },
  });

  return venueMapZoneInfoOverrideQuery;
};
