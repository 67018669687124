import {
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { useConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog/useConfirmDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { Feature, PurchasePaymentMethod } from 'src/WebApiController';

import {
  PAYMENT_METHOD_TABLE_COLUMNS_CONFIG,
  PurchasePaymentMethodWithHandlers,
} from './configs/PurchasePaymentMethodsTableColumnsConfig';

export type PurchasePaymentMethodsTableDataProps = {
  purchasePaymentMethods?: PurchasePaymentMethod[];
  onEditPaymentMethod?: (purchasePayment: PurchasePaymentMethod) => void;
  onDeletePaymentMethod?: (
    purchasePaymentMethodId: number,
    closeConfirmDeleteDialog: () => void
  ) => Promise<void>;
};

export type PurchasePaymentMethodsTableProps =
  PurchasePaymentMethodsTableDataProps & {
    onMount?: () => void;
    onUnmount?: (state: object) => void;
    initState?: InitialTableState;
    withOuterPadding?: boolean;
  };

const PAGE_SIZE = 10;

export function PurchasePaymentMethodsTable({
  purchasePaymentMethods,
  onEditPaymentMethod,
  onDeletePaymentMethod,
  onMount,
  onUnmount,
  initState,
  withOuterPadding,
}: PurchasePaymentMethodsTableProps) {
  const hasPurchasePaymentEditFeature = useUserHasFeature(
    Feature.PurchasePaymentMethodEdit
  );
  const purchasePaymentMethodsText = useContent(ContentId.PaymentMethods);
  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || []
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const {
    openConfirmDialog: openConfirmDeleteDialog,
    closeConfirmDialog: closeConfirmDeleteDialog,
  } = useConfirmDialog();

  const data = useMemo(() => {
    const purchasePaymentActions = {
      editPurchasePayment: onEditPaymentMethod,
      deletePurchasePayment: (purchasePaymentMethodId: number) => {
        openConfirmDeleteDialog({
          size: 's',
          headerText: <Content id={ContentId.DeletePurchasePaymentMethod} />,
          bodyText: <Content id={ContentId.AreYouSure} />,
          onOkay: () => {
            onDeletePaymentMethod?.(
              purchasePaymentMethodId,
              closeConfirmDeleteDialog
            );
          },
          onCancel: closeConfirmDeleteDialog,
          okText: ContentId.Yes,
          cancelText: ContentId.No,
        });
      },
    };
    return purchasePaymentMethods?.map((v) => ({
      purchasePaymentMethod: v,
      purchasePaymentActions,
    }));
  }, [
    closeConfirmDeleteDialog,
    onDeletePaymentMethod,
    onEditPaymentMethod,
    openConfirmDeleteDialog,
    purchasePaymentMethods,
  ]);

  const options: Partial<
    TableOptions<PurchasePaymentMethodWithHandlers | null>
  > = useMemo(
    () => ({
      data,
      columns: hasPurchasePaymentEditFeature
        ? PAYMENT_METHOD_TABLE_COLUMNS_CONFIG
        : PAYMENT_METHOD_TABLE_COLUMNS_CONFIG.filter(
            (column) => column.id !== 'paymentMethodActions'
          ),
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    }),
    [data, hasPurchasePaymentEditFeature, pagination, sorting]
  );

  return purchasePaymentMethods?.length ? (
    <Table options={options} withOuterPadding={withOuterPadding} />
  ) : (
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={purchasePaymentMethodsText}
      />
    </NoData>
  );
}
