import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { MultiSectionsDisplay } from 'src/components/Events/VenueMap';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { VenueMapContentProps } from 'src/components/Events/VenueMap/VenueMapContent';
import { Content } from 'src/contexts/ContentContext';
import { Button, Stack } from 'src/core/ui';
import { ChangeZoneMapSectionGroupDialog } from 'src/dialogs/ChangeZoneMapSectionGroupDialog';
import { useAutoCompMapSectionHandler } from 'src/hooks/useAutoCompMapSectionHandler';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionSectionGroup } from 'src/WebApiController';

import { EventVenueMapFormType } from '../EventVenueMapModal';

export const EventVenueMapContent = ({
  createGetColor,
}: {
  createGetColor: (
    sectionSectionGroupLookup?: {
      [key: string]: SectionSectionGroup;
    } | null
  ) => NonNullable<VenueMapContentProps['getColor']>;
}) => {
  const changeSectionGroupDialog = useBasicDialog();
  const onLaunchingSingleSectionDialog = useCallback(
    () => changeSectionGroupDialog.launchDialog(),
    [changeSectionGroupDialog]
  );

  const {
    selectedSectionIds,
    selectedSectionData,
    onSectionClicked,
    onResetSections,
    onToggleMirrors,
    onSelectedSectionsChange,
  } = useAutoCompMapSectionHandler(onLaunchingSingleSectionDialog);

  const { watch, setValue } = useFormContext<EventVenueMapFormType>();
  const sectionSectionGroupLookup = watch('sectionSectionGroupLookup');

  const onSectionZoneChanged = useCallback(
    (newSectionSectionGroup: SectionSectionGroup, sectionIds: number[]) => {
      const sectionGroupIdMap = sectionSectionGroupLookup;

      const newSectionGroupIdMap = {
        ...sectionGroupIdMap,
        ...sectionIds.reduce(
          (acc: { [key: string]: SectionSectionGroup }, sectionId) => {
            acc[sectionId] = newSectionSectionGroup;
            return acc;
          },
          {}
        ),
      };

      setValue('sectionSectionGroupLookup', newSectionGroupIdMap);

      changeSectionGroupDialog.closeDialog();
    },
    [changeSectionGroupDialog, setValue, sectionSectionGroupLookup]
  );

  return (
    <>
      <EventVenueMap
        selectedSectionIds={selectedSectionIds}
        onSectionClicked={onSectionClicked}
        onToggleMirrors={onToggleMirrors}
        setSelectedSections={onSelectedSectionsChange}
        showDefaultMapColors={true}
        getColor={createGetColor(sectionSectionGroupLookup)}
        staticInfoContent={
          selectedSectionData && (
            <Stack direction="row" gap="l" alignItems="center">
              <MultiSectionsDisplay
                sections={selectedSectionData}
                onClearSelection={onResetSections}
              />
              <Button
                size="md"
                onClick={() => changeSectionGroupDialog.launchDialog()}
              >
                <Content id={ContentId.EditZone} />
              </Button>
            </Stack>
          )
        }
        isRelocatedStaticInfo={true}
      />
      <ChangeZoneMapSectionGroupDialog
        {...changeSectionGroupDialog.dialogProps}
        selectedSections={selectedSectionData ?? []}
        sectionIdMapping={sectionSectionGroupLookup}
        sectionSectionGroupLookup={sectionSectionGroupLookup}
        onOkay={onSectionZoneChanged}
        onCancel={changeSectionGroupDialog.closeDialog}
      />
    </>
  );
};
