import clsx from 'clsx';
import { ComponentPropsWithoutRef, CSSProperties, ReactNode } from 'react';

import { Button } from '../Button';
import { Popover } from '../Popover';
import * as styles from './TooltipPopover.css';

export const TooltipPopover = ({
  triggerContent,
  children,
  onClosed,
  contentVariant,
  contentStyle,
  ...rest
}: ComponentPropsWithoutRef<typeof Button> & {
  triggerContent: ReactNode;
  title?: string;
  onClosed?: () => void;
  contentVariant?: 'theme' | 'light' | 'dark' | 'darkGray';
  contentStyle?: CSSProperties;
}) => {
  return (
    <Popover.Root
      onOpenChange={(open) => {
        if (!open) {
          onClosed?.();
        }
      }}
    >
      <Popover.Trigger asChild>
        <Button
          variant="link"
          shape="none"
          size="unset"
          {...rest}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {triggerContent}
        </Button>
      </Popover.Trigger>
      <Popover.Content
        className={clsx(styles.popoverContentWrapper, {
          [styles.lightContentWrapper]: contentVariant === 'light',
          [styles.darkContentWrapper]: contentVariant === 'dark',
          [styles.darkGrayContentWrapper]: contentVariant === 'darkGray',
        })}
        style={contentStyle}
        side="bottom"
        align="start"
        sideOffset={4}
        arrowPadding={8}
      >
        {children}
        <Popover.Arrow
          className={clsx(styles.contentArrow, {
            [styles.lightContentArrow]: contentVariant === 'light',
            [styles.darkContentArrow]: contentVariant === 'dark',
            [styles.darkGrayContentArrow]: contentVariant === 'darkGray',
          })}
        />
      </Popover.Content>
    </Popover.Root>
  );
};
