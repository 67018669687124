import { useState } from 'react';
import { useBulkPricingActionsPermissions } from 'src/components/common/BulkActions/BulkPricingActions/useBulkPricingActionsPermissions';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { useBulkChangeListingPriceAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkChangeListingPriceAction';
import { useBulkUpdateMarketplacePrices } from 'src/components/Listings/InventoryActionDropdown/components/BulkChangeMarketplacePriceAction/indext';
import { useBulkEditAutoPricingSettings } from 'src/components/Listings/InventoryActionDropdown/components/BulkEditAutoPricingSettingsAction';
import { useBulkUpdateListingSettings } from 'src/components/Listings/InventoryActionDropdown/components/BulkUpdateListingSettingsAction';
import { LaunchAutoCompSettings } from 'src/components/Listings/InventoryActionDropdown/components/GroupListingsAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useBulkChangeFloorCeilingActions } from 'src/hooks/useBulkChangeFloorCeilingActions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';
import { AutoCompAndAutoGroupMode, EventWithData } from 'src/WebApiController';

interface PricingActionsProps {
  event: EventWithData['event'];
}

export const PricingActions = ({ event }: PricingActionsProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const { affectedVisibleListingIds, filterQueryWithEventIds } =
    useInventoryEventBulkActionsState(event);

  const [isLoading, setIsLoading] = useState(false);

  const bulkUpdateListingPrice = useBulkChangeListingPriceAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  const { hasEditPricingPermission } = useBulkPricingActionsPermissions();

  const bulkEditAutoPricingSettings = useBulkUpdateListingSettings(
    'autoPricing',
    filterQueryWithEventIds,
    affectedVisibleListingIds,
    setIsLoading,
    isLoading,
    event
  );
  const bulkEditAutoPricingSettingsV2 = useBulkEditAutoPricingSettings(
    filterQueryWithEventIds,
    affectedVisibleListingIds,
    setIsLoading,
    isLoading,
    event
  );

  const bulkChangeFloorCeilingActions = useBulkChangeFloorCeilingActions({
    event,
    setIsLoading,
    isLoading,
    filterQueryWithEventIds,
  });

  const bulkUpdateMarketplacePrices = useBulkUpdateMarketplacePrices(
    filterQueryWithEventIds,
    affectedVisibleListingIds,
    setIsLoading,
    isLoading,
    event
  );

  const hasAutoGroupAndCompFeatureV2 = useUserHasFeature(
    Feature.AutoGroupAndCompV2
  );

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const hasAutoPricingBulkEditReworkFeature = useUserHasFeature(
    Feature.AutoPricingBulkEditRework
  );

  const hasHideAutoCompOnlyFeature = useUserHasFeature(
    Feature.HideAutoCompOnlyBulkEditOption
  );
  const hasZoneMapEdit = useUserHasFeature(Feature.ZoneMapEdit);

  return (
    <>
      <PosDropdown
        key="event-pricing-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Pricing} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {bulkUpdateListingPrice.dropDown}
        {hasEditPricingPermission && bulkChangeFloorCeilingActions.dropDown}
        {hasAutoPricingBulkEditReworkFeature
          ? bulkEditAutoPricingSettingsV2.dropDown
          : bulkEditAutoPricingSettings.dropDown}
        {bulkUpdateMarketplacePrices.dropDown}
        {hasAutoPricingFeature ? (
          <>
            {hasAutoGroupAndCompFeatureV2 &&
            !hasHideAutoCompOnlyFeature &&
            !hasZoneMapEdit ? (
              <>
                <LaunchAutoCompSettings
                  event={event}
                  mode={AutoCompAndAutoGroupMode.AutoComp}
                />
              </>
            ) : null}
          </>
        ) : null}
      </PosDropdown>
    </>
  );
};
