import { MapIcon } from '@heroicons/react/24/outline';
import { UseQueryResult } from '@tanstack/react-query';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { VENUE_ZONE_CONFIG_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  EventMapConfigMetadata,
  VenueZoneConfigType,
} from 'src/WebApiController';

import { createGetColor } from '../utils/colorUtils';
import * as styles from './EventVenueMapPopup.css';

export const EventVenueMapPopup = ({
  onClick,
  eventMapConfigQuery,
}: {
  onClick: () => void;
  eventMapConfigQuery: UseQueryResult<EventMapConfigMetadata | null, Error>;
}) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [popupPosition, setPopupPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const { isDarkMode } = useSiteTheme();

  const updatePosition = useCallback(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPopupPosition({
        top: rect.bottom + window.scrollY + 8,
        left: rect.left + rect.width / 2 - 250 / 2 + window.scrollX,
      });
    }
  }, []);

  useEffect(() => {
    if (isHovered) {
      window.addEventListener('scroll', updatePosition);
      window.addEventListener('resize', updatePosition);

      updatePosition();
    }
    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isHovered, updatePosition]);

  const selectedConfigType = useMemo(() => {
    if (!eventMapConfigQuery?.data) {
      return VenueZoneConfigType.SportsOrConcertsWith360;
    }

    return (
      eventMapConfigQuery.data.selectedVenueMapConfigType ??
      eventMapConfigQuery.data.defaultVenueZoneConfigType
    );
  }, [eventMapConfigQuery.data]);

  const configTypeContentId = useMemo(() => {
    return VENUE_ZONE_CONFIG_TYPE_TO_CID[selectedConfigType];
  }, [selectedConfigType]);

  const configTypeString = useContent(configTypeContentId);

  const configTypeFormatted = useMemo(() => {
    if (!eventMapConfigQuery?.data) {
      return null;
    }

    if (eventMapConfigQuery.data.selectedOverrideId != null) {
      return eventMapConfigQuery.data.selectedOverrideName;
    }

    if (
      eventMapConfigQuery.data.selectedVenueMapConfigType != null &&
      eventMapConfigQuery.data.selectedVenueMapConfigType !=
        VenueZoneConfigType.SportsOrConcertsWith360
    ) {
      return `${configTypeString}`;
    }

    return `${configTypeString}°`;
  }, [configTypeString, eventMapConfigQuery]);

  return (
    <div
      ref={buttonRef}
      className={styles.hoverContainer}
      onMouseEnter={() => {
        setIsExiting(false);
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsExiting(true);
        setTimeout(() => {
          setIsHovered(false);
          setIsExiting(false);
        }, 200);
      }}
    >
      <Button variant="outline" onClick={onClick}>
        <Stack alignItems="center" gap="m">
          <Content id={ContentId.ViewEventMap} />
          <MapIcon className={styles.mapIcon} />
        </Stack>
      </Button>
      {(isHovered || isExiting) &&
        popupPosition &&
        createPortal(
          <Stack
            className={clsx(
              isDarkMode
                ? styles.eventMapHoverPopupDark
                : styles.eventMapHoverPopup,
              isExiting
                ? styles.eventMapHoverPopupExiting
                : styles.eventMapHoverPopupEntering
            )}
            style={{
              top: popupPosition.top,
              left: popupPosition.left,
            }}
            alignItems="center"
            direction="column"
          >
            <EventVenueMap
              isZoomEnabled={false}
              isWhiteBackground={!isDarkMode}
              getColor={createGetColor(
                eventMapConfigQuery.data?.selectedVenueMapConfig
              )}
            />
            <span className={styles.venueConfigText}>
              <Content id={ContentId.VenueConfiguration} />
            </span>
            {configTypeFormatted}
          </Stack>,
          document.body
        )}
    </div>
  );
};
