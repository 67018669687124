import { ComponentProps, useCallback, useContext } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { SplitListingModal } from 'src/modals/SplitListing';
import { SplitIcon } from 'src/svgs/SplitIcon';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  ListingDetailDataField,
  ListingDetails,
} from 'src/WebApiController';

export const LaunchSplitListing = ({
  listingId,
  marketplaceListingId,
  cancelTo,
  variant,
  iconOnlyMode,
  ...buttonProps
}: {
  listingId: number;
  marketplaceListingId?: string | null;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
} & ComponentProps<typeof SplitListingModal>) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const splitListing = useCallback(() => {
    setModal({
      children: <SplitListingModal cancelTo={cancelTo} />,
      clickOutsideToClose: true,
      size: 'slide-in',
      deepLinkValue: listingId,
      trackViewEntityType: ActionOutboxEntityType.Listing,
    });
  }, [setModal, cancelTo, listingId]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(listingId, marketplaceListingId, false, [
        ListingDetailDataField.Basic,
        ListingDetailDataField.Tickets,
      ]);
      splitListing();
    },
    [setActivePosEntity, listingId, marketplaceListingId, splitListing]
  );

  return (
    <ButtonWithIcon
      {...buttonProps}
      variant={variant}
      iconOnlyMode={iconOnlyMode}
      onClick={onLaunchDialog}
      textContentId={ContentId.Split}
      icon={
        <SplitIcon
          withHoverEffect
          stroke={
            iconOnlyMode || (variant && variant !== 'regular')
              ? vars.color.textBrand
              : vars.color.textInverted
          }
          size={vars.iconSize.m}
        />
      }
    />
  );
};
