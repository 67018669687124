import { useMutation } from '@tanstack/react-query';
import { cloneDeep } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { useSyncCenterSettings } from 'src/hooks/api/useSyncCenterSetting';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Feature,
  SellerAccountClient,
  SyncCenterSettings,
} from 'src/WebApiController';

import { SyncCenterFieldValues } from '../SyncCenter/SyncCenter.types';
import {
  setValueAsNumber,
  toSyncCenterFieldValues,
  toSyncCenterSettings,
} from '../SyncCenter/SyncCenter.utils';
import * as styles from './PurchaseOrderSetting.css';

export function PurchaseOrderGeneralSettingForm() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const hasAutoPoFeature = useUserHasFeature(Feature.AutoPO);

  const query = useSyncCenterSettings();

  const formDefaultValues = useMemo(() => {
    if (query.data) {
      return toSyncCenterFieldValues(cloneDeep(query.data));
    }
    return undefined;
  }, [query.data]);

  const mutation = useMutation({
    mutationFn: async (data: SyncCenterSettings) => {
      return new SellerAccountClient(activeAccountWebClientConfig)
        .mergeSyncCenterSettings(data)
        .then(() => query.refetch());
    },
    onError: (err: ErrorTypes, data) => {
      showErrorDialog('SellerAccountClient.mergeSyncCenterSettings', err, {
        trackErrorData: { data },
      });
    },
  });

  const { control, register, handleSubmit, reset } =
    useForm<SyncCenterFieldValues>({
      defaultValues: formDefaultValues,
    });

  useEffect(() => {
    reset(formDefaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDefaultValues]);

  if (query.isLoading) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionName}>
        <Content id={ContentId.Purchase} />
      </div>
      <div className={styles.form}>
        <PosFormField
          label={<Content id={ContentId.InHandDaysBeforeEvent} />}
          className={styles.textField}
        >
          <PosTextField
            disabled={mutation.isPending}
            type="number"
            {...register('inHandDaysBeforeEvent', {
              setValueAs: setValueAsNumber,
            })}
          />
        </PosFormField>
        {hasAutoPoFeature && (
          <Controller
            control={control}
            name="isAutoPoQualityControlled"
            render={({ field: { value, onChange, ...field } }) => (
              <Checkbox
                disabled={mutation.isPending}
                labelPosition="right"
                label={<Content id={ContentId.EnableQualityControlForAutoPo} />}
                checked={Boolean(value)}
                onChange={(e) => {
                  onChange(e.currentTarget.checked);
                }}
                {...field}
              />
            )}
          />
        )}

        <div className={styles.footer}>
          <Button
            disabled={mutation.isPending}
            onClick={handleSubmit((data) => {
              mutation.mutate(toSyncCenterSettings(data));
            })}
          >
            <Content id={ContentId.Save} />
          </Button>
        </div>
      </div>
    </div>
  );
}
