import { useMemo } from 'react';
import {
  EventItemHeader,
  EventItemHeaderType,
} from 'src/components/Events/EventItemHeader/EventItemHeader';
import { MetricsDisplay } from 'src/components/Metrics';
import { Content } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { PurchaseDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { createPurchaseEventPageUrl } from 'src/utils/purchaseUtils';
import { Event, Performer, Venue } from 'src/WebApiController';

import * as styles from './PurchaseEventItemHeader.css';

export type PurchaseEventItemHeaderProps = Pick<
  EventItemHeaderType,
  'event' | 'performer' | 'venue' | 'hideYearFromDate'
> & {
  cost?: string;
  numOfTicketGroups?: number;
  numOfTickets?: number;
  disabled?: boolean;
  onAddTicketsForEvent?: (
    event: Event,
    performer?: Performer | null,
    venue?: Venue | null
  ) => void;
  removeMetrics?: boolean;
};

export const PurchaseEventItemHeader = ({
  event,
  performer,
  venue,
  numOfTicketGroups,
  numOfTickets,
  onAddTicketsForEvent,
  cost,
  disabled,
  removeMetrics,
  ...rest
}: PurchaseEventItemHeaderProps) => {
  const purchaseEventUrl = useMemo(() => {
    const eventId = event?.viagVirtualId;
    if (eventId) {
      return createPurchaseEventPageUrl(eventId, [PurchaseDeeplinkQueryParam]);
    }
    return undefined;
  }, [event]);

  const actions =
    !disabled && onAddTicketsForEvent
      ? [
          <span key="StubHub">
            <Button
              className={styles.eventActionButton}
              variant={'outline'}
              onClick={() => onAddTicketsForEvent(event, performer, venue)}
            >
              <Content id={ContentId.AddTicketsForThisEvent} />
            </Button>
          </span>,
        ]
      : undefined;
  return (
    <EventItemHeader
      showYear
      event={event}
      performer={performer}
      venue={venue}
      eventNameLink={purchaseEventUrl}
      {...rest}
      actions={actions}
      metrics={
        !removeMetrics && (
          <MetricsDisplay
            key="metrics"
            mainMetrics={cost}
            subMetricBelow
            subMetrics={
              <div className={styles.purchaseTicketCountsContainer}>
                {numOfTicketGroups ? (
                  <div>
                    {numOfTicketGroups}{' '}
                    <Content
                      id={
                        numOfTicketGroups > 1
                          ? ContentId.TicketGroups
                          : ContentId.TicketGroup
                      }
                    />
                  </div>
                ) : null}
                {numOfTickets ? (
                  <div>
                    {numOfTickets}{' '}
                    <Content
                      id={
                        numOfTickets > 1 ? ContentId.Tickets : ContentId.Ticket
                      }
                    />
                  </div>
                ) : null}
              </div>
            }
          />
        )
      }
    />
  );
};
