import { MapIcon } from '@heroicons/react/24/outline';
import { UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  Content,
  useContent,
  useFormattedContent,
} from 'src/contexts/ContentContext';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { VENUE_ZONE_CONFIG_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  EventMapConfigMetadata,
  VenueZoneConfigType,
} from 'src/WebApiController';

import * as styles from './NewEventMapBanner.css';

export const NewEventMapBanner = ({
  openEventMapView,
  eventMapConfigQuery,
}: {
  openEventMapView: () => void;
  eventMapConfigQuery: UseQueryResult<EventMapConfigMetadata | null, Error>;
}) => {
  const configTypeContentId = useMemo(() => {
    if (!eventMapConfigQuery.data) {
      return VENUE_ZONE_CONFIG_TYPE_TO_CID[
        VenueZoneConfigType.SportsOrConcertsWith360
      ];
    }
    return VENUE_ZONE_CONFIG_TYPE_TO_CID[
      eventMapConfigQuery.data.selectedVenueMapConfigType ??
        eventMapConfigQuery.data.defaultVenueZoneConfigType
    ];
  }, [eventMapConfigQuery.data]);

  const configTypeString = useContent(configTypeContentId);
  const configTypeFormatted = useMemo(() => {
    if (!eventMapConfigQuery?.data) {
      return null;
    }

    if (
      eventMapConfigQuery.data.selectedVenueMapConfigType != null &&
      eventMapConfigQuery.data.selectedVenueMapConfigType !=
        VenueZoneConfigType.SportsOrConcertsWith360
    ) {
      return `${configTypeString}`;
    }

    return `${configTypeString}°`;
  }, [configTypeString, eventMapConfigQuery]);

  const defaultConfigType = useFormattedContent(
    FormatContentId.FirstTimeVenueEventSetup,
    configTypeFormatted ?? ''
  ) as string;

  if (
    !eventMapConfigQuery.data ||
    eventMapConfigQuery.isLoading ||
    eventMapConfigQuery.isError ||
    eventMapConfigQuery.data.selectedOverrideId != null ||
    eventMapConfigQuery.data.selectedVenueMapConfigType != null
  ) {
    return null;
  }

  return (
    <div className={styles.bannerContainer}>
      <Stack alignItems="center" justifyContent="spaceBetween">
        <Stack alignItems="center" gap="m">
          <MapIcon className={styles.icon} />
          <span>{defaultConfigType}</span>
        </Stack>

        <Button variant="link" onClick={openEventMapView}>
          <Content id={ContentId.ViewEventMap} />
        </Button>
      </Stack>
    </div>
  );
};
