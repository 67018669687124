import { Label } from '@radix-ui/react-label';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { SimpleTable, Switch } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { Event, Marketplace } from 'src/WebApiController';

import * as styles from './Broadcast.css';

type BroadcastMarketplaceProps = {
  marketplace: Marketplace;
  isSelected: boolean;
  isActive: boolean;
  event?: Event;
  isUKEvent?: boolean;
  isViagogo?: boolean;
  onListingBroadcastToggle: (
    isSelected: boolean,
    isBroadcasting: boolean,
    marketplace: Marketplace
  ) => void;
};

export const BroadcastMarketplace: React.FC<BroadcastMarketplaceProps> = ({
  marketplace,
  isSelected,
  isActive,
  event,
  isUKEvent,
  isViagogo,
  onListingBroadcastToggle,
}) => {
  const alwaysShowAsDelisted =
    isUKEvent && marketplace === Marketplace.StubHub && !isViagogo;

  const isChecked = !alwaysShowAsDelisted && isActive && isSelected;

  return (
    <SimpleTable.Tr
      className={styles.broadcastRow}
      key={`${marketplace}-${isViagogo}`}
    >
      <SimpleTable.Td className={styles.broadcastCell}>
        <Checkbox
          checked={isSelected}
          onChange={() =>
            onListingBroadcastToggle(!isSelected, isChecked, marketplace)
          }
        />
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.broadcastCell}>
        <MarketplaceLogo
          marketplace={marketplace}
          event={event}
          grayScaleColor={!isSelected}
          isViagogo={isViagogo}
        />
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.broadcastCell}>
        <InventoryStatus isSelected={isSelected} isActive={isChecked} />
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.broadcastCell}>
        <Switch
          checked={isChecked}
          disabled={!isSelected}
          onChange={(e) => e.stopPropagation()}
          onCheckedChange={(checked) => {
            onListingBroadcastToggle(isSelected, checked, marketplace);
          }}
        />
      </SimpleTable.Td>
    </SimpleTable.Tr>
  );
};

type InventoryStatusProps = {
  isSelected: boolean;
  isActive: boolean;
};

const InventoryStatus: React.FC<InventoryStatusProps> = ({
  isSelected,
  isActive,
}) => {
  if (!isSelected) {
    return undefined;
  }
  return (
    <Label className={styles.inventoryStatusLabel}>
      <Content id={isActive ? ContentId.Visible : ContentId.Hidden} />
    </Label>
  );
};
