import { useCallback } from 'react';
import { LaunchBulkUpdatePurchaseCurrencyCode } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkUpdatePurchaseCurrencyCodeAction/LaunchBulkUpdatePurchaseCurrencyCode';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { InfoMessage } from 'src/core/POS/MessageWithIcon/InfoMessage';
import { BulkUpdatePurchaseCurrencyCodeDialog } from 'src/dialogs/BulkEdits/BulkUpdatePurchaseCurrencyCodeDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { PURCHASE_BULK_UPDATE_PURCHASE_CURRENCY_CODE_KEY } from '../../PurchaseActionDropdown.constants';

export const useBulkUpdatePurchaseCurrencyCode = (
  filterQuery: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = `${PURCHASE_BULK_UPDATE_PURCHASE_CURRENCY_CODE_KEY}${
    groupId ? `-${groupId}` : ''
  }`;

  const { showErrorDialog } = useErrorBoundaryContext();
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const {
    openDialog: openBulkUpdatePurchaseCurrencyCode,
    closeDialog: closeBulkMarkPurchaseAsPaidDialog,
  } = useDialog(
    DialogId.BulkUpdatePurchaseCurrencyCode,
    BulkUpdatePurchaseCurrencyCodeDialog
  );

  const onSubmit = useCallback(
    async (
      supportBackgroundProcess: boolean,
      onPreviewReceived:
        | ((preview: BulkEditPreviewWithDetails) => void)
        | undefined,
      preview: BulkEditPreviewWithDetails | undefined,
      currencyCode: string
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const bulkEditClient = new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            );

            const preview =
              await bulkEditClient.bulkUpdateCurrencyCodePreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateCurrencyCodePreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const bulkEditClient = new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            );

            const succeeded = await bulkEditClient.bulkUpdateCurrencyCode(
              {
                item1: preview!.preview,
                item2: currencyCode,
              },
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                await refreshData(true);
                await refreshExpandedListItems();
              }

              closeBulkMarkPurchaseAsPaidDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateCurrencyCode', error, {
              trackErrorData: {
                preview,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      filterQuery,
      refreshData,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
      updateKey,
      closeBulkMarkPurchaseAsPaidDialog,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkUpdatePurchaseCurrencyCode
        key="purchaseBulkUpdateCurrencyCode"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkUpdatePurchaseCurrencyCode({
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            entityType: ActionOutboxEntityType.Purchase,
            infoMessage: (
              <InfoMessage
                message={<Content id={ContentId.UpdatePurchaseCurrency} />}
              />
            ),
            onCancel: closeBulkMarkPurchaseAsPaidDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
