import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { getContent, useContentContext } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { VENUE_ZONE_SECTION_GROUP_AREA_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { SectionSectionGroup } from 'src/WebApiController';

import { VenueZoneMapFormType } from '../InventoryEventVenueZoneMapSection';
import { SortableZoneAreaItem } from './SortableZoneAreaItem';
import * as styles from './VenueZoneSectionGroupDraggableList.css';

export const VenueZoneSectionGroupDraggableList = () => {
  const contentContext = useContentContext();
  const { setValue, watch } = useFormContext<VenueZoneMapFormType>();

  const sectionSectionGroupLookup = watch('sectionSectionGroupLookup');
  const customSectionGroups = watch('customSectionGroups');

  const sectionGroups = Object.values(sectionSectionGroupLookup).reduce<{
    [key: string]: SectionSectionGroup;
  }>((acc, v) => {
    let sectionGroupName = `${v.ticketClassName}: ${v.sectionGroupAreaName}`;
    if (v.sectionGroupAreaType != null) {
      const sectionGroupAreaContentId =
        VENUE_ZONE_SECTION_GROUP_AREA_TYPE_TO_CID[v.sectionGroupAreaType];

      sectionGroupName = `${v.ticketClassName}: ${getContent(
        sectionGroupAreaContentId,
        contentContext
      )}`;
    }
    acc[sectionGroupName] = v;

    return acc;
  }, {});

  const sortabledSectionGroups = useMemo(() => {
    // Sort the sectionGroups by sectionGroupRank and areaRank
    const sortedSectionGroups = Object.entries(sectionGroups).map(
      ([key, value]) => ({
        id: crypto.randomUUID(),
        key,
        ...value,
      })
    );

    // Map over customSectionGroups and add unique IDs
    const customGroupsWithId = customSectionGroups.map((csg) => ({
      id: crypto.randomUUID(),
      key: `${csg.ticketClassName}: ${csg.sectionGroupAreaName}`,
      ...csg,
    }));

    // Combine the sorted section groups and custom section groups
    return sortedSectionGroups
      .concat(customGroupsWithId)
      .sort(
        (a, b) =>
          a.sectionGroupRank - b.sectionGroupRank || a.areaRank - b.areaRank
      );
  }, [sectionGroups, customSectionGroups]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over != null && active.id != over.id) {
        const oldIndex = sortabledSectionGroups.findIndex(
          (ro) => ro.id === active.id
        );

        const newIndex = sortabledSectionGroups.findIndex(
          (ro) => ro.id === over.id
        );

        const shiftedRankOverrides = arrayMove(
          sortabledSectionGroups,
          oldIndex,
          newIndex
        );

        // Update ranks based on new positions
        const updatedRankOverrides = shiftedRankOverrides
          .map((item, index) => ({
            ...item,
            sectionGroupRank: index,
          }))
          .reduce<{
            [key: string]: number;
          }>((acc, v) => {
            const ssgId = `${v.ticketClassName ?? ''}:${
              v.ticketClassId ?? ''
            }:${v.sectionGroupAreaName ?? ''}:${v.sectionGroupAreaType ?? ''}`;

            acc[ssgId] = v.sectionGroupRank;
            return acc;
          }, {});

        // Update the original sectionSectionGroupLookup with the new ranks
        const newSectionSectionGroupLookup = Object.entries(
          sectionSectionGroupLookup
        ).reduce<{
          [key: string]: SectionSectionGroup;
        }>((acc, [sectId, ssg]) => {
          const ssgId = `${ssg.ticketClassName ?? ''}:${
            ssg.ticketClassId ?? ''
          }:${ssg.sectionGroupAreaName ?? ''}:${
            ssg.sectionGroupAreaType ?? ''
          }`;

          const sgRank = updatedRankOverrides[ssgId] ?? 1;
          ssg.sectionGroupRank = sgRank;

          acc[sectId] = ssg;
          return acc;
        }, {});

        setValue('sectionSectionGroupLookup', newSectionSectionGroupLookup);

        const newCustomSectionGroups = customSectionGroups.reduce<
          SectionSectionGroup[]
        >((acc, ssg) => {
          const ssgId = `${ssg.ticketClassName ?? ''}:${
            ssg.ticketClassId ?? ''
          }:${ssg.sectionGroupAreaName ?? ''}:${
            ssg.sectionGroupAreaType ?? ''
          }`;

          const sgRank = updatedRankOverrides[ssgId] ?? 1;
          ssg.sectionGroupRank = sgRank;

          acc.push(ssg);
          return acc;
        }, []);

        setValue('customSectionGroups', newCustomSectionGroups);
      }
    },
    [
      customSectionGroups,
      sectionSectionGroupLookup,
      setValue,
      sortabledSectionGroups,
    ]
  );

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={sortabledSectionGroups}
        strategy={verticalListSortingStrategy}
      >
        <Stack direction="column" className={styles.sortableZoneAreaContainer}>
          {sortabledSectionGroups.map((ssg) => (
            <SortableZoneAreaItem
              key={ssg.id}
              id={ssg.id}
              label={`${ssg.key}`}
              color={ssg.sectionGroupColor}
            />
          ))}
        </Stack>
      </SortableContext>
    </DndContext>
  );
};
