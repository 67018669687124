import { ComponentProps, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button, SimpleTable, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { SplitListingInput } from 'src/WebApiController';

export type SplitListingToOriginalDialogProps = ComponentProps<
  typeof RSModal
> & {
  splitListingInput?: SplitListingInput | null;
  isDataLoading?: boolean;
  onOkay: () => Promise<void>;
  onCancel: () => void;
};

export function SplitListingToOriginalDialog({
  splitListingInput,
  isDataLoading,
  onOkay,
  onCancel,
  ...rest
}: SplitListingToOriginalDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <GenericDialog
      {...rest}
      size="m"
      header={<Content id={ContentId.ResetToOriginal} />}
      onOpened={() => {}}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      footer={
        <>
          <Button
            variant="outline"
            onClick={() => {
              onCancel();
            }}
            disabled={isLoading || isDataLoading}
          >
            <Content id={ContentId.Cancel} />
          </Button>

          <Button
            disabled={isLoading || isDataLoading}
            onClick={async () => {
              setIsLoading(true);
              await onOkay();
              setIsLoading(false);
            }}
          >
            <Content id={ContentId.Confirm} />
          </Button>
        </>
      }
      onCancel={isLoading || isDataLoading ? undefined : onCancel}
    >
      <Stack direction="column" gap="m">
        {isLoading || isDataLoading ? (
          <PosSpinner />
        ) : (
          <>
            <Content id={ContentId.ListingWillBeSplitInto} />
            <SimpleTable.Table>
              <SimpleTable.Thead>
                <SimpleTable.Tr>
                  <SimpleTable.Th style={{ width: '33%' }}>
                    <Content id={ContentId.Row} />
                  </SimpleTable.Th>
                  <SimpleTable.Th style={{ width: '33%' }}>
                    <Content id={ContentId.Seat} />
                  </SimpleTable.Th>
                  <SimpleTable.Th style={{ width: '33%' }}>
                    <Content id={ContentId.Listing} />
                  </SimpleTable.Th>
                </SimpleTable.Tr>
              </SimpleTable.Thead>
              <SimpleTable.Tbody>
                {(splitListingInput?.ticketsByListingNumber ?? []).map(
                  (ticketListingNumber) => {
                    return (
                      <SimpleTable.Tr key={ticketListingNumber.listingNumber}>
                        <SimpleTable.Td>
                          {ticketListingNumber.ticket.row}
                        </SimpleTable.Td>
                        <SimpleTable.Td>
                          {ticketListingNumber.ticket.seat}
                        </SimpleTable.Td>
                        <SimpleTable.Td>
                          {ticketListingNumber.listingNumber}
                        </SimpleTable.Td>
                      </SimpleTable.Tr>
                    );
                  }
                )}
              </SimpleTable.Tbody>
            </SimpleTable.Table>
          </>
        )}
      </Stack>
    </GenericDialog>
  );
}
