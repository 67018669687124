export function LyteSquareLogo({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="3" fill="black" />
      <path
        d="M6.00095 8.61607C6.00096 6.72511 6.00096 4.88933 6.00096 3C7.00573 3 7.97587 3 9.00391 3C9.00391 3.22646 9.00392 3.42443 9.00391 3.62241C9.00385 6.52565 9.01724 9.42902 8.99175 12.332C8.98696 12.8778 9.15707 13.0127 9.66617 12.9984C10.9233 12.9629 12.1819 12.9867 13.44 12.9866C13.6149 12.9866 13.7899 12.9866 14 12.9866C14 14.0078 14 14.9698 14 15.969C13.8133 15.9802 13.6565 15.9975 13.4996 15.9977C11.6485 15.9993 9.79737 16.0023 7.94626 15.9968C6.70422 15.9931 6.00554 15.2807 6.0024 14.0183C5.99796 12.236 6.00106 10.4536 6.00095 8.61607Z"
        fill="#F7F7F7"
      />
    </svg>
  );
}
