import { useCallback, useEffect, useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { COMP_LISTING_MODE_TO_FLUENT_CID } from 'src/utils/constants/contentIdMaps';
import { AutoPricingCompListingMode } from 'src/WebApiController';

import { useAutoPricingSettingsFormContext } from '../useAutoPricingSettingsFormContext';

export const AutoPricingCompListingModeSelector = ({
  allowSeatScoreCompListingMode,
  disabled,
  hideWhenOnlyComparableZonesModeIsAvailable,
  setIsOnlyComparableZonesModeAvailable,
}: {
  allowSeatScoreCompListingMode: boolean;
  disabled: boolean;
  hideWhenOnlyComparableZonesModeIsAvailable?: boolean;
  setIsOnlyComparableZonesModeAvailable?: (valueNew: boolean) => void;
}) => {
  const { formState, setValue, watch } = useAutoPricingSettingsFormContext();

  const initialCompListingMode = formState.defaultValues?.compListingMode;

  const { compListingMode } = watch();

  const { valueOptionsContent, valueOptionsDisabled } = useMemo(() => {
    const compListingModes: Record<string, ContentId> = {
      [AutoPricingCompListingMode.SameEvent]: ContentId.InComparableZones,
    };
    if (allowSeatScoreCompListingMode) {
      compListingModes[AutoPricingCompListingMode.QualityScore] =
        COMP_LISTING_MODE_TO_FLUENT_CID[
          AutoPricingCompListingMode.QualityScore
        ];
    }

    let compListingModesDisabled: Record<string, ContentId> | undefined =
      undefined;
    if (initialCompListingMode && !compListingModes[initialCompListingMode]) {
      const initialModeContentId =
        COMP_LISTING_MODE_TO_FLUENT_CID[initialCompListingMode];
      compListingModesDisabled = {
        [initialCompListingMode]: initialModeContentId,
      };
      compListingModes[initialCompListingMode] = initialModeContentId;
    }

    return {
      valueOptionsContent: compListingModes,
      valueOptionsDisabled: compListingModesDisabled,
    };
  }, [allowSeatScoreCompListingMode, initialCompListingMode]);

  const onCompListingModeChange = useCallback(
    (compListingModeNew: AutoPricingCompListingMode) => {
      setValue('compListingMode', compListingModeNew);

      if (compListingModeNew == AutoPricingCompListingMode.SameEvent) {
        setValue('compListingOnlyForSameZoneEnabled', false);
        setValue('compListingOnlyForSelectedSectionsEnabled', true);
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (!compListingMode) {
      onCompListingModeChange(AutoPricingCompListingMode.SameEvent);
    }
  }, [compListingMode, onCompListingModeChange]);

  const useSelector =
    Object.keys(valueOptionsContent).length > 1 || valueOptionsDisabled;

  useEffect(() => {
    setIsOnlyComparableZonesModeAvailable?.(!useSelector);
  }, [setIsOnlyComparableZonesModeAvailable, useSelector]);

  return useSelector ? (
    <PosEnumSelect
      style={{ width: 'fit-content' }}
      disabled={disabled}
      value={compListingMode}
      valueOptionsContent={valueOptionsContent}
      valueOptionsDisabled={valueOptionsDisabled}
      placeholderText={ContentId.None}
      onClick={(e) => e.stopPropagation()}
      onChange={(v) => onCompListingModeChange(v as AutoPricingCompListingMode)}
    />
  ) : (
    !hideWhenOnlyComparableZonesModeIsAvailable && (
      <Content id={valueOptionsContent[compListingMode as string]} />
    )
  );
};
