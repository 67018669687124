import { HubConnectionState } from '@microsoft/signalr';
import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmButton } from 'src/components/Buttons';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { EventSearch } from 'src/components/Events/EventSearch';
import { INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY } from 'src/components/Listings/InventoryActionDropdown';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import * as Tabs from 'src/core/ui/Tabs';
import { useListingGroupPricingSettings } from 'src/hooks/api/useListingGroupPricingSettings';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { useEventMarketplaceSettings } from 'src/hooks/useEventMarketplaceSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter } from 'src/modals/Modal';
import { CrossIcon, ProcessingIcon } from 'src/svgs/Viagogo';
import { randomizeInPlace } from 'src/utils/arrayUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { newGuidId } from 'src/utils/idUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkActionType,
  EventConfigClient,
  EventWithData,
  GroupingType,
  Listing,
} from 'src/WebApiController';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  Event,
  Feature,
  ListingGroup,
  ListingGroupClient,
  ListingGroupItemInput,
  MergeListingGroupInput,
} from 'src/WebApiController';

import { Summary } from '../common/Summary';
import { VenueConfigSummary } from '../common/Summary/VenueConfigSummary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';
import { AutoPricingSettings } from './components/AutoPricingSettings/AutoPricingSettings';
import {
  GroupingTemplateSeating,
  MergeListingGroupInputListFields,
} from './components/groupingTypes';
import {
  flattenListingGroup,
  generateMergeListingGroupInputsForEvents,
} from './components/groupingUtils';
import { ListingGroupAutoPricingSettingsFormContentWrapper } from './components/ListingGroupAutoPricingSettingsFormContentWrapper/ListingGroupAutoPricingSettingsFormContentWrapper';
import { ListingGroupMarketplaceBroadcast } from './components/MarketplaceBroadcast/ListingGroupMarketplaceBroadcast';
import * as styles from './GroupListings.css';
import { GroupListingsBody } from './GroupListingsBody';

enum GroupListingsTab {
  Groupings = 'groupings',
  Broadcast = 'broadcast',
  AutoPricing = 'autopricing',
}

export const GroupListingsModal = ({
  events,
  listingGroup,
}: {
  // Events is undefined means that we need user to search for events
  events?: Event[];
  listingGroup?: ListingGroup;
}) => {
  const listings = [
    ...(listingGroup?.groupItems ?? []),
    ...(listingGroup?.groupItems?.flatMap((gi) =>
      gi.isLtGrp ? (gi as ListingGroup).groupItems : []
    ) ?? []),
  ];
  const { defaultListingMarketplaceSettings } = useEventMarketplaceSettings(
    events?.[0]
  );

  const newGroupName = useContent(ContentId.NewGroup);
  const methods = useForm<MergeListingGroupInputListFields>({
    defaultValues: {
      mergeListingGroupInputs: listingGroup
        ? [
            {
              name: listingGroup.groupName ?? `${newGroupName} 1`,
              listingGroupItems: listings.map(
                (l) =>
                  ({
                    listingId: l.id,
                    priority: l.ltGrpPrior!,
                    groupId: l.ltGrpId,
                    undercutSetting: l.ltGrpUndercut,
                  }) as ListingGroupItemInput
              ),
              viagogoEventId: events![0].viagId,
              viagogoMappingId: events![0].mappingId,
              desiredActiveListings: listingGroup.desiredActiveListings ?? 1,
              minActiveListings: listingGroup.minActiveListings,
              maxActiveListings: listingGroup.maxActiveListings,
              targetPercentage: listingGroup.targetPercentage,
              deprioritizedQuantities:
                listingGroup.deprioritizedQuantities ?? [],
              marketplaceSettings: listingGroup.marketplaceSettings,
              groupUndercutSetting: {
                undAbsAmt: listingGroup.absoluteRankPremium,
                undRelAmt: listingGroup.relativeRankPremium,
                actRankUndAbsAmt: null,
                actRankUndRelAmt: null,
              },
            },
          ]
        : generateMergeListingGroupInputsForEvents(
            events,
            newGroupName,
            defaultListingMarketplaceSettings
          ),
      templateSettings: {
        groupBy: {
          groupingType: GroupingType.Custom,
        },
        desiredActiveListings: 0,
        deprioritizedQuantities: [],
      },
      eventSettings:
        events?.map(() => ({
          groupBys: [
            {
              groupingType: GroupingType.Custom,
            },
            {
              groupingType: GroupingType.None,
            },
          ],
          desiredActiveListings: 0,
          deprioritizedQuantities: [],
        })) ?? [],
    },
  });

  return (
    <FormProvider {...methods}>
      <GroupListingsModalContent
        {...methods}
        events={events}
        listingGroup={listingGroup}
      />
    </FormProvider>
  );
};

export const GroupListingsModalContent = ({
  listingGroup,
  events,
  formState,
  handleSubmit,
  watch,
  reset,
  getValues,
  setValue,
  setError,
  clearErrors,
}: { events?: Event[] } & Omit<
  ComponentProps<
    typeof FormProvider<MergeListingGroupInputListFields, unknown>
  > & {
    listingGroup?: ListingGroup;
  },
  'children'
>) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const hasListingGroupingMethodsFeature = useUserHasFeature(
    Feature.ListingGroupingMethods
  );

  const hasIntelligibleAutoPricingSettingsFeature = useUserHasFeature(
    Feature.IntelligibleAutoPricingSettings
  );

  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitting } = formState;
  const { setActivePosEntity } = useActivePosEntityContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { closeModal } = useContext(ModalContext);

  const templateSettings = watch('templateSettings');
  const mergeListingGroupInputs = watch('mergeListingGroupInputs');

  const {
    eventsTransformed,
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
    updateExpandedListItems,
  } = useCatalogDataContext();

  const hasAutoPricingGroupSettingsFeature = useUserHasFeature(
    Feature.AutoPricingGroupSettings
  );

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const { defaultListingMarketplaceSettings } = useEventMarketplaceSettings(
    events?.[0]
  );

  const [selectedEvents, setSelectedEvents] = useState<Event[] | undefined>(
    events
  );
  const [eventTabSelected, setEventTabSelected] = useState<
    EventWithData | undefined
  >();
  const [activeTab, setActiveTab] = useState<GroupListingsTab>(
    GroupListingsTab.Groupings
  );

  const newGroupName = useContent(ContentId.NewGroup);

  const onEventsSelect = useCallback(
    (
      data: {
        event: Event;
      }[]
    ) => {
      setListItemExpansion(
        true,
        data.map((ev) => ev.event.viagVirtualId)
      );

      const events = data.map((e) => e.event);
      setSelectedEvents(events);
      reset({
        mergeListingGroupInputs: generateMergeListingGroupInputsForEvents(
          events,
          newGroupName,
          defaultListingMarketplaceSettings
        ),
        templateSettings: {
          groupBy: {
            groupingType: GroupingType.Custom,
          },
          desiredActiveListings: 0,
          deprioritizedQuantities: [],
          groupTemplates: [],
        },
        eventSettings:
          events?.map(() => ({
            groupBys: [
              {
                groupingType: GroupingType.Custom,
              },
              {
                groupingType: GroupingType.None,
              },
            ],
            desiredActiveListings: 0,
            deprioritizedQuantities: [],
          })) ?? [],
      });
    },
    [
      defaultListingMarketplaceSettings,
      newGroupName,
      reset,
      setListItemExpansion,
    ]
  );

  const onEventSummaryClose = useCallback(() => {
    setSelectedEvents(undefined);
  }, []);

  const eventData = useMemo(
    () =>
      selectedEvents
        ? eventsTransformed?.filter((ev) =>
            selectedEvents.some(
              (sev) => ev.event.viagVirtualId === sev.viagVirtualId
            )
          ) ?? []
        : [],
    [eventsTransformed, selectedEvents]
  );

  const requiredMsg = useContent(ContentId.Required);
  useEffect(() => {
    mergeListingGroupInputs.forEach((m, i) => {
      if (m.listingGroupItems.length) {
        if (
          formState.errors.mergeListingGroupInputs?.[i]?.listingGroupItems
            ?.message
        ) {
          clearErrors(`mergeListingGroupInputs.${i}.listingGroupItems`);
        }
      }
      if (m.name) {
        if (formState.errors.mergeListingGroupInputs?.[i]?.name?.message) {
          clearErrors(`mergeListingGroupInputs.${i}.name`);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(mergeListingGroupInputs)]);

  const [formIsDone, setFormIsDone] = useState(false);

  const { setProgress, setPreview, setStage, setMainDialogOpened } =
    useBulkEditHubContext();
  const { bulkEditHub, initJob } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.GroupListings,
    INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY,
    () => {
      setActivePosEntity(0);
      setFormIsDone(true);
      return refreshExpandedListItems();
    }
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const {
    pricingSettings,
    onResetListingPricingSettings,
    onMergeListingGroupPricingSettings,
  } = useListingGroupPricingSettings(listingGroup?.ltGrpId, events?.[0]);

  useEffect(() => {
    if (pricingSettings)
      setValue('pricingSettingsInputs', { ...pricingSettings });
  }, [pricingSettings, setValue]);

  const onSubmit = useCallback(
    (inputFormData: MergeListingGroupInputListFields) => {
      setIsLoading(true);

      tryInvokeApi(
        async () => {
          if (listingGroup) {
            const listingsInGroups =
              inputFormData.mergeListingGroupInputs[0].listingGroupItems.filter(
                (x) => x.listingId > 0
              );
            if (listingsInGroups.length) {
              let updatedListings: {
                [key: string]: Listing[];
              };
              if (listingGroup.ltGrpId) {
                updatedListings = await new ListingGroupClient(
                  activeAccountWebClientConfig
                ).updateListingGroup(listingGroup.ltGrpId!, {
                  ...inputFormData.mergeListingGroupInputs[0],
                  listingGroupItems:
                    inputFormData.mergeListingGroupInputs[0].listingGroupItems
                      .filter((x) => x.listingId > 0)
                      .map((item, index) => ({ ...item, priority: index + 1 })),
                });
              } else {
                updatedListings = await new ListingGroupClient(
                  activeAccountWebClientConfig
                ).createListingGroup({
                  ...inputFormData.mergeListingGroupInputs[0],
                  listingGroupId: newGuidId(),
                  listingGroupItems:
                    inputFormData.mergeListingGroupInputs[0].listingGroupItems
                      .filter((x) => x.listingId > 0)
                      .map((item, index) => ({ ...item, priority: index + 1 })),
                });
              }
              await onMergeListingGroupPricingSettings(
                inputFormData.pricingSettingsInputs
              );

              reset(inputFormData);

              // Reset listing auto pricing setting per request
              if (inputFormData.resetListingAutoPricingSettings) {
                await onResetListingPricingSettings();
              }
              updateExpandedListItems(updatedListings);
              setActivePosEntity(0);

              // kick off a background refresh, but not wait since we already force updateExpandedListItems
              refreshExpandedListItems();

              closeModal();
              setSelectionMode();
            } else {
              return false;
            }
          } else {
            initJob();

            const filteredListingGroupInputs =
              inputFormData.mergeListingGroupInputs
                .map((groupInput) => ({
                  ...groupInput,
                  listingGroupItems: groupInput.listingGroupItems.filter(
                    (x) => x.listingId > 0
                  ),
                }))
                .filter((m) => m.listingGroupItems.length);

            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkCreateListingGroups(
              {
                mergeListingGroupInputs: filteredListingGroupInputs,
              },
              INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY,
              hasBackgroundBulkEditFeature
            );

            reset(inputFormData);

            // Update event grouping methods if applied
            const eventGroupingMethodsList =
              inputFormData.mergeListingGroupInputs
                .map(({ groupingMethods }) => groupingMethods)
                .filter((value) => value != null);
            if (
              hasListingGroupingMethodsFeature &&
              eventGroupingMethodsList &&
              eventGroupingMethodsList.length > 0
            ) {
              const eventGroupingMethods = eventGroupingMethodsList[0];
              for (const method of eventGroupingMethods!.groupingMethods) {
                // event level grouping methods shouldn't care about the grouping value
                method.groupingValue = null;
              }
              try {
                for (const event of eventData) {
                  if (event.event.viagVirtualId) {
                    await new EventConfigClient(
                      activeAccountWebClientConfig
                    ).updateSellerEventGroupingMethods(
                      event.event.viagVirtualId,
                      eventGroupingMethods!
                    );
                  }
                }
              } catch (error) {
                // relax the error for updating the event group methods.
              }
            }

            if (!hasBackgroundBulkEditFeature) {
              if (succeeded) {
                setActivePosEntity(0);
                await refreshExpandedListItems();

                closeModal();
                setSelectionMode();
              }
            }
          }
        },
        (error) => {
          showErrorDialog(
            'BulkEditListingClient.bulkCreateListingGroups',
            error,
            {
              trackErrorData: inputFormData,
            }
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      listingGroup,
      onMergeListingGroupPricingSettings,
      reset,
      updateExpandedListItems,
      setActivePosEntity,
      refreshExpandedListItems,
      closeModal,
      setSelectionMode,
      activeAccountWebClientConfig,
      onResetListingPricingSettings,
      initJob,
      hasBackgroundBulkEditFeature,
      hasListingGroupingMethodsFeature,
      eventData,
      showErrorDialog,
    ]
  );

  const validateForErrors = useCallback(() => {
    let hasErrors = false;
    mergeListingGroupInputs.forEach((m, i) => {
      if (!m.name) {
        setError(`mergeListingGroupInputs.${i}.name`, {
          message: requiredMsg,
        });
        hasErrors = true;
      }
    });

    return !hasErrors;
  }, [mergeListingGroupInputs, requiredMsg, setError]);

  const applyTemplateConfirmDialog = useBasicDialog();
  const [applyThenSubmit, setApplyThenSubmit] = useState(false);
  const [isApplyingTemplate, setIsApplyingTemplate] = useState(false);

  const onApplyTemplateToGroupings = useCallback(
    (prompt: boolean) => {
      setIsApplyingTemplate(true);

      if (
        prompt &&
        mergeListingGroupInputs.some((m) => m.listingGroupItems?.length)
      ) {
        applyTemplateConfirmDialog.launchDialog();
        return false;
      }

      try {
        templateSettings.groupTemplates.forEach((t) => {
          // Normalize the seatings into a map for faster lookup
          t.seatingsMap = t.seatings.reduce(
            (r, s) => {
              r.sectionRowKeys[s.id] = s;
              if (s.seating.rowId) {
                r.rowIdKeys[s.seating.rowId] = s;
              }

              return r;
            },
            { sectionRowKeys: {}, rowIdKeys: {} } as {
              sectionRowKeys: Record<string, GroupingTemplateSeating>;
              rowIdKeys: Record<number, GroupingTemplateSeating>;
            }
          );
        });

        // For each event
        // For each template
        // Get all listings matching the template and create a new group
        const newGroups = eventData.flatMap((ev) =>
          templateSettings.groupTemplates
            .map((t) => {
              const listingsInGroup =
                ev.listings
                  ?.flatMap(flattenListingGroup)
                  ?.map((l) => {
                    if (
                      l.seating.rowId &&
                      t.seatingsMap?.rowIdKeys[l.seating.rowId]
                    ) {
                      return {
                        listing: l,
                        seating: t.seatingsMap?.rowIdKeys[l.seating.rowId],
                      };
                    }

                    const key = `${l.seating.section}-${l.seating.row}`;
                    if (t.seatingsMap?.sectionRowKeys[key]) {
                      return {
                        listing: l,
                        seating: t.seatingsMap?.sectionRowKeys[key],
                      };
                    }

                    return null;
                  })
                  ?.filter((x) => x != null)
                  ?.map((x) => x!) ?? [];

              // If there are any listings - create group
              if (listingsInGroup.length) {
                let listingInputs = listingsInGroup.map((l) => ({
                  listingId: l.listing.id,
                  priority: l.seating.priority,
                  groupId: l.listing.ltGrpId,
                  // TODO - SUP-149 - template undercut settings?
                }));

                if (templateSettings.randomizePriority) {
                  randomizeInPlace(listingInputs);
                  listingInputs = listingInputs.map((x, i) => ({
                    ...x,
                    item2: i + 1,
                  }));
                }
                return {
                  listingGroupId: newGuidId(),
                  name: t.groupName,
                  viagogoEventId: ev.event.viagId,
                  viagogoMappingId: ev.event.mappingId,
                  desiredActiveListings: t.desiredActiveListings,
                  deprioritizedQuantities: t.deprioritizedQuantities,
                  marketplaceSettings: {
                    listingMarketplaceSettings:
                      defaultListingMarketplaceSettings,
                  },
                  listingGroupItems: listingInputs,
                } as MergeListingGroupInput;
              }

              return null;
            })
            .filter((x) => x != null)
            .map((x) => x!)
        );

        setValue('mergeListingGroupInputs', newGroups);
      } finally {
        setIsApplyingTemplate(false);
      }

      return true;
    },
    [
      applyTemplateConfirmDialog,
      defaultListingMarketplaceSettings,
      eventData,
      mergeListingGroupInputs,
      setValue,
      templateSettings.groupTemplates,
      templateSettings.randomizePriority,
    ]
  );

  console.log(listingGroup);

  return (
    <>
      <CancellableFormHeader
        disabled={isLoading || isSubmitting}
        onBeforeClose={() => {
          setProgress(undefined);
          setPreview(undefined);
          setStage(BulkEditStage.Idle);
          setMainDialogOpened(false);
        }}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.GroupListings} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        {!eventData.length ? (
          <ModalBodyDataContainer style={{ height: '100%' }}>
            <EventSearch
              onEventsSelect={onEventsSelect}
              selectedEvents={[]}
              numEventsSelected={0}
              eventsToSearchFrom={(eventsTransformed ?? []).filter(
                (ev) => ev.listCnt > 1
              )}
              isSingleSelect
              allowSearchByVenueConfig
              searchPlaceHolder={ContentId.SearchVenueConfigOrEvent}
            />
          </ModalBodyDataContainer>
        ) : (
          <>
            <ModalBodyHeaderContainer>
              {eventData.length === 1 ? (
                <Summary
                  event={eventData[0].event}
                  actions={
                    events == null && (
                      <div
                        onClick={
                          isLoading || isSubmitting
                            ? undefined
                            : onEventSummaryClose
                        }
                        className={styles.nowrap}
                      >
                        <CrossIcon size={vars.iconSize.m} withHoverEffect />
                      </div>
                    )
                  }
                />
              ) : (
                <VenueConfigSummary
                  events={eventData.map((ev) => ev.event)}
                  eventSelected={eventTabSelected?.event}
                  actions={
                    events == null && (
                      <div
                        onClick={
                          isLoading || isSubmitting
                            ? undefined
                            : onEventSummaryClose
                        }
                        className={styles.nowrap}
                      >
                        <CrossIcon size={vars.iconSize.m} withHoverEffect />
                      </div>
                    )
                  }
                />
              )}
            </ModalBodyHeaderContainer>
            <ModalBodyDataContainer>
              {isLoading || !eventData.some((ev) => ev.listings?.length) ? (
                <PosSpinner />
              ) : (
                <BulkEditStatus
                  entityType={ActionOutboxEntityType.Listing}
                  updateKey={INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY}
                  height={'full'}
                >
                  {
                    // Even that we have only 1 event-map context, this is ok because it is expected // that eventData is a list of events with the same venue config
                  }
                  <EventMapContextProvider event={eventData[0].event}>
                    <Tabs.Root
                      value={activeTab}
                      onValueChange={(value) =>
                        setActiveTab(value as GroupListingsTab)
                      }
                      style={{ height: '100%' }}
                    >
                      <Tabs.List>
                        <Tabs.Trigger value={GroupListingsTab.Groupings}>
                          <Content id={ContentId.GroupListings} />
                        </Tabs.Trigger>
                        <Tabs.Trigger value={GroupListingsTab.Broadcast}>
                          <Content id={ContentId.Broadcast} />
                        </Tabs.Trigger>
                        {listingGroup &&
                          hasAutoPricingGroupSettingsFeature &&
                          hasAutoPricingFeature && (
                            <Tabs.Trigger value={GroupListingsTab.AutoPricing}>
                              <Content id={ContentId.AutopricingSettings} />
                            </Tabs.Trigger>
                          )}
                      </Tabs.List>
                      <Tabs.Content
                        value={GroupListingsTab.Groupings}
                        style={{ height: '90%' }}
                      >
                        <GroupListingsBody
                          events={eventData}
                          listingGroup={listingGroup}
                          onEventSelected={setEventTabSelected}
                          isViewingTemplate={
                            eventData.length > 1 && !eventTabSelected?.event
                          }
                        />
                      </Tabs.Content>
                      <Tabs.Content
                        value={GroupListingsTab.Broadcast}
                        style={{ height: '90%' }}
                      >
                        <ListingGroupMarketplaceBroadcast
                          event={eventData?.[0]?.event}
                        />
                      </Tabs.Content>
                      {listingGroup &&
                        hasAutoPricingGroupSettingsFeature &&
                        hasAutoPricingFeature && (
                          <Tabs.Content
                            value={GroupListingsTab.AutoPricing}
                            style={{ height: '90%' }}
                          >
                            {hasIntelligibleAutoPricingSettingsFeature ? (
                              <ListingGroupAutoPricingSettingsFormContentWrapper
                                listingGroup={listingGroup}
                                disabled={isLoading || isSubmitting}
                              />
                            ) : (
                              <AutoPricingSettings
                                listingGroup={listingGroup}
                              />
                            )}
                          </Tabs.Content>
                        )}
                    </Tabs.Root>
                  </EventMapContextProvider>
                </BulkEditStatus>
              )}
            </ModalBodyDataContainer>
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          disabled={isLoading || isSubmitting}
          onBeforeClose={() => {
            setProgress(undefined);
            setPreview(undefined);
            setStage(BulkEditStage.Idle);
            setMainDialogOpened(false);
          }}
        >
          {eventData.length > 1 && !eventTabSelected?.event && (
            <Button
              onClick={() => {
                setApplyThenSubmit(false);
                onApplyTemplateToGroupings(true);
              }}
              variant="outline"
              disabled={
                isLoading ||
                isSubmitting ||
                templateSettings.groupTemplates
                  .map((m) => m.seatings.length)
                  .reduce((sum, cur) => sum + cur, 0) === 0
              }
            >
              {isApplyingTemplate && (
                <RotatingWrapper>
                  <ProcessingIcon size={vars.iconSize.l} />
                </RotatingWrapper>
              )}
              <Content id={ContentId.ApplyToAllEvents} />
            </Button>
          )}
          {!formIsDone && (
            <ConfirmButton
              onClick={() => {
                setMainDialogOpened(true);
                if (eventData.length > 1 && !eventTabSelected?.event) {
                  setApplyThenSubmit(true);

                  // If on the template tab
                  if (onApplyTemplateToGroupings(true)) {
                    if (validateForErrors()) {
                      handleSubmit(onSubmit)();
                    }
                  }
                } else {
                  setApplyThenSubmit(false);
                  if (validateForErrors()) {
                    handleSubmit(onSubmit)();
                  }
                }
              }}
              icon={
                isApplyingTemplate ? (
                  <RotatingWrapper>
                    <ProcessingIcon size={vars.iconSize.l} />
                  </RotatingWrapper>
                ) : undefined
              }
              disabled={
                isLoading ||
                isSubmitting ||
                mergeListingGroupInputs
                  .map((m) => m.listingGroupItems.length)
                  .reduce((sum, cur) => sum + cur, 0) === 0
              }
              textContentId={ContentId.Save}
            />
          )}
        </CancellableFormFooter>

        <ConfirmDialog
          {...applyTemplateConfirmDialog.dialogProps}
          headerText={<Content id={ContentId.ApplyToAllEvents} />}
          bodyText={
            <Content id={ContentId.ApplyGroupingTemplatesToAllEventsPrompt} />
          }
          onOkay={() => {
            onApplyTemplateToGroupings(false);
            if (applyThenSubmit) {
              if (validateForErrors()) {
                handleSubmit(onSubmit)();
              }
            }
            applyTemplateConfirmDialog.closeDialog();
          }}
          onCancel={() => applyTemplateConfirmDialog.closeDialog()}
        />
      </ModalFooter>
    </>
  );
};
