import { Content } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { Slider } from 'src/core/ui/Slider';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';

import * as styles from '../AutoPricing.css';
import { useAutoPricingSettingsFormContext } from '../useAutoPricingSettingsFormContext';

const DEFAULT_COMP_LISTING_FLOOR = 1;
const DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR = 5;

const SLIDER_MIN = -1;
const SLIDER_MAX = 5;

const convertSliderValueToServer = (val: number) =>
  roundToPrecision(val / 100 + 1, 2);
const convertSliderValueFromServer = (val: number) => (val - 1) * 100;

export const AutoPricingCompFloorAndCeilingInput = ({
  disabled,
}: {
  disabled: boolean;
}) => {
  const { setValue, watch, formState } = useAutoPricingSettingsFormContext();

  const {
    compListingMode,
    compListingFloor: nullableCompListingFloor,
    compListingCeiling: nullableLCompListingCeiling,
  } = watch();

  const compListingFloor =
    nullableCompListingFloor ?? DEFAULT_COMP_LISTING_FLOOR;
  const compListingCeiling =
    nullableLCompListingCeiling ??
    DEFAULT_COMP_LISTING_FLOOR + DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR;

  const compListingFloorError = formState.errors.compListingFloor?.message;

  return (
    <>
      <Stack gap="l">
        <div className={styles.inlinePricingInputText}>
          <Content id={ContentId.Between} />
        </div>
        <PosFormField
          errors={compListingFloorError}
          style={{ width: 'fit-content' }}
        >
          <PosTextField
            rootProps={{
              state: getTextFieldState(compListingFloorError),
              style: { maxWidth: '100px' },
            }}
            type="number"
            value={
              compListingMode == null
                ? ''
                : roundToPrecision(
                    convertSliderValueFromServer(compListingFloor),
                    2
                  )
            }
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              if (!isNaN(v)) {
                if (
                  v >= SLIDER_MIN * 100 &&
                  v <= SLIDER_MAX * 100 &&
                  convertSliderValueToServer(v) < compListingCeiling
                ) {
                  setValue('compListingFloor', convertSliderValueToServer(v));
                }
              }
            }}
            postfixDisplay="%"
          />
        </PosFormField>
        <div className={styles.inlinePricingInputTextLowercase}>
          <Content id={ContentId.And} />
        </div>
        <PosFormField style={{ width: 'fit-content' }}>
          <PosTextField
            rootProps={{
              style: { maxWidth: '100px' },
            }}
            type="number"
            value={
              compListingMode == null
                ? ''
                : roundToPrecision(
                    convertSliderValueFromServer(compListingCeiling),
                    2
                  )
            }
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              if (!isNaN(v)) {
                if (
                  v >= SLIDER_MIN * 100 &&
                  v <= SLIDER_MAX * 100 &&
                  convertSliderValueToServer(v) > compListingFloor
                ) {
                  setValue('compListingCeiling', convertSliderValueToServer(v));
                }
              }
            }}
            postfixDisplay="%"
          />
        </PosFormField>
        <div className={styles.inlinePricingInputTextLowercase}>
          <Content id={ContentId.OfMyListingQuality} />
        </div>
      </Stack>
      <Stack direction="column" gap="m">
        <Slider.Root
          disabled={disabled}
          min={SLIDER_MIN * 100}
          max={SLIDER_MAX * 100}
          step={0.01}
          minStepsBetweenThumbs={0.01}
          value={[compListingFloor, compListingCeiling].map(
            convertSliderValueFromServer
          )}
          renderHandle={(val) => (
            <Slider.Handle>{roundToPrecision(val, 0)}%</Slider.Handle>
          )}
          onValueChange={([floor, ceil]) => {
            setValue('compListingFloor', convertSliderValueToServer(floor));
            setValue('compListingCeiling', convertSliderValueToServer(ceil));
          }}
        />
        <div className={styles.yourListingIndicatorContainer}>
          <Content id={ContentId.YourListing} />
          <div className={styles.yourListingIndicatorArrow} />
        </div>
      </Stack>
    </>
  );
};
