import { isEmpty } from 'lodash-es';
import {
  ListingGroupItemInput,
  ListingGroupUndercutSetting,
} from 'src/WebApiController';

export const withGroupUndercustSettings = (
  groupUndercutSetting: ListingGroupUndercutSetting | null,
  listingGroupItems: ListingGroupItemInput[]
): ListingGroupItemInput[] => {
  if (isEmpty(listingGroupItems)) {
    return listingGroupItems;
  }
  const { undAbsAmt, undRelAmt } = groupUndercutSetting ?? {};
  return listingGroupItems.map((listing, index): ListingGroupItemInput => {
    const undercutSetting: ListingGroupUndercutSetting = {
      undAbsAmt: undAbsAmt ? undAbsAmt * index : null,
      undRelAmt: undRelAmt ? Math.min(undRelAmt * index, 1) : null,
      actRankUndAbsAmt: null,
      actRankUndRelAmt: null,
    };
    return {
      ...listing,
      undercutSetting,
    };
  });
};
