import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { VenueMapContentProps } from 'src/components/Events/VenueMap/VenueMapContent';
import { Content, useContent } from 'src/contexts/ContentContext';
import { ErrorTypes } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { CollapsibleSettting } from 'src/core/ui/CollapsibleSetting/CollapsibleSetting';
import { SectionRowTableDialog } from 'src/dialogs/SectionRowTableDialog/SectionRowTableDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { Summary } from 'src/modals/common/Summary';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AutoPricingInputs,
  Event,
  SectionSectionGroup,
  VenueMapZoneConfigInfo,
  VenueZoneConfigOverride,
  VenueZoneConfigType,
} from 'src/WebApiController';

import { useVenueSectionRow } from '../../Sections/VenueZoneMap/hooks/useVenueSectionRow';
import { EventVenueMapFormType } from '../EventVenueMapModal';
import { EventMapDetailSimpleActionGroup } from './EventMapDetailSimpleActionGroup';
import * as styles from './EventVenueMapDetailsPanel.css';
import { EventVenueMapDetailsPanelFooter } from './EventVenueMapDetailsPanelFooter';
import { EventVenueMapSelector } from './EventVenueMapSelector';
import { SectionGroupPanel } from './SectionGroupPanel';

export const EventVenueMapDetailsPanel = ({
  event,
  selectedOverrideId,
  setSelectedOverrideId,
  selectedConfigType,
  setSelectedConfigType,
  createGetColor,
  pricingSettingsQuery,
  venueZoneMapConfigInfoQuery,
  venueZoneMapConfigOverrideInfoQuery,
  setVenueZoneConfigTypeOverrideMutation,
}: {
  event: Event;
  selectedOverrideId: number | null;
  setSelectedOverrideId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedConfigType: VenueZoneConfigType | null;
  setSelectedConfigType: React.Dispatch<
    React.SetStateAction<VenueZoneConfigType | null>
  >;
  createGetColor: (
    sectionSectionGroupLookup?: {
      [key: string]: SectionSectionGroup;
    } | null
  ) => NonNullable<VenueMapContentProps['getColor']>;
  pricingSettingsQuery: UseQueryResult<AutoPricingInputs | null, Error>;
  venueZoneMapConfigInfoQuery: UseQueryResult<
    VenueMapZoneConfigInfo | null,
    Error
  >;
  venueZoneMapConfigOverrideInfoQuery: UseQueryResult<
    {
      [key: string]: VenueZoneConfigOverride;
    } | null,
    Error
  >;
  setVenueZoneConfigTypeOverrideMutation: UseMutationResult<
    void,
    ErrorTypes,
    {
      eventId?: number | null;
      venueZoneOverrideId?: number | null;
      venueZoneConfigType?: VenueZoneConfigType | null;
      setMapAsDefault?: boolean | null;
    },
    unknown
  >;
}) => {
  const eventMapText = useContent(ContentId.EventMap);
  const zoneMapText = useContent(ContentId.ZoneMap);
  const venueRowDataText = useContent(ContentId.VenueRowData);
  const venueRowDataDescText = useContent(ContentId.RawVenueCatData);
  const setAsDefaultText = useContent(ContentId.SetMapAsDefault);
  const setAsDefaultDescText = useContent(ContentId.SimilarEventsDefaults);

  const sectionRowDialog = useBasicDialog();

  const { scoreOverrides, onSectionRowChange, venueMapInfo } =
    useVenueSectionRow({
      event,
      pricingSettingsQuery,
    });

  const { closeModal } = useContext(ModalContext);

  const { watch, setValue } = useFormContext<EventVenueMapFormType>();
  const setMapAsDefault = watch('setMapAsDefault');

  return (
    <>
      <Stack direction="column" height="full" justifyContent="spaceBetween">
        <Stack direction="column" gap="m" alignItems="start">
          <Stack
            className={styles.headerContainer}
            alignItems="center"
            width="full"
            justifyContent="spaceBetween"
          >
            <span>
              <Content id={ContentId.EventMap} />
            </span>

            <CrossIcon
              withHoverEffect
              size={vars.iconSize.m}
              onClick={() => {
                closeModal();
              }}
            />
          </Stack>
          <div className={styles.summary}>
            <Summary event={event} />
          </div>
          <CollapsibleSettting
            title={
              <span className={styles.groupHeaderText}>
                {eventMapText}: {zoneMapText}
              </span>
            }
            altTitle={
              <span className={styles.groupHeaderText}>{eventMapText}</span>
            }
            content={
              <EventVenueMapSelector
                event={event}
                selectedOverrideId={selectedOverrideId}
                setSelectedOverrideId={setSelectedOverrideId}
                selectedConfigType={selectedConfigType}
                setSelectedConfigType={setSelectedConfigType}
                createGetColor={createGetColor}
                venueZoneMapConfigInfoQuery={venueZoneMapConfigInfoQuery}
                venueZoneMapConfigOverrideInfoQuery={
                  venueZoneMapConfigOverrideInfoQuery
                }
                setVenueZoneConfigTypeOverrideMutation={
                  setVenueZoneConfigTypeOverrideMutation
                }
              />
            }
          />

          <div className={styles.dividerLine} />

          <SectionGroupPanel />

          <div className={styles.dividerLine} />

          <EventMapDetailSimpleActionGroup
            header={venueRowDataText}
            subHeader={venueRowDataDescText}
            action={
              <Button variant="outline" onClick={sectionRowDialog.launchDialog}>
                <Content id={ContentId.View} />
              </Button>
            }
          />

          <div className={styles.dividerLine} />

          <EventMapDetailSimpleActionGroup
            header={setAsDefaultText}
            subHeader={setAsDefaultDescText}
            action={
              <Checkbox
                checked={setMapAsDefault}
                onChange={() => {
                  const isChecked = !setMapAsDefault;
                  setValue('setMapAsDefault', isChecked);
                }}
              />
            }
          />
        </Stack>
        <EventVenueMapDetailsPanelFooter
          event={event}
          selectedOverrideId={selectedOverrideId}
          setSelectedOverrideId={setSelectedOverrideId}
          setSelectedConfigType={setSelectedConfigType}
          pricingSettingsQuery={pricingSettingsQuery}
          venueZoneMapConfigInfoQuery={venueZoneMapConfigInfoQuery}
          venueZoneMapConfigOverrideInfoQuery={
            venueZoneMapConfigOverrideInfoQuery
          }
          setVenueZoneConfigTypeOverrideMutation={
            setVenueZoneConfigTypeOverrideMutation
          }
        />
      </Stack>
      <SectionRowTableDialog
        {...sectionRowDialog.dialogProps}
        sections={venueMapInfo?.sections ?? []}
        scoreOverrides={scoreOverrides}
        onClose={sectionRowDialog.closeDialog}
        onSectionRowChange={onSectionRowChange}
      />
    </>
  );
};
