import { PosIconProps } from './SvgWrapper';

export const QuickAtionShieldIcon = (props: Omit<PosIconProps, 'viewBox'>) => {
  return (
    <svg
      width={props.width ?? '18'}
      height={props.height ?? '20'}
      viewBox="0 0 18 20"
      fill={props.fill ?? '#1A2329'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.53024 1.65998L9.00018 0L16.4701 1.65998C16.8861 1.75242 17.182 2.12134 17.182 2.54743V11.6263C17.182 13.45 16.2705 15.1531 14.7531 16.1647L9.00018 20L3.24727 16.1647C1.72982 15.1531 0.818359 13.45 0.818359 11.6263V2.54743C0.818359 2.12134 1.1143 1.75242 1.53024 1.65998ZM2.63654 3.27667V11.6263C2.63654 12.8421 3.24418 13.9775 4.25581 14.6519L9.00018 17.8148L13.7445 14.6519C14.7562 13.9775 15.3638 12.8421 15.3638 11.6263V3.27667L9.00018 1.86254L2.63654 3.27667ZM9.90927 8.18182H12.6365L8.09109 14.5455V10H5.36381L9.90927 3.63636V8.18182Z"
        fill={props.fill ?? '#1A2329'}
      />
    </svg>
  );
};
