import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { MarketplacePaymentClient } from 'src/WebApiController';

export function useGetMarketplacePaymentById(
  marketplacePaymentId?: number | null
) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  return useQuery({
    queryKey: ['getMarketplacePaymentById', marketplacePaymentId],
    async queryFn() {
      if (
        marketplacePaymentId == null ||
        !activeAccountWebClientConfig?.activeAccountId
      )
        return null;

      try {
        return await new MarketplacePaymentClient(
          activeAccountWebClientConfig
        ).getMarketplacePaymentById(marketplacePaymentId);
      } catch (err: unknown) {
        trackError(
          'MarketplacePaymentClient.getMarketplacePaymentById',
          err as ErrorTypes,
          {
            marketplacePaymentId,
          }
        );
        throw err;
      }
    },
    enabled:
      !!activeAccountWebClientConfig?.activeAccountId && !!marketplacePaymentId,
    refetchOnWindowFocus: false,
  });
}
