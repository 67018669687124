import { ChangeEvent, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AutoPricingSettingsFormContent,
  AutoPricingSettingsFormContentCoreProps,
  PricingSetting,
} from 'src/components/AutoPricing/AutoPricingSettingsFormContent/AutoPricingSettingsFormContent';
import { Content } from 'src/contexts/ContentContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { Stack, Switch } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getAllInPriceFromListPrice,
  getListPriceFromAllinPrice,
} from 'src/utils/inventoryUtils';
import {
  Listing,
  ListingDetailsAutoPricingSectionUpdates,
  UiCurrency,
} from 'src/WebApiController';

import * as styles from './ListingAutoPricingSettingsFormContent.css';

export type ListingAutoPricingSettingsFormContentProps =
  AutoPricingSettingsFormContentCoreProps & {
    listing: Listing | null | undefined;
    isGlobalEdit: boolean | undefined;
    sellerFee: number | null | undefined;
    autoPricingStrategySection?: React.ReactNode;
  };

export const ListingAutoPricingSettingsFormContent = ({
  listing,
  disabled,
  sellerFee,
  autoPricingStrategySection,
  ...rest
}: ListingAutoPricingSettingsFormContentProps) => {
  const { watch, setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();

  const { autoPricingEnabled } = watch();

  return (
    <>
      <AutoPricingSettingsFormContent
        disabled={disabled}
        autoPricingSectionChildren={
          <>
            {autoPricingStrategySection && (
              <PricingSetting
                header={<Content id={ContentId.StrategyName} />}
                detail={autoPricingStrategySection}
              />
            )}

            <PricingSetting
              header={<Content id={ContentId.EnableAutoPricing} />}
              detail={
                <Switch
                  disabled={disabled}
                  checked={!!autoPricingEnabled}
                  onClick={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked: boolean) => {
                    if (isChecked !== autoPricingEnabled) {
                      setValue('autoPricingEnabled', isChecked);
                    }
                  }}
                />
              }
            />
          </>
        }
        pricingProtectionSectionChildren={
          <PricingProtectionSettings
            listing={listing}
            sellerFee={sellerFee}
            uiCurrency={rest.uiCurrency}
            disabled={disabled}
          />
        }
        {...rest}
      />
    </>
  );
};

const PricingProtectionSettings = ({
  listing,
  sellerFee,
  uiCurrency,
  disabled,
}: {
  listing: Listing | null | undefined;
  sellerFee: number | null | undefined;
  uiCurrency: UiCurrency;
  disabled: boolean;
}) => {
  const { watch, setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();

  const { netProceedsFloor, netProceedsCeiling } = watch();

  const allInFloor = useMemo(() => {
    return getAllInPriceFromListPrice(netProceedsFloor, listing, sellerFee);
  }, [listing, netProceedsFloor, sellerFee]);
  const allInCeiling = useMemo(() => {
    return getAllInPriceFromListPrice(netProceedsCeiling, listing, sellerFee);
  }, [listing, netProceedsCeiling, sellerFee]);

  const onPriceValueChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement>,
      valueName: 'netProceedsFloor' | 'netProceedsCeiling'
    ) => {
      const v = parseFloat(e.target.value);
      if (v >= 0 && v <= Number.MAX_VALUE) {
        const netProceedsValueNew = getListPriceFromAllinPrice(
          v,
          listing,
          sellerFee
        )!;
        setValue(valueName, netProceedsValueNew);
      } else {
        setValue(valueName, null);
      }
    },
    [listing, sellerFee, setValue]
  );

  return (
    <>
      <PricingSetting
        header={<Content id={ContentId.DontPriceBelow} />}
        detail={
          <Stack gap="l" alignItems="center">
            <PosFormField style={{ width: 'fit-content' }}>
              <PosCurrencyField
                disabled={disabled}
                placeholder={undefined}
                uiCurrency={uiCurrency}
                value={allInFloor ?? ''}
                onChange={(e) => onPriceValueChange(e, 'netProceedsFloor')}
              />
            </PosFormField>
            <div className={styles.inlinePricingInputText}>
              <Content id={ContentId.OrAbove} />
            </div>
            <PosFormField style={{ width: 'fit-content' }}>
              <PosCurrencyField
                disabled={disabled}
                placeholder={undefined}
                uiCurrency={uiCurrency}
                value={allInCeiling ?? ''}
                onChange={(e) => onPriceValueChange(e, 'netProceedsCeiling')}
              />
            </PosFormField>
          </Stack>
        }
      />
    </>
  );
};
