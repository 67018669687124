import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Feature, PricingClient } from 'src/WebApiController';

import { useUserHasFeature } from '../useUserHasFeature';

interface UseEventMapConfigMetadataProps {
  viagogoEventId: number | null;
}

export const useEventMapConfigMetadata = ({
  viagogoEventId,
}: UseEventMapConfigMetadataProps) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const hasZoneMapV2Feature = useUserHasFeature(Feature.ZoneMapV2);

  const shouldQuery =
    hasZoneMapV2Feature &&
    viagogoEventId != null &&
    activeAccountWebClientConfig?.activeAccountId != null;

  const queryKey = ['PricingClient.GetEventMapConfigMetadata', viagogoEventId];

  const eventMapConfigQuery = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      return await new PricingClient(
        activeAccountWebClientConfig
      ).getEventMapConfigMetadata(viagogoEventId!);
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('PricingClient.GetEventMapConfigMetadata', error, {
          trackErrorData: {
            viagogoEventId,
          },
        });
      },
    },
  });

  return {
    queryKey,
    eventMapConfigQuery,
  };
};
