import { ContentId } from 'src/utils/constants/contentId';

import { ListingGroupType, SplitZoneByType } from './GroupListingsV2.types';

export const GroupTypeToCID: Partial<Record<ListingGroupType, ContentId>> = {
  [ListingGroupType.ZoneMap]: ContentId.ZoneMap,
  [ListingGroupType.Section]: ContentId.Section,
  [ListingGroupType.Zone]: ContentId.Zone,
  [ListingGroupType.Row]: ContentId.Row,
  [ListingGroupType.Quantity]: ContentId.Quantity,
  [ListingGroupType.UnitCost]: ContentId.UnitCost,
  [ListingGroupType.None]: ContentId.None,
};

export const SplitZoneByTypeToCid: Record<SplitZoneByType, ContentId> = {
  [SplitZoneByType.RowRanges]: ContentId.RowRanges,
};
