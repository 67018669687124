import { useMemo } from 'react';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE } from 'src/tables/PurchaseTable/PurchaseTable.types';
import { TableShimmeringDiv } from 'src/tables/Table';
import { Commission } from 'src/WebApiController';

interface CommissionsCellProps {
  commissions: Commission[] | null;
}

export const CommissionsCell = ({ commissions }: CommissionsCellProps) => {
  const usersIds = useMemo(
    () => (commissions ?? [])?.map((commission) => commission.buyerUserId),
    [commissions]
  );

  const { isLoading: isLoadingUsers, data: usersMap } =
    useGetUserInfos(usersIds);

  return useMemo(() => {
    if (!commissions) {
      return null;
    }

    if (isLoadingUsers || !usersMap) {
      return (
        <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
      );
    }

    return commissions
      .map(
        (commission: Commission) =>
          `${usersMap[commission.buyerUserId]?.name} ${
            commission.commissionPercentage * 100
          }%`
      )
      .join(', ');
  }, [commissions, isLoadingUsers, usersMap]);
};
