import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Event, Feature, Listing, PricingClient } from 'src/WebApiController';

import { useUserHasAutopricingFeature } from './useUserHasAutopricingFeature';

export const useGetListingAutoPricingSettings = (
  listing: Listing | null | undefined,
  event: Event | null | undefined
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const hasIntelligibleAutoPricingSettingsFeature =
    useUserHasAutopricingFeature(Feature.IntelligibleAutoPricingSettings);

  const shouldQuery = !(
    !activeAccountWebClientConfig.activeAccountId ||
    !listing?.id ||
    !event?.viagId ||
    !hasIntelligibleAutoPricingSettingsFeature
  );
  const pricingSettingsQuery = useQuery({
    queryKey: [
      'PricingClient.getListingAutoPricingSettings',
      activeAccountWebClientConfig.activeAccountId,
      listing?.id,
      listing?.rowVer,
      event?.viagId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const pricingSettings = await new PricingClient(
        activeAccountWebClientConfig
      ).getListingAutoPricingSettings(listing?.id, event!.viagId!);

      return pricingSettings;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('PricingClient.getListingAutoPricingSettings', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
            listingId: listing?.id,
            rowVer: listing?.rowVer,
            viagogoEventId: event?.viagId,
          },
        });
      },
    },
  });

  return {
    pricingSettings: pricingSettingsQuery.data,
    pricingSettingsQuery,
    loaded: pricingSettingsQuery.isFetched,
  };
};
