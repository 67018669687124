import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  DocumentFileUploadInfo,
  UploadETicketForm,
  UploadETicketsSeatAssignmentBody,
} from 'src/components/UploadArtifacts/UploadETicketsV2';
import { UploadETicketsContextProvider } from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETicketsContext';
import { Stack } from 'src/core/ui';
import { PurchaseWizardFooter } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { toEntityWithTicket } from 'src/utils/ticketUtils';
import {
  ActionOutboxEntityType,
  DocumentProcessorClient,
  DocumentUploadInfo,
  FileParameter,
  PosClientConfig,
  PosUiActionResult,
  TicketType,
  UserDocumentLinks,
} from 'src/WebApiController';

import {
  ticketGroupInputFooter,
  ticketGroupPredeliveryBody,
} from '../PurchaseTicketFlow.css';

export interface UploadETicketsSectionV2Props {
  ticketGroupIndex: number;
  ticketType: TicketType.ETicket | TicketType.QRCode;
  onCancel: () => void;
}

export const UploadETicketsSectionV2 = ({
  ticketGroupIndex,
  ticketType,
  onCancel,
}: UploadETicketsSectionV2Props) => {
  const { setValue, watch } = useFormContext<PurchaseTicketsInput>();
  const ticketGroup = watch(`ticketGroups.${ticketGroupIndex}`);

  const onUploadETickets = (
    _: PosClientConfig,
    entityId: number,
    etickets: UserDocumentLinks[]
  ) => {
    const newTickets = ticketGroup.tickets?.value?.map((t) => {
      const tb = etickets.find((b) => b.entityId === t.id);
      if (tb) {
        if (ticketType === TicketType.ETicket) {
          t.eTicket = tb;
        } else {
          t.qrCode = tb;
        }
        return { ...t };
      }
      return t;
    });

    setValue(
      `ticketGroups.${ticketGroupIndex}.tickets`,
      posChangedField(newTickets ?? null)
    );

    setValue('updatePredeliveryETicketArtifacts', true);

    return Promise.resolve({
      status: 200,
      entityId,
      entityType: ActionOutboxEntityType.Purchase,
    } as PosUiActionResult);
  };

  const onUploadDocuments = (
    client: DocumentProcessorClient,
    docUploadInfo: DocumentUploadInfo,
    file: FileParameter
  ) =>
    client.uploadDocumentForListing(
      docUploadInfo.entityId,
      ActionOutboxEntityType.Listing,
      docUploadInfo.documentType,
      docUploadInfo.blobName,
      docUploadInfo.documentId,
      docUploadInfo.contentType,
      file
    );

  const entityWithTickets = useMemo(
    () => toEntityWithTicket(ticketGroup),
    [ticketGroup]
  );

  return (
    <UploadETicketsContextProvider entityWithTickets={entityWithTickets}>
      <UploadETicketForm
        entityWithTickets={entityWithTickets}
        ticketType={ticketType}
        onComplete={onCancel}
        onUploadDocuments={onUploadDocuments}
        onUploadETickets={onUploadETickets}
        renderContent={(
          eTicketUploadInfos: DocumentFileUploadInfo[],
          setETicketUploadInfos: (infos: DocumentFileUploadInfo[]) => void,
          onNext: () => void,
          onCancel: () => void,
          disabled?: boolean
        ) => (
          <Stack direction="column" height="full" justifyContent="spaceBetween">
            <UploadETicketsSeatAssignmentBody
              className={ticketGroupPredeliveryBody}
              entityWithTickets={entityWithTickets}
              ticketType={ticketType}
              eTicketUploadInfos={eTicketUploadInfos}
              setETicketUploadInfos={setETicketUploadInfos}
            />

            <PurchaseWizardFooter
              className={ticketGroupInputFooter}
              disabled={disabled}
              onPrevious={onCancel}
              onPreviousLabel={ContentId.Cancel}
              onNext={onNext}
              onNextLabel={ContentId.Save}
            />
          </Stack>
        )}
      />
    </UploadETicketsContextProvider>
  );
};
