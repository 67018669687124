export function TickPickSquareLogo({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_581_3694)">
        <rect width="20" height="20" rx="3" fill="#DBEDFF" />
        <path
          opacity="0.968"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.87046 4C5.83899 4 6.8075 4 7.77602 4C7.92326 4.67852 8.31066 4.84801 8.93825 4.50847C9.13042 4.38993 9.22726 4.22044 9.2288 4C10.3426 4 11.4564 4 12.5702 4C11.7297 5.42514 11.3422 6.97477 11.408 8.6489C10.1269 8.7932 8.81944 8.84163 7.48547 8.79418C6.31893 9.67308 5.59254 10.8353 5.3063 12.2809C5.07757 12.2174 4.90808 12.0721 4.79782 11.845C4.39481 11.138 4.08004 10.4116 3.85352 9.66585C3.85352 8.55204 3.85352 7.43825 3.85352 6.32445C4.10652 5.52638 4.4455 4.75157 4.87046 4Z"
          fill="#3298FE"
        />
        <path
          opacity="0.973"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4081 8.64885C12.6227 8.60073 13.8333 8.64915 15.0401 8.79412C16.6097 11.5309 16.5613 14.2428 14.8948 16.9297C14.0716 16.9297 13.2483 16.9297 12.4251 16.9297C12.2322 16.3843 11.8448 16.2148 11.2629 16.4212C11.0871 16.5722 10.9418 16.7417 10.827 16.9297C9.56795 16.9297 8.30889 16.9297 7.0498 16.9297C8.04737 15.6058 8.53164 14.1046 8.50259 12.4261C8.54727 11.1074 8.2083 9.8968 7.48564 8.79412C8.81961 8.84157 10.1271 8.79315 11.4081 8.64885Z"
          fill="#0062BB"
        />
        <path
          opacity="0.935"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.48581 8.79395C8.20847 9.89662 8.54745 11.1073 8.50276 12.4259C7.4104 12.4731 6.34503 12.4247 5.30664 12.2806C5.59288 10.8351 6.31927 9.67285 7.48581 8.79395Z"
          fill="#002967"
        />
      </g>
      <defs>
        <clipPath id="clip0_581_3694">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
