import { clsx } from 'clsx';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { PosLink } from 'src/core/POS/PosLink';
import { Button, SimpleTable, Stack } from 'src/core/ui';
import { Detail, DetailGroup } from 'src/modals/common';
import * as styles from 'src/modals/SaleDetails/components/MarketplacePaymentSection/MarketplacePaymentSection.css';
import {
  SetMarketplaceCharge,
  SetMarketplaceChargeActions,
} from 'src/modals/SaleDetails/components/MarketplacePaymentSection/SetMarketplaceCharge';
import { DeleteIcon, EditIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKETPLACE_PAYMENT_LINE_TYPE_TO_CID,
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { getMarketplacePaymentRelativeUrl } from 'src/utils/marketplacePaymentUtils';
import { formatCurrency } from 'src/utils/numberFormatter';
import { addMoneySameCurrency } from 'src/utils/ticketMetricUtils';
import {
  MarketplacePaymentState,
  SaleDetails,
  SaleInput,
  SiteMarketplacePaymentLineInput,
  UiMoney,
} from 'src/WebApiController';

interface MarketplacePaymentProps {
  sale: SaleDetails;
  canManageMarketplacePayment: boolean;
}

export const MarketplacePaymentLines = ({
  sale,
  canManageMarketplacePayment,
}: MarketplacePaymentProps) => {
  const setMarketplaceChargeActions =
    useRef<SetMarketplaceChargeActions | null>(null);
  const { timeZone } = useSiteTimezoneContext();
  const { watch, formState } = useFormContext<SaleInput>();
  const marketplacePaymentLines = watch('marketplacePaymentLines');
  const marketplacePaymentLinesFormFieldDirty =
    !!formState.dirtyFields.marketplacePaymentLines;

  const formatDateCell = useCallback(
    (date: string | null) => {
      return (
        date &&
        formatInTimeZone(stringToUtcDate(date), timeZone, 'MMM d, yyyy', {
          locale: getLocaleFromLanguageOrCurrent(),
        })
      );
    },
    [timeZone]
  );

  const allMkpLinesHaveSameCurrency = useMemo<boolean>(() => {
    const currencies = new Set<string>();
    const saleMkpLines = sale?.mkpLines ?? [];
    saleMkpLines.forEach((line) => {
      if (line.amt.currency) {
        currencies.add(line.amt.currency);
      }
    });
    return currencies.size <= 1;
  }, [sale]);

  const mkpLinesTotal = useMemo<string | undefined | null>(() => {
    if (!marketplacePaymentLinesFormFieldDirty) {
      return sale?.mkpLinesTotal?.disp;
    }

    if (allMkpLinesHaveSameCurrency) {
      const saleMkpLines = sale?.mkpLines ?? [];
      let total: UiMoney | null = null;
      const exampleUiMoney = saleMkpLines.find((line) => !!line.amt.currency)
        ?.amt;
      marketplacePaymentLines?.value?.forEach((line) => {
        const money: UiMoney = {
          amt: line.amount,
          disp: null,
          currency: exampleUiMoney?.currency ?? null,
          dec: exampleUiMoney?.dec ?? 2,
        };
        total = addMoneySameCurrency(total, money);
      });

      if (!total) {
        return undefined;
      }

      const totalDisplay = (total as UiMoney | null)?.disp;
      if (!totalDisplay) {
        const { amt, currency, dec } = total as UiMoney;
        return formatCurrency(amt, currency, dec);
      }

      return totalDisplay;
    }

    // Different currencies, don't show anything
    return undefined;
  }, [
    marketplacePaymentLinesFormFieldDirty,
    allMkpLinesHaveSameCurrency,
    sale,
    marketplacePaymentLines?.value,
  ]);

  return (
    <>
      <DetailGroup>
        <Detail
          label={
            <Stack justifyContent="spaceBetween" alignItems="center">
              <Content id={ContentId.Payments} />
              {canManageMarketplacePayment && (
                <Button
                  variant="outline"
                  onClick={() =>
                    setMarketplaceChargeActions.current?.addNewMarketplacePaymentLine()
                  }
                >
                  <Content id={ContentId.AddPayment} />
                </Button>
              )}
            </Stack>
          }
          detail={
            <>
              {Boolean(marketplacePaymentLines?.value?.length) && (
                <SimpleTable.Table style={{ width: '100%' }}>
                  <SimpleTable.Thead>
                    <SimpleTable.Tr>
                      <SimpleTable.Th className={styles.tableHeader}>
                        <Content id={ContentId.PaymentId} />
                      </SimpleTable.Th>
                      <SimpleTable.Th className={styles.tableHeader}>
                        <Content id={ContentId.PaymentLineId} />
                      </SimpleTable.Th>
                      <SimpleTable.Th
                        className={clsx(styles.tableHeader, styles.alignRight)}
                      >
                        <Content id={ContentId.Amount} />
                      </SimpleTable.Th>
                      <SimpleTable.Th className={styles.tableHeader}>
                        <Content id={ContentId.PaymentType} />
                      </SimpleTable.Th>
                      <SimpleTable.Th className={styles.tableHeader}>
                        <Content id={ContentId.State} />
                      </SimpleTable.Th>
                      <SimpleTable.Th className={styles.tableHeader}>
                        <Content id={ContentId.PaymentDate} />
                      </SimpleTable.Th>
                      {canManageMarketplacePayment && (
                        <SimpleTable.Th className={styles.tableHeader}>
                          <Content id={ContentId.Actions} />
                        </SimpleTable.Th>
                      )}
                    </SimpleTable.Tr>
                  </SimpleTable.Thead>
                  <SimpleTable.Tbody>
                    {marketplacePaymentLines?.value?.map(
                      (
                        marketplacePaymentLine: SiteMarketplacePaymentLineInput
                      ) => {
                        const {
                          externalPaymentId,
                          externalPaymentLineId,
                          paymentLineType,
                          amount,
                          currency,
                          externalSaleId,
                          paymentDate,
                          marketplacePaymentId,
                        } = marketplacePaymentLine;

                        const state =
                          sale.mkpLines?.find(
                            (mkPayment) =>
                              mkPayment.extPmtId === externalPaymentId
                          )?.state || MarketplacePaymentState.Paid;
                        return (
                          <SimpleTable.Tr
                            key={externalSaleId}
                            className={styles.tableRow}
                          >
                            <SimpleTable.Td className={styles.tableCell}>
                              {marketplacePaymentId && (
                                <PosLink
                                  variant="brand"
                                  to={getMarketplacePaymentRelativeUrl(
                                    marketplacePaymentId
                                  )}
                                >
                                  {externalPaymentId}
                                </PosLink>
                              )}
                            </SimpleTable.Td>
                            <SimpleTable.Td className={styles.tableCell}>
                              {externalPaymentLineId}
                            </SimpleTable.Td>
                            <SimpleTable.Td
                              className={clsx(
                                styles.tableCell,
                                styles.alignRight
                              )}
                            >
                              {formatCurrency(amount, currency)}
                            </SimpleTable.Td>
                            <SimpleTable.Td className={styles.tableCell}>
                              <Content
                                id={
                                  MARKETPLACE_PAYMENT_LINE_TYPE_TO_CID[
                                    paymentLineType
                                  ]
                                }
                              />
                            </SimpleTable.Td>
                            <SimpleTable.Td className={styles.tableCell}>
                              <Content
                                id={
                                  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID[state]
                                }
                              />
                            </SimpleTable.Td>
                            <SimpleTable.Td className={styles.tableCell}>
                              {formatDateCell(paymentDate)}
                            </SimpleTable.Td>

                            {canManageMarketplacePayment && (
                              <SimpleTable.Td className={styles.tableCell}>
                                <Stack>
                                  <IconButton
                                    icon={<EditIcon withHoverEffect />}
                                    onClick={() => {
                                      setMarketplaceChargeActions.current?.editNewMarketplacePaymentLine(
                                        marketplacePaymentLine
                                      );
                                    }}
                                    titleContentId={ContentId.Edit}
                                  />
                                  <IconButton
                                    icon={<DeleteIcon withHoverEffect />}
                                    onClick={() => {
                                      setMarketplaceChargeActions.current?.removeNewMarketplacePaymentLine(
                                        marketplacePaymentLine.externalPaymentLineId
                                      );
                                    }}
                                    titleContentId={ContentId.Edit}
                                  />
                                </Stack>
                              </SimpleTable.Td>
                            )}
                          </SimpleTable.Tr>
                        );
                      }
                    )}
                    {mkpLinesTotal && (
                      <SimpleTable.Tr>
                        <SimpleTable.Td className={styles.tableCell}>
                          <Content id={ContentId.Total} />
                        </SimpleTable.Td>
                        <SimpleTable.Td className={styles.tableCell} />
                        <SimpleTable.Td className={styles.tableCell}>
                          {mkpLinesTotal}
                        </SimpleTable.Td>
                        <SimpleTable.Td className={styles.tableCell} />
                        <SimpleTable.Td className={styles.tableCell} />
                      </SimpleTable.Tr>
                    )}
                  </SimpleTable.Tbody>
                </SimpleTable.Table>
              )}
            </>
          }
        />

        <SetMarketplaceCharge
          setMarketplaceActionsRef={setMarketplaceChargeActions}
          sale={sale}
        />
      </DetailGroup>
    </>
  );
};
