import { useUserHasAutopricingFeature } from 'src/hooks/useUserHasAutopricingFeature';
import { AutoPricingSection } from 'src/modals/ListingDetails/components/PricingSection/AutoPricingSection';
import { ListingAutoPricingSettingsFormContentWrapper } from 'src/modals/ListingDetails/ListingForms/AutoPricingForm/components/ListingAutoPricingSettingsFormContentWrapper/ListingAutoPricingSettingsFormContentWrapper';
import { Feature } from 'src/WebApiController';

export const AutoPricingSettings = ({
  isLoading,
  hasParents,
  isGlobalEdit,
}: {
  isLoading?: boolean;
  hasParents?: boolean;
  isGlobalEdit?: boolean;
}) => {
  const hasIntelligibleAutoPricingSettingsFeature =
    useUserHasAutopricingFeature(Feature.IntelligibleAutoPricingSettings);

  return (
    <>
      {hasIntelligibleAutoPricingSettingsFeature ? (
        <ListingAutoPricingSettingsFormContentWrapper
          listing={undefined}
          disabled={isLoading ?? false}
          isBulkEdit
          isGlobalEdit={isGlobalEdit}
        />
      ) : (
        <AutoPricingSection
          disabled={isLoading}
          listingHasParent={hasParents}
          isBulkEdit
          isGlobalEdit={isGlobalEdit}
        />
      )}
    </>
  );
};
