import {
  DateRangePresetName,
  getUiDateTimeRangeFromPreset,
  PurchaseDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import {
  DateTimeRangeWithRelative,
  EventSort,
  MarketplacePaymentQuery,
} from 'src/WebApiController';

export const EmptyMarketplacePaymentQuery = {
  marketplace: null,
  paymentDates: null,
  marketplacePaymentState: null,
  isSortDescending: false,
  viewMode: null,
  sortBy: null,
  timezoneOffsetMins: new Date().getTimezoneOffset(),
  currencyCodes: null,
} satisfies MarketplacePaymentQuery;

export const DefaultMarketplacePaymentQuery = {
  ...EmptyMarketplacePaymentQuery,
  paymentDates:
    (getUiDateTimeRangeFromPreset(
      DateRangePresetName.ThisYear,
      PurchaseDateRangePresetNames
    ) as DateTimeRangeWithRelative) ?? null,
  sortBy: EventSort.Date,
  isSortDescending: true,
} satisfies MarketplacePaymentQuery;

export enum PaymentTab {
  MarketplacePayments = 'MarketplacePayments',
  VendorPayments = 'VendorPayments',
  CommissionPayment = 'CommissionPayments',
}
