import { Path } from '@remix-run/router/history';
import clsx from 'clsx';
import * as React from 'react';
import { MouseEvent, useCallback, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import * as styles from './PosLink.css';

type PosLinkProps = Omit<LinkProps, 'to'> &
  React.RefAttributes<HTMLAnchorElement> & {
    to: string | undefined | null;
    variant?: 'brand';
  };

/**
 * Wrapper of react-router-dom Link component.
 * property "to" accepts a string | undefined |null. It can be a full URL or
 * path with query string parameters.
 * If not "to" is passed, then nothing happens when clicking the link.
 */
export const PosLink = ({
  children,
  onClick,
  to,
  className,
  variant,
  ...rest
}: PosLinkProps) => {
  const onEventNameClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (!to) {
        e.stopPropagation();
        e.preventDefault();
      }
      onClick?.(e);
    },
    [to, onClick]
  );

  const toProps = useMemo<Partial<Path> | string>(() => {
    if (!to) {
      return '';
    }
    try {
      const url = new URL(to, window.location.origin);
      return {
        pathname: url.pathname,
        search: url.search,
        searchParams: url.searchParams,
      };
    } catch (e) {
      return '';
    }
  }, [to]);

  return (
    <Link
      {...rest}
      onClick={onEventNameClick}
      className={clsx(className, {
        [styles.noLink]: !to,
        [styles.link]: to,
        [styles.linkBrand]: variant === 'brand',
      })}
      to={toProps}
    >
      {children}
    </Link>
  );
};
