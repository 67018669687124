import { debounce, isEmpty, uniqBy } from 'lodash-es';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { vars } from 'src/core/themes';
import { FocalPointInfo, SectionInfo } from 'src/WebApiController';
import svgPanZoom from 'svg-pan-zoom';

import { addFocalPoint } from '../shared/utils/addFocalPoint';
import {
  crossesPoints,
  DrawingPoint,
  isMissiongSectionData,
  registerDrawLine,
  registerPanningListners,
} from '../VenueMapContent.hooks';
import { StandardColorSpec } from './VenueMapV3Content';
import {
  GetColor,
  getSectionId,
  initializeGroups,
  resetSectionRowColors,
} from './VenueMapV3Content.utils';

const clickEvents = ['click'];

const ZOOM_SCALE_SENSITIVITY = 0.2;

type SvgMapInitializerProps = {
  availableSections: SectionInfo[];
  selectedSections?: SectionInfo[];
  markedSectionIds?: number[];
  onSectionClicked?: (
    e: MouseEvent,
    selectedSection: SectionInfo,
    surroundingSections?: SectionInfo[]
  ) => void;
  onSectionHovered?: (hoveredSection: SectionInfo) => ReactNode;
  setSelectedSections?: (sections: SectionInfo[]) => void;
  isZoomEnabled: boolean;
  isPanEnabled?: boolean;
  showDefaultMapColors?: boolean;
  focalPoint?: FocalPointInfo | null;
  colors?: StandardColorSpec;
  getColor?: GetColor;
  isHeatMap?: boolean;
  useRowMap?: boolean;
};

export const useSvgMap = ({
  availableSections,
  selectedSections,
  markedSectionIds,
  onSectionClicked,
  onSectionHovered,
  setSelectedSections,
  isZoomEnabled,
  isPanEnabled,
  focalPoint,
  getColor,
  showDefaultMapColors,
  isHeatMap = false,
  useRowMap = false,
}: SvgMapInitializerProps) => {
  const localRef = useRef<HTMLDivElement>();
  const isPanning = useRef(false);

  const [hoveredContent, setHoverContent] = useState<ReactNode>();
  const [zoomFlag, setZoomFlag] = useState(false);
  const [venueMapSvg, setVenueMapSvg] = useState<SvgPanZoom.Instance>();
  const [sectionGroups, setSectionGroups] = useState<SVGElement[]>([]);

  const sectionIdsMissingRowOrder = useMemo(
    () => availableSections.filter(isMissiongSectionData).map(({ id }) => id),
    [availableSections]
  );

  const setSelectedHandler = useCallback(
    (e: Event) => {
      if (isPanning.current) {
        return;
      }
      const mouseEvent = e as MouseEvent;
      const target = e.target as SVGElement;

      const selectedSectionId = parseInt(getSectionId(target) ?? '0');
      const selectedSection = availableSections.find(
        (x) => x.id === selectedSectionId
      );

      selectedSection && onSectionClicked?.(mouseEvent, selectedSection);
    },
    [availableSections, onSectionClicked]
  );

  const mouseEnter = useCallback(
    ({ target }: MouseEvent) => {
      const section = target as SVGElement;
      const sectionId = parseInt(getSectionId(section) ?? '0');

      const hoveredSection = availableSections.find((s) => s.id === sectionId);
      if (!hoveredSection) return;

      const hoveredContent = onSectionHovered?.(hoveredSection);
      setHoverContent(hoveredContent);
      const sectionPath = section.querySelector('path[t="s"]') as SVGElement;
      sectionPath?.setAttribute('fill-opacity', '0.75');
    },
    [availableSections, onSectionHovered]
  );

  const mouseLeave = useCallback(({ target }: MouseEvent) => {
    const section = target as SVGElement;
    const sectionPath = section.querySelector('path[t="s"]') as SVGElement;

    // If there are row paths, set the section path to be transparent
    // otherwise set the section path to be opaque
    const sectionRows = [
      ...section.querySelectorAll('g[id="row"] path'),
    ] as HTMLElement[];
    if (!isEmpty(sectionRows)) {
      sectionPath?.setAttribute('fill-opacity', '0');
    } else {
      sectionPath?.setAttribute('fill-opacity', '1');
    }
  }, []);

  const initializeSvg = useCallback(
    (svgElement: SVGSVGElement) => {
      addFocalPoint(svgElement, focalPoint);
      return svgPanZoom(svgElement, {
        zoomScaleSensitivity: ZOOM_SCALE_SENSITIVITY,
        zoomEnabled: isZoomEnabled,
        panEnabled: isPanEnabled,
        dblClickZoomEnabled: false,
        onZoom: () => setZoomFlag(true),
        onPan: () => setZoomFlag(true),
      });
    },
    [focalPoint, isPanEnabled, isZoomEnabled]
  );

  const onResize = useCallback(() => {
    if (localRef.current && venueMapSvg) {
      const svgElement = localRef.current.getElementsByTagName('svg')[0];
      svgElement.setAttribute('width', localRef.current.clientWidth.toString());
      svgElement.setAttribute(
        'height',
        localRef.current.clientHeight.toString()
      );
      venueMapSvg.resize(); // update SVG cached size and controls positions
      venueMapSvg.fit();
      venueMapSvg.center();
    }
  }, [venueMapSvg]);

  useEffect(() => {
    if (!venueMapSvg) return;

    const debounceOnResize = debounce(onResize, 200);

    // add eventListeners
    const onAddingEventListeners = () => {
      sectionGroups.forEach((element) => {
        const sectionId = parseInt(getSectionId(element) ?? '0');
        const isSelected =
          selectedSections?.some(({ id }) => id === sectionId) ?? false;
        const isMarked = markedSectionIds?.some((id) => id === sectionId);

        const sectionPath = element.querySelector('path[t="s"]') as SVGElement;
        const sectionLabel = element.querySelector('text[t="s"]') as SVGElement;

        element.style.cursor = 'pointer';

        const sectionRows = [
          ...element.querySelectorAll('g[id="row"] path'),
        ] as HTMLElement[];
        // If there are row paths, set the section path to be transparent
        // otherwise set the section path to be opaque
        if (!isEmpty(sectionRows)) {
          sectionPath?.setAttribute(
            'fill',
            vars.color.backgroundHighlightHover
          );
          sectionPath?.setAttribute('fill-opacity', '0');
        } else {
          sectionPath?.setAttribute('fill-opacity', '1');
        }
        sectionPath?.setAttribute('stroke-width', '2px');
        sectionPath?.setAttribute('stroke', 'black');
        sectionLabel?.removeAttribute('fill');

        if (isSelected) {
          sectionPath?.setAttribute('stroke', vars.color.textBrand);
          sectionPath?.setAttribute('stroke-width', '6px');
          sectionLabel?.setAttribute('fill', vars.color.textBrand);
        } else if (isMarked) {
          sectionPath?.setAttribute('stroke', vars.color.textBrand);
          sectionPath?.setAttribute('stroke-width', '6px');
        }

        element.addEventListener('mouseenter', mouseEnter);
        element.addEventListener('mouseleave', mouseLeave);
        clickEvents.forEach((e) =>
          element.addEventListener(e, setSelectedHandler)
        );
      });

      window.addEventListener('resize', debounceOnResize);
    };

    // remove eventListeners
    const onRemoveEventListeners = () => {
      window.removeEventListener('resize', debounceOnResize);

      sectionGroups.forEach((element) => {
        element.removeEventListener('mouseenter', mouseEnter);
        element.removeEventListener('mouseleave', mouseLeave);
        clickEvents.forEach((e) =>
          element.removeEventListener(e, setSelectedHandler)
        );
      });
    };

    const onDoulbeClickDone = (points: DrawingPoint[]) => {
      onAddingEventListeners();
      const selectedIds = new Set();
      sectionGroups.forEach((element) => {
        if (crossesPoints(points, element.getBoundingClientRect())) {
          selectedIds.add(parseInt(getSectionId(element) ?? '-1'));
        }
      });
      const updatedSections = availableSections
        .filter(
          ({ id }) => selectedIds.has(id) || markedSectionIds?.includes(id)
        )
        .sort((a, b) => a.name?.localeCompare(b.name));

      if (!isEmpty(updatedSections)) {
        setSelectedSections?.(
          uniqBy(updatedSections.concat(selectedSections ?? []), 'id')
        );
      }
    };

    const deregisterDrawLine = registerDrawLine(
      localRef.current,
      onRemoveEventListeners,
      onDoulbeClickDone
    );

    const deregisterPanEvents = registerPanningListners(
      localRef.current,
      isPanning
    );

    onAddingEventListeners();

    return () => {
      onRemoveEventListeners();
      deregisterDrawLine();
      deregisterPanEvents();
    };
  }, [
    availableSections,
    markedSectionIds,
    mouseEnter,
    mouseLeave,
    onResize,
    sectionGroups,
    selectedSections,
    setSelectedHandler,
    setSelectedSections,
    venueMapSvg,
  ]);

  const onInitializeContent = useCallback(
    (element: HTMLDivElement | null) => {
      if (!element || !!venueMapSvg) return false;
      localRef.current = element;
      const svgElement = element.getElementsByTagName('svg')[0];

      // The initialize part will clean up the unused groups and set the row level colors
      const sectionGroups = initializeGroups(
        svgElement,
        availableSections,
        selectedSections,
        markedSectionIds,
        getColor,
        showDefaultMapColors,
        sectionIdsMissingRowOrder,
        isHeatMap,
        useRowMap
      );
      setSectionGroups(sectionGroups);

      // Initialize the SVG pan zoom with the cleaned up svgElement
      const svg = initializeSvg(svgElement);
      setVenueMapSvg(svg);
      return true;
    },
    [
      venueMapSvg,
      availableSections,
      selectedSections,
      markedSectionIds,
      getColor,
      showDefaultMapColors,
      sectionIdsMissingRowOrder,
      isHeatMap,
      useRowMap,
      initializeSvg,
    ]
  );

  // this is to refresh the row colors in regards to to selected sections changes
  useEffect(() => {
    if (localRef.current && venueMapSvg && !isEmpty(sectionGroups)) {
      resetSectionRowColors(
        sectionGroups,
        availableSections,
        selectedSections,
        markedSectionIds,
        sectionIdsMissingRowOrder,
        getColor,
        isHeatMap,
        showDefaultMapColors
      );
    }
  }, [
    availableSections,
    getColor,
    isHeatMap,
    markedSectionIds,
    sectionGroups,
    sectionIdsMissingRowOrder,
    selectedSections,
    showDefaultMapColors,
    venueMapSvg,
  ]);

  const onZoom = useCallback(
    (zoomIn = true) => {
      if (!venueMapSvg) return;
      zoomIn ? venueMapSvg.zoomIn() : venueMapSvg.zoomOut();
      setZoomFlag(true);
    },
    [venueMapSvg]
  );

  const onReset = useCallback(() => {
    if (!venueMapSvg) return;
    venueMapSvg.resize(); // update SVG cached size and controls positions
    venueMapSvg.fit();
    venueMapSvg.center();
    setZoomFlag(false);
    setSelectedSections?.([]);
  }, [setSelectedSections, venueMapSvg]);

  return {
    onInitializeContent,
    onZoom,
    onReset,
    zoomFlag,
    hoveredContent,
  };
};
