import { FilterToolbarItemId } from 'src/components/FilterToolbar';
import { ExpandedEventData } from 'src/contexts/CatalogDataContext';
import { ErrorTypes } from 'src/contexts/ErrorBoundaryContext';
import {
  getPresetFromUiDateTimeRange,
  getUiDateTimeRangeFromPreset,
  InhandDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  CatalogClient,
  DateTimeRange,
  ListingClient,
  ListingMetrics,
  ListingMetricsInput,
  ListingQuery,
  PosClientConfig,
} from 'src/WebApiController';

export const getCatalogData = async (
  client: CatalogClient,
  filterQuery: ListingQuery,
  includeCounts: boolean
) => {
  return await client.getCatalogForListing(filterQuery, includeCounts);
};

export const getCatalogDataExpanded = async (
  viagogoVirtualIds: string[],
  filterQuery: ListingQuery,
  {
    activeAccountWebClientConfig,
    onError,
  }: {
    activeAccountWebClientConfig: PosClientConfig;
    onError?: (error: ErrorTypes) => void;
  }
) => {
  const result = await tryInvokeApi(async () => {
    const allListings = await new ListingClient(
      activeAccountWebClientConfig
    ).getListingsForEvents({
      ...filterQuery,
      // Is is to support the legacy event ID urls
      oldPosEventIds: viagogoVirtualIds
        .filter((id) => id.startsWith('old:'))
        .map((id) => id.substring(4)),
      eventOrMappingIds: viagogoVirtualIds.filter(
        (id) => !id.startsWith('old:')
      ),
    });

    const emptyResults = viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        sales: null,
        listings: null,
        ticketGroups: null,
        failedToRetrieveData: false,
      };
      return results;
    }, {} as ExpandedEventData);

    return Promise.resolve(
      Object.entries(allListings).reduce((results, [id, listings]) => {
        results[id] = {
          sales: null,
          listings: listings,
          ticketGroups: null,
          failedToRetrieveData: false,
        };
        return results;
      }, emptyResults)
    );
  }, onError);

  if (result) {
    return Promise.resolve(result);
  }

  return Promise.resolve(
    viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        failedToRetrieveData: true,
        sales: null,
        listings: null,
        ticketGroups: null,
      };
      return results;
    }, {} as ExpandedEventData)
  );
};

export const getCatalogMetrics = (
  client: CatalogClient,
  filterQuery: ListingQuery
) => {
  return client.getCatalogListingMetrics(filterQuery);
};

export const getCatalogSummaryMetrics = (
  client: CatalogClient,
  metrics: ListingMetrics[],
  mergeCatalogMetrics: (metrics: ListingMetrics[]) => ListingMetrics[]
) => {
  const resultMetrics = mergeCatalogMetrics(metrics);
  if (resultMetrics?.length === 1) {
    return Promise.resolve(resultMetrics[0]);
  }
  return client.getSummaryMetricsForListings(
    resultMetrics.map(
      (m) =>
        ({
          totalTicketQuantity: m.tktQty,
          totalSoldQuantity: m.soldQty,
          totalUnsoldQuantity: m.unsoldQty,
          totalListedQuantity: m.listQty,
          totalUnlistedQuantity: m.unlistQty,
          currencyCode: m.currency,
          /** Total cost of all tickets */
          totalCost: {
            amount: m.ttlCst.amt,
            currencyCode: m.ttlCst.currency ?? m.currency,
            decimalDigits: m.ttlCst.dec,
            display: m.ttlCst.disp,
          },
          /** Total cost of sold tickets */
          totalSoldCost: {
            amount: m.soldCst.amt,
            currencyCode: m.soldCst.currency ?? m.currency ?? m.currency,
            decimalDigits: m.soldCst.dec,
            display: m.soldCst.disp,
          },
          /** Total cost of unsold tickets */
          totalUnsoldCost: {
            amount: m.unsoldCst.amt,
            currencyCode: m.unsoldCst.currency ?? m.currency,
            decimalDigits: m.unsoldCst.dec,
            display: m.unsoldCst.disp,
          },
          /** Total list price of all tickets */
          totalListPrice: {
            amount: m.ttlListPrc.amt,
            currencyCode: m.ttlListPrc.currency ?? m.currency,
            decimalDigits: m.ttlListPrc.dec,
            display: m.ttlListPrc.disp,
          },
          /** Total value of all listed tickets */
          // This is un-used and only exists for backward compatibility
          totalListedValue: {
            amount: 0,
            currencyCode: m.currency,
            decimalDigits: 2,
            display: '',
          },
          /** Total list price of unsold tickets */
          totalUnsoldListPrice: {
            amount: m.ttlUnsoldListPrc.amt,
            currencyCode: m.ttlUnsoldListPrc.currency ?? m.currency,
            decimalDigits: m.ttlUnsoldListPrc.dec,
            display: m.ttlUnsoldListPrc.disp,
          },
          /** Total net proceeds of sold tickets */
          totalNetProceeds: {
            amount: m.netProcs.amt,
            currencyCode: m.netProcs.currency ?? m.currency,
            decimalDigits: m.netProcs.dec,
            display: m.netProcs.disp,
          },
          /** Total gross profit of sold tickets */
          totalGrossProfit: {
            amount: m.pandL.amt,
            currencyCode: m.pandL.currency ?? m.currency,
            decimalDigits: m.pandL.dec,
            display: m.pandL.disp,
          },
        }) as ListingMetricsInput
    )
  );
};

export const getEventDetailedMetrics = (
  client: CatalogClient,
  filterQuery: ListingQuery
) => client.getEventDetailedListingMetrics(filterQuery);

export const listingQueryValueTransformFromUrl = (
  key: string,
  value: string
) => {
  if (!value) {
    return value;
  }

  const listingsKey = key as keyof ListingQuery;
  if (listingsKey === 'inHandDates') {
    const preset = getUiDateTimeRangeFromPreset(
      value,
      InhandDateRangePresetNames
    );
    if (preset) {
      return preset;
    }
  }

  return value;
};

export const listingQueryValueTransformToUrl = (
  key: FilterToolbarItemId,
  value: unknown
) => {
  if (!value) {
    return value;
  }

  const listingsKey = key as keyof ListingQuery;

  if (listingsKey === 'inHandDates') {
    const preset = getPresetFromUiDateTimeRange(value as DateTimeRange);
    if (preset && InhandDateRangePresetNames.includes(preset.name)) {
      return preset.name;
    }
  }

  return value;
};
