export function StubHubSquareLogo({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.90835 19.1508C1.68799 19.2405 1.56557 19.1263 1.63902 18.9061L2.25929 16.8917C2.3409 16.6225 2.36539 16.3371 2.3409 16.0516L1.00243 3.37769C0.977946 3.14933 1.14117 2.94544 1.36969 2.92097H1.38602L18.8047 2.00124C19.0088 1.98493 19.1802 2.13173 19.1965 2.33562C19.1965 2.36009 19.1965 2.38455 19.1965 2.40087L17.4336 17.5993C17.4091 17.8359 17.1969 18.0153 16.9603 17.999L6.07883 17.6338C5.79318 17.6257 5.50753 17.6746 5.24636 17.7725L1.90835 19.1508Z"
        fill="#3F1D75"
      />
      <path
        d="M9.59341 8.84577L11.815 9.75124C12.1498 9.8806 12.452 10.1194 12.6807 10.4577C12.8931 10.7861 13.0074 11.194 12.9993 11.6219V11.7811C13.0156 12.4179 12.7624 13.0348 12.3295 13.403C11.8966 13.801 11.2677 14 10.4918 14C9.71592 14 9.11153 13.791 8.67048 13.403C8.2376 13.0348 7.98441 12.4279 8.00075 11.7811V11.6617H8.94817V11.7811C8.94817 12.1095 9.07069 12.3483 9.34021 12.5572C9.61791 12.7562 10.0018 12.8557 10.5 12.8557C10.9982 12.8557 11.3984 12.7562 11.668 12.5572C11.9375 12.3582 12.0682 12.1095 12.0682 11.7811V11.6219C12.0682 11.2438 11.8885 10.995 11.5128 10.8458L9.31571 9.9403C8.95634 9.79104 8.67048 9.58209 8.48263 9.30348C8.28661 9.02488 8.1886 8.66667 8.1886 8.29851V8.1194C8.1886 6.73632 8.93184 6.0199 10.4183 6H10.5163C12.0355 6 12.8196 6.68657 12.8686 8V8.0995H11.913L11.9048 8.00995C11.8558 7.43284 11.4066 7.14428 10.5163 7.14428H10.4347C9.55257 7.16418 9.11969 7.48259 9.11969 8.1194V8.29851C9.11969 8.40796 9.1687 8.51741 9.24221 8.59701C9.34022 8.70647 9.46273 8.79602 9.59341 8.84577Z"
        fill="white"
      />
    </svg>
  );
}
