import { Stack } from 'src/core/ui';

import * as styles from './EventMapDetailSimpleActionGroup.css';

export type EventMapDetailSimpleActionGroupProps = {
  header: string;
  subHeader?: string | undefined;
  action: React.ReactNode;
};

export const EventMapDetailSimpleActionGroup = ({
  header,
  subHeader,
  action,
}: EventMapDetailSimpleActionGroupProps) => {
  return (
    <Stack
      className={styles.container}
      direction="row"
      alignItems="center"
      width="full"
      justifyContent="spaceBetween"
    >
      <Stack direction="column">
        <span className={styles.headerText}>{header}</span>
        {subHeader ? (
          <span className={styles.subHeaderText}>{subHeader}</span>
        ) : undefined}
      </Stack>

      <div className={styles.actionContainer}>{action}</div>
    </Stack>
  );
};
