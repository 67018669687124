import { isEmpty } from 'lodash-es';
import { vars } from 'src/core/themes';
import { SectionInfo } from 'src/WebApiController';

const colors = {
  defaultBackground: vars.color.backgroundPrimary,
  defaultForeground: vars.color.textPrimary,
  defaultHoverBackground: vars.color.backgroundPrimaryHover,
  defaultHoverForeground: vars.color.textPrimaryHover,
  selectedBackground: vars.color.backgroundHighlight,
  selectedForeground: vars.color.textBrand,
  selectedHoverBackground: vars.color.backgroundHighlightHover,
  selectedHoverForeground: vars.color.textBrandHover,
};

export type GetColor = (info: {
  sectionId: number;
  rowId?: number;
  sectionName?: string;
  ticketClassId: number;
  isSelected: boolean;
  useRowColor?: boolean;
  isMarked?: boolean;
}) => {
  fill: string;
  stroke: string;
  textColor?: string;
  strokeWidth?: string;
} | void;

// We need both section and ticket class id to uniquely identify a section sprite in svg file
const toSectionKey = (sectionId: string | null, ticketClassId: string | null) =>
  `s${sectionId || ''}-t${ticketClassId || ''}`;

// Get section id from the element, traverse up the parent elements if not found
export const getSectionId = (element: SVGElement): string | null => {
  if (!element?.tagName) {
    return null;
  }
  if (element.parentElement && element.tagName !== 'g') {
    return getSectionId(element.parentElement as unknown as SVGElement);
  }
  return (
    element.getAttribute('sectionId') ??
    element.getAttribute('sectionid') ??
    element.getAttribute('section-id')
  );
};

// Get ticket class id from the element, traverse up the parent elements if not found
export const getTicketClassId = (element: SVGElement): string | null => {
  if (!element?.tagName) {
    return null;
  }
  if (element.parentElement && element.tagName !== 'g') {
    return getTicketClassId(element.parentElement as unknown as SVGElement);
  }
  return (
    element.getAttribute('ticketClassId') ??
    element.getAttribute('ticketclassid') ??
    element.getAttribute('ticket-class-id')
  );
};

export const resetSectionRowColors = (
  sectionGroups: SVGElement[],
  availableSections: SectionInfo[],
  selectedSections?: SectionInfo[],
  markedSectionIds?: number[],
  sectionIdsMissingRowOrder?: number[],
  getColor?: GetColor,
  isHeatMap?: boolean,
  showDefaultMapColors?: boolean
) => {
  for (const sectionGroup of sectionGroups) {
    // Set row level score color
    const sectionRows = [
      ...sectionGroup.querySelectorAll('g[id="row"] path'),
    ] as HTMLElement[];
    if (!isEmpty(sectionRows)) {
      sectionRows.forEach((path) => {
        const rowId = parseInt(path.getAttribute('eid') ?? '0');

        const { fill, stroke } = getColor?.({
          sectionId: -1,
          rowId,
          ticketClassId: -1,
          isSelected: false,
          useRowColor: true,
        }) ?? { fill: '', stroke: '' };

        path.setAttribute('fill', fill);
        path.setAttribute('stroke', stroke);
      });
      return;
    }

    // Set section level color if don't support row map
    const sectionId = parseInt(getSectionId(sectionGroup) ?? '0');
    const ticketClassId = parseInt(getTicketClassId(sectionGroup) ?? '0');

    const selectedSection = selectedSections?.find((x) => x.id === sectionId);
    const isMarked = markedSectionIds?.some((id) => id === sectionId);

    const knownSection = availableSections.find((x) => x.id === sectionId);
    const knownRow =
      knownSection?.rows?.find((r) => r.tktClass?.id === ticketClassId) ??
      knownSection?.specRow;

    const { fill, stroke, textColor, strokeWidth } = getColor?.({
      sectionId,
      rowId: knownRow?.id,
      sectionName: knownSection?.name,
      ticketClassId,
      isSelected: !!selectedSection,
      isMarked,
    }) ?? { fill: '', stroke: '' };

    const sectionLabel = sectionGroup.querySelector(
      'text[t="s"]'
    ) as SVGElement;
    if (selectedSection) {
      sectionLabel?.setAttribute('fill', vars.color.textBrand);
    } else if (textColor) {
      sectionLabel?.setAttribute('fill', textColor);
    } else {
      sectionLabel?.setAttribute('fill', 'black');
    }

    const sectionPath = sectionGroup.querySelector('path[t="s"]') as SVGElement;
    if (sectionPath === null) {
      continue;
    }

    if (fill && stroke) {
      sectionPath.setAttribute('fill', fill);
      sectionPath.setAttribute('stroke', stroke);
    } else {
      if (selectedSection) {
        sectionPath.setAttribute('fill', colors.selectedBackground);
        sectionPath.setAttribute('fill-opacity', '1');
        sectionPath.setAttribute('stroke', colors.selectedForeground);
      } else {
        if (knownSection) {
          sectionPath.setAttribute(
            'fill',
            showDefaultMapColors
              ? knownRow?.tktClass?.color ?? colors.defaultBackground
              : colors.defaultBackground
          );
          sectionPath.setAttribute('fill-opacity', '1');
          sectionPath.setAttribute('stroke', colors.defaultForeground);
        } else {
          sectionPath.setAttribute('fill', colors.defaultBackground);
          sectionPath.setAttribute('fill-opacity', '1');
          sectionPath.setAttribute('stroke', colors.defaultForeground);
        }
      }
    }

    if (selectedSection) {
      sectionPath.setAttribute('stroke', vars.color.textBrand);
      sectionPath.setAttribute('stroke-width', strokeWidth ?? '10px');
    } else if (isMarked) {
      sectionPath.setAttribute('stroke', vars.color.textBrand);
      sectionPath.setAttribute('stroke-width', strokeWidth ?? '6px');
    } else {
      sectionPath.setAttribute('stroke-width', strokeWidth ?? '2px');
    }

    const missingRowOrder = sectionIdsMissingRowOrder?.includes(sectionId);
    if (missingRowOrder && isHeatMap) {
      sectionPath?.setAttribute('stroke', vars.color.borderWarning);
      sectionPath?.setAttribute('stroke-width', '4px');
    }
  }
};

/**
 * Rework the SVG groups to limit the group number by grouping elements by section.
 * In each of the section group we want to ensure the row paths are at the bottom for
 * only displaying the row colors.
 *
 * We rebuild the group so that row paths comes first and then the section path and label.
 * This way section label will be on top of the section path, and row paths will be at the bottom.
 *
 * We will also only build the section level groups if the useRowMap is false.
 *
 * This will significantly reduce the number of groups in the SVG and improve the performance.
 *
 * @param svg the svg file
 * @returns reordered svg file
 */
const reworkGroups = (
  svg: SVGSVGElement,
  sectionIdsMissingRowOrder: number[],
  isHeatMap = false,
  useRowMap = false
) => {
  const rowPaths = svg.getElementById('rowpaths') as SVGElement;
  const sectionPaths = svg.getElementById('sectionpaths') as SVGElement;
  const sectionLables = svg.getElementById('sectionlabels') as SVGElement;
  const ticketClassPaths = svg.getElementById('ticketclasspaths') as SVGElement;
  const ticketClassLables = svg.getElementById(
    'ticketclasslabels'
  ) as SVGElement;
  const rowLablePaths = svg.getElementById('rowlabels') as SVGElement;

  // Required paths groups for V3 VenueMap SVG
  if ((useRowMap && !rowPaths) || !sectionPaths || !sectionLables) {
    return [];
  }

  // Map from section id to row paths
  const sectionToRows = (
    [...rowPaths.querySelectorAll('g')] as SVGElement[]
  ).reduce(
    (map, element) => {
      const sectionId = getSectionId(element);
      const ticketclassid = getTicketClassId(element);
      const sectionKey = toSectionKey(sectionId, ticketclassid);
      const rowPath = element.querySelector('path') as SVGElement;
      (map[sectionKey] = map[sectionKey] || ([] as HTMLElement[])).push(
        rowPath
      );
      return map;
    },
    {} as Record<string, SVGElement[]>
  );

  // Map from section id to section label
  const sectionToLable = (
    [...sectionLables.querySelectorAll('g')] as SVGElement[]
  ).reduce(
    (map, element) => {
      const sectionId = getSectionId(element);
      const ticketclassid = getTicketClassId(element);
      const sectionKey = toSectionKey(sectionId, ticketclassid);
      const textElement = element.querySelector('text') as SVGElement;
      if (
        sectionIdsMissingRowOrder.includes(parseInt(sectionId ?? '0')) &&
        isHeatMap
      ) {
        if (
          textElement.textContent &&
          textElement.textContent.slice(-1) !== '!'
        ) {
          textElement.textContent = `${textElement.textContent}!`;
        }
      }
      map[sectionKey] = textElement;
      return map;
    },
    {} as Record<string, SVGElement>
  );

  // Main logic to rework the section groups
  ([...sectionPaths.querySelectorAll('g')] as SVGElement[]).forEach(
    (section) => {
      const sectionId = getSectionId(section);
      const ticketClassId = getTicketClassId(section);
      const sectionKey = toSectionKey(sectionId, ticketClassId);
      // The original section path
      const sectionPath = section.querySelector('path') as SVGElement;
      if (!sectionPath || !sectionId) return;
      section.removeChild(sectionPath);

      const rowGroup = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'g'
      );
      rowGroup.setAttribute('id', `row`);
      for (const row of sectionToRows[sectionKey] ?? []) {
        row.setAttribute('pointer-events', 'none');
        row.setAttribute('fill-opacity', '1');
        if (useRowMap) {
          rowGroup.appendChild(row);
        }
      }
      if (useRowMap) {
        section.appendChild(rowGroup);
      }
      section.appendChild(sectionPath);

      if (sectionToLable[sectionKey]) {
        section.appendChild(sectionToLable[sectionKey]);
      }
    }
  );

  // Clean up all other groups and leave only the section paths group
  const root = sectionPaths.parentElement as HTMLElement;
  rowPaths && root.removeChild(rowPaths);
  sectionLables && root.removeChild(sectionLables);
  ticketClassPaths && root.removeChild(ticketClassPaths);
  ticketClassLables && root.removeChild(ticketClassLables);
  rowLablePaths && root.removeChild(rowLablePaths);

  return [...sectionPaths.querySelectorAll('g')] as SVGElement[];
};

export const initializeGroups = (
  svgElement: SVGSVGElement,
  availableSections: SectionInfo[],
  selectedSections?: SectionInfo[],
  markedSectionIds?: number[],
  getColor?: GetColor,
  showDefaultMapColors?: boolean,
  sectionIdsMissingRowOrder: number[] = [],
  isHeatMap = false,
  useRowMap = false
) => {
  const sectionGroups = reworkGroups(
    svgElement,
    sectionIdsMissingRowOrder,
    isHeatMap,
    useRowMap
  );

  if (!sectionGroups) {
    return [] as SVGElement[];
  }
  if (useRowMap) {
    // Set row level score colors
    resetSectionRowColors(
      sectionGroups,
      availableSections,
      selectedSections,
      markedSectionIds,
      sectionIdsMissingRowOrder,
      getColor,
      isHeatMap,
      showDefaultMapColors
    );
  }
  return sectionGroups;
};
