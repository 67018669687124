import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useEffectOnce } from 'react-use';

import { GeneralAdmissionMessage } from '../GeneralAdmissionMessage';
import { rawFetch } from '../shared/utils/rawFetch';
import VenueMapV3Content, { VenueMapV3ContentProps } from './VenueMapV3Content';

const domParser = new DOMParser();

export const VenueMapV3 = ({
  isGeneralAdmissionOnly,
  svgMapUrl,
  ...rest
}: Omit<VenueMapV3ContentProps, 'svg'> & {
  isGeneralAdmissionOnly?: boolean;
}) => {
  if (!svgMapUrl) {
    throw new Error('svgMapUrl not available');
  }

  const mapQuery = useQuery({
    queryKey: [svgMapUrl],
    queryFn: () =>
      rawFetch(svgMapUrl, {}).then((text: string) => {
        if (!text) {
          throw Error('Failed to get venue map : ' + svgMapUrl);
        }
        const s = domParser.parseFromString(text, 'image/svg+xml');

        const svgElement = s.children[0];
        svgElement.setAttribute(
          'width',
          ref?.current?.clientWidth?.toString() ?? '0'
        );
        svgElement.setAttribute(
          'height',
          ref?.current?.clientHeight?.toString() ?? '0'
        );
        const serializer = new XMLSerializer();
        return {
          svg: serializer.serializeToString(svgElement),
        };
      }),

    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const ref = useRef<HTMLDivElement>(null);

  // TODO: Remove this after SVGVenueMapV3 feature is GA'ed
  // This is just a debug log to tell the user that we are using the V3 map
  useEffectOnce(() => {
    console.log('Yes, you are using SVG V3 Map!');
  });

  const { svg } = mapQuery.data ?? {};

  if (isGeneralAdmissionOnly) {
    return <GeneralAdmissionMessage />;
  }

  if (!svg) {
    return <div style={{ width: '100%', height: '100%' }} ref={ref}></div>;
  }

  return (
    <VenueMapV3Content ref={ref} svg={svg} svgMapUrl={svgMapUrl} {...rest} />
  );
};
