import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { useGetMarketplaceDefaultColumnsConfig } from 'src/tables/MarketplacePaymentsTable/useGetMarketplaceDefaultColumnsConfig';
import { useMarketplacePaymentsTableColumnsConfig } from 'src/tables/MarketplacePaymentsTable/useMarketplacePaymentsTableColumnsConfig';
import {
  filterColumnsByFeatures,
  getMarketplacePaymentColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { MarketplacePaymentsTableColumnId } from 'src/utils/columns/marketplacePayments/marketplacePaymentsColumnsUtils.types';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import {
  Feature,
  MarketplaceSalePayment,
  UserSetting,
} from 'src/WebApiController';

export const useGetDisplayMarketplacePaymentsTableColumns = () => {
  const defaultMarketplaceColumnsConfig =
    useGetMarketplaceDefaultColumnsConfig();
  const paymentsTableColumnConfig = useMarketplacePaymentsTableColumnsConfig();
  const { appContext, loginContext } = useAppContext();
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );
  const {
    value:
      storedMarketplacePaymentsColumnOrderSetting = defaultMarketplaceColumnsConfig,
  } = useServerUserSetting<MarketplacePaymentsTableColumnId[]>({
    id: UserSetting.MarketplacePaymentsTableColumnOrder,
  });

  const { value: storedMarketplacePaymentsColumnsEnabledSetting } =
    useServerUserSetting<string[]>({
      id: UserSetting.MarketplacePaymentsTableColumnsEnabled,
    });

  return useMemo(() => {
    const marketplacePaymentColumnOrder =
      storedMarketplacePaymentsColumnOrderSetting.filter(
        (c) =>
          storedMarketplacePaymentsColumnsEnabledSetting?.includes(c) ?? true
      );

    const marketplacePaymentsTableColumnsConfig = Object.values(
      paymentsTableColumnConfig
    );

    const columns = new Set([
      // Add unconfigurable columns first
      ...marketplacePaymentsTableColumnsConfig
        .filter((column) => {
          const columnDef = getMarketplacePaymentColumnConfigById(
            column.id as MarketplacePaymentsTableColumnId
          ).personalization;
          const hasFeatureForColumn =
            columnDef.requiredFeatures.length === 0 ||
            hasFeatures(
              loginContext?.user,
              appContext?.features,
              columnDef.requiredFeatures
            );

          let isConfigurable = columnDef.isConfigurable;
          if (
            !hasTablePinActionColumnFeature &&
            column.id === MarketplacePaymentsTableColumnId.Actions
          ) {
            isConfigurable = true;
          }

          return !isConfigurable && hasFeatureForColumn;
        })
        .map((item) => item.id as MarketplacePaymentsTableColumnId),
      // Add user defined columns next
      ...filterColumnsByFeatures(
        marketplacePaymentColumnOrder,
        SectionType.MarketplacePaymentsTable,
        [],
        loginContext?.user,
        appContext?.features,
        []
      ),
    ]);

    return Array.from(columns).reduce<
      ColumnDef<MarketplaceSalePayment | null>[]
    >((acc, columnId) => {
      const columnConfig = marketplacePaymentsTableColumnsConfig.find(
        (column) => column.id === columnId
      );
      if (columnConfig) acc.push(columnConfig);

      return acc;
    }, []);
  }, [
    appContext?.features,
    hasTablePinActionColumnFeature,
    loginContext?.user,
    paymentsTableColumnConfig,
    storedMarketplacePaymentsColumnOrderSetting,
    storedMarketplacePaymentsColumnsEnabledSetting,
  ]);
};
