import { capitalize } from 'lodash-es';
import { useMemo } from 'react';
import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { useEventMetrics } from 'src/contexts/EventMetricsContext/EventMetricsContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EventSupplyMetricsTimePeriod, Feature } from 'src/WebApiController';

import * as styles from '../../DashboardSection.css';
import { HeaderWidgetContainer } from '../../HeaderWidgetContainer';
import { TimeSeriesLineChartUnit } from '../TimeSeriesLineChart';
import { EventSupplyWidget } from './EventSupplyWidget';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';

export const EventSupplyWidgetsContainer = () => {
  const { supplyMetrics } = useEventMetrics();
  const supplyText = useContent(ContentId.Supply);

  const hasLastDaySupplyMetricsFeature = useUserHasFeature(
    Feature.DemandDashLastDaySupplyMetrics,
  );

  const lastDay = useFormattedContent(FormatContentId.LastNDays, '1') as string;
  const lastSevenDays = useFormattedContent(
    FormatContentId.LastNDays,
    '7'
  ) as string;
  const lastNinetyDays = useFormattedContent(
    FormatContentId.LastNDays,
    '90'
  ) as string;
  const timePeriodValueOptionsContent: Record<string, string> = {
    [EventSupplyMetricsTimePeriod.LastSevenDays]: capitalize(lastSevenDays),
    [EventSupplyMetricsTimePeriod.LastNinetyDays]: capitalize(lastNinetyDays),
  };

  if (hasLastDaySupplyMetricsFeature) {
    timePeriodValueOptionsContent[EventSupplyMetricsTimePeriod.LastDay] = capitalize(lastDay);
  }

  const labelUnit = useMemo(() => {
    const timePeriod = supplyMetrics.timePeriod;
    if (timePeriod === EventSupplyMetricsTimePeriod.LastNinetyDays) {
      return 'week';
    }
    if (timePeriod === EventSupplyMetricsTimePeriod.LastSevenDays) {
      return 'day';
    }
    return 'hour';
  }, [supplyMetrics.timePeriod]);

  return (
    <>
      <HeaderWidgetContainer
        title={supplyText}
        action={
          <PosSelect
            className={styles.dashboardHeaderSelect}
            value={supplyMetrics.timePeriod}
            valueOptionsContent={timePeriodValueOptionsContent}
            onChange={v => supplyMetrics.updateTimePeriod(v as EventSupplyMetricsTimePeriod)}
            shape="none"
          />
        }
      />
      <Stack direction="row" gap="xl" width="full">
        <EventSupplyWidget
          key={`supply-ticket-count`}
          title={ContentId.TicketQuantity}
          series={supplyMetrics.ticketCountSeries}
          today={supplyMetrics.today}
          labelUnit={labelUnit}
          isLoading={supplyMetrics.isLoading}
        />
        <EventSupplyWidget
          key={`supply-ticket-prices`}
          title={ContentId.TicketPrices}
          series={supplyMetrics.ticketPricesSeries}
          today={supplyMetrics.today}
          unitType={{
            type: TimeSeriesLineChartUnit.Price,
            currency: supplyMetrics.currency,
          }}
          labelUnit={labelUnit}
          isLoading={supplyMetrics.isLoading}
        />
      </Stack>
    </>
  );
};
