import { clsx } from 'clsx';
import { nanoid } from 'nanoid';
import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Virtuoso } from 'react-virtuoso';
import {
  Content,
  getContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { SelectInputSearch } from 'src/core/POS/PosSelect/SelectInputSearch';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Select, Stack } from 'src/core/ui';
import { mergeProps } from 'src/core/utils';
import { useFlexSearchIndex } from 'src/hooks/useFlexSearchIndex';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './PosSelect.css';

const EMPTY_CONTENT_DESIGNATOR = '____empty_content_____';

export type PosSelectProps = {
  value?: string;
  defaultValue?: string;
  placeholderText?: string | ContentId;
  displayText?: string | ContentId;
  enableEmptySelection?: boolean;
  hasErrors?: boolean;
  onChange: (value: string, searchText?: string) => void;
  prefixDisplay?: ReactNode;
  postfixDisplay?: ReactNode;
  showClearButton?: boolean;
  useVirtualWindow?: boolean;
  expectedItemSizeForVirtualWindow?: number;
  valueOptionsContent: Record<string, ContentId | string>;
  valueOptionsAlwaysShow?: Record<string, boolean>;
  valueOptionsIcon?: Record<string, ReactNode>;
  valueOptionsContentOverride?: Record<string, ReactNode>;
  /* These are disabled and with reasoning why */
  valueOptionsDisabled?: Record<
    string,
    ContentId | string | boolean | undefined
  >;
  loading?: boolean;
  sortMode?: 'ascending' | 'descending' | 'none';
  sortFn?: (a: string, b: string) => number;
  sortBy?: 'key' | 'value';
  searchable?: boolean;
  /* this needs searchable to be true */
  allowSearchAsInput?: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  searchInput?: string;
  showFullWidth?: boolean;
  onSearchInputChange?: (searchText: string) => void;
  rootProps?: Omit<
    ComponentPropsWithRef<typeof Select.Root>,
    'defaultValue' | 'value' | 'children' | 'onValueChange'
  >;
  contentProps?: Omit<
    ComponentPropsWithRef<typeof Select.Content>,
    'defaultValue' | 'children' | 'onChange'
  > & { 'data-testid'?: string };
} & Omit<
  ComponentPropsWithRef<typeof Select.Trigger>,
  'value' | 'defaultValue' | 'children' | 'onChange'
>;

export const PosSelect = ({
  value,
  defaultValue,
  placeholderText,
  displayText,
  enableEmptySelection,
  prefixDisplay,
  postfixDisplay,
  hasErrors,
  rootProps,
  contentProps,
  valueOptionsContent,
  useVirtualWindow,
  expectedItemSizeForVirtualWindow = 40,
  valueOptionsAlwaysShow,
  valueOptionsDisabled,
  valueOptionsIcon,
  valueOptionsContentOverride,
  onChange,
  showClearButton,
  loading,
  sortMode = 'ascending',
  sortFn,
  sortBy = 'value',
  searchable,
  allowSearchAsInput,
  disabled,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  onSearchInputChange,
  showFullWidth,
  ...props
}: PosSelectProps) => {
  const inputSearchRefKey = useRef(nanoid());
  const scrollerRef = useRef<HTMLElement | Window | null>(null);
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(rootProps?.defaultOpen ?? false);

  const { flexSearchIndex } = useFlexSearchIndex(
    valueOptionsContent,
    searchable,
    isOpen
  );

  const isBuildingSearchIndex = searchable && !flexSearchIndex;

  const onOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open);
      rootProps?.onOpenChange?.(open);
    },
    [rootProps]
  );

  placeholderText = placeholderText ?? ContentId.Select;
  const placeholderTextContent = useContent(placeholderText);

  const displayTextContent = useContent(
    displayText ?? valueOptionsContent[value ?? defaultValue ?? '']
  );

  const searchPlaceholder = useContent(
    allowSearchAsInput ? ContentId.SearchOrEnter : ContentId.Search
  );

  const handleScrollerRef = (ref: HTMLElement | Window | null) => {
    scrollerRef.current = ref;
  };

  const onSearchTextApplied = useCallback(
    (searchText: string) => {
      const existingValue = Object.keys(valueOptionsContent).find(
        (k) =>
          valueOptionsContent[k]?.toLocaleUpperCase() ===
          searchText.toLocaleUpperCase()
      );
      if (existingValue || allowSearchAsInput) {
        onChange(existingValue ?? searchText);
        setIsOpen(false);
      }
    },
    [allowSearchAsInput, onChange, valueOptionsContent]
  );

  const keysOfValueOptionsAlwaysShow = useMemo(() => {
    return valueOptionsAlwaysShow ? Object.keys(valueOptionsAlwaysShow) : [];
  }, [valueOptionsAlwaysShow]);

  const keysOfValueOptionsContent = useMemo(() => {
    return Object.keys(valueOptionsContent);
  }, [valueOptionsContent]);

  const useVirtuoso = useMemo(() => {
    if (useVirtualWindow === null || useVirtualWindow === undefined) {
      return keysOfValueOptionsContent.length > 50;
    }
    return useVirtualWindow;
  }, [keysOfValueOptionsContent, useVirtualWindow]);

  const selectItems = useMemo(() => {
    let keysToRender = keysOfValueOptionsContent;

    if (searchable && searchText.trim().length > 0) {
      keysToRender = flexSearchIndex?.search(searchText) as string[];
    }

    let selectItemKeys =
      enableEmptySelection && searchText.length === 0
        ? [EMPTY_CONTENT_DESIGNATOR]
        : [];

    if (!sortMode || sortMode === 'none') {
      selectItemKeys = selectItemKeys.concat(keysToRender);
    } else {
      selectItemKeys = selectItemKeys.concat(
        (keysToRender ?? []).sort((keyA: string, keyB: string) => {
          const textA = sortBy === 'value' ? valueOptionsContent[keyA] : keyA;
          const textB = sortBy === 'value' ? valueOptionsContent[keyB] : keyB;

          let sortResult = textA
            .toLocaleUpperCase()
            .localeCompare(textB.toLocaleUpperCase());

          if (sortFn != null) {
            sortResult = sortFn(textA, textB);
          } else {
            const numberA = parseFloat(textA);
            const numberB = parseFloat(textB);
            if (!isNaN(numberA) && !isNaN(numberB)) {
              sortResult = numberA - numberB;
            }
          }

          return (sortMode === 'ascending' ? 1 : -1) * sortResult;
        })
      );
    }

    // Add selected value if exists to not break the Select
    if (value && !selectItemKeys.includes(value)) {
      selectItemKeys.push(value);
    }

    // Add keys that must be always shown
    if (valueOptionsAlwaysShow) {
      keysOfValueOptionsAlwaysShow.forEach((key) => {
        if (valueOptionsAlwaysShow[key] && !selectItemKeys.includes(key)) {
          selectItemKeys.push(key);
        }
      });
    }

    return selectItemKeys;
  }, [
    keysOfValueOptionsContent,
    keysOfValueOptionsAlwaysShow,
    valueOptionsContent,
    searchable,
    searchText,
    enableEmptySelection,
    value,
    valueOptionsAlwaysShow,
    flexSearchIndex,
    sortMode,
    sortFn,
    sortBy,
  ]);

  const renderItem = useCallback(
    (key: string) => {
      if (key === EMPTY_CONTENT_DESIGNATOR) {
        return (
          <FilteredSelectItem key={key} value="" contentId={placeholderText!} />
        );
      }
      return (
        <FilteredSelectItem
          data-test-id={`select-item-${key}`}
          key={key}
          value={key}
          optionContent={valueOptionsContentOverride?.[key]}
          contentId={valueOptionsContent[key]}
          disabledReason={valueOptionsDisabled?.[key]}
          prefixIcon={valueOptionsIcon ? valueOptionsIcon[key] : undefined}
        />
      );
    },
    [
      placeholderText,
      valueOptionsContent,
      valueOptionsContentOverride,
      valueOptionsDisabled,
      valueOptionsIcon,
    ]
  );

  return (
    <Select.Root
      {...rootProps}
      open={isOpen}
      onOpenChange={onOpenChange}
      value={value || undefined}
      defaultValue={defaultValue || undefined}
      onValueChange={(value) => onChange(value, searchText)}
    >
      <Select.Trigger
        disabled={disabled}
        {...mergeProps(
          {
            className: clsx({
              [styles.error]: hasErrors,
              [styles.fullWidth]: showFullWidth,
            }),
          },
          props
        )}
      >
        <Stack
          gap="m"
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          alignItems="center"
        >
          {prefixDisplay ?? valueOptionsIcon?.[value ?? '']}

          <Select.Value
            placeholder={
              placeholderTextContent || <Content id={ContentId.SelectFilter} />
            }
          >
            {displayTextContent || placeholderTextContent}
          </Select.Value>
        </Stack>
        {showClearButton && value !== defaultValue && (
          <CrossIcon
            withHoverEffect
            size={vars.iconSize.m}
            onClick={(e) => {
              e.stopPropagation();
              onChange(defaultValue ?? '', '');
            }}
          />
        )}
        {postfixDisplay}
      </Select.Trigger>
      <Select.Content align="start" {...contentProps}>
        {loading || isBuildingSearchIndex ? (
          <PosSpinner size={vars.iconSize.s} />
        ) : (
          <>
            {searchable && (
              <SelectInputSearch
                key={inputSearchRefKey.current}
                searchPlaceholder={searchPlaceholder}
                onChange={(value) => {
                  setSearchText(value);
                  if (hasNextPage) {
                    onSearchInputChange?.(value);
                  }
                }}
                searchInput={searchText}
                disabled={!!disabled}
                onSearchTextApplied={onSearchTextApplied}
                allowSearchAsInput={!!allowSearchAsInput}
              />
            )}
            {useVirtuoso ? (
              <Virtuoso
                style={{
                  height:
                    `clamp(100px, ` +
                    (searchable
                      ? 'calc(var(--radix-popper-available-height) - 60px - 16px)'
                      : 'calc(var(--radix-popper-available-height) - 16px)') +
                    `, ${
                      selectItems.length * expectedItemSizeForVirtualWindow
                    }px)`,
                }}
                data={selectItems}
                defaultItemHeight={expectedItemSizeForVirtualWindow}
                itemContent={(_, key) => renderItem(key)}
                endReached={() => {
                  if (scrollerRef.current instanceof HTMLElement) {
                    const { scrollHeight, clientHeight } = scrollerRef.current;
                    if (scrollHeight > clientHeight) {
                      !isFetchingNextPage && hasNextPage && fetchNextPage?.();
                    }
                  }
                }}
                scrollerRef={handleScrollerRef}
              />
            ) : (
              selectItems.map((key) => renderItem(key))
            )}
          </>
        )}
      </Select.Content>
    </Select.Root>
  );
};

export const FilteredSelectItem = ({
  contentId,
  prefixIcon,
  disabledReason,
  optionContent,
  ...props
}: ComponentPropsWithoutRef<typeof Select.Item> & {
  disabledReason?: ContentId | string | boolean;
  contentId: string | ContentId;
  searchText?: string;
  prefixIcon?: ReactNode;
  optionContent?: ReactNode;
}) => {
  const contentContext = useContentContext();
  const targetText = useContent(contentId);
  const disabledReasonText =
    !disabledReason || typeof disabledReason === 'boolean'
      ? undefined
      : getContent(disabledReason, contentContext);

  return (
    <Select.Item
      {...props}
      className={styles.item}
      prefixIcon={prefixIcon}
      disabled={Boolean(disabledReason)}
      title={disabledReasonText}
      textValue={targetText}
    >
      {optionContent ? optionContent : targetText}
    </Select.Item>
  );
};
