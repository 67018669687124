import { useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import {
  OUTLIER_MODE_KTH_LOWEST_STRATEGY,
  OutlierModeKthLowestStrategy,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { STANDARD_DEVIATIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { AutoPricingOutlierMode } from 'src/WebApiController';

import * as styles from '../AutoPricing.css';
import {
  OnOutlierSettingsChangeArgs,
  useAutoPricingSettingsFormContext,
} from '../useAutoPricingSettingsFormContext';

export const AutoPricingOutlierDetectionSettingsInput = ({
  disabled,
}: {
  disabled: boolean;
}) => {
  const { onOutlierSettingsChange, watch } =
    useAutoPricingSettingsFormContext();

  const {
    outlierMode,
    outlierKthLowestLimit,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierStandardDeviations,
  } = watch();

  return outlierMode === AutoPricingOutlierMode.KthLowest ? (
    <KthLowestOutlierDetectionSettingsInput
      outlierKthLowestLimit={outlierKthLowestLimit}
      outlierKthLowestLimitRelativeSpacing={
        outlierKthLowestLimitRelativeSpacing
      }
      outlierKthLowestLimitAbsoluteSpacing={
        outlierKthLowestLimitAbsoluteSpacing
      }
      disabled={disabled}
      onOutlierSettingsChange={onOutlierSettingsChange}
    />
  ) : outlierMode === AutoPricingOutlierMode.StandardDeviations ? (
    <StandardDeviationOutlierDetectionSettingsInput
      outlierStandardDeviations={outlierStandardDeviations}
      disabled={disabled}
      onOutlierSettingsChange={onOutlierSettingsChange}
    />
  ) : null;
};

const KthLowestOutlierDetectionSettingsInput = ({
  outlierKthLowestLimit,
  outlierKthLowestLimitRelativeSpacing,
  outlierKthLowestLimitAbsoluteSpacing,
  disabled,
  onOutlierSettingsChange,
}: {
  outlierKthLowestLimit: number | null;
  outlierKthLowestLimitRelativeSpacing: number | null;
  outlierKthLowestLimitAbsoluteSpacing: number | null;
  disabled: boolean;
  onOutlierSettingsChange: (input: OnOutlierSettingsChangeArgs) => void;
}) => {
  const [outlierKthLowestStrategy, setKthLowestOutlierStrategy] = useState(
    OutlierModeKthLowestStrategy.Relative
  );

  return (
    <Stack gap="l" alignItems="center">
      <div className={styles.inlinePricingInputText}>
        <Content id={ContentId.IgnoreTheFirst} />
      </div>

      <PosTextField
        value={outlierKthLowestLimit ?? ''}
        disabled={disabled}
        type="number"
        min={0}
        max={100}
        rootProps={{
          className: styles.inputInline,
        }}
        className={styles.inputInline}
        onChange={(e) => {
          const v = parseInt(e.target.value);
          onOutlierSettingsChange({
            outlierModeNew: AutoPricingOutlierMode.KthLowest,
            outlierKthLowestLimitNew: v,
          });
        }}
      />

      <div className={styles.inlinePricingInputTextLowercase}>
        <Content id={ContentId.MoreThan} />
      </div>

      {outlierKthLowestStrategy == OutlierModeKthLowestStrategy.Relative ? (
        <PosTextField
          disabled={disabled}
          type="number"
          min={-1}
          max={100}
          rootProps={{
            className: styles.kthLowestInputField,
          }}
          className={styles.kthLowestInputField}
          value={outlierKthLowestLimitRelativeSpacing ?? ''}
          onChange={(e) => {
            let v = parseInt(e.target.value);
            if (v < 0) {
              v = 0;
            } else if (v > 100) {
              v = 100;
            }

            if (!isNaN(v)) {
              onOutlierSettingsChange({
                outlierModeNew: AutoPricingOutlierMode.KthLowest,
                outlierKthLowestLimitNew: undefined,
                outlierKthLowestLimitAbsoluteSpacingNew: null,
                outlierKthLowestLimitRelativeSpacingNew: v,
              });
            }
          }}
        />
      ) : (
        <PosTextField
          disabled={disabled}
          type="number"
          rootProps={{
            className: styles.kthLowestInputField,
          }}
          className={styles.kthLowestInputField}
          value={outlierKthLowestLimitAbsoluteSpacing ?? ''}
          onChange={(e) => {
            const v = parseInt(e.target.value);
            onOutlierSettingsChange({
              outlierModeNew: AutoPricingOutlierMode.KthLowest,
              outlierKthLowestLimitNew: undefined,
              outlierKthLowestLimitAbsoluteSpacingNew: v,
              outlierKthLowestLimitRelativeSpacingNew: null,
            });
          }}
        />
      )}

      <ToggleGroup
        disabled={disabled}
        options={OUTLIER_MODE_KTH_LOWEST_STRATEGY}
        value={outlierKthLowestStrategy}
        onValueChange={(value) => {
          if (!value) {
            return;
          }

          const strat = value as OutlierModeKthLowestStrategy;
          setKthLowestOutlierStrategy(strat);

          onOutlierSettingsChange({
            outlierModeNew: AutoPricingOutlierMode.KthLowest,
            outlierKthLowestLimitAbsoluteSpacingNew: null,
            outlierKthLowestLimitRelativeSpacingNew: null,
          });
        }}
      />

      <div className={styles.inlinePricingInputTextLowercase}>
        <Content id={ContentId.BelowTheNextCheapest} />
      </div>
    </Stack>
  );
};

const StandardDeviationOutlierDetectionSettingsInput = ({
  outlierStandardDeviations,
  disabled,
  onOutlierSettingsChange,
}: {
  outlierStandardDeviations: number | null;
  disabled: boolean;
  onOutlierSettingsChange: (input: OnOutlierSettingsChangeArgs) => void;
}) => {
  return (
    <Stack>
      <PosEnumSelect
        value={outlierStandardDeviations?.toString()}
        disabled={disabled}
        className={styles.inputInline}
        valueOptionsContent={STANDARD_DEVIATIONS_TO_CID}
        enableEmptySelection={true}
        onClick={(e) => e.stopPropagation()}
        onChange={(v) => {
          const value = parseInt(v ?? '');
          if (value != null && !isNaN(value)) {
            onOutlierSettingsChange({
              outlierModeNew: AutoPricingOutlierMode.StandardDeviations,
              outlierStandardDeviationsNew: value,
            });
          } else {
            onOutlierSettingsChange({
              outlierModeNew: AutoPricingOutlierMode.StandardDeviations,
              outlierStandardDeviationsNew: null,
            });
          }
        }}
      />
    </Stack>
  );
};
