import { ReactNode } from 'react';
import { AutomatiqSquareLogo } from 'src/svgs/AutomatiqSquareLogo';
import { AxsSquareLogo } from 'src/svgs/AxsSquareLogo';
import { FaxExchangeSquareLogo } from 'src/svgs/FaxExchangeSquareLogo';
import { GametimeSquareLogo } from 'src/svgs/GametimeSquareLogo';
import { GoTicketsSquareLogo } from 'src/svgs/GoTicketsSquareLogo';
import { LyteSquareLogo } from 'src/svgs/LyteSquareLogo';
import { SeatGeekSquareLogo } from 'src/svgs/SeatGeekSquareLogo';
import { StubHubSquareLogo } from 'src/svgs/StubHubSquareLogo';
import { TicketEvolutionSquareLogo } from 'src/svgs/TicketEvolutionSquareLogo';
import { TicketmasterSquareLogo } from 'src/svgs/TicketmasterSquareLogo';
import { TicketNetworkSquareLogo } from 'src/svgs/TicketNetworkSquareLogo';
import { TickPickSquareLogo } from 'src/svgs/TickPickSquareLogo';
import { VividSeatsSquareLogo } from 'src/svgs/VividSeatsSquareLogo';
import { Marketplace } from 'src/WebApiController';

const getMarketplaceSquareLogo = (
  marketplace: Marketplace | undefined | null
): ReactNode => {
  switch (marketplace) {
    case Marketplace.VividSeats:
      return <VividSeatsSquareLogo />;
    case Marketplace.StubHub:
      return <StubHubSquareLogo />;
    case Marketplace.SeatGeek:
      return <SeatGeekSquareLogo />;
    case Marketplace.TickPick:
      return <TickPickSquareLogo />;
    case Marketplace.Ticketmaster:
      return <TicketmasterSquareLogo />;
    case Marketplace.AXS:
      return <AxsSquareLogo />;
    case Marketplace.FanXchange:
      return <FaxExchangeSquareLogo />;
    case Marketplace.Gametime:
      return <GametimeSquareLogo />;
    case Marketplace.TicketEvolution:
      return <TicketEvolutionSquareLogo />;
    case Marketplace.TicketNetwork:
      return <TicketNetworkSquareLogo />;
    case Marketplace.Automatiq:
      return <AutomatiqSquareLogo />;
    case Marketplace.Lyte:
      return <LyteSquareLogo />;
    case Marketplace.GoTickets:
      return <GoTicketsSquareLogo />;
    default:
      return marketplace;
  }
};

interface MarketplaceSquareLogoProps {
  marketplace?: Marketplace | undefined | null;
}

export const MarketplaceSquareLogo = ({
  marketplace,
}: MarketplaceSquareLogoProps) => {
  return <>{getMarketplaceSquareLogo(marketplace)}</>;
};
