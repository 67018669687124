import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { createGetColor } from 'src/components/Listings/InventoryEventPage/utils/colorUtils';
import { useContent } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './GroupingMethods.css';

export const ZoneMap = () => {
  const [mapOverlay, setMapOverlay] = useState<boolean>(true);
  const toggleZoneMap = useCallback(() => {
    setMapOverlay((prev) => !prev);
  }, []);

  const viewZoneMapText = useContent(ContentId.ViewZoneMap);
  const hideZoneMapText = useContent(ContentId.HideZoneMap);
  const buttonText = useMemo(
    () => (mapOverlay ? viewZoneMapText : hideZoneMapText),
    [hideZoneMapText, mapOverlay, viewZoneMapText]
  );

  const { venueZoneConfigs } = useEventMapContext();

  const getColor = useMemo(() => {
    if (isEmpty(venueZoneConfigs?.sectionSectionGroupLookup)) {
      return undefined;
    }
    return createGetColor(venueZoneConfigs?.sectionSectionGroupLookup);
  }, [venueZoneConfigs?.sectionSectionGroupLookup]);

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.overlayContainer, {
          [styles.overlay]: mapOverlay,
        })}
      >
        <div className={styles.zoneConfigName}>{venueZoneConfigs?.name}</div>
        {mapOverlay && (
          <Button
            variant="outline"
            className={styles.toggleButton}
            onClick={toggleZoneMap}
          >
            {buttonText}
          </Button>
        )}
      </div>
      <div
        className={clsx(styles.zoneMapWrapper, {
          [styles.zoneMapOverlay]: mapOverlay,
        })}
      >
        <div className={styles.zoneMapContainer}>
          <EventVenueMap
            showDefaultMapColors={true}
            isZoomEnabled={false}
            isPanEnabled={false}
            getColor={getColor}
          />
        </div>
      </div>
      {!mapOverlay && (
        <div className={styles.toggleButtonBottomContainer}>
          <Button variant="outline" onClick={toggleZoneMap}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};
