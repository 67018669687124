export function FaxExchangeSquareLogo({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="3" fill="#1C74A5" />
      <path
        d="M8.233 9.78L4.323 4H7.417L9.797 7.876L12.262 4H15.186L11.293 9.797L15.526 16.138H12.347L9.695 11.939L6.992 16.138H4L8.233 9.78Z"
        fill="#00E0C3"
      />
    </svg>
  );
}
