import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingDetailsTicketSectionUpdates,
} from 'src/WebApiController';

const getListingDetailsTicketsInput = (
  listing: ListingDetails | null | undefined
): ListingDetailsTicketSectionUpdates => {
  return {
    id: listing?.id ?? 0,
    rowVersion: null,
    splitType: listing?.splitType ?? null,
    hideSeatsFromMarketplace: listing?.hideSeats ?? null,
    inHandDate: listing?.inHandAt ?? null,
    isNoFulfill: listing?.isNoFulfill ?? null,
    delivType: listing?.delivType ?? null,
    seatTraits: listing?.seatTraits ?? null,
    section: listing?.seating?.section ?? null,
    sectionId: listing?.seating?.sectionId ?? null,
    row: listing?.seating?.row ?? null,
    rowId: listing?.seating?.rowId ?? null,
    faceValue: listing?.faceValue?.amt ?? null,
    manualMaxDisplayQuantity:
      listing?.manMaxDispQty ?? listing?.maxDispQty ?? null,
    internalNotes: listing?.privNotes ?? null,
    daysBeforeEvent: null,
    ticketTypeRules: listing?.tktTypeRules ?? null,
    updateSalesOfListings: null,
    updatePredeliveryETicketArtifacts: null,
  };
};

export const useTicketsForm = (
  listing: ListingDetails | null | undefined,
  onSubmitStart: () => void,
  onSubmitDone: (success?: boolean) => Promise<void>
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const methods = useForm<ListingDetailsTicketSectionUpdates>({
    defaultValues: listing?.dataIsLoaded[ListingDetailDataField.Tickets]
      ? getListingDetailsTicketsInput(listing)
      : undefined,
  });

  useEffect(() => {
    if (
      methods.formState.defaultValues == null &&
      listing?.dataIsLoaded[ListingDetailDataField.Tickets]
    ) {
      methods.reset(getListingDetailsTicketsInput(listing));
    }
  }, [methods, listing]);

  const onSubmitHandler = useCallback(
    async (ticketsSectionForm: ListingDetailsTicketSectionUpdates) => {
      tryInvokeApi(
        async () => {
          onSubmitStart();
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).updateListingTicketSection(ticketsSectionForm);

          if (isSuccess(result)) {
            // Reset the form to latest changed
            methods.reset(ticketsSectionForm);
            await onSubmitDone();
          } else {
            showErrorDialog(
              'ListingClient.updateListingTicketSection',
              { message: result.message, status: result.status },
              {
                trackErrorData: {
                  ticketsSectionForm,
                },
              }
            );
          }
        },
        (error) => {
          showErrorDialog('ListingClient.updateListingTicketSection', error, {
            trackErrorData: ticketsSectionForm,
          });
        },
        () => {
          onSubmitDone(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      methods,
      onSubmitDone,
      onSubmitStart,
      showErrorDialog,
    ]
  );

  const onSubmit = useCallback(() => {
    methods.handleSubmit(onSubmitHandler)();
  }, [methods, onSubmitHandler]);

  const onReset = useCallback(() => {
    methods.reset(getListingDetailsTicketsInput(listing));
  }, [listing, methods]);

  const input = methods.watch();

  const hasChanges = useMemo(() => {
    const defaultValues = methods.formState.defaultValues;
    return (
      input.splitType !== defaultValues?.splitType ||
      input.hideSeatsFromMarketplace !==
        defaultValues?.hideSeatsFromMarketplace ||
      input.inHandDate !== defaultValues?.inHandDate ||
      input.isNoFulfill !== defaultValues?.isNoFulfill ||
      input.delivType !== defaultValues?.delivType ||
      input.section !== defaultValues?.section ||
      input.sectionId !== defaultValues?.sectionId ||
      input.row !== defaultValues?.row ||
      input.rowId !== defaultValues?.rowId ||
      input.faceValue !== defaultValues?.faceValue ||
      input.manualMaxDisplayQuantity !==
        defaultValues?.manualMaxDisplayQuantity ||
      input.internalNotes !== defaultValues?.internalNotes ||
      input.daysBeforeEvent !== defaultValues?.daysBeforeEvent ||
      !isEqual(input.ticketTypeRules, defaultValues?.ticketTypeRules ?? null) ||
      input.updateSalesOfListings !== defaultValues?.updateSalesOfListings ||
      !isEqual(input.seatTraits, defaultValues?.seatTraits ?? null)
    );
  }, [input, methods.formState.defaultValues]);

  return {
    hasChanges,
    methods,
    onSubmit,
    onReset,
  };
};
