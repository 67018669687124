import { cloneDeep } from 'lodash-es';
import { useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { AutoPricingCircuitBreakerMaxDiscountVelocityInput } from 'src/components/AutoPricing/AutoPricingCircuitBreakerMaxDiscountVelocityInput/AutoPricingCircuitBreakerMaxDiscountVelocityInput';
import { AutoPricingDefaultCompQuantitiesInput } from 'src/components/AutoPricing/AutoPricingDefaultCompQuantitiesInput/AutoPricingDefaultCompQuantitiesInput';
import { AutoPricingMinCompListingsSlider } from 'src/components/AutoPricing/AutoPricingMinCompListingsSlider/AutoPricingMinCompListingsSlider';
import {
  AutoPricingSettingsFormContent,
  PricingSetting,
} from 'src/components/AutoPricing/AutoPricingSettingsFormContent/AutoPricingSettingsFormContent';
import { AutoPricingUnderCutRelativeAndAbsoluteAmountsInput } from 'src/components/AutoPricing/AutoPricingUnderCutRelativeAndAbsoluteAmountsInput/AutoPricingUnderCutRelativeAndAbsoluteAmountsInput';
import { Content } from 'src/contexts/ContentContext';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Switch } from 'src/core/ui';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AccountAutoPricingSettingsInputs,
  UiCurrency,
} from 'src/WebApiController';

import { setValueAsDecimalNumber } from '../../SyncCenter/SyncCenter.utils';
import * as styles from './AccountAutoPricingSettingsForm.css';

export const AccountAutoPricingSettingsForm = ({
  pricingSettings,
  onSaved,
  uiCurrency,
  disabled: disabledProp,
  allowSeatScoreCompListingMode,
  allowPriceByWebsitePrice,
}: {
  pricingSettings: AccountAutoPricingSettingsInputs | null | undefined;
  onSaved?: (
    pricingSettingsNew: AccountAutoPricingSettingsInputs
  ) => Promise<void>;
  uiCurrency: UiCurrency;
  disabled: boolean;
  allowSeatScoreCompListingMode: boolean;
  allowPriceByWebsitePrice: boolean;
}) => {
  const methods = useForm<AccountAutoPricingSettingsInputs>({
    defaultValues: pricingSettings ? cloneDeep(pricingSettings) : undefined,
  });

  const { handleSubmit } = methods;

  const disabled = disabledProp;
  return (
    <FormProvider {...methods}>
      <AutoPricingSettingsFormContent
        venueMapSections={undefined}
        hasSeatScores={false}
        uiCurrency={uiCurrency}
        allowSeatScoreCompListingMode={allowSeatScoreCompListingMode}
        hideCompListingQuantityFilters={true}
        hideUnderCutAmountInput={true}
        disabled={disabled}
        priceAgainstSectionChildren={
          <>
            <PricingSetting
              header={
                <div>
                  <Content id={ContentId.DefaultQuantityFilters} />
                </div>
              }
              headerStyle={{ alignSelf: 'start' }}
              detail={
                <AutoPricingDefaultCompQuantitiesInput disabled={disabled} />
              }
            />

            <PricingSetting
              header={
                <div>
                  <Content id={ContentId.MinListingsRequired} />
                </div>
              }
              detail={<AutoPricingMinCompListingsSlider disabled={disabled} />}
            />

            <PricingSetting
              header={<Content id={ContentId.HowToDeterminePrice} />}
              detail={
                <AutoPricingUnderCutRelativeAndAbsoluteAmountsInput
                  uiCurrency={uiCurrency}
                  disabled={disabled}
                />
              }
            />
          </>
        }
        pricingProtectionSectionChildren={
          <PricingProtectionSettings
            disabled={disabled}
            allowPriceByWebsitePrice={allowPriceByWebsitePrice}
          />
        }
        advancedSettingsSectionChildren={
          <VelocityCircuitBreakerSettings disabled={disabled} />
        }
      />

      <div className={styles.footer}>
        <Button
          disabled={disabled}
          onClick={handleSubmit((data) => onSaved?.(data))}
        >
          <Content id={ContentId.Save} />
        </Button>
      </div>
    </FormProvider>
  );
};

const PricingProtectionSettings = ({
  disabled,
  allowPriceByWebsitePrice,
}: {
  disabled: boolean;
  allowPriceByWebsitePrice: boolean;
}) => {
  const { register, setValue, watch } =
    useFormContext<AccountAutoPricingSettingsInputs>();

  const priceByNetProceeds = watch('priceByNetProceeds');

  return (
    <>
      {allowPriceByWebsitePrice && (
        <PricingSetting
          header={<Content id={ContentId.SimulateWebsiteDiscount} />}
          detail={
            <Switch
              disabled={disabled}
              checked={priceByNetProceeds}
              onClick={(e) => e.stopPropagation()}
              onCheckedChange={(isChecked) => {
                const priceByNetProceedsNew = isChecked;
                if (priceByNetProceedsNew !== priceByNetProceeds) {
                  setValue('priceByNetProceeds', priceByNetProceedsNew);
                }
              }}
            />
          }
        />
      )}

      <PricingSetting
        header={<Content id={ContentId.FloorRelativeToUnitCost} />}
        detail={
          <PosTextField
            disabled={disabled}
            type="number"
            postfixDisplay="%"
            rootProps={{
              className: styles.inputInline,
            }}
            className={styles.inputInline}
            {...register('circuitBreakerRelativeFloor', {
              setValueAs: setValueAsDecimalNumber,
            })}
          />
        }
      />
    </>
  );
};

const VelocityCircuitBreakerSettings = ({
  disabled,
}: {
  disabled: boolean;
}) => {
  const { setValue, watch } =
    useFormContext<AccountAutoPricingSettingsInputs>();

  const {
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
  } = watch();

  const restrictVelocityOfPriceUpdates = useMemo(() => {
    return (
      !!circuitBreakerMaxDiscountVelocityPercent &&
      !!circuitBreakerMaxDiscountVelocityTicksInHours
    );
  }, [
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
  ]);

  return (
    <>
      <PricingSetting
        header={<Content id={ContentId.RestrictPriceDropVelocity} />}
        detail={
          <Switch
            disabled={disabled}
            checked={restrictVelocityOfPriceUpdates}
            onClick={(e) => e.stopPropagation()}
            onCheckedChange={(isChecked) => {
              const restrictVelocityOfPriceUpdatesNew = isChecked;
              if (
                restrictVelocityOfPriceUpdatesNew !==
                restrictVelocityOfPriceUpdates
              ) {
                const circuitBreakerMaxDiscountVelocityPercentNew =
                  restrictVelocityOfPriceUpdatesNew ? 0.8 : null;
                const circuitBreakerMaxDiscountVelocityTicksInHours =
                  restrictVelocityOfPriceUpdatesNew ? 1 : null;
                setValue(
                  'circuitBreakerMaxDiscountVelocityPercent',
                  circuitBreakerMaxDiscountVelocityPercentNew
                );
                setValue(
                  'circuitBreakerMaxDiscountVelocityTicksInHours',
                  circuitBreakerMaxDiscountVelocityTicksInHours
                );
              }
            }}
          />
        }
      />

      {restrictVelocityOfPriceUpdates && (
        <PricingSetting
          header={<Content id={ContentId.Velocity} />}
          detail={
            <AutoPricingCircuitBreakerMaxDiscountVelocityInput
              disabled={disabled}
            />
          }
        />
      )}
    </>
  );
};
