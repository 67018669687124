import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, UseFormSetValue } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventHubContext } from 'src/contexts/EventHubContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import {
  Alert,
  AlertWithSuppressionDialog,
  useManualPriceDisableAutoPricingDialog,
  useSuspiciousPriceChangeDialog,
} from 'src/dialogs/AlertWithSuppressionDialog';
import { useManualPriceAdjustFloorOrCeilingDialog } from 'src/dialogs/AlertWithSuppressionDialog/useManualPriceAdjustFloorOrCeilingDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  validateAutoPricingSettings,
  validateCompListingSettings,
  validateUndercutSettings,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { isDatePassedHours } from 'src/utils/dateTimeUtils';
import {
  getListingDetailsUpdateInput,
  isSuspiciousPriceChange,
} from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Feature,
  Listing,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
  ListingDetailsUpdateInput,
  ListingPricesUpdateInput,
} from 'src/WebApiController';

import { ListingWithEvent } from '../ListingTable.types';
import { ListingPricingInput } from './ListingPriceForm.types';

export function ListingPricingForm({
  rowData,
  tableRow,
}: {
  rowData: ListingWithEvent | null;
  tableRow: React.ReactNode;
}) {
  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = useMemo(
    () => getUiCurrency(rowData?.listing?.currency),
    [getUiCurrency, rowData?.listing?.currency]
  );
  const hasListingPricesEndpointFeature = useUserHasFeature(
    Feature.ListingPricesEndpoint
  );
  const hasSectionalPricingApiInListingTable = useUserHasFeature(
    Feature.SectionalPricingApiInListingTable
  );

  const createListingPricingInput = useCallback(
    (listing?: Listing | null) => ({
      ...getListingDetailsUpdateInput(listing as ListingDetails, uiCurrency),
      isSubmitting: false,
      rowIndex: rowData?.rowIndex || 0,
      currentPageIndex: rowData?.currentPageIndex || 0,
    }),
    [rowData?.currentPageIndex, rowData?.rowIndex, uiCurrency]
  );

  const methods = useForm<ListingPricingInput>({
    defaultValues: createListingPricingInput(rowData?.listing),
  });
  const { activeAccountWebClientConfig } = useAppContext();
  const {
    reset,
    formState,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = methods;
  const { showErrorDialog } = useErrorBoundaryContext();
  const setPriceSettingValues =
    setValue as unknown as UseFormSetValue<ListingDetailsUpdateInput>;
  const { updateItemInEvent } = useCatalogDataContext();
  const { onUpdateListing } = useEventHubContext();

  // Nuance - this is necessary so the form picks up whenever the listing changed outside the table
  // like when ListingModal made edits to the data of this listing
  useEffect(() => {
    if (
      formState.defaultValues == null ||
      (rowData?.listing &&
        (rowData.listing.rowVer === 0 || // zero means reset
          rowData.listing.rowVer > (formState.defaultValues.rowVersion ?? 0)))
    ) {
      const newForm = createListingPricingInput(rowData?.listing);
      if (!isEqual(newForm, formState.defaultValues)) {
        reset(createListingPricingInput(rowData?.listing));
      }
    }
  }, [
    methods,
    reset,
    rowData?.listing,
    rowData?.rowIndex,
    rowData?.currentPageIndex,
    uiCurrency,
    createListingPricingInput,
    formState.defaultValues,
  ]);

  const listPrice = watch('listPrice') ?? -1;
  const allInPrice = watch('allInPrice') ?? -1;
  const isSubmitting = watch('isSubmitting');
  const autoPricingEnabled = watch('autoPricingEnabled');
  const netProceedsFloor = watch('netProceedsFloor') ?? 0;
  const netProceedsCeiling = watch('netProceedsCeiling');
  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const compListingFloor = watch('compListingFloor');
  const compListingCeiling = watch('compListingCeiling');
  const undercutAbsoluteAmount = watch('undercutAbsoluteAmount');
  const undercutRelativeAmount = watch('undercutRelativeAmount');
  const undercutMode = watch('undercutMode');
  const compListingMode = watch('compListingMode');
  const compListingQuantityScoreAdjustmentEnabled = watch(
    'compListingQuantityScoreAdjustmentEnabled'
  );
  const compListingQuantityScoreAdjustmentOverrideJson = watch(
    'compListingQuantityScoreAdjustmentOverrideJson'
  );
  const compListingOnlyForSameZoneEnabled = watch(
    'compListingOnlyForSameZoneEnabled'
  );
  const compListingOnlyForSelectedSectionsEnabled = watch(
    'compListingOnlyForSelectedSectionsEnabled'
  );
  const compListingExcludeFanInventory = watch(
    'compListingExcludeFanInventory'
  );
  const outlierMode = watch('outlierMode');
  const outlierStandardDeviations = watch('outlierStandardDeviations');
  const outlierKthLowestLimit = watch('outlierKthLowestLimit');
  const outlierKthLowestLimitAbsoluteSpacing = watch(
    'outlierKthLowestLimitAbsoluteSpacing'
  );
  const outlierKthLowestLimitRelativeSpacing = watch(
    'outlierKthLowestLimitRelativeSpacing'
  );
  const circuitBreakerMinCompListingCount = watch(
    'circuitBreakerMinCompListingCount'
  );
  const circuitBreakerMaxDiscountVelocityPercent = watch(
    'circuitBreakerMaxDiscountVelocityPercent'
  );
  const circuitBreakerMaxDiscountVelocityTicksInHours = watch(
    'circuitBreakerMaxDiscountVelocityTicksInHours'
  );

  const submitPriceChange = useCallback(() => {
    if (!rowData?.listing) return;

    tryInvokeApi(
      async () => {
        let updatedListing: Listing;
        if (hasListingPricesEndpointFeature) {
          // TODO: Move to its own form when feature is fully launched @tianxu
          const updated = getValues();
          const input: ListingPricesUpdateInput = {
            id: updated.id,
            allInPrice: updated.allInPrice,
            listPrice: updated.listPrice,
            netProceedsCeiling: updated.netProceedsCeiling,
            netProceedsFloor: updated.netProceedsFloor,
            marketplacePriceUpdates: updated.marketplacePriceUpdates,
            autoPricingEnabled: updated.autoPricingEnabled,
          };
          updatedListing = await new ListingClient(
            activeAccountWebClientConfig
          ).updateListingPricesV2(input);
        } else {
          updatedListing = await new ListingClient(
            activeAccountWebClientConfig
          ).updateListingPrices(
            rowData.listing!.id,
            listPrice,
            netProceedsFloor
          );
        }

        if (updatedListing) {
          // Refresh the active data so the SaleDetail dialog and table will have the new content
          updateItemInEvent(updatedListing, ActionOutboxEntityType.Listing);

          // Reset the form to the new updated listing so values don't get reverted
          // This is important to not having the row revert back to the previous values
          reset(createListingPricingInput(updatedListing));

          onUpdateListing(updatedListing.id);
        }
      },
      (error) => {
        showErrorDialog('ListingClient.updateListing(prices)', error, {
          trackErrorData: { listingId: rowData.listing!.id, listPrice },
        });
      }
    );
  }, [
    rowData?.listing,
    hasListingPricesEndpointFeature,
    getValues,
    activeAccountWebClientConfig,
    listPrice,
    netProceedsFloor,
    updateItemInEvent,
    reset,
    createListingPricingInput,
    onUpdateListing,
    showErrorDialog,
  ]);

  const warningDialog = useBasicDialog();

  const hasSectionalLoadDataFeature = useUserHasFeature(
    Feature.ListingLoadBySection
  );

  const submitPricingSettingsChange = useCallback(
    (listForm: ListingPricingInput) => {
      if (!rowData?.listing) return;

      tryInvokeApi(
        async () => {
          const client = new ListingClient(activeAccountWebClientConfig);
          const listing = rowData!.listing!;
          if (hasSectionalPricingApiInListingTable) {
            const input: ListingDetailsAutoPricingSectionUpdates = {
              ...listForm,
              id: listing.id,
              rowVersion: null,
              autoPriceFrequency: null,
              compListingQuantityFilters: null,
              compListingQuantityScoreAdjustmentOverrides: null,
              scoreModel: null,
              autoPricingDefaultQuantityFiltersJson: null,
              venueZoneOverrideId: null,
              venueZoneConfigType: null,
            };
            await client.updateListingAutoPricingSection(input);

            // Refersh listing
            const updatedListing = await client.getListingByListingId(
              listing.id,
              hasSectionalLoadDataFeature
                ? (listing as ListingDetails)
                : undefined,
              hasSectionalLoadDataFeature
                ? [ListingDetailDataField.Pricing]
                : null
            );
            if (updatedListing) {
              updateItemInEvent(updatedListing, ActionOutboxEntityType.Listing);
              reset(createListingPricingInput(updatedListing));
              onUpdateListing(updatedListing.id);
            }
          } else {
            const newInput = {
              item1: listing.id,
              item2: listForm,
            };
            const updatedListing =
              await client.updateListingsPricingSettings(newInput);
            if (updatedListing) {
              // Refresh the active data so the SaleDetail dialog and table will have the new content
              updateItemInEvent(updatedListing, ActionOutboxEntityType.Listing);

              // Reset the form to the new updated listing so values don't get reverted
              // This is important to not having the row revert back to the previous values
              reset(createListingPricingInput(updatedListing));
              onUpdateListing(updatedListing.id);
            }
          }
        },
        (error) => {
          showErrorDialog(
            'ListingClient.updateListing(pricingSettings)',
            error,
            {
              trackErrorData: listForm,
            }
          );
        }
      );
    },
    [
      rowData,
      activeAccountWebClientConfig,
      hasSectionalPricingApiInListingTable,
      hasSectionalLoadDataFeature,
      updateItemInEvent,
      reset,
      createListingPricingInput,
      onUpdateListing,
      showErrorDialog,
    ]
  );

  const suspiciousPriceChangeHookProps = useSuspiciousPriceChangeDialog(
    () => {
      submitPriceChange();
    },
    () => {
      // revert back
      setValue('listPrice', formState.defaultValues?.listPrice ?? null);
      setValue('allInPrice', formState.defaultValues?.allInPrice ?? null);
      setValue('isSubmitting', false);
    }
  );

  const manualPriceDisableAutoPricingProps =
    useManualPriceDisableAutoPricingDialog(() =>
      setValue('autoPricingEnabled', false)
    );
  const manualPriceAdjustFloorCeilingProps =
    useManualPriceAdjustFloorOrCeilingDialog(
      listPrice,
      netProceedsFloor,
      netProceedsCeiling,
      () => {
        if ((listPrice ?? 0) < (netProceedsFloor ?? Number.MIN_VALUE)) {
          setValue('netProceedsFloor', listPrice);
        } else if (
          (listPrice ?? 0) > (netProceedsCeiling ?? Number.MAX_VALUE)
        ) {
          setValue('netProceedsCeiling', listPrice);
        }
      },
      () => {
        if ((listPrice ?? 0) < (netProceedsFloor ?? Number.MIN_VALUE)) {
          setValue('listPrice', netProceedsFloor);
        } else if (
          (listPrice ?? 0) > (netProceedsCeiling ?? Number.MAX_VALUE)
        ) {
          setValue('listPrice', netProceedsCeiling);
        }
      }
    );

  const requiredMsg = useContent(ContentId.Required);
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const floorMustBeLessThanCeilingError = useContent(
    ContentId.FloorMustBeLessThanCeiling
  );
  const compListingFloorCeilingError = useContent(
    ContentId.CompListingFloorMustLessThanCeiling
  );

  useEffect(() => {
    // Input validity?
    if (isSubmittingPricingSettings) {
      if (
        autoPricingEnabled === formState.defaultValues?.autoPricingEnabled &&
        undercutAbsoluteAmount ===
          formState.defaultValues?.undercutAbsoluteAmount &&
        undercutRelativeAmount ===
          formState.defaultValues?.undercutRelativeAmount &&
        undercutMode === formState.defaultValues?.undercutMode &&
        compListingMode === formState.defaultValues?.compListingMode &&
        compListingCeiling === formState.defaultValues?.compListingCeiling &&
        compListingFloor === formState.defaultValues?.compListingFloor &&
        compListingQuantityScoreAdjustmentEnabled ===
          formState.defaultValues?.compListingQuantityScoreAdjustmentEnabled &&
        compListingOnlyForSelectedSectionsEnabled ===
          formState.defaultValues?.compListingOnlyForSelectedSectionsEnabled &&
        compListingQuantityScoreAdjustmentOverrideJson ===
          formState.defaultValues
            ?.compListingQuantityScoreAdjustmentOverrideJson &&
        compListingOnlyForSameZoneEnabled ===
          formState.defaultValues?.compListingOnlyForSameZoneEnabled &&
        compListingExcludeFanInventory ===
          formState.defaultValues?.compListingExcludeFanInventory &&
        outlierMode === formState.defaultValues?.outlierMode &&
        outlierStandardDeviations ===
          formState.defaultValues?.outlierStandardDeviations &&
        outlierKthLowestLimit ===
          formState.defaultValues?.outlierKthLowestLimit &&
        outlierKthLowestLimitAbsoluteSpacing ===
          formState.defaultValues?.outlierKthLowestLimitAbsoluteSpacing &&
        outlierKthLowestLimitRelativeSpacing ===
          formState.defaultValues?.outlierKthLowestLimitRelativeSpacing &&
        circuitBreakerMinCompListingCount ===
          formState.defaultValues?.circuitBreakerMinCompListingCount &&
        circuitBreakerMaxDiscountVelocityPercent ===
          formState.defaultValues?.circuitBreakerMaxDiscountVelocityPercent &&
        circuitBreakerMaxDiscountVelocityTicksInHours ===
          formState.defaultValues?.circuitBreakerMaxDiscountVelocityTicksInHours
      ) {
        // No change, just quit
        setValue('isSubmittingPricingSettings', false);
        return;
      }

      let hasErrors = false;
      const listForm = getValues();

      if (autoPricingEnabled !== formState.defaultValues?.autoPricingEnabled) {
        // Switching on autopricing might set all other values to default, so handle here first
        if (
          !validateAutoPricingSettings(
            clearErrors,
            setError,
            setPriceSettingValues,
            listForm,
            floorMustBeLessThanCeilingError,
            compListingFloorCeilingError,
            requiredMsg
          )
        ) {
          // Reset the field to the original values
          setValue('autoPricingEnabled', rowData?.listing?.isAutoPrc ?? false);
        }
      } else {
        // Only one of the fields should be changed within this branch
        if (
          compListingMode !== formState.defaultValues?.compListingMode ||
          compListingCeiling !== formState.defaultValues?.compListingCeiling ||
          compListingFloor !== formState.defaultValues?.compListingFloor
        ) {
          const fieldEdited =
            compListingCeiling !== formState.defaultValues?.compListingCeiling
              ? 'compListingCeiling'
              : compListingFloor !== formState.defaultValues?.compListingFloor
              ? 'compListingFloor'
              : 'compListingMode';
          if (
            !validateCompListingSettings(
              clearErrors,
              setError,
              setPriceSettingValues,
              listForm,
              compListingFloorCeilingError,
              requiredMsg,
              fieldEdited
            )
          ) {
            hasErrors = true;
          }
        }

        if (
          undercutAbsoluteAmount !==
            formState.defaultValues?.undercutAbsoluteAmount ||
          undercutRelativeAmount !==
            formState.defaultValues?.undercutRelativeAmount ||
          undercutMode !== formState.defaultValues?.undercutMode
        ) {
          const fieldEdited =
            undercutAbsoluteAmount !==
            formState.defaultValues?.undercutAbsoluteAmount
              ? 'undercutAbsoluteAmount'
              : undercutRelativeAmount !==
                formState.defaultValues?.undercutRelativeAmount
              ? 'undercutRelativeAmount'
              : 'undercutMode';
          if (
            !validateUndercutSettings(
              clearErrors,
              setPriceSettingValues,
              listForm,
              fieldEdited
            )
          ) {
            hasErrors = true;
          }
        }
      }

      if (hasErrors) {
        setValue('isSubmittingPricingSettings', false);
        return;
      }

      submitPricingSettingsChange(listForm);
    } else {
      setValue('isSubmittingPricingSettings', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmittingPricingSettings]);

  useEffect(() => {
    if (
      isSubmitting &&
      (listPrice >= 0 || allInPrice >= 0 || netProceedsFloor)
    ) {
      if (
        listPrice === formState.defaultValues?.listPrice &&
        allInPrice === formState.defaultValues?.allInPrice &&
        netProceedsFloor === formState.defaultValues?.netProceedsFloor
      ) {
        // No change, just quit
        setValue('isSubmitting', false);
        return;
      }

      clearErrors('listPrice');
      clearErrors('netProceedsFloor');

      let hasErrors = false;

      if (listPrice < 0) {
        setError('listPrice', { message: requiredMsg });
        hasErrors = true;
      }
      if (netProceedsFloor < 0) {
        setError('netProceedsFloor', { message: requiredMsg });
        hasErrors = true;
      }

      if (hasErrors) {
        setValue('isSubmitting', false);
        return;
      }

      const newAlerts = [];
      // Check only for updating list price
      if (listPrice > 0 && listPrice !== formState.defaultValues?.listPrice) {
        // Only validate for suspicious price change if listPrice > 0
        if (
          listPrice < (netProceedsFloor ?? Number.MIN_VALUE) ||
          listPrice > (netProceedsCeiling ?? Number.MAX_VALUE)
        ) {
          if (
            isDatePassedHours(
              manualPriceAdjustFloorCeilingProps.lastTimeStamp,
              1
            )
          ) {
            newAlerts.push(manualPriceAdjustFloorCeilingProps);
          }
        }

        if (
          autoPricingEnabled &&
          isDatePassedHours(manualPriceDisableAutoPricingProps.lastTimeStamp, 1)
        ) {
          newAlerts.push(manualPriceDisableAutoPricingProps);
        }

        const showWarning = isSuspiciousPriceChange(
          listPrice,
          formState.defaultValues?.listPrice
        );
        if (
          showWarning &&
          isDatePassedHours(suspiciousPriceChangeHookProps.lastTimeStamp, 1)
        ) {
          newAlerts.push(suspiciousPriceChangeHookProps);
        }
      }

      if (newAlerts.length) {
        setAlerts(newAlerts);
        warningDialog.launchDialog();
      } else {
        submitPriceChange();
      }
    } else {
      setValue('isSubmitting', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  return (
    <FormProvider key={rowData?.listing?.id} {...methods}>
      {tableRow}
      <AlertWithSuppressionDialog
        headerText={<Content id={ContentId.ManualPriceWarning} />}
        size="md"
        {...warningDialog.dialogProps}
        alerts={alerts}
        onOkay={() => {
          submitPriceChange();
          warningDialog.closeDialog();
        }}
        onCancel={() => {
          setValue('isSubmitting', false);
          warningDialog.closeDialog();
        }}
      />
    </FormProvider>
  );
}
