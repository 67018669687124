import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ErrorTypes } from 'src/contexts/ErrorBoundaryContext';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PricingClient } from 'src/WebApiController';
import {
  AutoPricingInputs,
  Event,
  VenueMapZoneConfigInfo,
  VenueZoneConfigOverride,
  VenueZoneConfigType,
} from 'src/WebApiController';

import { useVenueSectionRow } from '../../Sections/VenueZoneMap/hooks/useVenueSectionRow';
import { EventVenueMapFormType } from '../EventVenueMapModal';
import * as styles from './EventVenueMapDetailsPanelFooter.css';

export const EventVenueMapDetailsPanelFooter = ({
  event,
  selectedOverrideId,
  setSelectedOverrideId,
  setSelectedConfigType,
  pricingSettingsQuery,
  venueZoneMapConfigInfoQuery,
  venueZoneMapConfigOverrideInfoQuery,
  setVenueZoneConfigTypeOverrideMutation,
}: {
  event: Event;
  selectedOverrideId: number | null;
  setSelectedOverrideId: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedConfigType: React.Dispatch<
    React.SetStateAction<VenueZoneConfigType | null>
  >;
  pricingSettingsQuery: UseQueryResult<AutoPricingInputs | null, Error>;
  venueZoneMapConfigInfoQuery: UseQueryResult<
    VenueMapZoneConfigInfo | null,
    Error
  >;
  venueZoneMapConfigOverrideInfoQuery: UseQueryResult<
    {
      [key: string]: VenueZoneConfigOverride;
    } | null,
    Error
  >;
  setVenueZoneConfigTypeOverrideMutation: UseMutationResult<
    void,
    ErrorTypes,
    {
      eventId?: number | null;
      venueZoneOverrideId?: number | null;
      venueZoneConfigType?: VenueZoneConfigType | null;
      setMapAsDefault?: boolean | null;
    },
    unknown
  >;
}) => {
  const { reset, setValue, getValues, formState } =
    useFormContext<EventVenueMapFormType>();

  const [isLoading, setIsLoading] = useState(false);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const { mapConfigOverridesQuery, hasScaledSeatScoreFeature, venueMapInfo } =
    useVenueSectionRow({
      event,
      pricingSettingsQuery,
    });

  const hasChanges = useCallback(
    (eventForm: EventVenueMapFormType) => {
      if (!formState.defaultValues?.sectionSectionGroupLookup) {
        return false;
      }

      return (
        !isEqual(
          formState.defaultValues?.sectionSectionGroupLookup,
          eventForm.sectionSectionGroupLookup
        ) ||
        formState.defaultValues?.name != eventForm.name ||
        formState.defaultValues?.setMapAsDefault != eventForm.setMapAsDefault
      );
    },
    [formState.defaultValues]
  );

  const hasVenueRowChanges = useCallback(
    (eventForm: EventVenueMapFormType) => {
      const defaultValues = formState.defaultValues?.eventScoreOverride;
      if (!defaultValues) {
        return false;
      }

      return !isEqual(defaultValues, eventForm.eventScoreOverride);
    },
    [formState.defaultValues]
  );

  const onSubmit = useCallback(
    async (eventForm: EventVenueMapFormType, saveAsNewMap: boolean) => {
      tryInvokeApi(
        async () => {
          const client = new PricingClient(activeAccountWebClientConfig);
          setIsLoading(true);

          if (event?.viagId && hasChanges(eventForm)) {
            const newId = await client.upsertVenueZoneSectionGroupOverrides({
              viagogoEventId: event.viagId,
              venueZoneOverrideId: selectedOverrideId,
              name: eventForm.name ?? 'Default',
              sectionSectionGroupsJson: JSON.stringify({
                sectionSectionGroupLookup: eventForm.sectionSectionGroupLookup,
              }),
              saveAsNewMap: saveAsNewMap,
            });

            await venueZoneMapConfigInfoQuery.refetch();
            await venueZoneMapConfigOverrideInfoQuery.refetch();
            await pricingSettingsQuery.refetch();

            setVenueZoneConfigTypeOverrideMutation.mutate({
              eventId: event!.viagId,
              venueZoneOverrideId: newId,
              venueZoneConfigType: null,
              setMapAsDefault: eventForm.setMapAsDefault,
            });

            setSelectedOverrideId(newId);
            setSelectedConfigType(null);
          }

          if (
            event?.viagId &&
            eventForm.eventScoreOverride &&
            hasVenueRowChanges(eventForm)
          ) {
            const newId = await client.upsertEventSeatMapScoreOverride({
              ...eventForm.eventScoreOverride,
              viagogoEventId: event.viagId,
              scoreOverrides: getCompleteEventConfigScoreOverrides(
                venueMapInfo?.sectionScores,
                eventForm.eventScoreOverride.scoreOverrides,
                false,
                hasScaledSeatScoreFeature
              )!,
            });

            setValue('eventScoreOverride.id', newId);

            await mapConfigOverridesQuery.refetch();

            await client.setEventSeatMapScoreOverride(
              event.viagId,
              newId,
              null
            );
          }
        },
        (error) => {
          showErrorDialog(
            'PricingClient.UpsertVenueZoneSectionGroupOverrides',
            error,
            {
              trackErrorData: eventForm,
            }
          );
        },
        async () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      event,
      hasChanges,
      hasScaledSeatScoreFeature,
      hasVenueRowChanges,
      mapConfigOverridesQuery,
      pricingSettingsQuery,
      selectedOverrideId,
      setSelectedConfigType,
      setSelectedOverrideId,
      setValue,
      setVenueZoneConfigTypeOverrideMutation,
      showErrorDialog,
      venueMapInfo?.sectionScores,
      venueZoneMapConfigInfoQuery,
      venueZoneMapConfigOverrideInfoQuery,
    ]
  );

  const onSubmitHandler = useCallback(
    (saveAsNewMap: boolean) => {
      if (!hasChanges(getValues())) {
        return;
      }

      onSubmit(getValues(), saveAsNewMap);
    },
    [getValues, hasChanges, onSubmit]
  );

  return (
    <div className={styles.container}>
      <Stack justifyContent="spaceBetween" alignItems="center">
        <Button
          variant="link"
          size="unset"
          onClick={() => {
            reset();
          }}
        >
          <div className={styles.resetText}>
            <Content id={ContentId.Reset} />{' '}
            <span className={styles.resetTextLower}>
              <Content id={ContentId.Change} />
            </span>
            s
          </div>
        </Button>

        {selectedOverrideId != null ? (
          <Stack gap="m" alignItems="center">
            <Button
              variant={!hasChanges(getValues()) ? 'outlineGray' : 'outline'}
              size="lg"
              disabled={isLoading || !hasChanges(getValues())}
              onClick={() => {
                onSubmitHandler(true);
              }}
            >
              <Content id={ContentId.SaveMapAs} />
            </Button>
            <Button
              variant="regular"
              size="lg"
              disabled={isLoading}
              onClick={() => {
                onSubmitHandler(false);
              }}
            >
              <Content id={ContentId.Save} />
            </Button>
          </Stack>
        ) : (
          <Button
            variant={!hasChanges(getValues()) ? 'outlineGray' : 'outline'}
            size="lg"
            disabled={isLoading || !hasChanges(getValues())}
            onClick={() => {
              onSubmitHandler(true);
            }}
          >
            <Content id={ContentId.SaveMapAs} />
          </Button>
        )}
      </Stack>
    </div>
  );
};
