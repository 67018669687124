import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function ClockTrashIcon(props: Omit<PosIconProps, 'viewBox'>) {
  const stroke = props.stroke || '#3F1D75';
  return (
    <SvgWrapper
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 15V21.2857C18 21.7404 17.8156 22.1764 17.4874 22.4979C17.1592 22.8194 16.7141 23 16.25 23H5.75C5.28587 23 4.84075 22.8194 4.51256 22.4979C4.18437 22.1764 4 21.7404 4 21.2857V11"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M3 9H10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M11 13V19"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7 13V19"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15 14V19"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <g clipPath="url(#clip0_205_12332)">
        <path
          d="M17.5 11.9166C20.4916 11.9166 22.9167 9.49152 22.9167 6.49998C22.9167 3.50844 20.4916 1.08331 17.5 1.08331C14.5085 1.08331 12.0834 3.50844 12.0834 6.49998C12.0834 9.49152 14.5085 11.9166 17.5 11.9166Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M17.5 3.25V6.5L19.6667 8.125"
          stroke={stroke}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_205_12332">
          <rect
            width="13"
            height="13"
            fill="yellow"
            transform="translate(11)"
          />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
}
