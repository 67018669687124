import { ComponentProps, useCallback, useContext } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { ManagedMarketplaceSale } from 'src/modals/ManagedMarketplaceSale';
import { MoneyIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  ListingDetailDataField,
  ListingDetails,
} from 'src/WebApiController';

export const LaunchCreateOfflineSale = ({
  listingId,
  marketplaceListingId,
  cancelTo,
  ...buttonProps
}: Omit<
  ComponentProps<typeof ButtonWithIcon>,
  'onClick' | 'textContentId' | 'icon' | 'iconProps'
> &
  Omit<ComponentProps<typeof ManagedMarketplaceSale>, 'event' | 'venue'> & {
    listingId: number;
    marketplaceListingId?: string | null;
  }) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const createOfflineSale = useCallback(() => {
    setModal({
      children: <ManagedMarketplaceSale cancelTo={cancelTo} />,
      clickOutsideToClose: true,
      size: 'slide-in',
      deepLinkValue: listingId,
      trackViewEntityType: ActionOutboxEntityType.Listing,
    });
  }, [setModal, cancelTo, listingId]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(listingId, marketplaceListingId, true, [
        ListingDetailDataField.Basic,
        ListingDetailDataField.Tickets,
      ]);
      createOfflineSale();
    },
    [createOfflineSale, listingId, marketplaceListingId, setActivePosEntity]
  );

  return (
    <ButtonWithIcon
      {...buttonProps}
      onClick={onLaunchDialog}
      textContentId={ContentId.AddOfflineSale}
      icon={<MoneyIcon withHoverEffect size={vars.iconSize.l} />}
    />
  );
};
