import { isEmpty } from 'lodash-es';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Stack } from 'src/core/ui';
import { FloorAdjustmentInput } from 'src/dialogs/BulkEdits/BulkEditAutoPricingSettingsDialog/components/PricingProtection/FloorAdjustmentInput';
import { ContentId } from 'src/utils/constants/contentId';
import { PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  getAllInPriceFromListPrice,
  getListPriceFromAllinPrice,
} from 'src/utils/inventoryUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  Listing,
  ListingDetailsAutoPricingSectionUpdatesV2,
  Marketplace,
  PricingFloorCeilingAdjustmentType,
} from 'src/WebApiController';

export const QuickFloorInput = ({
  listing,
  disabled,
  onEnterKeyPressed,
}: {
  listing: Listing;
  disabled?: boolean;
  onEnterKeyPressed?: () => void;
}) => {
  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();
  const stubHubMkpSettings =
    loginContext?.user?.activeAccount?.marketplaceSettings?.find(
      (m) => m.mkp === Marketplace.StubHub
    );

  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount?.currencyCode
  );

  const { setValue, watch } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();

  const netProceedsFloor = watch('netProceedsFloor');
  const netProceedsFloorAdjustmentType = watch(
    'netProceedsFloorAdjustmentType'
  );
  const netProceedsFloorAdjustmentAmount = watch(
    'netProceedsFloorAdjustmentAmount'
  );
  const netProceedsFloorAdjustmentRelative = watch(
    'netProceedsFloorAdjustmentRelative'
  );

  const onPriceFloorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = parseFloat(e.target.value);
      const newNetProceedFloor = getListPriceFromAllinPrice(
        v,
        listing,
        stubHubMkpSettings?.sellerFee
      );
      if (
        newNetProceedFloor &&
        newNetProceedFloor >= 0 &&
        newNetProceedFloor < (listing.procsCeil ?? Number.MAX_VALUE)
      ) {
        setValue('netProceedsFloor', posChangedField(newNetProceedFloor));
      } else {
        setValue('netProceedsFloor', null);
      }
    },
    [listing, setValue, stubHubMkpSettings?.sellerFee]
  );

  const onUpdateNetProceedsFloorAdjustment = useCallback(
    (input: {
      isRelative?: boolean;
      adjustmentAmount?: number | null;
      adjustmentType?: PricingFloorCeilingAdjustmentType;
    }) => {
      if (isEmpty(input)) {
        setValue('netProceedsFloorAdjustmentAmount', null);
        setValue('netProceedsFloorAdjustmentRelative', false);
        setValue('netProceedsFloorAdjustmentType', null);
      }
      const { isRelative, adjustmentAmount, adjustmentType } = input;
      if (isRelative !== undefined) {
        setValue('netProceedsFloorAdjustmentRelative', isRelative);
      }
      if (adjustmentAmount !== undefined) {
        setValue('netProceedsFloorAdjustmentAmount', adjustmentAmount);
      }
      if (adjustmentType !== undefined) {
        setValue('netProceedsFloorAdjustmentType', adjustmentType);
      }
    },
    [setValue]
  );

  const valueOptionsDisabled = useMemo(() => {
    const result: Record<string, ContentId> = {};

    if (listing.procsFloor == null) {
      result[PricingFloorCeilingAdjustmentType.CurrentFloor] =
        ContentId.ListingHasNoCurrentFloor;
    }
    if (listing.undNewPrice == null) {
      result[PricingFloorCeilingAdjustmentType.MarketPrice] =
        ContentId.ListingHasNoMarketprice;
    }
    if (listing.unitCst == null) {
      result[PricingFloorCeilingAdjustmentType.UnitCost] =
        ContentId.ListingHasNoUnitCost;
    }

    return result;
  }, [listing.procsFloor, listing.undNewPrice, listing.unitCst]);

  const inputboxRef = useRef<HTMLInputElement>(null);

  const onHandleEnterKey = useCallback(
    (e: KeyboardEvent) => {
      if (!disabled && onEnterKeyPressed && e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        onEnterKeyPressed();
      }
    },
    [disabled, onEnterKeyPressed]
  );

  useEffect(() => {
    const curRef = inputboxRef.current;
    if (onEnterKeyPressed && curRef) {
      inputboxRef.current.addEventListener('keydown', onHandleEnterKey);
    }

    return () => curRef?.removeEventListener('keydown', onHandleEnterKey);
  }, [onEnterKeyPressed, onHandleEnterKey]);

  return (
    <>
      {listing.isAutoPrc &&
      netProceedsFloor?.value == null && // only show relative-floor-input when no floor is set and is auto-pricing
      Object.entries(valueOptionsDisabled).length <
        Object.entries(PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_CID).length ? (
        <Stack gap="l" alignItems="center">
          <FloorAdjustmentInput
            ref={inputboxRef}
            disabled={disabled}
            amount={netProceedsFloorAdjustmentAmount}
            isRelative={netProceedsFloorAdjustmentRelative}
            onChange={(amount, isRelative) => {
              onUpdateNetProceedsFloorAdjustment({
                adjustmentAmount: amount,
                isRelative,
              });
            }}
          />

          <PosEnumSelect
            disabled={disabled}
            style={{ width: '100%', height: '36px' }}
            value={netProceedsFloorAdjustmentType}
            valueOptionsContent={PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_CID}
            valueOptionsDisabled={valueOptionsDisabled}
            onChange={(newValue) => {
              if (
                newValue != null &&
                newValue != netProceedsFloorAdjustmentType
              ) {
                onUpdateNetProceedsFloorAdjustment({
                  adjustmentType: newValue,
                });
              }
            }}
          />
        </Stack>
      ) : (
        // show the normal floor input if is not auto-pricing or has floor set
        <PosFormField style={{ width: 'fit-content' }}>
          <PosCurrencyField
            ref={inputboxRef}
            disabled={disabled}
            uiCurrency={uiCurrency}
            value={
              netProceedsFloor?.value
                ? getAllInPriceFromListPrice(
                    netProceedsFloor.value,
                    listing,
                    stubHubMkpSettings?.sellerFee
                  ) ?? ''
                : ''
            }
            onChange={onPriceFloorChange}
          />
        </PosFormField>
      )}
    </>
  );
};
