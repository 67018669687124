import { useGetMarketplacePaymentIdQueryParam } from 'src/components/MarketplacePayments/hooks/useGetMarketplacePaymentIdQueryParam';
import { Content } from 'src/contexts/ContentContext';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { EventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { MarketplacePaymentsDetailsModalBody } from 'src/modals/MaketplacePayments/MarketplacePaymentsDetailsModalBody';
import {
  ModalBody,
  ModalBodyDataContainer,
} from 'src/modals/Modal/Modal.styled';
import { ContentId } from 'src/utils/constants/contentId';

export const MarketplacePaymentsDetailsModal = () => {
  const marketplacePaymentId = useGetMarketplacePaymentIdQueryParam();
  return (
    <ModalBody>
      <CancellableFormHeader>
        <EventEntityHeader title={<Content id={ContentId.PaymentDetails} />} />
      </CancellableFormHeader>
      <ModalBodyDataContainer>
        <MarketplacePaymentsDetailsModalBody
          marketplacePaymentId={marketplacePaymentId}
        />
      </ModalBodyDataContainer>
    </ModalBody>
  );
};
