import { Updater } from '@tanstack/react-table';
import { debounce, once } from 'lodash-es';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { getColumnConfigById } from 'src/utils/columns/columnUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { Feature, UserSetting } from 'src/WebApiController';

import { useServerUserSetting } from '../../hooks/useUserSetting';
import {
  ColumnResizingContextType,
  CustomColumnWidthsType,
  TableColumnIdType,
} from './ColumnResizingContext.types';

export const createColumnResizingContext = once(<
  T extends TableColumnIdType,
>() => React.createContext({} as ColumnResizingContextType<T>));

export function useColumnResizingContext<T extends TableColumnIdType>() {
  return useContext(createColumnResizingContext<T>());
}

const USER_SETTING_TO_SECTION_TYPE = {
  [UserSetting.InventoryColumnWidths]: SectionType.Listings,
  [UserSetting.SaleColumnWidths]: SectionType.Sales,
  [UserSetting.PurchaseColumnWidths]: SectionType.Purchases,
  [UserSetting.PurchaseEventColumnWidths]: SectionType.PurchaseEvent,
  [UserSetting.InventoryReportColumnWidths]: SectionType.ListingsReport,
  [UserSetting.SaleReportColumnWidths]: SectionType.SalesReport,
  [UserSetting.EventsColumnWidths]: SectionType.Events,
  [UserSetting.MarketplacePaymentsColumnsWidth]:
    SectionType.MarketplacePaymentsTable,
};

export const ColumnResizingContextProvider = <T extends TableColumnIdType>({
  userSettingId,
  children,
}: {
  userSettingId:
    | UserSetting.InventoryColumnWidths
    | UserSetting.SaleColumnWidths
    | UserSetting.PurchaseColumnWidths
    | UserSetting.PurchaseEventColumnWidths
    | UserSetting.InventoryReportColumnWidths
    | UserSetting.SaleReportColumnWidths
    | UserSetting.EventsColumnWidths
    | UserSetting.MarketplacePaymentsColumnsWidth;
  children: ReactNode;
}) => {
  const ColumnResizingContext = createColumnResizingContext<T>();
  const sectionType = USER_SETTING_TO_SECTION_TYPE[userSettingId];

  const hasReportColumnResizingFeature = useUserHasFeature(
    Feature.ReportColumnResizing
  );

  const isInitializedRef = useRef(false);

  const {
    value: serverCustomColumnWidths,
    setUserSetting: setServerCustomColumnWidths,
  } = useServerUserSetting<CustomColumnWidthsType<T>>({
    id: userSettingId,
  });

  const [customColumnWidths, setCustomColumnWidths] = useState<
    CustomColumnWidthsType<T>
  >({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetServerColumnWidths = useCallback(
    debounce(
      (newWidth: Partial<Record<T, number>>) =>
        setServerCustomColumnWidths(newWidth),
      1000
    ),
    [setServerCustomColumnWidths]
  );

  const onColumnSizingChange = (
    updaterOrValue: Updater<CustomColumnWidthsType<T>>
  ) => {
    if (!(updaterOrValue instanceof Function)) return;

    const state = updaterOrValue({});
    if (!Object.keys(state).length) return;

    const [key, width] = Object.entries<number>(
      state as Record<string, number>
    )[0] as [T, number];
    const definition = getColumnConfigById({
      id: key,
      sectionType,
    }).definition;
    const minSize = definition?.minSize ?? 0;
    const maxSize = definition?.maxSize;
    const currentSize = customColumnWidths[key] ?? 0;
    let updatedSize = Math.max(width, minSize);
    if (maxSize != null) {
      updatedSize = Math.min(updatedSize, maxSize);
    }

    if (updatedSize === currentSize) return;

    const newWidths = {
      ...customColumnWidths,
      [key]: updatedSize,
    };

    setCustomColumnWidths(newWidths);
    debouncedSetServerColumnWidths(newWidths);
  };

  useEffect(() => {
    if (isInitializedRef.current || !serverCustomColumnWidths) return;
    isInitializedRef.current = true;

    const serverCustomColumnWidthsWithoutZeroValues = Object.entries(
      serverCustomColumnWidths
    ).reduce(
      (acc, [key, value]) => {
        if (!value) return acc;
        return { ...acc, [key]: value };
      },
      {} as Partial<Record<T, number>>
    );
    setCustomColumnWidths(serverCustomColumnWidthsWithoutZeroValues);
  }, [serverCustomColumnWidths]);

  return (
    <ColumnResizingContext.Provider
      value={{
        isResizeEnabled:
          userSettingId == UserSetting.SaleReportColumnWidths ||
          userSettingId == UserSetting.InventoryReportColumnWidths
            ? hasReportColumnResizingFeature
            : true,
        customColumnWidths,
        onColumnSizingChange,
      }}
    >
      {children}
    </ColumnResizingContext.Provider>
  );
};
