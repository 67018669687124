import {
  functionalUpdate,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useGetDisplayMarketplacePaymentsTableColumns } from 'src/tables/MarketplacePaymentsTable/useGetDisplayMarketplacePaymentsTableColumns';
import { Table } from 'src/tables/Table';
import { MarketplacePaymentsTableColumnId } from 'src/utils/columns/marketplacePayments/marketplacePaymentsColumnsUtils.types';
import {
  Feature,
  MarketplaceSalePayment,
  UserSetting,
} from 'src/WebApiController';

const getRowId = (row: MarketplaceSalePayment | null, index: number) =>
  (row?.id ?? index).toString();

interface PaymentsTableProps {
  tableData: MarketplaceSalePayment[] | null;
}

export const MarketplacePaymentsTable = ({ tableData }: PaymentsTableProps) => {
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );
  const paymentsTableColumnConfig =
    useGetDisplayMarketplacePaymentsTableColumns();
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: MarketplacePaymentsTableColumnId.PaymentDate,
      desc: true,
    },
  ]);

  const options: Partial<TableOptions<MarketplaceSalePayment | null>> = useMemo(
    () => ({
      data: tableData ?? [],
      columns: Object.values(paymentsTableColumnConfig),
      state: {
        sorting,
        columnPinning: {
          right: hasTablePinActionColumnFeature
            ? [MarketplacePaymentsTableColumnId.Actions]
            : undefined,
        },
      },
      getRowId: getRowId,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
      },
      sortingFns: {},
    }),
    [
      tableData,
      paymentsTableColumnConfig,
      sorting,
      hasTablePinActionColumnFeature,
    ]
  );

  return (
    <ColumnResizingContextProvider<MarketplacePaymentsTableColumnId>
      userSettingId={UserSetting.MarketplacePaymentsColumnsWidth}
    >
      <Table
        options={options}
        tableLayout="fixed"
        withOuterPadding={true}
        useVirtuoso={false}
        usePaginationFooter={false}
      />
    </ColumnResizingContextProvider>
  );
};
