import { format } from 'date-fns';
import { useMemo } from 'react';
import { useSingleMonthMarketplacePaymentsQuery } from 'src/contexts/MarketplacePaymentDataContext';
import { MarketplacePaymentsTable } from 'src/tables/MarketplacePaymentsTable/MarketplacePaymentsTable';

interface GroupedPaymentsAccordionBodyProps {
  firstOfMonth: string;
}

export const GroupedPaymentsAccordionBody = ({
  firstOfMonth,
}: GroupedPaymentsAccordionBodyProps) => {
  const queryDate = useMemo(
    () => format(new Date(firstOfMonth), 'yyyy-MM-dd'),
    [firstOfMonth]
  );

  const { singleMonthPaymentQuery } = useSingleMonthMarketplacePaymentsQuery(
    queryDate,
    true
  );

  return (
    <MarketplacePaymentsTable
      tableData={singleMonthPaymentQuery.data ?? null}
    />
  );
};
