import { useCallback, useMemo } from 'react';
import { SectionInfo, SelectedSectionSettings } from 'src/WebApiController';

import { useAutoPricingSettingsFormContext } from '../useAutoPricingSettingsFormContext';

export const useAutoPricingSettingsSelectedSections = (
  venueMapSections: SectionInfo[] | null | undefined
) => {
  const { setValue, watch } = useAutoPricingSettingsFormContext();

  const { compListingSelectedSectionSettings } = watch();

  const selectedSections = useMemo(() => {
    const formSectionFilterIds =
      compListingSelectedSectionSettings?.sectionIdFilter ?? [];
    return (
      venueMapSections?.filter((section) => {
        return formSectionFilterIds.includes(section.id);
      }) ?? []
    );
  }, [compListingSelectedSectionSettings?.sectionIdFilter, venueMapSections]);

  const rowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.rowIdFilter ?? [];
  }, [compListingSelectedSectionSettings]);

  const sectionRowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};
  }, [compListingSelectedSectionSettings]);

  const onRowIdFiltersChange = useCallback(
    (rowIdFilters: number[], sectionRowIdFilters: { [x: string]: string }) => {
      const compListingSelectedSectionSettingsNew = {
        sectionIdFilter:
          compListingSelectedSectionSettings?.sectionIdFilter ?? [],
        rowIdFilter: Array.from(new Set(rowIdFilters)),
        sectionRowIdFilter: sectionRowIdFilters,
      };

      setValue(
        'compListingSelectedSectionSettings',
        compListingSelectedSectionSettingsNew
      );
      setValue('compListingOnlyForSelectedSectionsEnabled', true);
    },
    [compListingSelectedSectionSettings?.sectionIdFilter, setValue]
  );

  const onSectionIdFiltersChange = useCallback(
    (
      sectionId: number,
      selectedSections: SectionInfo[],
      section?: SectionInfo
    ) => {
      let newSectionIdFilter =
        compListingSelectedSectionSettings?.sectionIdFilter ?? [];

      let newRowIdFilter =
        compListingSelectedSectionSettings?.rowIdFilter ?? [];

      let newSectionRowIdFilter =
        compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};

      if (newSectionIdFilter.includes(sectionId)) {
        newSectionIdFilter = newSectionIdFilter.filter(
          (id) => id !== sectionId
        );

        // Remove all the associated row ids for this section
        const section = selectedSections.find((s) => s.id === sectionId);
        if (section) {
          const rowIds = section.rows.map((r) => r.id);
          newRowIdFilter = newRowIdFilter.filter((id) => !rowIds.includes(id));
        }

        // Remove the sectionRow mapping so we don't display it in pills
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [sectionId]: omitted, ...updatedSectionRowIdFilter } =
          compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};

        newSectionRowIdFilter = updatedSectionRowIdFilter;
      } else {
        newSectionIdFilter.push(sectionId);

        // If a section is present, add all the rows associated with the newly selected section
        if (section) {
          const newRowIds = section.rows.map((r) => r.id);
          newRowIds.forEach((newRowId) => {
            if (!newRowIdFilter.includes(newRowId)) {
              newRowIdFilter.push(newRowId);
            }
          });
        }
      }

      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter: newSectionIdFilter,
        rowIdFilter: newRowIdFilter,
        sectionRowIdFilter: newSectionRowIdFilter,
      };

      setValue(
        'compListingSelectedSectionSettings',
        compListingSelectedSectionSettingsNew
      );
    },
    [compListingSelectedSectionSettings, setValue]
  );

  const setCompListingSelectedSectionSettings = useCallback(
    (compListingSelectedSectionSettingsNew: SelectedSectionSettings | null) => {
      setValue(
        'compListingSelectedSectionSettings',
        compListingSelectedSectionSettingsNew
      );
    },
    [setValue]
  );

  return {
    selectedSections,
    rowIdFilters,
    sectionRowIdFilters,
    onRowIdFiltersChange,
    onSectionIdFiltersChange,
    compListingSelectedSectionSettings,
    setCompListingSelectedSectionSettings,
  };
};
