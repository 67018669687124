import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack, Switch } from 'src/core/ui';
import { AddSectionGroupDialog } from 'src/dialogs/AddSectionGroupDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionSectionGroup } from 'src/WebApiController';

import { getRandomColor } from '../../utils/colorUtils';
import { EventVenueMapFormType } from '../EventVenueMapModal';
import * as styles from './SectionGroupPanel.css';
import { VenueZoneSectionGroupDraggableList } from './VenueZoneSectionGroupDraggableList';
import { VenueZoneSectionGroupList } from './VenueZoneSectionGroupList';

export const SectionGroupPanel = () => {
  const { watch, setValue, reset } = useFormContext<EventVenueMapFormType>();

  const [useCustomRank, setUseCustomRank] = useState(false);

  const addSectionGroupDialog = useBasicDialog();

  const customSectionGroups = watch('customSectionGroups');
  const name = watch('name');

  const onAddSectionGroup = useCallback(
    (ticketClassName: string, sectionGroupName: string) => {
      const newCustomSectionGroup = {
        sectId: 0,
        areaRank: 1,
        sectionGroupRank: 1,
        ticketClassName: ticketClassName,
        sectionGroupAreaName: sectionGroupName,
        sectionGroupAreaType: null,
        ticketClassId: null,
        sectionGroupColor: getRandomColor(customSectionGroups.length),
      } as SectionSectionGroup;

      const newCustomSectionGroups = [
        ...(customSectionGroups || []),
        newCustomSectionGroup,
      ];
      setValue('customSectionGroups', newCustomSectionGroups);

      addSectionGroupDialog.closeDialog();
    },
    [addSectionGroupDialog, customSectionGroups, setValue]
  );

  return (
    <div className={styles.gridPanelContainer}>
      <Stack alignItems="center" justifyContent="spaceBetween">
        <span className={styles.groupHeaderText}>
          <Content id={ContentId.MapTitle} />
        </span>
        <div className={styles.textInput}>
          <PosTextField
            type={'text'}
            maxLength={200}
            onChange={(e) => {
              setValue('name', e.target.value);
            }}
            value={name}
          />
        </div>
      </Stack>
      <Stack alignItems="center" justifyContent="spaceBetween">
        <span className={styles.groupHeaderText}>
          <Content id={ContentId.SectionGroups} />
        </span>
        <Stack alignItems="center" gap="m">
          <span>
            <Content id={ContentId.CustomRanks} />
          </span>
          <Switch
            checked={useCustomRank}
            onChange={(e) => e.stopPropagation()}
            onCheckedChange={(isChecked) => {
              if (!isChecked) {
                reset();
              }

              setUseCustomRank(isChecked);
            }}
          />
        </Stack>
      </Stack>
      {useCustomRank ? (
        <VenueZoneSectionGroupDraggableList />
      ) : (
        <VenueZoneSectionGroupList />
      )}

      <div>
        <Button variant="text" onClick={addSectionGroupDialog.launchDialog}>
          + <Content id={ContentId.AddNewSection} />
        </Button>
      </div>

      <AddSectionGroupDialog
        {...addSectionGroupDialog.dialogProps}
        onOkay={onAddSectionGroup}
        onCancel={addSectionGroupDialog.closeDialog}
      />
    </div>
  );
};
