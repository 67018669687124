import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import * as styles from './DividerLine.css';

export const DividerLine = ({
  children,
  darkBackground,
}: PropsWithChildren<{ darkBackground?: boolean }>) => {
  return (
    <div className={styles.dividerLine}>
      <span
        className={clsx(styles.dividerLineBar, {
          [styles.dividerLineBarDark]: darkBackground,
        })}
      />
      <div
        className={clsx(styles.contentContainer, {
          [styles.contentContainerDark]: darkBackground,
        })}
      >
        {children}
      </div>
      <span
        className={clsx(styles.dividerLineBar, {
          [styles.dividerLineBarDark]: darkBackground,
        })}
      />
    </div>
  );
};
