import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { Content, useFormattedContent } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack, Tooltip } from 'src/core/ui';
import { InfoOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import { RowRange } from '../../GroupListingsV2.types';
import * as styles from './GroupingMethods.css';

type RowRangesPrefixType = {
  rowRanges: RowRange[];
  editing: boolean;
};

const RowRangesPrefix: React.FC<RowRangesPrefixType> = ({
  rowRanges,
  editing,
}) => {
  const getLabelFromRowRange = useCallback(({ min, max }: RowRange) => {
    if (min === max) {
      return min;
    }
    if (max === Number.MAX_VALUE) {
      return `${min}+`;
    }
    return `${min}-${max}`;
  }, []);

  const lastRange = useMemo(() => {
    if (rowRanges.length > 0) {
      return rowRanges[rowRanges.length - 1];
    }
    return null;
  }, [rowRanges]);

  return (
    <Stack gap="s" alignItems="center">
      {rowRanges.map((range, index) => {
        const lastEditing = index === rowRanges.length - 1 && editing;
        if (lastEditing) {
          return undefined;
        }
        return (
          <label
            key={range.min}
            className={clsx(styles.inputLabel, {
              [styles.activeInputLabel]: lastEditing,
            })}
          >
            {getLabelFromRowRange(range)}
          </label>
        );
      })}
      {editing && lastRange && (
        <div className={styles.editingRangePrefix}>{`${lastRange?.min}-`}</div>
      )}
    </Stack>
  );
};

type InputRowRangesProps = {
  rowRanges: RowRange[];
  onRowRangesChange: (rowRanges: RowRange[]) => void;
};

export const InputRowRanges: React.FC<InputRowRangesProps> = ({
  rowRanges,
  onRowRangesChange,
}) => {
  const [rowInput, setRowInput] = useState<number>();
  const [editing, setEditing] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string>();

  const lastRowRange = useMemo(() => {
    if (rowRanges.length > 0) {
      return rowRanges[rowRanges.length - 1];
    }
    return undefined;
  }, [rowRanges]);

  const rowRangeInputErrorMsg = useFormattedContent(
    FormatContentId.RowRangeInputError,
    lastRowRange?.min + ''
  );

  const onLeaveInput = useCallback(() => {
    setEditing(false);
    setRowInput(undefined);
    setInputError(undefined);
  }, []);

  const onCutomRowRangeKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const sorted = rowRanges.sort((a, b) => a.min - b.min);

      // In the case when no row ranges, add 1+
      if (sorted.length === 0) {
        onRowRangesChange([{ min: 1, max: Number.MAX_VALUE }]);
        setRowInput(undefined);
        return;
      }

      // + is considered an terminating input to mark the last row range +, i.e 6+.
      if (event.key === '+') {
        onLeaveInput();
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
        return;
      }

      if (event.key === 'Enter') {
        const lastRange = sorted[sorted.length - 1];

        //Enter with no input => apply max with infinite value via blur the input
        if (!rowInput) {
          lastRange.max = lastRange.min;
          sorted.pop();
          const updated = [
            ...sorted,
            lastRange,
            { min: lastRange.min + 1, max: Number.MAX_VALUE },
          ];

          onRowRangesChange(updated);
          setRowInput(undefined);
          return;
        }
        // Invalid input
        if (rowInput < lastRange.min) {
          setInputError(rowRangeInputErrorMsg as string);
          return;
        }
        // Only take the enter when the input value is gte than the leading min
        lastRange.max = rowInput;
        sorted.pop();
        const updated = [
          ...sorted,
          lastRange,
          { min: rowInput + 1, max: Number.MAX_VALUE },
        ];
        onRowRangesChange(updated);
        setRowInput(undefined);
      }

      // Backspace to clear the last range if no row input
      if (
        event.key === 'Backspace' &&
        rowInput === undefined &&
        sorted.length > 1
      ) {
        sorted.pop();
        const lastRange = sorted[sorted.length - 1];
        lastRange.max = Number.MAX_VALUE;
        onRowRangesChange(sorted);
        setRowInput(sorted[sorted.length - 1].min);
      }
    },
    [
      onLeaveInput,
      onRowRangesChange,
      rowInput,
      rowRangeInputErrorMsg,
      rowRanges,
    ]
  );

  const activePlaceholder = useMemo(() => {
    if (!editing) return undefined;
    if (!lastRowRange) return undefined;
    const suggestingNum = lastRowRange.min + 1;
    return suggestingNum + '';
  }, [editing, lastRowRange]);

  return (
    <PosFormField errors={inputError}>
      <PosTextField
        type="number"
        prefixDisplay={
          <RowRangesPrefix rowRanges={rowRanges} editing={editing} />
        }
        value={rowInput ?? ''}
        onChange={(event) => {
          const value = parseInt(event.target.value);
          if (value > 0) {
            setRowInput(value);
          } else {
            setRowInput(undefined);
          }
          setInputError(undefined);
        }}
        onKeyDown={onCutomRowRangeKeyDown}
        onFocus={() => setEditing(true)}
        onBlur={onLeaveInput}
        placeholder={activePlaceholder}
        postfixDisplay={
          <Tooltip
            delayDuration={100}
            contentVariant="dark"
            triggerContent={
              <div style={{ display: 'flex' }}>
                <InfoOutlineIcon size={vars.iconSize.m} />
              </div>
            }
          >
            <Content id={ContentId.RowRangesInputTip} /> &crarr;
          </Tooltip>
        }
      />
    </PosFormField>
  );
};
