import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import {
  AutoPricingSettingsFormContent,
  AutoPricingSettingsFormContentCoreProps,
  PricingSetting,
} from 'src/components/AutoPricing/AutoPricingSettingsFormContent/AutoPricingSettingsFormContent';
import { useAutoPricingSettingsFormContext } from 'src/components/AutoPricing/useAutoPricingSettingsFormContext';
import { SeatScoreQuantityMultiplerGraph } from 'src/components/Events/RelativeScoreGraph/SeatScoreQuantityMultiplierGraph';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Switch } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EventPricingSeatMapForm } from 'src/utils/eventWithDataUtils';
import {
  AutoPricingCompListingMode,
  AutoPricingInputs,
} from 'src/WebApiController';

type EventAutoPricingSettingsFormContentProps =
  AutoPricingSettingsFormContentCoreProps & {
    allowAutoPriceFrequency: boolean;
  };

export const EventAutoPricingSettingsFormContent = ({
  allowAutoPriceFrequency,
  disabled,
  ...rest
}: EventAutoPricingSettingsFormContentProps) => {
  const { watch: outerWatch, setValue: outerSetValue } =
    useFormContext<EventPricingSeatMapForm>();

  const { eventAutoPricing, eventWideAutoPricing } = outerWatch();

  const innerMethods = useForm<AutoPricingInputs>({
    defaultValues: eventAutoPricing ?? undefined,
  });

  const { watch: innerWatch } = innerMethods;

  const { compListingMode } = innerWatch();

  innerWatch((eventAutoPricingNew) => {
    outerSetValue('eventAutoPricing', eventAutoPricingNew as AutoPricingInputs);
    return;
  });

  return (
    <FormProvider {...innerMethods}>
      <>
        <AutoPricingSettingsFormContent
          isGlobalEdit={false}
          disabled={disabled}
          autoPricingSectionChildren={
            <PricingSetting
              header={<Content id={ContentId.AutoPricingAllListingsForEvent} />}
              detail={
                <Switch
                  disabled={disabled}
                  checked={!!eventWideAutoPricing}
                  onClick={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    if (isChecked !== eventWideAutoPricing) {
                      outerSetValue('eventWideAutoPricing', isChecked);
                    }
                  }}
                />
              }
            />
          }
          priceAgainstSectionChildren={
            allowAutoPriceFrequency && (
              <AutoPricingFrequencyInput disabled={disabled} />
            )
          }
          advancedSettingsSectionChildren={
            compListingMode == AutoPricingCompListingMode.QualityScore ? (
              <AdvancedSettingsSection disabled={disabled} />
            ) : null
          }
          hideCompListingQuantityFilters={true}
          hideBaseAdvancedSettingElements={true}
          {...rest}
        />
      </>
    </FormProvider>
  );
};

const AutoPricingFrequencyInput = ({ disabled }: { disabled: boolean }) => {
  const { setValue, watch } = useAutoPricingSettingsFormContext();

  const { autoPriceFrequency } = watch();

  return (
    <PricingSetting
      header={<Content id={ContentId.UpdatePriceEvery} />}
      detail={
        <PosTextField
          rootProps={{
            style: { maxWidth: '150px' },
          }}
          type="number"
          postfixDisplay={
            <FormatContent id={FormatContentId.NMinutes} params={''} />
          }
          value={autoPriceFrequency ?? ''}
          onChange={(e) => {
            let v = parseInt(e.target.value);
            if (!isNaN(v)) {
              if (v < 1) {
                v = 1;
              }
              setValue('autoPriceFrequency', v);
              return;
            } else {
              setValue('autoPriceFrequency', null);
            }
          }}
          disabled={disabled}
        />
      }
    />
  );
};

const AdvancedSettingsSection = ({ disabled }: { disabled: boolean }) => {
  const { setValue, watch } = useAutoPricingSettingsFormContext();

  const {
    compListingMode,
    compListingQuantityScoreAdjustmentEnabled,
    compListingQuantityScoreAdjustmentOverrideJson,
  } = watch();

  return (
    <>
      <PricingSetting
        header={<Content id={ContentId.CompListingQuantityScoreAdjustment} />}
        detail={
          <Switch
            disabled={disabled}
            checked={compListingQuantityScoreAdjustmentEnabled ?? false}
            onClick={(e) => e.stopPropagation()}
            onCheckedChange={(isChecked) => {
              const compListingQuantityScoreAdjustmentEnabledNew = isChecked;
              if (
                compListingQuantityScoreAdjustmentEnabledNew !==
                compListingQuantityScoreAdjustmentEnabled
              ) {
                setValue(
                  'compListingQuantityScoreAdjustmentEnabled',
                  compListingQuantityScoreAdjustmentEnabledNew
                );
              }
            }}
          />
        }
      />

      <PricingSetting
        header={<Content id={ContentId.QuantityAvailablePremiums} />}
        detail={
          <SeatScoreQuantityMultiplerGraph
            initialSegments={
              compListingQuantityScoreAdjustmentOverrideJson
                ? JSON.parse(compListingQuantityScoreAdjustmentOverrideJson)
                    .segments
                : undefined
            }
            onChange={({ overrides, segments }) => {
              const compListingQuantityScoreAdjustmentOverrideJsonNew =
                JSON.stringify({ overrides, segments });
              setValue(
                'compListingQuantityScoreAdjustmentOverrideJson',
                compListingQuantityScoreAdjustmentOverrideJsonNew
              );
            }}
          />
        }
      />
    </>
  );
};
