import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from '../../GroupListingsV2.css';
import {
  GroupListingsFormProps,
  RankPremiumType,
} from '../../GroupListingsV2.types';

type RankPremiumProps = {};

export const RankPremium: React.FC<RankPremiumProps> = () => {
  const [rankPremiumType, setRankPremiumType] = useState<RankPremiumType>(
    RankPremiumType.Absolute
  );
  const { getUiCurrency } = useLocalizationContext();
  const currency = getUiCurrency('USD');

  const { watch, setValue, register } =
    useFormContext<GroupListingsFormProps>();
  const undercutPremium = watch('groupUndercutSettings');

  const onRankPremiumTypeChange = useCallback(
    (premiumType: RankPremiumType) => {
      if (premiumType === RankPremiumType.Absolute) {
        setValue('groupUndercutSettings.undRelAmt', null);
      } else {
        setValue('groupUndercutSettings.undAbsAmt', null);
      }
      setRankPremiumType(premiumType);
    },
    [setValue]
  );

  return (
    <RadioGroup value={rankPremiumType} onValueChange={onRankPremiumTypeChange}>
      <Stack direction="column" gap="m" width="full">
        <Stack
          direction="row"
          width="full"
          justifyContent="spaceBetween"
          alignItems="center"
        >
          <Radio
            value={RankPremiumType.Absolute}
            label={<Content id={ContentId.AbsoluteDollarValue} />}
          />
          <PosCurrencyField
            value={undercutPremium?.undAbsAmt ?? undefined}
            {...register(`groupUndercutSettings.undAbsAmt`, {
              valueAsNumber: true,
              setValueAs: (v) => (v ? parseFloat(v) : null),
            })}
            disabled={rankPremiumType === RankPremiumType.Relative}
            uiCurrency={currency}
            rootProps={{ className: styles.inputStyle }}
          />
        </Stack>
        <Stack
          direction="row"
          width="full"
          justifyContent="spaceBetween"
          alignItems="center"
        >
          <Radio
            value={RankPremiumType.Relative}
            label={<Content id={ContentId.Percentage} />}
          />
          <PosTextField
            type="number"
            postfixDisplay="%"
            disabled={rankPremiumType === RankPremiumType.Absolute}
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              let relativeValue = null;
              if (v >= 0 && v <= Number.MAX_VALUE) {
                relativeValue = Math.min(v, 100) / 100;
              }
              setValue(`groupUndercutSettings.undRelAmt`, relativeValue);
            }}
            value={
              undercutPremium?.undRelAmt
                ? undercutPremium?.undRelAmt * 100
                : undefined
            }
            rootProps={{ className: styles.inputStyle }}
          />
        </Stack>
      </Stack>
    </RadioGroup>
  );
};
