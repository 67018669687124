import { useSortable } from '@dnd-kit/sortable';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { BroadcastIcon } from 'src/svgs/BroadcastIcon';
import { FlattenedViewIcon } from 'src/svgs/FlattenedViewIcon';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { TableShimmeringDiv } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import { DeliveryType } from 'src/WebApiController';

import { RankPremiumType } from '../../GroupListingsV2.types';
import { GroupListingTableItem } from './GroupListingsPreviewTable';

export const UngroupedListingGroupId = 'UngroupedListingGroup';

const DeliveryTypeCell = ({ deliveryType }: { deliveryType: DeliveryType }) => {
  const icon = getDeliveryTypeIcon(deliveryType, null, IconsFill.textStrong);
  return icon;
};

// Cell Component
const RowDragHandleCell = ({ rowId }: { rowId: number }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <FlattenedViewIcon
      {...attributes}
      {...listeners}
      fill={IconsFill.currentColor}
    />
  );
};

export const usePreviewTableColumns = (
  desiredActiveListings: number | null,
  isUngroupedGroup: boolean,
  removeListing: (listingId: number) => void,
  onMoveListing: (listingId: number) => void
) => {
  const columns = useMemo((): ColumnDef<GroupListingTableItem>[] => {
    const tableColumns: ColumnDef<GroupListingTableItem>[] = [
      {
        id: 'id',
        header: () => undefined,
        size: 40,
        cell: ({ row: { original } }) => {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          return <RowDragHandleCell rowId={original.id} />;
        },
        enableSorting: false,
      },
      {
        id: 'priority',
        header: () => (
          <TableHeaderCellDiv align="center" showTooltip={false}>
            #
          </TableHeaderCellDiv>
        ),
        size: 40,
        cell: ({ row: { original, depth } }) => {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          const { priority } = original;

          if (depth > 0) return null;

          if (desiredActiveListings && priority <= desiredActiveListings) {
            return (
              <TableCellDiv align="center" showTooltip={false}>
                <BroadcastIcon size={vars.iconSize.m} />
              </TableCellDiv>
            );
          }

          return (
            <TableCellDiv align="center" showTooltip={false}>
              {priority}
            </TableCellDiv>
          );
        },
        enableSorting: false,
      },
      {
        id: 'seating.section',
        header: () => (
          <TableHeaderCellDiv showTooltip={false}>
            <Content id={ContentId.Section} />
          </TableHeaderCellDiv>
        ),
        minSize: 50,
        maxSize: 100,
        accessorFn: (data) => data?.seating.section,
        cell: ({ row: { original } }) => {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          return (
            <TableCellDiv showTooltip={false}>
              {original.seating.section}
            </TableCellDiv>
          );
        },
        enableSorting: false,
      },
      {
        id: 'seating.row',
        header: () => (
          <TableHeaderCellDiv>
            <Content id={ContentId.Row} />
          </TableHeaderCellDiv>
        ),
        minSize: 50,
        maxSize: 70,
        accessorFn: (data) => data?.seating.row ?? '',
        cell: ({ row: { original } }) => {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          return (
            <TableCellDiv showTooltip={false}>
              {original.seating.row}
            </TableCellDiv>
          );
        },
        enableSorting: false,
      },
      {
        id: 'seating.seatFr',
        header: () => (
          <TableHeaderCellDiv showTooltip={false}>
            <Content id={ContentId.Seats} />
          </TableHeaderCellDiv>
        ),
        minSize: 50,
        maxSize: 100,
        cell: ({ row: { original } }) => {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          let text = original.seating.seatFr;
          if (
            original.seating.seatTo &&
            original.seating.seatTo !== original.seating.seatFr
          ) {
            text += ` - ${original.seating.seatTo}`;
          }

          return <TableCellDiv showTooltip={false}>{text}</TableCellDiv>;
        },
        enableSorting: false,
      },
      {
        id: 'undercutSetting',
        header: () => (
          <TableHeaderCellDiv showTooltip={false}>
            <Content id={ContentId.RankPremium} />
          </TableHeaderCellDiv>
        ),
        minSize: 50,
        maxSize: 100,
        cell: ({ row: { original } }) => {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          const undercutSettings = original.undercutSetting;

          const rankPremium =
            undercutSettings?.undAbsAmt != null
              ? RankPremiumType.Absolute
              : undercutSettings?.undRelAmt != null
              ? RankPremiumType.Relative
              : undefined;

          const premiumValue =
            undercutSettings?.undAbsAmt != null
              ? undercutSettings?.undAbsAmt
              : undercutSettings?.undRelAmt != null
              ? undercutSettings?.undRelAmt * 100
              : undefined;

          const display =
            premiumValue == null
              ? undefined
              : rankPremium === RankPremiumType.Absolute
              ? `$${premiumValue}`
              : rankPremium === RankPremiumType.Relative
              ? `${premiumValue}%`
              : premiumValue;

          return <TableCellDiv showTooltip={false}>{display}</TableCellDiv>;
        },
        enableSorting: false,
      },
      {
        id: 'delivType',
        header: () => (
          <TableHeaderCellDiv align="center" showTooltip={false}>
            <Content id={ContentId.TicketType} />
          </TableHeaderCellDiv>
        ),
        minSize: 80,
        maxSize: 100,
        cell: ({ row: { original } }) => {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          return (
            <TableCellDiv align="center" showTooltip={false}>
              <DeliveryTypeCell deliveryType={original.delivType} />
            </TableCellDiv>
          );
        },
        enableSorting: false,
      },
      {
        id: 'availQty',
        header: () => (
          <TableHeaderCellDiv showTooltip={false}>
            <Content id={ContentId.Sold} />/<Content id={ContentId.Unsold} />
          </TableHeaderCellDiv>
        ),
        minSize: 70,
        maxSize: 70,
        accessorFn: (data) => data?.availQty,
        cell: function Cell({ row: { original } }) {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          const available = original.availQty ?? 0;
          const sold = original.ticketCnt - available;

          return (
            <TableCellDiv
              showTooltip={false}
            >{`${sold}/${available}`}</TableCellDiv>
          );
        },
        enableSorting: false,
      },
      {
        id: 'unitCst',
        header: () => (
          <TableHeaderCellDiv align="right" showTooltip={false}>
            <Content id={ContentId.UnitCost} />
          </TableHeaderCellDiv>
        ),
        minSize: 70,
        maxSize: 100,
        cell: function Cell({ row: { original } }) {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          return (
            <TableCellDiv align="right" showTooltip={false}>
              {original.unitCst?.disp ?? original?.faceValue?.disp ?? (
                <Content id={ContentId.NA} />
              )}
            </TableCellDiv>
          );
        },
        enableSorting: false,
      },
      {
        id: 'name',
        header: () => (
          <TableHeaderCellDiv align="right" showTooltip={false}>
            <Content id={ContentId.Actions} />
          </TableHeaderCellDiv>
        ),
        size: 40,
        cell: function Cell({ row: { original } }) {
          if (!original) {
            return <TableShimmeringDiv />;
          }

          return (
            <TableCellDiv align="right" showTooltip={false}>
              <PosDropdown
                key="event-action-dropdown"
                trigger={
                  <div
                    style={{
                      padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}`,
                    }}
                  >
                    <MoreIcon
                      withHoverEffect
                      fill={IconsFill.textBrand}
                      align="middle"
                    />
                  </div>
                }
                align="end"
              >
                <PosDropdownItem
                  key="move"
                  onClick={() => onMoveListing(original.id)}
                >
                  <Content id={ContentId.MoveTo} />
                </PosDropdownItem>
                {!isUngroupedGroup && (
                  <PosDropdownItem
                    key="remove"
                    onClick={() => removeListing(original.id)}
                  >
                    <Content id={ContentId.RemoveFromGroup} />
                  </PosDropdownItem>
                )}
              </PosDropdown>
            </TableCellDiv>
          );
        },
        enableSorting: false,
      },
    ];

    return tableColumns.filter((column) => {
      if (isUngroupedGroup) {
        return column.id && !['id', 'undercutSetting'].includes(column.id);
      }
      return true;
    });
  }, [desiredActiveListings, isUngroupedGroup, onMoveListing, removeListing]);

  return columns;
};
