import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'reactstrap';
import { ButtonWithIcon } from 'src/components/Buttons';
import { FilterPillList } from 'src/components/common/FilterPill';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { createGetColor } from 'src/components/Listings/InventoryEventPage/utils/colorUtils';
import {
  Content,
  getContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useGetVenueZoneMapConfigInfo } from 'src/contexts/EventMapContext/useGetVenueZoneMapConfigInfo';
import { useGetVenueZoneMapInfoOverrides } from 'src/contexts/EventMapContext/useGetVenueZoneMapConfigInfoOverrides';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack, Switch, TooltipPopover } from 'src/core/ui';
import { PillItem } from 'src/core/ui/PillList/PillItem';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { SectionSelectDialog } from 'src/dialogs/SectionSelectDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useCompQuantitiesFilter } from 'src/hooks/useCompQuantitiesFilter';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { useUndercutAmountSettings } from 'src/hooks/useUndercutAmountSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EditIcon, IconsFill, InfoOutlineIcon } from 'src/svgs/Viagogo';
import {
  getUndercutPriceRepresentingMode,
  UNDERCUT_PRICE_MODE_OPTIONS,
  UndercutPriceSettingsMode,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { VENUE_ZONE_CONFIG_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { roundToPrecision } from 'src/utils/numberFormatter';
import {
  Feature,
  ListingDetailsAutoPricingSectionUpdatesV2,
  SectionSectionGroup,
} from 'src/WebApiController';

import { AutoPricingSettingsInput } from '../../BulkEditAutoPricingSettingsDialog.types';
import { useAutoPricingSettings } from '../../useBulkAutoPricingSettings';
import * as styles from '../AutoPricingSettings.css';
import { PricingSetting } from '../PricingSetting';
import { SettingsSubheader } from '../PricingSubheader';
import { RowComp } from './RowComp';

type PricingAgainstProps = {
  origin: Pick<
    AutoPricingSettingsInput,
    | 'compListingMode'
    | 'compListingQuantityScoreAdjustmentEnabled'
    | 'compListingQuantityScoreAdjustmentOverrideJson'
    | 'compListingOnlyForSelectedSectionsEnabled'
    | 'compListingSelectedSectionSettings'
    | 'undercutAbsoluteAmount'
    | 'undercutRelativeAmount'
  >;
  compListingModes: Record<string, ContentId>;
  compListingModesDisabled: Record<string, ContentId | undefined>;
};

export const PricingAgainst: React.FC<PricingAgainstProps> = ({
  origin,
  compListingModes,
  compListingModesDisabled,
}) => {
  const {
    uiCurrency,
    compListingMode,
    compListingQuantityScoreAdjustmentOverrideJson,
    compListingSelectedSectionSettings,
    undercutMode,
    undercutRelativeAmount,
    undercutAbsoluteAmount,
    includeExistingSelectedSections,
    onCompListingChange,
    onUndercutChange,
    onCompListingSectionIdFilterChange,
    onCompListingModeChange,
  } = useAutoPricingSettings();

  const hasAllowSeatScoreCompListingModeFeature = useUserHasFeature(
    Feature.AllowSeatScoreCompListingMode
  );

  const hasZoneEditMapFeature = useUserHasFeature(Feature.ZoneMapEdit);

  const { watch, setValue, formState } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();

  const { event, venueMapInfo } = useEventMapContext();
  const contentContext = useContentContext();

  const [overrideLookup, setOverrideLookup] = useState<
    | {
        [key: string]: SectionSectionGroup;
      }
    | null
    | undefined
  >(null);

  const [defaultLookup, setDefaultLookup] = useState<
    | {
        [key: string]: SectionSectionGroup;
      }
    | null
    | undefined
  >(null);

  const { pricingSettingsQuery } = useGetEventAutoPricingSettings(
    event ?? null,
    false
  );

  const venueZoneMapConfigInfoQuery = useGetVenueZoneMapConfigInfo(
    event?.viagId,
    event?.venueCfgId
  );

  const venueZoneMapConfigOverrideInfoQuery = useGetVenueZoneMapInfoOverrides(
    event?.viagId,
    event?.venueCfgId
  );

  const selectedOverrideId = pricingSettingsQuery.data?.venueZoneOverrideId;
  const defaultConfig =
    pricingSettingsQuery.data?.venueZoneConfigType ??
    venueZoneMapConfigInfoQuery.data?.defaultVenueZoneConfigType;

  const venueNameFromQuery = useMemo(() => {
    if (
      selectedOverrideId != null &&
      venueZoneMapConfigOverrideInfoQuery.data &&
      venueZoneMapConfigOverrideInfoQuery.data[selectedOverrideId].name != null
    ) {
      return venueZoneMapConfigOverrideInfoQuery.data[selectedOverrideId].name;
    }

    if (
      defaultConfig != null &&
      venueZoneMapConfigInfoQuery.data?.venueMapZoneConfigs[defaultConfig]
    ) {
      return getContent(
        VENUE_ZONE_CONFIG_TYPE_TO_CID[defaultConfig],
        contentContext
      );
    }
  }, [
    contentContext,
    defaultConfig,
    selectedOverrideId,
    venueZoneMapConfigInfoQuery.data?.venueMapZoneConfigs,
    venueZoneMapConfigOverrideInfoQuery.data,
  ]);

  const currentVenueName = watch('venueZoneMapName');

  useEffect(() => {
    if (
      overrideLookup == null &&
      selectedOverrideId != null &&
      venueZoneMapConfigOverrideInfoQuery.data &&
      !venueZoneMapConfigOverrideInfoQuery.isLoading
    ) {
      setOverrideLookup(
        venueZoneMapConfigOverrideInfoQuery.data[selectedOverrideId]
          .sectionSectionGroupLookup
      );
    }
  }, [
    overrideLookup,
    selectedOverrideId,
    venueZoneMapConfigOverrideInfoQuery.data,
    venueZoneMapConfigOverrideInfoQuery.isLoading,
    setValue,
  ]);

  useEffect(() => {
    if (
      defaultLookup == null &&
      defaultConfig != null &&
      venueZoneMapConfigInfoQuery.data &&
      !venueZoneMapConfigInfoQuery.isLoading
    ) {
      setDefaultLookup(
        venueZoneMapConfigInfoQuery.data.venueMapZoneConfigs[defaultConfig]
      );
    }
  }, [
    defaultLookup,
    defaultConfig,
    venueZoneMapConfigInfoQuery.data,
    venueZoneMapConfigInfoQuery.isLoading,
    contentContext,
    setValue,
  ]);

  useEffect(() => {
    if (venueNameFromQuery && currentVenueName !== venueNameFromQuery) {
      setValue('venueZoneMapName', venueNameFromQuery);
    }
  }, [currentVenueName, venueNameFromQuery, setValue]);

  const sectionSectionGroupLookup = useMemo(() => {
    return overrideLookup ?? defaultLookup;
  }, [overrideLookup, defaultLookup]);

  const [mapOverlay, setMapOverlay] = useState<boolean>(true);

  const deriveCompsFromVenueZoneConfig = watch(
    'deriveCompsFromVenueZoneConfig'
  );
  const includeBetterZones = watch('includeBetterZones');

  const toggleZoneMap = useCallback(() => {
    setMapOverlay((prev) => !prev);
  }, []);

  const viewZoneMapText = useContent(ContentId.ViewZoneMap);
  const hideZoneMapText = useContent(ContentId.HideZoneMap);

  const buttonText = useMemo(
    () => (mapOverlay ? viewZoneMapText : hideZoneMapText),
    [hideZoneMapText, mapOverlay, viewZoneMapText]
  );

  const sectionSelectDialog = useBasicDialog();

  const {
    quantitiesFilterOptions,
    quantitiesFilterValue,
    onUpdateQuantitiesFilter,
  } = useCompQuantitiesFilter(
    compListingQuantityScoreAdjustmentOverrideJson?.value,
    onCompListingChange
  );

  const {
    undercutAbsoluteAmountMode,
    setUndercutAbsoluteAmountMode,
    undercutRelativeAmountMode,
    setUndercutRelativeAmountMode,
  } = useUndercutAmountSettings(
    undercutAbsoluteAmount?.value,
    undercutRelativeAmount?.value
  );

  const onResetCompListingMode = useCallback(() => {
    onCompListingChange({
      compListingModeNew: origin.compListingMode.value,
      compListingQuantityScoreAdjustmentEnabledNew:
        origin.compListingQuantityScoreAdjustmentEnabled.value,
      compListingOnlyForSelectedSectionsEnabledNew:
        origin.compListingOnlyForSelectedSectionsEnabled?.value,
      compListingSelectedSectionSettingsNew:
        origin.compListingSelectedSectionSettings?.value,
    });
  }, [
    onCompListingChange,
    origin.compListingMode.value,
    origin.compListingOnlyForSelectedSectionsEnabled?.value,
    origin.compListingQuantityScoreAdjustmentEnabled.value,
    origin.compListingSelectedSectionSettings?.value,
  ]);

  const selectedSectionsHasChange = useMemo(
    () =>
      !isEqual(
        origin.compListingSelectedSectionSettings?.value,
        compListingSelectedSectionSettings?.value
      ) ||
      (origin.compListingSelectedSectionSettings?.hasConflict &&
        !includeExistingSelectedSections),
    [
      compListingSelectedSectionSettings?.value,
      includeExistingSelectedSections,
      origin.compListingSelectedSectionSettings?.hasConflict,
      origin.compListingSelectedSectionSettings?.value,
    ]
  );
  const onResetSelectedSections = useCallback(() => {
    onCompListingChange({
      compListingOnlyForSelectedSectionsEnabledNew:
        origin.compListingOnlyForSelectedSectionsEnabled?.value,
      compListingSelectedSectionSettingsNew:
        origin.compListingSelectedSectionSettings?.value,
    });
    setValue(
      'includeExistingSelectedSections',
      formState.defaultValues?.includeExistingSelectedSections ?? null
    );
  }, [
    formState.defaultValues?.includeExistingSelectedSections,
    onCompListingChange,
    origin.compListingOnlyForSelectedSectionsEnabled?.value,
    origin.compListingSelectedSectionSettings?.value,
    setValue,
  ]);
  const onResetZoneMapOption = useCallback(() => {
    onResetSelectedSections();

    setValue('deriveCompsFromVenueZoneConfig', false);
    setValue('includeBetterZones', false);
    setValue(
      'includeExistingSelectedSections',
      formState.defaultValues?.includeExistingSelectedSections ?? null
    );
  }, [
    formState.defaultValues?.includeExistingSelectedSections,
    onResetSelectedSections,
    setValue,
  ]);

  const quantitiesFilterHasChange = useMemo(
    () =>
      origin.compListingQuantityScoreAdjustmentOverrideJson?.value !==
      compListingQuantityScoreAdjustmentOverrideJson?.value,
    [
      compListingQuantityScoreAdjustmentOverrideJson?.value,
      origin.compListingQuantityScoreAdjustmentOverrideJson?.value,
    ]
  );
  const onResetQuantitiesFilter = useCallback(
    () =>
      onCompListingChange({
        compListingQuantityScoreAdjustmentOverrideJsonNew:
          origin.compListingQuantityScoreAdjustmentOverrideJson?.value,
      }),
    [
      onCompListingChange,
      origin.compListingQuantityScoreAdjustmentOverrideJson?.value,
    ]
  );

  const undercutAmountHasChange = useMemo(
    () =>
      origin.undercutAbsoluteAmount?.value !== undercutAbsoluteAmount?.value ||
      origin.undercutRelativeAmount?.value !== undercutRelativeAmount?.value,
    [
      origin.undercutAbsoluteAmount?.value,
      origin.undercutRelativeAmount?.value,
      undercutAbsoluteAmount?.value,
      undercutRelativeAmount?.value,
    ]
  );
  const onResetUndercutAmount = useCallback(
    () =>
      onUndercutChange(
        undefined,
        origin.undercutAbsoluteAmount?.value,
        origin.undercutRelativeAmount?.value
      ),
    [
      onUndercutChange,
      origin.undercutAbsoluteAmount?.value,
      origin.undercutRelativeAmount?.value,
    ]
  );

  const existingSectionsText = useContent(ContentId.ExistingSections);
  const editSections = useContent(ContentId.EditSections);

  const selectedSections = useMemo(() => {
    const formSectionFilterIds =
      compListingSelectedSectionSettings?.value?.sectionIdFilter ?? [];
    return (
      venueMapInfo?.sections.filter((section) => {
        return formSectionFilterIds.includes(section.id);
      }) ?? []
    );
  }, [
    compListingSelectedSectionSettings?.value?.sectionIdFilter,
    venueMapInfo?.sections,
  ]);

  const rowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.value?.rowIdFilter ?? [];
  }, [compListingSelectedSectionSettings]);

  const sectionRowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.value?.sectionRowIdFilter ?? {};
  }, [compListingSelectedSectionSettings]);

  const applyCompListingChanges = (
    rowIdFilters: number[],
    sectionRowIdFilters: { [x: string]: string }
  ) => {
    const compListingSelectedSectionSettingsNew = {
      sectionIdFilter:
        compListingSelectedSectionSettings?.value?.sectionIdFilter ?? [],
      rowIdFilter: Array.from(new Set(rowIdFilters)),
      sectionRowIdFilter: sectionRowIdFilters,
    };

    onCompListingChange({
      compListingSelectedSectionSettingsNew,
      compListingOnlyForSelectedSectionsEnabledNew: true,
    });
  };

  return (
    <>
      <SettingsSubheader title={ContentId.PriceAgainst} />
      {hasAllowSeatScoreCompListingModeFeature && (
        <PricingSetting
          header={<Content id={ContentId.AllListings} />}
          detail={
            <PosEnumSelect
              value={compListingMode?.value}
              valueOptionsContent={compListingModes}
              valueOptionsDisabled={compListingModesDisabled}
              placeholderText={ContentId.None}
              onClick={(e) => e.stopPropagation()}
              onChange={onCompListingModeChange}
              style={{ width: '50%' }}
            />
          }
          hasChange={origin.compListingMode.value !== compListingMode?.value}
          hasConflict={origin.compListingMode.hasConflict}
          onReset={onResetCompListingMode}
        />
      )}

      {event && (
        <>
          {!deriveCompsFromVenueZoneConfig ? (
            <PricingSetting
              header={
                <Stack direction="column" gap="s">
                  <Stack width="full" gap="m">
                    <Content id={ContentId.Sections} />
                  </Stack>
                  {hasZoneEditMapFeature && (
                    <div
                      className={styles.sectionSelectionToggle}
                      onClick={() =>
                        setValue(
                          'deriveCompsFromVenueZoneConfig',
                          !deriveCompsFromVenueZoneConfig
                        )
                      }
                    >
                      <Content id={ContentId.UseZoneMap} />{' '}
                    </div>
                  )}
                </Stack>
              }
              detail={
                <Stack gap="m" width="full" alignItems="center">
                  {event && (
                    <ButtonWithIcon
                      variant="textPlain"
                      title={editSections}
                      icon={
                        <EditIcon
                          fill={IconsFill.textBrand}
                          size={vars.iconSize.s}
                          withHoverEffect
                        />
                      }
                      onClick={sectionSelectDialog.launchDialog}
                      style={{ padding: 0, textAlign: 'start' }}
                    />
                  )}
                  {origin.compListingSelectedSectionSettings?.hasConflict &&
                    includeExistingSelectedSections && (
                      <PillItem
                        value={includeExistingSelectedSections + ''}
                        display={existingSectionsText}
                        customPillContainer={
                          includeExistingSelectedSections
                            ? styles.pillSelected
                            : styles.pill
                        }
                        onDelete={() =>
                          event &&
                          setValue('includeExistingSelectedSections', false)
                        }
                        disabled={!includeExistingSelectedSections}
                      />
                    )}
                  {event && (
                    <div style={{ flex: 1, width: '400px', overflow: 'auto' }}>
                      <FilterPillList
                        selectedSections={selectedSections}
                        rowIdFilters={rowIdFilters}
                        sectionRowIdFilters={sectionRowIdFilters}
                        applyRowFilterChanges={applyCompListingChanges}
                        applySelectedSectionChanges={
                          onCompListingSectionIdFilterChange
                        }
                      />
                    </div>
                  )}
                </Stack>
              }
              hasChange={selectedSectionsHasChange}
              hasConflict={
                origin.compListingSelectedSectionSettings?.hasConflict
              }
              onReset={onResetSelectedSections}
            />
          ) : hasZoneEditMapFeature ? (
            <>
              <PricingSetting
                header={
                  <Stack direction="column" gap="s">
                    <Content id={ContentId.Zones} />
                    <div
                      className={styles.sectionSelectionToggle}
                      onClick={() =>
                        setValue(
                          'deriveCompsFromVenueZoneConfig',
                          !deriveCompsFromVenueZoneConfig
                        )
                      }
                    >
                      <Content id={ContentId.ChooseSpecificSections} />
                    </div>
                  </Stack>
                }
                detail={
                  <div className={styles.mapOverlayContainer}>
                    <div
                      className={clsx(styles.overlayContainer, {
                        [styles.overlay]: mapOverlay,
                        inactive: !mapOverlay,
                      })}
                    >
                      <span className={styles.mapTitle}>
                        {currentVenueName}
                      </span>
                      <Button
                        variant="outline"
                        className={styles.toggleButton}
                        onClick={toggleZoneMap}
                      >
                        {buttonText}
                      </Button>
                    </div>
                    <div
                      className={clsx(styles.zoneMapWrapper, {
                        [styles.zoneMapOverlay]: mapOverlay,
                      })}
                    >
                      <div className={styles.zoneMapContainer}>
                        <EventVenueMap
                          showDefaultMapColors={true}
                          getColor={createGetColor(sectionSectionGroupLookup)}
                        />
                      </div>
                    </div>
                  </div>
                }
                hasChange={true}
                hasConflict={
                  origin.compListingSelectedSectionSettings?.hasConflict
                }
                onReset={onResetZoneMapOption}
              />
              <PricingSetting
                header={
                  <Stack direction="row" gap="m" alignItems="center">
                    <Content id={ContentId.IncludeBetterZones} />
                    <TooltipPopover
                      triggerContent={
                        <InfoOutlineIcon size={vars.iconSize.s} />
                      }
                      contentVariant="dark"
                    >
                      <Content id={ContentId.BetterZonesDetail} />
                    </TooltipPopover>
                  </Stack>
                }
                detail={
                  <Switch
                    checked={includeBetterZones ?? false}
                    onChange={() =>
                      setValue('includeBetterZones', !includeBetterZones)
                    }
                    onCheckedChange={() =>
                      setValue('includeBetterZones', !includeBetterZones)
                    }
                  />
                }
                hasChange={includeBetterZones ?? false}
                hasConflict={false}
                onReset={() => setValue('includeBetterZones', false)}
              />
              <RowComp />
            </>
          ) : null}
        </>
      )}

      <PricingSetting
        header={<Content id={ContentId.QuantitiesFilter} />}
        detail={
          <Stack direction="row" width="full">
            <div style={{ width: '50%' }}>
              <PosMultiSelect
                align="start"
                triggerProps={{ style: { width: '100%' } }}
                values={quantitiesFilterValue ?? []}
                onChange={onUpdateQuantitiesFilter}
                valueOptionsContent={quantitiesFilterOptions}
                valuesDisplayText={(quantitiesFilterValue ?? []).join(',')}
              />
            </div>
          </Stack>
        }
        hasChange={quantitiesFilterHasChange}
        hasConflict={
          origin.compListingQuantityScoreAdjustmentOverrideJson?.hasConflict
        }
        onReset={onResetQuantitiesFilter}
      />
      <PricingSetting
        header={<Content id={ContentId.UnderCutModePrompt} />}
        detail={
          <Stack gap="m">
            <ToggleGroup
              disabled={!undercutMode || !undercutRelativeAmount?.value}
              options={UNDERCUT_PRICE_MODE_OPTIONS}
              value={undercutRelativeAmountMode}
              onValueChange={(value) => {
                if (!value) return;

                const mode = value as UndercutPriceSettingsMode;
                setUndercutRelativeAmountMode(mode);

                if (undercutRelativeAmount?.value) {
                  const curAmountAbs = Math.abs(undercutRelativeAmount.value);
                  onUndercutChange(
                    undefined,
                    undefined,
                    getUndercutPriceRepresentingMode(curAmountAbs, mode)
                  );
                }
              }}
            />

            <div className={styles.inlinePricingInputText}>
              <Content id={ContentId.PriceBy} />
            </div>

            <PosFormField style={{ width: 'fit-content' }}>
              <PosTextField
                disabled={!undercutMode}
                value={
                  undercutRelativeAmount?.value != null
                    ? roundToPrecision(
                        Math.abs(undercutRelativeAmount.value) * 100,
                        8
                      )
                    : ''
                }
                placeholder={undefined}
                type="number"
                postfixDisplay="%"
                onChange={(e) => {
                  const v = parseFloat(e.target.value);
                  if (v >= 0 && v <= Number.MAX_VALUE) {
                    const relativeValue = Math.min(v, 100) / 100;
                    if (
                      undercutRelativeAmountMode ===
                      UndercutPriceSettingsMode.Premium
                    ) {
                      onUndercutChange(undefined, undefined, -relativeValue);
                    } else {
                      onUndercutChange(undefined, undefined, relativeValue);
                    }
                  } else {
                    onUndercutChange(undefined, undefined, null);
                  }
                }}
              />
            </PosFormField>
            <div className={styles.inlinePricingInputText}>
              <Content id={ContentId.Then} />
            </div>
            <ToggleGroup
              disabled={!undercutMode || !undercutAbsoluteAmount}
              options={UNDERCUT_PRICE_MODE_OPTIONS}
              value={undercutAbsoluteAmountMode}
              onValueChange={(value) => {
                if (!value) return;

                const mode = value as UndercutPriceSettingsMode;
                setUndercutAbsoluteAmountMode(mode);

                if (undercutAbsoluteAmount?.value) {
                  const curAmountAbs = Math.abs(undercutAbsoluteAmount.value);
                  onUndercutChange(
                    undefined,
                    getUndercutPriceRepresentingMode(curAmountAbs, mode)
                  );
                }
              }}
            />
            <div className={styles.inlinePricingInputText}>
              <Content id={ContentId.By} />
            </div>
            <PosFormField style={{ width: 'fit-content' }}>
              <PosCurrencyField
                uiCurrency={uiCurrency}
                disabled={!undercutMode}
                value={
                  undercutAbsoluteAmount?.value != null
                    ? Math.abs(undercutAbsoluteAmount.value)
                    : ''
                }
                placeholder={undefined}
                onChange={(e) => {
                  const v = parseFloat(e.target.value);
                  if (v >= 0 && v <= Number.MAX_VALUE) {
                    if (
                      undercutAbsoluteAmountMode ===
                      UndercutPriceSettingsMode.Premium
                    ) {
                      onUndercutChange(undefined, -v);
                    } else {
                      onUndercutChange(undefined, v);
                    }
                  } else {
                    onUndercutChange(undefined, null);
                  }
                }}
              />
            </PosFormField>
          </Stack>
        }
        hasChange={undercutAmountHasChange}
        hasConflict={
          origin.undercutAbsoluteAmount?.hasConflict ||
          origin.undercutRelativeAmount?.hasConflict
        }
        onReset={onResetUndercutAmount}
      />
      <SectionSelectDialog
        {...sectionSelectDialog.dialogProps}
        initialSectionIds={
          compListingSelectedSectionSettings?.value?.sectionIdFilter ?? []
        }
        onSave={(selectedSections) => {
          const compListingSelectedSectionSettingsNew = {
            ...compListingSelectedSectionSettings?.value,
            sectionIdFilter: selectedSections.map((s) => s.id),
            rowIdFilter:
              compListingSelectedSectionSettings?.value?.rowIdFilter ?? [],
            sectionRowIdFilter:
              compListingSelectedSectionSettings?.value?.sectionRowIdFilter ??
              {},
          };
          onCompListingChange({ compListingSelectedSectionSettingsNew });
          sectionSelectDialog.closeDialog();
        }}
        onClosed={sectionSelectDialog.closeDialog}
      />
    </>
  );
};
