import { isEmpty } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { SimpleTable } from 'src/core/ui';
import { useEventMarketplaceSettings } from 'src/hooks/useEventMarketplaceSettings';
import { ContentId } from 'src/utils/constants/contentId';
import { compareMarketplace } from 'src/utils/eventWithDataUtils';
import { isMarketplaceActive } from 'src/utils/userUtils';
import {
  Event,
  ListingMarketplaceSetting,
  Marketplace,
} from 'src/WebApiController';

import { GroupListingsFormProps } from '../../GroupListingsV2.types';
import * as styles from './Broadcast.css';
import { BroadcastMarketplace } from './BroadcastMarketplace';

/**
 * Get maerketplace id from marketplace enum.
 * This assumes the enum order is aligned with the Marketplace.Marketplace table
 */
const getMarketplaceIdFromMarketplace = (marketplace: Marketplace) => {
  let marketplaceId = 0;
  Object.values(Marketplace).forEach((mp, index) => {
    if (mp === marketplace) {
      marketplaceId = index + 1;
    }
  });
  return marketplaceId;
};

type GroupBroadcastSettingsProps = {
  event?: Event;
};

export const GroupBroadcastSettings: React.FC<GroupBroadcastSettingsProps> = ({
  event,
}) => {
  const { watch, setValue } = useFormContext<GroupListingsFormProps>();
  const marketplaceSettings = watch('marketplaceSettings');
  const { eventMarketplaceSettings, isUKEvent, isInternationalEvent } =
    useEventMarketplaceSettings(event);

  const availableMarketplaceSettings = useMemo(
    () =>
      eventMarketplaceSettings.filter((mk) => {
        if (mk.mkp === Marketplace.StubHub) {
          return true;
        }
        return (!event || !isInternationalEvent) && isMarketplaceActive(mk);
      }),
    [eventMarketplaceSettings, event, isInternationalEvent]
  );

  const onListingBroadcastToggle = useCallback(
    (isSelected: boolean, isBroadcasting: boolean, mp: Marketplace) => {
      const { listingMarketplaceSettings } = marketplaceSettings || {
        listingMarketplaceSettings: {},
      };
      const marketplaceId = getMarketplaceIdFromMarketplace(mp);
      const copied = { ...listingMarketplaceSettings };
      if (isSelected) {
        Object.assign(copied, {
          [marketplaceId + '']: {
            listingBroadcastStateId: isBroadcasting ? 2 : 1,
          },
        });
      } else {
        delete copied[marketplaceId];
      }
      setValue('marketplaceSettings', { listingMarketplaceSettings: copied });
    },
    [marketplaceSettings, setValue]
  );
  const onToggleAllBroadcast = useCallback(
    (isSelected: boolean) => {
      if (isSelected) {
        const marketplaceSettings: {
          [key: string]: ListingMarketplaceSetting;
        } = {};
        for (const setting of availableMarketplaceSettings) {
          const marketplaceId = getMarketplaceIdFromMarketplace(setting.mkp);
          marketplaceSettings[marketplaceId + ''] = {
            listingBroadcastStateId: 2,
          };
        }
        setValue('marketplaceSettings', {
          listingMarketplaceSettings: marketplaceSettings,
        });
      } else {
        setValue('marketplaceSettings', {
          listingMarketplaceSettings: {},
        });
      }
    },
    [availableMarketplaceSettings, setValue]
  );
  const allSelected = useMemo(() => {
    const { listingMarketplaceSettings } = marketplaceSettings || {
      listingMarketplaceSettings: {},
    };
    if (isEmpty(listingMarketplaceSettings)) {
      return false;
    }
    return availableMarketplaceSettings.every((setting) => {
      const marketplaceId = getMarketplaceIdFromMarketplace(setting.mkp);
      return !isEmpty(listingMarketplaceSettings[marketplaceId + '']);
    });
  }, [availableMarketplaceSettings, marketplaceSettings]);

  const marketplacesSelected = useMemo(() => {
    const selected: Marketplace[] = [];
    const listingMarketplaceSettings =
      marketplaceSettings?.listingMarketplaceSettings ?? {};
    const includedIds = Object.keys(listingMarketplaceSettings);
    Object.values(Marketplace).forEach((mp, index) => {
      if (includedIds.includes(index + 1 + '')) {
        selected.push(mp);
      }
    });
    return selected;
  }, [marketplaceSettings]);

  const marketplaceToStateId = useMemo(() => {
    const marketplaceToId = {} as Record<Marketplace, number>;
    const listingMarketplaceSettings =
      marketplaceSettings?.listingMarketplaceSettings ?? {};
    const includedIds = Object.keys(listingMarketplaceSettings);
    Object.values(Marketplace).forEach((mp, index) => {
      const key = index + 1 + '';
      if (includedIds.includes(key)) {
        marketplaceToId[mp] =
          listingMarketplaceSettings[key].listingBroadcastStateId;
      }
    });
    return marketplaceToId;
  }, [marketplaceSettings]);

  return (
    <SimpleTable.Table className={styles.broadcastTable}>
      <SimpleTable.Thead>
        <SimpleTable.Tr>
          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Checkbox
              checked={allSelected}
              onChange={(event) => {
                const value = event.target.checked;
                onToggleAllBroadcast(value);
              }}
            />
          </SimpleTable.Th>
          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.Marketplace} />
          </SimpleTable.Th>
          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.InventoryStatus} />
          </SimpleTable.Th>
          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.Broadcast} />
          </SimpleTable.Th>
        </SimpleTable.Tr>
      </SimpleTable.Thead>
      <SimpleTable.Tbody>
        {availableMarketplaceSettings
          .sort((a, b) => compareMarketplace(a.mkp, b.mkp))
          .flatMap((mk) => {
            const isSelected = marketplacesSelected.includes(mk.mkp);
            const isActive = marketplaceToStateId[mk.mkp] === 2;

            if (mk.mkp === Marketplace.StubHub) {
              return [
                <BroadcastMarketplace
                  key={`${mk}-${false}`}
                  marketplace={mk.mkp}
                  isActive={isActive}
                  isSelected={isSelected}
                  onListingBroadcastToggle={onListingBroadcastToggle}
                  isUKEvent={isUKEvent}
                />,
                <BroadcastMarketplace
                  key={`${mk}-${true}`}
                  marketplace={mk.mkp}
                  isViagogo
                  isActive={isActive}
                  isSelected={isSelected}
                  onListingBroadcastToggle={onListingBroadcastToggle}
                />,
              ];
            }

            return [
              <BroadcastMarketplace
                key={`${mk}-${false}`}
                marketplace={mk.mkp}
                isActive={isActive}
                isSelected={isSelected}
                onListingBroadcastToggle={onListingBroadcastToggle}
              />,
            ];
          })}
      </SimpleTable.Tbody>
    </SimpleTable.Table>
  );
};
