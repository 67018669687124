import {
  BucketType,
  ListingGroupListingMarketplaceSettings,
  ListingGroupUndercutSetting,
  MergeListingGroupInputList,
  RowGroupSetting,
  VenueZoneConfigType,
} from 'src/WebApiController';

export enum GroupListingsState {
  Configure = 'Configure',
  Review = 'Review',
}

export enum ListingGroupType {
  Zone = 'Zone',
  ZoneMap = 'ZoneMap',
  Section = 'Section',
  Row = 'Row',
  Quantity = 'Quantity',
  UnitCost = 'UnitCost',
  None = 'None',
}

export type ListingGroupBy = {
  groupingType: ListingGroupType;
  numberOfBuckets?: number;
  bucketOption?: BucketType;
};

export type GroupListingsFormProps = MergeListingGroupInputList & {
  groupBys: ListingGroupBy[];
  venueZoneConfigType: VenueZoneConfigType;
  rowGroupSetting: RowGroupSetting;
  deprioritizedQuantities: string[];
  groupSortBy: GroupSortByType | null;
  desiredActiveListings: number | null;
  groupUndercutSettings: ListingGroupUndercutSetting | null;
  marketplaceSettings: ListingGroupListingMarketplaceSettings | null;
  rowRanges: RowRange[];
};

export enum SplitZoneByType {
  RowRanges = 'RowRanges',
}

export enum RankPremiumType {
  Absolute = 'absolute',
  Relative = 'relative',
}

export enum GroupSortByType {
  SectionAsc = 'SectionAsc',
  SectionDesc = 'SectionDesc',
  RowAsc = 'RowAsc',
  RowDesc = 'RowDesc',
  UnsoldQuantityAsc = 'UnsoldQuantityAsc',
  UnsoldQuantityDesc = 'UnsoldQuantityDesc',
}

export type RowRange = {
  min: number;
  max: number;
};
