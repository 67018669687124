import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Button, Stack, Switch } from 'src/core/ui';
import { AddSectionGroupDialog } from 'src/dialogs/AddSectionGroupDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionSectionGroup } from 'src/WebApiController';

import { getRandomColor } from '../../../utils/colorUtils';
import { VenueZoneMapFormType } from '../InventoryEventVenueZoneMapSection';
import * as styles from './SectionGroupPanel.css';
import { VenueZoneSectionGroupDraggableList } from './VenueZoneSectionGroupDraggableList';
import { VenueZoneSectionGroupList } from './VenueZoneSectionGroupList';

export const SectionGroupPanel = () => {
  const { watch, setValue, reset } = useFormContext<VenueZoneMapFormType>();

  const [useCustomRank, setUseCustomRank] = useState(false);

  const addSectionGroupDialog = useBasicDialog();

  const customSectionGroups = watch('customSectionGroups');

  const onAddSectionGroup = useCallback(
    (ticketClassName: string, sectionGroupName: string) => {
      const newCustomSectionGroup = {
        sectId: 0,
        areaRank: 1,
        sectionGroupRank: 1,
        ticketClassName: ticketClassName,
        sectionGroupAreaName: sectionGroupName,
        sectionGroupAreaType: null,
        ticketClassId: null,
        sectionGroupColor: getRandomColor(customSectionGroups.length),
      } as SectionSectionGroup;

      const newCustomSectionGroups = [
        ...(customSectionGroups || []),
        newCustomSectionGroup,
      ];
      setValue('customSectionGroups', newCustomSectionGroups);

      addSectionGroupDialog.closeDialog();
    },
    [addSectionGroupDialog, customSectionGroups, setValue]
  );

  return (
    <div className={styles.gridPanelContainer}>
      <Stack direction="row" gap="m" alignItems="center">
        <strong>
          <Content id={ContentId.CustomRanks} />
        </strong>
        <Switch
          checked={useCustomRank}
          onChange={(e) => e.stopPropagation()}
          onCheckedChange={(isChecked) => {
            if (!isChecked) {
              reset();
            }

            setUseCustomRank(isChecked);
          }}
        />
        <Content id={useCustomRank ? ContentId.Enabled : ContentId.Disabled} />
      </Stack>
      {useCustomRank ? (
        <VenueZoneSectionGroupDraggableList />
      ) : (
        <VenueZoneSectionGroupList />
      )}

      <div className={styles.addSectionButton}>
        <Button variant="text" onClick={addSectionGroupDialog.launchDialog}>
          + <Content id={ContentId.AddSection} />
        </Button>
      </div>
      <AddSectionGroupDialog
        {...addSectionGroupDialog.dialogProps}
        onOkay={onAddSectionGroup}
        onCancel={addSectionGroupDialog.closeDialog}
      />
    </div>
  );
};
