import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Stack, Switch } from 'src/core/ui';
import { useAvailableAutopricingModes } from 'src/hooks/useAvailableAutopricingModes';
import { RestrictSelectionType } from 'src/modals/ListingDetails/components/PricingSection/AutoPricingSection.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AutoPricingCompListingMode,
  AutoPricingInputs,
  AutoPricingOutlierMode,
  SectionInfo,
  UiCurrency,
} from 'src/WebApiController';

import { AutoPricingCompFloorAndCeilingInput } from '../AutoPricingCompFloorAndCeilingInput/AutoPricingCompFloorAndCeilingInput';
import { AutoPricingCompListingModeSelector } from '../AutoPricingCompListingModeSelector/AutoPricingCompListingModeSelector';
import { AutoPricingCompQuantitiesSelector } from '../AutoPricingCompQuantitiesSelector/AutoPricingCompQuantitiesSelector';
import { AutoPricingOutlierDetectionModeSelector } from '../AutoPricingOutlierDetectionModeSelector/AutoPricingOutlierDetectionModeSelector';
import { AutoPricingOutlierDetectionSettingsInput } from '../AutoPricingOutlierDetectionSettingsInput/AutoPricingOutlierDetectionSettingsInput';
import { AutoPricingSelectedSectionSettingsInput } from '../AutoPricingSelectedSectionSettingsInput/AutoPricingSelectedSectionSettingsInput';
import { AutoPricingUnderCutRelativeAndAbsoluteAmountsInput } from '../AutoPricingUnderCutRelativeAndAbsoluteAmountsInput/AutoPricingUnderCutRelativeAndAbsoluteAmountsInput';
import * as styles from './AutoPricingSettingsFormContent.css';

export type AutoPricingSettingsFormContentCoreProps = {
  venueMapSections: SectionInfo[] | null | undefined;
  hasSeatScores: boolean;
  uiCurrency: UiCurrency;
  allowSeatScoreCompListingMode: boolean;
  disabled: boolean;
};

type AutoPricingSettingsFormContentCoreAndOptionalProps =
  AutoPricingSettingsFormContentCoreProps & {
    isGlobalEdit?: boolean;
    hideCompListingQuantityFilters?: boolean;
    hideUnderCutAmountInput?: boolean;
    hideBaseAdvancedSettingElements?: boolean;
    autoPricingSectionChildren?: React.ReactNode;
    priceAgainstSectionChildren?: React.ReactNode;
    pricingProtectionSectionChildren?: React.ReactNode;
    advancedSettingsSectionChildren?: React.ReactNode;
  };

export const AutoPricingSettingsFormContent = ({
  venueMapSections,
  hasSeatScores,
  isGlobalEdit,
  uiCurrency,
  allowSeatScoreCompListingMode,
  hideCompListingQuantityFilters,
  hideUnderCutAmountInput,
  hideBaseAdvancedSettingElements,
  autoPricingSectionChildren,
  priceAgainstSectionChildren,
  pricingProtectionSectionChildren,
  advancedSettingsSectionChildren,
  disabled,
}: AutoPricingSettingsFormContentCoreAndOptionalProps) => {
  const { watch } = useFormContext<AutoPricingInputs>();

  const { undercutMode, compListingMode } = watch();

  const { outlierModes, outlierModesDisabled, restrictSelectionTypes } =
    useAvailableAutopricingModes(
      true,
      undercutMode,
      compListingMode,
      hasSeatScores,
      false,
      isGlobalEdit,
      true
    );

  return (
    <div>
      {autoPricingSectionChildren && (
        <AutoPricingSettingsSection title={ContentId.Autopricing}>
          {autoPricingSectionChildren}
        </AutoPricingSettingsSection>
      )}

      <PriceAgainstSection
        venueMapSections={venueMapSections}
        allowSeatScoreCompListingMode={allowSeatScoreCompListingMode}
        restrictSelectionTypes={restrictSelectionTypes}
        hideCompListingQuantityFilters={hideCompListingQuantityFilters}
        hideUnderCutAmountInput={hideUnderCutAmountInput}
        uiCurrency={uiCurrency}
        disabled={disabled}
      >
        {priceAgainstSectionChildren}
      </PriceAgainstSection>

      {pricingProtectionSectionChildren && (
        <AutoPricingSettingsSection title={ContentId.PricingProtection}>
          {pricingProtectionSectionChildren}
        </AutoPricingSettingsSection>
      )}

      <AdvancedSettingsSection
        outlierModes={outlierModes}
        outlierModesDisabled={outlierModesDisabled}
        disabled={disabled}
        hideBaseAdvancedSettingElements={hideBaseAdvancedSettingElements}
      >
        {advancedSettingsSectionChildren}
      </AdvancedSettingsSection>
    </div>
  );
};

const PriceAgainstSection = ({
  venueMapSections,
  allowSeatScoreCompListingMode,
  restrictSelectionTypes,
  uiCurrency,
  hideCompListingQuantityFilters,
  hideUnderCutAmountInput,
  disabled,
  children,
}: React.PropsWithChildren & {
  venueMapSections: SectionInfo[] | null | undefined;
  allowSeatScoreCompListingMode: boolean;
  restrictSelectionTypes: Record<string, ContentId>;
  uiCurrency: UiCurrency;
  hideCompListingQuantityFilters: boolean | undefined;
  hideUnderCutAmountInput: boolean | undefined;
  disabled: boolean;
}) => {
  const { watch, setValue } = useFormContext<AutoPricingInputs>();

  const {
    compListingMode,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
  } = watch();

  const showCompListingSettingsDetails =
    compListingMode === AutoPricingCompListingMode.QualityScore;

  const showCompListingRestictSelectionDetails =
    compListingMode != null &&
    (compListingMode === AutoPricingCompListingMode.QualityScore ||
      compListingMode === AutoPricingCompListingMode.SameZone);

  const restrictSelection = Boolean(
    compListingOnlyForSameZoneEnabled ||
      compListingOnlyForSelectedSectionsEnabled
  );

  const [
    isOnlyComparableZonesModeAvailable,
    setIsOnlyComparableZonesModeAvailable,
  ] = useState(false);

  return (
    <AutoPricingSettingsSection title={ContentId.PriceAgainst}>
      {!isOnlyComparableZonesModeAvailable && (
        <>
          <PricingSetting
            header={<Content id={ContentId.AllListings} />}
            detail={
              <AutoPricingCompListingModeSelector
                allowSeatScoreCompListingMode={allowSeatScoreCompListingMode}
                disabled={disabled}
                hideWhenOnlyComparableZonesModeIsAvailable={true}
                setIsOnlyComparableZonesModeAvailable={
                  setIsOnlyComparableZonesModeAvailable
                }
              />
            }
          />

          {showCompListingSettingsDetails && (
            <PricingSetting
              header={null}
              detail={
                <AutoPricingCompFloorAndCeilingInput disabled={disabled} />
              }
            />
          )}

          {showCompListingRestictSelectionDetails && (
            <>
              <PricingSetting
                header={<Content id={ContentId.RestrictSelection} />}
                detail={
                  <Switch
                    disabled={disabled}
                    checked={restrictSelection}
                    onClick={(e) => e.stopPropagation()}
                    onCheckedChange={(isChecked) => {
                      let compListingOnlyForSameZoneEnabledNew: boolean | null =
                        null;
                      let compListingOnlyForSelectedSectionsEnabledNew:
                        | boolean
                        | null = null;
                      if (isChecked) {
                        if (
                          !compListingOnlyForSameZoneEnabled &&
                          !compListingOnlyForSelectedSectionsEnabled
                        ) {
                          // set zone by default
                          compListingOnlyForSameZoneEnabledNew = true;
                          compListingOnlyForSelectedSectionsEnabledNew = false;
                        }
                      } else {
                        compListingOnlyForSameZoneEnabledNew = false;
                        compListingOnlyForSelectedSectionsEnabledNew = false;
                      }

                      if (
                        compListingOnlyForSameZoneEnabledNew != null ||
                        compListingOnlyForSelectedSectionsEnabledNew != null
                      ) {
                        setValue(
                          'compListingOnlyForSameZoneEnabled',
                          compListingOnlyForSameZoneEnabledNew
                        );
                        setValue(
                          'compListingOnlyForSelectedSectionsEnabled',
                          compListingOnlyForSelectedSectionsEnabledNew
                        );
                      }
                    }}
                  />
                }
              />
            </>
          )}
          {restrictSelection && (
            <PricingSetting
              header={<Content id={ContentId.RestrictTo} />}
              detail={
                <PosEnumSelect
                  value={
                    compListingOnlyForSameZoneEnabled
                      ? RestrictSelectionType.SameZone
                      : RestrictSelectionType.SpecificSections
                  }
                  disabled={disabled}
                  valueOptionsContent={restrictSelectionTypes}
                  placeholderText={ContentId.None}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(v) => {
                    let compListingOnlyForSameZoneEnabledNew = false;
                    let compListingOnlyForSelectedSectionsEnabledNew = true;
                    if (v === RestrictSelectionType.SameZone) {
                      compListingOnlyForSameZoneEnabledNew = true;
                      compListingOnlyForSelectedSectionsEnabledNew = false;
                    }

                    setValue(
                      'compListingOnlyForSameZoneEnabled',
                      compListingOnlyForSameZoneEnabledNew
                    );
                    setValue(
                      'compListingOnlyForSelectedSectionsEnabled',
                      compListingOnlyForSelectedSectionsEnabledNew
                    );
                  }}
                />
              }
            />
          )}
        </>
      )}

      {compListingOnlyForSelectedSectionsEnabled && venueMapSections && (
        <PricingSetting
          header={<Content id={ContentId.Sections} />}
          detail={
            <AutoPricingSelectedSectionSettingsInput
              venueMapSections={venueMapSections}
              disabled={disabled}
            />
          }
        />
      )}

      {!hideCompListingQuantityFilters && (
        <PricingSetting
          header={<Content id={ContentId.Quantities} />}
          detail={<AutoPricingCompQuantitiesSelector disabled={disabled} />}
        />
      )}

      {!hideUnderCutAmountInput && (
        <PricingSetting
          header={<Content id={ContentId.HowToDeterminePrice} />}
          detail={
            <AutoPricingUnderCutRelativeAndAbsoluteAmountsInput
              uiCurrency={uiCurrency}
              disabled={disabled}
            />
          }
        />
      )}

      {children}
    </AutoPricingSettingsSection>
  );
};

const AdvancedSettingsSection = ({
  outlierModes,
  outlierModesDisabled,
  disabled,
  hideBaseAdvancedSettingElements,
  children,
}: React.PropsWithChildren & {
  outlierModes: Record<string, ContentId>;
  outlierModesDisabled: Record<string, ContentId | undefined>;
  disabled: boolean;
  hideBaseAdvancedSettingElements?: boolean;
}) => {
  const { watch, setValue } = useFormContext<AutoPricingInputs>();

  const {
    compListingExcludeFanInventory,
    compListingExcludeDefects,
    outlierMode,
  } = watch();

  const shouldShowAdvancedSettings =
    !hideBaseAdvancedSettingElements || !!children;
  return (
    shouldShowAdvancedSettings && (
      <AutoPricingSettingsSection title={ContentId.AdvancedSettings}>
        {!hideBaseAdvancedSettingElements && (
          <>
            <PricingSetting
              header={<Content id={ContentId.ExcludeFanInventory} />}
              detail={
                <Switch
                  disabled={disabled}
                  checked={compListingExcludeFanInventory ?? false}
                  onClick={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    const compListingExcludeFanInventoryNew = isChecked;
                    if (
                      compListingExcludeFanInventoryNew !==
                      compListingExcludeFanInventory
                    ) {
                      setValue(
                        'compListingExcludeFanInventory',
                        compListingExcludeFanInventoryNew
                      );
                    }
                  }}
                />
              }
            />

            <PricingSetting
              header={<Content id={ContentId.IncludeDefects} />}
              detail={
                <Switch
                  disabled={disabled}
                  checked={!(compListingExcludeDefects ?? true)}
                  onClick={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    const compListingExcludeDefectsNew = !isChecked;
                    if (
                      compListingExcludeDefectsNew !== compListingExcludeDefects
                    ) {
                      setValue(
                        'compListingExcludeDefects',
                        compListingExcludeDefectsNew
                      );
                    }
                  }}
                />
              }
            />

            <PricingSetting
              header={<Content id={ContentId.OutlierMode} />}
              detail={
                <AutoPricingOutlierDetectionModeSelector
                  disabled={disabled}
                  outlierModes={outlierModes}
                  outlierModesDisabled={outlierModesDisabled}
                />
              }
            />

            {outlierMode != AutoPricingOutlierMode.SkipLogic && (
              <PricingSetting
                header={<Content id={ContentId.OutlierFiltering} />}
                detail={
                  <AutoPricingOutlierDetectionSettingsInput
                    disabled={disabled}
                  />
                }
              />
            )}
          </>
        )}

        {children}
      </AutoPricingSettingsSection>
    )
  );
};

const AutoPricingSettingsSection = ({
  title,
  children,
}: React.PropsWithChildren & { title: ContentId }) => {
  return (
    <Stack className={styles.sectionContainer} direction="column" gap="l">
      <div className={styles.sectionHeader}>
        <Content id={title} />
      </div>

      {children}
    </Stack>
  );
};

export const PricingSetting = ({
  header,
  detail,
  headerStyle,
}: {
  header: React.ReactNode;
  detail: React.ReactNode;
  headerStyle?: React.CSSProperties;
}) => {
  return (
    <Stack direction="row" gap="m" alignItems="center" width="full">
      <div className={styles.pricingSettingHeader} style={headerStyle}>
        {header}
      </div>
      <div className={styles.pricingSettingDetails}>{detail}</div>
    </Stack>
  );
};
