import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isSuccess } from 'src/utils/errorUtils';
import { hasChanged, posChangedField } from 'src/utils/posFieldUtils';
import {
  getPosManagedMarketplaceSaleInputFromListing,
  getPosManagedMarketplaceSaleInputFromSale,
  getSaleDetailsRelativeUrl,
} from 'src/utils/saleUtils';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  ApiException,
  Event,
  ListingDetails,
  PosManagedMarketplaceSaleInput,
  SaleClient,
  SaleDetails,
} from 'src/WebApiController';

import { CancellableFormFooter } from '../common';
import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from '../common/EventEntityHeader';
import { modalDetails } from '../common/Modals.css';
import { Summary } from '../common/Summary';
import { ModalProps } from '../Modal';
import {
  ModalBody,
  ModalBodyHeaderContainer,
  ModalFooter,
} from '../Modal/Modal.styled';
import { ManagedMarketplaceSaleBody } from './ManagedMarketplaceSaleBody';

type ManagedMarketplaceSaleProps = {
  cancelTo?: ModalProps;
};

export const ManagedMarketplaceSale = ({
  cancelTo,
}: ManagedMarketplaceSaleProps) => {
  const { posEntity, event, posEntityDisplayId, posEntityId } =
    useActivePosEntityContext<EntityWithRealTickets>();
  const listing =
    posEntity?.entityType === ActionOutboxEntityType.Listing
      ? (posEntity as ListingDetails)
      : undefined;
  const sale =
    posEntity?.entityType === ActionOutboxEntityType.Sale
      ? (posEntity as SaleDetails)
      : undefined;

  const methods = useForm<PosManagedMarketplaceSaleInput>({
    defaultValues:
      listing?.tickets?.length && event
        ? getPosManagedMarketplaceSaleInputFromListing(listing, event)
        : sale?.tickets?.length && event
        ? getPosManagedMarketplaceSaleInputFromSale(sale, event)
        : undefined,
  });

  useEffect(() => {
    if (
      methods.formState.defaultValues === undefined &&
      (listing?.tickets?.length || sale?.tickets?.length) &&
      event
    ) {
      methods.reset(
        listing?.tickets?.length
          ? getPosManagedMarketplaceSaleInputFromListing(listing, event)
          : getPosManagedMarketplaceSaleInputFromSale(sale!, event)
      );
    }
  }, [sale, methods, event, listing]);

  return (
    <FormProvider {...methods}>
      <ManagedSaleContent
        {...methods}
        cancelTo={cancelTo}
        listing={listing}
        sale={sale}
        posEntityDisplayId={posEntityDisplayId ?? posEntityId?.toString()}
        event={event}
      />
    </FormProvider>
  );
};

export const ManagedSaleContent = ({
  listing,
  sale,
  posEntityDisplayId,
  event,
  cancelTo,
  formState,
  handleSubmit,
  watch,
  getValues,
  setError,
}: Omit<
  ComponentProps<typeof FormProvider<PosManagedMarketplaceSaleInput, unknown>>,
  'children'
> &
  ManagedMarketplaceSaleProps & {
    listing?: ListingDetails;
    sale?: SaleDetails;
    posEntityDisplayId?: string;
    event?: Event | null;
  }) => {
  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { setModal, closeModal } = useContext(ModalContext);
  const { setActivePosEntity } = useActivePosEntityContext();

  const { isLoading: entityIsLoading, loadingState } =
    useEventItemLoadingDisplay<SaleDetails>(
      FormatContentId.LoadingSaleId,
      FormatContentId.SearchingForSaleId,
      FormatContentId.CouldNotFindSaleId,
      posEntityDisplayId
    );

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (saleFormValue: PosManagedMarketplaceSaleInput) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const client = new SaleClient(activeAccountWebClientConfig);

          const r = await client.mergePosManagedMarketplaceSale(saleFormValue);

          if (isSuccess(r)) {
            if (sale) {
              await setActivePosEntity(sale.id, sale.idOnMkp, true);
            } else if (r.entityId) {
              navigate(getSaleDetailsRelativeUrl(r.entityId));
            }
            if (cancelTo) {
              setModal(cancelTo);
            } else {
              closeModal(true);
            }
          } else {
            showErrorDialog(
              'SaleClient.mergePosManagedMarketplaceSale',
              {
                message: r.message ?? genericError,
                status: r.status!,
              } as ApiException,
              {
                trackErrorData: saleFormValue,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('SaleClient.mergePosManagedMarketplaceSale', error, {
            trackErrorData: saleFormValue,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      cancelTo,
      closeModal,
      genericError,
      navigate,
      sale,
      setActivePosEntity,
      setModal,
      showErrorDialog,
    ]
  );

  const hasChanges = useCallback(
    (saleFormValue: PosManagedMarketplaceSaleInput) => {
      if (
        hasChanged(
          saleFormValue.saleStatus,
          formState.defaultValues?.saleStatus
        ) ||
        hasChanged(
          saleFormValue.buyerEmail,
          formState.defaultValues?.buyerEmail
        ) ||
        hasChanged(
          saleFormValue.buyerName,
          formState.defaultValues?.buyerName
        ) ||
        hasChanged(
          saleFormValue.buyerPhone,
          formState.defaultValues?.buyerPhone
        ) ||
        hasChanged(
          saleFormValue.currencyCode,
          formState.defaultValues?.currencyCode
        ) ||
        hasChanged(
          saleFormValue.inHandDate,
          formState.defaultValues?.inHandDate
        ) ||
        hasChanged(
          saleFormValue.quantitySold,
          formState.defaultValues?.quantitySold
        ) ||
        hasChanged(saleFormValue.saleDate, formState.defaultValues?.saleDate) ||
        hasChanged(saleFormValue.section, formState.defaultValues?.section) ||
        hasChanged(saleFormValue.row, formState.defaultValues?.row) ||
        hasChanged(saleFormValue.seatFrom, formState.defaultValues?.seatFrom) ||
        hasChanged(saleFormValue.seatTo, formState.defaultValues?.seatTo) ||
        hasChanged(
          saleFormValue.ticketType,
          formState.defaultValues?.ticketType
        ) ||
        hasChanged(
          saleFormValue.totalNetProceeds,
          formState.defaultValues?.totalNetProceeds
        ) ||
        hasChanged(
          saleFormValue.internalNotes,
          formState.defaultValues?.internalNotes
        ) ||
        hasChanged(
          saleFormValue.paymentState,
          formState.defaultValues?.paymentState
        ) ||
        hasChanged(
          saleFormValue.fulfillerSellerUserId,
          formState.defaultValues?.fulfillerSellerUserId
        )
      ) {
        return true;
      }
      return false;
    },
    [formState.defaultValues]
  );

  const requiredMsg = useContent(ContentId.Required);
  const inhandDateMustBeforeEventDate = useContent(
    ContentId.InHandDateMustBeBeforeEventDate
  );

  const onSubmitHandler = useCallback(() => {
    const formValues = getValues();
    if (!hasChanges(formValues)) return;

    if (!formValues.saleDate) {
      setError('saleDate', { message: requiredMsg }, { shouldFocus: true });
    }

    if (!formValues.totalNetProceeds?.value) {
      formValues.totalNetProceeds = posChangedField(0);
    }

    if (!formValues.inHandDate?.value) {
      setError('inHandDate', { message: requiredMsg }, { shouldFocus: true });
    } else if (
      new Date(formValues.inHandDate.value) >= new Date(event!.dates.start!)
    ) {
      setError(
        'inHandDate',
        { message: inhandDateMustBeforeEventDate },
        { shouldFocus: true }
      );
    }
    if ((formValues.quantitySold?.value ?? 0) <= 0) {
      setError('quantitySold', { message: requiredMsg }, { shouldFocus: true });
    }

    if (listing?.tickets?.find((t) => !!t.seat)) {
      // If the listing tickets has seats - so better the sale
      if (!formValues.seatFrom?.value || !formValues.seatTo?.value) {
        setError('seatFrom', { message: requiredMsg }, { shouldFocus: true });
      }
    }

    handleSubmit(onSubmit)();
  }, [
    getValues,
    hasChanges,
    event,
    listing?.tickets,
    handleSubmit,
    onSubmit,
    setError,
    requiredMsg,
    inhandDateMustBeforeEventDate,
  ]);

  if (!sale && !listing) {
    // If we're looking for an active Sale, and there isn't one, return loading state
    return loadingState;
  }

  const formHasChanges = hasChanges(watch());

  return (
    <>
      <CancellableFormHeader
        cancelTo={cancelTo}
        disabled={isLoading || formState.isSubmitting}
        showDialogOnCancel={formHasChanges}
      >
        <ConnectedEventEntityHeader
          title={
            <>
              <Content
                id={sale ? ContentId.ViewDetails : ContentId.AddOfflineSale}
              />
            </>
          }
        />
      </CancellableFormHeader>

      <ModalBody>
        <div className={modalDetails}>
          {event && (listing || sale) && (
            <ModalBodyHeaderContainer>
              <Summary event={event} posEntity={sale || listing} />
            </ModalBodyHeaderContainer>
          )}
          {entityIsLoading || !(listing || sale) ? (
            <PosSpinner />
          ) : (
            <ManagedMarketplaceSaleBody
              disabled={isLoading}
              listing={listing}
              sale={sale}
              event={event}
            />
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          cancelTo={cancelTo}
          disabled={isLoading || entityIsLoading}
          showDialogOnCancel={formHasChanges}
        >
          <OkButton
            onClick={onSubmitHandler}
            disabled={isLoading || entityIsLoading || !formHasChanges}
            textContentId={ContentId.Save}
          />
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
