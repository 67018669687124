import { Content } from 'src/contexts/ContentContext';
import { PosPill } from 'src/core/POS/PosPill';
import { PosPillVariant } from 'src/core/POS/PosPill/PosPill';
import { ContentId } from 'src/utils/constants/contentId';

export type type = 'custom' | 'system';

interface AccountTypeProps {
  type: type;
}

export const AccountType = ({ type }: AccountTypeProps) => {
  return (
    <PosPill
      variant={
        type === 'system' ? PosPillVariant.Brand : PosPillVariant.LightGreen
      }
    >
      <Content id={type === 'system' ? ContentId.System : ContentId.Custom} />
    </PosPill>
  );
};
