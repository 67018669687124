import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Button, Stack } from 'src/core/ui';
import { useListingHasVenueMapInfo } from 'src/hooks/useListingHasVenueMapInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ListingTab } from 'src/modals/ListingDetails/components/listingDetailsUtils';
import { useHasListingPricePermissions } from 'src/modals/ListingDetails/components/useHasListingPricePermissions';
import { ContentId } from 'src/utils/constants/contentId';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import { Feature, Listing, Marketplace } from 'src/WebApiController';

import { AutoPricingStrategySection } from '../../Sections/AutoPricingStrategySection';
import { ListingAutoPricingSettingsFormContent } from '../ListingAutoPricingSettingsFormContent/ListingAutoPricingSettingsFormContent';

export const ListingAutoPricingSettingsFormContentWrapper = ({
  listing,
  isBulkEdit,
  isGlobalEdit,
  disabled,
  onSwitchToTab,
}: {
  listing: Listing | null | undefined;
  isBulkEdit?: boolean;
  isGlobalEdit?: boolean;
  disabled: boolean;
  onSwitchToTab?: (tabName: ListingTab) => void;
}) => {
  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();
  const stubHubMkpSettings =
    loginContext?.user?.activeAccount?.marketplaceSettings?.find(
      (m) => m.mkp === Marketplace.StubHub
    );

  const uiCurrency = useMemo(
    () =>
      getUiCurrency(
        listing?.currency ?? loginContext?.user?.activeAccount?.currencyCode
      ),
    [
      getUiCurrency,
      listing?.currency,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );

  const { canAutoPrice } = useHasListingPricePermissions(listing, isBulkEdit);
  const { hasVenueMapInfo, mapExists } = useListingHasVenueMapInfo(listing);

  const { activeConfigOverride, venueMapInfo, venueMapsByScoreModelQuery } =
    useEventMapContext();

  const defaultScoreOverrides = useMemo(() => {
    // In the backend, if AVG scores are missing, then we always fallback to MVG, even if AVG is explicitly selected
    return (
      venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores
    );
  }, [
    venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores,
  ]);

  const scores = getCompleteEventConfigScoreOverrides(
    defaultScoreOverrides,
    activeConfigOverride?.scoreOverrides,
    false
  );

  const hasAllowSeatScoreCompListingModeFeature = useUserHasFeature(
    Feature.AllowSeatScoreCompListingMode
  );

  return (isBulkEdit || hasVenueMapInfo) && canAutoPrice ? (
    <ListingAutoPricingSettingsFormContent
      listing={listing}
      venueMapSections={venueMapInfo?.sections}
      hasSeatScores={Boolean(scores?.some((s) => s.score))}
      sellerFee={stubHubMkpSettings?.sellerFee}
      uiCurrency={uiCurrency}
      allowSeatScoreCompListingMode={hasAllowSeatScoreCompListingModeFeature}
      isGlobalEdit={isGlobalEdit}
      disabled={disabled}
      autoPricingStrategySection={
        <AutoPricingStrategySection
          listing={listing}
          disabled={disabled}
          isMarketListingsView
        />
      }
    />
  ) : (
    <Stack direction="column" gap="l">
      {!canAutoPrice ? (
        <Content id={ContentId.NoAutoPricing_MissingPermission} />
      ) : mapExists && !hasVenueMapInfo ? (
        <>
          <Content id={ContentId.NoAutoPricing_MissingRowId} />

          <Button
            variant="link"
            onClick={() => onSwitchToTab?.(ListingTab.Broadcast)}
            style={{ width: 'max-content', padding: '0' }}
          >
            <Content id={ContentId.UpdateSeatingInformation} />
          </Button>
        </>
      ) : (
        <Content id={ContentId.NoAutoPricing_NoVenueConfig} />
      )}
    </Stack>
  );
};
