import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { getSeatDisplayText } from 'src/components/common/SeatingInfo';
import { AdminHoldBadge } from 'src/components/Listings/AdminHoldBadge';
import {
  RowSelector,
  SectionSelector,
} from 'src/components/Selectors/SectionRowSelector';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import {
  Content,
  getContent,
  useContentContext,
  useFormattedContent,
} from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Switch } from 'src/core/interim/Switch';
import { ErrorIconMessage } from 'src/core/POS/ErrorIconMessage';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Popover, SimpleTable, Stack } from 'src/core/ui';
import { TooltipPopover } from 'src/core/ui/TooltipPopover';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CrossIcon, WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  BROADCAST_ERROR_CODE_TO_CID,
  LISTING_STATUS_TO_CID,
  PENDING_PROOF_STATE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getListingBroadcastIcon } from 'src/utils/inventoryUtils';
import {
  Feature,
  ListingBroadcastStatus,
  ListingDetails,
  ListingDetailsBroadcastSectionUpdates,
  ListingStatus,
  Marketplace,
  MarketplaceListing,
  PendingProofState,
  QualityControlState,
} from 'src/WebApiController';

import * as styles from './MarketplaceDisplay.css';
import { MarketplaceFailure } from './useGetMarketplaceFailures';
export const MarketplaceDisplay = ({
  marketplaceListing: ml,
  formIndex,
  status,
  setListingStatus,
  isUKEvent,
  isViagogo,
  isDeleted,
  disabled,
  onListingBroadcastToggle,
  error,
  qcState,
}: {
  marketplaceListing: MarketplaceListing;
  formIndex: number;
  status: ListingStatus;
  setListingStatus: (
    marketplace: Marketplace,
    newListingStatus: ListingStatus
  ) => void;
  isUKEvent?: boolean;
  isViagogo?: boolean;
  isDeleted?: boolean;
  disabled?: boolean;
  onListingBroadcastToggle: (
    isBroadcasting: boolean,
    marketplace: Marketplace
  ) => void;
  error?: MarketplaceFailure | null;
  qcState?: QualityControlState | null;
}) => {
  const alwaysShowAsDelisted =
    isUKEvent && ml.mkp === Marketplace.StubHub && !isViagogo;
  const { event, posEntity: listing } =
    useActivePosEntityContext<ListingDetails>();
  const noListingQty = listing ? listing.availQty === 0 : true;

  const contentContext = useContentContext();
  const { venueMapQuery } = useEventMapContext();

  const { register, watch, setValue } =
    useFormContext<ListingDetailsBroadcastSectionUpdates>();

  const marketplaceSeatOverrides = watch('marketplaceSeatOverrides');

  const { overrideSection, overrideRow, overrideSeats } =
    marketplaceSeatOverrides[formIndex] ?? {};

  const { selectedSection, selectedRow } = useMemo(() => {
    const section = venueMapQuery.data?.sections.find(
      (s) => s.name === overrideSection
    );
    const row = section?.rows.find((r) => r.name === overrideRow);

    return { selectedSection: section, selectedRow: row };
  }, [overrideRow, overrideSection, venueMapQuery.data?.sections]);

  const isListed = !alwaysShowAsDelisted && status === ListingStatus.Listed;

  const isChecked =
    !alwaysShowAsDelisted &&
    (status === ListingStatus.Listed ||
      status === ListingStatus.ListingPending ||
      status === ListingStatus.DelistingFailed ||
      ml.brdcstStatus === ListingBroadcastStatus.BroadcastWithError);

  const hasBroadcastErrors =
    ml.status === ListingStatus.ListingFailed ||
    ml.status === ListingStatus.DelistingFailed;

  const { iconComponent: ListingBroadcastIcon, contentId } =
    getListingBroadcastIcon(
      alwaysShowAsDelisted
        ? ListingStatus.Delisted // We always show Delisted for StubHub when event is in UK
        : status,
      qcState,
      false,
      true,
      listing?.isUndeliv,
      ml.brdcstStatus,
      ml.pendProofState
    );

  const disabledDueToPendingQC =
    ml.status !== ListingStatus.Listed &&
    qcState === QualityControlState.Pending;

  const pendingProofErrorMessage = useFormattedContent(
    FormatContentId.ProofOfPurchasedRequiredDetailMessage,
    [isViagogo ? 'Viagogo' : ml.mkp]
  ) as string;

  const hasUseSelectedRowIdFeature = useUserHasFeature(
    Feature.UseSelectedRowIdForMarketplaceSeatingOverride
  );

  const listingStatusCID = useMemo(() => {
    if (listing?.isAdminHold) {
      return ContentId.OnHoldBroadcastDisabled;
    }

    if (disabledDueToPendingQC) {
      return contentId ?? ContentId.PendingQC;
    }

    if (listing?.isUndeliv) {
      return contentId ?? ContentId.Undeliverable;
    }

    if (
      ml.pendProofState === PendingProofState.PendingProof ||
      ml.pendProofState === PendingProofState.PendingReview
    ) {
      return PENDING_PROOF_STATE_TO_CID[ml.pendProofState];
    }

    if (ml.brdcstStatus === ListingBroadcastStatus.BroadcastWithError) {
      return ContentId.ListedSyncFailed;
    }
    if (ml.brdcstStatus === ListingBroadcastStatus.UnbroadcastWithError) {
      return ContentId.Delisted;
    }

    const santitizedStatus =
      ml.errMsgs != null && ml.errMsgs.length > 0
        ? status
        : alwaysShowAsDelisted
        ? status === ListingStatus.Disabled
          ? status
          : ListingStatus.Delisted // We always show Delisted for StubHub when event is in UK
        : status;
    return LISTING_STATUS_TO_CID[santitizedStatus];
  }, [
    alwaysShowAsDelisted,
    contentId,
    disabledDueToPendingQC,
    listing?.isAdminHold,
    listing?.isUndeliv,
    ml.brdcstStatus,
    ml.errMsgs,
    ml.pendProofState,
    status,
  ]);

  const listingStatusCell = (
    <>
      {listing?.isAdminHold ? (
        <AdminHoldBadge
          adminHoldExpirationDate={listing?.adminHoldExpiredOn}
          adminHoldNotes={listing?.adminHoldNotes}
          showDetailOnHover
          iconOnlyMode
        />
      ) : (
        <ListingBroadcastIcon
          size={vars.iconSize.m}
          stroke={disabledDueToPendingQC ? vars.color.textPrimary : undefined}
        />
      )}
      <span>
        <Content id={listingStatusCID} />
      </span>
    </>
  );

  const hasVenueMapInfo = useMemo(() => {
    if (listing?.seating?.rowId) {
      return true;
    }

    const foundSection = venueMapQuery.data?.sections.find(
      (s) => s.name === listing?.seating.section
    );

    if (foundSection) {
      return true;
    }

    const foundOverrideSection = venueMapQuery.data?.sections.find(
      (s) => s.name === overrideSection
    );

    if (foundOverrideSection) {
      return true;
    }

    return false;
  }, [
    listing?.seating?.rowId,
    listing?.seating.section,
    overrideSection,
    venueMapQuery.data?.sections,
  ]);

  const errorMessages = useMemo(() => {
    let errorMessages: string[] = [];

    // Collect all the error messages if there are supposed to be errors
    if (hasBroadcastErrors) {
      errorMessages = error?.messages?.filter((m) => m)?.map((m) => m!) ?? [];

      if (ml.errCodes) {
        ml.errCodes.forEach((err) => {
          const message = getContent(
            BROADCAST_ERROR_CODE_TO_CID[err],
            contentContext
          );
          errorMessages.push(message);
        });
      }

      errorMessages = [...new Set(errorMessages)]; // filter out dups
    }

    if (
      ml.pendProofState === PendingProofState.PendingProof ||
      ml.pendProofState === PendingProofState.PendingReview
    ) {
      errorMessages.push(pendingProofErrorMessage);
    }

    return errorMessages;
  }, [
    contentContext,
    error?.messages,
    hasBroadcastErrors,
    ml.errCodes,
    ml.pendProofState,
    pendingProofErrorMessage,
  ]);

  return (
    <SimpleTable.Tr
      className={styles.broadcastRow}
      key={`${ml.mkpListingId}-${ml.mkp}-${isViagogo}`}
    >
      <SimpleTable.Td className={styles.broadcastCell}>
        <MarketplaceLogo
          marketplace={ml.mkp}
          event={event!}
          isViagogo={isViagogo}
        />
      </SimpleTable.Td>
      <SimpleTable.Td
        className={styles.broadcastCell}
        title={disabledDueToPendingQC ? contentId : undefined}
      >
        <Switch
          checked={noListingQty || listing?.isAdminHold ? false : isChecked}
          disabled={
            disabled ||
            isDeleted ||
            noListingQty ||
            (!isChecked && !(ml.isEnabled ?? true)) ||
            disabledDueToPendingQC ||
            listing?.isAdminHold
          }
          onChange={() => {
            if (ml && !isDeleted) {
              const newStatus = isChecked
                ? ListingStatus.DelistingPending
                : ListingStatus.ListingPending;
              setListingStatus(ml.mkp!, newStatus);
              onListingBroadcastToggle(!isChecked, ml.mkp!);
            }
          }}
        />
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.broadcastCell}>
        <div className={styles.broadcastCellData}>
          {errorMessages.length ? (
            <TooltipPopover
              variant="link"
              triggerContent={listingStatusCell}
              title={errorMessages[0]}
            >
              <ErrorIconMessage className={styles.broadcastErrorMessage}>
                {errorMessages.map((m, i) => (
                  <span key={i}>{m}</span>
                ))}
              </ErrorIconMessage>
            </TooltipPopover>
          ) : (
            listingStatusCell
          )}
        </div>
      </SimpleTable.Td>

      <SimpleTable.Td className={`${styles.broadcastCell}`}>
        <Stack direction="row" gap="s" alignItems="center">
          {ml.mkp === Marketplace.StubHub &&
            Boolean(venueMapQuery.data?.sections?.length) &&
            !hasVenueMapInfo && (
              <TooltipPopover
                variant="link"
                contentVariant="dark"
                triggerContent={
                  <WarningOutlineIcon
                    fill={'#FD9927'}
                    size={vars.iconSize.m}
                    withHoverEffect
                  />
                }
              >
                <Content id={ContentId.SeatingInfoNotMatchStubHub} />
              </TooltipPopover>
            )}
          <TooltipPopover
            variant="link"
            triggerContent={
              <div className={styles.sectionCell}>
                <span
                  className={
                    overrideSection
                      ? styles.overrideActive
                      : styles.overrideInactive
                  }
                >
                  {overrideSection || listing?.seating.section || (
                    <Content id={ContentId.Edit} />
                  )}
                </span>
              </div>
            }
          >
            {venueMapQuery.data?.sections?.length ? (
              <SectionSelector
                style={{ width: '100%' }}
                value={selectedSection?.id?.toString() ?? '-1'}
                displayText={overrideSection ?? ContentId.SearchOrEnter}
                sections={venueMapQuery.data?.sections}
                enableEmptySelection
                onChange={(selectedSectionId) => {
                  const id = parseInt(selectedSectionId);
                  const section = venueMapQuery.data?.sections?.find(
                    (s) => s.id === id
                  );

                  setValue(
                    `marketplaceSeatOverrides.${formIndex}.overrideSection`,
                    section?.name ?? selectedSectionId
                  );

                  if (section?.id) {
                    setValue(
                      `marketplaceSeatOverrides.${formIndex}.overrideSectionId`,
                      section?.id
                    );
                  }

                  if (hasUseSelectedRowIdFeature && overrideRow) {
                    const row = section?.rows?.find(
                      (r) => r.name === overrideRow
                    );
                    if (row?.id) {
                      setValue(
                        `marketplaceSeatOverrides.${formIndex}.overrideRowId`,
                        row?.id
                      );
                    }
                  }
                }}
              />
            ) : (
              <PosTextField
                {...register(
                  `marketplaceSeatOverrides.${formIndex}.overrideSection`
                )}
                postfixDisplay={
                  <Popover.Close asChild>
                    <CrossIcon withHoverEffect />
                  </Popover.Close>
                }
              />
            )}
          </TooltipPopover>
        </Stack>
      </SimpleTable.Td>
      <SimpleTable.Td className={`${styles.broadcastCell}`}>
        <TooltipPopover
          variant="link"
          triggerContent={
            <span
              className={
                overrideRow ? styles.overrideActive : styles.overrideInactive
              }
            >
              {overrideRow || listing?.seating.row || (
                <Content id={ContentId.Edit} />
              )}
            </span>
          }
        >
          {selectedSection?.rows?.length ? (
            <RowSelector
              style={{ width: '100%' }}
              value={selectedRow?.id?.toString() ?? '-1'}
              displayText={overrideRow || ContentId.SearchOrEnter}
              selectedSection={selectedSection}
              onChange={(rowId) => {
                const id = parseInt(rowId);
                const row = selectedSection?.rows?.find((r) => r.id === id);

                setValue(
                  `marketplaceSeatOverrides.${formIndex}.overrideRow`,
                  (row?.isSpeculative ? '' : row?.name) ?? rowId
                );

                if (row?.id) {
                  setValue(
                    `marketplaceSeatOverrides.${formIndex}.overrideRowId`,
                    row?.id
                  );
                }
              }}
            />
          ) : (
            <PosTextField
              {...register(`marketplaceSeatOverrides.${formIndex}.overrideRow`)}
              postfixDisplay={
                <Popover.Close asChild>
                  <CrossIcon withHoverEffect />
                </Popover.Close>
              }
            />
          )}
        </TooltipPopover>
      </SimpleTable.Td>
      <SimpleTable.Td className={`${styles.broadcastCell}`}>
        <TooltipPopover
          variant="link"
          triggerContent={
            <span
              className={
                overrideSeats ? styles.overrideActive : styles.overrideInactive
              }
            >
              {overrideSeats ||
                getSeatDisplayText({
                  seatFr: listing?.seating.seatFr,
                  seatTo: listing?.seating.seatTo,
                  compact: true,
                }) || <Content id={ContentId.Edit} />}
            </span>
          }
        >
          <PosTextField
            {...register(`marketplaceSeatOverrides.${formIndex}.overrideSeats`)}
            postfixDisplay={
              <Popover.Close asChild>
                <CrossIcon withHoverEffect />
              </Popover.Close>
            }
          />
        </TooltipPopover>
      </SimpleTable.Td>

      <SimpleTable.Td className={styles.broadcastCell}>
        {isListed && ml.mkpListingId ? (
          ml.mkpListingId
        ) : (
          <Content id={ContentId.NA} />
        )}
      </SimpleTable.Td>
    </SimpleTable.Tr>
  );
};
