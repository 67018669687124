import { format } from 'date-fns';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { MarketplacePaymentStatePill } from 'src/components/common/MarketplacePaymentStatePill/MarketplacePaymentStatePill';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { MarketplaceSalePayment } from 'src/WebApiController';

import * as styles from './MarketplacePaymentHeaderDetails.css';

interface MarketplacePaymentHeaderDetailsProps {
  marketplaceSalePayment: MarketplaceSalePayment;
}

export const MarketplacePaymentHeaderDetails = ({
  marketplaceSalePayment,
}: MarketplacePaymentHeaderDetailsProps) => {
  return (
    <div className={styles.wrapper}>
      <Stack justifyContent="spaceBetween" width="full">
        <div>
          <span className={styles.title}>
            <Content id={ContentId.PaymentId} />:
          </span>
          <span>{marketplaceSalePayment.extPmtId}</span>
        </div>
        <Stack alignItems="center" gap="m">
          <MarketplacePaymentStatePill state={marketplaceSalePayment.state} />
          <MarketplaceLogo marketplace={marketplaceSalePayment.mkp} />
        </Stack>
      </Stack>
      {marketplaceSalePayment.pmtDate && (
        <div>
          <span className={styles.title}>
            <Content id={ContentId.PaymentDate} />:
          </span>
          <span>
            {format(new Date(marketplaceSalePayment.pmtDate), 'do LLL yyyy')}
          </span>
        </div>
      )}
    </div>
  );
};
