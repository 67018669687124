import { Fragment } from 'react';
import { LinkAndCopy } from 'src/components/common/LinkAndCopy';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { SaleMarketplaceIdDisplay } from 'src/components/Sales/SaleMarketplaceIdDisplay';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { Detail, DetailSection, SectionContent } from 'src/modals/common';
import * as styles from 'src/modals/ListingDetails/ListingForms/SalesForm/SalesForm.css';
import { SeatIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getSaleDetailsRelativeUrl,
  getSaleStatusDisplayContentId,
} from 'src/utils/saleUtils';
import { SaleDisplayInfo, Seating, Ticket } from 'src/WebApiController';

interface SalesDetailsSectionProps {
  ticketsBySalesId: Record<string, Ticket[]> | undefined;
  saleInfos: SaleDisplayInfo[] | undefined;
  seating?: Seating | null;
  sectionName: ContentId;
}

export const SalesDetailsSection = ({
  ticketsBySalesId,
  seating,
  saleInfos,
  sectionName,
}: SalesDetailsSectionProps) => {
  return (
    <DetailSection name={<Content id={sectionName} />}>
      <SectionContent numOfColumns={3}>
        {saleInfos?.map((s) => {
          const { id, externalId, idOnMkp, mkp, stat } = s;
          const saleTickets = ticketsBySalesId?.[id];

          return (
            <Fragment key={id}>
              <Detail
                label={<Content id={ContentId.SaleId} />}
                detail={
                  <LinkAndCopy
                    relativeUrl={getSaleDetailsRelativeUrl(id!)}
                    valueToCopy={externalId || id}
                    copyIconSize={vars.iconSize.m}
                    linkChildren={
                      <SaleMarketplaceIdDisplay
                        saleId={id}
                        marketplaceSaleId={idOnMkp}
                        marketplace={mkp}
                        textAlign="left"
                        classNames={styles.linkStyle}
                      />
                    }
                  />
                }
              />
              <Detail
                label={<Content id={ContentId.SaleStatus} />}
                detail={
                  <div>
                    <Content id={getSaleStatusDisplayContentId(stat)} />
                  </div>
                }
              />
              <Detail
                label={<Content id={ContentId.Seating} />}
                detail={
                  <div>
                    {saleTickets?.map((ticket) => {
                      return (
                        <Stack key={ticket.id} gap="m">
                          <SeatIcon />
                          <SeatingInfo
                            seatFr={ticket.seat}
                            seatTo={null}
                            row={ticket.row}
                            section={seating?.section ?? ''}
                          />
                        </Stack>
                      );
                    })}
                  </div>
                }
              />
            </Fragment>
          );
        })}
      </SectionContent>
    </DetailSection>
  );
};
