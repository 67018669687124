import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  getFormattedContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { Button } from 'src/core/ui';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { AttrLabel, DetailSection } from 'src/modals/common';
import { modalDetails } from 'src/modals/common/Modals.css';
import { TicketsSection } from 'src/modals/SaleDetails/components/TicketsSection';
import { GenericFeatureIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { formatSeatDisplay } from 'src/utils/ticketUtils';
import {
  Feature,
  SaleClient,
  SaleDetails,
  Seating,
  SeatingForAlloc,
} from 'src/WebApiController';

import * as styles from './SeatingAllocationInput.css';
import {
  AllocationSeatTable,
  NoAllocationContainer,
} from './SeatingAllocationInput.styled';

export const SeatingAllocationInput = ({
  originalSeating,
  onlyShowSeatingAllocation,
}: {
  originalSeating: Seating;
  onlyShowSeatingAllocation?: boolean;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { getValues, setValue, watch } = useFormContext<SaleDetails>();

  const [allPossibleSeatings, setAllPossibleSeatings] = useState(false);

  const sale = getValues();
  const seating = watch('seating');

  const ticketsForAllocationQuery = useQuery({
    queryKey: [
      'SaleClient.getSeatingsForSaleAllocation',
      sale.id,
      originalSeating,
      allPossibleSeatings,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      const client = new SaleClient(activeAccountWebClientConfig);
      const data = await client.getSeatingsForSaleAllocation(
        sale.id,
        allPossibleSeatings
      );
      return data;
    },
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('SaleClient.getSeatingsForSaleAllocation', error, {
          trackErrorData: {
            saleId: sale.id,
            allPossibleSeatings: allPossibleSeatings,
          },
        });
      },
    },
  });

  if (ticketsForAllocationQuery.failureReason) {
    showErrorDialog(
      'SaleClient.getSeatingsForSaleAllocation',
      { message: ticketsForAllocationQuery.failureReason.message },
      {
        trackErrorData: {
          saleId: sale.id,
          allPossibleSeatings: allPossibleSeatings,
        },
      }
    );
  }

  const possibleSeatings = useMemo(() => {
    const possibleSeatings = ticketsForAllocationQuery.data?.possibleSeatings;
    if (!possibleSeatings) {
      return possibleSeatings;
    }

    const possibleSeatingsConsecutive = possibleSeatings.filter(
      (seating) => !seating.nonConsecSeats
    );

    const possibleSeatingsNonConsecutive = possibleSeatings.filter(
      (seating) => seating.nonConsecSeats
    );

    return [...possibleSeatingsConsecutive, ...possibleSeatingsNonConsecutive];
  }, [ticketsForAllocationQuery.data?.possibleSeatings]);

  const getIndexOf = useCallback(
    (seating: Seating) => {
      let index = possibleSeatings?.findIndex((s) =>
        isEqual(s.ticketIds?.sort(), seating.ticketIds?.sort())
      );

      if (index == null || index < 0) {
        // If we don't have any selected...
        if (possibleSeatings?.length) {
          const newS = possibleSeatings.some((s, i) => {
            if (s.recAlloc) {
              // ...auto-select the first recommended one if there is one
              setValue('seating', s);
              index = i;

              // break
              return true;
            }

            return false;
          });

          if (!newS && !sale.isSeatSaver) {
            // if we still have nothing, just select the first one
            setValue('seating', possibleSeatings[0]);
            index = 0;
          }
        }
      }

      return index;
    },
    [possibleSeatings, sale.isSeatSaver, setValue]
  );

  return (
    <div className={modalDetails}>
      <div className={styles.seatAllocationContainer}>
        {!onlyShowSeatingAllocation && (
          <>
            <h5>
              <Content id={ContentId.AllocateTickets} />
            </h5>
            <div className={styles.seatAllocationInstructions}>
              <Content id={ContentId.WhatSeatToFulfill} />
            </div>
            <DetailSection name={<Content id={ContentId.SoldAs} />}>
              <TicketsSection
                {...originalSeating}
                quantity={sale!.qtySold}
                listingId={sale!.listingId}
              />
            </DetailSection>
          </>
        )}
        <DetailSection name={<Content id={ContentId.Allocate} />}>
          <RadioGroup
            onValueChange={(value) => {
              const selectedIndex = parseInt(value);
              const newSeating = possibleSeatings?.[selectedIndex];

              if (newSeating) {
                setValue('seating', newSeating);
              }
            }}
            value={getIndexOf(seating)?.toString()}
          >
            {possibleSeatings ? (
              possibleSeatings.length ? (
                <>
                  <AllocationSeatTable>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>
                          <AttrLabel>
                            <Content id={ContentId.Section} />
                          </AttrLabel>
                        </th>
                        <th>
                          <AttrLabel>
                            <Content id={ContentId.Row} />
                          </AttrLabel>
                        </th>
                        <th>
                          <AttrLabel>
                            <Content id={ContentId.Seats} />
                          </AttrLabel>
                        </th>
                        <th>
                          <AttrLabel>
                            <Content id={ContentId.PurchasedBy} />
                          </AttrLabel>
                        </th>
                        <th>
                          <AttrLabel>
                            <Content id={ContentId.Vendor} />
                          </AttrLabel>
                        </th>
                        <th>
                          <AttrLabel>
                            <Content id={ContentId.VendorAccount} />
                          </AttrLabel>
                        </th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    {possibleSeatings.map((seating, index) => (
                      <PossibleSeatingTableRow
                        key={index}
                        index={index}
                        seating={seating}
                      />
                    ))}
                  </AllocationSeatTable>
                  {ticketsForAllocationQuery.data?.hasMoreAllocations && (
                    <div className={styles.allocationActionsContainer}>
                      <Button
                        variant="link"
                        onClick={() =>
                          setAllPossibleSeatings(!allPossibleSeatings)
                        }
                      >
                        <Content
                          id={
                            allPossibleSeatings
                              ? ContentId.AllocateToListingTickets
                              : ContentId.AllocateToDifferentTickets
                          }
                        />
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <NoAllocationContainer>
                  <Content id={ContentId.NoSeatingsAvailableForAllocation} />
                  <NavLink
                    title={getPurchaseOrderRelativeUrl(0, sale.viagVirtualId)}
                    tag={Link}
                    to={getPurchaseOrderRelativeUrl(0, sale.viagVirtualId)}
                  >
                    <Content id={ContentId.AddPurchase} />
                  </NavLink>
                </NoAllocationContainer>
              )
            ) : (
              <PosSpinner size={vars.iconSize.xxs} />
            )}
          </RadioGroup>
        </DetailSection>
      </div>
    </div>
  );
};

const PossibleSeatingTableRow = ({
  seating,
  index,
}: {
  seating: SeatingForAlloc;
  index: number;
}) => {
  const recommended = useContent(ContentId.Recommended);
  const contentContext = useContentContext();
  const deactivatedText = useContent(ContentId.Deactivated);
  const hasDisplayDeactivatedUser = useUserHasFeature(
    Feature.DisplayDeactivatedUser
  );

  const {
    section,
    row,
    seatFr,
    seatTo,
    recAlloc,
    nonConsecSeats,
    tktsForAlloc,
  } = seating;

  const purchasedByUsers = useGetUserInfos([
    ...new Set(
      tktsForAlloc
        ?.filter((t) => t.purchasedBy != null)
        ?.map((t) => t.purchasedBy!)
    ),
  ]);

  const { availableVendors } = usePurchaseVendorSelector({});

  const vendorNames = useMemo(() => {
    const missingVendorNameIds = new Set(
      tktsForAlloc
        .filter((t) => !t.vendorName && t.vendorId)
        .map((t) => t.vendorId!)
    );

    const vendorNames = [...missingVendorNameIds]
      .map((id) => availableVendors[id]?.name)
      .filter((n) => n)
      .map((n) => n!);

    const uniqueNames = new Set([
      ...vendorNames,
      ...tktsForAlloc.filter((t) => t.vendorName).map((t) => t.vendorName!),
    ]);

    return [...uniqueNames];
  }, [availableVendors, tktsForAlloc]);

  const vendorAccountNames = useMemo(() => {
    const uniqueNames = new Set(
      tktsForAlloc
        .filter((t) => t.vendorAccEmail || t.vendorAccName)
        .map((t) => (t.vendorAccEmail || t.vendorAccName)!)
    );

    return [...uniqueNames];
  }, [tktsForAlloc]);

  const seatNumbersDisplay = useMemo(() => {
    const allSeatsHaveValues = tktsForAlloc?.every((t) => !!t.seat);

    if (allSeatsHaveValues) {
      return tktsForAlloc
        ?.map((t) => t.seat)
        .sort((a, b) =>
          a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
        ) // Sort alphanumerically, case insensitive. This may not follow the underlying ordinality of the seats, but for display purposes it's better
        .join(', ');
    }

    const allSeatsHaveListingOrdinal = tktsForAlloc?.every(
      (t) => t.listingOrd != null
    );

    return tktsForAlloc
      ?.sort((a, b) => {
        if (allSeatsHaveListingOrdinal) {
          return a.listingOrd! - b.listingOrd!;
        }
        return a.ticketOrd - b.ticketOrd;
      })
      .map((t) => {
        if (t.seat) {
          return t.seat;
        }

        return getFormattedContent(
          FormatContentId.SeatNumber,
          [
            (allSeatsHaveListingOrdinal
              ? t.listingOrd!
              : t.ticketOrd
            ).toString(),
          ],
          contentContext
        );
      })
      .join(', ');
  }, [contentContext, tktsForAlloc]);

  return (
    <tr key={index}>
      <td>
        <Radio value={index.toString()} label={null} />
      </td>
      <td>{section}</td>
      <td>{row}</td>
      <td>
        {nonConsecSeats ? (
          <>
            {seatNumbersDisplay ? (
              <span title={seatNumbersDisplay}>{seatNumbersDisplay}</span>
            ) : (
              <Content id={ContentId.AllocateToAnyAvailableSeats} />
            )}
          </>
        ) : (
          <>{formatSeatDisplay(seatFr, seatTo)}</>
        )}
      </td>
      <td>
        <Stack direction="column">
          {Object.values(purchasedByUsers.data ?? {}).map((user) => (
            <div key={user.id}>
              {' '}
              {`${user?.name} ${
                user?.isDeactivated && !hasDisplayDeactivatedUser
                  ? ` (${deactivatedText})`
                  : ''
              }`}
            </div>
          ))}
        </Stack>
      </td>
      <td>
        <Stack direction="column">
          {vendorNames?.map((vn) => <div key={vn}>{vn}</div>)}
        </Stack>
      </td>
      <td>
        <Stack direction="column">
          {vendorAccountNames.map((van) => (
            <div key={van}>{van}</div>
          ))}
        </Stack>
      </td>
      <td>
        {recAlloc && !nonConsecSeats && (
          <div title={recommended}>
            <GenericFeatureIcon fill={IconsFill.textBrand} />
          </div>
        )}
      </td>
    </tr>
  );
};
