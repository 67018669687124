import clsx from 'clsx';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack, StackProps, TooltipPopover } from 'src/core/ui';
import { SeatSaverIcon } from 'src/svgs';
import { AdminHoldIcon } from 'src/svgs/AdminHoldIcon';
import { ClockTrashIcon } from 'src/svgs/ClockTrashIcon';
import { IconsFill, WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './SeatingInfo.css';

export type SeatingInfoProps = {
  section: string;
  row: string | null;
  rowId?: number | null;
  seatFr: string | null;
  seatTo: string | null;
  isSeatSaver?: boolean;
  showSectionPrefix?: boolean;
  showSeatPrefix?: boolean;
  fontSize?: string;
  isDuplicate?: boolean;
  showAdminHold?: boolean;
  isAdminHold?: boolean;
  showOrgSeat?: boolean;
  orgSeatFr?: string | null;
  orgSeatTo?: string | null;
  isSoldOut?: boolean;
  justifyIconContent?: StackProps['justifyContent'];
  showUnmappedSection?: boolean;
  darkBackground?: boolean; // force dark-background
  lightBackground?: boolean; // force light-background
  isWasted?: boolean;
};

export const getSeatDisplayText = ({
  seatFr,
  seatTo,
  compact,
}: {
  seatFr: string | null | undefined;
  seatTo: string | null | undefined;
  compact?: boolean;
}) => {
  const hasSeats = !!(seatFr || seatTo);
  const isSingleSeat = hasSeats && (seatFr === seatTo || !seatTo);
  const isSeatRange = seatFr && seatTo && seatFr !== seatTo;

  let text = seatFr ?? '';
  if (isSeatRange) {
    text += compact ? '-' : ` - `;
  }
  if (!isSingleSeat) {
    text += seatTo ?? '';
  }

  return text;
};

// TODO: piggyback rows display?
export const SeatingInfo = ({
  seatFr,
  seatTo,
  section,
  row,
  rowId,
  isSeatSaver,
  showSectionPrefix,
  showSeatPrefix = true,
  fontSize,
  isDuplicate,
  showAdminHold,
  isAdminHold,
  showOrgSeat,
  orgSeatFr,
  orgSeatTo,
  isSoldOut,
  justifyIconContent,
  showUnmappedSection,
  darkBackground,
  lightBackground,
  isWasted,
}: SeatingInfoProps) => {
  const hasSeats = !isSoldOut && !!(seatFr || seatTo);
  const hasOrgSeats = !!(orgSeatFr || orgSeatTo);
  const isSingleSeat = hasSeats && (seatFr === seatTo || !seatTo);
  const isOrgSingleSeat =
    hasOrgSeats && (orgSeatFr === orgSeatTo || !orgSeatTo);
  const showOrgSeatFr = showOrgSeat && orgSeatFr && seatFr !== orgSeatFr;
  const showOrgSeatTo =
    showOrgSeat && orgSeatTo && seatTo !== orgSeatTo && orgSeatTo !== seatFr;
  const isUnmappedSection = !rowId;

  return (
    <Stack gap="m" alignItems="center" justifyContent={justifyIconContent}>
      <div
        className={clsx(styles.wrapper, {
          [styles.wrapperLight]: lightBackground,
          [styles.wrapperDark]: darkBackground,
        })}
        style={fontSize ? { fontSize: fontSize } : undefined}
      >
        {showSectionPrefix && (
          <div
            className={clsx(styles.attrLabel, {
              [styles.attrLabelLight]: lightBackground,
              [styles.attrLabelDark]: darkBackground,
            })}
          >
            <Content id={ContentId.Section} />
          </div>
        )}
        <span>{section}</span>
        {row && (
          <>
            <div className={styles.attrLabel}>
              <Content id={ContentId.Row} />
            </div>
            <span>{row}</span>
          </>
        )}
        {hasSeats && (
          <>
            {showSeatPrefix && (
              <div className={styles.attrLabel}>
                <Content id={isSingleSeat ? ContentId.Seat : ContentId.Seats} />
              </div>
            )}
            {showOrgSeatFr && (
              <span className={styles.attrLabel}>{orgSeatFr}</span>
            )}
            <span>{getSeatDisplayText({ seatFr, seatTo })}</span>
            {showOrgSeatTo && (
              <span className={styles.attrLabel}>{orgSeatTo}</span>
            )}
          </>
        )}
        {isSoldOut && showOrgSeat && hasOrgSeats && (
          <>
            {showSeatPrefix && (
              <div className={styles.attrLabel}>
                <Content
                  id={isOrgSingleSeat ? ContentId.Seat : ContentId.Seats}
                />
              </div>
            )}
            <span>
              {getSeatDisplayText({ seatFr: orgSeatFr, seatTo: orgSeatTo })}
            </span>
          </>
        )}
      </div>
      <Stack gap="s">
        {isUnmappedSection && showUnmappedSection && (
          <TooltipPopover
            variant="link"
            contentVariant="dark"
            triggerContent={
              <WarningOutlineIcon fill={'#FD9927'} size={vars.iconSize.m} />
            }
          >
            <Content id={ContentId.SeatingInfoNotMatchStubHub} />
          </TooltipPopover>
        )}
        {isWasted && <ClockTrashIcon stroke={IconsFill.currentColor} />}
        {isSeatSaver && (
          <SeatSaverIcon fill={vars.color.textWarning} size={vars.iconSize.m} />
        )}
        {showAdminHold && isAdminHold && (
          <AdminHoldIcon
            fill={vars.color.textBrand}
            stroke={vars.color.backgroundPrimaryActive}
            size={vars.iconSize.m}
          />
        )}
        {isDuplicate && (
          <span className={styles.duplicateLabel}>
            <Content id={ContentId.Duplicate} />
          </span>
        )}
      </Stack>
    </Stack>
  );
};
