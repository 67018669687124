import { ActionOutboxEntityType, UserSetting } from 'src/WebApiController';

export const enum SectionType {
  Sales = 'sales',
  Listings = 'listings',
  SalesReport = 'salesReport',
  ListingsReport = 'listingsReport',
  Events = 'events',
  ListingsFlattened = 'ListingsFlattened',
  SalesFlattened = 'SalesFlattened',
  Purchases = 'purchases',
  PurchaseEvent = 'purchaseEvent',
  SalesSideTable = 'salesSideTable',
  InventorySideTable = 'inventorySideTable',
  PurchaseSideTable = 'purchaseSideTable',
  MarketplacePaymentsTable = 'marketplacePaymentsTable',
}

export const SECTION_TYPE_TO_CUSTOM_COLUMN_SETTING: Record<
  SectionType,
  UserSetting
> = {
  [SectionType.Listings]: UserSetting.InventoryCustomColumns,
  [SectionType.ListingsFlattened]: UserSetting.InventoryCustomColumns,
  [SectionType.ListingsReport]: UserSetting.InventoryCustomColumns,
  [SectionType.Sales]: UserSetting.SaleCustomColumns,
  [SectionType.SalesFlattened]: UserSetting.SaleCustomColumns,
  [SectionType.SalesReport]: UserSetting.SaleCustomColumns,
  [SectionType.Events]: UserSetting.EventsCustomColumns,
  [SectionType.Purchases]: UserSetting.PurchaseCustomColumns,
  [SectionType.PurchaseEvent]: UserSetting.PurchaseCustomColumns,
  [SectionType.SalesSideTable]: UserSetting.SaleCustomColumns,
  [SectionType.InventorySideTable]: UserSetting.InventoryCustomColumns,
  [SectionType.PurchaseSideTable]: UserSetting.PurchaseCustomColumns,
  [SectionType.MarketplacePaymentsTable]:
    UserSetting.MarketplacePaymentsCustomColumns,
} as const;

export const SECTION_TYPE_TO_PRECISION_SETTING: Record<
  SectionType,
  UserSetting
> = {
  [SectionType.Listings]: UserSetting.InventoryColumnNumberPrecision,
  [SectionType.ListingsFlattened]:
    UserSetting.InventoryFlattenedColumnNumberPrecision,
  [SectionType.Events]: UserSetting.EventsColumnNumberPrecision,
  [SectionType.Sales]: UserSetting.SaleColumnNumberPrecision,
  [SectionType.SalesFlattened]: UserSetting.SaleFlattenedColumnNumberPrecision,
  [SectionType.ListingsReport]: UserSetting.InventoryColumnNumberPrecision,
  [SectionType.SalesReport]: UserSetting.SaleColumnNumberPrecision,
  [SectionType.Purchases]: UserSetting.PurchaseColumnNumberPrecision,
  [SectionType.PurchaseEvent]: UserSetting.PurchaseEventColumnNumberPrecision,
  [SectionType.SalesSideTable]: UserSetting.SalesSideTableColumnNumberPrecision,
  [SectionType.InventorySideTable]:
    UserSetting.InventorySideTableColumnNumberPrecision,
  [SectionType.PurchaseSideTable]:
    UserSetting.PurchaseSideTableColumnNumberPrecision,
  [SectionType.MarketplacePaymentsTable]:
    UserSetting.MarketplacePaymentsColumnNumberPrecision,
};

export const SECTION_TYPE_TO_COLUMN_EDITABILITY_SETTING: Record<
  SectionType,
  UserSetting
> = {
  [SectionType.Listings]: UserSetting.InventoryColumnEditability,
  [SectionType.ListingsFlattened]:
    UserSetting.InventoryFlattenedColumnEditability,
  [SectionType.Events]: UserSetting.EventsColumnEditability,
  [SectionType.Sales]: UserSetting.SaleColumnEditability,
  [SectionType.SalesFlattened]: UserSetting.SaleFlattenedColumnEditability,
  [SectionType.ListingsReport]: UserSetting.InventoryFlattenedColumnEditability,
  [SectionType.SalesReport]: UserSetting.SaleColumnEditability,
  [SectionType.Purchases]: UserSetting.PurchaseColumnEditability,
  [SectionType.PurchaseEvent]: UserSetting.PurchaseEventColumnEditability,
  [SectionType.SalesSideTable]: UserSetting.SalesSideTableColumnEditability,
  [SectionType.InventorySideTable]:
    UserSetting.InventorySideTableColumnEditability,
  [SectionType.PurchaseSideTable]:
    UserSetting.PurchaseSideTableColumnEditability,
  [SectionType.MarketplacePaymentsTable]:
    UserSetting.MarketplacePaymentsColumnEditability,
};

export const SECTION_TYPE_TO_ACTION_OUTBOX_ENTITY_TYPE = {
  [SectionType.Listings]: ActionOutboxEntityType.Listing,
  [SectionType.ListingsFlattened]: ActionOutboxEntityType.Listing,
  [SectionType.ListingsReport]: ActionOutboxEntityType.Listing,
  [SectionType.Events]: ActionOutboxEntityType.SellerEvent,
  [SectionType.Purchases]: ActionOutboxEntityType.Purchase,
  [SectionType.PurchaseEvent]: ActionOutboxEntityType.Purchase,
  [SectionType.Sales]: ActionOutboxEntityType.Sale,
  [SectionType.SalesFlattened]: ActionOutboxEntityType.Sale,
  [SectionType.SalesReport]: ActionOutboxEntityType.Sale,
  [SectionType.SalesSideTable]: ActionOutboxEntityType.Sale,
  [SectionType.InventorySideTable]: ActionOutboxEntityType.Listing,
  [SectionType.PurchaseSideTable]: ActionOutboxEntityType.Purchase,
  [SectionType.MarketplacePaymentsTable]: undefined,
} as const;
