import { KeyboardEventHandler, useCallback, useMemo } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { Stack } from 'src/core/ui';
import { onTabPanelKeyDown, TabPanel } from 'src/core/ui/TabPanel';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody } from 'src/modals/Modal';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { ListingDetails } from 'src/WebApiController';

import { modalDetails } from '../common/Modals.css';
import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';
import { ListingTab } from './components/listingDetailsUtils';
import { ListingDetailsFooter } from './ListingDetailsFooter';
import {
  ListingDetailsModalContextProvider,
  useListingDetailsContext,
} from './ListingDetailsModalV2Context';
import {
  AutoPricingSidePanel,
  AutoPricingSidePanelContextProvider,
  useAutoPricingSidePanelContext,
} from './ListingForms/AutoPricingSidePanel';

export const ListingDetailsModalV2 = () => {
  const activeId = getDeepLinkIdFromUrl(
    InventoryDeeplinkQueryParam,
    window.location.href
  );

  const { isLoading: listingIsLoading, loadingState } =
    useEventItemLoadingDisplay<ListingDetails>(
      FormatContentId.LoadingListingId,
      FormatContentId.SearchingForListingId,
      FormatContentId.CouldNotFindListingId,
      activeId
    );

  const { posEntity: listing } = useActivePosEntityContext<ListingDetails>();

  if (!listing) {
    return loadingState;
  }

  if (!listingIsLoading && loadingState) return loadingState;

  return <ListingDetailsModalWrapper />;
};

const ListingDetailsModalWrapper = () => {
  const { event } = useActivePosEntityContext<ListingDetails>();

  return (
    <EventMapContextProvider event={event}>
      <AutoPricingSidePanelContextProvider>
        <ListingDetailsModalContextProvider>
          <ListingDetailsModalContent />
        </ListingDetailsModalContextProvider>
      </AutoPricingSidePanelContextProvider>
    </EventMapContextProvider>
  );
};

const ListingDetailsModalContent = () => {
  const {
    isLoading,
    hasChanges,
    event,
    listing,
    activeTabIndex,
    tabs,
    setActiveTabIndex,
    onTabChange,
    setLoading,
    onSubmit,
  } = useListingDetailsContext();
  const { metadata } = useAutoPricingSidePanelContext();

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      onTabPanelKeyDown(e, setActiveTabIndex, tabs.length);
    },
    [setActiveTabIndex, tabs.length]
  );

  const inventoryEventPageUrl = useMemo(() => {
    if (event) {
      const includeAllSearchParams = true;
      const useEventV2 = true;
      const queryParamOverride = undefined;
      const queryParamsToRemove = [InventoryDeeplinkQueryParam];
      return createInventoryEventUrl(
        event.viagVirtualId,
        includeAllSearchParams,
        useEventV2,
        queryParamOverride,
        queryParamsToRemove
      );
    }
    return undefined;
  }, [event]);

  return (
    <Stack
      direction="row"
      style={{ height: '100%', width: '100%', overflowX: 'auto' }}
    >
      {event && listing && (
        <AutoPricingSidePanel
          event={event}
          listing={listing}
          listingInput={metadata.input}
        />
      )}
      <Stack direction="column" style={{ height: '100%' }}>
        <CancellableFormHeader
          disabled={isLoading}
          showDialogOnCancel={hasChanges}
        >
          <ConnectedEventEntityHeader
            title={
              <>
                <Content id={ContentId.ViewDetails} />
                {listing!.isDeleted && (
                  <>
                    {' ('}
                    <Content id={ContentId.Deleted} />
                    {')'}
                  </>
                )}
              </>
            }
          />
        </CancellableFormHeader>

        <ModalBody onKeyDown={onKeyDown}>
          <ModalBodyHeaderContainer>
            <Summary
              event={event!}
              posEntity={listing!}
              eventNameLink={inventoryEventPageUrl}
            />
          </ModalBodyHeaderContainer>
          <div className={modalDetails}>
            <ModalBodyDataContainer>
              <TabPanel
                tabs={tabs}
                activeTabIndex={activeTabIndex}
                onValueChange={(v) => onTabChange(v as ListingTab)}
              />
            </ModalBodyDataContainer>
          </div>
        </ModalBody>

        <ListingDetailsFooter
          onSubmit={hasChanges ? onSubmit : undefined}
          disabled={isLoading || !!listing?.dupListingId}
          setIsLoading={setLoading}
        />
      </Stack>
    </Stack>
  );
};
