import { useCallback, useEffect, useMemo } from 'react';
import { useToggle } from 'react-use';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { InventoryEventBulkActions } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/InventoryEventBulkActions';
import { useAppContext } from 'src/contexts/AppContext';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useGetCompListings } from 'src/hooks/useGetCompListings';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { LayoutIcon } from 'src/svgs';
import { ListingTable } from 'src/tables/ListingTable';
import { ListingTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { getAllInPriceFromListPrice } from 'src/utils/inventoryUtils';
import { getMatchingSectionRow } from 'src/utils/seatScoreUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { EventWithData } from 'src/WebApiController';
import {
  IListingGroupItem,
  Listing,
  Marketplace,
  UserSetting,
} from 'src/WebApiController';

import * as styles from '../InventoryEventPage.css';
import { useStubHubListingManualPricingContext } from '../MarketListings/StubHubListingsManualPricingContext';
import { filterListingItemsBySectionRow } from '../MarketListings/StubHubListingsManualPricingContext.utils';
import {
  mapCompListingToListing,
  prependTicketClassColorColumnForFirstTime,
} from './ManualPricingSection.utils';

type ManualPricingSectionProps = {
  accordionItemProps?: EventWithData;
};

export function ManualPricingSection({
  accordionItemProps,
}: ManualPricingSectionProps) {
  const { onScroll } = useCollapsableViewContext();
  const { listings: ownListings, event } = accordionItemProps || {};
  const { compListingsQuery } = useGetCompListings(event);
  const {
    rowIdFilters,
    sectionIdFilters,
    sectionRowIdFilters,
    quantityFilter,
  } = useStubHubListingManualPricingContext();

  const {
    value: storedInventoryColumnsEnabledSetting,
    setUserSetting: setInventoryColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryColumnsEnabled,
  });

  const {
    value: storedInventoryColumnOrderSetting,
    setUserSetting: setInventoryColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryColumnOrder,
  });

  useEffect(() => {
    // XX Migration to add TicketClassColor column for users
    // When the column becomes available for the first time
    if (
      storedInventoryColumnOrderSetting != null &&
      storedInventoryColumnsEnabledSetting != null
    ) {
      prependTicketClassColorColumnForFirstTime(
        storedInventoryColumnOrderSetting,
        storedInventoryColumnsEnabledSetting,
        setInventoryColumnOrderSetting,
        setInventoryColumnsEnabledSetting
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedInventoryColumnOrderSetting, storedInventoryColumnsEnabledSetting]);

  const { loginContext } = useAppContext();
  const stubHubMkpSettings =
    loginContext?.user?.activeAccount?.marketplaceSettings?.find(
      (m) => m.mkp === Marketplace.StubHub
    );

  const { venueMapInfo } = useEventMapContext();

  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const ticketClassIdColorMap = useMemo(() => {
    const colorMap = new Map<number, string>();
    venueMapInfo?.sections.forEach((section) => {
      section.rows.forEach((row) => {
        if (row.tktClass?.id) {
          colorMap.set(row.tktClass.id, row.tktClass.color);
        }
      });
    });

    return colorMap;
  }, [venueMapInfo?.sections]);

  const filterListingItemsBySectionRowWrapper = useCallback(
    (l: Listing) => {
      const { section } = getMatchingSectionRow(
        l.seating,
        venueMapInfo?.sections
      );

      return filterListingItemsBySectionRow(
        l,
        section?.id ?? null,
        sectionIdFilters,
        rowIdFilters,
        sectionRowIdFilters,
        quantityFilter
      );
    },
    [
      quantityFilter,
      rowIdFilters,
      sectionIdFilters,
      sectionRowIdFilters,
      venueMapInfo?.sections,
    ]
  );

  const getListingTicketClassColor = useCallback(
    (listing: Listing) => {
      const { ticketClassId } = getMatchingSectionRow(
        listing.seating,
        venueMapInfo?.sections
      );

      return ticketClassIdColorMap.get(ticketClassId ?? 0);
    },
    [ticketClassIdColorMap, venueMapInfo?.sections]
  );

  const ownListingsMapped: Listing[] = useMemo(() => {
    const ownListingsFlattened =
      ownListings
        ?.flatMap(flattenListingGroup)
        .filter((l) => l.isFull)
        .map((lgi: IListingGroupItem) => {
          const listing = lgi as Listing;
          // Need to recalculate all in price to match display value
          const allInPrice = getAllInPriceFromListPrice(
            listing.listPrice,
            listing,
            stubHubMkpSettings?.sellerFee
          );

          return {
            ...listing,
            allInPrice,
          } as Listing;
        })
        .filter(filterListingItemsBySectionRowWrapper) || [];
    return ownListingsFlattened;
  }, [
    filterListingItemsBySectionRowWrapper,
    ownListings,
    stubHubMkpSettings?.sellerFee,
  ]);

  const ownListingsStubHubListingIds = useMemo(
    () =>
      ownListingsMapped
        .filter(
          (l) => l.mkpListings?.some((ml) => ml.mkp === Marketplace.StubHub)
        )
        .map(
          (l) =>
            l.mkpListings?.find((ml) => ml.mkp === Marketplace.StubHub)
              ?.mkpListingId ?? ''
        ),
    [ownListingsMapped]
  );

  const compListingsMapped = useMemo(
    () =>
      compListingsQuery.data
        ?.map(mapCompListingToListing)
        .filter(
          (cl) => !ownListingsStubHubListingIds.includes(cl.id.toString())
        )
        .filter(filterListingItemsBySectionRowWrapper) || [],
    [
      compListingsQuery.data,
      filterListingItemsBySectionRowWrapper,
      ownListingsStubHubListingIds,
    ]
  );

  const onColumnSettingButtonClickHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isColumnModalOpen) toggleColumnModal(true);
    },
    [isColumnModalOpen, toggleColumnModal]
  );

  const initState = useMemo(
    () => ({
      sorting: [{ id: ListingTableColumnId.AllInPrice, desc: false }],
    }),
    []
  );

  return (
    <div className={styles.tableSection}>
      {accordionItemProps && (
        <div className={styles.tableContainer}>
          <Stack justifyContent="end" className={styles.hoistedColumnSettings}>
            {isColumnModalOpen ? (
              <ColumnSettingsModal<CustomListingColumn>
                onClose={toggleColumnModal}
                sectionType={SectionType.Listings}
                showTicketClassColor
              />
            ) : null}
            <Stack width="full" direction="column" alignItems="end">
              <Button
                variant="textPlain"
                onClick={onColumnSettingButtonClickHandler}
              >
                <LayoutIcon size={vars.iconSize.m} />
                <Content id={ContentId.Columns} />
              </Button>
            </Stack>
          </Stack>
          <MultiSelectActionBar
            groupId={accordionItemProps.event.viagVirtualId}
            flattenedIdsOverride={ownListingsMapped.map((l) => l.id)}
          >
            <InventoryEventBulkActions
              eventWithData={accordionItemProps}
              listingCount={ownListingsMapped.length}
              isEventPage={true}
            />
          </MultiSelectActionBar>
          <ListingTable
            {...accordionItemProps}
            initState={initState}
            listings={ownListingsMapped}
            compListings={compListingsMapped}
            listCnt={compListingsMapped.length + ownListingsMapped.length}
            ungrListCnt={compListingsMapped.length + ownListingsMapped.length}
            listGrpCnt={0}
            disablePagination
            useVirtuoso
            getListingTicketClassColor={getListingTicketClassColor}
            onVirtuosoTableScroll={onScroll}
          />
        </div>
      )}
    </div>
  );
}
