import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import {
  AutoPricingSettingsFormContent,
  AutoPricingSettingsFormContentCoreProps,
  PricingSetting,
} from 'src/components/AutoPricing/AutoPricingSettingsFormContent/AutoPricingSettingsFormContent';
import { Content } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { AutoPricingInputs } from 'src/WebApiController';

import { MergeListingGroupInputListFields } from '../groupingTypes';

export type ListingGroupAutoPricingSettingsFormContentProps =
  AutoPricingSettingsFormContentCoreProps;

export const ListingGroupAutoPricingSettingsFormContent = ({
  disabled,
  ...rest
}: ListingGroupAutoPricingSettingsFormContentProps) => {
  const { watch: outerWatch, setValue: outerSetValue } =
    useFormContext<MergeListingGroupInputListFields>();

  const { pricingSettingsInputs, resetListingAutoPricingSettings } =
    outerWatch();

  const innerMethods = useForm<AutoPricingInputs>({
    defaultValues: pricingSettingsInputs,
  });

  const { watch: innerWatch } = innerMethods;

  innerWatch((pricingSettingsInputsNew) => {
    outerSetValue(
      'pricingSettingsInputs',
      pricingSettingsInputsNew as AutoPricingInputs
    );
  });

  return (
    <FormProvider {...innerMethods}>
      <>
        <AutoPricingSettingsFormContent
          autoPricingSectionChildren={
            <PricingSetting
              header={
                <Content id={ContentId.ClearListingAutoPricingSettings} />
              }
              detail={
                <Switch
                  disabled={disabled}
                  checked={!!resetListingAutoPricingSettings}
                  onClick={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    if (isChecked !== resetListingAutoPricingSettings) {
                      outerSetValue(
                        'resetListingAutoPricingSettings',
                        isChecked
                      );
                    }
                  }}
                />
              }
            />
          }
          isGlobalEdit={false}
          disabled={disabled}
          {...rest}
        />
      </>
    </FormProvider>
  );
};
