import { useRef } from 'react';
import {
  ConvertPaymentAmount,
  ConvertPaymentAmountRefActions,
} from 'src/components/MarketplacePayments/ConvertPaymentAmount/ConvertPaymentAmount';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature, MarketplaceSalePayment } from 'src/WebApiController';

interface MarketplacePaymentOptionsActionProps {
  marketplaceSalePayment: MarketplaceSalePayment;
}

export const MarketplacePaymentOptions = ({
  marketplaceSalePayment,
}: MarketplacePaymentOptionsActionProps) => {
  const actionsRef = useRef<ConvertPaymentAmountRefActions | null>(null);
  const canConvertMarketplacePaymentLines = useUserHasFeature(
    Feature.MarketplacePaymentConversion
  );

  const canShowOptions = canConvertMarketplacePaymentLines;

  if (!canShowOptions) {
    return null;
  }

  return (
    <>
      <ConvertPaymentAmount
        convertPaymentAmountActionsRef={actionsRef}
        marketplaceSalePayment={marketplaceSalePayment}
      />
      <PosDropdown
        key="event-action-dropdown"
        trigger={
          <div
            style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}
          >
            <MoreIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
            />
          </div>
        }
        align="end"
      >
        {canConvertMarketplacePaymentLines && (
          <PosDropdownItem
            key="convert-marketplace-payment"
            onClick={() => actionsRef.current?.showConvertPaymentAmountDialog()}
          >
            <Content id={ContentId.ConvertPayment} />
          </PosDropdownItem>
        )}
      </PosDropdown>
      {canConvertMarketplacePaymentLines && (
        <ConvertPaymentAmount
          convertPaymentAmountActionsRef={actionsRef}
          marketplaceSalePayment={marketplaceSalePayment}
        />
      )}
    </>
  );
};
