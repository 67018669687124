import { Event, Listing } from 'src/WebApiController';

import { InventoryEventTabs } from './InventoryEventPage';
import { MarketListingsForm } from './MarketListings/MarketListings';
import { StubHubListingsWithManualPricing } from './MarketListings/StubHubListingsWithManualPricing';

type InventoryEventPageRightPanelProps = {
  event: Event;
  listings: Listing[] | null | undefined;
  selectedListingIds: number[];
  activeTab: InventoryEventTabs;
};

export const InventoryEventPageRightPanel: React.FC<
  InventoryEventPageRightPanelProps
> = ({ event, listings, selectedListingIds, activeTab }) => {
  if (activeTab === InventoryEventTabs.ManualPricing) {
    return (
      <StubHubListingsWithManualPricing
        event={event}
        eventListings={listings}
        selectedListingIds={selectedListingIds}
      />
    );
  }

  return (
    <MarketListingsForm
      event={event}
      eventListings={listings}
      selectedListingIds={selectedListingIds}
    />
  );
};
