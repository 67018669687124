import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { CatalogClient, CatalogResults } from 'src/WebApiController';

export function useGetCatalogBySaleIds(saleIds?: number[] | null) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  return useQuery({
    queryKey: ['getCatalogBySaleIds', saleIds],
    async queryFn() {
      if (!saleIds) return null;

      if (saleIds.length === 0)
        return {
          events: {},
          performers: {},
          venues: {},
          venueCfgs: {},
        } as CatalogResults;

      try {
        return await new CatalogClient(
          activeAccountWebClientConfig
        ).getCatalogBySaleIds(saleIds);
      } catch (err: unknown) {
        trackError('CatalogClient.getCatalogBySaleIds', err as ErrorTypes, {
          saleIds,
        });
        throw err;
      }
    },
    refetchOnWindowFocus: false,
  });
}
