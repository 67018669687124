import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useCallback } from 'react';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { VenueMapContentProps } from 'src/components/Events/VenueMap/VenueMapContent';
import { Content } from 'src/contexts/ContentContext';
import { ErrorTypes } from 'src/contexts/ErrorBoundaryContext';
import { useAutoCompMapSectionHandler } from 'src/hooks/useAutoCompMapSectionHandler';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Event,
  SectionSectionGroup,
  VenueMapZoneConfigInfo,
  VenueZoneConfigOverride,
  VenueZoneConfigType,
} from 'src/WebApiController';

import { SeatMapCard } from '../../SeatMapCard';
import * as styles from './VenueZoneMapFooter.css';

export const VenueZoneMapFooter = ({
  event,
  selectedOverrideId,
  setSelectedOverrideId,
  selectedConfigType,
  setSelectedConfigType,
  createGetColor,
  venueZoneMapConfigInfoQuery,
  venueZoneMapConfigOverrideInfoQuery,
  setVenueZoneConfigTypeOverrideMutation,
}: {
  event: Event;
  selectedOverrideId: number | null;
  setSelectedOverrideId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedConfigType: VenueZoneConfigType | null;
  setSelectedConfigType: React.Dispatch<
    React.SetStateAction<VenueZoneConfigType | null>
  >;
  createGetColor: (
    sectionSectionGroupLookup?: {
      [key: string]: SectionSectionGroup;
    } | null
  ) => NonNullable<VenueMapContentProps['getColor']>;
  venueZoneMapConfigInfoQuery: UseQueryResult<
    VenueMapZoneConfigInfo | null,
    Error
  >;
  venueZoneMapConfigOverrideInfoQuery: UseQueryResult<
    {
      [key: string]: VenueZoneConfigOverride;
    } | null,
    Error
  >;
  setVenueZoneConfigTypeOverrideMutation: UseMutationResult<
    void,
    ErrorTypes,
    {
      eventId?: number | null;
      venueZoneOverrideId?: number | null;
      venueZoneConfigType?: VenueZoneConfigType | null;
    },
    unknown
  >;
}) => {
  const changeSectionGroupDialog = useBasicDialog();
  const onLaunchingSingleSectionDialog = useCallback(
    () => changeSectionGroupDialog.launchDialog(),
    [changeSectionGroupDialog]
  );

  const { onResetSections } = useAutoCompMapSectionHandler(
    onLaunchingSingleSectionDialog
  );

  const onVenueZoneMapClick = useCallback(
    (
      venueZoneOverrideId: number | null,
      venueZoneConfigType: VenueZoneConfigType | null
    ) => {
      setVenueZoneConfigTypeOverrideMutation.mutate({
        eventId: event!.viagId,
        venueZoneOverrideId,
        venueZoneConfigType,
      });
      setSelectedOverrideId(venueZoneOverrideId);
      setSelectedConfigType(venueZoneConfigType);
      onResetSections();
    },
    [
      setVenueZoneConfigTypeOverrideMutation,
      event,
      setSelectedOverrideId,
      setSelectedConfigType,
      onResetSections,
    ]
  );

  const disabledButtons = setVenueZoneConfigTypeOverrideMutation.isPending;

  return (
    <div>
      <span className={styles.zoneMapSubHeader}>
        <Content id={ContentId.OtherZoneMap} />
      </span>

      <div className={styles.venueMapCardsContainer}>
        <SeatMapCard
          isInteractive={!disabledButtons}
          isSelected={
            selectedOverrideId == null &&
            selectedConfigType === VenueZoneConfigType.Amphitheater
          }
          onClick={() => {
            onVenueZoneMapClick(null, VenueZoneConfigType.Amphitheater);
          }}
          footer={<Content id={ContentId.Amphitheater} />}
        >
          <EventVenueMap
            isZoomEnabled={false}
            getColor={createGetColor(
              venueZoneMapConfigInfoQuery?.data?.venueMapZoneConfigs
                .Amphitheater
            )}
          />
        </SeatMapCard>

        <SeatMapCard
          isInteractive={!disabledButtons}
          isSelected={
            selectedOverrideId == null &&
            selectedConfigType ===
              VenueZoneConfigType.ConcertsSectionsBehindStage
          }
          onClick={() => {
            onVenueZoneMapClick(
              null,
              VenueZoneConfigType.ConcertsSectionsBehindStage
            );
          }}
          footer={<Content id={ContentId.ConcertsSectionsBehindStage} />}
        >
          <EventVenueMap
            isZoomEnabled={false}
            getColor={createGetColor(
              venueZoneMapConfigInfoQuery?.data?.venueMapZoneConfigs
                .ConcertsSectionsBehindStage
            )}
          />
        </SeatMapCard>

        <SeatMapCard
          isInteractive={!disabledButtons}
          isSelected={
            selectedOverrideId == null &&
            selectedConfigType === VenueZoneConfigType.SportsOrConcertsWith360
          }
          onClick={() => {
            onVenueZoneMapClick(
              null,
              VenueZoneConfigType.SportsOrConcertsWith360
            );
          }}
          footer={<Content id={ContentId.SportsOrConcertsWith360} />}
        >
          <EventVenueMap
            isZoomEnabled={false}
            getColor={createGetColor(
              venueZoneMapConfigInfoQuery?.data?.venueMapZoneConfigs
                .SportsOrConcertsWith360
            )}
          />
        </SeatMapCard>

        {Object.entries(venueZoneMapConfigOverrideInfoQuery.data ?? {}).map(
          ([k, v], idx) => {
            return (
              <SeatMapCard
                key={idx}
                isInteractive={!disabledButtons}
                isSelected={selectedOverrideId == Number.parseInt(k)}
                onClick={() => {
                  onVenueZoneMapClick(Number.parseInt(k), null);
                }}
                footer={v.name}
              >
                <EventVenueMap
                  isZoomEnabled={false}
                  getColor={createGetColor(v.sectionSectionGroupLookup)}
                />
              </SeatMapCard>
            );
          }
        )}
      </div>
    </div>
  );
};
