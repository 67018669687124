import { once } from 'lodash-es';
import React, { useCallback, useContext, useState } from 'react';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import { lookupEventInCatalog } from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  CatalogClient,
  EntityWithTickets,
  Event,
  Listing,
  Sale,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { Content } from '../ContentContext';

export type IPosEntity = {
  id: EntityWithTickets['id'];
  posEvId?: EntityWithTickets['posEvId'];
  viagVirtualId: EntityWithTickets['viagVirtualId'] | null;
  entityType: ActionOutboxEntityType;
};

export type IActivePosEntityContext<TDataItem extends IPosEntity> = {
  entityType: ActionOutboxEntityType;
  posEntityId?: number | null;
  /* Null means a value was not able to be obtained.  Undefined means the item was never retrieved or being retrieved. */
  posEntity?: TDataItem | null;
  /* Null means a value was not able to be obtained.  Undefined means the item was never retrieved or being retrieved. */
  event?: Event | null;
  /* Whether the current active event item is being searched or just loading */
  posEntityDisplayId?: string | null;
  setActivePosEntity: (
    posEntityId: number,
    posEntityDisplayId?: string | null,
    forceRefresh?: boolean,
    dataToGet?: string[],
    resetData?: boolean
  ) => Promise<void | null>;
  updateActivePosEntityInfo: (
    posEntity: Pick<
      IActivePosEntityContext<TDataItem>,
      'posEntityId' | 'event' | 'posEntity' | 'posEntityDisplayId'
    >
  ) => void;
  isLoading: boolean;
  errorInfo?: { errorHeader: React.ReactNode; errorMessage: React.ReactNode };
};

export const createActivePosEntityContext = once(<
  TDataItem extends IPosEntity,
>() => React.createContext({} as IActivePosEntityContext<TDataItem>));
export const useActivePosEntityContext = <TDataItem extends IPosEntity>() =>
  useContext(createActivePosEntityContext<TDataItem>());

export const ActivePosEntityProvider = <TDataItem extends IPosEntity>({
  entityType,
  getActivePosEntity,
  onSetActiveItemCompleteCallback,
  children,
}: {
  entityType: ActionOutboxEntityType;
  getActivePosEntity: (
    posEntityId: number,
    curEntity?: TDataItem | null,
    dataToGet?: string[] | null
  ) => Promise<
    Pick<
      IActivePosEntityContext<TDataItem>,
      'posEntityId' | 'event' | 'posEntity' | 'posEntityDisplayId'
    >
  >;

  /* Callback when item is successfully set active */
  onSetActiveItemCompleteCallback?: (
    item: TDataItem,
    forceRefresh?: boolean
  ) => void;
  children: React.ReactNode;
}) => {
  const { trackError } = useErrorBoundaryContext();

  const [errorInfo, setErrorInfo] = useState<{
    errorHeader: React.ReactNode;
    errorMessage: React.ReactNode;
  }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ActivePosEntityContext = createActivePosEntityContext<TDataItem>();
  const [posEntityInfo, setPosEntityInfo] = useState<
    Pick<
      IActivePosEntityContext<TDataItem>,
      'posEntityId' | 'posEntity' | 'event' | 'posEntityDisplayId'
    >
  >({});

  const { data, updateItemInEvent } = useCatalogDataContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const getAndUpdateCatalogDataForEntity = useCallback(
    async (item: TDataItem) => {
      if (
        !item?.viagVirtualId ||
        (entityType !== ActionOutboxEntityType.Listing &&
          entityType !== ActionOutboxEntityType.Sale)
      ) {
        return null;
      }
      // Now that we got the listing details, try to get the Event from existing data
      let event = lookupEventInCatalog(data, item.viagVirtualId, item.posEvId);

      // If we can't find it, go fetch it
      if (event == null) {
        const client = new CatalogClient(activeAccountWebClientConfig);

        const results =
          entityType === ActionOutboxEntityType.Listing
            ? await client.getCatalogForListingByListingId(item.id!)
            : await client.getCatalogForSaleBySaleId(item.id!);
        if (results?.events) {
          event = lookupEventInCatalog(
            results,
            item.viagVirtualId,
            item.posEvId
          );
        }
      } else {
        // The event exists in the catalog, update the entity in that event
        if (entityType === ActionOutboxEntityType.Listing) {
          updateItemInEvent(item as unknown as Listing, entityType);
        } else {
          updateItemInEvent(item as unknown as Sale, entityType);
        }
      }

      return event;
    },
    [activeAccountWebClientConfig, data, entityType, updateItemInEvent]
  );

  const updateActivePosEntityInfo = useCallback(
    async (
      item: Pick<
        IActivePosEntityContext<TDataItem>,
        'posEntityId' | 'event' | 'posEntity' | 'posEntityDisplayId'
      >
    ) => {
      const event = item.posEntity
        ? await getAndUpdateCatalogDataForEntity(item.posEntity)
        : undefined;

      if (
        posEntityInfo.posEntityId == null ||
        posEntityInfo.posEntityId === item.posEntityId
      ) {
        // Only update the entity info if it's the active one
        setPosEntityInfo({ ...item, event: event?.event });
      }
    },
    [getAndUpdateCatalogDataForEntity, posEntityInfo.posEntityId]
  );

  const setActivePosEntity = useCallback(
    async (
      posEntityId: number,
      posEntityDisplayId?: string | null,
      forceRefresh?: boolean,
      dataToGet?: string[],
      resetData?: boolean
    ) => {
      if (isLoading) {
        // NOTE: This is already a request pending, any new call - will either likely replace the old one or being replaced by the old one
        // depending on which ones finish first - so we just quit here as there's nothing really to do correctly
        // Importantly - the dev calling setActivePosEntity should know when to call this (and when not)
        return;
      }

      if (posEntityId === 0) {
        // Clearing the active item
        setPosEntityInfo({
          posEntityId: posEntityId,
          posEntityDisplayId: posEntityDisplayId,
        });
        return;
      }

      const isSameEntity = posEntityInfo?.posEntityId === posEntityId;
      if (forceRefresh || !isSameEntity) {
        // If we are getting a different item, clear the existing ones so it shows a loading state
        setPosEntityInfo({
          posEntityId: posEntityId,
          posEntityDisplayId: posEntityDisplayId,
        });
      }

      return tryInvokeApi(
        async () => {
          setErrorInfo(undefined);
          setIsLoading(true);

          const item = await getActivePosEntity(
            posEntityId,
            // if we want to reset data or the entity we want is not the same, pass in null for current entity
            resetData || !isSameEntity ? undefined : posEntityInfo?.posEntity,
            dataToGet
          );

          // null item return means it was not found (204 status)
          if (item == null) {
            setErrorInfo({
              errorHeader: <Content id={ContentId.Error_NotFound_Header} />,
              errorMessage: (
                <Content id={ContentId.Error_NotFound_GenericMessage} />
              ),
            });
          }

          // Once we found it, set the item as active
          const event = item.posEntity
            ? await getAndUpdateCatalogDataForEntity(item.posEntity)
            : undefined;

          setPosEntityInfo({ ...item, event: event?.event });

          if (item.posEntity) {
            onSetActiveItemCompleteCallback?.(item.posEntity, forceRefresh);
          }
        },
        (error) => {
          // Clear the active ids so on retry it will reget
          setPosEntityInfo({
            posEntityId: undefined,
            posEntityDisplayId: undefined,
          });
          const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
            error?.status,
            error?.message
          );
          setErrorInfo({
            errorHeader: headerDisplay,
            errorMessage: messageDisplay,
          });
          trackError('ActivePosEntityContext.getActivePosEntity', error, {
            posEntityId,
            posEntityDisplayId,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      isLoading,
      posEntityInfo?.posEntityId,
      posEntityInfo?.posEntity,
      getActivePosEntity,
      getAndUpdateCatalogDataForEntity,
      onSetActiveItemCompleteCallback,
      trackError,
    ]
  );

  return (
    <ActivePosEntityContext.Provider
      value={{
        entityType,
        isLoading,
        errorInfo,
        setActivePosEntity,
        updateActivePosEntityInfo: updateActivePosEntityInfo,
        ...posEntityInfo,
        posEntityId: posEntityInfo.posEntity?.id || posEntityInfo.posEntityId,
      }}
    >
      {children}
    </ActivePosEntityContext.Provider>
  );
};
