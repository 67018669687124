import { HubConnectionState } from '@microsoft/signalr';
import { isEqual } from 'lodash-es';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosTextField } from 'src/core/POS/PosTextField';
import { SimpleTable } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import { compareMarketplace } from 'src/utils/eventWithDataUtils';
import { getListingDetailsUpdateInput } from 'src/utils/inventoryUtils';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  ListingDetails,
  ListingDetailsUpdateInput,
  Marketplace,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';
import * as styles from './BulkEditMarketplaceSeatingDialog.css';

export type MarketplaceBroadcastSelectionMode = 'all' | 'none' | 'default';

const enum SeatingAttribute {
  Section = 'overrideSection',
  Row = 'overrideRow',
  Seats = 'overrideSeats',
}

const seatingAttributes = [
  SeatingAttribute.Section,
  SeatingAttribute.Row,
  SeatingAttribute.Seats,
] as const;

export type BulkEditMarketplaceSeatingDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  onOkay: (
    listingUpdate: ListingDetailsUpdateInput | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

/**
 * Bulk Marketplace Seating Change Dialog, with Form Provider wrapper
 */
export const BulkEditMarketplaceSeatingDialog = ({
  ...rest
}: BulkEditMarketplaceSeatingDialogProps) => {
  const methods = useForm<
    Partial<Record<Marketplace, Record<SeatingAttribute, string>>>
  >({});

  return (
    <FormProvider {...methods}>
      <BulkEditMarketplaceSeatingDialogContent {...rest} methods={methods} />
    </FormProvider>
  );
};

type BulkEditMarketplaceSeatingDialogContent = {
  methods: UseFormReturn<
    Partial<Record<Marketplace, Record<SeatingAttribute, string>>>,
    unknown,
    undefined
  >;
} & BulkEditMarketplaceSeatingDialogProps;

/**
 * Bulk Marketplace Seating Change Dialog
 */
export function BulkEditMarketplaceSeatingDialogContent({
  updateKey,
  onOkay,
  onCancel,
  methods,
  ...rest
}: BulkEditMarketplaceSeatingDialogContent) {
  const { loginContext } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);
  const marketplaceSettings =
    loginContext?.user?.activeAccount?.marketplaceSettings;
  const { getValues, setValue, formState, watch } = methods;

  const input = watch();

  const textContentMap = {
    [SeatingAttribute.Section]: useContent(ContentId.Section),
    [SeatingAttribute.Row]: useContent(ContentId.Row),
    [SeatingAttribute.Seats]: useContent(ContentId.Seats),
  };

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshExpandedListItems();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.UpdateMarketplaceSeating,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const onInputChangeHandler = (
    value: string,
    marketplace: Marketplace,
    attribute: SeatingAttribute
  ) => {
    const oldValue =
      getValues(marketplace) ?? ({} as Record<SeatingAttribute, string>);
    setValue(marketplace, { ...oldValue, [attribute]: value });
  };

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      const formValues = getValues();
      const formEntries = Object.entries(formValues).map(
        ([marketplace, value]) => ({ mkp: marketplace, ...value })
      );

      const updatedListing = getListingDetailsUpdateInput({
        mkpListings: formEntries,
      } as ListingDetails);

      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(null, false, onPreviewReceived);
      } else {
        onOkay(
          updatedListing,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [getValues, onOkay, preview, hasBackgroundBulkEditFeature]
  );

  const isInputValid = useMemo(() => {
    return !isEqual(input, formState.defaultValues ?? {});
  }, [formState.defaultValues, input]);

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'lg'}
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.AllMarketplaces} />}
        />
      }
      onOpened={() => {
        initJob();
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
      }}
      centered
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter' && isInputValid) {
          submittButtonRef.current.click();
        }
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={ActionOutboxEntityType.Listing}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          disabled={!isInputValid}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={ActionOutboxEntityType.Listing}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <div className={styles.bulkEditBroadcastSettingsDialogContainer}>
          <div className={styles.fieldWrapper}>
            <SimpleTable.Table>
              <SimpleTable.Thead>
                <SimpleTable.Tr>
                  <SimpleTable.Th className={styles.broadcastCell}>
                    <Content id={ContentId.Marketplace} />
                  </SimpleTable.Th>
                  <SimpleTable.Th className={styles.broadcastCell}>
                    {textContentMap[SeatingAttribute.Section]}
                  </SimpleTable.Th>
                  <SimpleTable.Th className={styles.broadcastCell}>
                    {textContentMap[SeatingAttribute.Row]}
                  </SimpleTable.Th>
                  <SimpleTable.Th className={styles.broadcastCell}>
                    {textContentMap[SeatingAttribute.Seats]}
                  </SimpleTable.Th>
                </SimpleTable.Tr>
              </SimpleTable.Thead>
              <SimpleTable.Tbody>
                {marketplaceSettings
                  ?.filter((ml) => ml.mkp && ml.mkp !== Marketplace.Offline)
                  ?.sort((a, b) => compareMarketplace(a.mkp, b.mkp))
                  ?.map((marketplace) => {
                    return (
                      <SimpleTable.Tr
                        className={styles.broadcastRow}
                        key={`${marketplace.mkp}`}
                      >
                        <SimpleTable.Td className={styles.broadcastCell}>
                          <MarketplaceLogo marketplace={marketplace.mkp} />
                        </SimpleTable.Td>
                        {seatingAttributes.map((attribute) => (
                          <SimpleTable.Td
                            className={styles.broadcastCell}
                            key={attribute}
                          >
                            <PosTextField
                              placeholder={textContentMap[attribute]}
                              onChange={(e) =>
                                onInputChangeHandler(
                                  e.currentTarget.value,
                                  marketplace.mkp,
                                  attribute
                                )
                              }
                            />
                          </SimpleTable.Td>
                        ))}
                      </SimpleTable.Tr>
                    );
                  })}
              </SimpleTable.Tbody>
            </SimpleTable.Table>
          </div>
        </div>
      </BulkEditStatus>
    </GenericDialog>
  );
}
