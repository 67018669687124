import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  PurchaseOrderDeal,
  PurchaseOrderDealConfig,
  PurchaseOrderDealDetails,
} from 'src/WebApiController';

import { useSellerAccountContext } from '../SellerAccountContext';

interface DealContextValue {
  localDeal: PurchaseOrderDeal | null;
  setLocalDeal: Dispatch<SetStateAction<PurchaseOrderDeal | null>>;
  updateLocalDealDetails: (value: Partial<PurchaseOrderDealDetails>) => void;
  updateLocalDealConfigHurdle: (
    index: number,
    value: Partial<PurchaseOrderDealConfig>
  ) => void;
  deleteLocalDealConfigHurdle: (index: number) => void;
  isEditDeal: boolean;
  setIsEditDeal: Dispatch<SetStateAction<boolean>>;
  activeUserIds: string[];
  isValidDeal: boolean;
  assignedPriorities: Set<number>;
}
const DealContext = createContext<DealContextValue | undefined>(undefined);

export const DealProvider: React.FC<{
  deal?: PurchaseOrderDeal;
  defaultEdit?: boolean;
  children?: React.ReactNode;
}> = ({ deal, defaultEdit = false, children }) => {
  const [localDeal, setLocalDeal] = useState<PurchaseOrderDeal | null>(
    deal || null
  );
  const [isEditDeal, setIsEditDeal] = useState(defaultEdit);
  const { allActiveUserInfos } = useSellerAccountContext();

  const updateLocalDealDetails = useCallback(
    (value: Partial<PurchaseOrderDealDetails>) => {
      setLocalDeal((prev) => {
        if (!prev) return prev;
        return { ...prev, dealDetails: { ...prev.dealDetails, ...value } };
      });
    },
    []
  );

  const updateLocalDealConfigHurdle = useCallback(
    (index: number, value: Partial<PurchaseOrderDealConfig>) => {
      setLocalDeal((prev) => {
        if (!prev || !prev.dealDetails.dealConfigs?.value) return prev;

        const updatedConfigs = prev.dealDetails.dealConfigs.value.map(
          (config, i) => (i === index ? { ...config, ...value } : config)
        );

        return {
          ...prev,
          dealDetails: {
            ...prev.dealDetails,
            dealConfigs: posChangedField(updatedConfigs),
          },
        };
      });
    },
    []
  );

  const deleteLocalDealConfigHurdle = useCallback((index: number) => {
    setLocalDeal((prev) => {
      if (!prev || !prev.dealDetails.dealConfigs?.value) return prev;

      const updatedConfigs = prev.dealDetails.dealConfigs.value.filter(
        (_, i) => i !== index
      );

      return {
        ...prev,
        dealDetails: {
          ...prev.dealDetails,
          dealConfigs: posChangedField(updatedConfigs),
        },
      };
    });
  }, []);

  const activeUserIds = useMemo(
    () =>
      allActiveUserInfos
        ? Object.values(allActiveUserInfos).map((u) => u.userId)
        : [],
    [allActiveUserInfos]
  );

  const assignedPriorities = useMemo(() => {
    return new Set(
      localDeal?.dealDetails.dealConfigs?.value
        ?.map((config) => config.priority)
        .filter((priority): priority is number => priority !== null)
    );
  }, [localDeal?.dealDetails]);

  const isValidDeal = useMemo(() => {
    if (
      !localDeal ||
      !localDeal.dealDetails.dealConfigs?.value ||
      !localDeal.dealDetails.dealFallbackConfig
    )
      return true;

    const validHurdles = localDeal.dealDetails.dealConfigs.value.every(
      (config) => {
        const vendorSplit = config.vendorCommission.splitValue;
        const totalPurchaserSplit = config.purchaserCommissions.reduce(
          (acc, split) => acc + split.splitValue,
          0
        );
        return vendorSplit + totalPurchaserSplit === 100;
      }
    );

    const validFallbackHurdle =
      localDeal.dealDetails.dealFallbackConfig.vendorCommission.splitValue +
        localDeal.dealDetails.dealFallbackConfig.purchaserCommissions.reduce(
          (acc, split) => acc + split.splitValue,
          0
        ) ===
      100;

    return (
      validHurdles &&
      validFallbackHurdle &&
      assignedPriorities.size === localDeal.dealDetails.dealConfigs.value.length
    );
  }, [assignedPriorities.size, localDeal]);

  return (
    <DealContext.Provider
      value={{
        localDeal,
        setLocalDeal,
        updateLocalDealDetails,
        updateLocalDealConfigHurdle,
        deleteLocalDealConfigHurdle,
        isEditDeal,
        setIsEditDeal,
        activeUserIds,
        isValidDeal,
        assignedPriorities,
      }}
    >
      {children}
    </DealContext.Provider>
  );
};

export const useDealContext = (): DealContextValue => {
  const context = useContext(DealContext);
  if (!context) {
    throw new Error('useDealContext must be used within a DealProvider');
  }
  return context;
};
