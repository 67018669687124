import { groupBy, keyBy } from 'lodash-es';
import { getContent, IContentContext } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { kmeans, VectorWithData } from 'src/utils/kmeans';
import {
  BucketType,
  Listing,
  SectionInfo,
  SectionSectionGroup,
} from 'src/WebApiController';

import {
  ListingGroupBy,
  ListingGroupType,
  RowRange,
} from './GroupListingsV2.types';

export type ListingGroup = {
  name: string;
  listings: Listing[];
};

const getNameContent = (
  groupingType: ListingGroupType,
  contentContext: IContentContext
) => {
  switch (groupingType) {
    case ListingGroupType.Section:
      return getContent(ContentId.Section, contentContext);
    case ListingGroupType.Row:
      return getContent(ContentId.Row, contentContext);
    case ListingGroupType.Zone:
      return getContent(ContentId.Zone, contentContext);
    case ListingGroupType.Quantity:
      return getContent(ContentId.UnsoldQty, contentContext);
    case ListingGroupType.UnitCost:
      return getContent(ContentId.UnitCost, contentContext);
  }

  return '';
};

export class ListingGroupsGenerator {
  private listings: Listing[];
  private sections: SectionInfo[];
  private sectionGroups: SectionSectionGroup[];
  private listingGroups: ListingGroup[];
  constructor(
    listings: Listing[],
    sections: SectionInfo[],
    sectionGroups: SectionSectionGroup[]
  ) {
    this.listings = listings.filter(
      (listing) => listing.rowId || listing.seating.rowId
    );
    this.sections = sections;
    this.sectionGroups = sectionGroups;
    this.listingGroups = [{ name: '', listings: this.listings }];
  }

  public getListingGroups(): ListingGroup[] {
    return this.listingGroups;
  }

  public generateBaseZoneGroups(): ListingGroupsGenerator {
    const rowIdToSectionId = this.sections.reduce(
      (acc, section) => {
        for (const row of section.rows) {
          acc[row.id] = section.id;
        }
        return acc;
      },
      {} as Record<number, number>
    );
    const sectionToSectionGroup = keyBy(
      this.sectionGroups,
      (group) => group.sectId
    );
    const sectionListings = groupBy(this.listings, (listing) => {
      if (listing.rowId) {
        return rowIdToSectionId[listing.rowId];
      }
      return rowIdToSectionId[listing.seating.rowId!];
    });
    const sectionGroups = Object.entries(sectionListings)
      .map(([sectionId, listings]) => {
        const sectionGroup = sectionToSectionGroup[sectionId];
        if (!sectionGroup) {
          return undefined;
        }
        const name = `${sectionGroup.ticketClassName} ${
          sectionGroup.sectionGroupAreaType ?? sectionGroup.sectionGroupAreaName
        }`;
        return {
          name,
          listings,
        };
      })
      .filter((group): group is ListingGroup => !!group);

    // Group by zone and area
    this.listingGroups = Object.entries(
      groupBy(sectionGroups, (group) => group.name)
    ).map(([name, groups]) => {
      return {
        name,
        listings: groups.flatMap((g) => g.listings),
      };
    });

    return this;
  }

  public splitByRowRanges(rowRanges: RowRange[]): ListingGroupsGenerator {
    if (rowRanges.length === 0) {
      return this;
    }
    const speculativeRowIds = this.sections.flatMap((section) =>
      section.rows.filter((row) => row.ordinal == null).map((row) => row.id)
    );
    const rowIdToIndex = this.sections.reduce(
      (res, section) => {
        const sortedRows = section.rows
          .filter((row) => row.ordinal != null)
          .sort((a, b) => a.ordinal! - b.ordinal!);
        for (const [index, row] of sortedRows.entries()) {
          res[row.id] = index + 1;
        }
        return res;
      },
      {} as Record<number, number | null>
    );
    this.listingGroups = this.listingGroups
      .flatMap((group) => {
        return rowRanges.map((rowRange): ListingGroup | null => {
          const rangedListings = group.listings.filter((listing) => {
            const rowId = listing.rowId ?? listing.seating.rowId;
            if (rowId == null) {
              return false;
            }
            // Putting speculative rows to the first row range
            if (speculativeRowIds.includes(rowId)) {
              return rowRange.min === 1;
            }
            const ordinal = rowIdToIndex[rowId];
            return (
              ordinal && ordinal >= rowRange.min && ordinal <= rowRange.max
            );
          });
          if (rangedListings.length > 0) {
            const { min, max } = rowRange;
            const rangeText =
              min === max
                ? min
                : max === Number.MAX_VALUE
                ? `${min}+`
                : `${min}-${max}`;
            const updatedName = `${group.name} ${rangeText}`;
            return {
              name: updatedName,
              listings: rangedListings,
            };
          }
          return null;
        });
      })
      .filter((group: ListingGroup | null): group is ListingGroup => !!group);

    return this;
  }

  public splitWithGroupBy(
    groupBy: ListingGroupBy,
    contentContext: IContentContext
  ): ListingGroupsGenerator {
    const { groupingType } = groupBy;
    const groupNamePrefix = getNameContent(groupingType, contentContext);
    switch (groupingType) {
      case ListingGroupType.Zone:
        this.listingGroups = this.splitByZone(groupNamePrefix);
        break;
      case ListingGroupType.Section:
        this.listingGroups = this.splitBySection(groupNamePrefix);
        break;
      case ListingGroupType.Row:
        this.listingGroups = this.splitByRow(groupNamePrefix);
        break;
      case ListingGroupType.Quantity:
      case ListingGroupType.UnitCost:
        this.listingGroups = this.splitByBucket(groupBy, groupNamePrefix);
        break;
      default:
        break;
    }
    return this;
  }

  private splitByZone(groupNamePrefix: string): ListingGroup[] {
    const rowIdToZone = this.sections.reduce(
      (res, section) => {
        for (const row of section.rows) {
          res[row.id] = row.tktClass?.name ?? 'Unknown';
        }
        return res;
      },
      {} as Record<number, string>
    );
    return this.listingGroups.flatMap((group) => {
      return Object.entries(
        groupBy(
          group.listings,
          (listing) => rowIdToZone[listing.rowId ?? 0] ?? 'Unknown'
        )
      )
        .map(([zone, listings]) => {
          return {
            name: this.getGroupName(group.name, groupNamePrefix, zone),
            listings,
          };
        })
        .filter((group) => group.listings.length > 0);
    });
  }

  private splitBySection(groupNamePrefix: string): ListingGroup[] {
    const rowIdToSection = this.sections.reduce(
      (res, section) => {
        for (const row of section.rows) {
          res[row.id] = section.name ?? 'Unknown';
        }
        return res;
      },
      {} as Record<number, string>
    );
    return this.listingGroups.flatMap((group) => {
      return Object.entries(
        groupBy(group.listings, (listing) => rowIdToSection[listing.rowId ?? 0])
      )
        .map(([name, listings]) => {
          return {
            name: this.getGroupName(
              group.name,
              groupNamePrefix,
              name ?? 'Unknown'
            ),
            listings,
          };
        })
        .filter((group) => group.listings.length > 0);
    });
  }

  private splitByRow(groupNamePrefix: string): ListingGroup[] {
    const rowIdToName = this.sections.reduce(
      (res, section) => {
        for (const row of section.rows) {
          res[row.id] = row.name ?? 'Unknown';
        }
        return res;
      },
      {} as Record<number, string>
    );
    return this.listingGroups.flatMap((group) => {
      return Object.entries(
        groupBy(group.listings, (listing) => rowIdToName[listing.rowId ?? 0])
      )
        .map(([name, listings]) => {
          return {
            name: this.getGroupName(
              group.name,
              groupNamePrefix,
              name ?? 'Unknown'
            ),
            listings,
          };
        })
        .filter((group) => group.listings.length > 0);
    });
  }

  private splitByBucket(
    groupBy: ListingGroupBy,
    groupNamePrefix: string
  ): ListingGroup[] {
    const { groupingType, numberOfBuckets, bucketOption } = groupBy;
    return this.listingGroups.flatMap((group) => {
      const listings = group.listings;
      const divider =
        bucketOption === BucketType.SameSize
          ? this.sameSizeDivider(listings, numberOfBuckets ?? 2)
          : this.kMeansDivider(listings, groupingType, numberOfBuckets ?? 2);
      return divider
        .map((listings, index) => {
          return {
            name: this.getGroupName(
              group.name,
              groupNamePrefix,
              `${index + 1}`
            ),
            listings,
          };
        })
        .filter((group) => group.listings.length > 0);
    });
  }

  private sameSizeDivider(
    listings: Listing[],
    numberOfBuckets: number
  ): Listing[][] {
    const bucketSize = Math.ceil(listings.length / numberOfBuckets);
    const result = [];
    for (let i = 0; i < listings.length; i += bucketSize) {
      result.push(listings.slice(i, i + bucketSize));
    }
    return result;
  }

  private kMeansDivider(
    listings: Listing[],
    groupType: ListingGroupType,
    numberOfBuckets: number
  ): Listing[][] {
    const sanitizedBuckets = Math.min(numberOfBuckets, listings.length);
    const dataset = listings.map(
      (listing) =>
        ({
          vector: [this.getListingClusterValue(listing, groupType)],
          data: listing,
        }) as VectorWithData<Listing>
    );
    return kmeans(dataset, sanitizedBuckets).clusters.map((cluster) =>
      cluster.vectors.flatMap((v) => v.data)
    );
  }

  private getListingClusterValue(
    listing: Listing,
    groupingType: ListingGroupType
  ) {
    switch (groupingType) {
      case ListingGroupType.Quantity:
        return listing.availQty;
      case ListingGroupType.UnitCost:
        return listing.unitCst?.amt ?? 0;
    }
  }

  private getGroupName(prevName: string, subPrefix: string, value: string) {
    if (prevName.trim().length === 0) {
      return `${subPrefix} ${value}`;
    }
    return `${prevName} ${subPrefix} ${value}`;
  }
}
