import { first, isEmpty } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import * as Tabs from 'src/core/ui/Tabs';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Listing,
  ListingGroupItemInput,
  MergeListingGroupInput,
} from 'src/WebApiController';

import { GroupListingsFormProps } from '../../GroupListingsV2.types';
import { GroupListingsPreview } from './GroupListingsPreview';
import * as styles from './GroupListingsPreview.css';
import { UngroupedListingGroupId } from './GroupListingsPreviewTable.constants';

type GroupPreivewProps = {
  eventListings: Listing[];
};

export const GroupsPreview: React.FC<GroupPreivewProps> = ({
  eventListings,
}) => {
  const { watch } = useFormContext<GroupListingsFormProps>();
  const groups = watch('mergeListingGroupInputs');

  const [selectedGroup, setSelectedGroup] = useState<string>();
  const ungroupedText = useContent(ContentId.Ungrouped);

  const ungroupedListings = useMemo(() => {
    const groupedListingIds = groups.flatMap((group) =>
      group.listingGroupItems.map(({ listingId }) => listingId)
    );
    return eventListings.filter(({ id }) => !groupedListingIds.includes(id));
  }, [eventListings, groups]);

  const ungroupedListingGroup = useMemo((): MergeListingGroupInput | null => {
    if (isEmpty(ungroupedListings)) {
      return null;
    }
    return {
      listingGroupId: UngroupedListingGroupId,
      name: ungroupedText,
      viagogoEventId: null,
      viagogoMappingId: null,
      marketplaceSettings: null,
      maxActiveListings: null,
      minActiveListings: null,
      groupingMethods: null,
      groupUndercutSetting: null,
      desiredActiveListings: null,
      deprioritizedQuantities: null,
      targetPercentage: null,
      listingGroupItems: ungroupedListings.map(
        (listing, index) =>
          ({
            listingId: listing.id,
            priority: index + 1,
            groupId: null,
            undercutSetting: null,
          }) as ListingGroupItemInput
      ),
    };
  }, [ungroupedListings, ungroupedText]);

  const enrichedGroups = useMemo(() => {
    if (ungroupedListingGroup === null) {
      return groups;
    }
    return [...groups, ungroupedListingGroup];
  }, [groups, ungroupedListingGroup]);

  useEffect(() => {
    if (isEmpty(enrichedGroups)) {
      return;
    }
    const firstGroup = first(enrichedGroups);
    const ids = enrichedGroups.map(({ listingGroupId }) => listingGroupId);
    if (selectedGroup && ids.includes(selectedGroup)) {
      return;
    }
    setSelectedGroup(firstGroup!.listingGroupId);
  }, [enrichedGroups, selectedGroup]);

  if (isEmpty(groups)) {
    return <PosSpinner />;
  }
  return (
    <Tabs.Root
      value={selectedGroup}
      onValueChange={setSelectedGroup}
      defaultValue={selectedGroup}
      className={styles.overrideTabRoot}
    >
      <Tabs.List>
        {enrichedGroups.map((group) => {
          return (
            <Tabs.Trigger
              key={group.listingGroupId}
              value={group.listingGroupId}
            >
              {group.name}
            </Tabs.Trigger>
          );
        })}
      </Tabs.List>
      {enrichedGroups.map((group) => {
        return (
          <Tabs.Content key={group.listingGroupId} value={group.listingGroupId}>
            <GroupListingsPreview
              listingGroup={group}
              eventListings={eventListings}
            />
          </Tabs.Content>
        );
      })}
    </Tabs.Root>
  );
};
