import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  ColumnDef,
  ExpandedState,
  functionalUpdate,
  getExpandedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  TableOptions,
  Updater,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { EventAccordionItemBodyComponentType } from 'src/components/Accordions';
import { shiftSelect } from 'src/components/Events/EventPage/EventPage.css';
import { useSingleTableShortcutSelection } from 'src/components/Events/EventPage/hooks/useSingleTableShortcutSelection';
import { ListingDisplay } from 'src/components/Listings/ListingDisplay';
import { createFakeListingGroup } from 'src/components/Listings/listingUtils';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useActiveSortTableColumnContext } from 'src/contexts/ActiveSortTableColumnContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { FocusContext } from 'src/contexts/InputPriceFocusContext/FocusContext.types';
import { useInputPriceFocusContext } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import { ModalContext } from 'src/contexts/ModalContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { useClearShiftSelectionOnSortingChange } from 'src/contexts/MultiSelectionContext/useClearShiftSelectionOnSortingChange';
import { Stack } from 'src/core/ui';
import { useSortListingTableSeatingColumn } from 'src/hooks/useSortListingTableSeatingColumn';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { FormatOption, FormatOptionEntries } from 'src/modals/EditTableColumns';
import {
  flattenListingGroup,
  sortGroupType,
} from 'src/modals/GroupListings/components/groupingUtils';
import { GroupIdNameMap } from 'src/modals/MergeListings/utils';
import {
  inventoryEventDetailsTableCollapsed,
  listingTableNoVirtuosoWrapper,
} from 'src/tables/ListingTable/ListingTable.css';
import { NoData, Table } from 'src/tables/Table';
import {
  defaultListingColumnsConfig,
  filterColumnsByFeatures,
  getListingColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { LISTING_MAIN_COLUMNS } from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { ListingTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { filterCustomColumnsForListing } from 'src/utils/columns/inventory/inventoryCustomColumnUtils';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { generateShimmeringRows } from 'src/utils/dataTableUtils';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';
import { sortGroupedListing } from 'src/utils/tableUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  Feature,
  GroupType,
  Listing,
  ListingClient,
  ListingDetailDataField,
  ListingGroup,
  ListingStatus,
  SwapListingGroupInput,
  UserSetting,
} from 'src/WebApiController';

import { RowWrapper } from '../Table/Table.types';
import { usePaginationSettings } from '../Table/usePaginationSettings';
import { ListingPricingForm } from './configs/ListingPriceForm';
import {
  customColumnDef,
  getListingTableColumnConfigs,
  tagColumnDef,
} from './configs/ListingTableColumnsConfig';
import * as styles from './ListingTable.css';
import { ListingWithEvent } from './ListingTable.types';

export type ListingTableProps = EventAccordionItemBodyComponentType & {
  getListingNotEligibleForSelection?: (
    listings: Listing[] | null
  ) => { listing: Listing; ineligibleReason: string | undefined }[] | undefined;
  showMainColumnsOnly?: boolean;
  hideUnselectableListings?: boolean;
  showUnselectableListingsOnly?: boolean;
  disablePagination?: boolean;
  compListings?: Listing[];
  getListingTicketClassColor?: (listing: Listing) => string | undefined;
  listingGroupMap?: GroupIdNameMap;
  isSideTable?: boolean;
};

export const PAGE_SIZE = 10;

export const ListingTable = ({
  event,
  listings,
  compListings,
  listCnt: listingCount,
  ungrListCnt: ungroupedListingCount,
  listGrpCnt: listingGroupCount,
  getDataFail: failedToRetrieveData,
  selectedIds,
  selectionMode: defaultSelectionMode,
  onItemSelectionsChanged,
  getListingNotEligibleForSelection,
  hideUnselectableListings,
  showUnselectableListingsOnly,
  showMainColumnsOnly,
  disablePagination,
  onMount,
  onUnmount,
  initState,
  useVirtuoso,
  onVirtuosoTableScroll,
  getListingTicketClassColor,
  tableWithOuterPadding,
  useTableNavKeys = false,
  listingGroupMap,
  isSideTable,
}: ListingTableProps) => {
  const groupId = event.viagVirtualId;
  const { appContext, loginContext, activeAccountWebClientConfig } =
    useAppContext();
  const listingText = useContent(ContentId.Listings);
  const isItemsLoading =
    listingCount > 0 && (listings == null || listings.length == 0);
  const hasSortListingsInGroupFeature = useUserHasFeature(
    Feature.SortListingsInGroup
  );

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const { activeSortTableColumn, setActiveSortTableColumn } =
    useActiveSortTableColumnContext();
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );
  const hasReorderListingInGroupFeature = useUserHasFeature(
    Feature.ReorderListingInGroup
  );

  const { setActivePosEntity } = useActivePosEntityContext<Listing>();
  const { setModal } = useContext(ModalContext);
  const {
    selectionMode: selectionModeOverride,
    setGroupItems,
    getSelection,
    getGroupToggleState,
  } = useMultiSelectionContext();

  const selectionMode = selectionModeOverride ?? defaultSelectionMode;

  const listingSelection = getSelection(event.viagVirtualId);
  const soldGroupName = useContent(ContentId.Sold);
  const isGroupSoldListingsEnabled = useUserHasFeature(
    Feature.GroupSoldListings
  );

  const [sorting, setSorting] = useState<SortingState>(
    activeSortTableColumn ||
      initState?.sorting || [
        { id: 'actions', desc: false },
        { id: 'seating', desc: false },
      ]
  );
  useClearShiftSelectionOnSortingChange({ sortingState: sorting });
  const { showErrorDialog } = useErrorBoundaryContext();
  const { updateExpandedListItems } = useCatalogDataContext();
  const [expandedState, setExpandedState] = useState<ExpandedState>(
    initState?.expanded ?? {}
  );

  const { isEnabled, isCollapsedView } = useCollapsableViewContext();

  // This is to pre-expand all the groups on first-load
  useEffect(() => {
    if (
      Object.keys(expandedState).length === 0 &&
      listings?.some((l) => l.isLtGrp)
    ) {
      setExpandedState(
        listings?.reduce(
          (r, l) => {
            if (l.isLtGrp) {
              r[l.id.toString()] = true;
              (l as ListingGroup).groupItems.forEach((i) => {
                if ((i as ListingGroup).groupType === GroupType.Active) {
                  r[i.id.toString()] = true;
                }
              });
            }

            return r;
          },
          {} as Record<string, boolean>
        ) ?? {}
      );
    }
  }, [expandedState, listings]);

  // ListingTable don't always have these context wrappers - remember not to assume these are NOT NULL
  const {
    setDisabledFocusContexts,
    disabledFocusContexts,
    inputPriceFocusContext,
  } = useInputPriceFocusContext();

  const currentPage = useMemo(() => {
    return inputPriceFocusContext &&
      inputPriceFocusContext.viagVirtualId === event.viagVirtualId &&
      inputPriceFocusContext.rowIndex < listingCount
      ? inputPriceFocusContext.currentPageIndex
      : -1;
  }, [inputPriceFocusContext, event.viagVirtualId, listingCount]);

  const data = useMemo<(ListingWithEvent | null)[]>(() => {
    const listingsWithIneligibleReasons =
      getListingNotEligibleForSelection?.(listings) ??
      listings?.map((l) => {
        sortGroupedListing(l);
        return {
          listing: l,
          ineligibleReason: undefined,
        };
      });

    const listingsMapped: ListingWithEvent[] | undefined =
      listingsWithIneligibleReasons?.map((l) => ({
        event,
        listing: l.listing,
        selectionDisabledReason: l.ineligibleReason,
        ticketClassColor: getListingTicketClassColor?.(l.listing),
        isLtGrp: l.listing.isLtGrp,
        ltGroupName:
          listingGroupMap && l.listing.ltGrpId
            ? listingGroupMap[l.listing.ltGrpId]
            : undefined,
      }));

    // Add comp listings to the list
    if (compListings != null) {
      // Only add when own listings are done loading
      listingsMapped?.push(
        ...compListings.map((l) => ({
          event,
          listing: l,
          selectionDisabledReason: undefined,
          isCompListing: true,
          ticketClassColor: getListingTicketClassColor?.(l),
        }))
      );
    }
    let result = listingsMapped?.filter((l) =>
      showUnselectableListingsOnly
        ? Boolean(l.selectionDisabledReason)
        : hideUnselectableListings
        ? !l.selectionDisabledReason
        : true
    );

    if (isGroupSoldListingsEnabled) {
      const ungroupedSoldListings = result?.filter(
        (listing) =>
          !!listing &&
          !listing.isLtGrp &&
          !listing.listing?.ltGrpId &&
          listing?.listing?.status === ListingStatus.FullySold
      );

      if (ungroupedSoldListings?.length) {
        const fakeListingGroup = createFakeListingGroup(
          ungroupedSoldListings,
          soldGroupName
        );
        if (fakeListingGroup) {
          result = result ?? [];
          result = [
            ...result.filter((d) => !ungroupedSoldListings.includes(d)),
            fakeListingGroup,
          ];
        }
      }
    }

    return result?.length
      ? result
      : result == null || isItemsLoading
      ? (generateShimmeringRows(
          ungroupedListingCount,
          listingGroupCount
        ) as (ListingWithEvent | null)[])
      : result;
  }, [
    getListingNotEligibleForSelection,
    listings,
    compListings,
    isGroupSoldListingsEnabled,
    isItemsLoading,
    ungroupedListingCount,
    listingGroupCount,
    event,
    getListingTicketClassColor,
    listingGroupMap,
    showUnselectableListingsOnly,
    hideUnselectableListings,
    soldGroupName,
  ]);

  const { pagination, setPagination } = usePaginationSettings(
    data.length,
    currentPage,
    PAGE_SIZE,
    disablePagination,
    initState
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  // Clear selections when selection mode changes
  useEffect(() => {
    onItemSelectionsChanged?.([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionMode?.mode]);

  const rowSelection = useMemo(() => {
    return selectionMode == null
      ? {}
      : data
          ?.filter((d) => !d?.isCompListing)
          .flatMap((d) => [
            d?.listing,
            ...((d?.listing as ListingGroup)?.groupItems ?? []),
            ...((d?.listing as ListingGroup)?.groupItems ?? []).flatMap(
              (lg) => (lg as ListingGroup).groupItems ?? []
            ),
          ])
          ?.reduce<Record<string, boolean>>((result, listing) => {
            if (listing?.id) {
              result[listing.id] =
                getGroupToggleState(event.viagVirtualId).isGroupSelected &&
                !listingSelection.items.itemIds.length
                  ? true
                  : (listingSelection?.items.itemIds ?? selectedIds)?.includes(
                      String(listing?.id)
                    ) ?? false;
            }
            return result;
          }, {});
  }, [
    data,
    event.viagVirtualId,
    getGroupToggleState,
    listingSelection.items.itemIds,
    selectedIds,
    selectionMode,
  ]);

  const {
    lastSelectedRowRefIndex,
    allowShiftClickSelection,
    isShiftKeyPressed,
    isCtrlOrCmdKeyPressed,
    updateSelectionForRowAndSubRows,
  } = useSingleTableShortcutSelection<ListingWithEvent>({ isSideTable });

  const onRowSelectionChange = useCallback(
    (updater: Updater<RowSelectionState>) => {
      const newSelections =
        typeof updater === 'function' ? updater(rowSelection) : updater;
      const newSelectedIds = Object.keys(newSelections).filter(
        (r) => newSelections[r] === true
      );
      if (selectionMode?.mode === 'singleItem' && !allowShiftClickSelection) {
        newSelectedIds.forEach((id) => {
          if (rowSelection[id] && newSelections[id]) {
            // if both of these are the same value and is true, we need to set it to false
            newSelections[id] = false;
          }
        });
      }
      setGroupItems(groupId, newSelections);
      onItemSelectionsChanged?.(
        newSelectedIds
          .filter((id) => newSelections[id])
          .map((id) => parseInt(id))
      );
    },
    [
      rowSelection,
      selectionMode?.mode,
      allowShiftClickSelection,
      setGroupItems,
      groupId,
      onItemSelectionsChanged,
    ]
  );

  const {
    value: storedInventoryColumnOrderSetting = defaultListingColumnsConfig,
  } = useServerUserSetting<ListingTableColumnId[]>({
    id: isSideTable
      ? UserSetting.InventorySideTableColumnOrder
      : UserSetting.InventoryColumnOrder,
  });
  const { value: storedInventoryColumnNumberPrecisions = {} } =
    useServerUserSetting<FormatOptionEntries>({
      id: isSideTable
        ? UserSetting.InventorySideTableColumnNumberPrecision
        : UserSetting.InventoryColumnNumberPrecision,
    });

  const { value: storedInventoryColumnsEnabledSetting } = useServerUserSetting<
    string[]
  >({
    id: isSideTable
      ? UserSetting.InventorySideTableColumnsEnabled
      : UserSetting.InventoryColumnsEnabled,
  });

  const { value: customListingColumns = [] } = useServerUserSetting<
    CustomListingColumn[]
  >({
    id: UserSetting.InventoryCustomColumns,
  });
  const { tagsMetadata, tagsMetadataNumeric } = useTagsForEntityType(
    ActionOutboxEntityType.Listing,
    true
  );

  const customListingColumnsFiltered = filterCustomColumnsForListing(
    customListingColumns,
    tagsMetadataNumeric
  );

  const hasGroup = useMemo(
    () => listings?.find((l) => l.isLtGrp) != null,
    [listings]
  );

  const allColumnConfigs = useMemo(
    () => getListingTableColumnConfigs(hasSortListingsInGroupFeature, hasGroup),
    [hasGroup, hasSortListingsInGroupFeature]
  );

  const showCheckbox =
    selectionMode?.mode === MultiSelectScope.AllGroups ||
    listingSelection.isSingleGroupMultiSelect;

  const displayedColumnsConfig = useMemo(() => {
    if (showMainColumnsOnly) {
      return LISTING_MAIN_COLUMNS.filter((c) => {
        if (c === ListingTableColumnId.Checkbox) {
          return showCheckbox;
        }
        // Only show Seating Row and Seats if Seating is set to Section Only
        if (
          c === ListingTableColumnId.SeatingRow ||
          c === ListingTableColumnId.SeatingSeats
        ) {
          return (
            storedInventoryColumnNumberPrecisions[
              ListingTableColumnId.Seating
            ] === FormatOption.Seating_SectionOnly
          );
        }
        return true;
      }).reduce<ColumnDef<ListingWithEvent | null>[]>((acc, columnId) => {
        const columnConfig = Object.values(allColumnConfigs).find(
          (column) => column.id === columnId
        );
        if (columnConfig) {
          acc.push(columnConfig as ColumnDef<ListingWithEvent | null>);
        }
        return acc;
      }, []);
    }

    const inventoryColumnOrder = storedInventoryColumnOrderSetting.filter(
      // If storedInventoryColumnsEnabledSetting has never been set, default to true
      (c) => storedInventoryColumnsEnabledSetting?.includes(c) ?? true
    );

    const columns = new Set([
      // Add unconfigurable columns first
      ...Object.values(allColumnConfigs)
        .filter((column) => {
          const columnDef = getListingColumnConfigById(
            column.id as ListingTableColumnId
          ).personalization;

          const hasFeatureForColumn =
            columnDef.requiredFeatures.length === 0 ||
            hasFeatures(
              loginContext?.user,
              appContext?.features,
              columnDef.requiredFeatures
            );

          let isConfigurable = columnDef.isConfigurable;
          if (
            !hasTablePinActionColumnFeature &&
            column.id === ListingTableColumnId.Actions
          ) {
            isConfigurable = true;
          }

          return !isConfigurable && hasFeatureForColumn;
        })
        .map((item) => item.id as ListingTableColumnId),
      // Add user defined columns next
      ...filterColumnsByFeatures(
        inventoryColumnOrder,
        SectionType.Listings,
        customListingColumnsFiltered,
        loginContext?.user,
        appContext?.features,
        tagsMetadata ?? []
      ),
    ]);

    if (showCheckbox) {
      columns.delete(ListingTableColumnId.Actions);
    } else {
      columns.delete(ListingTableColumnId.Checkbox);
    }

    return Array.from(columns).reduce<ColumnDef<ListingWithEvent | null>[]>(
      (acc, columnId) => {
        if (tagsMetadata?.find((t) => t.key === columnId)) {
          acc.push(tagColumnDef(columnId));
        } else if (
          customListingColumnsFiltered.find((c) => c.id === columnId)
        ) {
          acc.push(
            customColumnDef(
              columnId,
              customListingColumnsFiltered.find((c) => c.id === columnId)
                ?.formula
            )
          );
        } else {
          const columnConfig = Object.values(allColumnConfigs).find(
            (column) => column.id === columnId
          );
          if (columnConfig) {
            acc.push(columnConfig as ColumnDef<ListingWithEvent | null>);
          }
        }

        return acc;
      },
      []
    );
  }, [
    showMainColumnsOnly,
    storedInventoryColumnOrderSetting,
    allColumnConfigs,
    customListingColumnsFiltered,
    loginContext?.user,
    appContext?.features,
    tagsMetadata,
    showCheckbox,
    storedInventoryColumnNumberPrecisions,
    storedInventoryColumnsEnabledSetting,
    hasTablePinActionColumnFeature,
  ]);

  const onRowShiftSelect = useCallback(
    (
      tableRows: Row<ListingWithEvent | null>[] | undefined,
      row: Row<ListingWithEvent | null>,
      index: number
    ) => {
      if (!tableRows || !allowShiftClickSelection || showCheckbox) {
        return;
      }

      const rowId = row.id.toString();
      const newIsSelected = !rowSelection[rowId];

      if (isShiftKeyPressed && lastSelectedRowRefIndex.current !== null) {
        const start = Math.min(lastSelectedRowRefIndex.current, index);
        const end = Math.max(lastSelectedRowRefIndex.current, index);

        const newRowSelection = { ...rowSelection };

        for (let i = start; i <= end; i++) {
          const row = tableRows[i];
          if (row) {
            updateSelectionForRowAndSubRows({
              row,
              rowId: row.id.toString(),
              newIsSelected,
              newRowSelection,
            });
          }
        }

        lastSelectedRowRefIndex.current = index;
        onRowSelectionChange(newRowSelection);
      } else if (isCtrlOrCmdKeyPressed) {
        const newRowSelection = { ...rowSelection };
        newRowSelection[rowId] = newIsSelected;
        updateSelectionForRowAndSubRows({
          row,
          rowId,
          newIsSelected,
          newRowSelection,
        });
        lastSelectedRowRefIndex.current = index;
        onRowSelectionChange(newRowSelection);
      } else {
        // No modifier key pressed, reset selection
        const newRowSelection = {};

        updateSelectionForRowAndSubRows({
          row,
          rowId,
          newIsSelected,
          newRowSelection,
        });

        lastSelectedRowRefIndex.current = index;
        onRowSelectionChange(newRowSelection);
      }
    },
    [
      allowShiftClickSelection,
      isCtrlOrCmdKeyPressed,
      isShiftKeyPressed,
      lastSelectedRowRefIndex,
      onRowSelectionChange,
      rowSelection,
      showCheckbox,
      updateSelectionForRowAndSubRows,
    ]
  );

  const sectionType = isSideTable
    ? SectionType.InventorySideTable
    : SectionType.Listings;

  const seatingColumnSorting = useSortListingTableSeatingColumn(sectionType);

  const options: Partial<TableOptions<ListingWithEvent | null>> = useMemo(
    () => ({
      data,
      columns: displayedColumnsConfig,
      state: {
        pagination,
        sorting,
        rowSelection,
        expanded: expandedState,
        columnVisibility: {
          [ListingTableColumnId.TicketClassColor]:
            getListingTicketClassColor != null,
        },
        columnPinning: {
          right: hasTablePinActionColumnFeature
            ? [ListingTableColumnId.Actions]
            : undefined,
        },
      },
      meta: {
        sectionType,
      },

      getRowId: (originalRow: ListingWithEvent | null, index: number) => {
        return (originalRow?.listing?.id ?? index).toString();
      },
      onPaginationChange: setPagination,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
        setActiveSortTableColumn(newSortVal);
      },
      onExpandedChange: (newState) => {
        setExpandedState(newState);
      },
      getSubRows: (originalRow) => {
        if (!originalRow?.listing || !originalRow.listing.isLtGrp) {
          return [];
        }
        const listingGroup = originalRow.listing as ListingGroup;
        return (
          listingGroup.groupItems
            ?.sort((a, b) => {
              if (a.isLtGrp && b.isLtGrp) {
                return sortGroupType(
                  (a as ListingGroup).groupType,
                  (b as ListingGroup).groupType
                );
              } else if (a.isLtGrp || b.isLtGrp) {
                return a.isLtGrp ? -1 : 1; // listing group sort first
              }

              return (a.ltGrpPrior ?? -1) - (b.ltGrpPrior ?? -1);
            })
            ?.map(
              (l, i) =>
                ({
                  ...originalRow,
                  rowIndex: i,
                  listing: l,
                  isLtGrp: l.isLtGrp,
                }) as ListingWithEvent
            ) ?? []
        );
      },
      getRowCanExpand: (originalRow) => {
        return Boolean(
          originalRow.original?.listing?.isLtGrp &&
            (originalRow.original?.listing as ListingGroup).groupType !==
              GroupType.Invisible
        );
      },
      getExpandedRowModel: (table) => getExpandedRowModel()(table),
      autoResetExpanded: false,
      paginateExpandedRows: false,
      enableRowSelection: (row) => !row.original?.isCompListing,
      enableMultiRowSelection: (row) => !row.original?.isCompListing,
      onRowSelectionChange,
      enableSubRowSelection: (row) => !row.original?.isCompListing,
      sortingFns: { ...seatingColumnSorting },
    }),
    [
      data,
      displayedColumnsConfig,
      pagination,
      sorting,
      rowSelection,
      expandedState,
      getListingTicketClassColor,
      hasTablePinActionColumnFeature,
      sectionType,
      setPagination,
      onRowSelectionChange,
      seatingColumnSorting,
      setActiveSortTableColumn,
    ]
  );

  const onRowWrapper: RowWrapper<ListingWithEvent | null> = useCallback(
    (rowData, tableRow, rowIndex) => {
      if (rowData?.isCompListing) {
        // If it's comp listing, put into `disabledFocusContexts`
        // We don't want to price on that
        if (
          !disabledFocusContexts?.find(
            (p) =>
              p?.currentPageIndex === pagination.pageIndex &&
              p?.rowIndex === rowIndex &&
              p?.viagVirtualId === event.viagVirtualId
          )
        ) {
          setDisabledFocusContexts((prev: FocusContext[] | undefined) => {
            return [
              ...(prev ?? []),
              {
                viagVirtualId: event.viagVirtualId,
                rowIndex,
                // can be either, we don't use it when checking for focus
                priceField: 'listPrice',
                currentPageIndex: pagination.pageIndex,
              },
            ];
          });
        }
      } else {
        if (
          disabledFocusContexts?.find(
            (p) =>
              p?.currentPageIndex === pagination.pageIndex &&
              p?.rowIndex === rowIndex &&
              p?.viagVirtualId === event.viagVirtualId
          )
        ) {
          setDisabledFocusContexts((prev: FocusContext[] | undefined) => {
            return prev?.filter(
              (p) =>
                p?.currentPageIndex !== pagination.pageIndex ||
                p?.rowIndex !== rowIndex ||
                p?.viagVirtualId !== event.viagVirtualId
            );
          });
        }
      }

      return (
        <ListingPricingForm
          key={rowIndex}
          rowData={
            rowData && {
              ...rowData,
              rowIndex,
              currentPageIndex: pagination.pageIndex,
            }
          }
          tableRow={tableRow}
        />
      );
    },
    [
      disabledFocusContexts,
      event.viagVirtualId,
      pagination.pageIndex,
      setDisabledFocusContexts,
    ]
  );

  const [draggingListing, setDraggingListing] = useState<Listing>();

  const listingsToDrop = useMemo((): Listing[] => {
    if (!draggingListing) {
      return [];
    }
    const selectedIds = Object.entries(rowSelection)
      .filter(([_, value]) => value)
      .map(([key, _]) => key)
      .map(Number);

    if (!isEmpty(selectedIds)) {
      if (selectedIds.includes(draggingListing.id)) {
        const selectedListings = (listings
          ?.flatMap(flattenListingGroup)
          .filter(({ id, isLtGrp }) => !isLtGrp && selectedIds.includes(id)) ??
          []) as Listing[];
        return selectedListings;
      }
    }
    return [draggingListing];
  }, [draggingListing, listings, rowSelection]);

  const onDragListingStart = useCallback(
    (event: DragStartEvent) => {
      const activeListing = listings
        ?.flatMap(flattenListingGroup)
        .find(({ id }) => id === event.active?.data?.current?.listingId);
      if (activeListing) {
        setDraggingListing(activeListing as Listing);
      }
    },
    [listings]
  );

  const onDragListingEnd = useCallback(
    async (e: DragEndEvent) => {
      const { over, active } = e;
      const fromListingGroupId = active?.data?.current
        ?.currentListingGroupId as string | null | undefined;
      const toListingGroupId = over?.data?.current?.ltGrpId as
        | string
        | null
        | undefined;

      const activeListingId = active?.data?.current?.listingId as
        | number
        | null
        | undefined;

      const onDropCompleted = () => {
        const targetListingGroup = listings?.find(
          (l) => l.isLtGrp && l.ltGrpId === toListingGroupId
        );
        if (!targetListingGroup) {
          return;
        }
        const allGroups = (targetListingGroup as ListingGroup).groupItems
          .map((item) => item.id)
          .reduce(
            (res, id) => {
              res[id.toString()] = true;
              return res;
            },
            {} as Record<string, boolean>
          );
        setExpandedState((prev) => {
          if (typeof prev === 'boolean') {
            return prev;
          }
          return {
            ...prev,
            ...allGroups,
            [targetListingGroup.id]: true,
          };
        });

        if (activeListingId) {
          onRowSelectionChange({
            [activeListingId + '']: true,
          });
        }
      };

      // Reorder listing group
      if (
        hasReorderListingInGroupFeature &&
        fromListingGroupId != null &&
        fromListingGroupId === toListingGroupId
      ) {
        const fromPriority = active?.data?.current?.listingGroupPriority as
          | number
          | null
          | undefined;
        const toPriority = over?.data?.current?.listingGroupPriority as
          | number
          | null
          | undefined;

        if (
          activeListingId &&
          fromPriority &&
          toPriority &&
          fromPriority != toPriority
        ) {
          setDraggingListing(undefined);

          return await tryInvokeApi(
            async () => {
              const updatedListings = await new ListingClient(
                activeAccountWebClientConfig
              ).reorderListingInGroup({
                listingId: activeListingId,
                fromPosition: fromPriority,
                toPosition: toPriority,
                listingGroupId: fromListingGroupId,
              });
              updateExpandedListItems(updatedListings);
              onDropCompleted();
            },
            (error) => {
              showErrorDialog(
                'ListingGroupClient.reorderListingInGroup',
                error,
                {
                  trackErrorData: {
                    listingGroupId: fromListingGroupId,
                    listingId: activeListingId,
                  },
                }
              );
            },
            () => {
              setDraggingListing(undefined);
            }
          );
        }
      }

      if (!toListingGroupId) {
        return;
      }

      // No point to drop listing to the same group
      const sanitizedListings = listingsToDrop.filter(
        (l) => l.ltGrpId !== toListingGroupId
      );

      if (isEmpty(sanitizedListings)) {
        return;
      }

      setDraggingListing(undefined);

      return await tryInvokeApi(
        async () => {
          const input: SwapListingGroupInput[] = sanitizedListings.map((l) => ({
            listingId: l.id,
            listingGroupId: l.ltGrpId,
            quantity: l.availQty,
            adminHoldExpirationDate: l.adminHoldExpiredOn,
          }));
          const updatedListings = await new ListingClient(
            activeAccountWebClientConfig
          ).batchSwapListingGroup({
            listings: input,
            toListingGroupId,
          });
          updateExpandedListItems(updatedListings);
          onDropCompleted();
        },
        (error) => {
          showErrorDialog('ListingGroupClient.batchSwapListingGroup', error, {
            trackErrorData: {
              toListingGroupId,
            },
          });
        },
        () => {
          setDraggingListing(undefined);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      hasReorderListingInGroupFeature,
      listings,
      listingsToDrop,
      onRowSelectionChange,
      showErrorDialog,
      updateExpandedListItems,
    ]
  );

  const onRowSelect = useCallback(
    (row: Row<ListingWithEvent | null>) => {
      const listing = row.original?.listing;
      if (listing) {
        setActivePosEntity(listing.id, listing.idOnMkp, true, [
          ListingDetailDataField.Basic,
        ]);
        setModal(getListingDetailsModalConfigWithDeepLink(listing.id, true));
      }
    },
    [setActivePosEntity, setModal]
  );

  // Apply distance on drag event, which will unblock the onClick event
  // for the elements in the draggable component
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 5 },
  });
  const sensors = useSensors(pointerSensor);

  return failedToRetrieveData ? (
    <SomethingWentWrong
      message={<Content id={ContentId.FailToLoadListContent} />}
    />
  ) : data?.length ? (
    <DndContext
      sensors={sensors}
      onDragStart={onDragListingStart}
      onDragEnd={onDragListingEnd}
    >
      <ColumnResizingContextProvider<ListingTableColumnId>
        userSettingId={UserSetting.InventoryColumnWidths}
      >
        <Table
          withOuterPadding={tableWithOuterPadding}
          options={options}
          rowWrapper={onRowWrapper}
          tableLayout="fixed"
          tableHeadStyle={
            disablePagination
              ? {
                  position: 'sticky',
                  top: '0',
                  zIndex: '10',
                }
              : undefined
          }
          tableCellStyle={{ overflow: 'hidden' }}
          useVirtuoso={useVirtuoso}
          onVirtuosoTableScroll={onVirtuosoTableScroll}
          className={clsx({
            [inventoryEventDetailsTableCollapsed]:
              isEnabled &&
              (isCollapsedView || selectionMode?.mode === 'singleGroup'),
            [listingTableNoVirtuosoWrapper]: disablePagination && !useVirtuoso,
            [shiftSelect]: isShiftKeyPressed,
          })}
          useTableNavKeys={
            useTableNavKeys &&
            !(
              selectionMode?.mode === MultiSelectScope.AllGroups ||
              listingSelection.isSingleGroupMultiSelect
            )
          }
          onRowSelect={onRowSelect}
          onRowShiftSelect={onRowShiftSelect}
        />
        {createPortal(
          <DragOverlay>
            {!isEmpty(listingsToDrop) ? (
              <div className={styles.seatDndOverlay}>
                <Stack
                  direction="column"
                  alignItems="center"
                  width="full"
                  style={{ overflow: 'hidden' }}
                  gap="s"
                >
                  {listingsToDrop.map((l) => (
                    <div key={l.id} className={styles.dndDisplayContainer}>
                      <ListingDisplay
                        listing={l}
                        width="full"
                        justifySeatingIconContent="spaceBetween"
                      />
                    </div>
                  ))}
                </Stack>
              </div>
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </ColumnResizingContextProvider>
    </DndContext>
  ) : (
    // We should never see this - because the events are filtered to only those that has listings
    // So this is to prevent a crash - but this should be looked at
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={listingText}
      />
    </NoData>
  );
};

export default ListingTable;
