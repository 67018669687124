import { isEmpty } from 'lodash-es';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext/ModalContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { Colors } from 'src/core/themes/constants/colors';
import { Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { OpenLinkIcon } from 'src/svgs/OpenLinkIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { isInFullPageEventView } from 'src/utils/deepLinkUtils';
import { compareMarketplace } from 'src/utils/eventWithDataUtils';
import {
  getListingActionButtons,
  getListingDetailsDeepLinkUrl,
  getListingDetailsModalConfigWithDeepLink,
  getListingDetailsRelativeUrl,
  getListingGroupForListing,
} from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Event,
  Listing,
  ListingActionType,
  ListingDetailDataField,
  ListingGroup,
  ListingGroupClient,
  ListingStatus,
  Marketplace,
} from 'src/WebApiController';
import styled from 'styled-components';

import { ListingGroupActions } from './ListingGroupActions';
import { ListingGroupStatusSummary } from './ListingGroupStatusSummary';

// Due to limited table width - certain actions are not being shown in the listing table
// but will only show in listing-detail modal view
// These are the actions we do not want to show in the listing table
const NotTableActionTypes = [
  ListingActionType.Split,
  ListingActionType.SplitToOriginal,
  ListingActionType.AddOfflineSale,
  ListingActionType.AddMarketplaceSale,
  ListingActionType.AddAdminHold,
  ListingActionType.RemoveAdminHold,
];

const BroadcastActionTypes = [
  ListingActionType.Broadcast,
  ListingActionType.Unbroadcast,
];

export const ListingActionsCell = ({
  event,
  listing,
  isSubRow,
  disabled,
  linkOnly,
}: {
  event: Event;
  listing: Listing;
  isSubRow?: boolean;
  disabled?: boolean;
  linkOnly?: boolean;
}) => {
  const [actionIsLoading, setActionIsLoading] = useState(false);

  const { isLoading } = useActivePosEntityContext<Listing>();

  const allDisabled = actionIsLoading || isLoading || disabled;

  return (
    <TableCellDiv align="right" showTooltip={false}>
      {listing.isLtGrp ? (
        <ActionCellForListingGroup
          event={event}
          listingGroup={listing as ListingGroup}
          disabled={allDisabled}
          setIsLoading={setActionIsLoading}
          isSubRow={isSubRow}
          linkOnly={linkOnly}
        />
      ) : listing.isFull ? (
        <ActionCellForListing
          listing={listing}
          event={event}
          disabled={allDisabled}
          setIsLoading={setActionIsLoading}
          linkOnly={linkOnly}
        />
      ) : null}
    </TableCellDiv>
  );
};

const ActionCellForListing = ({
  listing,
  event,
  disabled,
  setIsLoading,
  linkOnly,
}: {
  listing: Listing;
  event: Event;
  disabled?: boolean;
  setIsLoading: (state: boolean) => void;
  linkOnly?: boolean;
}) => {
  const { mainRoute, activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    updateItemInEvent,
    eventsExpansion: { refreshExpandedListItems },
    eventsTransformed,
  } = useCatalogDataContext();
  const { setActivePosEntity } = useActivePosEntityContext<Listing>();
  const { setModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const onViewClick = useCallback(
    async (newTab: boolean) => {
      const inFullPageEventView = isInFullPageEventView();
      if (newTab) {
        window.open(getListingDetailsDeepLinkUrl(listing.id), '_blank');
      } else if (mainRoute === MainRoute.Inventory || inFullPageEventView) {
        setActivePosEntity(listing.id, listing.idOnMkp, true, [
          ListingDetailDataField.Basic,
        ]);
        setModal(getListingDetailsModalConfigWithDeepLink(listing.id, true));
      } else {
        navigate(getListingDetailsRelativeUrl(listing.id));
      }
    },
    [
      mainRoute,
      setActivePosEntity,
      listing.id,
      listing.idOnMkp,
      setModal,
      navigate,
    ]
  );

  const lockListingGroupFromEdits = useCallback(
    (lock: boolean) => {
      const listingGroup = getListingGroupForListing(
        event.viagId,
        listing.ltGrpId,
        eventsTransformed
      );

      if (listingGroup) {
        listingGroup.isLockedFromEdits = lock;

        updateItemInEvent(listingGroup, ActionOutboxEntityType.Listing);
      }
    },
    [event.viagId, eventsTransformed, listing.ltGrpId, updateItemInEvent]
  );

  const onSetListingActiveInGroup = useCallback(() => {
    tryInvokeApi(
      async () => {
        setIsLoading(true);

        if (listing.ltGrpId) {
          lockListingGroupFromEdits(true);
          updateItemInEvent(
            {
              ...listing,
              status: ListingStatus.ListingPending,
              ltGrpActive: true,
            },
            ActionOutboxEntityType.Listing
          );
          if (
            await new ListingGroupClient(
              activeAccountWebClientConfig
            ).setListingActiveInGroup(listing.ltGrpId, listing.id, true)
          ) {
            setActivePosEntity(0);
            await refreshExpandedListItems();
          }
        }
      },
      (error) => {
        showErrorDialog('ListingGroupClient.setListingActiveInGroup', error, {
          trackErrorData: listing,
        });
      },
      () => {
        lockListingGroupFromEdits(false);
        setIsLoading(false);
      }
    );
  }, [
    activeAccountWebClientConfig,
    listing,
    lockListingGroupFromEdits,
    refreshExpandedListItems,
    setActivePosEntity,
    setIsLoading,
    showErrorDialog,
    updateItemInEvent,
  ]);

  return (
    <Stack direction="row">
      {!linkOnly &&
        getListingActionButtons(
          true /* for table */,
          listing,
          listing.actions.filter(
            (a) =>
              // Action is not in the not-action-table
              !NotTableActionTypes.includes(a) &&
              // And either not a group, or not-a-subrow-and-is-active, or not a broadcast-action
              (listing.ltGrpId == null ||
                listing.ltGrpActive ||
                !BroadcastActionTypes.includes(a))
          ),
          event,
          undefined,
          true,
          undefined,
          disabled,
          lockListingGroupFromEdits,
          onSetListingActiveInGroup
        )}
      <Link
        style={{ padding: `${vars.spacing['xxs']} 0` }}
        to={getListingDetailsRelativeUrl(listing.id)}
        onClick={(e) => {
          if (e.button === 0 && e.getModifierState('Control') === false) {
            // Perform like a button click when it's a left-click (and no Ctrl)
            // Else the click will be whatever <a /> does
            e.preventDefault();
            e.stopPropagation();

            onViewClick(!!linkOnly);
          }
        }}
      >
        <IconButton
          icon={
            <OpenLinkIcon
              fill={vars.color.textBrand}
              size={vars.iconSize.m}
              withHoverEffect={!disabled}
            />
          }
          disabled={disabled}
          titleContentId={ContentId.ViewDetails}
        />
      </Link>
    </Stack>
  );
};

const AllowedMarkeplaces: Marketplace[] = [
  Marketplace.StubHub,
  Marketplace.VividSeats,
  Marketplace.SeatGeek,
  Marketplace.Ticketmaster,
];
const ActionCellForListingGroup = ({
  event,
  listingGroup,
  disabled,
  setIsLoading,
  isSubRow,
  linkOnly,
}: {
  event: Event;
  listingGroup: ListingGroup;
  disabled?: boolean;
  setIsLoading: (state: boolean) => void;
  isSubRow?: boolean;
  linkOnly?: boolean;
}) => {
  const { marketplaceSettings } = listingGroup;

  const broadcastStatuses = useMemo(() => {
    const status: { marketplace: Marketplace; disabled: boolean }[] = [];
    if (isEmpty(marketplaceSettings?.listingMarketplaceSettings)) {
      return status;
    }
    Object.values(Marketplace).forEach((marketplace, index) => {
      if (AllowedMarkeplaces.includes(marketplace)) {
        const settings =
          marketplaceSettings?.listingMarketplaceSettings?.[index + 1 + ''];
        if (settings?.listingBroadcastStateId) {
          status.push({
            marketplace,
            disabled: settings.listingBroadcastStateId === 1,
          });
        }
      }
    });
    return status;
  }, [marketplaceSettings?.listingMarketplaceSettings]);

  return (
    <Stack direction="column" gap="s">
      <Stack direction="row" gap="m">
        {!isSubRow && <ListingGroupStatusSummary listingGroup={listingGroup} />}
        {!isSubRow && (
          <ListingGroupActions
            event={event}
            listingGroup={listingGroup}
            disabled={disabled}
            setIsLoading={setIsLoading}
          />
        )}
      </Stack>
      {!isSubRow && !isEmpty(broadcastStatuses) && (
        <MarketplaceActionCellForListingGroup
          broadcastStatuses={broadcastStatuses}
        />
      )}
    </Stack>
  );
};

const MarketplaceStyleMap: Record<
  Marketplace,
  { color: string; reverseColor?: string }
> = {
  [Marketplace.StubHub]: { color: Colors.indigo100 },
  [Marketplace.VividSeats]: { color: Colors.brandVivid },
  [Marketplace.SeatGeek]: { color: Colors.brandSeatgeek },
  [Marketplace.TickPick]: { color: Colors.brandTickPick },
  [Marketplace.Ticketmaster]: { color: Colors.brandTicketmaster },
  [Marketplace.Offline]: { color: '#2a9ba3' },
  [Marketplace.AXS]: { color: Colors.brandAxs },
  [Marketplace.FanXchange]: { color: Colors.brandFanXchange },
  [Marketplace.Gametime]: { color: '#21cc8a' },
  [Marketplace.TicketEvolution]: { color: Colors.brandTicketEvolution },
  [Marketplace.TicketNetwork]: { color: Colors.brandTicketNetwork },
  [Marketplace.Automatiq]: { color: '#000000', reverseColor: '#ffffff' },
  [Marketplace.Lyte]: { color: Colors.brandLyte },
  [Marketplace.GoTickets]: { color: Colors.brandGoTickets },
  [Marketplace.B2BSales]: { color: Colors.brandB2BSales },
};

type MarketplaceActionCellForListingGroup = {
  broadcastStatuses: { marketplace: Marketplace; disabled: boolean }[];
};
const MarketplaceActionCellForListingGroup: React.FC<
  MarketplaceActionCellForListingGroup
> = ({ broadcastStatuses }) => {
  const contentDelisted = useContent(ContentId.BroadcastDisabled);
  const contentListed = useContent(ContentId.Broadcast);
  const { isDarkMode } = useSiteTheme();

  return (
    <Stack direction="row" gap="s" style={{ margin: '0 4px' }}>
      {broadcastStatuses
        .sort((a, b) => compareMarketplace(a.marketplace, b.marketplace))
        .map(({ marketplace, disabled }) => {
          const style = MarketplaceStyleMap[marketplace];
          if (!style) {
            return null;
          }
          return (
            <MarketplaceDot
              title={`${marketplace} - ${
                disabled ? contentDelisted : contentListed
              }`}
              key={marketplace}
              color={
                isDarkMode ? style.reverseColor ?? style.color : style.color
              }
              disabled={disabled}
            />
          );
        })}
    </Stack>
  );
};

const MarketplaceDot = styled.div<{ color: string; disabled: boolean }>`
  height: 12px;
  width: 12px;
  background-color: ${({ color, disabled }) => (disabled ? '#bbb' : color)};
  border-radius: 50%;
  display: inline-block;

  &:hover {
    transform: scale(1.2);
  }
`;
