import { ComponentProps, ReactNode, useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  VenueMapConfigPlaceholder,
  VenueMapUrl,
  VenueMapV3Url,
  VenueMapVersionPlaceholder,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature, SectionInfo } from 'src/WebApiController';

import { useCtrlPlusM } from './EventVenueMap.hooks';
import { ColorBandProps } from './shared/components/ColorBand';
import { VenueMapV3 } from './v3/VenueMapV3';
import { VenueMap } from './VenueMap';
import * as styles from './VenueMap.css';

export const EventVenueMap = ({
  selectedSectionIds,
  markedSectionIds,
  onSectionClicked,
  onSectionHovered,
  setSelectedSections,
  onToggleMirrors,
  staticInfoContent,
  infoIconContent,
  isZoomEnabled = true,
  isPanEnabled = true,
  showDefaultMapColors,
  colorBandProps,
  getColor,
  useRowMap = false,
  isHeatMap = false,
  isWhiteBackground = false,
  isRelocatedStaticInfo = false,
  statsContent,
}: {
  selectedSectionIds?: number[];
  markedSectionIds?: number[];
  onSectionClicked?: (
    e: MouseEvent,
    selectedSection: SectionInfo,
    surroundingSections?: SectionInfo[]
  ) => void;
  onSectionHovered?: (hoveredSection: SectionInfo) => ReactNode;
  setSelectedSections?: (sections: SectionInfo[]) => void;
  onToggleMirrors?: (sections: SectionInfo[], exclude?: boolean) => void;
  staticInfoContent?: React.ReactNode;
  infoIconContent?: React.ReactNode;
  isZoomEnabled?: boolean;
  isPanEnabled?: boolean;
  showDefaultMapColors?: boolean;
  colorBandProps?: ColorBandProps;
  /* Callback to get color for a section/ticketClass pair to provide dynamic
   * colorization of sections in the map
   */
  getColor?: ComponentProps<typeof VenueMap>['getColor'];
  useRowMap?: boolean;
  isHeatMap?: boolean;
  isWhiteBackground?: boolean;
  isRelocatedStaticInfo?: boolean;
  statsContent?: React.ReactNode;
}) => {
  const { venueMapInfo, isMapLoading, isGeneralAdmissionOnly } =
    useEventMapContext();

  const hasSVGVenueMapV3Feature = useUserHasFeature(Feature.SVGVenueMapV3);

  useCtrlPlusM(markedSectionIds ?? selectedSectionIds ?? [], onToggleMirrors);

  const selectedSectionData = useMemo(() => {
    if (!venueMapInfo || !selectedSectionIds?.length) {
      return undefined;
    }

    return venueMapInfo.sections?.filter((s: SectionInfo) =>
      selectedSectionIds.includes(s.id)
    );
  }, [selectedSectionIds, venueMapInfo]);

  const shouldUseV3 = useMemo(() => {
    return hasSVGVenueMapV3Feature && venueMapInfo?.hasRow;
  }, [hasSVGVenueMapV3Feature, venueMapInfo?.hasRow]);

  if (isMapLoading) {
    return (
      <div className={styles.venueMapUnavailableContainer}>
        <PosSpinner />
      </div>
    );
  }
  if (!venueMapInfo) {
    return (
      <div className={styles.venueMapUnavailableContainer}>
        <Content id={ContentId.MapUnavailable} />
      </div>
    );
  }

  return shouldUseV3 ? (
    <VenueMapV3
      svgMapUrl={VenueMapV3Url.replace(
        VenueMapConfigPlaceholder,
        venueMapInfo.venueCfgId.toString()
      ).replace(
        VenueMapVersionPlaceholder,
        (venueMapInfo.svgMapVer ?? 1).toString()
      )}
      useRowMap={useRowMap}
      venueConfigId={venueMapInfo.venueCfgId}
      focalPoint={venueMapInfo.focalPt}
      availableSections={venueMapInfo.sections ?? []}
      selectedSections={selectedSectionData}
      markedSectionIds={markedSectionIds}
      onSectionClicked={onSectionClicked}
      onSectionHovered={onSectionHovered}
      setSelectedSections={setSelectedSections}
      staticInfoContent={staticInfoContent}
      infoIconContent={infoIconContent}
      isGeneralAdmissionOnly={isGeneralAdmissionOnly}
      showDefaultMapColors={showDefaultMapColors}
      colorBandProps={colorBandProps}
      isZoomEnabled={isZoomEnabled}
      isPanEnabled={isPanEnabled}
      isWhiteBackground={isWhiteBackground}
      isRelocatedStaticInfo={isRelocatedStaticInfo}
      getColor={getColor}
      isHeatMap={isHeatMap}
      statsContent={statsContent}
    />
  ) : (
    <VenueMap
      svgMapUrl={VenueMapUrl.replace(
        VenueMapConfigPlaceholder,
        venueMapInfo.venueCfgId.toString()
      ).replace(
        VenueMapVersionPlaceholder,
        (venueMapInfo.svgMapVer ?? 1).toString()
      )}
      venueConfigId={venueMapInfo.venueCfgId}
      focalPoint={venueMapInfo.focalPt}
      availableSections={venueMapInfo.sections ?? []}
      selectedSections={selectedSectionData}
      markedSectionIds={markedSectionIds}
      onSectionClicked={onSectionClicked}
      onSectionHovered={onSectionHovered}
      setSelectedSections={setSelectedSections}
      staticInfoContent={staticInfoContent}
      infoIconContent={infoIconContent}
      isGeneralAdmissionOnly={isGeneralAdmissionOnly}
      showDefaultMapColors={showDefaultMapColors}
      colorBandProps={colorBandProps}
      isZoomEnabled={isZoomEnabled}
      isPanEnabled={isPanEnabled}
      isWhiteBackground={isWhiteBackground}
      isRelocatedStaticInfo={isRelocatedStaticInfo}
      getColor={getColor}
      isHeatMap={isHeatMap}
      statsContent={statsContent}
    />
  );
};
