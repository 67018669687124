import { Content, useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { DividerLine, Stack, Tooltip } from 'src/core/ui';
import { InfoOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Event, Listing } from 'src/WebApiController';

import { GroupBroadcastSettings } from './components/Broadcast';
import { GroupingMethods } from './components/GroupingMethods';
import { GroupListingsSetting } from './components/GroupListingsSetting';
import { GroupsPreview } from './components/GroupsPreview';
import { OrderingMethods } from './components/OrderingMethods';
import { RankPremium } from './components/RankPremium';
import { GroupListingsState } from './GroupListingsV2.types';

type GroupListingsBodyProps = {
  event: Event;
  state: GroupListingsState;
  eventListings: Listing[];
};

export const GroupListingsBody: React.FC<GroupListingsBodyProps> = ({
  event,
  state,
  eventListings,
}) => {
  const rankPremiumTips = useContent(ContentId.RankPremiumTips);
  if (state === GroupListingsState.Review) {
    return <GroupsPreview eventListings={eventListings} />;
  }
  return (
    <Stack direction="column" gap="m" width="full">
      <GroupListingsSetting
        title={<Content id={ContentId.GroupInventory} />}
        content={<GroupingMethods />}
        defaultToOpen
      />
      <DividerLine />
      <GroupListingsSetting
        title={
          <Stack alignItems="center" gap="s">
            <Content id={ContentId.GroupListingsSettingRankPremium} />
            <Tooltip
              delayDuration={100}
              contentVariant="dark"
              triggerContent={
                <div style={{ display: 'flex' }}>
                  <InfoOutlineIcon
                    size={vars.iconSize.m}
                    title={rankPremiumTips}
                  />
                </div>
              }
            >
              <Content id={ContentId.RankPremiumTips} />
            </Tooltip>
          </Stack>
        }
        content={<RankPremium />}
      />
      <DividerLine />
      <GroupListingsSetting
        title={<Content id={ContentId.GroupListingsSettingQueuingLogic} />}
        content={<OrderingMethods />}
      />
      <DividerLine />
      <GroupListingsSetting
        title={<Content id={ContentId.GroupListingsSettingBroadcasting} />}
        content={<GroupBroadcastSettings event={event} />}
      />
    </Stack>
  );
};
