import { formatInTimeZone } from 'date-fns-tz';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { PosPill } from 'src/core/POS/PosPill';
import { PosPillVariant } from 'src/core/POS/PosPill/PosPill';
import { Stack } from 'src/core/ui';
import { CopyToClipboard } from 'src/core/ui/CopyToClipboard';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { getUserDisplayName, useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Detail, DetailGroup, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import {
  Feature,
  Marketplace,
  PointOfSaleSaleStatus,
  Sale,
} from 'src/WebApiController';

export type YourSaleSectionProps = {
  marketplace?: Marketplace | null;
  saleId: number;
  marketplaceSaleId: string | null;
  saleDate: Sale['saleDate'];
  insertBy: Sale['insertBy'];
  status: PointOfSaleSaleStatus;
};

export const YourSaleSection = ({
  marketplaceSaleId,
  saleId,
  marketplace,
  saleDate,
  insertBy,
  status,
}: YourSaleSectionProps) => {
  const hasWasteSaleFeature = useUserHasFeature(Feature.WasteSale);
  const { timeZone } = useSiteTimezoneContext();
  const displayDate = formatInTimeZone(
    stringToUtcDate(saleDate),
    timeZone,
    'MMM d, yyyy h:mm aa',
    {
      locale: getLocaleFromLanguageOrCurrent(),
    }
  );

  const isMobile = useMatchMedia('mobile');

  const useGetUserInfosQuery = useGetUserInfos(
    insertBy ? [insertBy] : undefined
  );

  const deactivatedText = useContent(ContentId.Deactivated);
  const insertByUser = insertBy
    ? useGetUserInfosQuery.data?.[insertBy]
    : undefined;

  const isWastedSale = status === PointOfSaleSaleStatus.Wasted;

  return (
    <SectionContent numOfColumns={isMobile ? 2 : 4}>
      <DetailGroup>
        <Detail
          label={<Content id={ContentId.SaleId} />}
          detail={
            <Stack alignItems="center" gap="s">
              <strong>#{marketplaceSaleId || saleId}</strong>
              <CopyToClipboard textToCopy={marketplaceSaleId || saleId} />
            </Stack>
          }
        />
      </DetailGroup>
      <DetailGroup>
        <Detail label={<Content id={ContentId.UniqueId} />} detail={saleId} />
      </DetailGroup>
      <DetailGroup>
        <Detail
          label={<Content id={ContentId.Merchant} />}
          detail={
            hasWasteSaleFeature && isWastedSale ? (
              <PosPill variant={PosPillVariant.Error}>
                <Content id={ContentId.Wasted} />
              </PosPill>
            ) : (
              <MarketplaceLogo marketplace={marketplace} />
            )
          }
        />
      </DetailGroup>
      <DetailGroup>
        <Detail
          label={<Content id={ContentId.SaleDate} />}
          detail={displayDate}
        />
      </DetailGroup>
      {insertByUser && (
        <DetailGroup>
          <Detail
            label={<Content id={ContentId.CreatedBy} />}
            detail={
              insertByUser && (
                <strong>
                  {getUserDisplayName(insertByUser, deactivatedText)}
                </strong>
              )
            }
          />
        </DetailGroup>
      )}
    </SectionContent>
  );
};
