import { isEmpty } from 'lodash-es';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { EventAccordionItemProps } from 'src/components/Accordions';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { InputPriceFocusProvider } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import * as Tabs from 'src/core/ui/Tabs';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';
import {
  EventWithData,
  Feature,
  ListingDetailDataField,
  ListingDetails,
  Permission,
  UserSetting,
} from 'src/WebApiController';

import { InventoryEventTabs } from './InventoryEventPage';
import * as styles from './InventoryEventPage.css';
import { InventoryEventDashboardSection } from './Sections/InventoryEventDashboardSection';
import { InventoryEventEditMapSection } from './Sections/InventoryEventEditMapSection';
import { InventoryEventFulfillmentSection } from './Sections/InventoryEventFulfillmentSection';
import { InventoryEventInfoSection } from './Sections/InventoryEventInfoSection';
import { InventoryEventTransactionsSection } from './Sections/InventoryEventTransactionsSection';
import { InventoryPricingABTestSection } from './Sections/InventoryPricingABTestSection';
import { ManualPricingSection } from './Sections/ManualPricingSection';
import { TagsSection } from './Sections/TagsSection';
import { InventoryEventVenueZoneMapSection } from './Sections/VenueZoneMap/InventoryEventVenueZoneMapSection';
import { SellThroughRateSection } from './SellThroughRateSection';
import { TabWithStarIcon } from './TabWithStarIcon';

type InventoryEventPageContentProps = {
  accordionItemProps?: EventWithData;
  inventoryAccordionItem?: EventAccordionItemProps;
  selectedListingIds: number[];
  setSelectedListingIds: Dispatch<SetStateAction<number[]>>;
  activeTab: InventoryEventTabs;
  setActiveTab: (tab: InventoryEventTabs) => void;
};

export const InventoryEventPageContent: React.FC<
  InventoryEventPageContentProps
> = ({
  accordionItemProps,
  inventoryAccordionItem,
  selectedListingIds,
  setSelectedListingIds,
  activeTab,
  setActiveTab,
}) => {
  const hasPricingV2DemandDashboard = useUserHasFeature(
    Feature.PricingV2DemandDashboard
  );

  const { setModal } = useContext(ModalContext);
  const { posEntity: listing, setActivePosEntity } =
    useActivePosEntityContext<ListingDetails>();

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const hasRecentTransactionsAccess = useUserHasFeature(
    Feature.InventoryEventRecentTransactions
  );
  const hasEventDeliveryStrategyFeature = useUserHasFeature(
    Feature.EventDeliveryStrategy
  );
  const hasEditPricingPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_SetPrice,
    Permission.Inventory_SetPriceOnIsPricerOf,
    Permission.Inventory_SetPriceOnPurchasedBy
  );
  const hasPricingABTestFeature = useUserHasFeature(Feature.PricingABTest);
  const hasEditMapInListingTab = useUserHasFeature(Feature.EditMapInListingTab);
  const hasZoneMapEditFeature = useUserHasFeature(Feature.ZoneMapEdit);

  const {
    value: defaultInventoryTabUserSetting,
    setUserSetting: setDefaultInventoryTabUserSetting,
    isLoading: defaultInventoryTabLoading,
  } = useServerUserSetting<InventoryEventTabs>({
    id: UserSetting.InventoryDefaultTab,
  });

  const activeId = getDeepLinkIdFromUrl(
    InventoryDeeplinkQueryParam,
    window.location.href
  );

  useEffect(() => {
    if (activeId?.length && !listing) {
      setActivePosEntity(parseInt(activeId), undefined, true, [
        ListingDetailDataField.Basic,
      ]);
      setModal(getListingDetailsModalConfigWithDeepLink(activeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  const [defaultTab, setDefaultTab] = useState<InventoryEventTabs | undefined>(
    defaultInventoryTabUserSetting
  );

  useEffect(() => {
    if (
      defaultInventoryTabUserSetting != null &&
      defaultInventoryTabUserSetting !== activeTab &&
      defaultInventoryTabUserSetting !== InventoryEventTabs.None &&
      defaultInventoryTabLoading === false
    ) {
      setActiveTab(defaultInventoryTabUserSetting);
    }
    if (
      defaultInventoryTabUserSetting != null &&
      defaultInventoryTabUserSetting !== defaultTab &&
      defaultInventoryTabUserSetting !== InventoryEventTabs.None &&
      defaultInventoryTabLoading === false
    ) {
      setDefaultTab(defaultInventoryTabUserSetting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultInventoryTabLoading, defaultInventoryTabUserSetting]);

  const onSetDefaultTabClick = useCallback(
    async (tab: InventoryEventTabs) => {
      if (tab !== activeTab && tab !== InventoryEventTabs.None) {
        setActiveTab(tab);
      }
      if (tab !== defaultTab) {
        setDefaultTab(tab);
      }
      setDefaultInventoryTabUserSetting(tab);
    },
    [activeTab, defaultTab, setActiveTab, setDefaultInventoryTabUserSetting]
  );

  const event = accordionItemProps?.event!;

  return (
    <Tabs.Root
      value={activeTab}
      onValueChange={(value) => {
        setActiveTab(value as InventoryEventTabs);
      }}
      className={styles.tabsSection}
    >
      <Tabs.List
        style={{
          padding: `${vars.spacing['lg']} ${vars.spacing['xl']} 0 ${vars.spacing['xl']}`,
        }}
      >
        {hasPricingV2DemandDashboard && (
          <Tabs.Trigger value={InventoryEventTabs.Dashboard}>
            <TabWithStarIcon
              onIconClick={onSetDefaultTabClick}
              defaultTab={defaultTab}
              tabType={InventoryEventTabs.Dashboard}
            >
              <Content id={ContentId.Dashboard} />
            </TabWithStarIcon>
          </Tabs.Trigger>
        )}
        <Tabs.Trigger value={InventoryEventTabs.Info}>
          <TabWithStarIcon
            onIconClick={onSetDefaultTabClick}
            defaultTab={defaultTab}
            tabType={InventoryEventTabs.Info}
          >
            <Content id={ContentId.Inventory} />
          </TabWithStarIcon>
        </Tabs.Trigger>
        <Tabs.Trigger value={InventoryEventTabs.Tags}>
          <TabWithStarIcon
            onIconClick={onSetDefaultTabClick}
            defaultTab={defaultTab}
            tabType={InventoryEventTabs.Tags}
          >
            <Content id={ContentId.Tags} />
          </TabWithStarIcon>
        </Tabs.Trigger>
        {hasAutoPricingFeature && (
          <Tabs.Trigger value={InventoryEventTabs.SellThroughRate}>
            <TabWithStarIcon
              onIconClick={onSetDefaultTabClick}
              defaultTab={defaultTab}
              tabType={InventoryEventTabs.SellThroughRate}
            >
              <Content id={ContentId.SellThroughRate} />
            </TabWithStarIcon>
          </Tabs.Trigger>
        )}
        {hasEditPricingPermission && (
          <Tabs.Trigger value={InventoryEventTabs.ManualPricing}>
            <TabWithStarIcon
              onIconClick={onSetDefaultTabClick}
              defaultTab={defaultTab}
              tabType={InventoryEventTabs.ManualPricing}
            >
              <Content id={ContentId.StubHubListings} />
            </TabWithStarIcon>
          </Tabs.Trigger>
        )}

        {hasRecentTransactionsAccess && (
          <Tabs.Trigger value={InventoryEventTabs.Transactions}>
            <TabWithStarIcon
              onIconClick={onSetDefaultTabClick}
              defaultTab={defaultTab}
              tabType={InventoryEventTabs.Transactions}
            >
              <Content id={ContentId.Transactions} />
            </TabWithStarIcon>
          </Tabs.Trigger>
        )}
        {hasEventDeliveryStrategyFeature &&
          accordionItemProps?.event?.viagId != null && (
            <Tabs.Trigger value={InventoryEventTabs.Fulfillment}>
              <TabWithStarIcon
                // Only show if there's feature flag and this event has eventId
                onIconClick={onSetDefaultTabClick}
                defaultTab={defaultTab}
                tabType={InventoryEventTabs.Fulfillment}
              >
                <Content id={ContentId.Delivery} />
              </TabWithStarIcon>
            </Tabs.Trigger>
          )}
        {hasEditMapInListingTab && !hasZoneMapEditFeature && (
          <Tabs.Trigger value={InventoryEventTabs.EditMap}>
            <TabWithStarIcon
              onIconClick={onSetDefaultTabClick}
              defaultTab={defaultTab}
              tabType={InventoryEventTabs.EditMap}
            >
              <Content id={ContentId.EditMap} />
            </TabWithStarIcon>
          </Tabs.Trigger>
        )}
        {hasZoneMapEditFeature && (
          <Tabs.Trigger value={InventoryEventTabs.ZoneMap}>
            <TabWithStarIcon
              onIconClick={onSetDefaultTabClick}
              defaultTab={defaultTab}
              tabType={InventoryEventTabs.ZoneMap}
            >
              <Content id={ContentId.EventMap} />
            </TabWithStarIcon>
          </Tabs.Trigger>
        )}
        {hasPricingABTestFeature && (
          <Tabs.Trigger value={InventoryEventTabs.PricingABTest}>
            <TabWithStarIcon
              onIconClick={onSetDefaultTabClick}
              defaultTab={defaultTab}
              tabType={InventoryEventTabs.PricingABTest}
            >
              <Content id={ContentId.ABTest} />
            </TabWithStarIcon>
          </Tabs.Trigger>
        )}
      </Tabs.List>
      {hasPricingV2DemandDashboard && (
        <Tabs.Content value={InventoryEventTabs.Dashboard}>
          <InventoryEventDashboardSection
            accordionItemProps={accordionItemProps}
          />
        </Tabs.Content>
      )}
      <Tabs.Content
        value={InventoryEventTabs.Info}
        className={styles.tabContent}
      >
        <InventoryEventInfoSection
          accordionItemProps={accordionItemProps}
          inventoryAccordionItem={inventoryAccordionItem}
          selectedListingIds={selectedListingIds}
          setSelectedListingIds={setSelectedListingIds}
        />
      </Tabs.Content>
      <Tabs.Content value={InventoryEventTabs.Tags}>
        <TagsSection eventId={event.viagVirtualId} />
      </Tabs.Content>
      {hasAutoPricingFeature && (
        <Tabs.Content value={InventoryEventTabs.SellThroughRate}>
          <SellThroughRateSection event={event} />
        </Tabs.Content>
      )}
      {hasEditPricingPermission && (
        <Tabs.Content
          value={InventoryEventTabs.ManualPricing}
          className={styles.tabContent}
        >
          <InputPriceFocusProvider
            disablePagination
            pageSize={accordionItemProps?.listCnt ?? 0}
          >
            <ManualPricingSection accordionItemProps={accordionItemProps} />
          </InputPriceFocusProvider>
        </Tabs.Content>
      )}
      {hasRecentTransactionsAccess && (
        <Tabs.Content value={InventoryEventTabs.Transactions}>
          <InventoryEventTransactionsSection
            accordionItemProps={accordionItemProps}
            inventoryAccordionItem={inventoryAccordionItem}
          />
        </Tabs.Content>
      )}

      {hasEventDeliveryStrategyFeature &&
        accordionItemProps?.event?.viagId != null && (
          <Tabs.Content value={InventoryEventTabs.Fulfillment}>
            <InventoryEventFulfillmentSection
              accordionItemProps={accordionItemProps}
              inventoryAccordionItem={inventoryAccordionItem}
            />
          </Tabs.Content>
        )}

      {hasEditMapInListingTab && accordionItemProps?.event?.viagId != null && (
        <Tabs.Content value={InventoryEventTabs.EditMap}>
          <InventoryEventEditMapSection event={event} />
        </Tabs.Content>
      )}
      {hasZoneMapEditFeature && accordionItemProps?.event?.viagId != null && (
        <Tabs.Content value={InventoryEventTabs.ZoneMap}>
          <InventoryEventVenueZoneMapSection event={event} />
        </Tabs.Content>
      )}

      {hasPricingABTestFeature &&
        accordionItemProps?.event?.viagId != null &&
        !isEmpty(accordionItemProps?.listings) && (
          <Tabs.Content value={InventoryEventTabs.PricingABTest}>
            <InventoryPricingABTestSection
              event={event}
              listings={accordionItemProps?.listings ?? []}
            />
          </Tabs.Content>
        )}
    </Tabs.Root>
  );
};
