import { isEmpty } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
  DEFAULT_COMP_LISTING_FLOOR,
} from 'src/utils/autoPricingUtils';
import { coalesceUndefinedOnly } from 'src/utils/miscUtils';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import {
  AutoPricingCompListingMode,
  AutoPricingOutlierMode,
  AutoPricingUndercutMode,
  Feature,
  Listing,
  ListingDetailsAutoPricingSectionUpdatesV2,
  PricingFloorCeilingAdjustmentType,
  SectionInfo,
  SelectedSectionSettings,
} from 'src/WebApiController';

export const useAutoPricingSettings = (listing?: Listing | null) => {
  const { setValue, watch } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();

  const hasDefaultFilterRowsFeature = useUserHasFeature(
    Feature.DefaultFilterRows
  );

  const updateInput = watch();
  const {
    netProceedsFloor,
    netProceedsCeiling,
    netProceedsFloorAdjustmentAmount,
    netProceedsFloorAdjustmentType,
    netProceedsFloorAdjustmentRelative,

    autoPricingEnabled,
    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,

    compListingMode,
    compListingCeiling,
    compListingFloor,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingQuantityScoreAdjustmentEnabled,
    compListingExcludeFanInventory,
    compListingExcludeDefects,
    compListingQuantityScoreAdjustmentOverrideJson,
    compListingSelectedSectionSettings,
    includeExistingSelectedSections,

    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,

    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
  } = updateInput;

  const { loginContext } = useAppContext();

  const { getUiCurrency } = useLocalizationContext();

  const uiCurrency = useMemo(
    () =>
      getUiCurrency(
        listing?.currency ?? loginContext?.user?.activeAccount?.currencyCode
      ),
    [
      getUiCurrency,
      listing?.currency,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );

  const { event } = useEventMapContext();

  const circuitBreakerSettings = useMemo(() => {
    return {
      circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerMinCompListingCount,
      circuitBreakerRelativeCeiling,
      circuitBreakerRelativeFloor,
    };
  }, [
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
  ]);

  const undercutSettings = useMemo(() => {
    return {
      undercutMode,
      undercutAbsoluteAmount,
      undercutRelativeAmount,
    };
  }, [undercutAbsoluteAmount, undercutMode, undercutRelativeAmount]);

  const compListingSettings = useMemo(() => {
    return {
      compListingMode,
      compListingFloor:
        compListingFloor ?? posField(DEFAULT_COMP_LISTING_FLOOR),
      compListingCeiling:
        compListingCeiling ??
        posField(
          (compListingFloor?.value ?? DEFAULT_COMP_LISTING_FLOOR) +
            DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR
        ),
      compListingOnlyForSameZoneEnabled,
      compListingOnlyForSelectedSectionsEnabled,
      compListingExcludeFanInventory,
      compListingExcludeDefects,
      compListingSelectedSectionSettings,
      compListingQuantityScoreAdjustmentEnabled,
      compListingQuantityScoreAdjustmentOverrideJson,
    };
  }, [
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory,
    compListingExcludeDefects,
    compListingSelectedSectionSettings,
    compListingQuantityScoreAdjustmentEnabled,
    compListingQuantityScoreAdjustmentOverrideJson,
  ]);

  const outlierSettings = useMemo(() => {
    return {
      outlierMode,
      outlierStandardDeviations,
      outlierKthLowestLimit,
      outlierKthLowestLimitRelativeSpacing,
      outlierKthLowestLimitAbsoluteSpacing,
    };
  }, [
    outlierKthLowestLimit,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,
    outlierMode,
    outlierStandardDeviations,
  ]);

  const onUndercutChange = useCallback(
    (
      undercutModeNew?: AutoPricingUndercutMode | null,
      undercutAbsoluteAmountNew?: number | null,
      undercutRelativeAmountNew?: number | null
    ) => {
      setValue(
        'undercutMode',
        posField(
          coalesceUndefinedOnly(
            undercutModeNew,
            undercutSettings.undercutMode?.value
          )!,
          undercutModeNew !== undefined ||
            undercutSettings.undercutMode?.hasChanged
        )
      );
      setValue(
        'undercutAbsoluteAmount',
        posField(
          coalesceUndefinedOnly(
            undercutAbsoluteAmountNew,
            undercutSettings.undercutAbsoluteAmount?.value
          )!,
          undercutAbsoluteAmountNew !== undefined ||
            undercutSettings.undercutAbsoluteAmount?.hasChanged
        )
      );
      setValue(
        'undercutRelativeAmount',
        posField(
          coalesceUndefinedOnly(
            undercutRelativeAmountNew,
            undercutSettings.undercutRelativeAmount?.value
          )!,
          undercutRelativeAmountNew !== undefined ||
            undercutSettings.undercutRelativeAmount?.hasChanged
        )
      );
    },
    [
      setValue,
      undercutSettings.undercutAbsoluteAmount,
      undercutSettings.undercutMode,
      undercutSettings.undercutRelativeAmount,
    ]
  );

  const onCompListingChange = useCallback(
    (input: {
      compListingModeNew?: AutoPricingCompListingMode | null;
      compListingFloorNew?: number | null;
      compListingCeilingNew?: number | null;
      compListingOnlyForSameZoneEnabledNew?: boolean | null;
      compListingOnlyForSelectedSectionsEnabledNew?: boolean | null;
      compListingQuantityScoreAdjustmentEnabledNew?: boolean | null;
      compListingExcludeFanInventoryNew?: boolean | null;
      compListingExcludeDefectsNew?: boolean | null;
      compListingQuantityScoreAdjustmentOverrideJsonNew?: string | null;
      compListingSelectedSectionSettingsNew?: SelectedSectionSettings | null;
    }) => {
      const {
        compListingModeNew,
        compListingFloorNew,
        compListingCeilingNew,
        compListingOnlyForSameZoneEnabledNew,
        compListingOnlyForSelectedSectionsEnabledNew,
        compListingQuantityScoreAdjustmentEnabledNew,
        compListingExcludeFanInventoryNew,
        compListingExcludeDefectsNew,
        compListingQuantityScoreAdjustmentOverrideJsonNew,
        compListingSelectedSectionSettingsNew,
      } = input;

      const sanitizedCompListingOnlyForSameZoneEnabledNew =
        compListingOnlyForSelectedSectionsEnabledNew
          ? false
          : compListingOnlyForSameZoneEnabledNew;
      const sanitizedCompListingOnlyForSelectedSectionsEnabledNew =
        compListingOnlyForSameZoneEnabledNew
          ? false
          : compListingOnlyForSelectedSectionsEnabledNew;

      setValue(
        'compListingMode',
        posField(
          coalesceUndefinedOnly(
            compListingModeNew,
            compListingSettings.compListingMode?.value
          )!,
          compListingModeNew !== undefined ||
            compListingSettings.compListingMode?.hasChanged
        )
      );
      setValue(
        'compListingFloor',
        posField(
          coalesceUndefinedOnly(
            compListingFloorNew,
            compListingSettings.compListingFloor?.value
          )!,
          compListingFloorNew !== undefined ||
            compListingSettings.compListingFloor?.hasChanged
        )
      );
      setValue(
        'compListingCeiling',
        posField(
          coalesceUndefinedOnly(
            compListingCeilingNew,
            compListingSettings.compListingCeiling?.value
          )!,
          compListingCeilingNew !== undefined ||
            compListingSettings.compListingCeiling?.hasChanged
        )
      );
      setValue(
        'compListingOnlyForSameZoneEnabled',
        posField(
          coalesceUndefinedOnly(
            sanitizedCompListingOnlyForSameZoneEnabledNew,
            compListingSettings.compListingOnlyForSameZoneEnabled?.value
          )!,
          sanitizedCompListingOnlyForSameZoneEnabledNew !== undefined ||
            compListingSettings.compListingOnlyForSameZoneEnabled?.hasChanged
        )
      );
      setValue(
        'compListingOnlyForSelectedSectionsEnabled',
        posField(
          coalesceUndefinedOnly(
            sanitizedCompListingOnlyForSelectedSectionsEnabledNew,
            compListingSettings.compListingOnlyForSelectedSectionsEnabled?.value
          )!,
          sanitizedCompListingOnlyForSelectedSectionsEnabledNew !== undefined ||
            compListingSettings.compListingOnlyForSelectedSectionsEnabled
              ?.hasChanged
        )
      );
      setValue(
        'compListingQuantityScoreAdjustmentEnabled',
        posField(
          coalesceUndefinedOnly(
            compListingQuantityScoreAdjustmentEnabledNew,
            compListingSettings.compListingQuantityScoreAdjustmentEnabled?.value
          )!,
          compListingQuantityScoreAdjustmentEnabledNew !== undefined ||
            compListingSettings.compListingQuantityScoreAdjustmentEnabled
              ?.hasChanged
        )
      );
      setValue(
        'compListingExcludeFanInventory',
        posField(
          coalesceUndefinedOnly(
            compListingExcludeFanInventoryNew,
            compListingSettings.compListingExcludeFanInventory?.value
          )!,
          compListingExcludeFanInventoryNew !== undefined ||
            compListingSettings.compListingExcludeFanInventory?.hasChanged
        )
      );
      setValue(
        'compListingExcludeDefects',
        posField(
          coalesceUndefinedOnly(
            compListingExcludeDefectsNew,
            compListingSettings.compListingExcludeDefects?.value
          )!,
          compListingExcludeDefectsNew !== undefined ||
            compListingSettings.compListingExcludeDefects?.hasChanged
        )
      );
      setValue(
        'compListingQuantityScoreAdjustmentOverrideJson',
        posField(
          coalesceUndefinedOnly(
            compListingQuantityScoreAdjustmentOverrideJsonNew,
            compListingSettings.compListingQuantityScoreAdjustmentOverrideJson
              ?.value
          )!,
          compListingQuantityScoreAdjustmentOverrideJsonNew !== undefined ||
            compListingSettings.compListingQuantityScoreAdjustmentOverrideJson
              ?.hasChanged
        )
      );
      setValue(
        'compListingSelectedSectionSettings',
        posField(
          coalesceUndefinedOnly(
            compListingSelectedSectionSettingsNew,
            compListingSettings.compListingSelectedSectionSettings?.value
          )!,
          compListingSelectedSectionSettingsNew !== undefined ||
            compListingSettings.compListingSelectedSectionSettings?.hasChanged
        )
      );
    },
    [
      compListingSettings.compListingCeiling,
      compListingSettings.compListingFloor,
      compListingSettings.compListingMode,
      compListingSettings.compListingOnlyForSameZoneEnabled,
      compListingSettings.compListingOnlyForSelectedSectionsEnabled,
      compListingSettings.compListingQuantityScoreAdjustmentEnabled,
      compListingSettings.compListingExcludeFanInventory,
      compListingSettings.compListingExcludeDefects,
      compListingSettings.compListingQuantityScoreAdjustmentOverrideJson,
      compListingSettings.compListingSelectedSectionSettings,
      setValue,
    ]
  );

  const onCircuitBreakerChange = useCallback(
    (
      circuitBreakerRelativeCeilingNew?: number | null,
      circuitBreakerRelativeFloorNew?: number | null,
      circuitBreakerMaxDiscountVelocityPercentNew?: number | null,
      circuitBreakerMaxDiscountVelocityTicksInHoursNew?: number | null,
      circuitBreakerMinCompListingCountNew?: number | null
    ) => {
      setValue(
        'circuitBreakerRelativeCeiling',
        posField(
          coalesceUndefinedOnly(
            circuitBreakerRelativeCeilingNew,
            circuitBreakerSettings.circuitBreakerRelativeCeiling?.value
          ) ?? null,
          circuitBreakerRelativeCeilingNew !== undefined ||
            circuitBreakerSettings.circuitBreakerRelativeCeiling?.hasChanged
        )
      );
      setValue(
        'circuitBreakerRelativeFloor',
        posField(
          coalesceUndefinedOnly(
            circuitBreakerRelativeFloorNew,
            circuitBreakerSettings.circuitBreakerRelativeFloor?.value
          ) ?? null,
          circuitBreakerRelativeFloorNew !== undefined ||
            circuitBreakerSettings.circuitBreakerRelativeFloor?.hasChanged
        )
      );
      setValue(
        'circuitBreakerMaxDiscountVelocityPercent',
        posField(
          coalesceUndefinedOnly(
            circuitBreakerMaxDiscountVelocityPercentNew,
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityPercent
              ?.value
          ) ?? null,
          circuitBreakerMaxDiscountVelocityPercentNew !== undefined ||
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityPercent
              ?.hasChanged
        )
      );
      setValue(
        'circuitBreakerMaxDiscountVelocityTicksInHours',
        posField(
          coalesceUndefinedOnly(
            circuitBreakerMaxDiscountVelocityTicksInHoursNew,
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityTicksInHours
              ?.value
          ) ?? null,
          circuitBreakerMaxDiscountVelocityTicksInHoursNew !== undefined ||
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityTicksInHours
              ?.hasChanged
        )
      );
      setValue(
        'circuitBreakerMinCompListingCount',
        posField(
          coalesceUndefinedOnly(
            circuitBreakerMinCompListingCountNew,
            circuitBreakerSettings.circuitBreakerMinCompListingCount?.value
          ) ?? null,
          circuitBreakerMinCompListingCountNew !== undefined ||
            circuitBreakerSettings.circuitBreakerMinCompListingCount?.hasChanged
        )
      );
    },
    [
      circuitBreakerSettings.circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerSettings.circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerSettings.circuitBreakerMinCompListingCount,
      circuitBreakerSettings.circuitBreakerRelativeCeiling,
      circuitBreakerSettings.circuitBreakerRelativeFloor,
      setValue,
    ]
  );

  const onOutlierChange = useCallback(
    (input: {
      outlierModeNew?: AutoPricingOutlierMode | null;
      outlierStandardDeviationsNew?: number | null;
      outlierKthLowestLimitNew?: number | null;
      outlierKthLowestLimitRelativeSpacingNew?: number | null;
      outlierKthLowestLimitAbsoluteSpacingNew?: number | null;
    }) => {
      const {
        outlierModeNew,
        outlierStandardDeviationsNew,
        outlierKthLowestLimitNew,
        outlierKthLowestLimitRelativeSpacingNew,
        outlierKthLowestLimitAbsoluteSpacingNew,
      } = input;

      // Covers both null and undefined cases
      if (outlierModeNew !== undefined) {
        setValue('outlierMode', posChangedField(outlierModeNew));

        switch (outlierModeNew) {
          case AutoPricingOutlierMode.StandardDeviations:
            setValue(
              'outlierStandardDeviations',
              posField(
                coalesceUndefinedOnly(
                  outlierStandardDeviationsNew,
                  outlierSettings.outlierStandardDeviations?.value
                ) ?? null,
                outlierStandardDeviationsNew !== undefined ||
                  outlierSettings.outlierStandardDeviations?.hasChanged
              )
            );
            setValue('outlierKthLowestLimit', posChangedField(null));
            setValue(
              'outlierKthLowestLimitRelativeSpacing',
              posChangedField(null)
            );
            setValue(
              'outlierKthLowestLimitAbsoluteSpacing',
              posChangedField(null)
            );
            break;
          case AutoPricingOutlierMode.KthLowest:
            setValue('outlierStandardDeviations', posChangedField(null));
            setValue(
              'outlierKthLowestLimit',
              posField(
                coalesceUndefinedOnly(
                  outlierKthLowestLimitNew,
                  outlierSettings.outlierKthLowestLimit?.value
                ) ?? null,
                outlierKthLowestLimitNew !== undefined ||
                  outlierSettings.outlierKthLowestLimit?.hasChanged
              )
            );

            setValue(
              'outlierKthLowestLimitRelativeSpacing',
              posField(
                coalesceUndefinedOnly(
                  outlierKthLowestLimitRelativeSpacingNew,
                  outlierSettings.outlierKthLowestLimitRelativeSpacing?.value
                ) ?? null,
                outlierKthLowestLimitRelativeSpacingNew !== undefined ||
                  outlierSettings.outlierKthLowestLimitRelativeSpacing
                    ?.hasChanged
              )
            );

            setValue(
              'outlierKthLowestLimitAbsoluteSpacing',
              posField(
                coalesceUndefinedOnly(
                  outlierKthLowestLimitAbsoluteSpacingNew,
                  outlierSettings.outlierKthLowestLimitAbsoluteSpacing?.value
                ) ?? null,
                outlierKthLowestLimitAbsoluteSpacingNew !== undefined ||
                  outlierSettings.outlierKthLowestLimitAbsoluteSpacing
                    ?.hasChanged
              )
            );
            break;
          case AutoPricingOutlierMode.SkipLogic:
            setValue('outlierStandardDeviations', posChangedField(null));
            setValue('outlierKthLowestLimit', posChangedField(null));
            setValue(
              'outlierKthLowestLimitRelativeSpacing',
              posChangedField(null)
            );
            setValue(
              'outlierKthLowestLimitAbsoluteSpacing',
              posChangedField(null)
            );
        }
      } else {
        setValue('outlierMode', null);
        setValue('outlierStandardDeviations', null);
        setValue('outlierKthLowestLimit', null);
        setValue('outlierKthLowestLimitRelativeSpacing', null);
        setValue('outlierKthLowestLimitAbsoluteSpacing', null);
      }
    },
    [
      outlierSettings.outlierKthLowestLimit,
      outlierSettings.outlierKthLowestLimitAbsoluteSpacing,
      outlierSettings.outlierKthLowestLimitRelativeSpacing,
      outlierSettings.outlierStandardDeviations,
      setValue,
    ]
  );

  const applySectionRowFilterCompListingChanges = useCallback(
    (
      newSectionIdFilter: number[],
      sections: SectionInfo[],
      compListingSelectedSectionSettingsNew?:
        | SelectedSectionSettings
        | null
        | undefined
    ) => {
      if (newSectionIdFilter.length === 0 && sections.length > 0) {
        onCompListingChange({
          compListingSelectedSectionSettingsNew,
          compListingOnlyForSelectedSectionsEnabledNew: false,
        });
      }
      onCompListingChange({
        compListingSelectedSectionSettingsNew,
        compListingOnlyForSelectedSectionsEnabledNew: true,
      });
    },
    [onCompListingChange]
  );

  const onCompListingSectionIdFilterChange = useCallback(
    (
      sectionId: number,
      selectedSections: SectionInfo[],
      section?: SectionInfo,
      listing?: Listing
    ) => {
      let newSectionIdFilter =
        compListingSelectedSectionSettings?.value?.sectionIdFilter ?? [];

      let newRowIdFilter =
        compListingSelectedSectionSettings?.value?.rowIdFilter ?? [];

      let newSectionRowIdFilter =
        compListingSelectedSectionSettings?.value?.sectionRowIdFilter ?? {};

      if (newSectionIdFilter.includes(sectionId)) {
        newSectionIdFilter = newSectionIdFilter.filter(
          (id) => id !== sectionId
        );

        // Remove all the associated row ids for this section
        const section = selectedSections.find((s) => s.id === sectionId);
        if (section) {
          const rowIds = section.rows.map((r) => r.id);
          newRowIdFilter = newRowIdFilter.filter((id) => !rowIds.includes(id));
        }

        // Remove the sectionRow mapping so we don't display it in pills
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [sectionId]: omitted, ...updatedSectionRowIdFilter } =
          compListingSelectedSectionSettings?.value?.sectionRowIdFilter ?? {};

        newSectionRowIdFilter = updatedSectionRowIdFilter;
      } else {
        newSectionIdFilter.push(sectionId);

        // If a section is present, add all the rows associated with the newly selected section
        if (
          hasDefaultFilterRowsFeature &&
          listing &&
          section &&
          listing.seating?.row
        ) {
          const { seating: listingSeating } = listing;
          const listingRow = listingSeating.row!;

          const matchingRow = section.rows.find(
            ({ id, name }) =>
              id === listingSeating.rowId ||
              name?.toLowerCase() === listingRow.toLowerCase()
          );

          if (matchingRow) {
            // Remove all the row filters associated with the section id
            const sectionRowIds = section.rows.map((r) => r.id);
            newRowIdFilter = [
              ...newRowIdFilter.filter(
                (rowId) => !sectionRowIds.includes(rowId)
              ),
            ];

            // Exclude all rows from section if the matching row has no ordinal
            if (matchingRow.ordinal == null) {
              newSectionRowIdFilter[section.id] = matchingRow.id.toString();
              return;
            }

            // Push all the row ids with an ordinal less than or equal to the matching row
            section.rows.forEach((row) => {
              if (row.ordinal == null || matchingRow.ordinal == null) return;
              if (row.ordinal <= matchingRow.ordinal) {
                newRowIdFilter.push(row.id);
              }
            });

            // Only store the highest ordinal row id to display in the pill
            newSectionRowIdFilter[section.id] = matchingRow.id.toString();
          }
        } else if (section) {
          // If listing or seating info is not present, add all the section rows as a filter
          const newRowIds = section.rows.map((r) => r.id);
          newRowIds.forEach((newRowId) => {
            if (!newRowIdFilter.includes(newRowId)) {
              newRowIdFilter.push(newRowId);
            }
          });
        }
      }

      const compListingSelectedSectionSettingsNew = {
        sectionIdFilter: newSectionIdFilter,
        rowIdFilter: newRowIdFilter,
        sectionRowIdFilter: newSectionRowIdFilter,
      };

      applySectionRowFilterCompListingChanges(
        newSectionIdFilter,
        selectedSections,
        compListingSelectedSectionSettingsNew
      );
    },
    [
      applySectionRowFilterCompListingChanges,
      compListingSelectedSectionSettings,
      hasDefaultFilterRowsFeature,
    ]
  );

  const onCompListingSectionZoneChange = useCallback(
    (sections: SectionInfo[], flipOnSameSections = true) => {
      let newSectionIdFilter =
        compListingSelectedSectionSettings?.value?.sectionIdFilter ?? [];

      let newRowIdFilter =
        compListingSelectedSectionSettings?.value?.rowIdFilter ?? [];

      const newSectionRowIdFilter =
        compListingSelectedSectionSettings?.value?.sectionRowIdFilter ?? {};

      // If every section passed in already exists in prevSections then we should remove them entirely
      const completelyOverlap = sections.every((section) =>
        newSectionIdFilter.includes(section.id)
      );

      if (completelyOverlap && flipOnSameSections) {
        const sectionIdsToRemove = new Set(
          sections.map((section) => section.id)
        );

        newSectionIdFilter = newSectionIdFilter.filter(
          (id) => !sectionIdsToRemove.has(id)
        );

        // Remove all associated rows as well
        const rowIdsToRemove = sections
          .map((section) => section.rows.map((r) => r.id))
          .flat();

        const rowIdsToRemoveSet = new Set(rowIdsToRemove);

        newRowIdFilter = newRowIdFilter.filter(
          (rowId) => !rowIdsToRemoveSet.has(rowId)
        );

        // Update sectionRowIdFilters to remove the sections that have been removed
        sections.forEach((section) => {
          if (section.id in newSectionRowIdFilter) {
            delete newSectionRowIdFilter[section.id];
          }
        });
      } else {
        // Upsert of the new sections
        sections.forEach((section) => {
          if (section.id in newSectionRowIdFilter) {
            return;
          }

          newSectionIdFilter.push(section.id);
          if (hasDefaultFilterRowsFeature && listing && listing.seating?.row) {
            const { seating: listingSeating } = listing;
            const listingRow = listingSeating.row!;

            const matchingRow = section.rows.find(
              ({ id, name }) =>
                id === listingSeating.rowId ||
                name?.toLowerCase() === listingRow.toLowerCase()
            );

            if (matchingRow) {
              // Remove all the row filters associated with the section id
              const sectionRowIds = section.rows.map((r) => r.id);
              newRowIdFilter = [
                ...newRowIdFilter.filter(
                  (rowId) => !sectionRowIds.includes(rowId)
                ),
              ];

              // Exclude all rows from section if the matching row has no ordinal
              if (matchingRow.ordinal == null) {
                newSectionRowIdFilter[section.id] = matchingRow.id.toString();
                return;
              }

              // Push all the row ids with an ordinal less than or equal to the matching row
              section.rows.forEach((row) => {
                if (row.ordinal == null || matchingRow.ordinal == null) return;
                if (row.ordinal <= matchingRow.ordinal) {
                  newRowIdFilter.push(row.id);
                }
              });

              // Only store the highest ordinal row id to display in the pill
              newSectionRowIdFilter[section.id] = matchingRow.id.toString();
            }
          } else {
            // If listing or seating info is not present, add all the section rows as a filter
            const newRowIds = section.rows.map((r) => r.id);
            newRowIds.forEach((newRowId) => {
              if (!newRowIdFilter.includes(newRowId)) {
                newRowIdFilter.push(newRowId);
              }
            });
          }
        });
      }

      const compListingSelectedSectionSettingsNew = {
        sectionIdFilter: newSectionIdFilter,
        rowIdFilter: newRowIdFilter,
        sectionRowIdFilter: newSectionRowIdFilter,
      };

      applySectionRowFilterCompListingChanges(
        newSectionIdFilter,
        sections,
        compListingSelectedSectionSettingsNew
      );
    },
    [
      applySectionRowFilterCompListingChanges,
      compListingSelectedSectionSettings,
      hasDefaultFilterRowsFeature,
      listing,
    ]
  );

  const onCompListingModeChange = useCallback(
    (mode: AutoPricingCompListingMode | null) => {
      if (!mode) {
        onCompListingChange({
          compListingModeNew: null,
          compListingFloorNew: null,
          compListingCeilingNew: null,
        });
        return;
      }

      onCompListingChange({
        compListingModeNew: mode,
        // Reset flag on mode change
        compListingOnlyForSameZoneEnabledNew: false,
        compListingOnlyForSelectedSectionsEnabledNew: false,
      });

      if (mode === AutoPricingCompListingMode.ParentListing) {
        onUndercutChange(AutoPricingUndercutMode.Simple);
      }
    },
    [onCompListingChange, onUndercutChange]
  );

  const onUpdateNetProceedsFloorAdjustment = useCallback(
    (input: {
      isRelative?: boolean;
      adjustmentAmount?: number | null;
      adjustmentType?: PricingFloorCeilingAdjustmentType;
    }) => {
      if (isEmpty(input)) {
        setValue('netProceedsFloorAdjustmentAmount', null);
        setValue('netProceedsFloorAdjustmentRelative', false);
        setValue('netProceedsFloorAdjustmentType', null);
      }
      const { isRelative, adjustmentAmount, adjustmentType } = input;
      if (isRelative !== undefined) {
        setValue('netProceedsFloorAdjustmentRelative', isRelative);
      }
      if (adjustmentAmount !== undefined) {
        setValue('netProceedsFloorAdjustmentAmount', adjustmentAmount);
      }
      if (adjustmentType !== undefined) {
        setValue('netProceedsFloorAdjustmentType', adjustmentType);
      }
    },
    [setValue]
  );

  return {
    uiCurrency,
    autoPricingEnabled,
    event,

    ...circuitBreakerSettings,
    ...compListingSettings,
    ...undercutSettings,
    ...outlierSettings,

    netProceedsFloor,
    netProceedsCeiling,
    netProceedsFloorAdjustmentAmount,
    netProceedsFloorAdjustmentRelative,
    netProceedsFloorAdjustmentType,
    includeExistingSelectedSections,

    onUndercutChange,
    onCompListingChange,
    onCircuitBreakerChange,
    onOutlierChange,
    onCompListingSectionIdFilterChange,
    onCompListingSectionZoneChange,
    onCompListingModeChange,
    onUpdateNetProceedsFloorAdjustment,
  };
};
