import { isEqual } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { getQuantitiesOptions } from 'src/modals/GroupListings/components/groupingUtils';
import {
  groupPrioritySort,
  useGroupSortingCriteria,
} from 'src/modals/GroupListings/components/ListingGroupInput.hook';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Listing,
  ListingGroupItemInput,
  MergeListingGroupInput,
} from 'src/WebApiController';

import { useSetListingGroupValue } from '../../GroupListingsV2.hooks';
import { RankPremiumType } from '../../GroupListingsV2.types';
import * as styles from './GroupListingsPreview.css';
import { GroupListingPreviewTable } from './GroupListingsPreviewTable';
import { UngroupedListingGroupId } from './GroupListingsPreviewTable.constants';
import { withGroupUndercustSettings } from './GroupsPreview.utils';

export const RelativeAbsoluteGroupOptions = [
  { value: RankPremiumType.Relative, children: '%' },
  { value: RankPremiumType.Absolute, children: '$' },
];

type GroupListingsPreviewProps = {
  listingGroup: MergeListingGroupInput;
  eventListings: Listing[];
};

export const GroupListingsPreview: React.FC<GroupListingsPreviewProps> = ({
  listingGroup,
  eventListings,
}) => {
  const setListingGroupValue = useSetListingGroupValue(
    listingGroup.listingGroupId
  );

  const isUngroupedGroup = useMemo(
    () => listingGroup.listingGroupId === UngroupedListingGroupId,
    [listingGroup.listingGroupId]
  );

  const idToSortingCriteria = useGroupSortingCriteria(eventListings);

  const [rankPremium, setRankPremium] = useState<RankPremiumType>(() =>
    listingGroup.groupUndercutSetting?.undAbsAmt
      ? RankPremiumType.Absolute
      : RankPremiumType.Relative
  );

  const premiumValue = useMemo(() => {
    if (rankPremium === RankPremiumType.Absolute) {
      return listingGroup.groupUndercutSetting?.undAbsAmt ?? undefined;
    }
    if (listingGroup.groupUndercutSetting?.undRelAmt != null) {
      return listingGroup.groupUndercutSetting?.undRelAmt * 100;
    }
    return undefined;
  }, [
    listingGroup.groupUndercutSetting?.undAbsAmt,
    listingGroup.groupUndercutSetting?.undRelAmt,
    rankPremium,
  ]);

  const onRankPremiumChange = useCallback(
    (premium: string) => {
      if (premium === rankPremium) {
        return;
      }
      const { groupUndercutSetting, listingGroupItems } = listingGroup;
      const value =
        groupUndercutSetting?.undAbsAmt ??
        groupUndercutSetting?.undRelAmt ??
        null;
      let undercutSetting;
      if (premium === RankPremiumType.Absolute) {
        undercutSetting = {
          actRankUndAbsAmt: null,
          actRankUndRelAmt: null,
          undRelAmt: null,
          undAbsAmt: value ? value * 100 : null,
        };
      } else {
        undercutSetting = {
          actRankUndAbsAmt: null,
          actRankUndRelAmt: null,
          undRelAmt: value ? Math.min(value, 100) / 100 : null,
          undAbsAmt: null,
        };
      }
      setListingGroupValue({
        groupUndercutSetting: undercutSetting,
        listingGroupItems: withGroupUndercustSettings(
          undercutSetting,
          listingGroupItems
        ),
      });
      setRankPremium(premium as RankPremiumType);
    },
    [listingGroup, rankPremium, setListingGroupValue]
  );

  const onPremiumValueChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const value = parseFloat(event.target.value);
        let undercutSetting;
        if (rankPremium === RankPremiumType.Absolute) {
          undercutSetting = {
            actRankUndAbsAmt: null,
            actRankUndRelAmt: null,
            undRelAmt: null,
            undAbsAmt: isNaN(value) ? null : value,
          };
        }
        if (rankPremium === RankPremiumType.Relative) {
          undercutSetting = {
            actRankUndAbsAmt: null,
            actRankUndRelAmt: null,
            undRelAmt: isNaN(value) ? null : Math.min(value, 100) / 100,
            undAbsAmt: null,
          };
        }
        if (undercutSetting) {
          setListingGroupValue({
            groupUndercutSetting: undercutSetting,
            listingGroupItems: withGroupUndercustSettings(
              undercutSetting,
              listingGroup.listingGroupItems
            ),
          });
        }
      },
      [listingGroup.listingGroupItems, rankPremium, setListingGroupValue]
    );

  const onUpdateDeprioritizeQuantities = useCallback(
    (newValues: string[]) => {
      if (!isEqual(newValues, listingGroup.deprioritizedQuantities)) {
        setListingGroupValue({
          deprioritizedQuantities: newValues,
          listingGroupItems: listingGroup.listingGroupItems
            .sort(groupPrioritySort(idToSortingCriteria, newValues))
            .map((l, i): ListingGroupItemInput => {
              return {
                ...l,
                priority: i + 1,
              };
            }),
        });
      }
    },
    [
      idToSortingCriteria,
      listingGroup.deprioritizedQuantities,
      listingGroup.listingGroupItems,
      setListingGroupValue,
    ]
  );

  return (
    <Stack direction="column" gap="m">
      <PosTextField
        value={listingGroup.name}
        disabled={isUngroupedGroup}
        prefixDisplay={
          <div className={styles.inputPrefix}>
            <Content id={ContentId.Group} />:
          </div>
        }
        onChange={(value) =>
          setListingGroupValue({
            name: value.target.value,
          })
        }
      />
      {!isUngroupedGroup && (
        <Stack direction="row" width="full" gap="m">
          <PosTextField
            type="number"
            prefixDisplay={
              <div className={styles.inputPrefix}>
                <Content id={ContentId.Premium} />:
              </div>
            }
            value={premiumValue}
            onChange={onPremiumValueChange}
            postfixDisplay={
              <ToggleGroup
                itemStyle={{
                  fontSize: vars.typography.fontSize['xs'],
                  height: '22px',
                }}
                options={RelativeAbsoluteGroupOptions}
                value={rankPremium}
                onValueChange={onRankPremiumChange}
              />
            }
          />

          <PosTextField
            type="number"
            prefixDisplay={
              <div className={styles.inputPrefix}>
                <Content id={ContentId.Active} />:
              </div>
            }
            value={listingGroup.desiredActiveListings ?? undefined}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (isNaN(value) || value <= 0) {
                setListingGroupValue({
                  desiredActiveListings: null,
                });
                return;
              }
              setListingGroupValue({
                desiredActiveListings: value,
              });
            }}
          />

          <PosMultiSelect
            align="start"
            prefixDisplay={
              <div className={styles.inputPrefix}>
                <Content id={ContentId.Deprioritize} />:
              </div>
            }
            triggerProps={{ style: { width: '100%' } }}
            values={listingGroup.deprioritizedQuantities ?? []}
            onChange={onUpdateDeprioritizeQuantities}
            valueOptionsContent={getQuantitiesOptions()}
            valuesDisplayText={(
              listingGroup.deprioritizedQuantities ?? []
            ).join(',')}
          />
        </Stack>
      )}
      <GroupListingPreviewTable
        listingGroup={listingGroup}
        eventListings={eventListings}
        isUngroupedGroup={isUngroupedGroup}
      />
    </Stack>
  );
};
