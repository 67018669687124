import { useCallback, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getDefaultReturnUrl } from 'src/components/Events/EventPage/utils';
import { MainFilterBar } from 'src/components/MainFilterBar';
import { SaleEventPage } from 'src/components/Sales/SaleEventPage/SaleEventPage';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { CatalogMetricsContextProvider } from 'src/contexts/CatalogMetricsContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { queryIdSearchParam } from 'src/navigations/Routes/InventoryEvent/constants';
import {
  EventIdQueryParam,
  SaleDeeplinkQueryParam,
} from 'src/utils/constants/constants';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import { EmptySaleQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { addSaleMetrics } from 'src/utils/ticketMetricUtils';
import { EventPageLocationState } from 'src/utils/types/EventPageLocationState';
import {
  ActionOutboxEntityType,
  SaleClient,
  SaleDetails,
  SaleMetrics,
  SaleQuery,
  UserSetting,
} from 'src/WebApiController';

import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  POS_EVENT_IDS_SEPARATOR,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_SEPARATOR,
} from '../InventoryEvent/InventoryEvent.constants';
import { MainRoute } from '../MainRoute';
import {
  getCatalogData,
  getCatalogDataExpanded,
  getCatalogMetrics,
  SALE_USER_SETTINGS,
  salesQueryValueTransformFromUrl,
  salesQueryValueTransformToUrl,
} from '../Sales/Sales';

export function SalesEvent() {
  const [searchParams] = useSearchParams();
  const oldPosEventId = searchParams.get(queryIdSearchParam);
  const viagEventId = searchParams.get(EventIdQueryParam);

  const activeEventIds = searchParams.get(ACTIVE_EVENT_IDS_QUERY_PARAM);
  const tabEventIds = searchParams.get(TAB_EVENT_IDS_QUERY_PARAM);

  const activeEventIds2 = searchParams.get(ACTIVE_EVENT_IDS_2_QUERY_PARAM);
  const tabEventIds2 = searchParams.get(TAB_EVENT_IDS_2_QUERY_PARAM);

  const location = useLocation();

  const posEventIds = useMemo(
    // We need to make sure all the ids in the params are lowercase or else it won't be found in the CatalogDataContext
    () =>
      (tabEventIds != null
        ? activeEventIds?.toLowerCase()?.split(POS_EVENT_IDS_SEPARATOR)
        : oldPosEventId?.toLowerCase()?.split(POS_EVENT_IDS_SEPARATOR)) ?? [],
    [activeEventIds, oldPosEventId, tabEventIds]
  );

  const viagogoVirtualId = useMemo(
    // We need to make sure all the ids in the params are lowercase or else it won't be found in the CatalogDataContext
    () =>
      tabEventIds2 != null
        ? activeEventIds2?.toLowerCase()
        : viagEventId?.toLowerCase(),
    [activeEventIds2, tabEventIds2, viagEventId]
  );

  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], filterQuery: SaleQuery) =>
      getCatalogDataExpanded(ids, filterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('SaleClient.getSalesForEvents', error, {
            ...filterQuery,
            eventIds: ids,
          });
        },
      }),
    [activeAccountWebClientConfig, trackError]
  );

  const initialQuery = useMemo(() => {
    if (posEventIds.length || tabEventIds?.length) {
      const oldPosEventIds = tabEventIds
        ? tabEventIds
            .split(TAB_EVENT_IDS_SEPARATOR)
            .flatMap((eids) => eids.split(POS_EVENT_IDS_SEPARATOR))
        : posEventIds;
      return { ...EmptySaleQuery, oldPosEventIds };
    } else if (tabEventIds2?.length || viagogoVirtualId) {
      const eventOrMappingIds = tabEventIds2
        ? tabEventIds2.split(TAB_EVENT_IDS_SEPARATOR)
        : [viagogoVirtualId!];
      return { ...EmptySaleQuery, eventOrMappingIds };
    }

    return { ...EmptySaleQuery };
  }, [posEventIds, tabEventIds, tabEventIds2, viagogoVirtualId]);

  const getActivePosEntity = useCallback(
    async (saleId: number) => {
      if (activeAccountWebClientConfig.activeAccountId) {
        const saleDetail = await new SaleClient(
          activeAccountWebClientConfig
        ).getSaleBySaleId(saleId);

        if (saleDetail) {
          return {
            posEntityId: saleDetail.id,
            posEntity: saleDetail,
            posEntityDisplayId: saleDetail.idOnMkp,
          };
        }
      }
      return {};
    },
    [activeAccountWebClientConfig]
  );

  // Only use value provided at the first time (should be from '/inventory')
  const returnUrl = useMemo(() => {
    const locationState = (location.state ?? {}) as EventPageLocationState;

    if (locationState.returnUrl && locationState.keepReturnUrlUnchanged) {
      return locationState.returnUrl;
    }

    const relativeUrl =
      locationState.returnUrl || getDefaultReturnUrl('sales', viagEventId);

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      SaleDeeplinkQueryParam,
      ACTIVE_EVENT_IDS_QUERY_PARAM,
      TAB_EVENT_IDS_QUERY_PARAM,
      ACTIVE_EVENT_IDS_2_QUERY_PARAM,
      TAB_EVENT_IDS_2_QUERY_PARAM,
    ]);
  }, [location.state, viagEventId]);

  if (
    !posEventIds.length &&
    !tabEventIds?.length &&
    !tabEventIds2?.length &&
    !viagogoVirtualId
  ) {
    return null;
  }

  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={SALE_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<SaleQuery>
        initialQuery={initialQuery}
        emptyQuery={EmptySaleQuery}
        viewModeSettingId={UserSetting.SalePageViewMode}
        saveQueryInUrl
        queryValueTransformToUrl={salesQueryValueTransformToUrl}
        queryValueTransformFromUrl={salesQueryValueTransformFromUrl}
      >
        <CatalogDataContextProvider<SaleQuery>
          queryKey={'getCatalogForSale'}
          getCatalogData={(c, f) => getCatalogData(c, f, true)}
          getCatalogDataExpanded={getCatalogDataExpandedCallback}
          transformEventData={transformData}
        >
          <CatalogMetricsContextProvider<SaleMetrics, SaleQuery>
            queryKey="getCatalogSaleMetrics"
            getCatalogMetrics={getCatalogMetrics}
            addCatalogMetrics={addSaleMetrics}
          >
            <ActivePosEntityProvider<SaleDetails>
              entityType={ActionOutboxEntityType.Sale}
              getActivePosEntity={getActivePosEntity}
            >
              <CatalogMultiSelectionContextProvider type="sale">
                <MultiSelectionContextProvider>
                  <DialogProvider>
                    <LayoutContent mainRoute={MainRoute.Sales} routeTitle="">
                      <MainFilterBar
                        isEventPage
                        returnUrl={returnUrl}
                        posEventId={posEventIds[0]}
                      />
                      <SaleEventPage
                        posEventIds={posEventIds}
                        viagVirtualId={viagogoVirtualId}
                        returnUrl={returnUrl}
                      />
                    </LayoutContent>
                  </DialogProvider>
                </MultiSelectionContextProvider>
              </CatalogMultiSelectionContextProvider>
            </ActivePosEntityProvider>
          </CatalogMetricsContextProvider>
        </CatalogDataContextProvider>
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}
