import { EventInfo, EventInfoProps } from 'src/components/Events/EventInfo';
import {
  EVENT_TYPE_PARKING,
  getFormattedEventName,
} from 'src/utils/eventWithDataUtils';
import { getFormattedLocation } from 'src/utils/format';
import { Event, Performer, Venue } from 'src/WebApiController';

import * as styles from '../EventInfo/EventNameDisplay/EventNameDisplay.css';

export type ConnectedEventInfoProps = Pick<
  EventInfoProps,
  | 'showEventDateTbd'
  | 'showYear'
  | 'showMappingState'
  | 'eventMappingState'
  | 'mappingRejectionReasonId'
  | 'mappingRejectionReasonDescriptionDefault'
  | 'eventNameOnly'
  | 'slimMode'
> & {
  event?: Event | null;
  performer?: Performer | null;
  venue?: Venue | null;

  useShortName?: boolean;
  eventNameLink?: string;
};

export function ConnectedEventInfo({
  event,
  performer,
  venue,
  useShortName,
  ...eventInfoProps
}: ConnectedEventInfoProps) {
  let eventName: string | null = null;
  let eventNameConnector: string | null = null;
  let eventSubName: string | null = null;

  [eventName, eventNameConnector, eventSubName] = getFormattedEventName({
    event,
    performer,
    useShortName,
  });

  const eventNameNode =
    eventInfoProps.slimMode && eventName ? (
      <span className={styles.eventNameSlim}>{eventName}</span>
    ) : (
      eventName
    );

  const venueName = useShortName
    ? venue?.shortName || venue?.name
    : venue?.name;

  return (
    <EventInfo
      eventName={eventNameNode}
      eventNameConnector={eventNameConnector}
      eventSubName={eventSubName}
      eventDateString={event?.dates?.start ?? undefined}
      eventEndDateString={event?.dates?.end ?? undefined}
      venueName={venueName}
      venueLocation={venue ? getFormattedLocation(venue) : undefined}
      showParkingPassIcon={
        event?.eventType === EVENT_TYPE_PARKING && useShortName
      }
      eventGenre={event?.genre}
      {...eventInfoProps}
    />
  );
}
