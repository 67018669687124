import { PriceUpdatedFilterEnum } from 'src/components/Filters/StackedFilter/PriceUpdatedDateStackedFilter';
import { SaleDateFilterEnum } from 'src/components/Filters/StackedFilter/SaleDateStackedFilter';
import { CommandOptionType } from 'src/components/Listings/InventoryEventPage/CommandMenu/CommandMenu';
import { SiteThemeOption } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { RestrictSelectionType } from 'src/modals/ListingDetails/components/PricingSection/AutoPricingSection.types';
import { SettingTab } from 'src/navigations/Routes/Settings/constants';
import { SaleFormulaFields } from 'src/tables/SalesTable/SalesTable.type';
import { EventsTableColumnId } from 'src/utils/columns/events/eventsColumnUtils.types';
import { MarketplacePaymentsTableColumnId } from 'src/utils/columns/marketplacePayments/marketplacePaymentsColumnsUtils.types';
import {
  PurchasesTableColumnId,
  PurchaseTableColumnGroupId,
} from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import {
  SalesReportTableColumnId,
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';
import {
  ActionOutboxEntityType,
  ApiEventStatus,
  AutoPoInboundEmailClassificationType,
  AutoPricingCompListingMode,
  AutoPricingOutlierMode,
  AutoPricingResultType,
  AutoPricingUndercutMode,
  BroadcastErrorCode,
  BucketType,
  DealSplitType,
  DealThresholdSplitDirection,
  DeliveryType,
  EventDemandFactorType,
  EventGroupingType,
  EventTimeFrameFilter,
  EventUnpricedStatus,
  GroupingType,
  InventoryViewMode,
  ListingNoteType,
  ListingStatus,
  Marketplace,
  MarketplaceOperationResultState,
  MarketplacePaymentLineType,
  MarketplacePaymentState,
  MarketplacePaymentTerm,
  NotificationType,
  PaymentMethodNameKnownRegex,
  PendingProofState,
  Permission,
  PermissionAccessArea,
  PointOfSaleFulfillmentState,
  PointOfSalePaymentState,
  PointOfSaleSaleStatus,
  PricingFloorCeilingAdjustmentType,
  PurchaseOrderCostType,
  PurchaseOrderState,
  PurchasePaymentMethodType,
  PurchaseViewMode,
  QualityControlState,
  ReportGroupBy,
  ReportWidgetTypesInventory,
  Role,
  RowGroupSetting,
  SalesViewMode,
  SectionGroupAreaType,
  SplitType,
  TagsValueType,
  TicketGroupEditReason,
  TicketType,
  TimePeriodType,
  TopLevelCategory,
  VenueZoneConfigType,
} from 'src/WebApiController';

import { AdCampaignsTableColumnId } from '../columns/adCampaigns/adCampaignsColumnUtils.types';
import { AdGroupsTableColumnId } from '../columns/adGroups/adGroupsColumnUtils.types';
import {
  ListingReportTableColumnId,
  ListingTableColumnId,
  ListingTableFlattenedColumnId,
} from '../columns/inventory/inventoryColumnUtils.types';
import { ListingFormulaFields } from '../columns/inventory/inventoryCustomColumnUtils';
import {
  EventMappingStateFilter,
  EventUiSort,
  LastReviewedSinceDate,
  TextFilterEnum,
  YesNoEnum,
} from '../eventQueryUtils';
import { ReportFilterEditability } from '../reportsFilterUtils';
import { ReportTypes } from '../reportsUtils';
import { PurchasePaymentStatus } from './../../WebApiController';
import { ContentId } from './contentId';
import { FormatContentId } from './formatContentId';

// NOTE - the order declared here is the order the items shows up in the drop-down

export const EVENT_GROUPING_TO_CID: Record<EventGroupingType, ContentId> = {
  [EventGroupingType.Performer]: ContentId.Performers,
  [EventGroupingType.Venue]: ContentId.Venues,
};

export const EVENT_SORT_TO_CID: Record<EventUiSort, ContentId> = {
  [EventUiSort.DateDesc]: ContentId.DateDescArrow,
  [EventUiSort.DateAsc]: ContentId.DateAscArrow,
  [EventUiSort.SortAtoZ]: ContentId.AtoZArrow,
  [EventUiSort.SortZtoA]: ContentId.ZtoAArrow,
};

export const EVENT_SORT_DATE_ONLY_TO_CID: Record<string, ContentId> = {
  [EventUiSort.DateDesc]: ContentId.DateDescArrow,
  [EventUiSort.DateAsc]: ContentId.DateAscArrow,
};

export const INVENTORY_VIEW_MODE_TO_CID: Record<string, ContentId> = {
  [InventoryViewMode.TileView]: ContentId.TileView,
  [InventoryViewMode.MetricView]: ContentId.InsightsView,
  [InventoryViewMode.FlattenedView]: ContentId.ListView,
};
export const SALES_VIEW_MODE_TO_CID: Record<string, ContentId> = {
  [SalesViewMode.TileView]: ContentId.TileView,
  [SalesViewMode.FlattenedView]: ContentId.ListView,
};
export const PURCHASE_VIEW_MODE_TO_CID: Record<string, ContentId> = {
  [PurchaseViewMode.TileView]: ContentId.TimeView,
  [PurchaseViewMode.FlattenedView]: ContentId.ListView,
  [PurchaseViewMode.EventTileView]: ContentId.EventView,
};

export const PURCHASE_SORT_TO_CID: Record<string, ContentId> = {
  [EventUiSort.DateDesc]: ContentId.DateDescArrow,
  [EventUiSort.DateAsc]: ContentId.DateAscArrow,
};

export const TIME_PERIOD_TO_CID: Record<
  Exclude<TimePeriodType, TimePeriodType.Daily>,
  ContentId
> = {
  // [TimePeriodType.Daily]: ContentId.Day, // TODO - Fix how we handle timezones when fetching data
  [TimePeriodType.Weekly]: ContentId.Week,
  [TimePeriodType.Monthly]: ContentId.Month,
};

export const CONVERSATION_SORT_TO_CID: Record<string, ContentId> = {
  [EventUiSort.DateDesc]: ContentId.DateDescArrow,
  [EventUiSort.DateAsc]: ContentId.DateAscArrow,
};

export const DEAL_CONFIG_SPLIT_TYPE_TO_CID: Record<string, ContentId> = {
  [DealSplitType.Sales]: ContentId.Sales,
  [DealSplitType.Quantity]: ContentId.Quantity,
  [DealSplitType.None]: ContentId.NoHurdle,
};

export const DEAL_CONFIG_TRESHOLD_SPLIT_DIRECTION_TO_CID: Record<
  string,
  ContentId
> = {
  [DealThresholdSplitDirection.Above]: ContentId.Above,
  [DealThresholdSplitDirection.Below]: ContentId.Below,
};

/**
 * Use `getSaleStatusDisplayContentId` for displaying purposes
 */
export const SALES_STATUS_TO_CID: Record<PointOfSaleSaleStatus, ContentId> = {
  [PointOfSaleSaleStatus.PendingConfirmation]: ContentId.PendingConfirmation,
  [PointOfSaleSaleStatus.PendingAllocation]: ContentId.PendingSourcing,
  [PointOfSaleSaleStatus.ProvisionalAllocation]:
    ContentId.ProvisionalAllocation,
  [PointOfSaleSaleStatus.PendingFulfillment]: ContentId.PendingFulfillment,
  [PointOfSaleSaleStatus.PendingReshipment]: ContentId.PendingReshipment,
  [PointOfSaleSaleStatus.ProcessingFulfillment]:
    ContentId.ProcessingFulfillment,
  [PointOfSaleSaleStatus.Fulfilled]: ContentId.Fulfilled,
  [PointOfSaleSaleStatus.FulfillmentFailed]: ContentId.FulfillmentFailed,
  [PointOfSaleSaleStatus.PendingRejection]: ContentId.Rejected,
  [PointOfSaleSaleStatus.Rejected]: ContentId.Cancelled,
  [PointOfSaleSaleStatus.Hold]: ContentId.Hold,
  [PointOfSaleSaleStatus.CancelledHold]: ContentId.CancelledHold,
  [PointOfSaleSaleStatus.Wasted]: ContentId.Wasted,
};

export const SETTINGS_VIEWS_TO_CONTENT_ID_MAP: Record<SettingTab, ContentId> = {
  [SettingTab.MyAccount]: ContentId.MyAccount,
  [SettingTab.UserManagement]: ContentId.UserManagement,
  [SettingTab.UserManagement_Users]: ContentId.Users,
  [SettingTab.UserManagement_Roles]: ContentId.Roles,
  [SettingTab.UserManagement_Teams]: ContentId.Teams,
  [SettingTab.SyncCenter]: ContentId.SyncCenter,
  [SettingTab.AutoPricing]: ContentId.Autopricing,
  [SettingTab.PurchaseOrder]: ContentId.Purchases,
  [SettingTab.SellerAccount]: ContentId.SellerAccount,
  [SettingTab.CurrencyConversion]: ContentId.CurrencyConversion,
  [SettingTab.FulfillmentProviders]: ContentId.FulfillmentProviders,
  [SettingTab.VendorManagement]: ContentId.VendorManagement,
  [SettingTab.VendorManagement_Payments]: ContentId.Payments,
  [SettingTab.VendorManagement_Vendors]: ContentId.Vendors,
};

export const SALE_PAYMENT_STATE_TO_CID: Record<
  PointOfSalePaymentState,
  ContentId
> = {
  [PointOfSalePaymentState.Paid]: ContentId.Paid,
  [PointOfSalePaymentState.Queued]: ContentId.QueuedForPayment,
  [PointOfSalePaymentState.Unpaid]: ContentId.Unpaid,
  [PointOfSalePaymentState.PartiallyPaid]: ContentId.PartiallyPaid,
};

export const MARKETPLACE_SALE_PAYMENT_STATE_TO_CID: Record<
  MarketplacePaymentState,
  ContentId
> = {
  [MarketplacePaymentState.Paid]: ContentId.Paid,
  [MarketplacePaymentState.Queued]: ContentId.QueuedForPayment,
  [MarketplacePaymentState.Unpaid]: ContentId.Unpaid,
  [MarketplacePaymentState.PartiallyPaid]: ContentId.PartiallyPaid,
  [MarketplacePaymentState.Ineligible]: ContentId.Ineligible,
};

export const MARKETPLACE_PAYMENT_LINE_TYPE_TO_CID: Record<
  MarketplacePaymentLineType,
  ContentId
> = {
  [MarketplacePaymentLineType.Credit]: ContentId.Credit,
  [MarketplacePaymentLineType.Proceeds]: ContentId.Proceeds,
  [MarketplacePaymentLineType.FullPrice]: ContentId.FullPrice,
  [MarketplacePaymentLineType.SellerFee]: ContentId.SellerFee,
  [MarketplacePaymentLineType.Penalty]: ContentId.Penalty,
  [MarketplacePaymentLineType.AlternativeTickets]: ContentId.AlternativeTickets,
  [MarketplacePaymentLineType.OrderNotFulfilled]: ContentId.OrderNotFulfilled,
  [MarketplacePaymentLineType.CancelledEvent]: ContentId.CancelledEvent,
  [MarketplacePaymentLineType.WitheldFunds]: ContentId.WitheldFunds,
  [MarketplacePaymentLineType.LastMinuteSalesLateDelivery]:
    ContentId.LastMinuteSalesLateDelivery,
  [MarketplacePaymentLineType.ExpiredShippingLabel]:
    ContentId.ExpiredShippingLabel,
  [MarketplacePaymentLineType.DeliveryMethodChange]:
    ContentId.DeliveryMethodChange,
  [MarketplacePaymentLineType.BuyerDeniedEntry]: ContentId.BuyerDeniedEntry,
  [MarketplacePaymentLineType.WrongTicketsReturnShippingCost]:
    ContentId.WrongTicketsReturnShippingCost,
  [MarketplacePaymentLineType.EarlyShippingLabelGeneration]:
    ContentId.EarlyShippingLabelGeneration,
  [MarketplacePaymentLineType.WrongTrackingNumber]:
    ContentId.WrongTrackingNumber,
  [MarketplacePaymentLineType.ExcessiveFailureRate]:
    ContentId.ExcessiveFailureRate,
  [MarketplacePaymentLineType.SellerError]: ContentId.SellerError,
  [MarketplacePaymentLineType.AlternativeTicketsBuyerCompensation]:
    ContentId.AlternativeTicketsBuyerCompensation,
  [MarketplacePaymentLineType.RAGSellerFaultNoResponse]:
    ContentId.RAGSellerFaultNoResponse,
  [MarketplacePaymentLineType.OverchargedSeller]: ContentId.OverchargedSeller,
  [MarketplacePaymentLineType.ShippingRefund]: ContentId.ShippingRefund,
  [MarketplacePaymentLineType.SellerRelationship]: ContentId.SellerRelationship,
  [MarketplacePaymentLineType.RAGDeliveryDispute]: ContentId.RAGDeliveryDispute,
  [MarketplacePaymentLineType.CSError]: ContentId.CSError,
  [MarketplacePaymentLineType.LMSError]: ContentId.LMSError,
  [MarketplacePaymentLineType.TaxRefund]: ContentId.TaxRefund,
  [MarketplacePaymentLineType.Other]: ContentId.Other,
};

export const PURCHASE_PAYMENT_STATUS_TO_CID: Record<
  PurchasePaymentStatus,
  ContentId
> = {
  [PurchasePaymentStatus.Unpaid]: ContentId.Unpaid,
  [PurchasePaymentStatus.PartiallyPaid]: ContentId.PartiallyPaid,
  [PurchasePaymentStatus.Paid]: ContentId.Paid,
  [PurchasePaymentStatus.Failed]: ContentId.Failed,
  [PurchasePaymentStatus.RefundNeeded]: ContentId.RefundNeeded,
  [PurchasePaymentStatus.Refunded]: ContentId.Refunded,
  [PurchasePaymentStatus.PartiallyRefunded]: ContentId.PartiallyRefunded,
};

export const QUALITY_CONTROL_STATE_TO_CID: Record<
  QualityControlState,
  ContentId
> = {
  [QualityControlState.Pending]: ContentId.Pending,
  [QualityControlState.Approved]: ContentId.Approved,
  [QualityControlState.Rejected]: ContentId.Rejected,
};

export const ROLE_TO_CID: Record<Role, [ContentId, ContentId]> = {
  [Role.Owner]: [ContentId.Owner, ContentId.Role_Owner_Details],
  [Role.Buyer]: [ContentId.Buyer, ContentId.Role_Buyer_Details],
  [Role.Finance]: [ContentId.Finance, ContentId.Role_Finance_Details],
  [Role.Fulfillment]: [
    ContentId.Fulfillment,
    ContentId.Role_Fulfillment_Details,
  ],
  [Role.Pricer]: [ContentId.Pricer, ContentId.Role_Pricer_Details],
  [Role.Processor]: [ContentId.Processor, ContentId.Role_Processor_Details],
  [Role.AutoPO]: [ContentId.AutoPO, ContentId.Role_AutoPO_Details],
  [Role.AutoFulfill]: [
    ContentId.AutoFulfill,
    ContentId.Role_AutoFulfill_Details,
  ],
  [Role.AutoPricer]: [ContentId.AutoPricer, ContentId.Role_AutoPricer_Details],
  [Role.Reporter]: [ContentId.Reporter, ContentId.Role_Reporter_Details],
};

export const PERMISSION_ACCESS_AREA_TO_CID: Record<
  PermissionAccessArea,
  ContentId
> = {
  [PermissionAccessArea.General]: ContentId.General,
  [PermissionAccessArea.Inventory]: ContentId.Inventory,
  [PermissionAccessArea.Sales]: ContentId.Sales,
  [PermissionAccessArea.Purchases]: ContentId.Purchases,
  [PermissionAccessArea.Events]: ContentId.Events,
  [PermissionAccessArea.Account]: ContentId.Account,
  [PermissionAccessArea.Reporting]: ContentId.Reporting,
  [PermissionAccessArea.Messages]: ContentId.Messages,
  [PermissionAccessArea.Accounting]: ContentId.Accounting,
};

export const PERMISSION_TO_CID: Record<
  Permission,
  // TODO - add contentId for Details (explanation when clicking (i) icon)
  [ContentId, ContentId, 'view' | 'action']
> = {
  [Permission.Sales_ViewAll]: [
    ContentId.Permission_ViewAll,
    ContentId.Sales_ViewAll_Details,
    'view',
  ],
  [Permission.Sales_ViewRecent]: [
    ContentId.Permission_ViewRecentSales,
    ContentId.Sales_ViewRecent_Details,
    'view',
  ],
  [Permission.Sales_ViewRecentFromExternalMarketplace]: [
    ContentId.Permission_ViewRecentFromExternalMarketplace,
    ContentId.Sales_ViewRecentFromExternalMarketplace_Details,
    'view',
  ],
  [Permission.Sales_ConfirmReject]: [
    ContentId.Permission_ConfirmRejectSale,
    ContentId.Sales_ConfirmReject_Details,
    'action',
  ],
  [Permission.Sales_Fulfill]: [
    ContentId.Permission_FulfillSale,
    ContentId.Sales_Fulfill_Details,
    'action',
  ],
  [Permission.Sales_CreateForExternalMarketplaces]: [
    ContentId.Permission_CreateForExternalMarketplaces,
    ContentId.Sales_CreateForExternalMarketplaces_Details,
    'action',
  ],
  [Permission.Sales_ViewProceeds]: [
    ContentId.Permission_ViewProceeds,
    ContentId.Sales_ViewProceeds_Details,
    'view',
  ],
  [Permission.Sales_ViewRecentProceeds]: [
    ContentId.Permission_ViewRecentProceeds,
    ContentId.Sales_ViewRecentProceeds_Details,
    'view',
  ],
  [Permission.Sales_ViewCostAndMargin]: [
    ContentId.Permission_ViewCostAndMargin,
    ContentId.Sales_ViewCostAndMargin_Details,
    'view',
  ],
  [Permission.Sales_ViewPaymentState]: [
    ContentId.Permission_ViewPaymentState,
    ContentId.Sales_ViewPaymentState_Details,
    'view',
  ],
  [Permission.Sales_ViewFulfillmentArtifacts]: [
    ContentId.Permission_ViewFulfillmentArtifacts,
    ContentId.Sales_ViewFulfillmentArtifacts_Details,
    'view',
  ],
  [Permission.Sales_ManageHold]: [
    ContentId.Permission_ManageHolds,
    ContentId.Sales_ManageHold_Details,
    'action',
  ],
  [Permission.Inventory_ViewAll]: [
    ContentId.Permission_ViewAll,
    ContentId.Inventory_ViewAll_Details,
    'view',
  ],
  [Permission.Inventory_ViewRecent]: [
    ContentId.Permission_ViewRecentListings,
    ContentId.Inventory_ViewRecent_Details,
    'view',
  ],
  [Permission.Inventory_ViewRecentFromExternalMarketplace]: [
    ContentId.Permission_ViewRecentFromExternalMarketplace,
    ContentId.Inventory_ViewRecentFromExternalMarketplace_Details,
    'view',
  ],
  [Permission.Inventory_ViewAllMarketplaceAttributes]: [
    ContentId.Permission_ViewAllMarketplaceAttributes,
    ContentId.Inventory_ViewAllMarketplaceAttributes_Details,
    'view',
  ],
  [Permission.Inventory_ViewExternalMarketplaceAttributes]: [
    ContentId.Permission_ViewExternalMarketplaceAttributes,
    ContentId.Inventory_ViewExternalMarketplaceAttributes_Details,
    'view',
  ],
  [Permission.Inventory_ViewFulfillmentArtifacts]: [
    ContentId.Permission_ViewFulfillmentArtifacts,
    ContentId.Inventory_ViewFulfillmentArtifacts_Details,
    'view',
  ],
  [Permission.Inventory_SetPrice]: [
    ContentId.Permission_SetPrice,
    ContentId.Inventory_SetPrice_Details,
    'action',
  ],
  [Permission.Inventory_SetPriceOnPurchasedBy]: [
    ContentId.Permission_SetPriceOnPurchasedListings,
    ContentId.Inventory_SetPriceOnPurchasedBy_Details,
    'action',
  ],
  [Permission.Inventory_SetPriceOnIsPricerOf]: [
    ContentId.Permission_SetPriceOnAssignedListings,
    ContentId.Inventory_SetPriceOnIsPricerOf_Details,
    'action',
  ],
  [Permission.Inventory_Predelivery]: [
    ContentId.Permission_Predeliver,
    ContentId.Inventory_Predelivery_Details,
    'action',
  ],
  [Permission.Inventory_BroadcastUnbroadcast]: [
    ContentId.Permission_BroadcastUnbroadcast,
    ContentId.Inventory_BroadcastUnbroadcast_Details,

    'action',
  ],
  [Permission.Inventory_BroadcastUnbroadcastOnPurchasedBy]: [
    ContentId.Permission_BroadcastUnbroadcastOnPurchasedBy,
    ContentId.Inventory_BroadcastUnbroadcastOnPurchasedBy_Details,
    'action',
  ],
  [Permission.Inventory_UpdateListingInfo]: [
    ContentId.Permission_UpdateInfo,
    ContentId.Inventory_UpdateListingInfo_Details,
    'action',
  ],
  [Permission.Inventory_UpdateListingInfoOnPurchasedBy]: [
    ContentId.Permission_UpdateInfoOnPurchasedListings,
    ContentId.Inventory_UpdateListingInfoOnPurchasedBy_Details,
    'action',
  ],
  [Permission.Inventory_UpdateDeliveryStrategy]: [
    ContentId.Permission_UpdateDeliveryStrategy,
    ContentId.Inventory_UpdateDeliveryStrategy_Details,
    'action',
  ],
  [Permission.Inventory_UpdateDeliveryStrategyOnIsPricerOf]: [
    ContentId.Permission_UpdateDeliveryStrategyOnPricedListings,
    ContentId.Inventory_UpdateDeliveryStrategyOnIsPricerOf_Details,
    'action',
  ],
  [Permission.Inventory_UpdateDeliveryStrategyOnPurchasedBy]: [
    ContentId.Permission_UpdateDeliveryStrategyOnPurchasedListings,
    ContentId.Inventory_UpdateDeliveryStrategyOnPurchasedBy_Details,
    'action',
  ],
  [Permission.SellerAccount_Admin]: [
    ContentId.Permission_Administration,
    ContentId.SellerAccount_Admin_Details,
    'action',
  ],
  [Permission.SellerSupportCase_Create]: [
    ContentId.Permission_Create,
    ContentId.SellerSupportCase_Create_Details,
    'action',
  ],
  [Permission.SellerSupportCase_View]: [
    ContentId.Permission_ViewAll,
    ContentId.SellerSupportCase_View_Details,
    'view',
  ],
  [Permission.Purchases_ViewAll]: [
    ContentId.Permission_ViewAll,
    ContentId.Purchases_ViewAll_Details,
    'view',
  ],
  [Permission.Purchases_ViewRecent]: [
    ContentId.Permission_ViewRecent,
    ContentId.Purchases_ViewRecent_Details,
    'view',
  ],
  [Permission.Purchases_Edit]: [
    ContentId.Permission_UpdateInfo,
    ContentId.Purchases_Edit_Details,
    'action',
  ],
  [Permission.Purchases_EditOwned]: [
    ContentId.Permission_UpdateInfoOnPurchasedListings,
    ContentId.Purchases_EditOwned_Details,
    'action',
  ],
  [Permission.Purchases_UpdateBuyerCommission]: [
    ContentId.Permission_UpdateBuyerCommission,
    ContentId.Purchases_UpdateBuyerCommission_Details,
    'action',
  ],
  [Permission.Purchases_UpdateBuyerCommissionOwned]: [
    ContentId.Permission_UpdateBuyerCommissionOwned,
    ContentId.Purchases_UpdateBuyerCommissionOwned_Details,
    'action',
  ],
  [Permission.Purchases_UpdateDeliveryType]: [
    ContentId.Permission_UpdateDeliveryType,
    ContentId.Purchases_UpdateDeliveryType_Details,
    'action',
  ],
  [Permission.Purchases_UpdateDeliveryTypeOwned]: [
    ContentId.Permission_UpdateDeliveryTypeOwned,
    ContentId.Purchases_UpdateDeliveryTypeOwned_Details,
    'action',
  ],
  [Permission.Purchases_UpdateCustomDeliveryType]: [
    ContentId.Permission_UpdateCustomDeliveryType,
    ContentId.Purchases_UpdateCustomDeliveryType_Details,
    'action',
  ],
  [Permission.Purchases_UpdateCustomDeliveryTypeOwned]: [
    ContentId.Permission_UpdateCustomDeliveryTypeOwned,
    ContentId.Purchases_UpdateCustomDeliveryTypeOwned_Details,
    'action',
  ],
  [Permission.Purchases_UpdateSeatTraits]: [
    ContentId.Permission_UpdateSeatTraits,
    ContentId.Purchases_UpdateSeatTraits_Details,
    'action',
  ],
  [Permission.Purchases_UpdateSeatTraitsOwned]: [
    ContentId.Permission_UpdateSeatTraitsOwned,
    ContentId.Purchases_UpdateSeatTraitsOwned_Details,
    'action',
  ],
  [Permission.Purchases_Create]: [
    ContentId.Permission_CreatePO,
    ContentId.Purchases_Create_Details,
    'action',
  ],
  [Permission.Events_ViewAll]: [
    ContentId.Permission_ViewAll,
    ContentId.Events_ViewAll_Details,
    'view',
  ],
  [Permission.Accounts_ViewAll]: [
    ContentId.Permission_ViewAll,
    ContentId.Accounts_ViewAll_Details,
    'view',
  ],
  [Permission.TicketGroups_ViewAll]: [
    ContentId.Permission_ViewAll,
    ContentId.TicketGroups_ViewAll_Details,
    'view',
  ],
  [Permission.Sales_AddTagType]: [
    ContentId.Permission_AddTagType,
    ContentId.Sales_AddTagType_Details,
    'action',
  ],
  [Permission.Sales_UpdateTags]: [
    ContentId.Permission_UpdateTags,
    ContentId.Sales_UpdateTags_Details,
    'action',
  ],
  [Permission.Sales_ViewSalesOnIsPricerOf]: [
    ContentId.Permission_ViewSaleFromPricedListings,
    ContentId.Sales_ViewSalesOnIsPricerOf_Details,
    'view',
  ],
  [Permission.Sales_UpdateSalesInfo]: [
    ContentId.Permission_UpdateInfo,
    ContentId.Sales_UpdateSalesInfo_Details,
    'action',
  ],
  [Permission.Sales_UpdateSalesInfoOnIsPricerOf]: [
    ContentId.Permission_UpdateInfoOnPricedListings,
    ContentId.Sales_UpdateSalesInfoOnIsPricerOf_Details,
    'action',
  ],
  [Permission.Sales_UpdateSalesInfoOnIsFulfillerOf]: [
    ContentId.Permission_UpdateInfoOnAssignedSales,
    ContentId.Sales_UpdateSalesInfoOnIsFulfillerOf_Details,
    'action',
  ],
  [Permission.Sales_FulfillOnIsFulfillerOf]: [
    ContentId.Permission_FulfillSalesOnAssignedSales,
    ContentId.Sales_FulfillOnIsFulfillerOf_Details,
    'action',
  ],
  [Permission.Sales_ViewSalesOnPurchasedBy]: [
    ContentId.Permission_ViewSaleOnPurchasedListings,
    ContentId.Sales_ViewSalesOnPurchasedBy_Details,
    'view',
  ],
  [Permission.Sales_UpdateSaleOnPurchasedBy]: [
    ContentId.Permission_UpdateInfoFromPurchasedListings,
    ContentId.Sales_UpdateSaleOnPurchasedBy_Details,
    'action',
  ],
  [Permission.Sales_FulfillOnPurchasedBy]: [
    ContentId.Permission_FulfillSalesFromPurchasedListings,
    ContentId.Sales_FulfillOnPurchasedBy_Details,
    'action',
  ],
  [Permission.Sales_FulfillOnPricerOf]: [
    ContentId.Permission_FulfillSaleOnPricedListings,
    ContentId.Sales_FulfillOnPricerOf_Details,
    'action',
  ],
  [Permission.Sales_UpdateTagsOnPurchasedBy]: [
    ContentId.Permission_UpdateTagsFromPurchasedListings,
    ContentId.Sales_UpdateTagsOnPurchasedBy_Details,
    'action',
  ],
  [Permission.Sales_UpdateTagsOnIsPricerOf]: [
    ContentId.Permission_UpdateTagsFromPricedListings,
    ContentId.Sales_UpdateTagsOnIsPricerOf_Details,
    'action',
  ],
  [Permission.Sales_UpdateTagsOnIsFulfillerOf]: [
    ContentId.Permission_UpdateTagsOnAssignedSales,
    ContentId.Sales_UpdateTagsOnIsFulfillerOf_Details,
    'action',
  ],
  [Permission.Sales_ConfirmRejectOnIsPricerOf]: [
    ContentId.Permission_ConfirmRejectSaleOnPricedListings,
    ContentId.Sales_ConfirmRejectOnIsPricerOf_Details,
    'action',
  ],
  [Permission.Sales_ConfirmRejectOnPurchasedBy]: [
    ContentId.Permission_ConfirmRejectSalesFromPurchasedListings,
    ContentId.Sales_ConfirmRejectOnPurchasedBy_Details,
    'action',
  ],
  [Permission.Sales_ViewFulfillmentArtifactsOnPurchasedBy]: [
    ContentId.Permission_ViewFulfillmentArtifactsOnSaleFromPurchasedListings,
    ContentId.Sales_ViewFulfillmentArtifactsOnPurchasedBy_Details,
    'view',
  ],
  [Permission.Sales_ViewFulfillmentArtifactsOnIsPricerOf]: [
    ContentId.Permission_ViewFulfillmentArtifactsOnSaleFromPricedListings,
    ContentId.Sales_ViewFulfillmentArtifactsOnIsPricerOf_Details,
    'view',
  ],
  [Permission.Sales_ConfirmRejectOnIsFulfillerOf]: [
    ContentId.Permission_ConfirmRejectOnAssignedSales,
    ContentId.Sales_ConfirmRejectOnIsFulfillerOf_Details,
    'action',
  ],
  [Permission.Sales_ViewSalesOnIsFulfillerOf]: [
    ContentId.Permission_ViewAssignedSales,
    ContentId.Sales_ViewSalesOnIsFulfillerOf_Details,
    'view',
  ],
  [Permission.Inventory_EditPricing]: [
    ContentId.Permission_SetPrice,
    ContentId.Inventory_EditPricing_Details,
    'action',
  ],
  [Permission.Inventory_AddTagType]: [
    ContentId.Permission_AddTagType,
    ContentId.Inventory_AddTagType_Details,
    'action',
  ],
  [Permission.Inventory_UpdateTags]: [
    ContentId.Permission_UpdateTags,
    ContentId.Inventory_UpdateTags_Details,
    'action',
  ],
  [Permission.Inventory_UpdateTagsOnPurchasedBy]: [
    ContentId.Permission_UpdateTagsOnPurchasedListings,
    ContentId.Inventory_UpdateTagsOnPurchasedBy_Details,
    'action',
  ],
  [Permission.Inventory_ViewListingOnIsPricerOf]: [
    ContentId.Permission_ViewAssignedListings,
    ContentId.Inventory_ViewListingOnIsPricerOf_Details,
    'view',
  ],
  [Permission.Inventory_UpdateListingInfoOnIsPricerOf]: [
    ContentId.Permission_UpdateInfoOnAssignedListings,
    ContentId.Inventory_UpdateListingInfoOnIsPricerOf_Details,
    'action',
  ],
  [Permission.Inventory_UpdateTagsOnIsPricerOf]: [
    ContentId.Permission_UpdateTagsOnAssignedListings,
    ContentId.Inventory_UpdateTagsOnIsPricerOf_Details,
    'action',
  ],
  [Permission.Inventory_PredeliveryOnPurchasedBy]: [
    ContentId.Permission_PredeliverPurchasedListings,
    ContentId.Inventory_PredeliveryOnPurchasedBy_Details,
    'action',
  ],
  [Permission.Inventory_PredeliveryOnIsPricerOf]: [
    ContentId.Permission_PredeliverOnAssignedListings,
    ContentId.Inventory_PredeliveryOnIsPricerOf_Details,
    'action',
  ],
  [Permission.Inventory_ViewListingsOnPurchasedBy]: [
    ContentId.Permission_ViewPurchasedListings,
    ContentId.Inventory_ViewListingsOnPurchasedBy_Details,
    'view',
  ],
  [Permission.Inventory_ViewFulfillmentArtifactsOnPurchasedBy]: [
    ContentId.Permission_ViewFulfillmentArtifactsOnPurchasedListings,
    ContentId.Inventory_ViewFulfillmentArtifactsOnPurchasedBy_Details,
    'view',
  ],
  [Permission.Inventory_ViewFulfillmentArtifactsOnIsPricerOf]: [
    ContentId.Permission_ViewFulfillmentArtifactsOnAssignedListings,
    ContentId.Inventory_ViewFulfillmentArtifactsOnIsPricerOf_Details,
    'view',
  ],
  [Permission.Inventory_BroadcastUnbroadcastOnIsPricerOf]: [
    ContentId.Permission_BroadcastUnbroadcastOnAssignedListings,
    ContentId.Inventory_BroadcastUnbroadcastOnIsPricerOf_Details,
    'action',
  ],
  [Permission.Purchases_AddTagType]: [
    ContentId.Permission_AddTagType,
    ContentId.Purchases_AddTagType_Details,
    'action',
  ],
  [Permission.Purchases_UpdateTags]: [
    ContentId.Permission_UpdateTags,
    ContentId.Purchases_UpdateTags_Details,
    'action',
  ],
  [Permission.Purchases_UpdateTagsOwned]: [
    ContentId.Permission_UpdateTagsOnOwnedPurchasedOrder,
    ContentId.Purchases_UpdateTagsOwned_Details,
    'action',
  ],
  [Permission.Purchases_ViewOwned]: [
    ContentId.Permission_ViewOwnedPurchasedOrders,
    ContentId.Purchases_ViewOwned_Details,
    'view',
  ],
  [Permission.Events_AddTagType]: [
    ContentId.Permission_AddTagType,
    ContentId.Events_AddTagType_Details,
    'action',
  ],
  [Permission.Events_UpdateTags]: [
    ContentId.Permission_UpdateTags,
    ContentId.Events_UpdateTags_Details,
    'action',
  ],
  [Permission.Events_Edit]: [
    ContentId.Permission_UpdateInfo,
    ContentId.Events_Edit_Details,
    'action',
  ],
  [Permission.Reports_CreateReports]: [
    ContentId.Permission_CreateReports,
    ContentId.Reports_CreateReports_Details,
    'action',
  ],
  [Permission.Reports_UpdateReports]: [
    ContentId.Permission_UpdateReports,
    ContentId.Reports_UpdateReports_Details,
    'action',
  ],
  [Permission.Reports_UpdateReportsOwned]: [
    ContentId.Permission_UpdateCreatedReports,
    ContentId.Reports_UpdateReportsOwned_Details,
    'action',
  ],
  [Permission.Reports_ViewInventoryReports]: [
    ContentId.Permission_ViewInventoryReports,
    ContentId.Reports_ViewInventoryReports_Details,
    'view',
  ],
  [Permission.Reports_ViewSalesReports]: [
    ContentId.Permission_ViewSalesReports,
    ContentId.Reports_ViewSalesReports_Details,
    'view',
  ],
  [Permission.Reports_ViewGlobalReports]: [
    ContentId.Permission_ViewGlobalReports,
    ContentId.Reports_ViewGlobalReports_Details,
    'view',
  ],
  [Permission.Reports_ViewAll]: [
    ContentId.Permission_ViewAll,
    ContentId.Reports_ViewAll_Details,
    'view',
  ],
  [Permission.Sales_ViewFulfillmentArtifactsOnIsFulfillerOf]: [
    ContentId.Permission_ViewFulfillmentArtifactsOnAssignedSales,
    ContentId.Sales_ViewFulfillmentArtifactsOnIsFulfillerOf_Details,
    'view',
  ],
  [Permission.Sales_CreateOfflineSale]: [
    ContentId.Permission_CreateOfflineSale,
    ContentId.Sales_CreateOfflineSale_Details,
    'action',
  ],
  [Permission.Sales_ManageOfflineSale]: [
    ContentId.Permission_ManageOfflineSale,
    ContentId.Sales_ManageOfflineSale_Details,
    'action',
  ],
  [Permission.Sales_ManageOfflineSaleCreatedBy]: [
    ContentId.Permission_ManageOfflineSalesOnPurchasedListings,
    ContentId.Sales_ManageOfflineSaleCreatedBy_Details,
    'action',
  ],
  [Permission.Sales_ConfirmTransferWithoutProof]: [
    ContentId.Permission_ConfirmTransferWithoutProof,
    ContentId.Sales_ConfirmTransferWithoutProof_Details,
    'action',
  ],
  [Permission.Sales_ResetFulfillment]: [
    ContentId.Permission_ResetFulfillment,
    ContentId.Sales_ResetFulfillment_Details,
    'action',
  ],
  [Permission.Inventory_AddSeatSaver]: [
    ContentId.Permission_AddSeatSaver,
    ContentId.Inventory_AddSeatSaver_Details,
    'action',
  ],
  [Permission.Inventory_ManageSeatSaver]: [
    ContentId.Permission_ManagePlaceholderListings,
    ContentId.Inventory_ManageSeatSaver_Details,
    'action',
  ],
  [Permission.Inventory_ManageSeatSaverCreatedBy]: [
    ContentId.Permission_ManageCreatedPlaceholderListings,
    ContentId.Inventory_ManageSeatSaverCreatedBy_Details,
    'action',
  ],
  [Permission.Events_SetPrice]: [
    ContentId.Permission_ConfigurePricing,
    ContentId.Events_SetPrice_Details,
    'action',
  ],
  [Permission.Account_SetPrice]: [
    ContentId.Permission_ConfigurePricing,
    ContentId.Account_SetPrice_Details,
    'action',
  ],
  [Permission.Webhook_View]: [
    ContentId.Permission_Webhook_View,
    ContentId.Webhook_View_Details,
    'view',
  ],
  [Permission.Webhook_Create]: [
    ContentId.Permission_Webhook_Create,
    ContentId.Webhook_Create_Details,
    'action',
  ],
  [Permission.Webhook_Edit]: [
    ContentId.Permission_Webhook_Edit,
    ContentId.Webhook_Edit_Details,
    'action',
  ],
  [Permission.EventConfig_Create]: [
    ContentId.Permission_EventConfig_Create,
    ContentId.EventConfig_Create_Details,
    'action',
  ],
  [Permission.EventConfig_Edit]: [
    ContentId.Permission_EventConfig_Edit,
    ContentId.EventConfig_Edit_Details,
    'action',
  ],
  [Permission.EventConfig_View]: [
    ContentId.Permission_EventConfig_View,
    ContentId.EventConfig_View_Details,
    'view',
  ],
  [Permission.Accounting_ViewAll]: [
    ContentId.Permission_ViewAll,
    ContentId.Accounting_ViewAll_Details,
    'view',
  ],
  [Permission.Accounting_ViewPaymentsOnSale]: [
    ContentId.Permission_ViewPaymentsOnSale,
    ContentId.Accounting_ViewPaymentsOnSale_Details,
    'view',
  ],
};

export const EVENT_TIME_FRAME_FILTER_TO_CID: Record<
  EventTimeFrameFilter,
  ContentId
> = {
  [EventTimeFrameFilter.Future]: ContentId.FutureEvents,
  [EventTimeFrameFilter.Past]: ContentId.PastEvents,
};

export const MARKETPLACE_PAYMENTS_TIME_FRAME_FILTER_TO_CID: Record<
  EventTimeFrameFilter,
  ContentId
> = {
  [EventTimeFrameFilter.Future]: ContentId.FuturePayments,
  [EventTimeFrameFilter.Past]: ContentId.PastPayments,
};

export const TOP_LEVEL_CATEGORY_TO_CID: Record<TopLevelCategory, ContentId> = {
  [TopLevelCategory.Concert]: ContentId.Concert,
  [TopLevelCategory.Festival]: ContentId.Festival,
  [TopLevelCategory.Sports]: ContentId.Sports,
  [TopLevelCategory.Theatre]: ContentId.Theatre,
};

export const PARENT_CATEGORY_TO_CID: Record<string, ContentId> = {
  '1007': ContentId.Category1007,
  '1009': ContentId.Category1009,
  '4286': ContentId.Category4286,
  '5099': ContentId.Category5099,
  '6401': ContentId.Category6401,
  '6404': ContentId.Category6404,
  '6451': ContentId.Category6451,
  '6452': ContentId.Category6452,
  '12567': ContentId.Category12567,
  '263024': ContentId.Category263024,
  '272988': ContentId.Category272988,
  '272989': ContentId.Category272989,
  '273013': ContentId.Category273013,
  '273026': ContentId.Category273026,
  '273031': ContentId.Category273031,
  '273034': ContentId.Category273034,
  '273055': ContentId.Category273055,
  '273057': ContentId.Category273057,
  '273089': ContentId.Category273089,
  '273137': ContentId.Category273137,
  '273138': ContentId.Category273138,
  '273164': ContentId.Category273164,
  '273171': ContentId.Category273171,
  '273393': ContentId.Category273393,
  '273409': ContentId.Category273409,
  '273431': ContentId.Category273431,
  '273485': ContentId.Category273485,
  '273529': ContentId.Category273529,
  '273657': ContentId.Category273657,
  '274164': ContentId.Category274164,
  '274701': ContentId.Category274701,
  '274794': ContentId.Category274794,
  '275344': ContentId.Category275344,
  '275641': ContentId.Category275641,
  '276077': ContentId.Category276077,
  '276281': ContentId.Category276281,
  '276306': ContentId.Category276306,
  '276308': ContentId.Category276308,
  '276311': ContentId.Category276311,
  '276861': ContentId.Category276861,
  '278221': ContentId.Category278221,
  '278837': ContentId.Category278837,
  '279267': ContentId.Category279267,
  '279563': ContentId.Category279563,
  '289352': ContentId.Category289352,
  '150012246': ContentId.Category150012246,
  '1013': ContentId.Category1013,
  '1015': ContentId.Category1015,
  '1017': ContentId.Category1017,
  '2294': ContentId.Category2294,
  '4551': ContentId.Category4551,
  '6403': ContentId.Category6403,
  '55548': ContentId.Category55548,
  '259716': ContentId.Category259716,
  '356361': ContentId.Category356361,
  '356453': ContentId.Category356453,
  '356499': ContentId.Category356499,
  '356585': ContentId.Category356585,
  '357019': ContentId.Category357019,
  '357036': ContentId.Category357036,
  '358121': ContentId.Category358121,
  '358959': ContentId.Category358959,
  '366262': ContentId.Category366262,
  '366288': ContentId.Category366288,
  '366529': ContentId.Category366529,
  '366888': ContentId.Category366888,
  '375100': ContentId.Category375100,
  '379731': ContentId.Category379731,
  '379991': ContentId.Category379991,
  '380480': ContentId.Category380480,
  '381561': ContentId.Category381561,
  '150002820': ContentId.Category150002820,
  '1014': ContentId.Category1014,
  '1022': ContentId.Category1022,
  '1023': ContentId.Category1023,
  '1026': ContentId.Category1026,
  '1027': ContentId.Category1027,
  '1059': ContentId.Category1059,
  '1205': ContentId.Category1205,
  '14858': ContentId.Category14858,
  '259810': ContentId.Category259810,
  '259815': ContentId.Category259815,
  '259819': ContentId.Category259819,
  '259820': ContentId.Category259820,
  '259822': ContentId.Category259822,
  '259823': ContentId.Category259823,
  '259824': ContentId.Category259824,
  '259827': ContentId.Category259827,
  '259828': ContentId.Category259828,
  '259829': ContentId.Category259829,
  '259833': ContentId.Category259833,
  '259921': ContentId.Category259921,
  '259922': ContentId.Category259922,
  '260261': ContentId.Category260261,
  '260263': ContentId.Category260263,
  '260264': ContentId.Category260264,
  '260265': ContentId.Category260265,
  '260266': ContentId.Category260266,
  '260267': ContentId.Category260267,
  '260268': ContentId.Category260268,
  '260270': ContentId.Category260270,
  '260271': ContentId.Category260271,
  '260275': ContentId.Category260275,
  '260347': ContentId.Category260347,
  '260357': ContentId.Category260357,
  '260385': ContentId.Category260385,
  '260389': ContentId.Category260389,
  '260392': ContentId.Category260392,
  '260421': ContentId.Category260421,
  '260478': ContentId.Category260478,
  '260483': ContentId.Category260483,
  '260492': ContentId.Category260492,
  '260530': ContentId.Category260530,
  '260539': ContentId.Category260539,
  '260542': ContentId.Category260542,
  '260601': ContentId.Category260601,
  '260624': ContentId.Category260624,
  '260628': ContentId.Category260628,
  '260637': ContentId.Category260637,
  '260644': ContentId.Category260644,
  '261289': ContentId.Category261289,
  '261291': ContentId.Category261291,
  '308335': ContentId.Category308335,
  '308336': ContentId.Category308336,
  '308341': ContentId.Category308341,
  '308342': ContentId.Category308342,
  '308344': ContentId.Category308344,
  '308345': ContentId.Category308345,
  '308350': ContentId.Category308350,
  '308351': ContentId.Category308351,
  '308352': ContentId.Category308352,
  '308353': ContentId.Category308353,
  '308355': ContentId.Category308355,
  '308357': ContentId.Category308357,
  '308358': ContentId.Category308358,
  '308363': ContentId.Category308363,
  '308364': ContentId.Category308364,
  '308365': ContentId.Category308365,
  '308385': ContentId.Category308385,
  '321834': ContentId.Category321834,
  '1024': ContentId.Category1024,
};

export const MARKET_PLACE_TO_CID: Record<Marketplace, ContentId | string> = {
  [Marketplace.StubHub]: Marketplace.StubHub,
  [Marketplace.SeatGeek]: Marketplace.SeatGeek,
  [Marketplace.Ticketmaster]: Marketplace.Ticketmaster,
  [Marketplace.VividSeats]: Marketplace.VividSeats,
  [Marketplace.TickPick]: Marketplace.TickPick,
  [Marketplace.Offline]: ContentId.DirectOffline,
  [Marketplace.AXS]: Marketplace.AXS,
  [Marketplace.FanXchange]: Marketplace.FanXchange,
  [Marketplace.Gametime]: Marketplace.Gametime,
  [Marketplace.TicketEvolution]: Marketplace.TicketEvolution,
  [Marketplace.TicketNetwork]: Marketplace.TicketNetwork,
  [Marketplace.Automatiq]: Marketplace.Automatiq,
  [Marketplace.Lyte]: Marketplace.Lyte,
  [Marketplace.GoTickets]: Marketplace.GoTickets,
  [Marketplace.B2BSales]: Marketplace.B2BSales,
};

export const YES_NO_ENUM_FILTER_TO_CID: Record<YesNoEnum, ContentId> = {
  [YesNoEnum.Yes]: ContentId.Yes,
  [YesNoEnum.No]: ContentId.No,
};

export const EVENT_PRICED_STATUS_TO_CID: Record<
  EventUnpricedStatus,
  ContentId
> = {
  [EventUnpricedStatus.AllUnpriced]: ContentId.All,
  [EventUnpricedStatus.NoneUnpriced]: ContentId.None,
  [EventUnpricedStatus.AnyUnpriced]: ContentId.Any,
};

export const EVENT_LAST_REVIEWED_SINCE_DATE_CID: Record<
  LastReviewedSinceDate,
  ContentId
> = {
  [LastReviewedSinceDate.LastThreeDays]: ContentId.Last3Days,
  [LastReviewedSinceDate.LastSevenDays]: ContentId.LastWeek,
  [LastReviewedSinceDate.LastTwoWeeks]: ContentId.Last2Weeks,
  [LastReviewedSinceDate.LastThirtyDays]: ContentId.Last30Days,
};

export const CONTAINS_EQUALS_ENUM_FILTER_TO_CID: Record<
  TextFilterEnum,
  ContentId
> = {
  [TextFilterEnum.Contains]: ContentId.Contains,
  [TextFilterEnum.Equals]: ContentId.Equals,
  [TextFilterEnum.NotContains]: ContentId.NotContains,
};

export const SALES_DATE_FILTER_TO_CID: Record<SaleDateFilterEnum, ContentId> = {
  [SaleDateFilterEnum.HasSaleDate]: ContentId.SaleDateFilterOccured,
  [SaleDateFilterEnum.NoSaleDate]: ContentId.SaleDateFilterNotOccured,
};

export const PRICE_UPDATED_DATE_FILTER_TO_CID: Record<
  PriceUpdatedFilterEnum,
  ContentId
> = {
  [PriceUpdatedFilterEnum.PriceUpdatedDate]: ContentId.PriceDateFilterChanged,
  [PriceUpdatedFilterEnum.PriceNotUpdatedDate]:
    ContentId.PriceDateFilterUnchanged,
};

/**
 * This is created when we don't need to show the No option in the filter
 */
export const YES_ENUM_FILTER_TO_CID: Record<string, ContentId> = {
  [YesNoEnum.Yes]: ContentId.Yes,
};

/**
 * This is created because the original YES_NO_ENUM_FILTER_TO_CID was used for
 * filter query "isSeatingUnmapped" and the labels was Seating Unmapped.
 * A new business requirement, asks for called it Seating Mapped, so the values
 * must be inverted in order to continue working.
 */
export const YES_NO_INVERTED_ENUM_FILTER_TO_CID: Record<YesNoEnum, ContentId> =
  {
    [YesNoEnum.Yes]: ContentId.No,
    [YesNoEnum.No]: ContentId.Yes,
  };

export const AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_CID: Record<
  AutoPricingResultType,
  ContentId
> = {
  [AutoPricingResultType.None]: ContentId.NA,
  [AutoPricingResultType.PriceSuccess]: ContentId.Success,
  [AutoPricingResultType.PriceBelowFloor]: ContentId.PriceBelowFloor,
  [AutoPricingResultType.PriceAboveCeiling]: ContentId.PriceAboveCeiling,
  [AutoPricingResultType.PriceBelowMaxAllowedDrop]:
    ContentId.PriceBelowMaxAllowedDrop,
  [AutoPricingResultType.InsufficientComparables]:
    ContentId.InsufficientComparables,
  [AutoPricingResultType.CannotCalculateUndercut]:
    ContentId.CannotCalculateUndercut,
  [AutoPricingResultType.EffectiveCeilingBelowFloor]:
    ContentId.EffectiveCeilingBelowFloor,
  [AutoPricingResultType.MissingSeatScore]: ContentId.MissingSeatScore,
  [AutoPricingResultType.MissingUnitCost]: ContentId.MissingUnitCost,
  [AutoPricingResultType.MissingZone]: ContentId.MissingZone,
  [AutoPricingResultType.PriceHitHardFloor]: ContentId.PriceHitHardFloor,
  [AutoPricingResultType.PriceHitRelativeFloor]:
    ContentId.PriceHitRelativeFloor,
  [AutoPricingResultType.PriceHitMaxAllowedDrop]:
    ContentId.PriceHitMaxAllowedDrop,
  [AutoPricingResultType.ConfigRowMissing]: ContentId.MappingError,
  [AutoPricingResultType.SectionUnmapped]: ContentId.SectionUnmapped,
};

export const AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_COLOR: Record<
  AutoPricingResultType,
  string
> = {
  [AutoPricingResultType.None]: vars.color.textDisabled,
  [AutoPricingResultType.PriceSuccess]: vars.color.borderSuccess,
  [AutoPricingResultType.PriceBelowFloor]: vars.color.borderWarning,
  [AutoPricingResultType.PriceAboveCeiling]: vars.color.borderWarning,
  [AutoPricingResultType.PriceBelowMaxAllowedDrop]: vars.color.borderWarning,
  [AutoPricingResultType.InsufficientComparables]: vars.color.borderWarning,
  [AutoPricingResultType.CannotCalculateUndercut]: vars.color.borderError,
  [AutoPricingResultType.EffectiveCeilingBelowFloor]: vars.color.borderError,
  [AutoPricingResultType.MissingSeatScore]: vars.color.borderError,
  [AutoPricingResultType.MissingUnitCost]: vars.color.borderError,
  [AutoPricingResultType.MissingZone]: vars.color.borderError,
  [AutoPricingResultType.PriceHitHardFloor]: vars.color.borderWarning,
  [AutoPricingResultType.PriceHitRelativeFloor]: vars.color.borderWarning,
  [AutoPricingResultType.PriceHitMaxAllowedDrop]: vars.color.borderWarning,
  [AutoPricingResultType.ConfigRowMissing]: vars.color.borderError,
  [AutoPricingResultType.SectionUnmapped]: vars.color.borderError,
};

export const DELIVERY_OPTIONS_TO_CID: Record<DeliveryType, ContentId | string> =
  {
    [DeliveryType.InApp]: ContentId.InApp,
    [DeliveryType.PDF]: DeliveryType.PDF, // PDF has no translation
    [DeliveryType.Paper]: ContentId.Paper,
    [DeliveryType.MemberCard]: ContentId.MemberCard,
    [DeliveryType.Wallet]: ContentId.Wallet,
    [DeliveryType.Custom]: ContentId.Custom,
  };

export const TICKET_TYPE_TO_CID: Record<TicketType, ContentId> = {
  [TicketType.Barcode]: ContentId.Barcode,
  [TicketType.ETicket]: ContentId.ETicket,
  [TicketType.QRCode]: ContentId.QRCode,
  [TicketType.Transfer]: ContentId.Transfer,
  [TicketType.ETicketUrl]: ContentId.Wallet,
  [TicketType.SMPWallet]: ContentId.SMPWallet,
  [TicketType.Paper]: ContentId.Paper,
  [TicketType.Season]: ContentId.Season,
  [TicketType.Custom]: ContentId.Custom,
};

export const PENDING_PROOF_STATE_TO_CID: Record<PendingProofState, ContentId> =
  {
    [PendingProofState.PendingProof]: ContentId.ProofOfPurchasedRequired,
    [PendingProofState.PendingReview]: ContentId.PendingReview,
    [PendingProofState.Approved]: ContentId.Approved,
  };

export const PURCHASE_COST_TYPE_TO_CID: Record<
  PurchaseOrderCostType,
  ContentId
> = {
  [PurchaseOrderCostType.Tax]: ContentId.TaxPaid,
  [PurchaseOrderCostType.Shipping]: ContentId.Shipping,
  [PurchaseOrderCostType.CreditCardFee]: ContentId.CreditCardFee,
  [PurchaseOrderCostType.BulkDiscount]: ContentId.BulkDiscount,
};

export const SPLIT_TYPE_TO_CID: Record<SplitType, ContentId | string> = {
  [SplitType.Any]: ContentId.Any,
  [SplitType.None]: ContentId.None,
  [SplitType.AvoidOne]: ContentId.AvoidOne,
  [SplitType.AvoidOneAndThree]: ContentId.AvoidOneAndThree,
  [SplitType.Pairs]: ContentId.Pairs,
};

export const TAG_VALUE_TYPE_TO_CID: Record<string, ContentId> = {
  [TagsValueType.String]: ContentId.Text,
  [TagsValueType.Decimal]: ContentId.DecimalNumber,
  [TagsValueType.Int]: ContentId.WholeNumber,
  [TagsValueType.Boolean]: ContentId.TrueFalse,
  [TagsValueType.Url]: ContentId.Hyperlink,
  [TagsValueType.Marketplace]: ContentId.Marketplace,
  [TagsValueType.Date]: ContentId.Date,
  [TagsValueType.POSUser]: ContentId.Users,
  [TagsValueType.Vendor]: ContentId.Vendors,
  [TagsValueType.UserGroup]: ContentId.Teams,
};

export const PAYMENT_METHOD_TYPE_TO_CID: Record<
  PurchasePaymentMethodType,
  ContentId | string
> = {
  [PurchasePaymentMethodType.PayPal]: PurchasePaymentMethodType.PayPal, // this is a brand
  [PurchasePaymentMethodType.Check]: ContentId.Check,
  [PurchasePaymentMethodType.ECheck]: ContentId.ECheck,
  [PurchasePaymentMethodType.MoneyOrder]: ContentId.MoneyOrder,
  [PurchasePaymentMethodType.Card]: ContentId.CreditCard,
  [PurchasePaymentMethodType.Cash]: ContentId.Cash,
  [PurchasePaymentMethodType.Credit]: ContentId.Credit,
  [PurchasePaymentMethodType.COD]: ContentId.COD,
  [PurchasePaymentMethodType.BankTransfer]: ContentId.BankTransfer,
  [PurchasePaymentMethodType.DebitCard]: ContentId.DebitCard,
  [PurchasePaymentMethodType.CreditMemo]: ContentId.CreditBalance,
  [PurchasePaymentMethodType.DebitNote]: ContentId.DebitNote,
  [PurchasePaymentMethodType.Other]: ContentId.Other,
};

export const TICKET_GROUP_EDIT_REASON_TO_CID: Record<
  TicketGroupEditReason,
  ContentId
> = {
  [TicketGroupEditReason.AddTickets]: ContentId.AddTickets,
  [TicketGroupEditReason.AmendTickets]: ContentId.AmendTickets,
  [TicketGroupEditReason.CancelTickets]: ContentId.CancelTickets,
  [TicketGroupEditReason.ExchangeTickets]: ContentId.ExchangeTickets,
  [TicketGroupEditReason.EditEvent]: ContentId.ChangeEvent,
};

export const LISTING_STATUS_TO_CID: Record<ListingStatus, ContentId> = {
  [ListingStatus.Delisted]: ContentId.Delisted,
  [ListingStatus.DelistingPending]: ContentId.DelistingPending,
  [ListingStatus.DelistingFailed]: ContentId.ListedDelistFailed,
  [ListingStatus.Listed]: ContentId.Listed,
  [ListingStatus.ListingPending]: ContentId.ListingPending,
  [ListingStatus.ListingFailed]: ContentId.ListingFailed,
  [ListingStatus.FullySold]: ContentId.FullySold,
  [ListingStatus.Disabled]: ContentId.BroadcastDisabled,
};

export const FULFILLMENT_STATE_TO_CID: Record<
  PointOfSaleFulfillmentState,
  ContentId
> = {
  [PointOfSaleFulfillmentState.Ineligible]: ContentId.Ineligible,
  [PointOfSaleFulfillmentState.Pending]: ContentId.Pending,
  [PointOfSaleFulfillmentState.Processing]: ContentId.Processing,
  [PointOfSaleFulfillmentState.PendingPickup]: ContentId.PendingPickup,
  [PointOfSaleFulfillmentState.InTransit]: ContentId.InTransit,
  [PointOfSaleFulfillmentState.Fulfilled]: ContentId.Fulfilled,
  [PointOfSaleFulfillmentState.Resetted]: ContentId.PendingRefulfillment,
  [PointOfSaleFulfillmentState.Failed]: ContentId.Failed,
};

export const LISTING_STATUS_WITH_ACTION_TO_CID: Record<
  ListingStatus,
  ContentId
> = {
  [ListingStatus.Delisted]: ContentId.DelistedClickToList,
  [ListingStatus.DelistingPending]: ContentId.DelistingClickToCancel,
  [ListingStatus.DelistingFailed]: ContentId.DelistingFailedClickToTryAgain,
  [ListingStatus.Listed]: ContentId.ListedClickToDelist,
  [ListingStatus.ListingPending]: ContentId.ListingClickToCancel,
  [ListingStatus.ListingFailed]: ContentId.ListingFailedClickToTryAgain,
  [ListingStatus.FullySold]: ContentId.ListingFullSoldNoActionAvailable,
  [ListingStatus.Disabled]: ContentId.BroadcastDisabled,
};

export const LISTING_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID: Record<
  ListingTableFlattenedColumnId,
  ContentId
> = {
  [ListingTableFlattenedColumnId.Event]: ContentId.Event,
  [ListingTableFlattenedColumnId.Venue]: ContentId.Venue,
  [ListingTableFlattenedColumnId.Location]: ContentId.Location,
  [ListingTableFlattenedColumnId.EventDate]: ContentId.EventDate,
};

export const LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID: Record<
  ListingTableColumnId,
  ContentId | string
> = {
  [ListingTableColumnId.Checkbox]: ContentId.Checkbox,
  [ListingTableColumnId.TicketClassColor]: ContentId.Zone,
  [ListingTableColumnId.Seating]: ContentId.Seating,
  [ListingTableColumnId.DeliveryType]: ContentId.Delivery,
  [ListingTableColumnId.ShownQuantity]: ContentId.ShownQty,
  [ListingTableColumnId.UnsoldQuantity]: ContentId.UnsoldQty,
  [ListingTableColumnId.TicketCount]: ContentId.TotalQty,
  [ListingTableColumnId.InHandDate]: 'IH',
  [ListingTableColumnId.UnitCost]: ContentId.UnitCost,
  [ListingTableColumnId.ListPrice]: ContentId.Proceeds,
  [ListingTableColumnId.AllInPrice]: ContentId.Price,
  [ListingTableColumnId.Actions]: ContentId.Actions,
  [ListingTableColumnId.ListingId]: ContentId.ListingId,
  [ListingTableColumnId.AutoPricing]: ContentId.PriceToMarket,
  [ListingTableColumnId.ProceedsFloor]: ContentId.ProceedsFloor,
  [ListingTableColumnId.ProceedsCeiling]: ContentId.ProceedsCeiling,
  [ListingTableColumnId.WebsiteFloor]: ContentId.WebsiteFloor,
  [ListingTableColumnId.WebsiteCeiling]: ContentId.WebsiteCeiling,
  [ListingTableColumnId.UndercutMode]: ContentId.UndercutMode,
  [ListingTableColumnId.UndercutAbsoluteAmount]:
    ContentId.UndercutAbsoluteAmount,
  [ListingTableColumnId.UndercutRelativeAmount]:
    ContentId.UndercutRelativeAmount,
  [ListingTableColumnId.UndercutNewPrice]: ContentId.MarketPrice,
  [ListingTableColumnId.CompListingMode]: ContentId.CompListingMode,
  [ListingTableColumnId.CompListingFloor]: ContentId.CompListingFloor,
  [ListingTableColumnId.CompListingCeiling]: ContentId.CompListingCeiling,
  [ListingTableColumnId.CompListingMinimumQuantity]:
    ContentId.CompListingMinQuantity,
  [ListingTableColumnId.CompListingExcludeFanInventory]:
    ContentId.ExcludeFanInventory,
  [ListingTableColumnId.OutlierMode]: ContentId.OutlierMode,
  [ListingTableColumnId.OutlierLimit]: ContentId.OutlierLimit,
  [ListingTableColumnId.OutlierFiltering]: ContentId.OutlierFiltering,
  [ListingTableColumnId.OutlierSpacing]: ContentId.OutlierSpacing,
  [ListingTableColumnId.CircuitBreakerMinCompListingCount]:
    ContentId.CompListingMinCount,
  [ListingTableColumnId.CircuitBreakerVelocity]: ContentId.Velocity,
  [ListingTableColumnId.CircuitBreakerVelocityHours]: ContentId.VelocityHours,
  [ListingTableColumnId.SeatingRow]: ContentId.Row,
  [ListingTableColumnId.SeatingSeats]: ContentId.Seats,
  [ListingTableColumnId.AutoPricingResultType]: ContentId.AutoPricingResult,
  [ListingTableColumnId.LastPriceUpdatedDate]: ContentId.LastPriceUpdatedDate,
  [ListingTableColumnId.WastedCost]: ContentId.WastedCost,
  [ListingTableColumnId.WastedTickets]: ContentId.WastedQty,
  [ListingTableColumnId.PurchaseId]: ContentId.ExternalPurchaseIdAbbreviated,
  [ListingTableColumnId.VendorAccount]: ContentId.VendorAccount,
  [ListingTableColumnId.MarketplaceVisibility]: ContentId.MarketplaceVisibility,
  [ListingTableColumnId.PurchaseCreatedBy]: ContentId.CreatedBy,
  [ListingTableColumnId.PurchasedBy]: ContentId.PurchasedBy,
  [ListingTableColumnId.PurchaseDate]: ContentId.PoDate,
  [ListingTableColumnId.RelativeRankPremium]: ContentId.RelativeRankPremium,
  [ListingTableColumnId.AbsoluteRankPremium]: ContentId.AbsoluteRankPremium,
  [ListingTableColumnId.UnsoldProceeds]: ContentId.UnsoldProceeds,
  [ListingTableColumnId.UnsoldCost]: ContentId.UnsoldCost,
  [ListingTableColumnId.SoldQty]: ContentId.SoldQty,
  [ListingTableColumnId.SoldCost]: ContentId.SoldCost,
  [ListingTableColumnId.TotalCost]: ContentId.TotalCost,
  [ListingTableColumnId.AutoFulfill]: ContentId.AutoFulfill,
};

export const LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID: Record<
  ListingReportTableColumnId,
  ContentId | string
> = {
  [ListingReportTableColumnId.Performer]: ContentId.Performer,
  [ListingReportTableColumnId.Venue]: ContentId.Venue,
  [ListingReportTableColumnId.Event]: ContentId.EventName,
  [ListingReportTableColumnId.UnsoldQuantity]: ContentId.UnsoldQty,
  [ListingReportTableColumnId.SoldQuantity]: ContentId.SoldQty,
  [ListingReportTableColumnId.TicketCount]: ContentId.TotalQty,
  [ListingReportTableColumnId.UnitCost]: ContentId.TotalUnitCost,
  [ListingReportTableColumnId.ListPrice]: ContentId.TotalProceeds,
  [ListingReportTableColumnId.AllInPrice]: ContentId.Price,
  [ListingReportTableColumnId.EventGenre]: ContentId.Genre,
  [ListingReportTableColumnId.EventCountry]: ContentId.Country,
  [ListingReportTableColumnId.EventCity]: ContentId.City,
  [ListingReportTableColumnId.SeatingSection]: ContentId.Section,
  [ListingReportTableColumnId.SeatingRow]: ContentId.Row,
  [ListingReportTableColumnId.SoldCost]: ContentId.SoldCost,
  [ListingReportTableColumnId.SoldProceeds]: ContentId.SoldProceeds,
  [ListingReportTableColumnId.UnsoldCost]: ContentId.UnsoldCost,
  [ListingReportTableColumnId.EventId]: ContentId.EventId,
  [ListingReportTableColumnId.Category]: ContentId.Category,
  [ListingReportTableColumnId.EventDate]: ContentId.EventDate,
  [ListingReportTableColumnId.EventStateProvince]: ContentId.StateProvince,
  [ListingReportTableColumnId.SaleId]: ContentId.SaleId,
  [ListingReportTableColumnId.HoldId]: ContentId.HoldId,
  [ListingReportTableColumnId.SeatingSeats]: ContentId.Seats,
  [ListingReportTableColumnId.DeliveryType]: ContentId.Delivery,
  [ListingReportTableColumnId.InHandDate]: 'IH',
  [ListingReportTableColumnId.PurchaseId]: ContentId.PoId,
  [ListingReportTableColumnId.PurchasedBy]: ContentId.PurchasedBy,
  [ListingReportTableColumnId.PurchaseDate]: ContentId.PoDate,
  [ListingReportTableColumnId.Vendor]: ContentId.Vendor,
  [ListingReportTableColumnId.SalePaymentState]: ContentId.PayState,
  [ListingReportTableColumnId.Charges]: ContentId.Charges,
  [ListingReportTableColumnId.Credits]: ContentId.Credits,
  [ListingReportTableColumnId.Fulfiller]: ContentId.FulfilledBy,
  [ListingReportTableColumnId.ListingId]: ContentId.ListingId,
  [ListingReportTableColumnId.PaymentMethodType]: ContentId.PaymentMethod,
  [ListingReportTableColumnId.PurchasePaymentStatus]: ContentId.POPaymentState,
  [ListingReportTableColumnId.Zone]: ContentId.Zone,
  [ListingReportTableColumnId.PricedBy]: ContentId.PricedBy,
  [ListingReportTableColumnId.InternalNotes]: ContentId.Notes,
  [ListingReportTableColumnId.InternalNotesPurchase]: ContentId.Notes,
  [ListingReportTableColumnId.VendorOrderId]:
    ContentId.ExternalPurchaseIdAbbreviated,
  [ListingReportTableColumnId.VendorAccountEmail]: ContentId.VendorAccount,
  [ListingReportTableColumnId.CancellationDatePurchase]:
    ContentId.CancellationDate,
  [ListingReportTableColumnId.PandL]: ContentId.PandL,
  [ListingReportTableColumnId.Margin]: ContentId.Margin,
  [ListingReportTableColumnId.PurchaseCreatedBy]: ContentId.POCreatedBy,
};

export const SITE_THEME_OPTION_TO_CID_MAP: Record<SiteThemeOption, ContentId> =
  {
    [SiteThemeOption.System]: ContentId.SyncWithSystemSettings,
    [SiteThemeOption.Light]: ContentId.Light,
    [SiteThemeOption.Dark]: ContentId.Dark,
  };

export const EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID: Record<
  EventsTableColumnId,
  ContentId
> = {
  [EventsTableColumnId.Checkbox]: ContentId.Checkbox,
  [EventsTableColumnId.Event]: ContentId.EventName,
  [EventsTableColumnId.Venue]: ContentId.Venue,
  [EventsTableColumnId.Location]: ContentId.Location,
  [EventsTableColumnId.Date]: ContentId.EventDate,
  [EventsTableColumnId.TotalQty]: ContentId.TotalQty,
  [EventsTableColumnId.SoldQty]: ContentId.SoldQty,
  [EventsTableColumnId.ActualSTR]: ContentId.SellThroughRate,
  [EventsTableColumnId.Revenue]: ContentId.SoldProceeds,
  [EventsTableColumnId.SoldCost]: ContentId.SoldCost,
  [EventsTableColumnId.Profit]: ContentId.SoldProfit,
  [EventsTableColumnId.LiftPercentage]: ContentId.LiftPercentage,
  [EventsTableColumnId.ATP]: ContentId.SoldAverageTicketPrice,
  [EventsTableColumnId.ListedQty]: ContentId.ListedQty,
  [EventsTableColumnId.ListedAtp]: ContentId.ListedAverageTicketPrice,
  [EventsTableColumnId.UnsoldQty]: ContentId.UnsoldQty,
  [EventsTableColumnId.Action]: ContentId.Actions,
  [EventsTableColumnId.UnsoldProceeds]: ContentId.UnsoldProceeds,
  [EventsTableColumnId.UnsoldCost]: ContentId.UnsoldCost,
  [EventsTableColumnId.TotalCost]: ContentId.TotalCost,
  [EventsTableColumnId.LastPriceUpdatedDate]: ContentId.LastPriceUpdatedDate,
};

export const SALE_TABLE_COLUMN_ID_TO_CONTENT_ID: Record<
  SalesTableColumnId,
  ContentId
> = {
  [SalesTableColumnId.Checkbox]: ContentId.Checkbox,
  [SalesTableColumnId.Actions]: ContentId.Actions,
  [SalesTableColumnId.InHandDate]: ContentId.InHandDate,
  [SalesTableColumnId.OrderDate]: ContentId.SaleDate,
  [SalesTableColumnId.Proceeds]: ContentId.SoldProceeds,
  [SalesTableColumnId.QuantitySold]: ContentId.SoldQty,
  [SalesTableColumnId.Sale]: ContentId.Sale,
  [SalesTableColumnId.Seating]: ContentId.Seating,
  [SalesTableColumnId.Row]: ContentId.Row,
  [SalesTableColumnId.Seats]: ContentId.Seats,
  [SalesTableColumnId.Status]: ContentId.SaleStatus,
  [SalesTableColumnId.TicketType]: ContentId.Ticket,
  [SalesTableColumnId.SoldTicketCost]: ContentId.SoldCost,
  [SalesTableColumnId.Charges]: ContentId.Charges,
  [SalesTableColumnId.Credits]: ContentId.Credits,
  [SalesTableColumnId.PandL]: ContentId.PandL,
  [SalesTableColumnId.Margin]: ContentId.Margin,
  [SalesTableColumnId.PurchaseCreatedBy]: ContentId.POCreatedBy,
  [SalesTableColumnId.PurchasedBy]: ContentId.PurchasedBy,
  [SalesTableColumnId.PurchaseDate]: ContentId.PoDate,
  [SalesTableColumnId.PaymentState]: ContentId.PayState,
  [SalesTableColumnId.PaymentReceived]: ContentId.PayRec,
  [SalesTableColumnId.PaymentReferenceId]: ContentId.PayRef,
  [SalesTableColumnId.FulfillmentDate]: ContentId.FulfillmentDate,
  [SalesTableColumnId.AutoFulfill]: ContentId.AutoFulfill,
  [SalesTableColumnId.VendorOrderId]: ContentId.ExternalPurchaseIdAbbreviated,
  [SalesTableColumnId.VendorAccountEmail]: ContentId.VendorAccount,
  [SalesTableColumnId.OriginatedBy]: ContentId.OriginatedBy,
  [SalesTableColumnId.TotalPaymentsAmount]: ContentId.PaymentsAmount,
  [SalesTableColumnId.TotalConvertedPaymentsAmount]:
    ContentId.ConvertedPaymentsAmount,
};

export const SALE_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID: Record<
  SalesTableFlattenedColumnId,
  ContentId
> = {
  [SalesTableFlattenedColumnId.Event]: ContentId.Event,
  [SalesTableFlattenedColumnId.Venue]: ContentId.Venue,
  [SalesTableFlattenedColumnId.Location]: ContentId.Location,
  [SalesTableFlattenedColumnId.EventDate]: ContentId.EventDate,
};

export const SALE_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID: Record<
  SalesReportTableColumnId,
  ContentId
> = {
  [SalesReportTableColumnId.Performer]: ContentId.Performer,
  [SalesReportTableColumnId.Venue]: ContentId.Venue,
  [SalesReportTableColumnId.Event]: ContentId.EventName,
  [SalesReportTableColumnId.QuantitySold]: ContentId.SoldQty,
  [SalesReportTableColumnId.Proceeds]: ContentId.SoldProceeds,
  [SalesReportTableColumnId.EventGenre]: ContentId.Genre,
  [SalesReportTableColumnId.EventCountry]: ContentId.Country,
  [SalesReportTableColumnId.EventCity]: ContentId.City,
  [SalesReportTableColumnId.SeatingSection]: ContentId.Section,
  [SalesReportTableColumnId.SeatingRow]: ContentId.Row,
  [SalesReportTableColumnId.SaleMarketplace]: ContentId.Marketplace,
  [SalesReportTableColumnId.SaleMonth]: ContentId.Month,
  [SalesReportTableColumnId.SaleWeek]: ContentId.WeekOfYear,
  [SalesReportTableColumnId.SaleDay]: ContentId.Day,
  [SalesReportTableColumnId.SoldTicketCost]: ContentId.SoldCost,
  [SalesReportTableColumnId.Charges]: ContentId.Charges,
  [SalesReportTableColumnId.Credits]: ContentId.Credits,
  [SalesReportTableColumnId.EventId]: ContentId.EventId,
  [SalesReportTableColumnId.Category]: ContentId.Category,
  [SalesReportTableColumnId.EventDate]: ContentId.EventDate,
  [SalesReportTableColumnId.EventStateProvince]: ContentId.StateProvince,
  [SalesReportTableColumnId.SaleId]: ContentId.SaleId,
  [SalesReportTableColumnId.MarketplaceSaleId]: ContentId.ExternalSaleId,
  [SalesReportTableColumnId.Status]: ContentId.SaleStatus,
  [SalesReportTableColumnId.TicketType]: ContentId.Ticket,
  [SalesReportTableColumnId.InHandDate]: ContentId.InHandDate,
  [SalesReportTableColumnId.PaymentState]: ContentId.PayState,
  [SalesReportTableColumnId.PaymentReceived]: ContentId.PayRec,
  [SalesReportTableColumnId.PaymentReferenceId]: ContentId.PayRef,
  [SalesReportTableColumnId.Fulfiller]: ContentId.FulfilledBy,
  [SalesReportTableColumnId.PurchaseId]: ContentId.PoId,
  [SalesReportTableColumnId.PurchasedBy]: ContentId.PurchasedBy,
  [SalesReportTableColumnId.Vendor]: ContentId.Vendor,
  [SalesReportTableColumnId.SeatingSeats]: ContentId.Seats,
  [SalesReportTableColumnId.OrderDate]: ContentId.SaleDate,
  [SalesReportTableColumnId.PricedBy]: ContentId.PricedBy,
  [SalesReportTableColumnId.CurrencyCode]: ContentId.Currency,
  [SalesReportTableColumnId.PaymentDate]: ContentId.PaymentDate,
  [SalesReportTableColumnId.FulfillmentDate]: ContentId.FulfillmentDate,
  [SalesReportTableColumnId.VendorAccountEmail]: ContentId.VendorAccount,
  [SalesReportTableColumnId.InternalNotes]: ContentId.Notes,
  [SalesReportTableColumnId.CancellationDate]: ContentId.CancellationDate,
  [SalesReportTableColumnId.OutstandingBalance]: ContentId.OutstandingBalance,
  [SalesReportTableColumnId.PandL]: ContentId.PandL,
  [SalesReportTableColumnId.Margin]: ContentId.Margin,
  [SalesReportTableColumnId.PurchaseCreatedBy]: ContentId.POCreatedBy,
  [SalesReportTableColumnId.PurchaseDate]: ContentId.PoDate,
  [SalesReportTableColumnId.TicketId]: ContentId.TicketId,
  [SalesReportTableColumnId.TicketGroupId]: ContentId.TicketGroupId,
  [SalesReportTableColumnId.CommissionUser]: ContentId.Commissioner,
  [SalesReportTableColumnId.CommissionPercent]: ContentId.Commission,
  [SalesReportTableColumnId.CommissionShare]: ContentId.Share,
  [SalesReportTableColumnId.MarketplacePaymentTerms]:
    ContentId.MarketplacePaymentTerms,
};

export const ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID: Record<
  AdGroupsTableColumnId,
  ContentId
> = {
  [AdGroupsTableColumnId.Name]: ContentId.Name,
  [AdGroupsTableColumnId.State]: ContentId.State,
  [AdGroupsTableColumnId.BaseBid]: ContentId.BaseBid,
  [AdGroupsTableColumnId.MaxBid]: ContentId.MaxBid,
  [AdGroupsTableColumnId.DailyBudget]: ContentId.DailyBudget,
  [AdGroupsTableColumnId.Genre]: ContentId.Genre,
  [AdGroupsTableColumnId.Performer]: ContentId.Performer,
  [AdGroupsTableColumnId.Venue]: ContentId.Venue,
  [AdGroupsTableColumnId.Section]: ContentId.Section,
  [AdGroupsTableColumnId.TicketClass]: ContentId.TicketClass,
  [AdGroupsTableColumnId.Listing]: ContentId.Listing,
  [AdGroupsTableColumnId.Event]: ContentId.Event,
  [AdGroupsTableColumnId.BidModifier]: ContentId.BidModifier,
  [AdGroupsTableColumnId.AdSpend]: ContentId.AdSpend,
  [AdGroupsTableColumnId.SoldProceeds]: ContentId.SoldProceeds,
  [AdGroupsTableColumnId.SoldQty]: ContentId.SoldQty,
  [AdGroupsTableColumnId.Actions]: ContentId.Actions,
};

export const ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID: Record<
  AdCampaignsTableColumnId,
  ContentId
> = {
  [AdCampaignsTableColumnId.Name]: ContentId.Name,
  [AdCampaignsTableColumnId.State]: ContentId.State,
  [AdCampaignsTableColumnId.Budget]: ContentId.Budget,
  [AdCampaignsTableColumnId.StartDateTime]: ContentId.StartDateTime,
  [AdCampaignsTableColumnId.EndDateTime]: ContentId.EndDateTime,
  [AdCampaignsTableColumnId.AdSpend]: ContentId.AdSpend,
  [AdCampaignsTableColumnId.SoldProceeds]: ContentId.SoldProceeds,
  [AdCampaignsTableColumnId.SoldQty]: ContentId.SoldQty,
  [AdCampaignsTableColumnId.Actions]: ContentId.Actions,
};

export const PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID: Record<
  PurchasesTableColumnId | PurchaseTableColumnGroupId,
  ContentId | string
> = {
  [PurchasesTableColumnId.BuyerUserId]: ContentId.PurchasedBy,
  [PurchasesTableColumnId.Checkbox]: ContentId.Checkbox,
  [PurchasesTableColumnId.AccordianToggle]: ContentId.AccordianToggle,
  [PurchasesTableColumnId.Id]: ContentId.Id,
  [PurchasesTableColumnId.Payment]: ContentId.PmtMethod,
  [PurchasesTableColumnId.PaymentStatus]: ContentId.PmtState,
  [PurchasesTableColumnId.PurchaseDate]: ContentId.PoDate,
  [PurchasesTableColumnId.QualityControlState]: 'QC',
  [PurchasesTableColumnId.TotalCost]: ContentId.TotalCost,
  [PurchasesTableColumnId.UnitCost]: ContentId.UnitCost,
  [PurchasesTableColumnId.VendorOrderId]:
    ContentId.ExternalPurchaseIdAbbreviated,
  [PurchasesTableColumnId.Tickets]: ContentId.QuantityAbbreviated,
  [PurchasesTableColumnId.Orders]: ContentId.TicketGroups,
  [PurchasesTableColumnId.Shipment]: ContentId.Delivery,
  [PurchasesTableColumnId.Cost]: ContentId.TotalCost,
  [PurchasesTableColumnId.Actions]: ContentId.Actions,
  [PurchasesTableColumnId.VendorAccountEmail]: ContentId.VendorAccount,
  [PurchasesTableColumnId.CreatedBy]: ContentId.CreatedBy,
  [PurchasesTableColumnId.Event]: ContentId.Event,
  [PurchasesTableColumnId.Venue]: ContentId.Venue,
  [PurchasesTableColumnId.EventDate]: ContentId.EventDate,
  [PurchasesTableColumnId.Seating]: ContentId.Seating,
  [PurchasesTableColumnId.Row]: ContentId.Row,
  [PurchasesTableColumnId.Seats]: ContentId.Seats,
  [PurchaseTableColumnGroupId.TicketGroup]: ContentId.TicketGroup,
  [PurchasesTableColumnId.TotalPayment]: ContentId.PmtAmount,
  [PurchasesTableColumnId.TotalConvertedPayment]: ContentId.ConvPmtAmt,
  [PurchasesTableColumnId.PaymentConversionRate]: ContentId.FXRate,
  [PurchasesTableColumnId.Commissions]: ContentId.Commissions,
};

export const PAYMENTS_TABLE_COLUMN_ID_TO_CONTENT_ID: Record<
  MarketplacePaymentsTableColumnId,
  ContentId | string
> = {
  [MarketplacePaymentsTableColumnId.PaymentReferenceID]:
    ContentId.PaymentReferenceID,
  [MarketplacePaymentsTableColumnId.PaymentAmount]: ContentId.PaymentAmount,
  [MarketplacePaymentsTableColumnId.PaymentDate]: ContentId.PaymentDate,
  [MarketplacePaymentsTableColumnId.State]: ContentId.State,
  [MarketplacePaymentsTableColumnId.CreditAmount]: ContentId.CreditAmount,
  [MarketplacePaymentsTableColumnId.ProceedsAmount]: ContentId.ProceedsAmount,
  [MarketplacePaymentsTableColumnId.ChargeAmount]: ContentId.ChargeAmount,
  [MarketplacePaymentsTableColumnId.Marketplace]: ContentId.Marketplace,
  [MarketplacePaymentsTableColumnId.ConvertedPaymentsAmount]:
    ContentId.ConvertedPaymentsAmount,
  [MarketplacePaymentsTableColumnId.Actions]: ContentId.Actions,
};

export type ListingFormulaFieldsListing =
  | ListingFormulaFields.availableQuantity
  | ListingFormulaFields.ticketCount
  | ListingFormulaFields.unitCost
  | ListingFormulaFields.listPrice
  | ListingFormulaFields.allInPrice
  | ListingFormulaFields.netProceedsFloor
  | ListingFormulaFields.netProceedsCeiling
  | ListingFormulaFields.websiteFloor
  | ListingFormulaFields.websiteCeiling
  | ListingFormulaFields.undercutAbsoluteAmount
  | ListingFormulaFields.undercutRelativeAmount
  | ListingFormulaFields.compListingFloor
  | ListingFormulaFields.compListingCeiling;

export const LISTING_FORMULA_FIELDS_TO_CID: Record<
  ListingFormulaFieldsListing,
  ContentId
> = {
  ['availableQuantity']: ContentId.Remaining,
  ['ticketCount']: ContentId.Original,
  ['unitCost']: ContentId.UnitCost,
  ['listPrice']: ContentId.Proceeds,
  ['allInPrice']: ContentId.Price,
  ['netProceedsFloor']: ContentId.ProceedsFloor,
  ['netProceedsCeiling']: ContentId.ProceedsCeiling,
  ['websiteFloor']: ContentId.Price,
  ['websiteCeiling']: ContentId.WebsiteCeiling,
  ['undercutAbsoluteAmount']: ContentId.UndercutAbsoluteAmount,
  ['undercutRelativeAmount']: ContentId.UndercutRelativeAmount,
  ['compListingFloor']: ContentId.CompListingFloor,
  ['compListingCeiling']: ContentId.CompListingCeiling,
};

export type ListingFormulaFieldsListingReportMetrics =
  | ListingFormulaFields.availableQuantity
  | ListingFormulaFields.ticketCount
  | ListingFormulaFields.unitCost
  | ListingFormulaFields.listPrice
  | ListingFormulaFields.allInPrice
  | ListingFormulaFields.soldQuantity
  | ListingFormulaFields.soldCost
  | ListingFormulaFields.unsoldCost
  | ListingFormulaFields.soldProceeds;

export const LISTING_REPORT_FORMULA_FIELDS_TO_CID: Record<
  ListingFormulaFieldsListingReportMetrics,
  ContentId
> = {
  ['availableQuantity']: ContentId.UnsoldQty,
  ['soldQuantity']: ContentId.SoldQty,
  ['ticketCount']: ContentId.TotalQty,
  ['soldProceeds']: ContentId.SoldProceeds,
  ['listPrice']: ContentId.TotalProceeds,
  ['allInPrice']: ContentId.Price,
  ['soldCost']: ContentId.SoldCost,
  ['unsoldCost']: ContentId.UnsoldCost,
  ['unitCost']: ContentId.TotalCost,
};

export const SALE_FORMULA_FIELDS_TO_CID: Record<
  keyof SaleFormulaFields,
  ContentId
> = {
  ['quantitySold']: ContentId.SoldQty,
  ['totalNetProceeds']: ContentId.SoldProceeds,
  ['charges']: ContentId.Charges,
  ['credits']: ContentId.Credits,
  ['soldTicketCost']: ContentId.SoldCost,
  ['pandL']: ContentId.PandL,
  ['margin']: ContentId.Margin,
};

export const EVENTS_FORMULA_FIELDS_TO_CID: Record<string, ContentId> = {
  totalListedQuantity: ContentId.ListedQty,
  totalSoldQuantity: ContentId.SoldQty,
  totalUnsoldQuantity: ContentId.UnsoldQty,
  totalTicketQuantity: ContentId.TotalQty,
  listedAtp: ContentId.ListedAverageTicketPrice,
  totalAtp: ContentId.SoldAverageTicketPrice,
  revenue: ContentId.SoldProceeds,
  totalSoldCost: ContentId.SoldCost,
  totalGrossProfit: ContentId.SoldProfit,
  actualStr: ContentId.SellThroughRate,
  liftPercentage: ContentId.LiftPercentage,
};

export const LISTING_GROUPING_TO_CID: Record<GroupingType, ContentId> = {
  [GroupingType.Custom]: ContentId.Custom,
  [GroupingType.Section]: ContentId.Section,
  [GroupingType.Zone]: ContentId.Zone,
  [GroupingType.Row]: ContentId.Row,
  [GroupingType.SeatScore]: ContentId.SeatScore,
  [GroupingType.Quantity]: ContentId.UnsoldQuantity,
  [GroupingType.UnitCost]: ContentId.UnitCost,
  [GroupingType.None]: ContentId.None,
};

export const BUCKET_OPTIONS_TO_CID: Record<BucketType, ContentId> = {
  [BucketType.None]: ContentId.None,
  [BucketType.Clusters]: ContentId.Clusters,
  [BucketType.SameSize]: ContentId.SameSize,
};

export const NOTIFICATION_TYPE_TO_CID: Record<
  NotificationType,
  ContentId | FormatContentId
> = {
  [NotificationType.SaleNew]: ContentId.NotificationTypeSaleNew,
  [NotificationType.SaleFulfilmentFailed]:
    ContentId.NotificationTypeSaleFulfilmentFailed,
  [NotificationType.SaleMarketplaceCancelled]:
    ContentId.NotificationTypeSaleMarketplaceCancelled,
  [NotificationType.ListingHideSeatsFailed]:
    ContentId.NotificationTypeListingHideSeatsFailed,
  [NotificationType.ListingUpdateAllInPriceFailed]:
    FormatContentId.NotificationTypeListingUpdateAllInPriceFailed,
  [NotificationType.ListingUpdateInHandDateFailed]:
    FormatContentId.NotificationTypeListingUpdateInHandDateFailed,
  [NotificationType.ListingUpdateSplitTypeFailed]:
    FormatContentId.NotificationTypeListingUpdateSplitTypeFailed,
  [NotificationType.ListingUpdateDeliveryTypeFailed]:
    ContentId.NotificationTypeListingUpdateDeliveryTypeFailed,
  [NotificationType.ListingUpdateUnitNetProceedsFailed]:
    FormatContentId.NotificationTypeListingUpdateUnitNetProceedsFailed,
  [NotificationType.ListingBroadcastFailed]:
    FormatContentId.NotificationTypeListingBroadcastFailed,
  [NotificationType.ListingUnbroadcastFailed]:
    FormatContentId.NotificationTypeListingUnbroadcastFailed,
  [NotificationType.ListingMergeFailed]:
    ContentId.NotificationTypeListingMergeFailed,
  [NotificationType.ListingSplitFailed]:
    ContentId.NotificationTypeListingSplitFailed,
  [NotificationType.ListingPriceUpdate]:
    ContentId.NotificationTypeListingPriceUpdate,
  [NotificationType.AutopriceBelowFloor]:
    ContentId.NotificationTypeAutopriceBelowFloor,
  [NotificationType.AutopriceInsufficientCompListings]:
    ContentId.NotificationTypeAutopriceInsufficientCompListings,
  [NotificationType.AutopriceFirebrake]:
    ContentId.NotificationTypeAutopriceFirebrake,
  [NotificationType.SellerSupportCaseNewReply]:
    FormatContentId.NotificationTypeSellerSupportCaseNewReply,
};

export const ACTION_OUTBOX_ENTITY_TYPE_TO_CID: Record<
  ActionOutboxEntityType,
  ContentId
> = {
  [ActionOutboxEntityType.Listing]: ContentId.Listing,
  [ActionOutboxEntityType.Sale]: ContentId.Sale,
  [ActionOutboxEntityType.Purchase]: ContentId.Purchase,
  [ActionOutboxEntityType.SellerSupportCase]: ContentId.SellerSupportCase,
  [ActionOutboxEntityType.Document]: ContentId.Document,
  [ActionOutboxEntityType.SellerEvent]: ContentId.Event,
  [ActionOutboxEntityType.InboundEmail]: ContentId.Email,
  [ActionOutboxEntityType.TicketGroup]: ContentId.TicketGroup,
};

export const ACTIVITY_LOG_STATE_TO_CID: Record<
  MarketplaceOperationResultState,
  ContentId
> = {
  [MarketplaceOperationResultState.Succeeded]: ContentId.Succeeded,
  [MarketplaceOperationResultState.FailedAndRequiresSellerAction]:
    ContentId.FailedAndRequiresSellerAction,
  [MarketplaceOperationResultState.FailedWithNonRetryableError]:
    ContentId.FailedWithNonRetryableError,
  [MarketplaceOperationResultState.FailedWithRetryableError]:
    ContentId.FailedWithRetryableError,
  [MarketplaceOperationResultState.Skipped]: ContentId.Skipped,
  [MarketplaceOperationResultState.NotImplemented]: ContentId.NotImplemented,
};

export const UNDERCUT_MODE_TO_CID: Record<AutoPricingUndercutMode, ContentId> =
  {
    [AutoPricingUndercutMode.Simple]: ContentId.Simple,
    [AutoPricingUndercutMode.RowExtrapolation]: ContentId.RowExtrapolation,
    [AutoPricingUndercutMode.Deal]: ContentId.Deal,
  };

export const UNDERCUT_MODE_TO_FLUENT_CID: Record<
  AutoPricingUndercutMode,
  ContentId
> = {
  [AutoPricingUndercutMode.Simple]: ContentId.Cheapest,
  [AutoPricingUndercutMode.RowExtrapolation]: ContentId.BestRowExtrapolation,
  [AutoPricingUndercutMode.Deal]: ContentId.BestDealScore,
};

export const OUTLIER_MODE_TO_CID: Record<AutoPricingOutlierMode, ContentId> = {
  [AutoPricingOutlierMode.StandardDeviations]: ContentId.StandardDeviations,
  [AutoPricingOutlierMode.KthLowest]: ContentId.Spacing,
  [AutoPricingOutlierMode.SkipLogic]: ContentId.SkipLogic,
};

export const COMP_LISTING_MODE_TO_CID: Record<
  AutoPricingCompListingMode,
  ContentId
> = {
  [AutoPricingCompListingMode.QualityScore]: ContentId.VenueWideQualityScore,
  [AutoPricingCompListingMode.SameSection]:
    ContentId.VenueWideInSectionPriority,
  [AutoPricingCompListingMode.SameZone]: ContentId.Zones,
  [AutoPricingCompListingMode.SameEvent]: ContentId.Events,
  [AutoPricingCompListingMode.ParentListing]: ContentId.ParentListing,
};

export const COMP_LISTING_MODE_TO_FLUENT_CID: Record<
  AutoPricingCompListingMode,
  ContentId
> = {
  [AutoPricingCompListingMode.QualityScore]: ContentId.OfSimilarQuality,
  [AutoPricingCompListingMode.SameSection]: ContentId.InSection,
  [AutoPricingCompListingMode.SameZone]: ContentId.InZone,
  [AutoPricingCompListingMode.SameEvent]: ContentId.ForTheEvent,
  [AutoPricingCompListingMode.ParentListing]: ContentId.InMyGroup,
};

export const AUTO_PO_INBOUND_EMAIL_CLASSIFICATION_TYPE_TO_CID: Record<
  AutoPoInboundEmailClassificationType,
  ContentId
> = {
  [AutoPoInboundEmailClassificationType.NewPurchaseOrder]: ContentId.NewOrder,
  [AutoPoInboundEmailClassificationType.ExistingPurchaseOrder]:
    ContentId.ExistingOrder,
  [AutoPoInboundEmailClassificationType.Junk]: ContentId.Junk,
  [AutoPoInboundEmailClassificationType.OneTimePass]: ContentId.OneTimePass,
  [AutoPoInboundEmailClassificationType.Password]: ContentId.Password,
  [AutoPoInboundEmailClassificationType.TicketsAvailable]:
    ContentId.TicketsAvailable,
  [AutoPoInboundEmailClassificationType.PurchaseCancelled]:
    ContentId.PurchaseCancelled,
  [AutoPoInboundEmailClassificationType.EventCancelled]:
    ContentId.EventCancelled,
  [AutoPoInboundEmailClassificationType.EventPostponed]:
    ContentId.EventPostponed,
  [AutoPoInboundEmailClassificationType.EnjoyTheEvent]: ContentId.EnjoyTheEvent,
  [AutoPoInboundEmailClassificationType.TransferSent]: ContentId.TransferSent,
  [AutoPoInboundEmailClassificationType.TransferAccepted]:
    ContentId.TransferAccepted,
  [AutoPoInboundEmailClassificationType.TransferExpired]:
    ContentId.TransferExpired,
  [AutoPoInboundEmailClassificationType.NewSale]: ContentId.NewSale,
  [AutoPoInboundEmailClassificationType.ConfirmationDeadline]:
    ContentId.ConfirmationDeadline,
  [AutoPoInboundEmailClassificationType.ShippingDeadline]:
    ContentId.ShippingDeadline,
  [AutoPoInboundEmailClassificationType.SaleCancelled]: ContentId.SaleCancelled,
  [AutoPoInboundEmailClassificationType.OtherMarketplaceContact]:
    ContentId.OtherMarketplaceContact,
};

export const STANDARD_DEVIATIONS_TO_CID: Record<string, ContentId> = {
  '1': ContentId.VeryAggressive,
  '2': ContentId.AggressiveOption,
  '3': ContentId.NormalOption,
  '4': ContentId.InclusiveOption,
};

export const REPORT_TYPES_TO_CID: Record<ReportTypes, ContentId> = {
  [ReportTypes.Inventory]: ContentId.Inventory,
  [ReportTypes.Sale]: ContentId.Sales,
};

export const INVENTORY_REPORT_WIDGETS_TO_CID: Record<
  ReportWidgetTypesInventory,
  ContentId
> = {
  [ReportWidgetTypesInventory.AverageTicketPrice]:
    ContentId.SoldAverageTicketPrice,
  [ReportWidgetTypesInventory.SalesAndMargin]: ContentId.SalesAndMargin,
  [ReportWidgetTypesInventory.TicketsListedAndSold]:
    ContentId.TicketsListedAndSold,
};

export const REPORT_INVENTORY_GROUP_BY_TO_CID: Record<string, ContentId> = {
  [ReportGroupBy.ListingId]: ContentId.Listing,
  [ReportGroupBy.PurchaseId]: ContentId.Purchase,
  [ReportGroupBy.Performer]: ContentId.Performers,
  [ReportGroupBy.Event]: ContentId.Event,
  [ReportGroupBy.EventGenre]: ContentId.Genre,
  [ReportGroupBy.Venue]: ContentId.Venue,
  [ReportGroupBy.EventCountry]: ContentId.Country,
  [ReportGroupBy.EventCity]: ContentId.City,
  [ReportGroupBy.SeatingSection]: ContentId.Section,
  [ReportGroupBy.SeatingRow]: ContentId.Row,
  [ReportGroupBy.Vendor]: ContentId.Vendor,
  [ReportGroupBy.PricedBy]: ContentId.PricedBy,
  [ReportGroupBy.PurchasedBy]: ContentId.PurchasedBy,
  [ReportGroupBy.FulfilledBy]: ContentId.FulfilledBy,
};

export const REPORT_SALE_GROUP_BY_TO_CID: Record<string, ContentId> = {
  [ReportGroupBy.SaleId]: ContentId.Sale,
  [ReportGroupBy.Performer]: ContentId.Performers,
  [ReportGroupBy.Event]: ContentId.Event,
  [ReportGroupBy.EventGenre]: ContentId.Genre,
  [ReportGroupBy.Venue]: ContentId.Venue,
  [ReportGroupBy.EventCountry]: ContentId.Country,
  [ReportGroupBy.EventCity]: ContentId.City,
  [ReportGroupBy.SeatingSection]: ContentId.Section,
  [ReportGroupBy.SeatingRow]: ContentId.Row,
  [ReportGroupBy.SaleMarketplace]: ContentId.Marketplace,
  [ReportGroupBy.Vendor]: ContentId.Vendor,
  [ReportGroupBy.SaleMonth]: ContentId.Month,
  [ReportGroupBy.SaleWeek]: ContentId.WeekOfYear,
  [ReportGroupBy.SaleDay]: ContentId.Day,
  [ReportGroupBy.PricedBy]: ContentId.PricedBy,
  [ReportGroupBy.PurchasedBy]: ContentId.PurchasedBy,
  [ReportGroupBy.FulfilledBy]: ContentId.FulfilledBy,
};

export const REPORT_IS_SORT_DESCENDING_TO_CID: Record<string, ContentId> = {
  // corresponds to value isSortDescending : boolean
  false: ContentId.AtoZArrow,
  true: ContentId.ZtoAArrow,
};

export const REPORT_IS_SORT_ASCENDING_TO_CID: Record<string, ContentId> = {
  // corresponds to value isSortAsc : boolean
  true: ContentId.AtoZArrow,
  false: ContentId.ZtoAArrow,
};

export const BOOLEAN_OPTIONS_CONTENT: Record<string, string> = {
  true: 'true',
  false: 'false',
};

export const RESTRICT_SELECTION_TYPE_TO_CID: Record<
  RestrictSelectionType,
  ContentId
> = {
  [RestrictSelectionType.SameZone]: ContentId.SameZone,
  [RestrictSelectionType.SpecificSections]: ContentId.SpecificSections,
  [RestrictSelectionType.SameSection]: ContentId.SameSection,
};

export const MARKETPLACE_PAYMENT_TERM_TO_CID: Record<
  MarketplacePaymentTerm,
  ContentId
> = {
  [MarketplacePaymentTerm.PaymentUponSaleConfirmation]:
    ContentId.PaymentUponSaleConfirmation,
  [MarketplacePaymentTerm.PaymentUponDelivery]: ContentId.PaymentUponDelivery,
  [MarketplacePaymentTerm.PaymentPostEvent]: ContentId.PaymentPostEvent,
};

// From https://dev.azure.com/viagogo/Database/_git/viagogo.Database.ProductionDeploys?path=%2Fdb%2FGoldenData%2FAppEventDataManager%2Ftables%2FMappingRejectionReason.sql&_a=contents&version=GBdevelop
// Overrides the MappingRejectionReasonDescription within the table with internationalized content
//
// select	*
// from	GoldenData.AppEventDataManager.MappingRejectionReason
// where	MappingTypeId = 1; -- Event Mapping
export const MAPPING_REJECTION_REASON_ID_TO_CID: Record<string, ContentId> = {
  '1': ContentId.CancelledEvent,
  '2': ContentId.CharityEvent,
  '3': ContentId.DuplicateEventNeedsToBeRescheduled,
  '4': ContentId.DuplicateEventPerformerVenueDateAndTimeMatch,
  '5': ContentId.EventHasPassed,
  '6': ContentId.FreeEvent,
  '7': ContentId.FulfillmentRestrictions,
  '8': ContentId.IncorrectPerfomerOrGrouping,
  '9': ContentId.NotEnoughInformationToCreate,
  '10': ContentId.NotFoundOnPrimary,
  '11': ContentId.PendingPartnerIntegrations,
  '12': ContentId.IncorrectCountryCode,
};

export const EVENT_MAPPING_STATE_FILTER_TO_CID: Record<
  EventMappingStateFilter,
  ContentId
> = {
  [EventMappingStateFilter.All]: ContentId.All,
  [EventMappingStateFilter.Mapped]: ContentId.Mapped,
  [EventMappingStateFilter.Pending]: ContentId.Pending,
  [EventMappingStateFilter.Rejected]: ContentId.Rejected,
};

export const PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_CID: Record<
  PricingFloorCeilingAdjustmentType,
  ContentId
> = {
  [PricingFloorCeilingAdjustmentType.MarketPrice]: ContentId.MarketPrice,
  [PricingFloorCeilingAdjustmentType.UnitCost]: ContentId.UnitCost,
  [PricingFloorCeilingAdjustmentType.CurrentFloor]: ContentId.CurrentFloor,
};

export const PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_WARNING_CID: Record<
  PricingFloorCeilingAdjustmentType,
  ContentId
> = {
  [PricingFloorCeilingAdjustmentType.MarketPrice]:
    ContentId.BulkEditFloorCeilingWarning,
  [PricingFloorCeilingAdjustmentType.UnitCost]:
    ContentId.BulkEditFloorCeilingUnitCostWarning,
  [PricingFloorCeilingAdjustmentType.CurrentFloor]:
    ContentId.BulkEditFloorCeilingCurrentFloorWarning,
};

export const BROADCAST_ERROR_CODE_TO_CID: Record<
  BroadcastErrorCode,
  ContentId
> = {
  [BroadcastErrorCode.BroadcastListing]:
    ContentId.BroadcastErrorCode_BroadcastListing,
  [BroadcastErrorCode.Unknown]: ContentId.BroadcastErrorCode_Unknown,
  [BroadcastErrorCode.UpdatePrice]: ContentId.BroadcastErrorCode_UpdatePrice,
  [BroadcastErrorCode.UpdateTicketType]:
    ContentId.BroadcastErrorCode_UpdateTicketType,
  [BroadcastErrorCode.UpdateSplitType]:
    ContentId.BroadcastErrorCode_UpdateSplitType,
  [BroadcastErrorCode.UpdateInHandDate]:
    ContentId.BroadcastErrorCode_UpdateInHandDate,
  [BroadcastErrorCode.SplitListing]: ContentId.BroadcastErrorCode_SplitListing,
  [BroadcastErrorCode.MergeListing]: ContentId.BroadcastErrorCode_MergeListing,
  [BroadcastErrorCode.UnbroadcastListing]:
    ContentId.BroadcastErrorCode_UnbroadcastListing,
  [BroadcastErrorCode.UpdateQuantity]:
    ContentId.BroadcastErrorCode_UpdateQuantity,
  [BroadcastErrorCode.HideSeats]: ContentId.BroadcastErrorCode_HideSeats,
  [BroadcastErrorCode.UpdateListingNotes]:
    ContentId.BroadcastErrorCode_UpdateListingNotes,
  [BroadcastErrorCode.UpdateSeating]:
    ContentId.BroadcastErrorCode_UpdateSeating,
  [BroadcastErrorCode.MarketplaceResaleRestricted]:
    ContentId.BroadcastErrorCode_MarketplaceResaleRestricted,
  [BroadcastErrorCode.MarketplaceManifestMismatch]:
    ContentId.BroadcastErrorCode_MarketplaceManifestMismatch,
  [BroadcastErrorCode.MarketplacePriceBelowFloor]:
    ContentId.BroadcastErrorCode_MarketplacePriceBelowFloor,
};

export const PAYMENT_METHOD_NAME_REGEX_TO_CONTENT_ID: Record<
  PaymentMethodNameKnownRegex,
  ContentId
> = {
  [PaymentMethodNameKnownRegex.All]: ContentId.All,
  [PaymentMethodNameKnownRegex.AllAmex]: ContentId.AllAmex,
  [PaymentMethodNameKnownRegex.AllVisa]: ContentId.AllVisa,
  [PaymentMethodNameKnownRegex.AllMastercard]: ContentId.AllMastercard,
};

export const REPORT_FILTER_EDITABILITY_TO_CID: Record<
  ReportFilterEditability,
  ContentId
> = {
  [ReportFilterEditability.Hidden]: ContentId.Hidden,
  [ReportFilterEditability.View]: ContentId.View,
  [ReportFilterEditability.Edit]: ContentId.Edit,
};

export const REPORT_FILTER_EDITABILITY_TO_DESC_CID: Record<
  ReportFilterEditability,
  ContentId
> = {
  [ReportFilterEditability.Hidden]: ContentId.HiddenEditabilityDescription,
  [ReportFilterEditability.View]: ContentId.ViewEditabilityDescription,
  [ReportFilterEditability.Edit]: ContentId.EditEditabilityDescription,
};

export const VENUE_ZONE_CONFIG_TYPE_TO_CID: Record<
  VenueZoneConfigType,
  ContentId
> = {
  [VenueZoneConfigType.Amphitheater]: ContentId.Amphitheater,
  [VenueZoneConfigType.ConcertsSectionsBehindStage]:
    ContentId.ConcertsSectionsBehindStage,
  [VenueZoneConfigType.SportsOrConcertsWith360]:
    ContentId.SportsOrConcertsWith360,
};

export const VENUE_ZONE_SECTION_GROUP_AREA_TYPE_TO_CID: Record<
  SectionGroupAreaType,
  ContentId
> = {
  [SectionGroupAreaType.Center]: ContentId.Center,
  [SectionGroupAreaType.LeftRight]: ContentId.LeftAndRight,
  [SectionGroupAreaType.FarLeftFarRight]: ContentId.FarLeftAndFarRight,
  [SectionGroupAreaType.Front]: ContentId.Front,
  [SectionGroupAreaType.Sides]: ContentId.Sides,
  [SectionGroupAreaType.SideT1]: ContentId.SideTier1,
  [SectionGroupAreaType.SideT2]: ContentId.SideTier2,
  [SectionGroupAreaType.Ends]: ContentId.Ends,
  [SectionGroupAreaType.Rear]: ContentId.Rear,
  [SectionGroupAreaType.Corners]: ContentId.Corners,
  [SectionGroupAreaType.Unknown]: ContentId.Unknown,
};

export const EVENT_STATUS_TO_CID: Record<ApiEventStatus, ContentId> = {
  [ApiEventStatus.Normal]: ContentId.Normal,
  [ApiEventStatus.Postponed]: ContentId.Postponed,
  [ApiEventStatus.Cancelled]: ContentId.Cancelled,
  [ApiEventStatus.Rescheduled]: ContentId.Rescheduled,
  [ApiEventStatus.Relocated]: ContentId.Relocated,
  [ApiEventStatus.RelocatedAndRescheduled]: ContentId.RelocatedAndRescheduled,
  [ApiEventStatus.Draft]: ContentId.Draft,
  [ApiEventStatus.Deleted]: ContentId.Deleted,
  [ApiEventStatus.Contingent]: ContentId.Contingent,
};

export const EVENT_DEMAND_FACTOR_TYPE_TO_CID: Record<
  EventDemandFactorType,
  ContentId
> = {
  [EventDemandFactorType.HomeTeamOdds]: ContentId.Odds,
  [EventDemandFactorType.Opponent]: ContentId.Opponent,
  [EventDemandFactorType.SpotifyPopularity]: ContentId.SpotifyPopularity,
  [EventDemandFactorType.Stakes]: ContentId.Stakes,
  [EventDemandFactorType.Standing]: ContentId.Standing,
  [EventDemandFactorType.Weather]: ContentId.Weather,
};

export const ROW_COMP_SETTING_TO_CID: Record<string, ContentId | string> = {
  0: ContentId.RowCompSettingExactMatch,
  1: '-1',
  3: '-3',
  6: '-6',
};

export const ROW_GROUP_SETTING_TO_CID: Record<
  RowGroupSetting,
  ContentId | string
> = {
  [RowGroupSetting.SingleTwoToFiveSixPlus]: '1 2-5 6+',
  [RowGroupSetting.OneTwoToFiveSixToTwelveThirteenPlus]: '1 2-5 6-12 13+',
  [RowGroupSetting.OneToFiveSixPlus]: '1-5 6+',
  [RowGroupSetting.OneToTenElevenPlus]: '1-10 11+',
  [RowGroupSetting.AllRows]: ContentId.All,
};

export const PURCHASE_ORDER_STATE_TO_CID: Record<
  PurchaseOrderState,
  ContentId
> = {
  [PurchaseOrderState.Active]: ContentId.Active,
  [PurchaseOrderState.Cancelled]: ContentId.Cancelled,
  [PurchaseOrderState.Void]: ContentId.Void,
  [PurchaseOrderState.Drafted]: ContentId.Drafted,
};

export const LISTING_NOTE_TYPE_TO_CID: Record<ListingNoteType, ContentId> = {
  [ListingNoteType.Defect]: ContentId.Defect,
  [ListingNoteType.RestrictionOnUse]: ContentId.RestrictionOnUse,
  [ListingNoteType.Perk]: ContentId.Perk,
  [ListingNoteType.Information]: ContentId.Information,
  [ListingNoteType.ConnectedSeller]: ContentId.ConnectedSeller,
  [ListingNoteType.InternallyGenerated]: ContentId.InternallyGenerated,
};

export const COMMAND_OPTION_TYPE_TO_CID: Record<CommandOptionType, ContentId> =
  {
    [CommandOptionType.ToggleSelectionMode]: ContentId.ToggleSelectionMode,
    [CommandOptionType.SelectAll]: ContentId.SelectAll,
    [CommandOptionType.AutoPricingSettings]: ContentId.AutopricingSettings,
  };
