import clsx from 'clsx';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  isFormatContentId,
  useContent,
  useFormattedContent,
} from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useFlexSearchIndex } from 'src/hooks/useFlexSearchIndex';
import {
  CheckIcon,
  CrossIcon,
  ExpandIcon,
  FoldIcon,
  IconsFill,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import { PosDropdown } from '../PosDropdown';
import { PosSpinner } from '../PosSpinner';
import { PosTextField } from '../PosTextField';
import * as styles from './PosMultiSelect.css';
import {
  isToggleAllValue,
  PosMultiSelectOptions,
} from './PosMultiSelectOptions';

const MIN_ITEMS_COUNT_TO_HIDE_SELECT_ALL = 50;

export type PosMultiSelectProps = Omit<
  ComponentPropsWithoutRef<typeof PosDropdown>,
  'onChange'
> & {
  values: string[];
  placeholderText?: string | ContentId;
  enableEmptySelection?: boolean;
  hasErrors?: boolean;
  onChange: (values: string[], searchText?: string | null) => void;
  leftIcon?: ReactNode;
  showClearButton?: boolean;
  closeAfterToggleAll?: boolean;
  valueOptionsContent: Record<string, ContentId | string>;
  valueOptionsIcon?: Record<string, ReactNode>;
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  /* this needs searchable to be true */
  allowSearchAsInput?: boolean;
  sortMode?: 'ascending' | 'descending' | 'none';
  sortFn?: (a: string, b: string) => number;
  useVirtualWindow?: boolean;
  expectedItemSizeForVirtualWindow?: number;
  fullWidth?: boolean;
  showSelectedOptionsFirst?: boolean;
  showSelectAllOption?: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  onSearchTextChange?: (searchText: string) => void;
  includeSelectedInSearch?: boolean;
  valuesDisplayText?: string | null;
  prefixDisplay?: React.ReactNode;
};

export const PosMultiSelect = forwardRef<HTMLDivElement, PosMultiSelectProps>(
  function PosMultiSelect(
    {
      values,
      placeholderText,
      enableEmptySelection,
      hasErrors,
      onChange,
      leftIcon,
      valueOptionsContent,
      valueOptionsIcon,
      disabled,
      loading,
      showClearButton,
      closeAfterToggleAll,
      searchable,
      allowSearchAsInput,
      rootProps,
      sortMode,
      sortFn,
      useVirtualWindow,
      expectedItemSizeForVirtualWindow,
      fullWidth,
      showSelectedOptionsFirst,
      showSelectAllOption: showSelectAllOptionProp,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      onSearchTextChange,
      includeSelectedInSearch,
      valuesDisplayText,
      prefixDisplay,
      ...rest
    },
    ref
  ): ReactElement {
    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const showSelectAllOption = useMemo<boolean>(() => {
      if (typeof showSelectAllOptionProp === 'boolean') {
        return showSelectAllOptionProp;
      }
      return (
        Object.keys(valueOptionsContent).length <
        MIN_ITEMS_COUNT_TO_HIDE_SELECT_ALL
      );
    }, [showSelectAllOptionProp, valueOptionsContent]);

    const { flexSearchIndex } = useFlexSearchIndex(
      valueOptionsContent,
      searchable,
      isOpen
    );

    const isBuildingSearchIndex = searchable && !flexSearchIndex;

    const onOpenChange = useCallback(
      (open: boolean) => {
        setIsOpen(open);
        rootProps?.onOpenChange?.(open);
      },
      [rootProps]
    );

    const valueDisplay =
      values.length === 1
        ? valueOptionsContent[values[0]]
        : values.length > 1
        ? FormatContentId.ItemsSelected
        : null; // we only display value when we have only 1 value selected

    const singleValueContentDisplay = useContent(valueDisplay ?? undefined);
    const valueContentDisplay =
      useFormattedContent(
        valueDisplay && isFormatContentId(valueDisplay)
          ? valueDisplay
          : FormatContentId.EmptyContent,
        `${values.length}`
      ) || singleValueContentDisplay;

    placeholderText = placeholderText ?? ContentId.Select;
    const placeholderTextContent = useContent(placeholderText);

    const mergeSelectedValue = useCallback(
      (newValue: string | null) => {
        const isToggleAll = isToggleAllValue(newValue);
        const allValuesKeys = Object.keys(valueOptionsContent);
        const areValuesSelected = values.length === allValuesKeys.length;

        if (isToggleAll) {
          if (showSelectAllOption) {
            return areValuesSelected ? [] : allValuesKeys;
          } else {
            // It's clear all option, then clear all
            return [];
          }
        }

        if (newValue && values.includes(newValue)) {
          // Unselect
          return values.filter((v) => v !== newValue);
        }

        // If we allow empty selections, just clear the selected values
        if (enableEmptySelection && !newValue) {
          return [];
        }

        return newValue ? [...values, newValue] : [...values];
      },
      [valueOptionsContent, values, showSelectAllOption, enableEmptySelection]
    );

    const searchPlaceholder = useContent(
      allowSearchAsInput ? ContentId.SearchOrEnter : ContentId.Search
    );

    const onSearchTextApplied = useCallback(
      (searchText: string) => {
        const existingValue = Object.keys(valueOptionsContent).find(
          (k) =>
            valueOptionsContent[k]?.toLocaleUpperCase() ===
            searchText.toLocaleUpperCase()
        );

        if (existingValue) {
          onChange(mergeSelectedValue(existingValue));
          if (isToggleAllValue(existingValue) && closeAfterToggleAll) {
            setIsOpen(false);
          }
        } else if (allowSearchAsInput) {
          onChange(values, searchText);
          setIsOpen(false);
        }
      },
      [
        allowSearchAsInput,
        closeAfterToggleAll,
        mergeSelectedValue,
        onChange,
        valueOptionsContent,
        values,
      ]
    );

    const searchBox = useMemo(
      () => (
        <div className={styles.seachBoxContainer}>
          <PosTextField
            ref={(input: HTMLInputElement) => input?.focus()}
            value={searchText}
            placeholder={searchPlaceholder}
            onChange={(e) => {
              setSearchText(e.target.value);
              onSearchTextChange?.(e.target.value);
            }}
            onKeyDown={(e) => {
              if (!disabled) {
                const key = e.key;
                if (key === 'Enter') {
                  e.stopPropagation();

                  // See if search-text matches any option, return it as a selection, else return searchText
                  if (searchText) {
                    onSearchTextApplied(searchText);
                  }
                }
              }
            }}
            postfixDisplay={
              searchText && (
                <>
                  {allowSearchAsInput && (
                    <CheckIcon
                      fill={IconsFill.textSuccess}
                      withHoverEffect
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onSearchTextApplied(searchText);
                      }}
                    />
                  )}
                  <CrossIcon
                    size={vars.iconSize.m}
                    withHoverEffect
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSearchText('');
                      onSearchTextChange?.('');
                    }}
                  />
                </>
              )
            }
          />
        </div>
      ),
      [
        allowSearchAsInput,
        disabled,
        onSearchTextApplied,
        onSearchTextChange,
        searchPlaceholder,
        searchText,
      ]
    );

    return (
      <PosDropdown
        ref={ref}
        {...rest}
        rootProps={{
          ...rootProps,
          open: isOpen,
          onOpenChange,
        }}
        triggerProps={{
          disabled,
          ...rest.triggerProps,
        }}
        trigger={
          <Button
            variant="outline"
            shape="rect"
            textColor="strong"
            className={clsx(styles.posMultiSelectTrigger, {
              [styles.error]: hasErrors,
              [styles.triggerFullWidth]: fullWidth,
            })}
            disabled={disabled}
            {...rest.triggerProps}
          >
            <Stack
              className={styles.posMultiSelectContent}
              gap="m"
              alignItems="center"
              width="full"
            >
              {prefixDisplay}
              {leftIcon}
              <div className={clsx('value-selected', styles.valueWrapper)}>
                {valuesDisplayText
                  ? valuesDisplayText
                  : !valueDisplay
                  ? placeholderTextContent || placeholderText
                  : valueContentDisplay}
              </div>
            </Stack>
            <Stack
              className={styles.posMultiSelectContent}
              gap="m"
              alignItems="center"
            >
              {showClearButton && values.length > 0 && (
                <CrossIcon
                  disabled={disabled}
                  withHoverEffect
                  size={vars.iconSize.m}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange([], null);
                  }}
                />
              )}
              {isOpen ? (
                <FoldIcon
                  withHoverEffect
                  disabled={disabled}
                  size={vars.iconSize.xs}
                  align={'middle'}
                />
              ) : (
                <ExpandIcon
                  withHoverEffect
                  disabled={disabled}
                  size={vars.iconSize.xs}
                  align={'middle'}
                />
              )}
            </Stack>
          </Button>
        }
      >
        {loading || isBuildingSearchIndex ? (
          <PosSpinner size={vars.iconSize.s} />
        ) : (
          <>
            {searchable && searchBox}
            <PosMultiSelectOptions
              selectedOptions={values}
              onChange={(v) => {
                onChange(mergeSelectedValue(v), null);
                if (isToggleAllValue(v) && closeAfterToggleAll) {
                  setIsOpen(false);
                }
              }}
              valueOptionsContent={valueOptionsContent}
              valueOptionsIcon={valueOptionsIcon}
              searchText={searchText}
              emptySelectionText={
                enableEmptySelection ? placeholderText : undefined
              }
              sortMode={sortMode}
              sortFn={sortFn}
              useVirtualWindow={useVirtualWindow}
              expectedItemSizeForVirtualWindow={
                expectedItemSizeForVirtualWindow
              }
              contentHeight={
                searchable
                  ? 'calc(var(--radix-popper-available-height) - 60px - 16px)'
                  : 'calc(var(--radix-popper-available-height) - 16px)'
              }
              flexSearchIndex={flexSearchIndex}
              showSelectedOptionsFirst={showSelectedOptionsFirst}
              showSelectAllOption={showSelectAllOption}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              includeSelectedInSearch={includeSelectedInSearch}
            />
          </>
        )}
      </PosDropdown>
    );
  }
);
