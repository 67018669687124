import {
  FieldPath,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { FormatContent } from 'src/contexts/ContentContext';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  AccountAutoPricingSettingsInputs,
  AutoPricingInputs,
  CompListingQuantityFilters,
} from 'src/WebApiController';

import * as styles from '../AutoPricing.css';
import { AutoPricingCompQuantitiesSelector } from '../AutoPricingCompQuantitiesSelector/AutoPricingCompQuantitiesSelector';

const exactMatchPosListingQuantities = [
  ...Array(9)
    .fill(0)
    .map((_, i) => i + 1),
];
const greaterThanOrEqualToPosListingQuantity = 10;

export const AutoPricingDefaultCompQuantitiesInput = ({
  disabled,
}: {
  disabled: boolean;
}) => {
  return (
    <div className={styles.enumeratedPriorityInputGrid}>
      {exactMatchPosListingQuantities.map((posListingQuantity, index) => {
        return (
          <AutoPricingDefaultCompQuantitiesPackSelector
            key={posListingQuantity}
            quantityFilterFieldPath={`defaultCompListingQuantityFilters.exactMatchQuantityFilters.${index}`}
            posListingQuantity={posListingQuantity}
            packLabelText={posListingQuantity.toString()}
            disabled={disabled}
          />
        );
      })}

      <AutoPricingDefaultCompQuantitiesPackSelector
        key={greaterThanOrEqualToPosListingQuantity}
        quantityFilterFieldPath={`defaultCompListingQuantityFilters.greaterThanOrEqualToQuantityFilter`}
        posListingQuantity={greaterThanOrEqualToPosListingQuantity}
        packLabelText={`${greaterThanOrEqualToPosListingQuantity}+`}
        disabled={disabled}
      />
    </div>
  );
};

const AutoPricingDefaultCompQuantitiesPackSelector = ({
  quantityFilterFieldPath,
  posListingQuantity,
  packLabelText,
  disabled,
}: {
  quantityFilterFieldPath: FieldPath<AccountAutoPricingSettingsInputs>;
  posListingQuantity: number;
  packLabelText: string;
  disabled: boolean;
}) => {
  const { setValue: outerSetValue, watch: outerWatch } =
    useFormContext<AccountAutoPricingSettingsInputs>();

  const quantityFilter = outerWatch(
    quantityFilterFieldPath
  ) as CompListingQuantityFilters;

  const {
    control: innerControl,
    watch: innerWatch,
    ...innerMethods
  } = useForm<AutoPricingInputs>({
    defaultValues: {
      compListingQuantityFilters: quantityFilter?.compQuantityFilters,
    },
  });

  innerWatch((autoPricingInputsNew) => {
    outerSetValue(quantityFilterFieldPath, {
      posListingQuantity: posListingQuantity,
      compQuantityFilters:
        autoPricingInputsNew?.compListingQuantityFilters ?? null,
    } as CompListingQuantityFilters);
  });

  return (
    <FormProvider control={innerControl} watch={innerWatch} {...innerMethods}>
      <>
        <div className={styles.inlinePricingInputText}>
          <FormatContent id={FormatContentId.PacksOf} params={packLabelText} />
        </div>
        <div>
          <AutoPricingCompQuantitiesSelector disabled={disabled} />
        </div>
      </>
    </FormProvider>
  );
};
