import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MarketplacePaymentIdDeeplinkQueryParam } from 'src/utils/constants/constants';

export const useGetMarketplacePaymentIdQueryParam = (): number | undefined => {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const activeMarketplacePaymentId = searchParams.get(
      MarketplacePaymentIdDeeplinkQueryParam
    );

    if (activeMarketplacePaymentId) {
      return Number(activeMarketplacePaymentId);
    }
  }, [searchParams]);
};
