export enum SalesTableColumnId {
  // Not Configurable
  Checkbox = 'checkbox',
  // Configurable
  Seating = 'seating',
  Row = 'row',
  Seats = 'seats',
  QuantitySold = 'quantitySold',
  OrderDate = 'orderDate',
  InHandDate = 'inHandDate',
  Status = 'status',
  TicketType = 'ticketType',
  Proceeds = 'proceeds',
  Sale = 'sale',
  Actions = 'actions',
  // Default Visibility Off (optional columns)
  SoldTicketCost = 'soldTicketCost',
  Charges = 'charges',
  Credits = 'credits',
  PandL = 'PandL',
  Margin = 'margin',
  PurchaseCreatedBy = 'purchaseCreatedBy',
  PurchasedBy = 'purchasedBy',
  PaymentState = 'paymentState',
  PaymentReceived = 'paymentReceived',
  PaymentReferenceId = 'paymentReferenceId',
  FulfillmentDate = 'fulfillmentDate',
  AutoFulfill = 'autoFulfill',
  PurchaseDate = 'purchaseDate',
  VendorOrderId = 'vendorOrderId',
  VendorAccountEmail = 'vendorAccountEmail',
  OriginatedBy = 'originatedBy',
  TotalPaymentsAmount = 'totalPaymentsAmount',
  TotalConvertedPaymentsAmount = 'totalConvertedPaymentsAmount',
}

export enum SalesTableFlattenedColumnId {
  Event = 'Event',
  Venue = 'Venue',
  Location = 'Location',
  EventDate = 'Date',
}

export enum SalesReportTableColumnId {
  // Group by types
  Performer = 'performer',
  Venue = 'venue',
  Event = 'event',
  EventGenre = 'eventGenre',
  EventCountry = 'eventCountry',
  EventCity = 'eventCity',
  SeatingSection = 'seatingSection',
  SeatingRow = 'seatingRow',
  SaleMarketplace = 'saleMarketplace',
  SaleMonth = 'saleMonth',
  SaleWeek = 'saleWeek',
  SaleDay = 'saleDay',
  // Configurable - shared precision with ListingTableColumnId
  QuantitySold = SalesTableColumnId.QuantitySold,
  Proceeds = SalesTableColumnId.Proceeds,
  SoldTicketCost = SalesTableColumnId.SoldTicketCost,
  Charges = SalesTableColumnId.Charges,
  Credits = SalesTableColumnId.Credits,
  // Auxiliary columns - neither metrics nor primary dimensions
  EventId = 'eventId',
  Category = 'category',
  EventDate = 'eventDate',
  EventStateProvince = 'eventStateProvince',
  SaleId = 'saleId',
  MarketplaceSaleId = 'marketplaceSaleId',
  Status = SalesTableColumnId.Status,
  TicketType = SalesTableColumnId.TicketType,
  InHandDate = SalesTableColumnId.InHandDate,
  Fulfiller = 'fulfiller',
  PurchaseId = 'purchaseId',
  PurchasedBy = 'purchasedBy',
  PurchaseDate = SalesTableColumnId.PurchaseDate,
  Vendor = 'vendor',
  SeatingSeats = 'seatingSeats',
  OrderDate = SalesTableColumnId.OrderDate,
  PricedBy = 'pricedBy',
  CurrencyCode = 'currencyCode',
  PaymentDate = 'paymentDate',
  FulfillmentDate = SalesTableColumnId.FulfillmentDate,
  PurchaseCreatedBy = SalesTableColumnId.PurchaseCreatedBy,
  PaymentState = SalesTableColumnId.PaymentState,
  PaymentReceived = SalesTableColumnId.PaymentReceived,
  PaymentReferenceId = SalesTableColumnId.PaymentReferenceId,
  VendorAccountEmail = SalesTableColumnId.VendorAccountEmail,
  InternalNotes = 'internalNotes',
  CancellationDate = 'cancellationDate',
  OutstandingBalance = 'outstandingBalance',
  PandL = SalesTableColumnId.PandL,
  Margin = SalesTableColumnId.Margin,
  TicketId = 'ticketId',
  TicketGroupId = 'ticketGroupId',
  CommissionUser = 'commissionUser',
  CommissionPercent = 'commissionPercent',
  CommissionShare = 'commissionShare',
  MarketplacePaymentTerms = 'marketplacePaymentTerms',
}
