import { Feature } from 'src/WebApiController';

import { ColumnPersonalizationFlags } from '../columnUtils.types';
import {
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from './salesColumnUtils.types';

export const SALE_MAIN_COLUMNS: SalesTableColumnId[] = [
  SalesTableColumnId.Checkbox,
  SalesTableColumnId.Seating,
  SalesTableColumnId.QuantitySold,
  SalesTableColumnId.OrderDate,
  SalesTableColumnId.InHandDate,
  SalesTableColumnId.Status,
  SalesTableColumnId.TicketType,
  SalesTableColumnId.Proceeds,
  SalesTableColumnId.Sale,
];

export const SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES: Record<
  SalesTableColumnId | SalesTableFlattenedColumnId,
  Partial<ColumnPersonalizationFlags>
> = {
  [SalesTableColumnId.Checkbox]: { isConfigurable: false },

  [SalesTableFlattenedColumnId.Event]: {
    isHiddenByDefault: false,
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
  },
  [SalesTableFlattenedColumnId.EventDate]: { isHiddenByDefault: true },
  [SalesTableFlattenedColumnId.Venue]: { isHiddenByDefault: true },
  [SalesTableFlattenedColumnId.Location]: { isHiddenByDefault: true },

  [SalesTableColumnId.Actions]: { isConfigurable: false, isRequired: true },
  [SalesTableColumnId.InHandDate]: {},
  [SalesTableColumnId.OrderDate]: { isDateTime: true },
  [SalesTableColumnId.Proceeds]: { isNumeric: true, isCurrency: true },
  [SalesTableColumnId.QuantitySold]: { isNumeric: true, isInteger: true },
  [SalesTableColumnId.Sale]: {},
  [SalesTableColumnId.Seating]: { isNumeric: true, isRequired: true },
  [SalesTableColumnId.Row]: {
    requiredFeatures: [Feature.RowSeatColumnSalePurchase],
  },
  [SalesTableColumnId.Seats]: {
    requiredFeatures: [Feature.RowSeatColumnSalePurchase],
  },
  [SalesTableColumnId.Status]: {
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
    isEnumWithIcon: true,
  },
  [SalesTableColumnId.TicketType]: {
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
    isEnumWithIcon: true,
  },
  [SalesTableColumnId.SoldTicketCost]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
  },
  [SalesTableColumnId.Charges]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
  },
  [SalesTableColumnId.Credits]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
  },
  [SalesTableColumnId.PandL]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
  },
  [SalesTableColumnId.Margin]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isPercent: true,
  },
  [SalesTableColumnId.AutoFulfill]: {
    isHiddenByDefault: true,
    requiredFeatures: [Feature.AutoFulfillSetting],
  },
  [SalesTableColumnId.PaymentState]: {
    isHiddenByDefault: true,
  },
  [SalesTableColumnId.PaymentReceived]: {
    isHiddenByDefault: true,
  },
  [SalesTableColumnId.PaymentReferenceId]: {
    isHiddenByDefault: true,
  },
  [SalesTableColumnId.FulfillmentDate]: {
    isDateTime: true,
    isHiddenByDefault: true,
  },
  [SalesTableColumnId.PurchaseCreatedBy]: {
    isHiddenByDefault: true,
  },
  [SalesTableColumnId.PurchasedBy]: {
    isHiddenByDefault: true,
  },
  [SalesTableColumnId.PurchaseDate]: {
    isHiddenByDefault: true,
    isDateTime: true,
  },
  [SalesTableColumnId.VendorOrderId]: {
    isHiddenByDefault: true,
  },
  [SalesTableColumnId.VendorAccountEmail]: {
    isHiddenByDefault: true,
  },
  [SalesTableColumnId.OriginatedBy]: {
    isHiddenByDefault: true,
  },
  [SalesTableColumnId.TotalPaymentsAmount]: {
    isHiddenByDefault: true,
    requiredFeatures: [Feature.MarketplacePaymentConversion],
  },
  [SalesTableColumnId.TotalConvertedPaymentsAmount]: {
    isHiddenByDefault: true,
    requiredFeatures: [Feature.MarketplacePaymentConversion],
  },
} as const;
