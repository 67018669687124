import { ComponentProps, useCallback, useContext, useMemo } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { SplitListingToOriginalDialog } from 'src/dialogs/SplitListingToOriginalDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { SplitListingModal } from 'src/modals/SplitListing';
import { SplitIcon } from 'src/svgs/SplitIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Feature,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  SplitListingInput,
  TicketByListingNumber,
} from 'src/WebApiController';

function getSplitListingToOriginalInput(
  listing: ListingDetails | null | undefined
): SplitListingInput | null {
  if (!listing?.tickets?.length) {
    return null;
  }

  const ticketsByListingNumber: TicketByListingNumber[] = [];

  (listing.tickets ?? []).forEach((t) => {
    if (ticketsByListingNumber.length === 0) {
      ticketsByListingNumber.push({ ticket: t, listingNumber: 1 });
    } else {
      const prev = ticketsByListingNumber[ticketsByListingNumber.length - 1];
      if (prev.ticket.tgId !== t.tgId) {
        ticketsByListingNumber.push({
          ticket: t,
          listingNumber: prev.listingNumber + 1,
        });
      } else {
        ticketsByListingNumber.push({
          ticket: t,
          listingNumber: prev.listingNumber,
        });
      }
    }
  });

  if (ticketsByListingNumber.length < 2) {
    return null;
  }

  const numOfListings =
    ticketsByListingNumber[ticketsByListingNumber.length - 1].listingNumber;

  return {
    sourceListingId: listing.id,
    numOfListings: numOfListings,
    ticketsByListingNumber: ticketsByListingNumber,
    alwaysUnbroadcastListing: true,
    useOriginalTicketGroupInfoForNewListings: true,
  };
}

export const LaunchSplitListingToOriginal = ({
  listingId,
  cancelTo,
  variant,
  disabled,
  iconOnlyMode,
  ...buttonProps
}: {
  listingId: number;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
} & ComponentProps<typeof SplitListingModal>) => {
  const hasSplitListingToOriginalFeature = useUserHasFeature(
    Feature.SplitListingToOriginal
  );
  const { closeModal } = useContext(ModalContext);
  const {
    posEntity: listing,
    setActivePosEntity,
    isLoading,
  } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const splitListingInput = useMemo(
    () => getSplitListingToOriginalInput(listing),
    [listing]
  );

  const { openDialog, closeDialog } = useDialog(
    DialogId.Confirm,
    SplitListingToOriginalDialog
  );

  const { showErrorDialog } = useErrorBoundaryContext();

  const splitListing = useCallback(async () => {
    return await tryInvokeApi(
      async () => {
        const result = await new ListingClient(
          activeAccountWebClientConfig
        ).splitListings(splitListingInput!);

        if (result) {
          refreshExpandedListItems();

          // After we split - the original listing is gone - so we don't want to cancel back to it
          // so we just close the main modal
          closeModal(true);
        }
      },
      (error) => {
        showErrorDialog('ListingClient.splitListings', error);
      },
      () => {
        closeDialog();
      }
    );
  }, [
    activeAccountWebClientConfig,
    closeDialog,
    closeModal,
    refreshExpandedListItems,
    showErrorDialog,
    splitListingInput,
  ]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(listingId, null, true, [
        ListingDetailDataField.Basic,
        ListingDetailDataField.Tickets,
      ]);

      openDialog({
        splitListingInput,
        isDataLoading: isLoading,
        onOkay: async () => {
          await splitListing();
          closeDialog();
        },
        onCancel: () => {
          closeDialog();
        },
      });
    },
    [
      setActivePosEntity,
      listingId,
      openDialog,
      splitListingInput,
      isLoading,
      splitListing,
      closeDialog,
    ]
  );

  if (!hasSplitListingToOriginalFeature) {
    return null;
  }

  return (
    <ButtonWithIcon
      {...buttonProps}
      variant={variant}
      iconOnlyMode={iconOnlyMode}
      onClick={onLaunchDialog}
      textContentId={ContentId.ResetToOriginal}
      disabled={disabled || !splitListingInput}
      icon={
        <SplitIcon
          withHoverEffect
          stroke={
            iconOnlyMode || (variant && variant !== 'regular')
              ? vars.color.textBrand
              : vars.color.textInverted
          }
          size={vars.iconSize.m}
        />
      }
    />
  );
};
