import { ColumnDef } from '@tanstack/react-table';
import { ReactNode, useMemo } from 'react';
import { MarketplacePaymentStatePill } from 'src/components/common/MarketplacePaymentStatePill/MarketplacePaymentStatePill';
import { MarketplaceSquareLogo } from 'src/components/common/MarketplaceSquareLogo';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_SM,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content, useContent } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FormatOption_DateTime } from 'src/modals/EditTableColumns';
import { MarketplacePaymentsActions } from 'src/tables/MarketplacePaymentsTable/Cells/MarketplacePaymentsActionsCell';
import { SHIMMERING_DIV_HEIGHT_PAYMENTS } from 'src/tables/MarketplacePaymentsTable/MarketplacePaymentsTableCommon.constants';
import { TableShimmeringDiv } from 'src/tables/Table';
import { DateCell } from 'src/tables/Table/TableCells';
import { MarketplacePaymentsTableColumnId } from 'src/utils/columns/marketplacePayments/marketplacePaymentsColumnsUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { MARKETPLACE_SALE_PAYMENT_STATE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { safeSortDateTime, sortMoney } from 'src/utils/tableUtils';
import {
  Feature,
  Marketplace,
  MarketplacePaymentState,
  MarketplaceSalePayment,
  UiMoney,
} from 'src/WebApiController';

export const useMarketplacePaymentsTableColumnsConfig = () => {
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );

  return useMemo<
    Record<
      MarketplacePaymentsTableColumnId,
      ColumnDef<MarketplaceSalePayment | null>
    >
  >(() => {
    // Reuse dates for same values
    const datesMap: Map<string, ReactNode> = new Map();

    return {
      [MarketplacePaymentsTableColumnId.PaymentDate]: {
        id: MarketplacePaymentsTableColumnId.PaymentDate,
        accessorFn: (data) => data?.pmtDate,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.PaymentDate} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const date = getValue<string | null>();
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          if (!date) {
            return null;
          }

          if (!datesMap.has(date)) {
            datesMap.set(
              date,
              <DateCell
                precision={FormatOption_DateTime.DateTime_Month_DD_YY}
                date={date}
                align="left"
                enableUtcFallback
                hideRelativeTerms
              />
            );
          }
          return <TableCellDiv align="left">{datesMap.get(date)}</TableCellDiv>;
        },
        sortingFn: safeSortDateTime<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.Marketplace]: {
        id: MarketplacePaymentsTableColumnId.Marketplace,
        accessorFn: (data) => data?.mkp,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.Marketplace} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const marketplace = getValue<Marketplace>();
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          return (
            <TableCellDiv align="left" title={marketplace}>
              <MarketplaceSquareLogo marketplace={marketplace} />
            </TableCellDiv>
          );
        },
      },
      [MarketplacePaymentsTableColumnId.PaymentReferenceID]: {
        id: MarketplacePaymentsTableColumnId.PaymentReferenceID,
        accessorFn: (data) => data?.extPmtId,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.PaymentId} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_BASE,
        minSize: COLUMN_DEFAULT_SIZE_BASE,
        maxSize: COLUMN_DEFAULT_SIZE_LG,
        cell: ({ getValue, row: { original } }) => {
          const pmtRefId = getValue<string | null>();
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }
          return <TableCellDiv align="left">{pmtRefId}</TableCellDiv>;
        },
      },
      [MarketplacePaymentsTableColumnId.State]: {
        id: MarketplacePaymentsTableColumnId.State,
        accessorFn: (data) => data?.state,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.State} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({ getValue, row: { original } }) {
          const state = getValue<MarketplacePaymentState | null>();
          const stateTitle = useContent(
            MARKETPLACE_SALE_PAYMENT_STATE_TO_CID[
              state || MarketplacePaymentState.Ineligible
            ]
          );

          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          if (!state) {
            return null;
          }

          return (
            <TableCellDiv align="left" title={stateTitle}>
              <MarketplacePaymentStatePill state={state} />
            </TableCellDiv>
          );
        },
      },
      [MarketplacePaymentsTableColumnId.PaymentAmount]: {
        id: MarketplacePaymentsTableColumnId.PaymentAmount,
        accessorFn: (data) => data?.pmtAmt,
        header: (params) => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.Total} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const pmtAmt = getValue<UiMoney | null>();
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          if (!pmtAmt) {
            return null;
          }

          return <TableCellDiv align="right">{pmtAmt.disp}</TableCellDiv>;
        },
        sortingFn: sortMoney<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.ConvertedPaymentsAmount]: {
        id: MarketplacePaymentsTableColumnId.ConvertedPaymentsAmount,
        accessorFn: (data) => data?.convertedPmtAmt,
        header: (params) => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.Converted} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const convertedPmtAmt = getValue<UiMoney | null>();
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          if (!convertedPmtAmt) {
            return null;
          }

          return (
            <TableCellDiv align="right">{convertedPmtAmt.disp}</TableCellDiv>
          );
        },
        sortingFn: sortMoney<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.CreditAmount]: {
        id: MarketplacePaymentsTableColumnId.CreditAmount,
        accessorFn: (data) => data?.credit,
        header: (params) => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.Credits} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const credit = getValue<UiMoney | null>();
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          if (!credit) {
            return null;
          }

          return <TableCellDiv align="right">{credit.disp}</TableCellDiv>;
        },
        sortingFn: sortMoney<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.ChargeAmount]: {
        id: MarketplacePaymentsTableColumnId.ChargeAmount,
        accessorFn: (data) => data?.charge,
        header: (params) => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.Charges} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const charge = getValue<UiMoney | null>();

          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          if (!charge) {
            return null;
          }

          return <TableCellDiv align="right">{charge.disp}</TableCellDiv>;
        },
        sortingFn: sortMoney<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.ProceedsAmount]: {
        id: MarketplacePaymentsTableColumnId.ProceedsAmount,
        accessorFn: (data) => data?.proceedsAmt,
        header: (params) => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.Proceeds} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const proceeds = getValue<UiMoney | null>();

          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          if (!proceeds) {
            return null;
          }

          return <TableCellDiv align="right">{proceeds.disp}</TableCellDiv>;
        },
        sortingFn: sortMoney<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.Actions]: {
        id: MarketplacePaymentsTableColumnId.Actions,
        accessorFn: (data) => data,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.Actions} />
          </TableHeaderCellDiv>
        ),
        enableResizing: !hasTablePinActionColumnFeature,
        enableSorting: false,
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }
          const marketplaceSalePayment = getValue<MarketplaceSalePayment>();
          return (
            <TableCellDiv align="left" showTooltip={false}>
              <MarketplacePaymentsActions
                marketplaceSalePayment={marketplaceSalePayment}
              />
            </TableCellDiv>
          );
        },
      },
    };
  }, [hasTablePinActionColumnFeature]);
};
