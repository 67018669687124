import { useQuery } from '@tanstack/react-query';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Feature,
  SellerAccountClient,
  SellerUserAccountInfo,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useContent } from '../ContentContext';

type ISellerAccountContext = {
  allUserInfos: Record<string, SellerUserAccountInfo> | null; // List of all user-infos for the seller account (including deleted ones so they can be displayed on entity's ownership)
  allActiveUserInfos: Record<string, SellerUserAccountInfo> | null; // List of all active user-infos for the seller account
  isLoading?: boolean;
  getUserInfo: (id: string) => SellerUserAccountInfo | null;
  refetchUserInfos: () => void;
};

export const SellerAccountContext = createContext<ISellerAccountContext>({
  allUserInfos: {},
  allActiveUserInfos: {},
  isLoading: false,
  getUserInfo: () => null,
  refetchUserInfos: () => {},
});

export const useSellerAccountContext = () => useContext(SellerAccountContext);

export const SELLER_ACCOUNT_SELLER_USERS_QUERY_KEY =
  'SellerAccountClient.getSellerUsersForSellerAccount';

/**
 * Adds a modal to the page where the provider is added
 * Provides utils to set all or update part of the modal
 * The modal is assumed to be open when it contains content
 */
export const SellerAccountContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const deactivatedText = useContent(ContentId.Deactivated);
  const hasDisplayDeactivatedUser = useUserHasFeature(
    Feature.DisplayDeactivatedUser
  );

  const getQuery = useQuery({
    queryKey: [
      SELLER_ACCOUNT_SELLER_USERS_QUERY_KEY,
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      const results = await new SellerAccountClient(
        activeAccountWebClientConfig
      ).getSellerUsersForSellerAccount();

      const resultsMap = results?.reduce<Record<string, SellerUserAccountInfo>>(
        (r, cur) => {
          r[cur.userId] = cur;
          return r;
        },
        {}
      );

      return resultsMap;
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('SellerAccountClient.getSellerUsersForSellerAccount', error);
      },
    },
  });

  const getUserInfo = useCallback(
    (userId: string) => {
      if (!userId || !getQuery.data) {
        return null;
      }

      return getQuery.data[userId];
    },
    [getQuery.data]
  );

  const allUserInfos = useMemo(() => {
    if (!getQuery.data) return null;

    return Object.values(getQuery.data).reduce<
      Record<string, SellerUserAccountInfo>
    >((r, cur) => {
      if (cur.isDeactivated) {
        cur.displayName = `${cur.displayName} (${deactivatedText})`;
      }
      r[cur.userId] = cur;
      return r;
    }, {});
  }, [deactivatedText, getQuery.data]);

  const allActiveUserInfos = useMemo(() => {
    if (!getQuery.data) return null;

    return Object.values(getQuery.data)
      .filter((u) => !u.isDeactivated)
      .reduce<Record<string, SellerUserAccountInfo>>((r, cur) => {
        r[cur.userId] = cur;
        return r;
      }, {});
  }, [getQuery.data]);

  return (
    <SellerAccountContext.Provider
      value={{
        allUserInfos: hasDisplayDeactivatedUser
          ? allUserInfos
          : getQuery.data ?? {},
        allActiveUserInfos: hasDisplayDeactivatedUser
          ? allUserInfos
          : allActiveUserInfos,
        isLoading: getQuery.isLoading,
        getUserInfo: getUserInfo,
        refetchUserInfos: getQuery.refetch,
      }}
    >
      {children}
    </SellerAccountContext.Provider>
  );
};
