import { ButtonWithIcon } from 'src/components/Buttons';
import { FilterPillList } from 'src/components/common/FilterPill';
import { vars } from 'src/core/themes';
import { SectionSelectDialog } from 'src/dialogs/SectionSelectDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionInfo } from 'src/WebApiController';

import { useAutoPricingSettingsSelectedSections } from './AutoPricingSelectedSectionSettingsInput.hooks';

export const AutoPricingSelectedSectionSettingsInput = ({
  venueMapSections,
  disabled,
}: {
  venueMapSections: SectionInfo[] | null | undefined;
  disabled: boolean;
}) => {
  const sectionSelectDialog = useBasicDialog();

  const {
    compListingSelectedSectionSettings,
    setCompListingSelectedSectionSettings,
    selectedSections,
    rowIdFilters,
    sectionRowIdFilters,
    onRowIdFiltersChange,
    onSectionIdFiltersChange,
  } = useAutoPricingSettingsSelectedSections(venueMapSections);

  return (
    <>
      {compListingSelectedSectionSettings?.sectionIdFilter?.length ? (
        <>
          <div style={{ flex: 1, width: '600px', overflow: 'auto' }}>
            <FilterPillList
              selectedSections={selectedSections}
              rowIdFilters={rowIdFilters}
              sectionRowIdFilters={sectionRowIdFilters}
              applyRowFilterChanges={onRowIdFiltersChange}
              applySelectedSectionChanges={onSectionIdFiltersChange}
              disabled={disabled}
            />
          </div>

          <ButtonWithIcon
            variant="textPlain"
            shape="none"
            icon={
              <PlusIcon
                fill={IconsFill.textBrand}
                size={vars.iconSize.s}
                withHoverEffect
              />
            }
            textContentId={ContentId.EditSections}
            onClick={sectionSelectDialog.launchDialog}
            disabled={disabled}
            style={{ padding: 0 }}
          />
        </>
      ) : (
        venueMapSections && (
          <ButtonWithIcon
            variant="textPlain"
            shape="none"
            icon={
              <PlusIcon
                fill={IconsFill.textBrand}
                size={vars.iconSize.s}
                withHoverEffect
              />
            }
            textContentId={ContentId.SelectSections}
            onClick={sectionSelectDialog.launchDialog}
            disabled={disabled}
            style={{ padding: 0 }}
          />
        )
      )}

      <SectionSelectDialog
        {...sectionSelectDialog.dialogProps}
        initialSectionIds={
          compListingSelectedSectionSettings?.sectionIdFilter ?? []
        }
        onSave={(selectedSections) => {
          const compListingSelectedSectionSettingsNew = {
            ...compListingSelectedSectionSettings,
            sectionIdFilter: selectedSections.map((s) => s.id),
            rowIdFilter: compListingSelectedSectionSettings?.rowIdFilter ?? [],
            sectionRowIdFilter:
              compListingSelectedSectionSettings?.sectionRowIdFilter ?? {},
          };
          setCompListingSelectedSectionSettings(
            compListingSelectedSectionSettingsNew
          );
          sectionSelectDialog.closeDialog();
        }}
        onClosed={sectionSelectDialog.closeDialog}
      />
    </>
  );
};
