import { useFormContext } from 'react-hook-form';
import { getContent, useContentContext } from 'src/contexts/ContentContext';
import { VENUE_ZONE_SECTION_GROUP_AREA_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { SectionSectionGroup } from 'src/WebApiController';

import { VenueZoneMapFormType } from '../InventoryEventVenueZoneMapSection';
import * as styles from './VenueZoneSectionGroup.css';

export const VenueZoneSectionGroupList = () => {
  const contentContext = useContentContext();
  const { watch } = useFormContext<VenueZoneMapFormType>();

  const sectionSectionGroupLookup = watch('sectionSectionGroupLookup');
  const customSectionGroups = watch('customSectionGroups');

  const sectionGroups = Object.values(sectionSectionGroupLookup).reduce<{
    [key: string]: SectionSectionGroup;
  }>((acc, v) => {
    let sectionGroupName = `${v.ticketClassName}: ${v.sectionGroupAreaName}`;
    if (v.sectionGroupAreaType != null) {
      const sectionGroupAreaContentId =
        VENUE_ZONE_SECTION_GROUP_AREA_TYPE_TO_CID[v.sectionGroupAreaType];

      sectionGroupName = `${v.ticketClassName}: ${getContent(
        sectionGroupAreaContentId,
        contentContext
      )}`;
    }
    acc[sectionGroupName] = v;

    return acc;
  }, {});

  return (
    <div className={styles.venueZoneGroupsContainer}>
      {Object.entries(sectionGroups)
        .sort(
          ([, a], [, b]) =>
            a.sectionGroupRank - b.sectionGroupRank || a.areaRank - b.areaRank
        )
        .map(([key, value]) => (
          <div key={key} className={styles.venueZoneGroupContainer}>
            <div
              className={styles.venueZoneGroupColorBox}
              style={{ backgroundColor: value.sectionGroupColor }}
            />
            {key}
          </div>
        ))}

      {customSectionGroups.map((ssg) => (
        <div
          key={`${ssg.ticketClassName}-${ssg.sectionGroupAreaName}`}
          className={styles.venueZoneGroupContainer}
        >
          <div
            className={styles.venueZoneGroupColorBox}
            style={{ backgroundColor: ssg.sectionGroupColor }}
          />
          <span>{`${ssg.ticketClassName}: ${ssg.sectionGroupAreaName}`}</span>
        </div>
      ))}
    </div>
  );
};
