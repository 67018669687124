import { ColumnDef } from '@tanstack/react-table';
import { IconButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { DeleteIcon, EditIcon, PlusIcon } from 'src/svgs/Viagogo';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PurchasePaymentMethod,
  PurchasePaymentMethodType,
} from 'src/WebApiController';

export type PurchasePaymentActions = {
  editPurchasePayment?: (purchasePayment: PurchasePaymentMethod) => void;
  deletePurchasePayment: (purchasePaymentMethodId: number) => void;
};

export type PurchasePaymentMethodWithHandlers = {
  purchasePaymentMethod: PurchasePaymentMethod;
  purchasePaymentActions: PurchasePaymentActions;
};

export const PAYMENT_METHOD_TABLE_COLUMNS_CONFIG: ColumnDef<PurchasePaymentMethodWithHandlers | null>[] =
  [
    {
      id: 'paymentMethodDisplay',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.PaymentMethod} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.purchasePaymentMethod?.display,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
    {
      id: 'paymentMethodName',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Name} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.purchasePaymentMethod?.name,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
    {
      id: 'paymentMethodType',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Type} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.purchasePaymentMethod?.type,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as PurchasePaymentMethodType;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
    {
      id: 'paymentMethodActions',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      ),
      cell: ({ row: { original } }) => {
        return (
          <TableCellDiv align="right" showTooltip={false}>
            <Stack justifyContent="end">
              <IconButton
                icon={<EditIcon withHoverEffect />}
                onClick={() =>
                  original?.purchasePaymentActions.editPurchasePayment?.(
                    original.purchasePaymentMethod
                  )
                }
                titleContentId={ContentId.Edit}
              />
              <IconButton
                icon={<DeleteIcon withHoverEffect />}
                onClick={() =>
                  original?.purchasePaymentActions.deletePurchasePayment(
                    original.purchasePaymentMethod.id
                  )
                }
                titleContentId={ContentId.Edit}
              />
            </Stack>
          </TableCellDiv>
        );
      },
    },
  ];
