import { endOfDay, startOfDay } from 'date-fns';
import { Content } from 'src/contexts/ContentContext/Content';
import { useDealContext } from 'src/contexts/DealContext/DealContext';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosFormField } from 'src/core/POS/PosFormField';
import { ContentId } from 'src/utils/constants/contentId';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';

import * as styles from './Deals.css';

export const DealDateRangeSection = ({
  dealStartDate,
  dealEndDate,
}: {
  dealStartDate?: string | null;
  dealEndDate?: string | null;
}) => {
  const { updateLocalDealDetails } = useDealContext();

  return (
    <PosFormField
      label={
        <div className={styles.SectionTitle}>
          <Content id={ContentId.DateRange} />
        </div>
      }
    >
      <DatePickerInput
        fieldError={undefined}
        minDate={new Date()}
        date={dealStartDate ? new Date(dealStartDate) : undefined}
        onDateChange={(value) => {
          const startDate = startOfDay(value);
          const currentEndDate = dealEndDate ? new Date(dealEndDate) : null;
          updateLocalDealDetails({ dealStartDate: startDate.toISOString() });

          // Update end date only if it's less than the new start date
          if (currentEndDate && currentEndDate < startDate) {
            updateLocalDealDetails({
              dealEndDate: endOfDay(startDate).toISOString(),
            });
          }
        }}
        locale={getLocaleFromLanguageOrCurrent()}
      />
      <DatePickerInput
        fieldError={undefined}
        minDate={new Date()}
        date={dealEndDate ? new Date(dealEndDate) : undefined}
        onDateChange={(value) => {
          const endDate = endOfDay(value);
          const currentStartDate = dealStartDate
            ? new Date(dealStartDate)
            : null;
          updateLocalDealDetails({ dealEndDate: endDate.toISOString() });

          // Update start date only if it's less than the new end date
          if (currentStartDate && currentStartDate > endDate) {
            updateLocalDealDetails({
              dealStartDate: startOfDay(endDate).toISOString(),
            });
          }
        }}
        locale={getLocaleFromLanguageOrCurrent()}
      />
    </PosFormField>
  );
};
