import clsx from 'clsx';
import { ButtonWithIcon } from 'src/components/Buttons';
import { Stack } from 'src/core/ui';
import { ReloadIcon, WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './AutoPricingSettings.css';

const PricingSettingStatus: React.FC<
  Pick<PricingSettingProps, 'hasConflict' | 'hasChange' | 'onReset'>
> = ({ hasChange, hasConflict, onReset }) => {
  if (!hasChange && !hasConflict) {
    return undefined;
  }

  return (
    <ButtonWithIcon
      variant="textPlain"
      shape="none"
      className={clsx(styles.statusLabel, {
        [styles.statusLabelConflict]: hasConflict,
        [styles.statusLabelEdited]: hasChange,
      })}
      icon={
        hasChange ? (
          <ReloadIcon size="16px" />
        ) : (
          <WarningOutlineIcon size="16px" />
        )
      }
      textContentId={hasChange ? ContentId.Edited : ContentId.Conflict}
      disabled={!hasChange}
      onClick={hasChange ? onReset : undefined}
      titleContentId={hasChange ? ContentId.Reset : undefined}
    />
  );
};

type PricingSettingProps = {
  header: React.ReactNode;
  detail: React.ReactNode;
  status?: React.ReactNode;
  isHeader?: boolean;
  hasConflict?: boolean;
  hasChange?: boolean;
  onReset?: () => void;
  headerStyle?: React.CSSProperties;
};

export const PricingSetting: React.FC<PricingSettingProps> = ({
  isHeader,
  header,
  detail,
  status,
  hasChange,
  hasConflict,
  onReset,
  headerStyle,
}) => {
  return (
    <Stack
      direction="row"
      gap="m"
      alignItems="center"
      width="full"
      className={clsx(styles.container, {
        [styles.header]: isHeader,
      })}
    >
      <div className={styles.setting} style={headerStyle}>
        {header}
      </div>
      <div className={styles.details}>{detail}</div>
      <div className={styles.status}>
        {status ?? (
          <PricingSettingStatus
            hasChange={hasChange}
            hasConflict={hasConflict}
            onReset={onReset}
          />
        )}
      </div>
    </Stack>
  );
};
