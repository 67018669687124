import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useGetMarketplacePaymentById } from 'src/hooks/api/useGetMarketplacePaymentById';
import { useGetMarketplacePaymentLineTableData } from 'src/hooks/useGetMarketplacePaymentLineTableData';
import { MarketplacePaymentHeaderDetails } from 'src/modals/MaketplacePayments/MarketplacePaymentHeaderDetails';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { MarketplacePaymentLinesTable } from 'src/tables/MarketplacePaymentLinesTable/MarketplacePaymentLinesTable';
import { ContentId } from 'src/utils/constants/contentId';
import { SomethingWentWrong } from 'src/views';

interface MarketplacePaymentsDetailsModalBodyProps {
  marketplacePaymentId: number | undefined;
}

export const MarketplacePaymentsDetailsModalBody = ({
  marketplacePaymentId,
}: MarketplacePaymentsDetailsModalBodyProps) => {
  const {
    isLoading: isLoadingTableData,
    error,
    data,
  } = useGetMarketplacePaymentLineTableData(marketplacePaymentId);

  const { data: marketplacePayment, isLoading: isLoadingMarketplacePayment } =
    useGetMarketplacePaymentById(marketplacePaymentId);

  const hasData = !!data?.length;

  const isLoading = isLoadingTableData || isLoadingMarketplacePayment;

  return (
    <>
      {isLoading && <PosSpinner />}
      {error && (
        <SomethingWentWrong
          message={<Content id={ContentId.FailToLoadListContent} />}
        />
      )}
      {!isLoading && !error && (
        <>
          {marketplacePayment && (
            <MarketplacePaymentHeaderDetails
              marketplaceSalePayment={marketplacePayment}
            />
          )}
          {hasData ? (
            <>
              <MarketplacePaymentLinesTable tableData={data!} />
            </>
          ) : (
            <EmptySectionContent.Root
              icon={
                <EmptySectionContent.SolidIconContainer>
                  <SearchSolidIcon />
                </EmptySectionContent.SolidIconContainer>
              }
            >
              <EmptySectionContent.Label>
                <Content id={ContentId.NoResultFound} />
              </EmptySectionContent.Label>
            </EmptySectionContent.Root>
          )}
        </>
      )}
    </>
  );
};
