import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext/Content';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

import * as styles from './AccountAutoPricingSettings.css';
import { useAccountAutoPricingSettings } from './AccountAutoPricingSettings.hooks';
import { AccountAutoPricingSettingsForm } from './AccountAutoPricingSettingsForm';

export const AccountAutoPricingSettings = () => {
  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();

  const {
    accountAutoPricingSettings,
    onMergeAccountAutoPricingSettings,
    isLoading,
    isPending,
  } = useAccountAutoPricingSettings();

  const hasAllowSeatScoreCompListingModeFeature = useUserHasFeature(
    Feature.AllowSeatScoreCompListingMode
  );

  const hasPriceByWebsitePriceFeature = useUserHasFeature(
    Feature.PriceByWebsitePrice
  );

  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount?.currencyCode
  );

  return (
    <div className={styles.root}>
      <div className={styles.center}>
        <h1 className={styles.pageName}>
          <Content id={ContentId.Autopricing} />
        </h1>
        {isLoading ? (
          <PosSpinner />
        ) : (
          <AccountAutoPricingSettingsForm
            pricingSettings={accountAutoPricingSettings}
            uiCurrency={uiCurrency}
            disabled={isPending}
            onSaved={onMergeAccountAutoPricingSettings}
            allowSeatScoreCompListingMode={
              hasAllowSeatScoreCompListingModeFeature
            }
            allowPriceByWebsitePrice={hasPriceByWebsitePriceFeature}
          />
        )}
      </div>
    </div>
  );
};
