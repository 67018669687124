import { useQueryClient } from '@tanstack/react-query';
import {
  MutableRefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PAYMENT_SINGLE_MONTH_QUERY_KEY } from 'src/contexts/MarketplacePaymentDataContext/MarketplacePaymentDataContext.constants';
import { ConvertCurrencyDialog } from 'src/dialogs/ConvertCurrency';
import {
  ConvertCurrencyForm,
  CurrencyConversionPayment,
} from 'src/dialogs/ConvertCurrency/ConvertCurrencyDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { getConversionRate } from 'src/utils/currencyUtils';
import {
  Feature,
  MarketplacePaymentClient,
  MarketplaceSalePayment,
} from 'src/WebApiController';

export interface ConvertPaymentAmountRefActions {
  showConvertPaymentAmountDialog: () => void;
  currencyConversionPayment: CurrencyConversionPayment;
}

interface ConvertPaymentAmountProps {
  marketplaceSalePayment: MarketplaceSalePayment;
  convertPaymentAmountActionsRef?: MutableRefObject<ConvertPaymentAmountRefActions | null>;
}

export const ConvertPaymentAmount = ({
  convertPaymentAmountActionsRef,
  marketplaceSalePayment,
}: ConvertPaymentAmountProps) => {
  const [isSavingConversionRate, setIsSavingConversionRate] =
    useState<boolean>(false);
  const canConvertMarketplacePaymentLines = useUserHasFeature(
    Feature.MarketplacePaymentConversion
  );
  const queryClient = useQueryClient();
  const { activeAccountWebClientConfig } = useAppContext();
  const convertMarketplacePaymentDialog = useBasicDialog();
  const { loginContext } = useAppContext();
  const currentUserId = loginContext?.user?.userId!;

  const onCancel = useCallback(() => {
    convertMarketplacePaymentDialog.closeDialog();
  }, [convertMarketplacePaymentDialog]);
  const { showErrorDialog } = useErrorBoundaryContext();

  const onSave = useCallback(
    async (formValues: ConvertCurrencyForm) => {
      setIsSavingConversionRate(true);
      try {
        await new MarketplacePaymentClient(
          activeAccountWebClientConfig
        ).convertMarketplacePaymentAmount(
          marketplaceSalePayment.id,
          formValues.convertedPaymentAmount!.amt,
          formValues.convertedCurrencyCode
        );

        const isExpanded = true;
        queryClient.invalidateQueries({
          queryKey: [PAYMENT_SINGLE_MONTH_QUERY_KEY, isExpanded],
          refetchType: 'active',
        });

        convertMarketplacePaymentDialog.closeDialog();
      } catch (e) {
        console.error(e);
        showErrorDialog(
          'MarketplacePaymentClient.convertMarketplacePaymentAmount',
          e as Error
        );
      } finally {
        setIsSavingConversionRate(false);
      }
    },
    [
      activeAccountWebClientConfig,
      convertMarketplacePaymentDialog,
      marketplaceSalePayment.id,
      queryClient,
      showErrorDialog,
    ]
  );

  const currencyConversionPayment = useMemo<CurrencyConversionPayment>(() => {
    return {
      convertedPaymentAmount: marketplaceSalePayment.convertedPmtAmt,
      paymentAmount: marketplaceSalePayment.pmtAmt,
      conversionRate: marketplaceSalePayment.convertedPmtAmt
        ? Math.abs(
            getConversionRate(
              marketplaceSalePayment.pmtAmt.amt,
              marketplaceSalePayment.convertedPmtAmt.amt
            )
          )
        : null,
      convertedCurrencyCode:
        marketplaceSalePayment.convertedPmtAmt?.currency ?? null,
    };
  }, [marketplaceSalePayment.convertedPmtAmt, marketplaceSalePayment.pmtAmt]);

  useImperativeHandle(
    convertPaymentAmountActionsRef,
    () => ({
      showConvertPaymentAmountDialog: () =>
        convertMarketplacePaymentDialog.launchDialog(),
      currencyConversionPayment: currencyConversionPayment,
    }),
    [convertMarketplacePaymentDialog, currencyConversionPayment]
  );

  return (
    <>
      {canConvertMarketplacePaymentLines &&
        convertMarketplacePaymentDialog.dialogProps.isOpen &&
        currencyConversionPayment && (
          <ConvertCurrencyDialog
            {...convertMarketplacePaymentDialog.dialogProps}
            onSave={onSave}
            onCancel={onCancel}
            payment={currencyConversionPayment}
            currentUserId={currentUserId}
            isLoading={isSavingConversionRate}
          />
        )}
    </>
  );
};
