/* eslint-disable react-hooks/exhaustive-deps */
import { HubConnectionState } from '@microsoft/signalr';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useBulkEditHubContext } from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  Event,
  Listing,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdatesV2,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';
import {
  AutoPricingSettingsInput,
  PricingSettingsUpdateKey,
} from './BulkEditAutoPricingSettingsDialog.types';
import {
  generateDefaultSettings,
  getAutoPricingSettingsUpdateInput,
  getSettingsInputChanged,
  santizedPricingSettingsUpdateInput,
} from './BulkEditAutoPricingSettingsDialog.utils';
import { AutoPricingSettings } from './components/AutoPricingSettings';
import { PricingSettingsPreview } from './PricingSettingsPreview';

export type BulkEditAutoPricingSettingsDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  isLoading?: boolean;
  listings?: Listing[] | null;
  selectedListingIds?: number[];
  event?: Event;
  onOkay: (
    input: ListingDetailsAutoPricingSectionUpdatesV2 | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel?: () => void;
};

export const BulkEditAutoPricingSettingsDialog: React.FC<
  BulkEditAutoPricingSettingsDialogProps
> = ({ listings, selectedListingIds, ...rest }) => {
  const { loginContext } = useAppContext();
  const selectedListings = useMemo(() => {
    const flattenedListings = listings?.flatMap((l) => flattenListingGroup(l));
    return (
      flattenedListings
        ?.filter((l) => l.isFull && selectedListingIds?.includes(l.id))
        .map((l) => l as Listing) ?? []
    );
  }, [listings, selectedListingIds]);

  const settingsInput = useMemo(
    () => generateDefaultSettings(selectedListings),
    [selectedListings]
  );

  const defaultValues = useMemo(
    () =>
      getAutoPricingSettingsUpdateInput(
        settingsInput,
        loginContext?.user?.activeAccount?.currencyCode ?? null
      ),
    [settingsInput, loginContext?.user?.activeAccount?.currencyCode]
  );
  const methods = useForm<ListingDetailsAutoPricingSectionUpdatesV2>({
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <BulkEditAutoPricingSettingsDialogContent
        listings={listings}
        settingsInput={settingsInput}
        defaultInput={defaultValues}
        {...rest}
      />
    </FormProvider>
  );
};

function BulkEditAutoPricingSettingsDialogContent({
  updateKey,
  isLoading,
  event,
  onOkay,
  onCancel,
  settingsInput,
  defaultInput,
  ...rest
}: BulkEditAutoPricingSettingsDialogProps & {
  settingsInput: AutoPricingSettingsInput;
  defaultInput: ListingDetailsAutoPricingSectionUpdatesV2;
}) {
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();

  const [changedFields, setChangedFileds] =
    useState<Partial<Record<PricingSettingsUpdateKey, boolean | undefined>>>();
  const { handleSubmit, watch, reset, formState } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();

  const input = watch();

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);
        await refreshExpandedListItems();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel?.();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.UpdateAutoPricingSettings,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      if (onPreviewReceived) {
        onOkay(null, false, onPreviewReceived);
      } else {
        handleSubmit((i) => {
          onOkay(
            santizedPricingSettingsUpdateInput(i, changedFields),
            hasBackgroundBulkEditFeature,
            undefined,
            preview
          );
        })();
      }
    },
    [onOkay, changedFields, preview, hasBackgroundBulkEditFeature]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    const changed = getSettingsInputChanged(
      input,
      formState.defaultValues as ListingDetailsAutoPricingSectionUpdatesV2,
      settingsInput
    );

    setChangedFileds(changed);
  }, [
    JSON.stringify(input),
    JSON.stringify(formState.defaultValues),
    JSON.stringify(settingsInput),
  ]);

  const hasChanges = useMemo(
    () => Object.values(changedFields ?? {}).some((value) => !!value),
    [changedFields]
  );

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  const onUpdateChangedFields = useCallback(
    (
      values: Partial<Record<PricingSettingsUpdateKey, boolean | undefined>>
    ) => {
      setChangedFileds((prev) => ({ ...prev, ...values }));
    },
    [setChangedFileds]
  );

  return (
    <>
      <GenericDialog
        {...rest}
        size={'xl'}
        header={
          <BulkEditHeader
            headerText={<Content id={ContentId.AutopricingSettings} />}
          />
        }
        onOpened={() => {
          reset(defaultInput);
          initJob();
        }}
        onKeyUp={(e) => {
          if (submittButtonRef.current && e.key === 'Enter' && hasChanges) {
            submittButtonRef.current.click();
          }
        }}
        onClosed={() => {
          setMainDialogOpened(false);
          if (progress) {
            setSelectionMode(undefined);
          }

          rest.onClosed?.();
        }}
        footer={
          <BulkEditFooter
            entityType={ActionOutboxEntityType.Listing}
            isLoading={isLoading}
            hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
            onClose={onClose}
            onSubmit={onSubmitHandler}
            disabled={!hasChanges}
            submittButtonRef={submittButtonRef}
          />
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <EventMapContextProvider event={event}>
          <BulkEditStatus
            entityType={ActionOutboxEntityType.Listing}
            isLoading={isRefreshing || isLoading}
            updateKey={updateKey}
            height="fit"
            entityUpdatesPreview={
              <PricingSettingsPreview
                settingsInput={settingsInput}
                defaultValues={
                  formState.defaultValues as ListingDetailsAutoPricingSectionUpdatesV2
                }
                updates={input}
                changedFields={changedFields}
                updateChangedFields={onUpdateChangedFields}
              />
            }
          >
            <Stack direction="column" width="full" gap="s">
              <AutoPricingSettings settingsInput={settingsInput} />
            </Stack>
          </BulkEditStatus>
        </EventMapContextProvider>
      </GenericDialog>
    </>
  );
}
