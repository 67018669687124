import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { SalesDetailsSection } from 'src/modals/ListingDetails/ListingForms/SalesForm/SalesDetailsSection';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Feature,
  ListingDetails,
  PointOfSaleSaleStatus,
  Ticket,
} from 'src/WebApiController';

type ListingDetailsSalesFormProps = {
  listing?: ListingDetails | null;
};

export const ListingDetailsSalesForm: React.FC<
  ListingDetailsSalesFormProps
> = ({ listing }) => {
  const hasWasteSaleFeature = useUserHasFeature(Feature.WasteSale);
  const { tickets = [] } = listing ?? {};
  const ticketsWithSales = tickets?.filter((ticket) => ticket.id);

  const ticketsBySalesId = ticketsWithSales?.reduce<Record<string, Ticket[]>>(
    (acc, curr) => {
      if (!curr.id) return acc;
      const sale = acc[curr.id] ?? [];
      return { ...acc, [curr.id]: [...sale, curr] };
    },
    {}
  );

  const wastedSales = listing?.saleInfos?.filter(
    (saleInfo) => saleInfo.stat === PointOfSaleSaleStatus.Wasted
  );

  const nonWastedSales = listing?.saleInfos?.filter(
    (saleInfo) => saleInfo.stat !== PointOfSaleSaleStatus.Wasted
  );

  return (
    <>
      <SalesDetailsSection
        ticketsBySalesId={ticketsBySalesId}
        sectionName={ContentId.Sales}
        seating={listing?.seating}
        saleInfos={nonWastedSales}
      />
      {hasWasteSaleFeature && (
        <SalesDetailsSection
          ticketsBySalesId={ticketsBySalesId}
          sectionName={ContentId.Wasted}
          seating={listing?.seating}
          saleInfos={wastedSales}
        />
      )}
    </>
  );
};
