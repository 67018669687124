import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { useDefaultPaymentsColumnConfig } from 'src/tables/PaymentsTable/useDefaultPaymentsColumnConfig';
import {
  defaultEventsColumnsConfig,
  defaultListingColumnsConfig,
  defaultListingFlattenedColumnsConfig,
  defaultPurchaseEventEnabledColumnsConfig,
  defaultPurchasesColumnsConfig,
  defaultSalesColumnsConfig,
  defaultSalesFlattenedColumnsConfig,
} from 'src/utils/columns/columnUtils';
import { CustomEventsColumn } from 'src/utils/columns/events/eventsCustomColumnUtils.types';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { CustomMarketplacePaymentsColumn } from 'src/utils/columns/payments/paymentsCustomColumnUtils.types';
import { CustomPurchasesColumn } from 'src/utils/columns/purchases/purchasesCustomColumnUtils.types';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { SectionType } from 'src/utils/types/sectionType';
import { UserSetting } from 'src/WebApiController';

export const useColumnServerUserSettings = <
  T extends
    | CustomEventsColumn
    | CustomListingColumn
    | CustomPurchasesColumn
    | CustomSalesColumn
    | CustomMarketplacePaymentsColumn,
>({
  sectionType,
}: {
  sectionType: SectionType;
}) => {
  const {
    value: storedEventsColumnOrderSetting = defaultEventsColumnsConfig,
    setUserSetting: setEventsColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.EventsColumnOrder,
  });
  const {
    value: storedInventoryColumnOrderSetting = defaultListingColumnsConfig,
    setUserSetting: setInventoryColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryColumnOrder,
  });
  const {
    value:
      storedInventoryFlattenedColumnOrderSetting = defaultListingFlattenedColumnsConfig,
    setUserSetting: setInventoryFlattenedColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryFlattenedColumnOrder,
  });
  const {
    value:
      storedInventorySideTableColumnOrderSetting = defaultListingColumnsConfig,
    setUserSetting: setInventorySideTableColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventorySideTableColumnOrder,
  });
  const {
    value: storedSaleColumnOrderSetting = defaultSalesColumnsConfig,
    setUserSetting: setSaleColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SaleColumnOrder,
  });
  const {
    value:
      storedSaleFlattenedColumnOrderSetting = defaultSalesFlattenedColumnsConfig,
    setUserSetting: setSaleFlattenedColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SaleFlattenedColumnOrder,
  });
  const {
    value: storedSaleSideTableColumnOrderSetting = defaultSalesColumnsConfig,
    setUserSetting: setSaleSideTableColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SalesSideTableColumnOrder,
  });
  const {
    value: storedPurchaseColumnOrderSetting = defaultPurchasesColumnsConfig,
    setUserSetting: setPurchaseColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseColumnOrder,
  });
  const {
    value:
      storedPurchaseEventColumnOrderSetting = defaultPurchaseEventEnabledColumnsConfig,
    setUserSetting: setPurchaseEventColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseEventColumnOrder,
  });
  const {
    value: storedPurchaseSideTableOrderSetting = defaultPurchasesColumnsConfig,
    setUserSetting: setPurchaseSideTableOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseSideTableColumnOrder,
  });

  const defaultPaymentsColumnsConfig = useDefaultPaymentsColumnConfig();
  const {
    value: storedPaymentsTableOrderSetting = defaultPaymentsColumnsConfig,
    setUserSetting: setPaymentsTableOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.MarketplacePaymentsTableColumnOrder,
  });

  const storedColumnOrderSetting = {
    [SectionType.Events]: storedEventsColumnOrderSetting,
    [SectionType.Listings]: storedInventoryColumnOrderSetting,
    [SectionType.ListingsFlattened]: storedInventoryFlattenedColumnOrderSetting,
    [SectionType.InventorySideTable]:
      storedInventorySideTableColumnOrderSetting,
    [SectionType.ListingsReport]: [],
    [SectionType.Sales]: storedSaleColumnOrderSetting,
    [SectionType.SalesFlattened]: storedSaleFlattenedColumnOrderSetting,
    [SectionType.SalesReport]: [],
    [SectionType.SalesSideTable]: storedSaleSideTableColumnOrderSetting,
    [SectionType.Purchases]: storedPurchaseColumnOrderSetting,
    [SectionType.PurchaseEvent]: storedPurchaseEventColumnOrderSetting,
    [SectionType.PurchaseSideTable]: storedPurchaseSideTableOrderSetting,
    [SectionType.MarketplacePaymentsTable]: storedPaymentsTableOrderSetting,
  }[sectionType];

  const setColumnOrderSetting = {
    [SectionType.Events]: setEventsColumnOrderSetting,
    [SectionType.Listings]: setInventoryColumnOrderSetting,
    [SectionType.ListingsFlattened]: setInventoryFlattenedColumnOrderSetting,
    [SectionType.ListingsReport]: () => console.log('Unused'),
    [SectionType.InventorySideTable]: setInventorySideTableColumnOrderSetting,
    [SectionType.Sales]: setSaleColumnOrderSetting,
    [SectionType.SalesFlattened]: setSaleFlattenedColumnOrderSetting,
    [SectionType.SalesReport]: () => console.log('Unused'),
    [SectionType.SalesSideTable]: setSaleSideTableColumnOrderSetting,
    [SectionType.Purchases]: setPurchaseColumnOrderSetting,
    [SectionType.PurchaseEvent]: setPurchaseEventColumnOrderSetting,
    [SectionType.PurchaseSideTable]: setPurchaseSideTableOrderSetting,
    [SectionType.MarketplacePaymentsTable]: setPaymentsTableOrderSetting,
  }[sectionType];

  const {
    value: customEventsColumns = [],
    setUserSetting: setCustomEventsColumns,
  } = useServerUserSetting<CustomEventsColumn[]>({
    id: UserSetting.EventsCustomColumns,
  });
  const {
    value: customListingColumns = [],
    setUserSetting: setCustomListingColumns,
  } = useServerUserSetting<CustomListingColumn[]>({
    id: UserSetting.InventoryCustomColumns,
  });
  const {
    value: customSaleColumns = [],
    setUserSetting: setCustomSaleColumns,
  } = useServerUserSetting<CustomSalesColumn[]>({
    id: UserSetting.SaleCustomColumns,
  });
  const {
    value: customPurchaseColumns = [],
    setUserSetting: setCustomPurchaseColumns,
  } = useServerUserSetting<CustomPurchasesColumn[]>({
    id: UserSetting.PurchaseCustomColumns,
  });
  const {
    value: customPaymentsColumns = [],
    setUserSetting: setCustomPaymentsColumns,
  } = useServerUserSetting<CustomMarketplacePaymentsColumn[]>({
    id: UserSetting.MarketplacePaymentsCustomColumns,
  });

  const setCustomColumns = {
    [SectionType.Events]: setCustomEventsColumns,
    [SectionType.Listings]: setCustomListingColumns,
    [SectionType.ListingsFlattened]: setCustomListingColumns,
    [SectionType.ListingsReport]: () => console.log('Unused'),
    [SectionType.InventorySideTable]: setCustomListingColumns,
    [SectionType.Sales]: setCustomSaleColumns,
    [SectionType.SalesFlattened]: setCustomSaleColumns,
    [SectionType.SalesReport]: () => console.log('Unused'),
    [SectionType.SalesSideTable]: setCustomSaleColumns,
    [SectionType.Purchases]: setCustomPurchaseColumns,
    [SectionType.PurchaseEvent]: setCustomPurchaseColumns,
    [SectionType.PurchaseSideTable]: setCustomPurchaseColumns,
    [SectionType.MarketplacePaymentsTable]: setCustomPaymentsColumns,
  }[sectionType] as (value: T[]) => void;

  const customColumns = {
    [SectionType.Events]: customEventsColumns,
    [SectionType.Listings]: customListingColumns,
    [SectionType.ListingsFlattened]: customListingColumns,
    [SectionType.ListingsReport]: [], // Unused
    [SectionType.InventorySideTable]: customListingColumns,
    [SectionType.Sales]: customSaleColumns,
    [SectionType.SalesFlattened]: customSaleColumns,
    [SectionType.SalesReport]: [], // Unused
    [SectionType.SalesSideTable]: customSaleColumns,
    [SectionType.Purchases]: customPurchaseColumns,
    [SectionType.PurchaseEvent]: customPurchaseColumns,
    [SectionType.PurchaseSideTable]: customPurchaseColumns,
    [SectionType.MarketplacePaymentsTable]: customPaymentsColumns,
  };

  const {
    value: storedEventsColumnsEnabledSetting,
    setUserSetting: setEventsColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.EventsColumnsEnabled,
  });
  const {
    value: storedInventoryColumnsEnabledSetting,
    setUserSetting: setInventoryColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryColumnsEnabled,
  });
  const {
    value: storedInventoryFlattenedColumnsEnabledSetting,
    setUserSetting: setInventoryFlattenedColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryFlattenedColumnsEnabled,
  });
  const {
    value: storedInventorySideTableColumnsEnabledSetting,
    setUserSetting: setInventorySideTableColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventorySideTableColumnsEnabled,
  });
  const {
    value: storedSaleColumnsEnabledSetting,
    setUserSetting: setSaleColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SaleColumnsEnabled,
  });
  const {
    value: storedSaleFlattenedColumnsEnabledSetting,
    setUserSetting: setSaleFlattenedColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SaleFlattenedColumnsEnabled,
  });
  const {
    value: storedSaleSideTableColumnsEnabledSetting,
    setUserSetting: setSaleSideTableColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SalesSideTableColumnsEnabled,
  });
  const {
    value: storedPurchaseColumnsEnabledSetting,
    setUserSetting: setPurchaseColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseColumnsEnabled,
  });
  const {
    value:
      storedPurchaseEventColumnsEnabledSetting = defaultPurchaseEventEnabledColumnsConfig,
    setUserSetting: setPurchaseEventColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseEventColumnsEnabled,
  });
  const {
    value: storedPurchaseSideTableColumnsEnabledSetting,
    setUserSetting: setPurchaseSideTableColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseSideTableColumnsEnabled,
  });
  const {
    value: storedPaymentsTableColumnsEnabledSetting,
    setUserSetting: setPaymentsTableColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.MarketplacePaymentsTableColumnsEnabled,
  });

  const storedColumnsEnabledSetting = {
    [SectionType.Events]: storedEventsColumnsEnabledSetting,
    [SectionType.Listings]: storedInventoryColumnsEnabledSetting,
    [SectionType.ListingsFlattened]:
      storedInventoryFlattenedColumnsEnabledSetting,
    [SectionType.ListingsReport]: [],
    [SectionType.InventorySideTable]:
      storedInventorySideTableColumnsEnabledSetting,
    [SectionType.Sales]: storedSaleColumnsEnabledSetting,
    [SectionType.SalesFlattened]: storedSaleFlattenedColumnsEnabledSetting,
    [SectionType.SalesReport]: [],
    [SectionType.SalesSideTable]: storedSaleSideTableColumnsEnabledSetting,
    [SectionType.Purchases]: storedPurchaseColumnsEnabledSetting,
    [SectionType.PurchaseEvent]: storedPurchaseEventColumnsEnabledSetting,
    [SectionType.PurchaseSideTable]:
      storedPurchaseSideTableColumnsEnabledSetting,
    [SectionType.MarketplacePaymentsTable]:
      storedPaymentsTableColumnsEnabledSetting,
  }[sectionType];

  const setColumnsEnabledSetting = {
    [SectionType.Events]: setEventsColumnsEnabledSetting,
    [SectionType.Listings]: setInventoryColumnsEnabledSetting,
    [SectionType.ListingsFlattened]: setInventoryFlattenedColumnsEnabledSetting,
    [SectionType.ListingsReport]: () => console.log('Unused'),
    [SectionType.InventorySideTable]:
      setInventorySideTableColumnsEnabledSetting,
    [SectionType.Sales]: setSaleColumnsEnabledSetting,
    [SectionType.SalesFlattened]: setSaleFlattenedColumnsEnabledSetting,
    [SectionType.SalesReport]: () => console.log('Unused'),
    [SectionType.SalesSideTable]: setSaleSideTableColumnsEnabledSetting,
    [SectionType.Purchases]: setPurchaseColumnsEnabledSetting,
    [SectionType.PurchaseEvent]: setPurchaseEventColumnsEnabledSetting,
    [SectionType.PurchaseSideTable]: setPurchaseSideTableColumnsEnabledSetting,
    [SectionType.MarketplacePaymentsTable]:
      setPaymentsTableColumnsEnabledSetting,
  }[sectionType];

  return {
    storedColumnOrderSetting,
    setColumnOrderSetting,
    customColumns,
    setCustomColumns,
    storedColumnsEnabledSetting,
    setColumnsEnabledSetting,
  };
};
