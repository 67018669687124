import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { VenueZoneSectionGroupDropdownList } from 'src/components/Listings/InventoryEventPage/Sections/VenueZoneMap/components/VenueZoneSectionGroupDropdownList';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionInfo, SectionSectionGroup } from 'src/WebApiController';

import * as styles from './ChangeZoneMapSectionGroupDialog.css';

export type ChangeZoneMapDialogProps = ComponentProps<typeof RSModal> & {
  selectedSections: SectionInfo[] | undefined;
  sectionIdMapping: { [key: string]: SectionSectionGroup } | undefined;
  onOkay: (
    sectionSectionGroup: SectionSectionGroup,
    sectionIds: number[]
  ) => void;
  onCancel: () => void;
};

export function ChangeZoneMapSectionGroupDialog({
  selectedSections,
  sectionIdMapping,
  onOkay,
  onCancel,
  ...rest
}: ChangeZoneMapDialogProps) {
  const [selectedSectionSectionGroup, setSelectedSectionSectionGroup] =
    useState<SectionSectionGroup | null>(null);

  const selectedSection = useMemo(() => {
    return selectedSections?.[0];
  }, [selectedSections]);

  useEffect(() => {
    const sectionId = selectedSection?.id;
    const sectionSectionGroup =
      sectionIdMapping && sectionId ? sectionIdMapping[sectionId] : undefined;

    if (sectionSectionGroup) {
      setSelectedSectionSectionGroup(sectionSectionGroup);
    }
  }, [sectionIdMapping, selectedSection?.id]);

  if (!selectedSections?.length || !selectedSection) {
    return null;
  }

  return (
    <>
      <GenericDialog
        {...rest}
        size="m"
        header={<Content id={ContentId.ChangeZone} />}
        footer={
          <>
            <Button className={styles.cancelButton} onClick={onCancel}>
              <Content id={ContentId.Cancel} />
            </Button>
            <Button
              disabled={selectedSectionSectionGroup == null}
              onClick={() => {
                if (selectedSectionSectionGroup != null) {
                  onOkay(
                    selectedSectionSectionGroup,
                    selectedSections!.map((s) => s.id)
                  );
                }
              }}
            >
              <Content id={ContentId.Save} />
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        <div>
          <Content id={ContentId.ChangeGroup} />
        </div>
        {
          <VenueZoneSectionGroupDropdownList
            selectedSectionSectionGroup={selectedSectionSectionGroup}
            setSelectedSectionSectionGroup={setSelectedSectionSectionGroup}
          />
        }
      </GenericDialog>
    </>
  );
}
