import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import { Feature, ListingGroup } from 'src/WebApiController';

import { ListingGroupAutoPricingSettingsFormContent } from '../ListingGroupAutoPricingSettingsFormContent/ListingGroupAutoPricingSettingsFormContent';

export const ListingGroupAutoPricingSettingsFormContentWrapper = ({
  listingGroup,
  disabled,
}: {
  listingGroup: ListingGroup | null | undefined;
  disabled: boolean;
}) => {
  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();

  const uiCurrency = useMemo(
    () =>
      getUiCurrency(
        listingGroup?.currency ??
          loginContext?.user?.activeAccount?.currencyCode
      ),
    [
      getUiCurrency,
      listingGroup?.currency,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );

  const { activeConfigOverride, venueMapInfo, venueMapsByScoreModelQuery } =
    useEventMapContext();

  const defaultScoreOverrides = useMemo(() => {
    // In the backend, if AVG scores are missing, then we always fallback to MVG, even if AVG is explicitly selected
    return (
      venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores
    );
  }, [
    venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores,
  ]);

  const scores = getCompleteEventConfigScoreOverrides(
    defaultScoreOverrides,
    activeConfigOverride?.scoreOverrides,
    false
  );

  const hasAllowSeatScoreCompListingModeFeature = useUserHasFeature(
    Feature.AllowSeatScoreCompListingMode
  );

  return (
    <ListingGroupAutoPricingSettingsFormContent
      venueMapSections={venueMapInfo?.sections}
      hasSeatScores={Boolean(scores?.some((s) => s.score))}
      uiCurrency={uiCurrency}
      allowSeatScoreCompListingMode={hasAllowSeatScoreCompListingModeFeature}
      disabled={disabled}
    />
  );
};
