import { useQuery } from '@tanstack/react-query';
import { ComponentProps, useCallback, useContext } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { BodySectionTitle, FieldError } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';
import {
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
} from 'src/WebApiController';

import { DuplicateSeatDialogEntry } from './DuplicateSeatDialogEntry';

export type DuplicateSeatsSelectionDialogProps = ComponentProps<
  typeof RSModal
> & {
  onCancel: () => void;
};

export function DuplicateSeatsSelectionDialog({
  onCancel,
  ...rest
}: DuplicateSeatsSelectionDialogProps) {
  const { posEntity: listing, setActivePosEntity } =
    useActivePosEntityContext<ListingDetails>();
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { setModal } = useContext(ModalContext);

  const shouldQuery =
    listing?.id != null && activeAccountWebClientConfig.activeAccountId != null;
  const {
    data: duplicateListingInfo,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      'ListingClient.GetDuplicateListingsForListingIds',
      activeAccountWebClientConfig,
      listing?.id,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const data = await new ListingClient(
        activeAccountWebClientConfig
      ).getDuplicateListingsForListingId(listing?.id);

      return data;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog(
          'ListingClient.GetDuplicateListingsForListingIds',
          error,
          {
            trackErrorData: {
              activeAccountId: activeAccountWebClientConfig.activeAccountId,
            },
          }
        );
      },
    },
  });

  const activeListingInfo = duplicateListingInfo?.[0];

  const onDuplicateListingClick = useCallback(
    (listingId: number) => {
      setActivePosEntity(listingId, null, false, [
        ListingDetailDataField.Basic,
      ]);
      setModal(getListingDetailsModalConfigWithDeepLink(listingId, true));
    },
    [setActivePosEntity, setModal]
  );

  return (
    <GenericDialog
      {...rest}
      size="lg"
      header={<Content id={ContentId.DuplicateSeat} />}
      footer={
        <>
          <CancelButton onClick={onCancel} textContentId={ContentId.Close} />
        </>
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      {isLoading || !listing ? (
        <PosSpinner />
      ) : error ? (
        <FieldError>Failed fetching duplicate listings</FieldError>
      ) : (
        <Stack direction="column" gap="l" width="full">
          <BodySectionTitle style={{ paddingBlockEnd: 0 }}>
            <Content id={ContentId.Current} />
          </BodySectionTitle>
          <FormatContent
            id={FormatContentId.DuplicateListingDetails}
            params={[String(listing.dupListingId) ?? '']}
          />
          {activeListingInfo && (
            <DuplicateSeatDialogEntry
              activeListing={listing}
              listingId={activeListingInfo.listingId}
              lastUpdateDate={new Date(activeListingInfo.lastUpdateDate)}
              onClick={onDuplicateListingClick}
            />
          )}
          <BodySectionTitle style={{ paddingBlockEnd: 0 }}>
            <Content id={ContentId.Duplicates} />
          </BodySectionTitle>
          {duplicateListingInfo?.map((info) => (
            <DuplicateSeatDialogEntry
              activeListing={listing}
              key={info.duplicateListingId}
              listingId={info.duplicateListingId}
              lastUpdateDate={new Date(info.duplicateListingLastUpdateDate)}
              onClick={onDuplicateListingClick}
            />
          ))}
        </Stack>
      )}
    </GenericDialog>
  );
}
