import { useCallback, useContext, useMemo } from 'react';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext/ModalContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ProceedsCell } from 'src/tables/SalesTable/configs/ProceedsCell';
import { SeatInfoContainer } from 'src/tables/Table';
import { DateCell, InHandCell } from 'src/tables/Table/TableCells';
import {
  getSaleActionButton,
  getSaleDetailsModalConfigWithDeepLink,
  getSaleStatusDisplayContentId,
  NON_MAIN_SALE_ACTIONS,
} from 'src/utils/saleUtils';
import { getTicketTypeIcon } from 'src/utils/ticketTypeUtils';
import {
  ListingDetailDataField,
  Permission,
  PointOfSaleSaleStatus,
  Sale,
  SaleDetails,
  TicketType,
} from 'src/WebApiController';

import {
  TileHeader,
  TileLeftLayout,
  TileRightLayout,
} from '../Tile/Tile.styled';
import * as styles from './SaleTiles.css';
import {
  OtherDetailsDiv,
  Price,
  SaleEventTileWrapper,
  SaleTilesContainer,
  Status,
} from './SaleTiles.styled';

const SaleTile = ({ sale }: { sale: Sale }) => {
  const ticketTypeIcon = getTicketTypeIcon(
    sale?.ticketType as TicketType,
    IconsFill.textPrimary
  );

  const { setActivePosEntity } = useActivePosEntityContext<SaleDetails>();
  const { setModal } = useContext(ModalContext);
  const onViewClick = useCallback(async () => {
    setActivePosEntity(sale.id, sale.idOnMkp, true, [
      ListingDetailDataField.Basic,
    ]);
    setModal(getSaleDetailsModalConfigWithDeepLink(sale.id, true));
  }, [sale.idOnMkp, setActivePosEntity, setModal, sale.id]);

  const hasTotalNetProceedsPermissions = useUserHasAnyOfPermissions(
    Permission.Sales_ViewProceeds,
    Permission.Sales_ViewRecentProceeds
  );

  const mainActionTypes = useMemo(
    () => sale.actions.filter((a) => !NON_MAIN_SALE_ACTIONS.includes(a)),
    [sale.actions]
  );

  const actions = sale
    ? sale.status === PointOfSaleSaleStatus.PendingConfirmation
      ? [
          getSaleActionButton(
            sale.id,
            sale.idOnMkp,
            sale.mkp,
            sale.status,
            mainActionTypes[0],
            sale.ticketType,
            sale.isNoFulfill,
            'text',
            false
          ),
          getSaleActionButton(
            sale.id,
            sale.idOnMkp,
            sale.mkp,
            sale.status,
            mainActionTypes[1],
            sale.ticketType,
            sale.isNoFulfill,
            'text',
            false
          ),
        ]
      : mainActionTypes.length > 0
      ? [
          getSaleActionButton(
            sale.id,
            sale.idOnMkp,
            sale.mkp,
            sale.status,
            mainActionTypes[mainActionTypes.length - 1],
            sale.ticketType,
            sale.isNoFulfill,
            'text',
            false
          ),
        ]
      : []
    : [];

  return (
    <SaleEventTileWrapper onClick={onViewClick}>
      <TileHeader>
        <TileLeftLayout>
          <div className={styles.detailsDiv}>
            {ticketTypeIcon}
            <SeatInfoContainer>
              <SeatingInfo {...sale?.seating} isSeatSaver={sale?.isSeatSaver} />
            </SeatInfoContainer>
          </div>
        </TileLeftLayout>
        <TileRightLayout>
          <InHandCell
            date={sale.inHandAt ? new Date(sale.inHandAt) : null}
            needsToFulfill={Boolean(
              sale.status &&
                ![
                  PointOfSaleSaleStatus.ProcessingFulfillment,
                  PointOfSaleSaleStatus.Fulfilled,
                  PointOfSaleSaleStatus.CancelledHold,
                  PointOfSaleSaleStatus.Rejected,
                  PointOfSaleSaleStatus.PendingRejection,
                ].includes(sale.status)
            )}
            handleFulfillmentState
          />
          <span>
            {actions.length > 0 ? (
              <PosDropdown
                trigger={
                  <MoreIcon
                    withHoverEffect
                    fill={IconsFill.textBrand}
                    align="middle"
                  />
                }
                align="end"
              >
                {actions.map((a, i) => (
                  <PosDropdownItem key={i}>{a}</PosDropdownItem>
                ))}
              </PosDropdown>
            ) : null}
          </span>
        </TileRightLayout>
      </TileHeader>
      <span className={styles.saleBody}>
        <TileLeftLayout>
          <OtherDetailsDiv>
            <Status>
              <Content
                id={getSaleStatusDisplayContentId(
                  sale.status,
                  sale.mkpContrState,
                  sale.mkp
                )}
              />
            </Status>
            <DateCell date={sale.saleDate} align="left" enableUtcFallback />
          </OtherDetailsDiv>
        </TileLeftLayout>
        <TileRightLayout columns={1}>
          {hasTotalNetProceedsPermissions && (
            <Price>
              <ProceedsCell
                displayValue={sale?.ttlNetProcs?.disp ?? undefined}
                margin={undefined}
              />
            </Price>
          )}
        </TileRightLayout>
      </span>
    </SaleEventTileWrapper>
  );
};

export const SaleTiles = ({ sales }: { sales?: Sale[] | null }) => {
  return sales ? (
    <SaleTilesContainer>
      {sales.map((sale) => (
        <SaleTile key={sale.id} sale={sale} />
      ))}
    </SaleTilesContainer>
  ) : (
    <PosSpinner size={vars.iconSize.s} />
  );
};
