import { isEqual } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { InHandDateDisplay } from 'src/components/common/InHandDateDisplay';
import { InHandDateInput } from 'src/components/Input/InHandDateInput';
import { DeliveryTypeSelector } from 'src/components/Selectors/DeliveryTypeSelector';
import { Content } from 'src/contexts/ContentContext';
import { Stack, Switch } from 'src/core/ui';
import { AlertWithSuppressionDialog } from 'src/dialogs/AlertWithSuppressionDialog';
import { useDeliveryInfoChangeDialog } from 'src/dialogs/AlertWithSuppressionDialog/useDeliveryInfoChangeDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useAnyMarketplaceHandledByProcessor } from 'src/hooks/useMarketplaceHandledByProcessor';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  useUserCanSetDeliveryStrategy,
  useUserCanUpdateListing,
  useUserCanViewPredelivery,
} from 'src/hooks/useUserHasListingPermissions';
import {
  Detail,
  DetailGroup,
  DetailSection,
  SectionContent,
} from 'src/modals/common';
import { ViewTickets } from 'src/modals/ViewTickets';
import { ContentId } from 'src/utils/constants/contentId';
import { DELIVERY_OPTIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isDatePassedHours } from 'src/utils/dateTimeUtils';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import {
  Event,
  Feature,
  ListingDetails,
  ListingDetailsTicketSectionUpdates,
  PreDeliveryState,
} from 'src/WebApiController';

import { TicketTypePrioritySection } from './TicketTypePrioritySection';

export const DeliverySection = ({
  listing,
  disabled: externalDisabled,
  event,
  isBulkEdit,
}: {
  event?: Event | null;
  listing?: ListingDetails | null;
  disabled?: boolean;
  isBulkEdit?: boolean;
}) => {
  const hasAutoFulfillFeature = useUserHasFeature(Feature.AutoFulfillSetting);
  const isAnyHandledByProcessor = useAnyMarketplaceHandledByProcessor();

  const { watch, setValue } =
    useFormContext<ListingDetailsTicketSectionUpdates>();

  const isMobile = useMatchMedia('mobile');
  const inHandAt = watch('inHandDate');
  const isNoFulfill = watch('isNoFulfill');
  const updateSalesOfListings = watch('updateSalesOfListings');

  const deliveryType = watch('delivType');

  const ticketsSold = useMemo(
    () => (listing?.tickets?.filter((t) => t.saleId) ?? []).length,
    [listing?.tickets]
  );

  const hasUpdateSalesOfListingsFeature = useUserHasFeature(
    Feature.UpdateSalesOfListingsDeliveryInfo
  );

  const hasAnySales = ticketsSold > 0;

  const warningDialog = useBasicDialog();
  const deliveryInfoChangeHookProps = useDeliveryInfoChangeDialog(
    () => {
      setValue('updateSalesOfListings', true);
    },
    () => {
      // set don't change back
      setValue('updateSalesOfListings', false);
    }
  );

  const { preDelivState, isUndeliv } = listing ?? {};
  const predeliveryArtifactTypes = listing?.availArtTypes ?? [];
  const cancelTo = listing?.id
    ? getListingDetailsModalConfigWithDeepLink(listing.id)
    : undefined;
  const inHandDate = inHandAt
    ? new Date(inHandAt)
    : isBulkEdit
    ? undefined
    : new Date();

  const canUpdate = useUserCanUpdateListing(listing, isBulkEdit);
  const canSetDeliveryStrategy = useUserCanSetDeliveryStrategy(
    listing,
    isBulkEdit
  );
  const canViewFulfillmentArtifacts = useUserCanViewPredelivery(
    listing,
    isBulkEdit
  );
  const disabled = externalDisabled || !canUpdate;

  const icon = getDeliveryTypeIcon(listing?.delivType);

  const confirmUpdateSales = useCallback(() => {
    if (
      hasAnySales &&
      hasUpdateSalesOfListingsFeature &&
      // only ask once per form
      updateSalesOfListings == null
    ) {
      if (isDatePassedHours(deliveryInfoChangeHookProps.lastTimeStamp, 1)) {
        // Show warning if it has passed 1 hr or not suppressed
        warningDialog.launchDialog();
      } else {
        // If suppressed, assumed it's true (since the current alert suppression only save for Yes answer)
        setValue('updateSalesOfListings', true);
      }
    }
  }, [
    deliveryInfoChangeHookProps.lastTimeStamp,
    hasAnySales,
    hasUpdateSalesOfListingsFeature,
    setValue,
    updateSalesOfListings,
    warningDialog,
  ]);

  return (
    <>
      <DetailSection name={<Content id={ContentId.MarketplaceDelivery} />}>
        <SectionContent
          numOfColumns={isMobile || isBulkEdit || !listing ? 1 : 3}
        >
          {!isBulkEdit && listing && (
            <DetailGroup style={{ gridColumn: 1 }}>
              <Detail
                label={<Content id={ContentId.DeliveryType} />}
                style={{ flex: 1 }}
                detail={
                  listing?.isSeatSaver ? (
                    <DeliveryTypeSelector
                      disabled={disabled}
                      value={deliveryType}
                      enableEmptySelection={false}
                      onChange={(newD) => {
                        if (newD !== deliveryType) {
                          setValue('delivType', newD);

                          confirmUpdateSales();
                        }
                      }}
                    />
                  ) : (
                    <Stack gap="m">
                      {icon}
                      <Content
                        id={
                          DELIVERY_OPTIONS_TO_CID[
                            deliveryType ?? listing.delivType
                          ]
                        }
                      />
                    </Stack>
                  )
                }
              />
            </DetailGroup>
          )}
          {!isBulkEdit && listing && (
            <DetailGroup
              style={{ gridColumn: isMobile || isBulkEdit || !listing ? 1 : 2 }}
            >
              <Stack direction="column">
                <Detail
                  label={<Content id={ContentId.PreDelivered} />}
                  style={{ flex: 1 }}
                  detail={
                    <span>
                      <Content
                        id={
                          isUndeliv
                            ? ContentId.Undeliverable
                            : preDelivState === PreDeliveryState.PreDelivered
                            ? ContentId.Yes
                            : ContentId.No
                        }
                      />
                    </span>
                  }
                />

                {canViewFulfillmentArtifacts &&
                  cancelTo &&
                  canViewFulfillmentArtifacts &&
                  predeliveryArtifactTypes.map((predeliveryArtifactType) => (
                    <ViewTickets
                      key={predeliveryArtifactType}
                      type={predeliveryArtifactType}
                      cancelTo={cancelTo}
                      loadingContentId={FormatContentId.LoadingListingId}
                      searchingContentId={FormatContentId.SearchingForListingId}
                      errorContentId={FormatContentId.CouldNotFindListingId}
                    />
                  ))}
              </Stack>
            </DetailGroup>
          )}
          {!isBulkEdit && hasAutoFulfillFeature && isAnyHandledByProcessor && (
            <DetailGroup
              style={{ gridColumn: isMobile || isBulkEdit || !listing ? 1 : 3 }}
            >
              <Stack direction="column">
                <Detail
                  label={<Content id={ContentId.AutoFulfill} />}
                  style={{ flex: 1 }}
                  detail={
                    <Switch
                      checked={!isNoFulfill}
                      disabled={disabled}
                      onChange={(e) => e.stopPropagation()}
                      onCheckedChange={(isChecked) => {
                        setValue('isNoFulfill', !isChecked);

                        confirmUpdateSales();
                      }}
                    />
                  }
                />
              </Stack>
            </DetailGroup>
          )}
          <DetailGroup
            style={{ gridColumn: isMobile || isBulkEdit || !listing ? 1 : 4 }}
          >
            <Detail
              label={<Content id={ContentId.InHand} />}
              style={{ flex: 1 }}
              detail={
                <Stack gap="xl" width="fit">
                  {!isBulkEdit && <InHandDateDisplay date={inHandDate} />}
                  <InHandDateInput
                    disabled={disabled}
                    allowEmptySelection
                    placeholderText={
                      isBulkEdit ? ContentId.DontChange : undefined
                    }
                    onChange={(date, daysBeforeEvent) => {
                      if (date) {
                        if (!inHandDate || !isEqual(date, inHandDate)) {
                          setValue('inHandDate', date.toISOString());
                        }

                        setValue('daysBeforeEvent', daysBeforeEvent ?? null);

                        confirmUpdateSales();
                      } else {
                        if (isBulkEdit) {
                          setValue('inHandDate', null);
                        }
                      }
                    }}
                    value={inHandAt}
                    targetDate={
                      event?.dates?.start
                        ? new Date(event?.dates?.start)
                        : new Date()
                    }
                  />
                </Stack>
              }
            />
          </DetailGroup>
          {!isBulkEdit && listing && (
            <TicketTypePrioritySection
              disabled={disabled || !canSetDeliveryStrategy}
              deliveryType={deliveryType ?? listing.delivType}
            />
          )}
        </SectionContent>
      </DetailSection>
      <AlertWithSuppressionDialog
        headerText={<Content id={ContentId.ListingUpdateConfirmation} />}
        size="md"
        {...warningDialog.dialogProps}
        alerts={[deliveryInfoChangeHookProps]}
        onOkay={() => {
          warningDialog.closeDialog();
        }}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
        onCancel={() => warningDialog.closeDialog()}
      />
    </>
  );
};
