export function AutomatiqSquareLogo({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="3" fill="black" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0674 12.2668L13.7627 15.7378H15.8886L13.5835 11.0674L12.0674 12.2668Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.52805 12.8012L9.80835 7.56543L10.9923 10.3023L7.52805 12.8012ZM12.5839 9.26779L9.81706 3L4 16.162H5.90742L18.4358 5.31992L12.5839 9.26779Z"
        fill="white"
      />
    </svg>
  );
}
