import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { ErrorIconMessage } from 'src/core/POS/ErrorIconMessage';
import { vars } from 'src/core/themes';
import { Card, Stack } from 'src/core/ui';
import { LaunchDuplicateSeatsSelectionDialog } from 'src/dialogs/DuplicateSeatsSelectionDialog.tsx';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FieldError } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { Feature, ListingDetails } from 'src/WebApiController';

import * as styles from './DuplicateListingSection.css';

export const DuplicateListingSection = ({
  listing,
  allowBroadcast,
  handleClick,
}: {
  listing: ListingDetails;
  allowBroadcast: boolean;
  handleClick: (isChecked: boolean) => void;
}) => {
  const hasIgnoreDupFeature = useUserHasFeature(Feature.IgnoreDuplicate);

  const duplicateSeatHeader = useContent(ContentId.DuplicateSeat);
  if (!listing.dupListingId) {
    return null;
  }

  return (
    <>
      {listing.dupListingId ? (
        <Card style={{ padding: vars.spacing['lg'] }}>
          <Stack gap="l" alignItems="center" justifyContent="spaceBetween">
            <ErrorIconMessage className={styles.errorLabel}>
              <Stack direction="column">
                <span className={styles.errorLabel}>{duplicateSeatHeader}</span>
                <FieldError>
                  <FormatContent
                    id={FormatContentId.DuplicateListingDetails}
                    params={[String(listing.dupListingId) ?? '']}
                  />
                </FieldError>
              </Stack>
            </ErrorIconMessage>
            {hasIgnoreDupFeature && (
              <Stack direction="row" gap="m" alignItems="center">
                <Checkbox
                  checked={allowBroadcast}
                  onChange={() => {
                    const isChecked = !allowBroadcast;
                    handleClick(isChecked);
                  }}
                />
                <Content id={ContentId.AllowBroadcast} />
              </Stack>
            )}
            <LaunchDuplicateSeatsSelectionDialog />
          </Stack>
        </Card>
      ) : null}
    </>
  );
};
