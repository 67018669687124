import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { mergeProps } from 'src/core/utils';
import { CrossIcon, MoreIcon } from 'src/svgs/Viagogo';

import * as styles from './FloatingToolbar.css';

export const FloatingToolbar = ({
  children,
  onToggleCollapsed,
  isCollapsed,
  disabled,
  ...rest
}: PropsWithChildren<{
  onToggleCollapsed?: (forceCollapse?: boolean) => void;
  isCollapsed?: boolean;
  disabled?: boolean;
}>) => {
  const { isDarkMode } = useSiteTheme();

  return (
    <div
      {...mergeProps(
        {
          className: clsx(styles.floatingToolbarContainer, {
            [styles.darkContentWrapper]: !isDarkMode,
            [styles.lightContentWrapper]: isDarkMode,
            [styles.collapsed]: isCollapsed,
          }),
        },
        rest
      )}
      onClick={
        isCollapsed && !disabled ? () => onToggleCollapsed?.(false) : undefined
      }
    >
      {!isCollapsed ? (
        <>
          {children}

          <CrossIcon
            disabled={disabled}
            className={styles.dismissButton}
            onClick={disabled ? undefined : () => onToggleCollapsed?.(true)}
            withHoverEffect
            size={vars.iconSize.xs}
          />
        </>
      ) : (
        <MoreIcon
          withHoverEffect
          size={vars.iconSize.s}
          fill={vars.color.textInverted}
        />
      )}
    </div>
  );
};
