import { useCallback, useContext } from 'react';
import { useBulkBroadcastActionsPermission } from 'src/components/common/BulkActions/BulkBroadcastActions/useBulkBroadcastActionsPermission';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { GroupListingsV2 } from 'src/modals/GroupListingsV2';
import { ContentId } from 'src/utils/constants/contentId';
import { Event } from 'src/WebApiController';

type LaunchGroupingWizardModal = {
  event: Event;
};

export const LaunchGroupingWizard: React.FC<LaunchGroupingWizardModal> = ({
  event,
}) => {
  const { hasBroadcastPermission } = useBulkBroadcastActionsPermission();

  const { setModal } = useContext(ModalContext);
  const {
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  const groupListings = useCallback(() => {
    setModal({
      children: <GroupListingsV2 event={event} />,
      clickOutsideToClose: true,
      ...{ size: 'slide-in', fullscreen: 'sm' },
    });
  }, [setModal, event]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setListItemExpansion(true, [event.viagVirtualId]);

      groupListings();
    },
    [event.viagVirtualId, groupListings, setListItemExpansion]
  );

  if (!hasBroadcastPermission) {
    return null;
  }

  return (
    <PosDropdownItem key="GroupListings" onClick={onLaunchDialog}>
      <Stack alignItems="center" gap="m" width="full">
        <Content id={ContentId.GroupListings} />
      </Stack>
    </PosDropdownItem>
  );
};
