export function SeatGeekSquareLogo({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_581_3722)">
        <rect width="20" height="20" rx="3" fill="#FF5B49" />
        <path
          d="M9.05051 6.97826C9.05051 5.74418 11.685 6.07675 12.9004 6.79311L13.0132 4.35486C12.7258 4.26551 11.563 4 10.1755 4C8.21329 4 6.5 5.16194 6.5 7.00857C6.5 10.301 10.9878 10.5284 10.9878 11.8495C10.9878 13.587 8.01219 12.621 6.5 11.1869V13.9644C6.5 13.9644 7.61291 15 9.82486 15C12.2623 15 13.5 13.4097 13.5 11.6958C13.5014 8.35722 9.05051 8.34275 9.05051 6.97826Z"
          fill="#181818"
        />
      </g>
      <defs>
        <clipPath id="clip0_581_3722">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
