import { ComponentProps, useCallback, useContext } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { WasteUnsoldTickets } from 'src/modals/ListingDetails/components/WasteUnsoldTickets/WasteUnsoldTickets';
import { ClockTrashIcon } from 'src/svgs/ClockTrashIcon';
import { IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { ActionOutboxEntityType, ListingDetails } from 'src/WebApiController';

type WasteUnsoldTicketsProps = Omit<
  ComponentProps<typeof WasteUnsoldTickets>,
  'event' | 'venue'
> & {
  onWastedSuccess: (saleId: number) => void;
};

export const LaunchWasteUnsoldTickets = ({
  cancelTo,
  onWastedSuccess,
}: WasteUnsoldTicketsProps) => {
  const { posEntity } = useActivePosEntityContext<EntityWithRealTickets>();
  const listing =
    posEntity?.entityType === ActionOutboxEntityType.Listing
      ? (posEntity as ListingDetails)
      : undefined;
  const { setModal } = useContext(ModalContext);

  const listingId = listing?.id;

  const launchWasteUnsoldTickets = useCallback(() => {
    setModal({
      children: (
        <WasteUnsoldTickets
          cancelTo={cancelTo}
          onWastedSuccess={onWastedSuccess}
        />
      ),
      clickOutsideToClose: true,
      size: 'slide-in',
      deepLinkValue: listingId,
      trackViewEntityType: ActionOutboxEntityType.Listing,
    });
  }, [setModal, cancelTo, onWastedSuccess, listingId]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      launchWasteUnsoldTickets();
    },
    [launchWasteUnsoldTickets]
  );

  return (
    <ButtonWithIcon
      variant={'text'}
      textContentId={ContentId.WasteUnsoldTickets}
      icon={
        <ClockTrashIcon
          withHoverEffect
          size={vars.iconSize.l}
          stroke={IconsFill.currentColor}
        />
      }
      onClick={onLaunchDialog}
    />
  );
};
