import { useCallback, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { MultiDetailSection } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Feature,
  ListingClient,
  ListingDetails,
  ListingStatus,
  Marketplace,
} from 'src/WebApiController';

import { DuplicateListingSection } from '../DuplicateListingSection';
import { EnableNativeSyncSection } from '../EnableNativeSyncSection';
import { ProofOfPurchaseSection } from '../ProofOfPurchaseSection';
import { MarketplaceTable } from './MarketplaceTable';
import { useGetMarketplaceFailures } from './useGetMarketplaceFailures';

export const MarketplaceBroadcastSection = ({
  listing,
  disabled,
  setIsLoading,
}: {
  listing: ListingDetails;
  disabled?: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [ignoreDup, setIgnoreDup] = useState(listing?.ignoreDup ?? false);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const marketplaceFailures = useGetMarketplaceFailures(listing);
  const hasIgnoreDupFeature = useUserHasFeature(Feature.IgnoreDuplicate);

  // We need to keep a local state of the listing status so we can use this to reflect the listing toggle and status when user clicks
  // otherwise we have to wait until the status is updated in the server before the UI shows the updates
  // However, mainting this local state in sync with the server is a bit nuanced - do be care of changing this logic
  const [marketplaceListingStatus, setMarketplaceListingStatus] = useState<
    Record<Marketplace, ListingStatus>
  >(
    // Initialized this state to what the current market listing status is
    listing.mkpListings
      ?.filter((ml) => ml.mkp)
      .reduce(
        (ml, cur) => {
          ml[cur.mkp!] = cur.status;
          return ml;
        },
        {} as Record<Marketplace, ListingStatus>
      ) ?? ({} as Record<Marketplace, ListingStatus>)
  );

  const handleIgnoreDuplicateClick = useCallback(
    async (isChecked: boolean) => {
      setIsLoading(true);
      setIgnoreDup(isChecked);
      tryInvokeApi(
        async () => {
          const client = new ListingClient(activeAccountWebClientConfig);
          const success = await client.updateListingIgnoreDuplicate(
            listing?.id,
            isChecked
          );

          if (!success) {
            setIgnoreDup(!isChecked);
          } else if (listing) {
            refreshExpandedListItems();

            const unbroadcastResult = await client.deleteMarketplaceListings(
              listing.id,
              []
            );
            if (unbroadcastResult) {
              const newStatuses = Object.keys(marketplaceListingStatus).reduce(
                (acc, marketplace) => {
                  acc[marketplace as Marketplace] = ListingStatus.Delisted;
                  return acc;
                },
                {} as Record<Marketplace, ListingStatus>
              );
              setMarketplaceListingStatus(newStatuses);
            }
          }
        },
        (error) => {
          showErrorDialog('ListingClient.updateListingIgnoreDuplicate', error, {
            trackErrorData: { ListingId: listing?.id },
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      listing,
      marketplaceListingStatus,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
    ]
  );

  const isDuplicate =
    !!listing?.dupListingId && (!hasIgnoreDupFeature || !ignoreDup);

  const isDisabled = disabled || isDuplicate;

  const sections = [
    <EnableNativeSyncSection key="1" listing={listing} disabled={disabled} />,
    <ProofOfPurchaseSection key="2" listing={listing} />,
    <DuplicateListingSection
      key="3"
      listing={listing}
      allowBroadcast={ignoreDup ?? false}
      handleClick={handleIgnoreDuplicateClick}
    />,
  ];

  return (
    <>
      <MultiDetailSection
        name={<Content id={ContentId.Broadcast} />}
        extraContent={sections}
      >
        <MarketplaceTable
          disabled={isDisabled}
          listing={listing}
          marketplaceFailures={marketplaceFailures}
          marketplaceListingStatus={marketplaceListingStatus}
          setMarketplaceListingStatus={setMarketplaceListingStatus}
        />
      </MultiDetailSection>
    </>
  );
};
