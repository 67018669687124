import { Link } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { vars } from 'src/core/themes';
import { OpenLinkIcon } from 'src/svgs';
import { MarketplacePaymentOptions } from 'src/tables/MarketplacePaymentsTable/Cells/MarketplacePaymentOptionsAction';
import { ContentId } from 'src/utils/constants/contentId';
import { getMarketplacePaymentRelativeUrl } from 'src/utils/marketplacePaymentUtils';
import { MarketplaceSalePayment } from 'src/WebApiController';

interface MarketplacePaymentsActionsCellProps {
  marketplaceSalePayment: MarketplaceSalePayment;
}

export const MarketplacePaymentsActions = ({
  marketplaceSalePayment,
}: MarketplacePaymentsActionsCellProps) => {
  return (
    <>
      <MarketplacePaymentOptions
        marketplaceSalePayment={marketplaceSalePayment}
      />
      <Link to={getMarketplacePaymentRelativeUrl(marketplaceSalePayment.id)}>
        <IconButton
          icon={
            <OpenLinkIcon
              size={vars.iconSize.m}
              fill={vars.color.textBrand}
              withHoverEffect
            />
          }
          titleContentId={ContentId.ViewDetails}
        />
      </Link>
    </>
  );
};
