import { UseQueryResult } from '@tanstack/react-query';
import {
  MutableRefObject,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { Button, Card, Stack } from 'src/core/ui';
import { PurchasePaymentMethodDialog } from 'src/dialogs/PurchasePaymentMethodDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  PurchaseClient,
  PurchasePaymentMethod,
  PurchasePaymentMethodType,
} from 'src/WebApiController';

import { VendorTableContainer } from '../VendorsTable/VendorsTableContainer.styled';
import { PurchasePaymentMethodsTable } from './PurchasePaymentMethodsTable';

export interface PurchasePaymentMethodsImperativeRef {
  onAddNewPaymentMethod: () => void;
}

export function PurchasePaymentMethodsTableContainer({
  purchasePaymentMethods,
  paymentMethodsQuery,
  purchasePaymentMethodsImperativeRef,
  useNewStyles = false,
}: {
  purchasePaymentMethods?: PurchasePaymentMethod[];
  paymentMethodsQuery: UseQueryResult<PurchasePaymentMethod[] | null, unknown>;
  purchasePaymentMethodsImperativeRef?: MutableRefObject<PurchasePaymentMethodsImperativeRef | null>;
  useNewStyles?: boolean;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const [currentPaymentMethod, setCurrentPaymentMethod] =
    useState<PurchasePaymentMethod>();
  const { showErrorDialog } = useErrorBoundaryContext();

  const onAddNewPaymentMethod = useCallback(() => {
    setCurrentPaymentMethod({
      display: '',
      id: -1,
      name: '',
      type: PurchasePaymentMethodType.Other,
    });
    launchDialog();
  }, [launchDialog]);

  const onEditPaymentMethod = useCallback(
    (paymentMethod: PurchasePaymentMethod) => {
      setCurrentPaymentMethod({ ...paymentMethod });
      launchDialog();
    },
    [launchDialog]
  );

  const onDeletePaymentMethod = useCallback(
    async (
      purchasePaymentMethodId: number,
      closeConfirmDeleteDialog: () => void
    ) => {
      await tryInvokeApi(
        async () => {
          const isDeleted = await new PurchaseClient(
            activeAccountWebClientConfig
          ).deletePurchasePaymentMethod(purchasePaymentMethodId);

          if (isDeleted) {
            paymentMethodsQuery.refetch();
            closeConfirmDeleteDialog();
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.deletePurchasePaymentMethod', error, {
            trackErrorData: { purchasePaymentMethodId },
          });
        }
      );
    },
    [activeAccountWebClientConfig, paymentMethodsQuery, showErrorDialog]
  );

  const onSavePaymentMethod = useCallback(
    async (paymentMethod: PurchasePaymentMethod) => {
      await tryInvokeApi(
        async () => {
          if (paymentMethod.id > 0) {
            const updatedPaymentMethod = await new PurchaseClient(
              activeAccountWebClientConfig
            ).updatePurchasePaymentMethod(paymentMethod);

            if (updatedPaymentMethod) {
              paymentMethodsQuery.refetch();
              closeDialog();
            }
          } else {
            const newId = await new PurchaseClient(
              activeAccountWebClientConfig
            ).insertPurchasePaymentMethod(paymentMethod);

            if (newId) {
              paymentMethodsQuery.refetch();
              closeDialog();
            } else {
              // This should never happen
              // Normal errors would have fallen to the error catch
              showErrorDialog(
                'PurchaseClient.insertPurchasePaymentMethod',
                {
                  message:
                    'Unable to create payment method ' + paymentMethod.id,
                  status: 500,
                } as ApiException,
                {
                  trackErrorData: paymentMethod,
                }
              );
            }
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.insertPurchasePaymentMethod', error, {
            trackErrorData: paymentMethod,
          });
        }
      );
    },
    [
      activeAccountWebClientConfig,
      closeDialog,
      paymentMethodsQuery,
      showErrorDialog,
    ]
  );

  const onPaymentMethodCancel = useCallback(() => {
    setCurrentPaymentMethod(undefined);
    closeDialog();
  }, [closeDialog]);

  useImperativeHandle(
    purchasePaymentMethodsImperativeRef,
    () => {
      return {
        onAddNewPaymentMethod,
      };
    },
    [onAddNewPaymentMethod]
  );

  const table = (
    <PurchasePaymentMethodsTable
      purchasePaymentMethods={purchasePaymentMethods}
      onEditPaymentMethod={onEditPaymentMethod}
      onDeletePaymentMethod={onDeletePaymentMethod}
      withOuterPadding={useNewStyles}
    />
  );
  return (
    <>
      <VendorTableContainer>
        <Stack direction="column" gap="m">
          {!useNewStyles && (
            <Button variant={'outline'} onClick={onAddNewPaymentMethod}>
              <Content id={ContentId.AddPurchasePaymentMethod} />
            </Button>
          )}
          {useNewStyles ? <Card>{table}</Card> : <>{table}</>}
        </Stack>
      </VendorTableContainer>
      <PurchasePaymentMethodDialog
        {...dialogProps}
        paymentMethod={currentPaymentMethod}
        onClosed={onPaymentMethodCancel}
        onSave={onSavePaymentMethod}
      />
    </>
  );
}
