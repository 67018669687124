import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Content,
  getContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import {
  PosDropdownAnimated,
  PosDropdownAnimatedItem,
} from 'src/core/POS/PosDropdownAnimated';
import { ContentId } from 'src/utils/constants/contentId';
import { VENUE_ZONE_SECTION_GROUP_AREA_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { SectionSectionGroup } from 'src/WebApiController';

import { VenueZoneMapFormType } from '../InventoryEventVenueZoneMapSection';
import * as styles from './VenueZoneSectionGroupDropdownList.css';

export type VenueZoneSectionGroupDropdownListProps = {
  selectedSectionSectionGroup: SectionSectionGroup | null;
  setSelectedSectionSectionGroup: React.Dispatch<
    React.SetStateAction<SectionSectionGroup | null>
  >;
};

export const VenueZoneSectionGroupDropdownList = ({
  selectedSectionSectionGroup,
  setSelectedSectionSectionGroup,
}: VenueZoneSectionGroupDropdownListProps) => {
  const contentContext = useContentContext();

  const { watch } = useFormContext<VenueZoneMapFormType>();

  const sectionSectionGroupLookup = watch('sectionSectionGroupLookup');
  const customSectionGroups = watch('customSectionGroups');

  const sectionGroups = Object.values(sectionSectionGroupLookup).reduce<{
    [key: string]: SectionSectionGroup;
  }>((acc, v) => {
    let sectionGroupName = `${v.ticketClassName}: ${v.sectionGroupAreaName}`;
    if (v.sectionGroupAreaType != null) {
      const sectionGroupAreaContentId =
        VENUE_ZONE_SECTION_GROUP_AREA_TYPE_TO_CID[v.sectionGroupAreaType];

      sectionGroupName = `${v.ticketClassName}: ${getContent(
        sectionGroupAreaContentId,
        contentContext
      )}`;
    }
    acc[sectionGroupName] = v;

    return acc;
  }, {});

  const selectedSectionGroup = useCallback(() => {
    if (selectedSectionSectionGroup == null) {
      return <Content id={ContentId.NoneSelected} />;
    }

    if (selectedSectionSectionGroup.sectionGroupAreaType == null) {
      return (
        <>
          <div
            className={styles.groupColorBox}
            style={{
              backgroundColor: selectedSectionSectionGroup.sectionGroupColor,
            }}
          />
          <span>
            {`${selectedSectionSectionGroup.ticketClassName}: ${selectedSectionSectionGroup.sectionGroupAreaName}`}
          </span>
        </>
      );
    }

    const sectionGroupAreaContentId =
      VENUE_ZONE_SECTION_GROUP_AREA_TYPE_TO_CID[
        selectedSectionSectionGroup.sectionGroupAreaType
      ];

    return (
      <>
        <div
          className={styles.groupColorBox}
          style={{
            backgroundColor: selectedSectionSectionGroup.sectionGroupColor,
          }}
        />
        <span>
          {`${selectedSectionSectionGroup.ticketClassName}: ${getContent(
            sectionGroupAreaContentId,
            contentContext
          )}`}
        </span>
      </>
    );
  }, [contentContext, selectedSectionSectionGroup]);

  return (
    <PosDropdownAnimated
      key="event-action-dropdown"
      trigger={
        <div className={styles.groupContainer}>{selectedSectionGroup()}</div>
      }
      align="end"
    >
      {Object.entries(sectionGroups)
        .sort(
          ([, a], [, b]) =>
            a.sectionGroupRank - b.sectionGroupRank || a.areaRank - b.areaRank
        )
        .map(([label, ssg]) => (
          <PosDropdownAnimatedItem
            key={label}
            className={styles.groupContainer}
            justifyContent="start"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedSectionSectionGroup(ssg);
            }}
          >
            <div
              className={styles.groupColorBox}
              style={{ backgroundColor: ssg.sectionGroupColor }}
            />
            <span>{label}</span>
          </PosDropdownAnimatedItem>
        ))}

      {customSectionGroups.map((ssg) => (
        <PosDropdownAnimatedItem
          key={`${ssg.ticketClassName}-${ssg.sectionGroupAreaName}`}
          className={styles.groupContainer}
          justifyContent="start"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setSelectedSectionSectionGroup(ssg);
          }}
        >
          <div
            className={styles.groupColorBox}
            style={{ backgroundColor: ssg.sectionGroupColor }}
          />
          <span>{`${ssg.ticketClassName}: ${ssg.sectionGroupAreaName}`}</span>
        </PosDropdownAnimatedItem>
      ))}
    </PosDropdownAnimated>
  );
};
