import { CSSProperties, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { AutoPricingInputs, CompListingQuantity } from 'src/WebApiController';

const quantitiesOptions = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10+',
].reduce(
  (r, c) => {
    r[c] = c;
    return r;
  },
  {} as Record<string, string>
);

export const AutoPricingCompQuantitiesSelector = ({
  disabled,
  rootStyle,
}: {
  disabled: boolean;
  rootStyle?: CSSProperties;
}) => {
  const { setValue, watch } = useFormContext<AutoPricingInputs>();

  const {
    compListingQuantityFilters,
    compListingQuantityScoreAdjustmentOverrideJson,
  } = watch();

  const parsedQuantityOverridesJson = useMemo(() => {
    return compListingQuantityScoreAdjustmentOverrideJson != null
      ? JSON.parse(compListingQuantityScoreAdjustmentOverrideJson)
      : null;
  }, [compListingQuantityScoreAdjustmentOverrideJson]);

  const derivedCompListingQuantityFilters =
    useMemo((): CompListingQuantity | null => {
      if (compListingQuantityFilters != null) {
        return compListingQuantityFilters;
      }

      if (parsedQuantityOverridesJson == null) {
        return null;
      }

      const { exact, gte } =
        parsedQuantityOverridesJson?.quantitiesFilter ?? {};
      return {
        exactQuantities: exact ?? [],
        greaterThanOrEqualToQuantity: gte,
      };
    }, [compListingQuantityFilters, parsedQuantityOverridesJson]);

  const selectedQuantitiesValues = useMemo(() => {
    if (!derivedCompListingQuantityFilters) {
      return [];
    }

    const { exactQuantities: exact, greaterThanOrEqualToQuantity: gte } =
      derivedCompListingQuantityFilters;

    const eligibleQuantities = Object.values(quantitiesOptions)
      .filter((value) => !isNaN(parseInt(value)))
      .map((value) => parseInt(value));
    const selectedQuantitiesInts = eligibleQuantities.filter((quantity) => {
      if (((exact as number[]) ?? []).includes(quantity)) {
        return true;
      }
      if (gte != null && (gte as number) <= quantity) {
        return true;
      }
      return false;
    });
    return Object.values(quantitiesOptions).filter((value) =>
      selectedQuantitiesInts.includes(parseInt(value))
    );
  }, [derivedCompListingQuantityFilters]);

  const handleOnChange = useCallback(
    (values: string[]) => {
      const exact = [];
      let gte: number | null = null;
      for (const value of values) {
        const quantity = parseInt(value);
        if (value.indexOf('+') > -1) {
          gte = quantity;
        } else {
          if (!isNaN(quantity)) {
            exact.push(quantity);
          }
        }
      }

      const compListingQuantityFiltersNew = {
        exactQuantities: exact,
        greaterThanOrEqualToQuantity: gte,
      };
      const compListingQuantityScoreAdjustmentOverrideJsonNew = JSON.stringify({
        ...parsedQuantityOverridesJson,
        quantitiesFilter: { exact, gte },
      });

      setValue('compListingQuantityFilters', compListingQuantityFiltersNew);
      setValue(
        'compListingQuantityScoreAdjustmentOverrideJson',
        compListingQuantityScoreAdjustmentOverrideJsonNew
      );
    },
    [parsedQuantityOverridesJson, setValue]
  );

  return (
    <PosMultiSelect
      disabled={disabled}
      align="start"
      values={selectedQuantitiesValues}
      onChange={handleOnChange}
      valueOptionsContent={quantitiesOptions}
      valuesDisplayText={selectedQuantitiesValues.join(', ')}
      triggerProps={{ style: rootStyle ?? { width: '180px' } }}
    />
  );
};
