export enum MarketplacePaymentsTableColumnId {
  PaymentDate = 'paymentDate',
  PaymentReferenceID = 'paymentReferenceID',
  State = 'state',
  PaymentAmount = 'paymentAmount',
  CreditAmount = 'creditAmount',
  ChargeAmount = 'chargeAmount',
  ProceedsAmount = 'proceedsAmount',
  Marketplace = 'marketplace',
  ConvertedPaymentsAmount = 'convertedPaymentAmount',
  Actions = 'actions',
}
