import { isEqual } from 'lodash-es';
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { DndGlobalFileDrop } from 'src/core/POS/DnDFileUploader/components/DndGlobalFileDrop';
import { DndFilerUploaderContextProvider } from 'src/core/POS/DnDFileUploader/DndFileUploaderContext';
import { Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetEventListingConstraints } from 'src/hooks/useGetEventListingConstraints';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { EventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBodyHeaderContainer } from 'src/modals/Modal/Modal.styled';
import { PurchaseTicketsFlowEventHeader } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/components/PurchaseTicketFlowEventHeader';
import { PurchaseTicketFlowTitle } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/components/PurchaseTicketFlowTitle';
import { predeliverETicketsDnd } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/PurchaseTicketFlow.css';
import { ContentId } from 'src/utils/constants/contentId';
import { getInHandDateBasedOnEvent } from 'src/utils/eventWithDataUtils';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import {
  PurchaseTicketsInput,
  validateEventSelection,
  validateTicketGroup,
  validateTicketGroups,
} from 'src/utils/purchaseUtils';
import { selectEventInfoFromTicketGroups } from 'src/utils/ticketUtils';
import {
  Feature,
  ListingActionType,
  TicketGroupInput,
} from 'src/WebApiController';

import {
  PurchaseWizardFooter,
  PurchaseWizardFooterProps,
} from '../../common/Purchase/PurchaseWizardFooter';
import { ModalBody } from '../../Modal';
import { PredeliveryActionsDropdown } from './components/PredeliveryActionsDropdown';
import { UploadPredeliveryAction } from './purchaseTicketsFlow.utils';
import { SearchPurchasedEvents } from './SearchPurchasedEvents';
import { TicketGroupInputForm } from './TicketGroupInput';
import { PurchaseOrderInfoForTicketGroupInput } from './TicketGroupInput.types';

const scrollToTopOnTicketGroupChange = () => {
  // The modal sits on top of the window, so window scrolling don't work.
  // Also, the ModalBody is a react component, wrapping it in forwardRef didn't expose the unlying html element. So, we use the vanilla js way to scroll to the top.
  const el = document.getElementsByClassName('modal-body')[0];
  el.scrollTop = 0;
};

export type PurchaseTicketsFlowProps = {
  currencyCode: string;
  purchaseOrderInfo?: PurchaseOrderInfoForTicketGroupInput | null;
  ticketGroups: TicketGroupInput[];
  ticketGroupFromPartialPurchaseOrder?: TicketGroupInput | null;
  eventSearchTextFromPartialPurchaseOrder?: string | null;
  updateSalesOfListings?: boolean | null;
  startIndex: number;
  avoidPrefill?: boolean;
  disabled?: boolean;
  onCancel: () => void;
  onSubmit: (formData: PurchaseTicketsInput) => void;
  emailPreview?: JSX.Element | null;
  uploadPredeliveryAction?: UploadPredeliveryAction | undefined;
};

export const PurchaseTicketsFlow = ({
  ticketGroups,
  avoidPrefill,
  ticketGroupFromPartialPurchaseOrder,
  eventSearchTextFromPartialPurchaseOrder,
  updateSalesOfListings,
  ...rest
}: PurchaseTicketsFlowProps) => {
  const methods = useForm<PurchaseTicketsInput>({
    defaultValues: {
      ticketGroups: avoidPrefill
        ? selectEventInfoFromTicketGroups(ticketGroups)
        : ticketGroups.map((tg) => ({
            ...tg,
            tickets: posField(tg.tickets?.value?.sort((a, b) => a.ord - b.ord)),
          })),
      updateSalesOfListings,
      updatePredeliveryETicketArtifacts: false,
      ticketGroupFromPartialPurchaseOrder,
      eventSearchTextFromPartialPurchaseOrder,
    },
  });

  return (
    <FormProvider {...methods}>
      <PurchaseTicketsFlowBody {...methods} {...rest} />
    </FormProvider>
  );
};

const EMPTY_EVENT_IDS: number[] = [];

function PurchaseTicketsFlowBody({
  disabled,
  currencyCode,
  purchaseOrderInfo,
  startIndex,
  onCancel,
  onSubmit,
  formState,
  handleSubmit,
  setError,
  setValue,
  clearErrors,
  watch,
  emailPreview,
  uploadPredeliveryAction,
}: Omit<PurchaseTicketsFlowProps, 'ticketGroups' | 'updateSalesOfListings'> &
  Omit<
    ComponentProps<typeof FormProvider<PurchaseTicketsInput, unknown>>,
    'children'
  >) {
  const formTicketGroups = watch('ticketGroups');
  const daysBeforeEvent = watch('daysBeforeEvent');

  const hasPurchaseDeliveryFeature = useUserHasFeature(
    Feature.PurchasePredelivery
  );
  const requiredMsg = useContent(ContentId.Required);
  const duplicateSeatMsg = useContent(ContentId.DuplicateSeat);
  const eventRequiredMsg = useContent(ContentId.SearchAndSelectAnEvent);
  const inHandDateMustBeforeEventDateMsg = useContent(
    ContentId.InHandDateMustBeBeforeEventDate
  );
  const autoBroadcastRequireFaceValueMsg = useContent(
    ContentId.AutoBroadcastRequiredFaceValue
  );

  const hasUploadTicketFilesFlowV2 = useUserHasFeature(
    Feature.UploadTicketFilesFlowV2
  );

  const multiEventInputsDialog = useBasicDialog();
  const [singleInputModeForMultiEvent, setSingleInputModeForMultiEvent] =
    useState(true);
  const isSingleInputModeForMultiEvent =
    singleInputModeForMultiEvent && formTicketGroups.length > 1;

  // Index of a Group of TicketGroupInput
  const [curGroupIndex, setCurGroupIndex] = useState<number>(startIndex);

  const groupsOfTicketGroup = useMemo<TicketGroupInput[][]>(() => {
    const noVenueCfgIdGroup = 'no_venue_cfg_id';

    if (isSingleInputModeForMultiEvent) {
      const ticketGroupsMap = new Map<number | string, TicketGroupInput[]>();
      formTicketGroups.forEach((ticketGroup) => {
        const venueCfgId = ticketGroup?.event?.venueCfgId || noVenueCfgIdGroup;
        const group = ticketGroupsMap.get(venueCfgId) || [];
        group.push(ticketGroup);
        ticketGroupsMap.set(venueCfgId, group);
      });

      const noCfgId = ticketGroupsMap.get(noVenueCfgIdGroup);
      ticketGroupsMap.delete(noVenueCfgIdGroup);

      const groupOfTicketGroups = Array.from(ticketGroupsMap.values());
      if (noCfgId && noCfgId.length > 0) {
        noCfgId.forEach((ticketGroup) => {
          groupOfTicketGroups.push([ticketGroup]);
        });
      }

      return groupOfTicketGroups;
    }

    return formTicketGroups.map((ticketGroup) => [ticketGroup]);
  }, [formTicketGroups, isSingleInputModeForMultiEvent]);

  const [showPredeliverySection, setShowPredeliverySection] = useState<
    UploadPredeliveryAction | undefined
  >(uploadPredeliveryAction);

  const curTicketGroup = useMemo(() => {
    return curGroupIndex >= 0
      ? // Pick first ticketGroup because:
        // If inputMode is grouping: all the items in the group will have the same config
        // If input mode is one by one: Each ticketGroup is a group of one item.
        groupsOfTicketGroup[curGroupIndex]?.[0]
      : undefined;
  }, [groupsOfTicketGroup, curGroupIndex]);

  const showSearch = curGroupIndex < 0 || !curTicketGroup;
  const ticketGroupsEventIds = useMemo(
    () =>
      formTicketGroups
        .filter((tg) => tg.event?.viagId != null)
        .map((tg) => tg.event?.viagId!),
    [formTicketGroups]
  );
  const { eventListingConstraintsQuery } = useGetEventListingConstraints(
    showSearch ? EMPTY_EVENT_IDS : ticketGroupsEventIds
  );

  const currentFormTicketGroupIndex = useMemo(() => {
    return formTicketGroups.findIndex(
      (tg) => tg.ticketGroupId === curTicketGroup?.ticketGroupId
    );
  }, [formTicketGroups, curTicketGroup]);

  const onSubmitWrapper = useCallback(() => {
    if (
      validateEventSelection(
        clearErrors,
        setError,
        formTicketGroups,
        eventRequiredMsg
      ) &&
      validateTicketGroups(
        true,
        clearErrors,
        setError,
        formTicketGroups,
        requiredMsg,
        duplicateSeatMsg,
        inHandDateMustBeforeEventDateMsg,
        autoBroadcastRequireFaceValueMsg
      )
    ) {
      handleSubmit(onSubmit)();
    }
  }, [
    autoBroadcastRequireFaceValueMsg,
    clearErrors,
    duplicateSeatMsg,
    eventRequiredMsg,
    handleSubmit,
    inHandDateMustBeforeEventDateMsg,
    onSubmit,
    requiredMsg,
    setError,
    formTicketGroups,
  ]);

  const onPrevFromSearchEventsStep = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onNextFromSearchEventsStep = useCallback(() => {
    if (
      validateEventSelection(
        clearErrors,
        setError,
        formTicketGroups,
        eventRequiredMsg
      )
    ) {
      const hasGroupedEvents = groupsOfTicketGroup.some(
        (group) => group.length > 1
      );
      if (hasGroupedEvents) {
        // If we have more than one event selected, we need to ask whether the user
        // wants to input tickets for each of them separately for together
        multiEventInputsDialog.launchDialog();
      } else {
        setSingleInputModeForMultiEvent(false);
        setCurGroupIndex(0);
      }
    }
  }, [
    clearErrors,
    setError,
    formTicketGroups,
    eventRequiredMsg,
    groupsOfTicketGroup,
    multiEventInputsDialog,
  ]);

  const onPrevFromTicketGroupInputStep = useCallback(() => {
    if (showPredeliverySection) {
      setShowPredeliverySection(undefined);
    } else if (curGroupIndex === startIndex) {
      onCancel();
    } else {
      setCurGroupIndex(curGroupIndex - 1);

      // Generate groups again to ask for confirmation for single input
      setSingleInputModeForMultiEvent(true);
    }
  }, [curGroupIndex, onCancel, showPredeliverySection, startIndex]);

  const onNextFromTicketGroupInputStep = useCallback(() => {
    const errors = validateTicketGroup(
      clearErrors,
      setError,
      curTicketGroup!,
      currentFormTicketGroupIndex,
      requiredMsg,
      duplicateSeatMsg,
      inHandDateMustBeforeEventDateMsg,
      autoBroadcastRequireFaceValueMsg
    );

    if (errors.length) {
      return;
    }

    const isLastGroup = curGroupIndex === groupsOfTicketGroup.length - 1;

    // Input mode: One input per group of ticket
    if (isSingleInputModeForMultiEvent) {
      const groupOfTickets = groupsOfTicketGroup[curGroupIndex];

      // Copy the content of the previous ticket-Group to all the ticketGroups and submit
      for (let i = 1; i < groupOfTickets.length; i++) {
        const tg = groupOfTickets[i];

        if (!tg.inHandDate?.value && tg.event?.dates?.start) {
          tg.inHandDate = posChangedField(
            getInHandDateBasedOnEvent(tg.event, daysBeforeEvent)
          );
        }

        const formTicketGroup = formTicketGroups.find(
          (ftg) => ftg.ticketGroupId === tg.ticketGroupId
        );

        if (formTicketGroup) {
          Object.assign(formTicketGroup, {
            ...curTicketGroup,
            ticketGroupId: tg.ticketGroupId,
            viagogoEventId: tg.event?.viagId,
            viagogoVirtualId: tg.event?.viagVirtualId,
            eventMappingId: tg.event?.mappingId,
            posEventId: tg.event?.posId,
            event: tg.event,
            performer: tg.performer,
            venue: tg.venue,
            inHandDate: tg.inHandDate,
          } as TicketGroupInput);
        }
      }

      setValue('ticketGroups', [...formTicketGroups]);

      if (isLastGroup) {
        onSubmitWrapper();
      } else {
        setCurGroupIndex(curGroupIndex + 1);
      }
    } else {
      // Input mode: One ticket group at a time
      if (isLastGroup) {
        onSubmitWrapper();
      } else {
        // Just increment the group, since each ticketGroup
        // represents a ticketGroup[] of one element.
        setCurGroupIndex(curGroupIndex + 1);
      }
    }
    scrollToTopOnTicketGroupChange();
  }, [
    clearErrors,
    setError,
    curTicketGroup,
    currentFormTicketGroupIndex,
    requiredMsg,
    duplicateSeatMsg,
    inHandDateMustBeforeEventDateMsg,
    autoBroadcastRequireFaceValueMsg,
    curGroupIndex,
    groupsOfTicketGroup,
    isSingleInputModeForMultiEvent,
    setValue,
    formTicketGroups,
    daysBeforeEvent,
    onSubmitWrapper,
  ]);

  const getFooterActions = useCallback((): PurchaseWizardFooterProps => {
    if (curGroupIndex < 0) {
      return {
        hasChanges:
          !showPredeliverySection &&
          !isEqual(formState.defaultValues?.ticketGroups, formTicketGroups),
        onPreviousLabel: ContentId.Cancel,
        onPrevious: onPrevFromSearchEventsStep,
        onNextLabel: ContentId.Next,
        onNext: onNextFromSearchEventsStep,
      };
    } else {
      return {
        hasChanges:
          !showPredeliverySection && curGroupIndex === startIndex
            ? !isEqual(formState.defaultValues?.ticketGroups, formTicketGroups)
            : false,
        onPreviousLabel:
          curGroupIndex === startIndex ? ContentId.Cancel : ContentId.Back,
        onPrevious: onPrevFromTicketGroupInputStep,
        onNextLabel: showPredeliverySection
          ? ContentId.Upload
          : curGroupIndex === formTicketGroups.length - 1
          ? disabled
            ? ContentId.OK
            : ContentId.Save
          : ContentId.Next,
        onNext: onNextFromTicketGroupInputStep,
      };
    }
  }, [
    curGroupIndex,
    disabled,
    formState.defaultValues?.ticketGroups,
    onNextFromSearchEventsStep,
    onNextFromTicketGroupInputStep,
    onPrevFromSearchEventsStep,
    onPrevFromTicketGroupInputStep,
    showPredeliverySection,
    startIndex,
    formTicketGroups,
  ]);

  const footerActions = getFooterActions();
  const isPredeliverETicketsAction =
    showPredeliverySection === ListingActionType.PredeliverETickets;

  return (
    <>
      <CancellableFormHeader
        disabled={formState.isSubmitting}
        showDialogOnCancel={
          !showPredeliverySection &&
          !isEqual(formState.defaultValues, formTicketGroups)
        }
        onClose={
          showPredeliverySection
            ? () => setShowPredeliverySection(undefined)
            : onCancel
        }
      >
        <EventEntityHeader
          title={
            <PurchaseTicketFlowTitle
              showSearch={showSearch}
              isSingleInputModeForMultiEvent={isSingleInputModeForMultiEvent}
              currentGroupIndex={curGroupIndex}
              currentGroupTicketsCount={
                groupsOfTicketGroup[curGroupIndex]?.length
              }
            />
          }
        />
      </CancellableFormHeader>
      <DndFilerUploaderContextProvider>
        <DndGlobalFileDrop
          className={
            hasUploadTicketFilesFlowV2 && isPredeliverETicketsAction
              ? predeliverETicketsDnd
              : undefined
          }
        >
          <Stack
            direction="row"
            style={{ height: '100%', width: '100%', overflowX: 'auto' }}
          >
            {emailPreview}

            <ModalBody>
              {showSearch ? (
                <SearchPurchasedEvents currencyCode={currencyCode} />
              ) : (
                <>
                  <ModalBodyHeaderContainer>
                    <PurchaseTicketsFlowEventHeader
                      isSingleInputModeForMultiEvent={
                        isSingleInputModeForMultiEvent
                      }
                      ticketGroups={groupsOfTicketGroup[curGroupIndex]}
                    />
                  </ModalBodyHeaderContainer>
                  <TicketGroupInputForm
                    showPredeliverySection={showPredeliverySection}
                    onHidePredeliverAction={() =>
                      setShowPredeliverySection(undefined)
                    }
                    purchaseOrderInfo={purchaseOrderInfo}
                    hideHeader={isSingleInputModeForMultiEvent}
                    disabled={disabled}
                    ticketGroup={curTicketGroup}
                    ticketGroupIndex={currentFormTicketGroupIndex}
                    listingConstraints={
                      curTicketGroup.event?.viagId
                        ? eventListingConstraintsQuery.data?.[
                            curTicketGroup.event.viagId
                          ]
                        : null
                    }
                  />
                </>
              )}
            </ModalBody>
          </Stack>
          {!showPredeliverySection && (
            <PurchaseWizardFooter
              {...footerActions}
              additionalActions={
                hasPurchaseDeliveryFeature &&
                !showSearch &&
                !isSingleInputModeForMultiEvent
                  ? [
                      <PredeliveryActionsDropdown
                        ticketGroup={curTicketGroup}
                        key="ticket-group-upload-predelivery"
                        disabled={
                          disabled ||
                          Boolean(curTicketGroup?.numberOfTickets?.value === 0)
                        }
                        onPredeliveryAction={setShowPredeliverySection}
                      />,
                    ]
                  : undefined
              }
              isSubmitting={formState.isSubmitting}
            />
          )}
        </DndGlobalFileDrop>
      </DndFilerUploaderContextProvider>
      <ConfirmDialog
        {...multiEventInputsDialog.dialogProps}
        size="m"
        cancelText={ContentId.No}
        okText={ContentId.Yes}
        headerText={
          <Content id={ContentId.SameEventsShareSameVenueConfigTitle} />
        }
        bodyText={
          <Stack direction="column" gap="l">
            <span>
              <Content id={ContentId.SameEventsShareSameVenueConfigHeader} />
            </span>
            <span>
              <Content id={ContentId.SameEventsShareSameVenueConfigMessage1} />
            </span>
            <span>
              <Content id={ContentId.SameEventsShareSameVenueConfigMessage2} />
            </span>
          </Stack>
        }
        onCancel={() => {
          setCurGroupIndex(0);
          setSingleInputModeForMultiEvent(false);
          multiEventInputsDialog.closeDialog();
        }}
        onOkay={() => {
          setCurGroupIndex(0);
          setSingleInputModeForMultiEvent(true);
          multiEventInputsDialog.closeDialog();
        }}
      />
    </>
  );
}
