import { Virtuoso } from 'react-virtuoso';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { GroupedPaymentsAccordion } from 'src/components/MarketplacePayments/Tabs/components/GroupedPaymentsAccordion';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useMarketplacePaymentDataContext } from 'src/contexts/MarketplacePaymentDataContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SomethingWentWrong } from 'src/views';
import { MarketplacePaymentQuery } from 'src/WebApiController';

export const MarketplacePaymentsTabContent = () => {
  const { isQueryInitialized } =
    useFilterQueryContext<MarketplacePaymentQuery>();

  const { monthPaymentsQuery, errorInfo, selectedIds, onSelect } =
    useMarketplacePaymentDataContext();

  const dataIsLoading =
    !isQueryInitialized ||
    monthPaymentsQuery.isLoading ||
    monthPaymentsQuery.data == null;

  const hasData = monthPaymentsQuery.data?.length;

  return (
    <>
      {dataIsLoading && <PosSpinner />}
      {errorInfo && (
        <SomethingWentWrong
          header={errorInfo.errorHeader}
          message={
            errorInfo.errorMessage ?? (
              <Content id={ContentId.FailToLoadListContent} />
            )
          }
        />
      )}
      {!dataIsLoading && !errorInfo && (
        <>
          {hasData ? (
            <>
              {monthPaymentsQuery.data && (
                <Virtuoso
                  data={monthPaymentsQuery.data}
                  itemContent={(_, monthPayments) => (
                    <GroupedPaymentsAccordion
                      item={monthPayments}
                      selectedIds={selectedIds}
                      onSelect={onSelect}
                    />
                  )}
                />
              )}
            </>
          ) : (
            <EmptySectionContent.Root
              icon={
                <EmptySectionContent.SolidIconContainer>
                  <SearchSolidIcon />
                </EmptySectionContent.SolidIconContainer>
              }
            >
              <EmptySectionContent.Label>
                <Content id={ContentId.NoResultFound} />
              </EmptySectionContent.Label>
            </EmptySectionContent.Root>
          )}
        </>
      )}
    </>
  );
};
