import { useCallback, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { InventoryEventPageV2 } from 'src/components/Listings/InventoryEventPage/InventoryEventPageV2';
import { MainFilterBar } from 'src/components/MainFilterBar';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { CatalogMetricsContextProvider } from 'src/contexts/CatalogMetricsContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ListingNotificationContextProvider } from 'src/contexts/ListingNotificationContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { useGetActiveAccountClientConfig } from 'src/hooks/useGetActiveAccountClientConfig';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import {
  getDefaultReturnUrl,
  INVENTORY_USER_SETTINGS,
} from 'src/navigations/Routes/InventoryEvent/constants';
import {
  EventIdQueryParam,
  InventoryDeeplinkQueryParam,
} from 'src/utils/constants/constants';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import {
  EmptyListingQuery,
  QueryWithViewMode,
} from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { ListingCatalogQueryKey } from 'src/utils/inventoryUtils';
import { addListingMetrics } from 'src/utils/ticketMetricUtils';
import { EventPageLocationState } from 'src/utils/types/EventPageLocationState';
import {
  ActionOutboxEntityType,
  EntityWithTicketsQuery,
  Feature,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingMetrics,
  ListingQuery,
  UserSetting,
} from 'src/WebApiController';

import {
  getCatalogData,
  getCatalogDataExpanded,
  getCatalogMetrics,
  listingQueryValueTransformFromUrl,
  listingQueryValueTransformToUrl,
} from '../Inventory';
import { MainRoute } from '../MainRoute';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
} from './InventoryEvent.constants';

export function InventoryEventV2() {
  const [searchParams] = useSearchParams();
  const viagEventId = searchParams.get(EventIdQueryParam);

  const location = useLocation();

  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], filterQuery: ListingQuery) =>
      getCatalogDataExpanded(ids, filterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('ListingClient.getListingsForEvents', error, {
            ...filterQuery,
            eventIds: ids,
          });
        },
      }),
    [activeAccountWebClientConfig, trackError]
  );

  const initialQuery = useMemo(
    () => ({
      ...EmptyListingQuery,
      eventOrMappingIds: viagEventId ? [viagEventId] : null,
    }),
    [viagEventId]
  );

  const getActClientCfg = useGetActiveAccountClientConfig(
    'InventoryEventV2.ListingClient.getListingByListingId'
  );

  const hasSectionalLoadDataFeature = useUserHasFeature(
    Feature.ListingLoadBySection
  );

  const getActivePosEntity = useCallback(
    async (
      posEntityId: number,
      curEntity?: ListingDetails | null,
      dataToGet?: string[] | null
    ) => {
      const activeClientCf = await getActClientCfg();
      const listingDetail = await new ListingClient(
        activeClientCf
      ).getListingByListingId(
        posEntityId,
        hasSectionalLoadDataFeature ? curEntity ?? undefined : undefined,
        hasSectionalLoadDataFeature
          ? dataToGet?.map((s) => s as ListingDetailDataField) ?? []
          : undefined
      );

      if (listingDetail) {
        return {
          posEntityId: listingDetail.id,
          posEntity: listingDetail,
          posEntityDisplayId: listingDetail.idOnMkp,
        };
      }

      return {};
    },
    [getActClientCfg, hasSectionalLoadDataFeature]
  );

  // Only use value provided at the first time (should be from '/inventory')
  const returnUrl = useMemo(() => {
    const locationState = (location.state ?? {}) as EventPageLocationState;

    if (locationState.returnUrl && locationState.keepReturnUrlUnchanged) {
      return locationState.returnUrl;
    }

    const relativeUrl =
      locationState.returnUrl || getDefaultReturnUrl(viagEventId);

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      InventoryDeeplinkQueryParam,
      ACTIVE_EVENT_IDS_QUERY_PARAM,
      TAB_EVENT_IDS_QUERY_PARAM,
      ACTIVE_EVENT_IDS_2_QUERY_PARAM,
      TAB_EVENT_IDS_2_QUERY_PARAM,
    ]);
  }, [viagEventId, location.state]);

  if (!viagEventId) {
    return null;
  }

  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={INVENTORY_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<EntityWithTicketsQuery & QueryWithViewMode>
        initialQuery={initialQuery}
        emptyQuery={EmptyListingQuery}
        viewModeSettingId={UserSetting.InventoryPageViewMode}
        saveQueryInUrl
        queryValueTransformToUrl={listingQueryValueTransformToUrl}
        queryValueTransformFromUrl={listingQueryValueTransformFromUrl}
      >
        <CatalogDataContextProvider<ListingQuery>
          queryKey={ListingCatalogQueryKey}
          getCatalogData={(c, f) => getCatalogData(c, f, true)}
          getCatalogDataExpanded={getCatalogDataExpandedCallback}
          transformEventData={transformData}
        >
          <CatalogMetricsContextProvider<ListingMetrics, ListingQuery>
            queryKey="getCatalogListingMetrics"
            getCatalogMetrics={getCatalogMetrics}
            addCatalogMetrics={addListingMetrics}
          >
            <ActivePosEntityProvider<ListingDetails>
              entityType={ActionOutboxEntityType.Listing}
              getActivePosEntity={getActivePosEntity}
            >
              <ListingNotificationContextProvider>
                <CatalogMultiSelectionContextProvider type="listing">
                  <MultiSelectionContextProvider>
                    <DialogProvider>
                      <LayoutContent
                        mainRoute={MainRoute.Inventory}
                        routeTitle=""
                      >
                        <MainFilterBar isEventPage returnUrl={returnUrl} />
                        <InventoryEventPageV2
                          viagVirtualId={viagEventId}
                          returnUrl={returnUrl}
                        />
                      </LayoutContent>
                    </DialogProvider>
                  </MultiSelectionContextProvider>
                </CatalogMultiSelectionContextProvider>
              </ListingNotificationContextProvider>
            </ActivePosEntityProvider>
          </CatalogMetricsContextProvider>
        </CatalogDataContextProvider>
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}
