import { MouseEvent } from 'react';
import { MarketplacePaymentsFilterBar } from 'src/components/MarketplacePayments/MarketplacePaymentsFilterBar';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

import * as styles from './PaymentsToolbar.css';

interface PaymentsToolbarProps {
  onColumnSettingButtonClickHandler?: (
    e: MouseEvent<HTMLButtonElement>
  ) => void;
}

export const MarketplacePaymentsToolbar = ({
  onColumnSettingButtonClickHandler,
}: PaymentsToolbarProps) => {
  const hasMarketplacePaymentsFiltersFeature = useUserHasFeature(
    Feature.MarketplacePaymentsFilters
  );

  return (
    <Stack justifyContent="spaceBetween" className={styles.paymentsToolbar}>
      {hasMarketplacePaymentsFiltersFeature && <MarketplacePaymentsFilterBar />}
      <Button variant="textPlain" onClick={onColumnSettingButtonClickHandler}>
        <LayoutIcon size={vars.iconSize.m} />
        <Content id={ContentId.Columns} />
      </Button>
    </Stack>
  );
};
