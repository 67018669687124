import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { Card } from 'src/core/ui';
import { CheckIcon, CrossIcon, IconsFill } from 'src/svgs/Viagogo';

import * as styles from './SeatMapCard.css';

export const SeatMapCard = ({
  isSelected = false,
  isInteractive = false,
  isSmall = false,
  footer,
  onClick,
  onDelete,
  children,
}: {
  isSelected?: boolean;
  isInteractive?: boolean;
  isSmall?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  footer?: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <Card
      className={
        isInteractive
          ? clsx(styles.venueMapCardInteractive, {
              [styles.venueMapCardSelected]: isSelected,
            })
          : styles.venueMapCard
      }
      onClick={isInteractive ? onClick : undefined}
    >
      <div
        className={
          isSmall ? styles.venueMapWrapperSmall : styles.venueMapWrapper
        }
      >
        {children}
      </div>
      <div
        className={
          isSmall ? styles.venueMapCardFooterSmall : styles.venueMapCardFooter
        }
      >
        {footer}
      </div>

      {isSelected ? (
        <div className={styles.venueMapCardCheckIconWrapper}>
          <CheckIcon fill={IconsFill.textBrand} />
        </div>
      ) : (
        onDelete && (
          <div className={styles.venueMapCardCheckIconWrapper}>
            <CrossIcon
              fill={IconsFill.textBrand}
              withHoverEffect
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDelete();
              }}
            />
          </div>
        )
      )}
    </Card>
  );
};
