import { useMemo } from 'react';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { VENUE_ZONE_CONFIG_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { Event, VenueZoneConfigOverride } from 'src/WebApiController';

import { getContent, useContentContext } from '../ContentContext';
import { useGetVenueZoneMapConfigInfo } from './useGetVenueZoneMapConfigInfo';
import { useGetVenueZoneMapInfoOverrides } from './useGetVenueZoneMapConfigInfoOverrides';

export const useVenueZoneMapIntegration = (event: Event | null) => {
  const contentContext = useContentContext();

  const { pricingSettingsQuery } = useGetEventAutoPricingSettings(
    event ?? null,
    false
  );
  const venueZoneMapConfigInfoQuery = useGetVenueZoneMapConfigInfo(
    event?.viagId,
    event?.venueCfgId
  );
  const venueZoneMapConfigOverrideInfoQuery = useGetVenueZoneMapInfoOverrides(
    event?.viagId,
    event?.venueCfgId
  );

  const venueZoneConfigs = useMemo((): VenueZoneConfigOverride | null => {
    const venueZoneOverrideId = pricingSettingsQuery.data?.venueZoneOverrideId;
    if (
      venueZoneOverrideId != null &&
      venueZoneMapConfigOverrideInfoQuery.data &&
      venueZoneMapConfigOverrideInfoQuery.data[venueZoneOverrideId]
    ) {
      return venueZoneMapConfigOverrideInfoQuery.data[venueZoneOverrideId];
    }

    const zoneConfigType =
      pricingSettingsQuery.data?.venueZoneConfigType ??
      venueZoneMapConfigInfoQuery.data?.defaultVenueZoneConfigType;
    if (zoneConfigType && venueZoneMapConfigInfoQuery.data) {
      const sectionSectionGroupLookup =
        venueZoneMapConfigInfoQuery.data.venueMapZoneConfigs[zoneConfigType];

      return {
        name: getContent(
          VENUE_ZONE_CONFIG_TYPE_TO_CID[zoneConfigType],
          contentContext
        ),
        sectionSectionGroupLookup: sectionSectionGroupLookup ?? {},
      };
    }
    return null;
  }, [
    contentContext,
    pricingSettingsQuery.data?.venueZoneConfigType,
    pricingSettingsQuery.data?.venueZoneOverrideId,
    venueZoneMapConfigInfoQuery.data,
    venueZoneMapConfigOverrideInfoQuery.data,
  ]);

  return venueZoneConfigs;
};
