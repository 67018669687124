import { MarketplacePaymentsTabContent } from 'src/components/MarketplacePayments/Tabs/MarketplacePaymentsTabContent';
import { MarketplacePaymentDataContextProvider } from 'src/contexts/MarketplacePaymentDataContext';

export const MarketplacePaymentsTab = () => {
  return (
    <MarketplacePaymentDataContextProvider>
      <MarketplacePaymentsTabContent />
    </MarketplacePaymentDataContextProvider>
  );
};
