import { MarketplacePaymentsDetailsModal } from 'src/modals/MaketplacePayments/MarketplacePaymentsDetailsModal';
import { MarketplacePaymentIdDeeplinkQueryParam } from 'src/utils/constants/constants';

export const MarketplacePaymentsDetails = {
  children: <MarketplacePaymentsDetailsModal />,
  clickOutsideToClose: true,
  deepLinkKey: MarketplacePaymentIdDeeplinkQueryParam,
  deepLinkValue: undefined,
  size: 'slide-in',
};
