import { useQuery } from '@tanstack/react-query';
import { ComponentPropsWithRef } from 'react';
import { Components, Virtuoso } from 'react-virtuoso';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { clearIDBCache } from 'src/core/utils/createIndexDBPersister';
import { ErrorSolidIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  SellerSupportCaseClient,
  SellerSupportCaseNote,
} from 'src/WebApiController';

import IconBulletList from '~icons/pos/bulletList';

import { SupportCaseNoteDisplay } from '../SupportCaseNoteDisplay/SupportCaseNoteDisplay';
import * as styles from './SupportCaseBody.css';
import { SupportCaseReplyInput } from './SupportCaseReplyInput';

type VirtuosoContext = {
  isLoading?: boolean;
  isError?: boolean;
};

const EmptyPlaceholder: Components<
  unknown,
  VirtuosoContext
>['EmptyPlaceholder'] = ({ context }: { context?: VirtuosoContext }) => {
  if (context?.isLoading) {
    return <PosSpinner />;
  }
  if (context?.isError) {
    return (
      <EmptySectionContent.Root
        icon={
          <ErrorSolidIcon
            fill={IconsFill.textNegative}
            size={vars.iconSize.xl}
          />
        }
      >
        <EmptySectionContent.Error>
          <Content id={ContentId.FailToLoadListContent} />
        </EmptySectionContent.Error>
        <Button
          variant={'regular'}
          onClick={() => {
            clearIDBCache(); // delete the cache storage before reloading
            window.location.reload();
          }}
        >
          <Content id={ContentId.Retry} />
        </Button>
      </EmptySectionContent.Root>
    );
  }
  return (
    <EmptySectionContent.Root icon={<IconBulletList />}>
      <Content id={ContentId.NoResultFound} />
    </EmptySectionContent.Root>
  );
};

const Item: Components<SellerSupportCaseNote, VirtuosoContext>['Item'] = ({
  children,
  ...rest
}: ComponentPropsWithRef<'div'>) => {
  return (
    <div className={styles.listItemWrapper} {...rest}>
      {children}
    </div>
  );
};

// XXX hack to prevent margin collapse on last element
const Footer = () => {
  return <div style={{ paddingTop: '0.05px' }} />;
};

export function SupportCaseBody({
  sellerSupportCaseId,
}: {
  sellerSupportCaseId: number;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    sellerSupportCaseId != null;

  const sellerSupportCaseNoteQuery = useQuery({
    queryKey: [
      'SellerSupportCaseClient.getSellerSupportCaseNotesByCaseId',
      activeAccountWebClientConfig.activeAccountId,
      sellerSupportCaseId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      return tryInvokeApi(
        () => {
          return new SellerSupportCaseClient(
            activeAccountWebClientConfig
          ).getSellerSupportCaseNotesByCaseId(
            sellerSupportCaseId,
            null,
            null,
            true
          );
        },
        (error) => {
          trackError(
            'SellerSupportCaseClient.getSellerSupportCaseNotesByCaseId',
            error,
            { sellerSupportCaseId }
          );
        }
      );
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
  });

  const supportNoteData = sellerSupportCaseNoteQuery.data;
  return (
    <div className={styles.supportCaseBodyContainer}>
      <Virtuoso
        className={styles.supportCaseVirtuosoWrapper}
        data={supportNoteData ?? []}
        components={{ Footer, EmptyPlaceholder, Item }}
        context={{
          isLoading: sellerSupportCaseNoteQuery.isLoading,
          isError: sellerSupportCaseNoteQuery.isError,
        }}
        itemContent={(_, supportNote) => {
          return (
            <SupportCaseNoteDisplay
              createdByUserId={
                supportNote.impersonatedUserId ?? supportNote.createByUserId
              }
              noteDate={stringToUtcDate(supportNote.createDate)}
              noteContent={supportNote.noteContent}
              attachments={supportNote.attachments}
            />
          );
        }}
      />

      <SupportCaseReplyInput
        className={styles.replyContainer}
        sellerSupportCaseId={sellerSupportCaseId}
        sellerSupportCaseNotesIsLoading={sellerSupportCaseNoteQuery.isLoading}
        onReply={() => {
          sellerSupportCaseNoteQuery.refetch();
        }}
      />
    </div>
  );
}
