import { useMemo } from 'react';
import { useMarketplacePaymentsTableColumnsConfig } from 'src/tables/MarketplacePaymentsTable/useMarketplacePaymentsTableColumnsConfig';
import { MarketplacePaymentsTableColumnId } from 'src/utils/columns/marketplacePayments/marketplacePaymentsColumnsUtils.types';

export const useGetMarketplaceDefaultColumnsConfig =
  (): MarketplacePaymentsTableColumnId[] => {
    const paymentsTableColumnConfig =
      useMarketplacePaymentsTableColumnsConfig();
    return useMemo(
      () =>
        Object.values(paymentsTableColumnConfig).map(
          (column) => column.id as MarketplacePaymentsTableColumnId
        ),
      [paymentsTableColumnConfig]
    );
  };
