import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { vars } from 'src/core/themes';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import {
  groupPrioritySort,
  useGroupSortingCriteria,
} from 'src/modals/GroupListings/components/ListingGroupInput.hook';
import {
  Listing,
  ListingGroupItemInput,
  ListingGroupUndercutSetting,
  MergeListingGroupInput,
} from 'src/WebApiController';

import { useSetListingGroupValue } from '../../GroupListingsV2.hooks';
import { GroupListingsFormProps } from '../../GroupListingsV2.types';
import * as styles from './GroupListingsPreview.css';
import {
  UngroupedListingGroupId,
  usePreviewTableColumns,
} from './GroupListingsPreviewTable.constants';
import { withGroupUndercustSettings } from './GroupsPreview.utils';
import { MoveListingGroupDialog } from './MoveListingGroupDialog';

export type GroupListingTableItem = ListingGroupItemInput & Listing;

type GroupListingPreviewTableProps = {
  listingGroup: MergeListingGroupInput;
  eventListings: Listing[];
  isUngroupedGroup: boolean;
};

// Row Component
const DraggableRow = ({ row }: { row: Row<GroupListingTableItem> }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });

  return (
    <tr
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
        lineHeight: vars.spacing['3xl'],
      }}
    >
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} style={{ width: cell.column.getSize() }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

export const GroupListingPreviewTable: React.FC<
  GroupListingPreviewTableProps
> = ({ listingGroup, eventListings, isUngroupedGroup }) => {
  const { watch, setValue } = useFormContext<GroupListingsFormProps>();
  const listingGroups = watch('mergeListingGroupInputs');

  const [targetListingId, setTargetListingId] = useState<number>();

  const moveListingGroupDialog = useBasicDialog();

  const idToSortingCriteria = useGroupSortingCriteria(eventListings);

  const setListingGroupValue = useSetListingGroupValue(
    listingGroup.listingGroupId
  );

  const listingIdToItem = useMemo(
    () =>
      listingGroup.listingGroupItems.reduce(
        (res, item) => {
          res[item.listingId] = item;
          return res;
        },
        {} as Record<number, ListingGroupItemInput>
      ),
    [listingGroup.listingGroupItems]
  );

  const listings = useMemo((): GroupListingTableItem[] => {
    const groupListingIds = Object.keys(listingIdToItem);
    return eventListings
      .filter((listing) => groupListingIds.includes(listing.id + ''))
      .map((listing) => ({ ...listing, ...listingIdToItem[listing.id] }))
      .sort((a, b) => a.priority - b.priority);
  }, [eventListings, listingIdToItem]);

  const listingIds = useMemo<UniqueIdentifier[]>(
    () => listings.map(({ id }) => id),
    [listings]
  );

  const onMoveListing = useCallback(
    (listingId: number) => {
      setTargetListingId(listingId);
      moveListingGroupDialog.launchDialog();
    },
    [moveListingGroupDialog]
  );

  const onRemoveListingFromGroup = useCallback(
    (listingId: number) => {
      const updatedListings = withGroupUndercustSettings(
        listingGroup.groupUndercutSetting,
        listingGroup.listingGroupItems.filter(
          (item) => item.listingId !== listingId
        )
      ).map((item, index) => ({
        ...item,
        priority: index + 1,
      }));
      setListingGroupValue({
        listingGroupItems: updatedListings,
      });
    },
    [
      listingGroup.groupUndercutSetting,
      listingGroup.listingGroupItems,
      setListingGroupValue,
    ]
  );

  const onMoveListingToGroup = useCallback(
    (targetListingGroupId: string) => {
      if (!targetListingId) {
        return;
      }
      const targetListing = listings.find(
        ({ listingId }) => listingId === targetListingId
      );
      if (!targetListing) {
        return;
      }

      const idToGroup = listingGroups.reduce(
        (res, group) => {
          res[group.listingGroupId] = group;
          return res;
        },
        {} as Record<string, MergeListingGroupInput>
      );

      const toGroup = idToGroup[targetListingGroupId];

      if (listingGroup.listingGroupId === UngroupedListingGroupId) {
        const newItems = [
          ...toGroup.listingGroupItems,
          {
            listingId: targetListing.id,
            priority: toGroup.listingGroupItems.length + 1,
            groupId: targetListing.ltGrpId,
            undercutSetting: null,
          } as ListingGroupItemInput,
        ];

        idToGroup[targetListingGroupId] = {
          ...toGroup,
          listingGroupItems: withGroupUndercustSettings(
            toGroup.groupUndercutSetting,
            newItems
          )
            .sort(
              groupPrioritySort(
                idToSortingCriteria,
                toGroup.deprioritizedQuantities
              )
            )
            .map((listing, index) => ({
              ...listing,
              priority: index + 1,
            })),
        };
      } else {
        const fromGroup = idToGroup[listingGroup.listingGroupId];

        const targetListingGroupItem = fromGroup.listingGroupItems.find(
          ({ listingId }) => listingId === targetListingId
        );
        if (!targetListingGroupItem || !targetListing) {
          return;
        }

        idToGroup[listingGroup.listingGroupId] = {
          ...fromGroup,
          listingGroupItems: withGroupUndercustSettings(
            fromGroup.groupUndercutSetting,
            fromGroup.listingGroupItems.filter(
              ({ listingId }) => listingId !== targetListingId
            )
          ).map((listing, index) => ({
            ...listing,
            priority: index + 1,
          })),
        };

        const targetGroupItems = [
          ...toGroup.listingGroupItems,
          { ...targetListingGroupItem, groupId: targetListing.ltGrpId },
        ];
        idToGroup[targetListingGroupId] = {
          ...toGroup,
          listingGroupItems: withGroupUndercustSettings(
            toGroup.groupUndercutSetting,
            targetGroupItems
          )
            .sort(
              groupPrioritySort(
                idToSortingCriteria,
                toGroup.deprioritizedQuantities
              )
            )
            .map((listing, index) => ({
              ...listing,
              priority: index + 1,
            })),
        };
      }

      setValue('mergeListingGroupInputs', Object.values(idToGroup));
      moveListingGroupDialog.closeDialog();
    },
    [
      idToSortingCriteria,
      listingGroup.listingGroupId,
      listingGroups,
      listings,
      moveListingGroupDialog,
      setValue,
      targetListingId,
    ]
  );

  const columns = usePreviewTableColumns(
    listingGroup.desiredActiveListings,
    isUngroupedGroup,
    onRemoveListingFromGroup,
    onMoveListing
  );

  const table = useReactTable({
    data: listings,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id + '',
    sortingFns: {},
  });

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = listingIds.indexOf(active.id);
      const newIndex = listingIds.indexOf(over.id);
      const reordered = arrayMove(listings, oldIndex, newIndex);
      const updateGroupItems = reordered.map(
        (listing, index): ListingGroupItemInput => {
          const { undAbsAmt, undRelAmt } =
            listingGroup.groupUndercutSetting ?? {};
          const undercutSetting: ListingGroupUndercutSetting = {
            undAbsAmt: undAbsAmt ? undAbsAmt * index : null,
            undRelAmt: undRelAmt ? undRelAmt * index : null,
            actRankUndAbsAmt: null,
            actRankUndRelAmt: null,
          };
          return {
            listingId: listing.id,
            groupId: listing.ltGrpId,
            priority: index + 1,
            undercutSetting,
          };
        }
      );
      setListingGroupValue({
        listingGroupItems: updateGroupItems,
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <table style={{ width: '100%' }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={styles.tableHeader}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            <SortableContext
              items={listingIds}
              strategy={verticalListSortingStrategy}
              key={new Date().getTime()}
            >
              {table.getRowModel().rows.map((row) => (
                <DraggableRow key={row.id} row={row} />
              ))}
            </SortableContext>
          </tbody>
        </table>
      </DndContext>
      <MoveListingGroupDialog
        {...moveListingGroupDialog.dialogProps}
        currentListingGroupId={listingGroup.listingGroupId}
        listingGroups={listingGroups}
        listings={eventListings}
        onMoveListingToGroup={onMoveListingToGroup}
        onClose={moveListingGroupDialog.closeDialog}
      />
    </>
  );
};
