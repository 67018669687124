import { sum } from 'lodash-es';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { newGuidId } from 'src/utils/idUtils';
import { Listing, MergeListingGroupInput } from 'src/WebApiController';

import * as styles from './GroupListingsPreview.css';
import { UngroupedListingGroupId } from './GroupListingsPreviewTable.constants';

export type MoveListingGroupItem = Pick<
  MergeListingGroupInput,
  'listingGroupId' | 'name' | 'listingGroupItems'
>;

type MoveListingGroupDialogProps = ComponentProps<typeof RSModal> & {
  onClose: () => void;
  currentListingGroupId?: string;
  listings: Listing[];
  listingGroups: MoveListingGroupItem[];
  onMoveListingToGroup: (
    targetListingGroupId: string,
    newGroupName?: string
  ) => void;
  canAddNewGroup?: boolean;
};

export const MoveListingGroupDialog: React.FC<MoveListingGroupDialogProps> = ({
  currentListingGroupId,
  listingGroups,
  listings,
  onMoveListingToGroup,
  onClose,
  canAddNewGroup = false,
  ...rest
}) => {
  const [targetListingGroupId, setTargetListingGroupId] = useState<string>();
  const [groupMode, setGroupMode] = useState<'Select' | 'Create'>('Select');
  const [newGroupName, setNewGroupName] = useState<string | null>(null);
  const [groups, setGroups] = useState<MoveListingGroupItem[]>(listingGroups);

  const onConfirm = useCallback(() => {
    if (groupMode === 'Select') {
      if (targetListingGroupId) {
        const targetGroup = groups.find(
          (group) => group.listingGroupId === targetListingGroupId
        );
        onMoveListingToGroup(targetListingGroupId, targetGroup?.name);
      }
    } else {
      if (!newGroupName) {
        return;
      }
      const newListingGroupId = newGuidId();
      setGroups((prev) => {
        return [
          ...prev,
          {
            listingGroupId: newListingGroupId,
            name: newGroupName,
            listingGroupItems: [],
          },
        ];
      });
      setNewGroupName(null);
      setGroupMode('Select');
      setTargetListingGroupId(newListingGroupId);
    }
  }, [
    groupMode,
    groups,
    newGroupName,
    onMoveListingToGroup,
    targetListingGroupId,
  ]);

  const idToListing = useMemo(() => {
    return listings.reduce(
      (res, listing) => {
        res[listing.id] = listing;
        return res;
      },
      {} as Record<string, Listing>
    );
  }, [listings]);

  const [groupOptions, contentOptions] = useMemo(() => {
    const groupOptions = groups.reduce(
      (res, group) => {
        if (
          group.listingGroupId !== currentListingGroupId &&
          group.listingGroupId !== UngroupedListingGroupId
        ) {
          res[group.listingGroupId] = group.name;
        }
        return res;
      },
      {} as Record<string, string>
    );

    const contentOptions = groups.reduce(
      (res, group) => {
        if (
          group.listingGroupId !== currentListingGroupId &&
          group.listingGroupId !== UngroupedListingGroupId
        ) {
          const groupListings = group.listingGroupItems.map(
            (item) => idToListing[item.listingId]
          );
          const availableQuantity = sum(
            groupListings.map((listing) => listing?.availQty ?? 0)
          );
          const total = sum(
            groupListings.map((listing) => listing?.ticketCnt ?? 0)
          );
          res[group.listingGroupId] = (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="spaceBetween"
              className={styles.overrideSelectionWidth}
            >
              <div className={styles.groupName}>{group.name}</div>
              <Stack alignItems="center" gap="m">
                <Stack alignItems="center" gap="s">
                  <div className={styles.inputPrefix}>
                    <Content id={ContentId.QuantityAbbreviated} />:
                  </div>
                  <div className={styles.numberFixedLength}>{total}</div>
                </Stack>
                <Stack alignItems="center" gap="s">
                  <div className={styles.inputPrefix}>
                    <Content id={ContentId.Unsold} />:
                  </div>
                  <div className={styles.numberFixedLength}>
                    {availableQuantity}
                  </div>
                </Stack>
              </Stack>
            </Stack>
          );
        }
        return res;
      },
      {} as Record<string, ReactNode>
    );
    return [groupOptions, contentOptions];
  }, [currentListingGroupId, idToListing, groups]);

  const content = useMemo(() => {
    if (groupMode === 'Select') {
      return (
        <Stack direction="column" alignItems="start" gap="m">
          <div>
            <Content id={ContentId.SelectGroupToMoveTo} />:
          </div>
          <PosSelect
            value={targetListingGroupId}
            onChange={setTargetListingGroupId}
            valueOptionsContent={groupOptions}
            valueOptionsContentOverride={contentOptions}
            style={{ width: '100%' }}
          />
        </Stack>
      );
    }

    return (
      <Stack direction="column" alignItems="start" gap="m">
        <div>
          <Content id={ContentId.AddNameToCreateGroup} />:
        </div>
        <PosTextField
          value={newGroupName ?? undefined}
          style={{ width: '100%' }}
          type="text"
          onChange={(e) => {
            const newValue = e.target.value?.length ? e.target.value : null;
            if (newGroupName !== newValue) {
              setNewGroupName(newValue);
            }
          }}
          maxLength={200}
        />
      </Stack>
    );
  }, [
    contentOptions,
    groupMode,
    groupOptions,
    newGroupName,
    targetListingGroupId,
  ]);

  const actionButton = useMemo(() => {
    if (!canAddNewGroup) {
      return undefined;
    }
    if (groupMode === 'Select') {
      return (
        <Button variant="textPlain" onClick={() => setGroupMode('Create')}>
          <Content id={ContentId.CreateNewGroup} />
        </Button>
      );
    }
    return (
      <Button variant="textPlain" onClick={() => setGroupMode('Select')}>
        <Content id={ContentId.MoveToExistingGroup} />
      </Button>
    );
  }, [canAddNewGroup, groupMode]);

  const readyToSubmit = useMemo(() => {
    if (groupMode === 'Select') {
      return targetListingGroupId;
    }
    return newGroupName;
  }, [groupMode, newGroupName, targetListingGroupId]);

  return (
    <>
      <GenericDialog
        {...rest}
        size="m"
        header={<Content id={ContentId.MoveTo} />}
        footer={
          <Stack direction="row" width="full" justifyContent="spaceBetween">
            <Stack>{actionButton}</Stack>
            <Stack direction="row" gap="m">
              <CancelButton onClick={onClose} />
              <OkButton
                onClick={onConfirm}
                disabled={!readyToSubmit}
                textContentId={
                  groupMode === 'Select' ? ContentId.Save : ContentId.Add
                }
              />
            </Stack>
          </Stack>
        }
        onCancel={onClose}
      >
        {content}
      </GenericDialog>
    </>
  );
};
