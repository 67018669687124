import { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  getCompleteEventConfigScoreOverrides,
  getEventConfigScoreOverride,
} from 'src/utils/seatScoreUtils';
import {
  AutoPricingInputs,
  Event,
  Feature,
  ScoreModel,
  SectionScoreOverride,
  VenueMapInfo,
} from 'src/WebApiController';

import { VenueZoneMapFormType } from '../InventoryEventVenueZoneMapSection';

type UseVenueSectionRowProps = {
  event: Event;
  pricingSettingsQuery: UseQueryResult<AutoPricingInputs | null, Error>;
};

export const useVenueSectionRow = ({
  event,
  pricingSettingsQuery,
}: UseVenueSectionRowProps) => {
  const {
    activeConfigOverride,
    venueMapInfo,
    venueMapsByScoreModelQuery,
    mapConfigOverridesQuery,
  } = useEventMapContext();

  const hasScaledSeatScoreFeature = useUserHasFeature(Feature.ScaledSeatScore);

  const { watch, setValue } = useFormContext<VenueZoneMapFormType>();

  const sanitizedScoreModel = useSanitizedScoreModel(
    pricingSettingsQuery.data?.scoreModel,
    venueMapsByScoreModelQuery.data
  );

  const modeledSectionScores = useModeledSectionScores(
    sanitizedScoreModel,
    venueMapsByScoreModelQuery.data
  );

  const scoreOverrides = useMemo(() => {
    const watchedOverrides = watch('eventScoreOverride.scoreOverrides');
    return (
      watchedOverrides ??
      getCompleteEventConfigScoreOverrides(modeledSectionScores?.sectionScores)
    );
  }, [watch, modeledSectionScores]);

  const onSectionRowChange = useCallback(
    (updated: SectionScoreOverride[]) => {
      const newOverride = getEventConfigScoreOverride(
        event.viagId!,
        modeledSectionScores ?? venueMapInfo!,
        'New Model',
        null
      );
      newOverride.scoreOverrides = updated;
      setValue('eventScoreOverride', newOverride);
    },
    [event, modeledSectionScores, setValue, venueMapInfo]
  );

  return {
    mapConfigOverridesQuery,
    hasScaledSeatScoreFeature,
    sanitizedScoreModel,
    modeledSectionScores,
    scoreOverrides,
    onSectionRowChange,
    venueMapInfo,
  };
};

const useSanitizedScoreModel = (
  scoreModel?: ScoreModel | null | undefined,
  venueMapData?:
    | {
        readonly VenueGeometry?: VenueMapInfo | undefined;
        readonly MergedVenueGeometry?: VenueMapInfo | undefined;
        readonly AdvancedVenueGeometry?: VenueMapInfo | undefined;
      }
    | null
    | undefined
) => {
  return useMemo(() => {
    if (scoreModel) return scoreModel;

    const { AdvancedVenueGeometry, MergedVenueGeometry } = venueMapData ?? {};

    if (AdvancedVenueGeometry) return ScoreModel.AdvancedVenueGeometry;
    if (MergedVenueGeometry) return ScoreModel.MergedVenueGeometry;
    return ScoreModel.VenueGeometry;
  }, [scoreModel, venueMapData]);
};

const useModeledSectionScores = (
  sanitizedScoreModel: ScoreModel,
  venueMapData?:
    | {
        readonly VenueGeometry?: VenueMapInfo | undefined;
        readonly MergedVenueGeometry?: VenueMapInfo | undefined;
        readonly AdvancedVenueGeometry?: VenueMapInfo | undefined;
      }
    | null
    | undefined
) => {
  return useMemo(() => {
    switch (sanitizedScoreModel) {
      case ScoreModel.AdvancedVenueGeometry:
        return venueMapData?.AdvancedVenueGeometry;
      case ScoreModel.MergedVenueGeometry:
        return venueMapData?.MergedVenueGeometry;
      default:
        return venueMapData?.VenueGeometry;
    }
  }, [sanitizedScoreModel, venueMapData]);
};
