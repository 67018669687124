import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonWithIcon } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Switch } from 'src/core/interim/Switch';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { Slider } from 'src/core/ui/Slider';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { SectionSelectDialog } from 'src/dialogs/SectionSelectDialog';
import { useAvailableAutopricingModes } from 'src/hooks/useAvailableAutopricingModes';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useCompQuantitiesFilter } from 'src/hooks/useCompQuantitiesFilter';
import { useAllInPriceFromListPrice } from 'src/hooks/useListAndAllInPrice';
import { useUndercutAmountSettings } from 'src/hooks/useUndercutAmountSettings';
import { Detail, DetailGroup } from 'src/modals/common';
import { EditIcon, IconsFill, PlusIcon, ResetIcon } from 'src/svgs/Viagogo';
import {
  convertSliderValueFromServer,
  convertSliderValueToServer,
  getUndercutPriceRepresentingMode,
  SLIDER_MAX,
  SLIDER_MIN,
  UNDERCUT_PRICE_MODE_OPTIONS,
  UndercutPriceSettingsMode,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import {
  AutoPricingCompListingMode,
  AutoPricingUndercutMode,
  Listing,
  ListingDetailsAutoPricingSectionUpdates,
} from 'src/WebApiController';

import { useAutoPricingSidePanelContext } from '../../AutoPricingSidePanel';
import { AutoPricingSectionRowIdFilter } from '../components/AutoPricingSectionRowIdFilter';
import { useAutoPricingSettings } from '../useAutoPricingSettings';
import { RestrictSelectionType } from './AutoPricingSection.types';
import { useRestrictSelection } from './AutoPricingSettingsSection.hook';
import * as styles from './PricingSection.css';

export const AutoPricingSettingsSection = ({
  listing,
  disabled,
  listingHasParent,
}: {
  listing?: Listing;
  disabled?: boolean;
  listingHasParent: boolean;
}) => {
  const autoPricingResultStateContent = (
    AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_CID as Record<
      string,
      string | undefined
    >
  )[listing?.autoPrcResult ?? ''];

  const { setValue, clearErrors, formState } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();

  const sectionSelectDialog = useBasicDialog();

  const { contentType, onShowContent, onCloseSidePanel } =
    useAutoPricingSidePanelContext();

  const {
    canSetPrice,
    showAutoPricingSettings,
    uiCurrency,
    currencyNumberFormat,

    onResetSettings,
    eventPricingSettings,
    accountPricingSettings,

    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,

    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingSelectedSectionSettings,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingQuantityScoreAdjustmentOverrideJson,

    onUndercutChange,
    onCompListingChange,
    onCompListingModeChange,

    skipCompListing,
    skipUndercut,
  } = useAutoPricingSettings(listing);

  const {
    undercutAbsoluteAmountMode,
    setUndercutAbsoluteAmountMode,
    undercutRelativeAmountMode,
    setUndercutRelativeAmountMode,
  } = useUndercutAmountSettings(undercutAbsoluteAmount, undercutRelativeAmount);

  const {
    quantitiesFilterOptions,
    quantitiesFilterValue,
    onUpdateQuantitiesFilter,
  } = useCompQuantitiesFilter(
    compListingQuantityScoreAdjustmentOverrideJson,
    onCompListingChange
  );

  const compListingFloorError = formState.errors.compListingFloor?.message;
  const compListingSelectedSectionError =
    formState.errors.compListingSelectedSectionSettings?.message;

  const parsedQuantityOverridesJson = JSON.parse(
    compListingQuantityScoreAdjustmentOverrideJson || 'null'
  );

  const quantityFilterValue: number | null =
    parsedQuantityOverridesJson?.quantityFilter;

  const undercutAbsoluteAmountError =
    formState.errors.undercutAbsoluteAmount?.message;
  const undercutRelativeAmountError =
    formState.errors.undercutRelativeAmount?.message;

  const { activeConfigOverride, venueMapsByScoreModelQuery } =
    useEventMapContext();

  const defaultScoreOverrides = useMemo(() => {
    // In the backend, if AVG scores are missing, then we always fallback to MVG, even if AVG is explicitly selected
    return (
      venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores
    );
  }, [
    venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores,
  ]);

  const scores = getCompleteEventConfigScoreOverrides(
    defaultScoreOverrides,
    activeConfigOverride?.scoreOverrides,
    false
  );

  const {
    compListingModes,
    compListingModesDisabled,
    undercutModes,
    undercutModesDisabled,
    defaultRestrictSelectionType,
    restrictSelectionTypes,
  } = useAvailableAutopricingModes(
    true,
    undercutMode,
    compListingMode,
    Boolean(scores?.some((s) => s.score)),
    listingHasParent,
    false,
    compListingOnlyForSelectedSectionsEnabled
  );

  if (
    compListingMode == AutoPricingCompListingMode.QualityScore &&
    compListingModesDisabled[AutoPricingCompListingMode.QualityScore] != null
  ) {
    setValue('compListingMode', AutoPricingCompListingMode.SameEvent);
    setValue('compListingOnlyForSameZoneEnabled', false);
  }

  const {
    restrictSelectionType,
    onRestrictSelectionTypeChange,
    disabledSectionIds,
  } = useRestrictSelection(
    listing,
    defaultRestrictSelectionType as RestrictSelectionType,
    restrictSelectionTypes
  );

  const isDealScoreMode =
    (undercutMode ??
      eventPricingSettings?.undercutMode ??
      accountPricingSettings?.undercutMode) === AutoPricingUndercutMode.Deal;

  const isParentListingMode =
    (compListingMode ??
      eventPricingSettings?.compListingMode ??
      accountPricingSettings?.compListingMode) ===
    AutoPricingCompListingMode.ParentListing;

  const showCompListingSettingsDetails = !(
    !compListingMode ||
    compListingMode === AutoPricingCompListingMode.SameZone ||
    compListingMode === AutoPricingCompListingMode.SameEvent ||
    compListingMode === AutoPricingCompListingMode.ParentListing
  );

  const showCompListingRestictSelectionDetails =
    compListingMode != null && Object.values(restrictSelectionTypes).length > 0;

  const hasAnyActiveRestrictionApplied = useMemo(() => {
    let applied = false;
    const restirctSelectionTypes = Object.keys(restrictSelectionTypes);
    restirctSelectionTypes.forEach((type) => {
      if (type === RestrictSelectionType.SameZone) {
        applied = applied || compListingOnlyForSameZoneEnabled === true;
      }
      if (
        type === RestrictSelectionType.SpecificSections ||
        type === RestrictSelectionType.SameSection
      ) {
        applied = applied || compListingOnlyForSelectedSectionsEnabled === true;
      }
    });

    return applied;
  }, [
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    restrictSelectionTypes,
  ]);

  const isCompListingAmountDisabled =
    disabled ||
    listing?.isDeleted ||
    !canSetPrice ||
    !compListingMode ||
    compListingMode === AutoPricingCompListingMode.SameZone ||
    compListingMode === AutoPricingCompListingMode.SameEvent ||
    compListingMode === AutoPricingCompListingMode.ParentListing;

  useEffect(() => {
    if (
      contentType === 'sectionSelection' &&
      (!showCompListingRestictSelectionDetails ||
        !hasAnyActiveRestrictionApplied)
    ) {
      onCloseSidePanel();
    }
  }, [
    showCompListingRestictSelectionDetails,
    hasAnyActiveRestrictionApplied,
    contentType,
    onShowContent,
    onCloseSidePanel,
  ]);

  const onLaunchSectionSelection = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (listing?.id) {
        onShowContent('sectionSelection', e);
      } else {
        sectionSelectDialog.launchDialog();
      }
    },
    [listing?.id, onShowContent, sectionSelectDialog]
  );

  const marketPrice = useAllInPriceFromListPrice(listing?.undNewPrice, listing);

  if (!showAutoPricingSettings) return null;

  return (
    <>
      {skipCompListing ? null : (
        <>
          <DetailGroup
            style={{ gridColumn: '1' }}
            className={styles.autoPricingLabel}
          >
            <Content id={ContentId.CompListingModePrompt} />
          </DetailGroup>
          <DetailGroup style={{ gridColumn: '1' }}>
            <Stack gap="l">
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.AllListings} />
              </div>
              <PosEnumSelect
                value={
                  compListingMode ??
                  eventPricingSettings?.compListingMode ??
                  accountPricingSettings?.compListingMode
                }
                disabled={disabled || Boolean(skipCompListing)}
                valueOptionsContent={compListingModes}
                valueOptionsDisabled={compListingModesDisabled}
                placeholderText={ContentId.None}
                onClick={(e) => e.stopPropagation()}
                onChange={onCompListingModeChange}
              />
            </Stack>
          </DetailGroup>
          {showCompListingSettingsDetails && (
            <>
              <DetailGroup style={{ gridColumn: '1' }}>
                <Stack gap="l">
                  <div className={styles.inlinePricingInputText}>
                    <Content id={ContentId.Between} />
                  </div>
                  <PosFormField
                    errors={compListingFloorError}
                    style={{ width: 'fit-content' }}
                  >
                    <PosTextField
                      rootProps={{
                        state: getTextFieldState(compListingFloorError),
                        style: { maxWidth: '100px' },
                      }}
                      type="number"
                      value={
                        compListingMode == null
                          ? ''
                          : roundToPrecision((compListingFloor - 1) * 100, 2)
                      }
                      onChange={(e) => {
                        const v = parseFloat(e.target.value);
                        if (!isNaN(v)) {
                          if (
                            v >= SLIDER_MIN * 100 &&
                            v <= SLIDER_MAX * 100 &&
                            convertSliderValueToServer(v) < compListingCeiling
                          ) {
                            onCompListingChange({
                              compListingFloorNew:
                                convertSliderValueToServer(v),
                            });
                          }
                        }
                      }}
                      postfixDisplay="%"
                      placeholder={
                        compListingMode == null
                          ? eventPricingSettings?.compListingFloor?.toString() ??
                            accountPricingSettings?.compListingFloor?.toString() ??
                            undefined
                          : undefined
                      }
                    />
                  </PosFormField>
                  <div className={styles.inlinePricingInputTextLowercase}>
                    <Content id={ContentId.And} />
                  </div>
                  <PosFormField style={{ width: 'fit-content' }}>
                    <PosTextField
                      rootProps={{
                        style: { maxWidth: '100px' },
                      }}
                      type="number"
                      value={
                        compListingMode == null
                          ? ''
                          : roundToPrecision((compListingCeiling - 1) * 100, 2)
                      }
                      onChange={(e) => {
                        const v = parseFloat(e.target.value);
                        if (!isNaN(v)) {
                          if (
                            v >= SLIDER_MIN * 100 &&
                            v <= SLIDER_MAX * 100 &&
                            convertSliderValueToServer(v) > compListingFloor
                          ) {
                            onCompListingChange({
                              compListingCeilingNew:
                                convertSliderValueToServer(v),
                            });
                          }
                        }
                      }}
                      postfixDisplay="%"
                      placeholder={
                        compListingMode == null
                          ? eventPricingSettings?.compListingCeiling?.toString() ??
                            accountPricingSettings?.compListingCeiling?.toString() ??
                            undefined
                          : undefined
                      }
                    />
                  </PosFormField>
                  <div className={styles.inlinePricingInputTextLowercase}>
                    <Content id={ContentId.OfMyListingQuality} />
                  </div>
                </Stack>
              </DetailGroup>
              <DetailGroup style={{ gridColumn: '1' }}>
                {!isCompListingAmountDisabled && (
                  <Stack direction="column" gap="m">
                    <Slider.Root
                      disabled={disabled || Boolean(skipCompListing)}
                      min={SLIDER_MIN * 100}
                      max={SLIDER_MAX * 100}
                      step={0.01}
                      minStepsBetweenThumbs={0.01}
                      value={[compListingFloor, compListingCeiling].map(
                        convertSliderValueFromServer
                      )}
                      renderHandle={(val) => (
                        <Slider.Handle>
                          {roundToPrecision(val, 0)}%
                        </Slider.Handle>
                      )}
                      onValueChange={([floor, ceil]) => {
                        onCompListingChange({
                          compListingFloorNew:
                            convertSliderValueToServer(floor),
                          compListingCeilingNew:
                            convertSliderValueToServer(ceil),
                        });
                      }}
                    />
                    <div className={styles.yourListingIndicatorContainer}>
                      <Content id={ContentId.YourListing} />
                      <div className={styles.yourListingIndicatorArrow} />
                    </div>
                  </Stack>
                )}
              </DetailGroup>
            </>
          )}
          {showCompListingRestictSelectionDetails && (
            <DetailGroup
              style={{ gridColumn: '1', width: '100%', overflow: 'hidden' }}
            >
              <PosFormField
                label={<Content id={ContentId.QuantitiesFilter} />}
                style={{ width: '100%' }}
              >
                <PosMultiSelect
                  disabled={disabled}
                  align="start"
                  values={quantitiesFilterValue ?? []}
                  onChange={onUpdateQuantitiesFilter}
                  valueOptionsContent={quantitiesFilterOptions}
                />
              </PosFormField>

              <PosFormField
                label={<Content id={ContentId.RestrictSelection} />}
                style={{ width: '100%' }}
              >
                <Switch
                  disabled={disabled || listing?.isDeleted || !canSetPrice}
                  checked={hasAnyActiveRestrictionApplied}
                  onClick={(e: ChangeEvent<HTMLElement>) => e.stopPropagation()}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      if (!hasAnyActiveRestrictionApplied) {
                        onCompListingChange({
                          compListingOnlyForSameZoneEnabledNew:
                            defaultRestrictSelectionType ===
                            RestrictSelectionType.SameZone,
                          compListingOnlyForSelectedSectionsEnabledNew:
                            defaultRestrictSelectionType ===
                            RestrictSelectionType.SpecificSections,
                        });
                      }
                    } else {
                      onCompListingChange({
                        compListingOnlyForSameZoneEnabledNew: false,
                        compListingOnlyForSelectedSectionsEnabledNew: false,
                        compListingSelectedSectionSettingsNew: null,
                      });
                    }
                  }}
                />
              </PosFormField>
              {hasAnyActiveRestrictionApplied && (
                <Stack gap="l">
                  <div className={styles.inlinePricingInputText}>
                    <Content id={ContentId.RestrictTo} />
                  </div>
                  <PosEnumSelect
                    value={restrictSelectionType}
                    disabled={
                      disabled || Boolean(skipCompListing) || !canSetPrice
                    }
                    valueOptionsContent={restrictSelectionTypes}
                    placeholderText={ContentId.None}
                    onClick={(e) => e.stopPropagation()}
                    onChange={onRestrictSelectionTypeChange}
                  />
                </Stack>
              )}
              {hasAnyActiveRestrictionApplied && (
                <PosFormField errors={compListingSelectedSectionError}>
                  <Stack
                    direction="column"
                    gap="l"
                    alignItems="start"
                    width="full"
                  >
                    {compListingSelectedSectionSettings?.sectionIdFilter
                      ?.length ? (
                      <>
                        <Content id={ContentId.SelectedSections} />
                        <AutoPricingSectionRowIdFilter
                          listing={listing}
                          disabled={disabled}
                        />
                        <ButtonWithIcon
                          variant="textPlain"
                          icon={
                            <EditIcon
                              fill={IconsFill.textBrand}
                              size={vars.iconSize.s}
                              withHoverEffect
                            />
                          }
                          textContentId={ContentId.EditSections}
                          onClick={onLaunchSectionSelection}
                          disabled={disabled}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        />
                      </>
                    ) : (
                      <ButtonWithIcon
                        variant="textPlain"
                        shape="none"
                        icon={
                          <PlusIcon
                            fill={IconsFill.textBrand}
                            size={vars.iconSize.s}
                            withHoverEffect
                          />
                        }
                        textContentId={ContentId.SelectSections}
                        onClick={onLaunchSectionSelection}
                        disabled={disabled}
                        style={{ padding: 0 }}
                      />
                    )}
                  </Stack>
                </PosFormField>
              )}
            </DetailGroup>
          )}
        </>
      )}

      {skipUndercut ? null : (
        <>
          <DetailGroup
            style={{ gridColumn: '1' }}
            className={styles.autoPricingLabel}
          >
            <Content id={ContentId.UnderCutModePrompt} />
          </DetailGroup>
          {isParentListingMode ? (
            <div className={styles.inlinePricingInputText}>
              <Content id={ContentId.TakeMyParentListingAnd} />
            </div>
          ) : (
            <>
              <DetailGroup style={{ gridColumn: '1' }}>
                <Stack gap="l">
                  <div className={styles.inlinePricingInputText}>
                    <Content id={ContentId.FindThe} />
                  </div>
                  <PosEnumSelect
                    value={undercutMode}
                    disabled={disabled || Boolean(skipUndercut)}
                    valueOptionsContent={undercutModes}
                    valueOptionsDisabled={undercutModesDisabled}
                    placeholderText={ContentId.None}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(v) => {
                      clearErrors('undercutAbsoluteAmount');
                      clearErrors('undercutRelativeAmount');
                      if (v) {
                        onUndercutChange(v as AutoPricingUndercutMode);
                      } else {
                        onUndercutChange(null, null, null);
                      }
                    }}
                  />
                  <div className={styles.inlinePricingInputText}>
                    <Content id={ContentId.OfTheComparableListings} />
                  </div>
                </Stack>
              </DetailGroup>
              {isDealScoreMode && (
                <div className={styles.inlinePricingInputText}>
                  <Content id={ContentId.BestDealScorePrompt} />
                </div>
              )}
            </>
          )}
          <DetailGroup style={{ gridColumn: '1' }}>
            <Stack gap="l">
              <ToggleGroup
                disabled={
                  !undercutMode ||
                  !undercutRelativeAmount ||
                  Boolean(skipUndercut)
                }
                options={UNDERCUT_PRICE_MODE_OPTIONS}
                value={undercutRelativeAmountMode}
                onValueChange={(value) => {
                  if (!value) return;

                  const mode = value as UndercutPriceSettingsMode;
                  setUndercutRelativeAmountMode(mode);

                  if (undercutRelativeAmount) {
                    const curAmountAbs = Math.abs(undercutRelativeAmount);
                    onUndercutChange(
                      undefined,
                      undefined,
                      getUndercutPriceRepresentingMode(curAmountAbs, mode)
                    );
                  }
                }}
              />

              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.PriceBy} />
              </div>

              <PosFormField
                errors={undercutRelativeAmountError}
                style={{ width: 'fit-content' }}
              >
                <PosTextField
                  rootProps={{
                    state: getTextFieldState(undercutRelativeAmountError),
                    style: { maxWidth: '100px' },
                    disabled: !undercutMode,
                  }}
                  disabled={!undercutMode || Boolean(skipUndercut)}
                  value={
                    undercutMode == null
                      ? ''
                      : undercutRelativeAmount != null
                      ? roundToPrecision(
                          Math.abs(undercutRelativeAmount) * 100,
                          8
                        )
                      : ''
                  }
                  placeholder={
                    undercutMode == null
                      ? eventPricingSettings?.undercutRelativeAmount?.toString() ??
                        accountPricingSettings?.undercutRelativeAmount?.toString() ??
                        undefined
                      : undefined
                  }
                  type="number"
                  postfixDisplay="%"
                  onChange={(e) => {
                    clearErrors('undercutAbsoluteAmount');
                    clearErrors('undercutRelativeAmount');
                    const v = parseFloat(e.target.value);
                    if (v >= 0 && v <= Number.MAX_VALUE) {
                      const relativeValue = Math.min(v, 100) / 100;
                      if (
                        undercutRelativeAmountMode ===
                        UndercutPriceSettingsMode.Premium
                      ) {
                        onUndercutChange(undefined, undefined, -relativeValue);
                      } else {
                        onUndercutChange(undefined, undefined, relativeValue);
                      }
                    } else {
                      onUndercutChange(undefined, undefined, null);
                    }
                  }}
                />
              </PosFormField>
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.Then} />
              </div>
              <ToggleGroup
                disabled={
                  !undercutMode ||
                  !undercutAbsoluteAmount ||
                  Boolean(skipUndercut)
                }
                options={UNDERCUT_PRICE_MODE_OPTIONS}
                value={undercutAbsoluteAmountMode}
                onValueChange={(value) => {
                  if (!value) return;

                  const mode = value as UndercutPriceSettingsMode;
                  setUndercutAbsoluteAmountMode(mode);

                  if (undercutAbsoluteAmount) {
                    const curAmountAbs = Math.abs(undercutAbsoluteAmount);
                    onUndercutChange(
                      undefined,
                      getUndercutPriceRepresentingMode(curAmountAbs, mode)
                    );
                  }
                }}
              />
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.By} />
              </div>
              <PosFormField
                errors={undercutAbsoluteAmountError}
                style={{ width: 'fit-content' }}
              >
                <PosCurrencyField
                  rootProps={{
                    state: getTextFieldState(undercutAbsoluteAmountError),
                    style: { maxWidth: '100px' },
                    disabled: !undercutMode,
                  }}
                  uiCurrency={uiCurrency}
                  disabled={!undercutMode || Boolean(skipUndercut)}
                  value={
                    undercutMode == null
                      ? ''
                      : undercutAbsoluteAmount != null
                      ? Math.abs(undercutAbsoluteAmount)
                      : ''
                  }
                  placeholder={
                    undercutMode == null
                      ? eventPricingSettings?.undercutAbsoluteAmount?.toString() ??
                        accountPricingSettings?.undercutAbsoluteAmount?.toString() ??
                        undefined
                      : undefined
                  }
                  onChange={(e) => {
                    clearErrors('undercutAbsoluteAmount');
                    clearErrors('undercutRelativeAmount');
                    const v = parseFloat(e.target.value);
                    if (v >= 0 && v <= Number.MAX_VALUE) {
                      if (
                        undercutAbsoluteAmountMode ===
                        UndercutPriceSettingsMode.Premium
                      ) {
                        onUndercutChange(undefined, -v);
                      } else {
                        onUndercutChange(undefined, v);
                      }
                    } else {
                      onUndercutChange(undefined, null);
                    }
                  }}
                />
              </PosFormField>
            </Stack>
          </DetailGroup>
        </>
      )}
      {autoPricingResultStateContent && (
        <DetailGroup style={{ gridColumn: 1 }}>
          <Stack direction="row" justifyContent="spaceBetween">
            <Detail
              label={<Content id={ContentId.AutoPricingResultType} />}
              detail={
                <span>
                  <Content id={autoPricingResultStateContent} />
                </span>
              }
            />
            {marketPrice != null && (
              <Detail
                label={<Content id={ContentId.MarketPrice} />}
                detail={<span>{currencyNumberFormat.format(marketPrice)}</span>}
              />
            )}
          </Stack>
        </DetailGroup>
      )}
      <DetailGroup style={{ gridColumn: 1 }}>
        <ButtonWithIcon
          variant="text"
          icon={<ResetIcon withHoverEffect />}
          textContentId={ContentId.ResetSettings}
          onClick={onResetSettings}
        />
      </DetailGroup>
      <SectionSelectDialog
        {...sectionSelectDialog.dialogProps}
        initialSectionIds={
          compListingSelectedSectionSettings?.sectionIdFilter ?? []
        }
        disabledSectionIds={disabledSectionIds}
        onSave={(selectedSections) => {
          const compListingSelectedSectionSettingsNew = {
            ...compListingSelectedSectionSettings,
            sectionIdFilter: selectedSections.map((s) => s.id),
            rowIdFilter: compListingSelectedSectionSettings?.rowIdFilter ?? [],
            sectionRowIdFilter:
              compListingSelectedSectionSettings?.sectionRowIdFilter ?? {},
          };
          onCompListingChange({
            compListingOnlyForSameZoneEnabledNew: false,
            compListingOnlyForSelectedSectionsEnabledNew: true,
            compListingSelectedSectionSettingsNew,
          });
          sectionSelectDialog.closeDialog();
          clearErrors('compListingSelectedSectionSettings');
        }}
        onClosed={sectionSelectDialog.closeDialog}
      />
    </>
  );
};
