import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { isSuccess } from 'src/utils/errorUtils';
import { compareMarketplace } from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingDetailsBroadcastSectionUpdates,
  Marketplace,
} from 'src/WebApiController';

const getListingDetailsBroadcastInput = (
  listing: ListingDetails | null | undefined
): ListingDetailsBroadcastSectionUpdates => {
  return {
    id: listing?.id ?? 0,
    rowVersion: null,
    adminHoldNotes: listing?.adminHoldNotes ?? null,
    enableNativeSync: listing?.enableNativeSync ?? null,
    marketplaceSeatOverrides:
      listing?.mkpListings
        ?.filter((m) => m.mkp && m.mkp !== Marketplace.Offline)
        ?.sort((a, b) => compareMarketplace(a.mkp, b.mkp))
        ?.map((m) => ({
          marketplace: m.mkp,
          overrideSection: m.overrideSection,
          overrideRow: m.overrideRow,
          overrideSeats: m.overrideSeats,
          overrideRowId: m.overrideRowId,
          overrideSectionId: m.overrideSectionId,
        })) ?? [],
  };
};

export const useBroadcastForm = (
  listing: ListingDetails | null | undefined,
  onSubmitStart: () => void,
  onSubmitDone: (success?: boolean) => Promise<void>
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const methods = useForm<ListingDetailsBroadcastSectionUpdates>({
    defaultValues: listing?.dataIsLoaded[ListingDetailDataField.Basic]
      ? getListingDetailsBroadcastInput(listing)
      : undefined,
  });

  useEffect(() => {
    if (
      methods.formState.defaultValues == null &&
      listing?.dataIsLoaded[ListingDetailDataField.Basic]
    ) {
      methods.reset(getListingDetailsBroadcastInput(listing));
    }
  }, [methods, listing]);

  const onSubmitHandler = useCallback(
    async (broadcastSectionForm: ListingDetailsBroadcastSectionUpdates) => {
      tryInvokeApi(
        async () => {
          onSubmitStart();
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).updateListingBroadcastSection(broadcastSectionForm);

          if (isSuccess(result)) {
            // Reset the form to latest changed
            methods.reset(broadcastSectionForm);
            await onSubmitDone();
          } else {
            showErrorDialog(
              'ListingClient.updateListingBroadcastSection',
              { message: result.message, status: result.status },
              {
                trackErrorData: {
                  broadcastSectionForm,
                },
              }
            );
          }
        },
        (error) => {
          showErrorDialog(
            'ListingClient.updateListingBroadcastSection',
            error,
            {
              trackErrorData: broadcastSectionForm,
            }
          );
        },
        () => {
          onSubmitDone(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      methods,
      onSubmitDone,
      onSubmitStart,
      showErrorDialog,
    ]
  );

  const onSubmit = useCallback(() => {
    methods.handleSubmit(onSubmitHandler)();
  }, [methods, onSubmitHandler]);

  const onReset = useCallback(() => {
    methods.reset(getListingDetailsBroadcastInput(listing));
  }, [listing, methods]);

  const input = methods.watch();

  const hasChanges = useMemo(() => {
    const defaultValues = methods.formState.defaultValues;
    return (
      input.adminHoldNotes !== defaultValues?.adminHoldNotes ||
      input.enableNativeSync !== defaultValues?.enableNativeSync ||
      !isEqual(
        input.marketplaceSeatOverrides,
        defaultValues?.marketplaceSeatOverrides
      )
    );
  }, [input, methods.formState.defaultValues]);

  return {
    hasChanges,
    methods,
    onSubmit,
    onReset,
  };
};
