import { isEmpty } from 'lodash-es';
import React, {
  forwardRef,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui/Stack';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { ContentId } from 'src/utils/constants/contentId';

const enum RelativeAbsoluteOptions {
  Relative = 'relative',
  Absolute = 'absolute',
}

const RelativeAbsoluteGroupOptions = [
  { value: RelativeAbsoluteOptions.Relative, children: '%' },
  { value: RelativeAbsoluteOptions.Absolute, children: '$' },
];

const AboveBelowGroupOptions = [
  { value: 'true', children: <Content id={ContentId.Above} /> },
  { value: 'false', children: <Content id={ContentId.Below} /> },
];

type FloorAdjustmentInputProps = {
  amount: number | null;
  isRelative?: boolean | null;
  onChange: (value: number | null, isRelative: boolean) => void;
  disabled?: boolean;
};

export const FloorAdjustmentInput = forwardRef<
  HTMLInputElement,
  FloorAdjustmentInputProps
>(function PosCurrencyField(
  { amount, isRelative, onChange, disabled },
  ref
): ReactElement {
  const [isAbove, setIsAbove] = useState<boolean>(!!amount && amount > 0);

  const onValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value);
      if (value < 0 || isNaN(value)) {
        onChange(null, !!isRelative);
        return;
      }
      // we can't decrease by 100 or more % because that will result in zero
      if (!isAbove && isRelative && value > 99) {
        return;
      }

      const sanitizedValue = isAbove ? value : -value;
      onChange(sanitizedValue, !!isRelative);
    },
    [isAbove, isRelative, onChange]
  );

  const onRelativeChange = useCallback(
    (value: string) => {
      if (isEmpty(value)) {
        return;
      }
      const updatedRelative = value === RelativeAbsoluteOptions.Relative;
      onChange(amount, updatedRelative);
    },
    [amount, onChange]
  );
  const onAboveChange = useCallback(
    (value: string) => {
      if (isEmpty(value)) {
        return;
      }
      const updatedIsAbove = value === 'true';
      setIsAbove(updatedIsAbove);
      const sanitizedValue = amount
        ? updatedIsAbove
          ? Math.abs(amount)
          : -Math.abs(amount)
        : null;
      onChange(sanitizedValue, !!isRelative);
    },
    [amount, isRelative, onChange]
  );

  const relativeValue = useMemo(() => {
    if (isRelative == null) {
      return undefined;
    }
    return isRelative
      ? RelativeAbsoluteOptions.Relative
      : RelativeAbsoluteOptions.Absolute;
  }, [isRelative]);

  return (
    <Stack direction="row" gap="m" alignItems="center" width="full">
      <PosTextField
        ref={ref}
        disabled={disabled}
        value={amount ? (isAbove ? amount : -amount) : undefined}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        min={0}
        type="number"
        inputMode="numeric"
        onChange={onValueChange}
        postfixDisplay={
          <>
            <ToggleGroup
              disabled={disabled}
              itemStyle={{
                fontSize: vars.typography.fontSize['xs'],
                height: '22px',
              }}
              options={RelativeAbsoluteGroupOptions}
              value={relativeValue}
              onValueChange={onRelativeChange}
            />
            <ToggleGroup
              disabled={disabled}
              itemStyle={{
                fontSize: vars.typography.fontSize['xs'],
                height: '22px',
              }}
              options={AboveBelowGroupOptions}
              value={isAbove ? 'true' : 'false'}
              onValueChange={onAboveChange}
            />
          </>
        }
      />
    </Stack>
  );
});
