export function TicketEvolutionSquareLogo({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="3" fill="#D7E6E4" />
      <path
        d="M14.8724 5C14.7705 5.44131 14.2871 5.77535 13.7046 5.77535C13.122 5.77535 12.6373 5.44131 12.5368 5H11.126V13.9987H12.5151C12.5151 13.4759 13.0474 13.0522 13.7032 13.0522C14.3591 13.0522 14.8914 13.4759 14.8914 13.9987H16.2805V5H14.8724Z"
        fill="#F5C028"
      />
      <path
        d="M15.2191 5.40234C14.9136 5.92241 14.3501 6.27274 13.7024 6.27274C13.056 6.27274 12.4911 5.92241 12.1856 5.40234H11.5977V13.5958H12.1856C12.4911 13.0757 13.0546 12.7254 13.7024 12.7254C14.3487 12.7254 14.9136 13.0757 15.2191 13.5958H15.8071V5.40234H15.2191Z"
        stroke="#FEEC8F"
        strokeWidth="0.230839"
        strokeMiterlimit="10"
      />
      <path
        d="M15.809 7.97461H11.5996V9.23609H15.809V7.97461Z"
        fill="#FEEC8F"
      />
      <path d="M15.809 9.5H11.5996V9.89788H15.809V9.5Z" fill="#FEEC8F" />
      <path
        d="M6.44629 7.16382V13.2512C6.84277 13.2593 7.16052 13.5811 7.16052 13.979C7.16052 13.9858 7.15918 13.9912 7.15918 13.998H7.86118C7.87204 13.6042 8.19251 13.2865 8.589 13.2865C8.98551 13.2865 9.30732 13.6029 9.31683 13.998H9.9917C10.0026 13.6042 10.323 13.2865 10.7195 13.2865C10.7236 13.2865 10.7263 13.2878 10.7304 13.2878V7.20184C10.7263 7.20184 10.7236 7.20319 10.7195 7.20319C10.3162 7.20319 9.98897 6.87595 9.98897 6.47266H9.31683C9.31683 6.87595 8.98958 7.20319 8.58629 7.20319C8.183 7.20319 7.85575 6.87595 7.85575 6.47266H7.15374C7.13745 6.85286 6.82919 7.15567 6.44629 7.16382Z"
        fill="#DB322C"
      />
      <path
        d="M10.2073 7.53125H6.96875V11.1744H10.2073V7.53125Z"
        fill="#A82928"
      />
      <path
        d="M10.2073 12.0898H6.96875V12.9059H10.2073V12.0898Z"
        fill="#A82928"
      />
      <path
        d="M5.67773 8.14381C5.67773 8.11664 5.68045 8.09084 5.68588 8.06641H5.4917C5.4917 8.1601 5.41566 8.23614 5.32197 8.23614C5.22827 8.23614 5.15223 8.1601 5.15223 8.06641H4.98521C4.98521 8.1601 4.90917 8.23614 4.81548 8.23614C4.72179 8.23614 4.64575 8.1601 4.64575 8.06641H4.47873C4.47873 8.1601 4.40269 8.23614 4.30899 8.23614C4.2153 8.23614 4.13926 8.1601 4.13926 8.06641H3.97088C3.97088 8.1601 3.89484 8.23614 3.80115 8.23614C3.70745 8.23614 3.63141 8.1601 3.63141 8.06641H3.43724C3.44131 8.09221 3.44538 8.11801 3.44538 8.14381C3.44538 8.38959 3.24578 8.5892 3 8.5892V13.5549C3.24578 13.5549 3.44538 13.7546 3.44538 14.0003H3.63141C3.63141 13.9066 3.70745 13.8306 3.80115 13.8306C3.89484 13.8306 3.97088 13.9066 3.97088 14.0003H4.1379C4.1379 13.9066 4.21394 13.8306 4.30763 13.8306C4.40133 13.8306 4.47737 13.9066 4.47737 14.0003H4.64439C4.64439 13.9066 4.72043 13.8306 4.81412 13.8306C4.90782 13.8306 4.98386 13.9066 4.98386 14.0003H5.15088C5.15088 13.9066 5.22692 13.8306 5.32061 13.8306C5.4143 13.8306 5.49034 13.9066 5.49034 14.0003H5.67637C5.67637 13.7546 5.87598 13.5549 6.12176 13.5549V8.58783C5.87735 8.58783 5.67773 8.38959 5.67773 8.14381Z"
        fill="#479A8B"
      />
      <path
        d="M5.75531 9.08203H3.36816V12.9818H5.75531V9.08203Z"
        fill="#046F6D"
      />
      <path
        d="M3.78145 9.08203H3.61035V13.1977H3.78145V9.08203Z"
        fill="#479A8B"
      />
      <path
        d="M5.51289 9.08203H5.3418V13.1977H5.51289V9.08203Z"
        fill="#479A8B"
      />
    </svg>
  );
}
