import { ListingDetails } from 'src/WebApiController';

import { BuyerSection } from '../ManagedMarketplaceSale/components/BuyerSection';
import { MarketplaceSaleInfoSection } from '../ManagedMarketplaceSale/components/MarketplaceSaleInfoSection';
import { TicketSection } from '../ManagedMarketplaceSale/components/TicketSection';
import { ModalBodyDataContainer } from '../Modal/Modal.styled';
import { MarketplaceSection } from './components/MarketplaceSection';

export const MarketplaceSaleBody = ({
  listing,
  disabled,
}: {
  listing?: ListingDetails;
  disabled?: boolean;
}) => {
  return (
    <ModalBodyDataContainer>
      <MarketplaceSection disabled={disabled} />
      <BuyerSection disabled={disabled} />
      <MarketplaceSaleInfoSection disabled={disabled} />
      <TicketSection
        disabled={disabled}
        maxQuantity={listing?.availQty}
        deliveryType={listing?.delivType}
        tickets={listing?.tickets ?? []}
        listing={listing}
      />
    </ModalBodyDataContainer>
  );
};
