export function TicketmasterSquareLogo({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_581_3717)">
        <rect width="20" height="20" rx="3" fill="#006CE3" />
        <path
          d="M6.89565 6.70784H8.72174L9.14783 4.8266L11.8565 4L11.2478 6.70784H13.5L13.1043 8.44656H10.8522L9.93913 12.4086C9.87826 12.6081 9.81739 12.9501 9.81739 13.2922C9.81739 13.8337 10.213 14.1758 10.7913 14.1758C11.2478 14.1758 11.7043 14.0903 12.0087 13.9762L11.5826 15.8005C11.1565 15.886 10.6696 16 10.213 16C8.5087 16 7.2913 15.3729 7.2913 13.6342C7.2913 12.9216 7.44348 12.2375 7.62609 11.5249L8.32609 8.44656H6.5L6.89565 6.70784Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_581_3717">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
