import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Content,
  getContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { getQuantitiesOptions } from 'src/modals/GroupListings/components/groupingUtils';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from '../../GroupListingsV2.css';
import {
  GroupListingsFormProps,
  GroupSortByType,
} from '../../GroupListingsV2.types';
import { SettingsTitle } from '../SettingsTitle';

type OrderingMethodsProps = {};

export const OrderingMethods: React.FC<OrderingMethodsProps> = () => {
  const { watch, setValue } = useFormContext<GroupListingsFormProps>();
  const groupSortBy = watch('groupSortBy');
  const deprioritizedQuantities = watch('deprioritizedQuantities');
  const desiredActiveListings = watch('desiredActiveListings');

  const contentContext = useContentContext();

  const sortingOptions = useMemo(() => {
    return {
      [GroupSortByType.RowDesc.toString()]: getContent(
        ContentId.RowsAscToDesc,
        contentContext
      ),
      [GroupSortByType.RowAsc.toString()]: getContent(
        ContentId.RowsDescToAsc,
        contentContext
      ),
      [GroupSortByType.SectionDesc.toString()]: getContent(
        ContentId.SectionsAscToDesc,
        contentContext
      ),
      [GroupSortByType.SectionAsc.toString()]: getContent(
        ContentId.SectionsDescToAsc,
        contentContext
      ),
      [GroupSortByType.UnsoldQuantityDesc.toString()]: getContent(
        ContentId.UnsoldQuantitiesHighToLow,
        contentContext
      ),
      [GroupSortByType.UnsoldQuantityAsc.toString()]: getContent(
        ContentId.UnsoldQuantitiesLowToHigh,
        contentContext
      ),
    };
  }, [contentContext]);

  return (
    <Stack direction="column" width="full" alignItems="center" gap="l">
      <Stack direction="row" width="full" justifyContent="spaceBetween">
        <SettingsTitle
          title={<Content id={ContentId.ListingsSortByHeader} />}
          subtitle={<Content id={ContentId.ListingsGroupSortByDescription} />}
        />

        <PosSelect
          value={groupSortBy + ''}
          onChange={(type) =>
            type && setValue('groupSortBy', type as GroupSortByType)
          }
          valueOptionsContent={sortingOptions}
          valueOptionsContentOverride={sortingOptions}
          className={styles.inputStyle}
        />
      </Stack>
      <Stack direction="row" width="full" justifyContent="spaceBetween">
        <SettingsTitle
          title={<Content id={ContentId.ActiveListingsHeader} />}
          subtitle={<Content id={ContentId.ActiveListingsDescription} />}
        />
        <PosTextField
          type="number"
          value={desiredActiveListings ?? undefined}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value >= 0 && value !== desiredActiveListings) {
              setValue('desiredActiveListings', value);
            }
          }}
          rootProps={{ className: styles.inputStyle }}
        />
      </Stack>
      <Stack direction="row" width="full" justifyContent="spaceBetween">
        <SettingsTitle
          title={<Content id={ContentId.DeprioritizeQuantitiesHeader} />}
          subtitle={
            <Content id={ContentId.DeprioritizeQuantitiesDescription} />
          }
        />
        <div className={styles.inputStyle}>
          <PosMultiSelect
            align="start"
            triggerProps={{ style: { width: '100%' } }}
            values={deprioritizedQuantities ?? []}
            onChange={(newValues) => {
              if (!isEqual(newValues, deprioritizedQuantities)) {
                setValue(`deprioritizedQuantities`, newValues);
              }
            }}
            valueOptionsContent={getQuantitiesOptions()}
            valuesDisplayText={(deprioritizedQuantities ?? []).join(', ')}
          />
        </div>
      </Stack>
    </Stack>
  );
};
