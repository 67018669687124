import {
  functionalUpdate,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { MarketplacePaymentLineWithSaleAndEventInfo } from 'src/hooks/useGetMarketplacePaymentLineTableData';
import { useMarketplacePaymentLinesTableColumnsConfig } from 'src/tables/MarketplacePaymentLinesTable/useMarketplacePaymentLinesTableColumnsConfig';
import { Table } from 'src/tables/Table';

const getRowId = (
  row: MarketplacePaymentLineWithSaleAndEventInfo | null,
  index: number
) => (row?.id ?? index).toString();

interface PaymentsTableProps {
  tableData: MarketplacePaymentLineWithSaleAndEventInfo[] | null;
}

export const MarketplacePaymentLinesTable = ({
  tableData,
}: PaymentsTableProps) => {
  const paymentsTableColumnConfig =
    useMarketplacePaymentLinesTableColumnsConfig();
  const [sorting, setSorting] = useState<SortingState>([]);

  const options: Partial<
    TableOptions<MarketplacePaymentLineWithSaleAndEventInfo | null>
  > = useMemo(
    () => ({
      data: tableData ?? [],
      columns: Object.values(paymentsTableColumnConfig),
      state: {
        sorting,
      },
      getRowId: getRowId,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
      },
      sortingFns: {},
    }),
    [tableData, paymentsTableColumnConfig, sorting]
  );

  return (
    <Table
      options={options}
      tableLayout="fixed"
      withOuterPadding={false}
      useVirtuoso={false}
      usePaginationFooter={false}
      roundBorders={true}
    />
  );
};
