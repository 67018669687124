import * as Collapsible from '@radix-ui/react-collapsible';
import clsx from 'clsx';
import React, { useState } from 'react';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ComponentType,
  forwardRef,
  ReactNode,
} from 'react';
import { vars } from 'src/core/themes';
import { mergeProps, styledComponent } from 'src/core/utils';
import { IconsFill, MinusIcon, PlusIcon } from 'src/svgs/Viagogo';

import * as styles from './CollapsibleSetting.css';

export type PosCollapsibleProps = Pick<
  ComponentProps<typeof Collapsible.Root>,
  'open' | 'onOpenChange'
> & {
  trigger?: ReactNode;
  children?: ReactNode;
};

export function PosCollapsible({
  open,
  onOpenChange,
  trigger,
  children,
}: PosCollapsibleProps) {
  return (
    <Root open={open} onOpenChange={onOpenChange}>
      <Trigger>{trigger}</Trigger>
      <Content>{children}</Content>
    </Root>
  );
}

export const Root = styledComponent(Collapsible.Root, styles.root);

export const Trigger = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithoutRef<typeof Collapsible.Trigger> & {
    ExpandIcon?: ComponentType<{ size: string; fill: IconsFill }>;
    FoldIcon?: ComponentType<{ size: string; fill: IconsFill }>;
    iconSize?: string;
  }
>(function Content({ children, ...props }, ref) {
  return (
    <Collapsible.Trigger
      ref={ref}
      asChild
      {...mergeProps(
        { className: clsx(styles.trigger, props.className) },
        props
      )}
    >
      <div>
        <div className={styles.triggerContent}>{children}</div>
        <div className={styles.triggerIconOpen}>
          <MinusIcon
            size={props.iconSize ?? vars.iconSize.m}
            fill={IconsFill.textPrimary}
          />
        </div>
        <div className={styles.triggerIconClosed}>
          <PlusIcon
            size={props.iconSize ?? vars.iconSize.m}
            fill={IconsFill.textPrimary}
          />
        </div>
      </div>
    </Collapsible.Trigger>
  );
});

export const Content = styledComponent(Collapsible.Content, styles.content);

type CollapsibleSettting = {
  title: React.ReactNode;
  altTitle?: React.ReactNode;
  content: React.ReactNode;
  defaultToOpen?: boolean;
};

export const CollapsibleSettting = ({
  title,
  altTitle,
  content,
  defaultToOpen = false,
}: CollapsibleSettting) => {
  const [open, setOpen] = useState(defaultToOpen);
  return (
    <Root open={open} onOpenChange={() => setOpen(!open)}>
      {altTitle && open ? (
        <Trigger>{altTitle}</Trigger>
      ) : (
        <Trigger>{title}</Trigger>
      )}
      <Content>{content}</Content>
    </Root>
  );
};
