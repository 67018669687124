import { useCallback, useMemo } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  BulkEditAutoPricingSettingsDialog,
  BulkEditAutoPricingSettingsDialogProps,
} from 'src/dialogs/BulkEdits/BulkEditAutoPricingSettingsDialog';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  Listing,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdatesV2,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_AUTOPRICING_SETTINGS_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchEditAutoPricingSettings } from './LaunchEditAutoPricingSettings';

export const useBulkEditAutoPricingSettings = (
  filterQueryWithEventIds: ListingQuery,
  affectedVisibleListingIds: number[],
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = event
    ? `autopricingSettings-event-${event?.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_AUTOPRICING_SETTINGS_UPDATE_KEY;

  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const {
    eventsTransformed,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkEditAutoPricingSettingsDialog,
    closeDialog: closeBulkEditAutoPricingSettingsDialog,
  } = useDialog<BulkEditAutoPricingSettingsDialogProps>(
    DialogId.BulkEditAutoPricingSettings,
    BulkEditAutoPricingSettingsDialog
  );

  const onSubmitAutoPricingRework = useCallback(
    (
      settingsInput: ListingDetailsAutoPricingSectionUpdatesV2 | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        tryInvokeApi(
          async () => {
            const client = await new BulkEditListingClient(
              activeAccountWebClientConfig
            );
            const preview = await client.bulkUpdateAutoPricingSettingsPreview(
              filterQueryWithEventIds
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateAutoPricingSettingsPreview', error, {
              trackErrorData: {
                filterQueryWithEventIds,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (settingsInput) {
        tryInvokeApi(
          async () => {
            const client = await new BulkEditListingClient(
              activeAccountWebClientConfig
            );
            const input = {
              item1: preview!.preview,
              item2: settingsInput,
            };

            const succeeded = await client.bulkUpdateAutoPricingSettingsV2(
              input,
              event?.viagId,
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkEditAutoPricingSettingsDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateAutoPricingSettingsV2', error, {
              trackErrorData: {
                settingsInput,
                preview,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkEditAutoPricingSettingsDialog,
      event?.viagId,
      filterQueryWithEventIds,
      refreshExpandedListItems,
      setActivePosEntity,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  const listings = useMemo(() => {
    return (eventsTransformed ?? [])
      .flatMap((ev) => ev.listings)
      .flatMap(flattenListingGroup)
      .filter(({ id, isLtGrp }) => id > 0 && !isLtGrp) as Listing[];
  }, [eventsTransformed]);

  const openDialog = () => {
    openBulkEditAutoPricingSettingsDialog({
      event: event,
      updateKey: updateKey,
      isLoading: isLoading,
      listings: listings,
      selectedListingIds: affectedVisibleListingIds,
      onOkay: onSubmitAutoPricingRework,
      onClosed: () => {
        setIsLoading(false);
      },
    });
  };

  return {
    dropDown: (
      <LaunchEditAutoPricingSettings
        key={`LaunchEditAutoPricingSettings`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openDialog();
        }}
        disabled={isLoading}
      />
    ),
    openDialog: openDialog,
  };
};
