export function GametimeSquareLogo({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_581_3737)">
        <rect width="20" height="20" rx="3" fill="black" />
        <path
          d="M11.8682 3.94336L15.4111 10.0005L11.8682 16.0576H5.35392C5.06821 16.0576 4.95392 15.7719 5.06821 15.5434L11.8682 3.94336Z"
          fill="#00EF9F"
        />
        <path
          d="M5.0684 4.45765L7.58268 8.8005L11.8684 3.94336H5.35411C5.0684 3.94336 4.89697 4.22907 5.0684 4.45765Z"
          fill="#19CE85"
        />
        <path
          d="M11.8687 3.94336L7.58301 8.8005L8.32586 10.0005L11.8687 3.94336Z"
          fill="#21AA6F"
        />
      </g>
      <defs>
        <clipPath id="clip0_581_3737">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
