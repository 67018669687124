import { useMemo } from 'react';
import { useGetMarketplacePaymentLinesWithSales } from 'src/contexts/MarketplacePaymentDataContext';
import { useGetCatalogBySaleIds } from 'src/hooks/api/useGetCatalogBySaleIds';
import {
  Event,
  MarketplacePaymentLineWithSaleInfo,
  Venue,
} from 'src/WebApiController';

export interface MarketplacePaymentLineWithSaleAndEventInfo
  extends MarketplacePaymentLineWithSaleInfo {
  event: Event | undefined;
  venue: Venue | undefined;
}

interface MarketplacePaymentLineTableDataResponse {
  data: MarketplacePaymentLineWithSaleAndEventInfo[];
  isLoading: boolean;
  error: Error | null;
}

export const useGetMarketplacePaymentLineTableData = (
  marketplacePaymentLineId: number | undefined
): MarketplacePaymentLineTableDataResponse => {
  const { paymentLineQuery } = useGetMarketplacePaymentLinesWithSales(
    marketplacePaymentLineId ?? 0,
    false
  );

  const {
    isLoading: isLoadingMktPaymentLines,
    error: mktPaymentLinesError,
    data: marketplacePaymentData,
  } = paymentLineQuery;

  const saleIds = useMemo(() => {
    return (marketplacePaymentData ?? [])
      .map((mktPaymentLine) => mktPaymentLine.saleId)
      .filter((s) => s != null) as number[];
  }, [marketplacePaymentData]);

  const {
    isLoading: isLoadingEventCatalog,
    error: eventCatalogError,
    data: catalog,
  } = useGetCatalogBySaleIds(saleIds);

  const isLoading = isLoadingEventCatalog || isLoadingMktPaymentLines;

  const data = useMemo(() => {
    if (isLoading || !catalog) {
      return [];
    }

    return (marketplacePaymentData ?? []).map(
      (mktPaymentLine): MarketplacePaymentLineWithSaleAndEventInfo => {
        const event = catalog.events[mktPaymentLine.viagEvId ?? '']?.event;
        return {
          ...mktPaymentLine,
          event,
          venue: catalog.venues[event?.venueId],
        };
      }
    );
  }, [catalog, isLoading, marketplacePaymentData]);

  return {
    isLoading,
    error: eventCatalogError || mktPaymentLinesError,
    data,
  };
};
