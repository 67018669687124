import React, { ReactNode, Ref, useEffect, useState } from 'react';
import { vars } from 'src/core/themes';
import { TooltipPopover } from 'src/core/ui';
import { mergeRefs } from 'src/core/utils';
import {
  IconsFill,
  InfoSolidIcon,
  MinusIcon,
  PlusIcon,
  ResetIcon,
} from 'src/svgs/Viagogo';
import { FocalPointInfo, SectionInfo } from 'src/WebApiController';

import { ColorBand, ColorBandProps } from '../shared/components/ColorBand';
import {
  IconParent,
  StyledBorderDiv,
  StyledDiv,
  StyledHoverDiv,
  StyledIconParentVariant,
  StyledResetVariant,
  VenueMapContentContainer,
} from '../VenueMap.styled';
import * as styles from './VenueMapV3.css';
import { useSvgMap } from './VenueMapV3Content.hook';

enum SvgInitializationState {
  UNSET,
  INITIALIZING,
  INITIALIZED,
}

export type StandardColorSpec = {
  defaultBackground: string;
  defaultForeground: string;
  defaultHoverBackground: string;
  defaultHoverForeground: string;
  selectedBackground: string;
  selectedForeground: string;
  selectedHoverBackground: string;
  selectedHoverForeground: string;
};

export type VenueMapV3ContentProps = {
  selectedSections?: SectionInfo[];
  markedSectionIds?: number[];
  onSectionClicked?: (
    e: MouseEvent,
    selectedSection: SectionInfo,
    surroundingSections?: SectionInfo[]
  ) => void;
  onSectionHovered?: (hoveredSection: SectionInfo) => ReactNode;
  setSelectedSections?: (sections: SectionInfo[]) => void;
  staticInfoContent?: React.ReactNode;
  infoIconContent?: React.ReactNode;
  availableSections: SectionInfo[];
  svg: string | TrustedHTML;
  useRowMap?: boolean;
  isZoomEnabled: boolean;
  isPanEnabled?: boolean;
  isWhiteBackground?: boolean;
  isRelocatedStaticInfo?: boolean;
  svgMapUrl: string; // what to use this for?
  venueConfigId: number; // what to use this for?
  showDefaultMapColors?: boolean;
  colors?: StandardColorSpec;
  colorBandProps?: ColorBandProps;
  /* Callback to get color for a section/ticketClass pair to provide dynamic
   * colorization of sections in the map
   */
  focalPoint?: FocalPointInfo | null;
  getColor?: (info: {
    sectionId: number;
    rowId?: number;
    sectionName?: string;
    ticketClassId: number;
    isSelected: boolean;
    useRowColor?: boolean;
  }) => { fill: string; stroke: string; textColor?: string } | void;
  isHeatMap?: boolean;
  statsContent?: React.ReactNode;
};

const VenueMapV3Content = React.forwardRef(
  (
    {
      selectedSections,
      markedSectionIds,
      onSectionClicked,
      onSectionHovered,
      setSelectedSections,
      staticInfoContent,
      infoIconContent,
      statsContent,
      availableSections,
      svg,
      focalPoint,
      isZoomEnabled = true,
      isPanEnabled = true,
      isWhiteBackground = false,
      isRelocatedStaticInfo = false,
      showDefaultMapColors,
      colorBandProps,
      getColor,
      isHeatMap = false,
      useRowMap = false,
    }: VenueMapV3ContentProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [blurMap, setBlurMap] = useState(true);
    const [svgState, setSvgState] = useState<SvgInitializationState>(
      SvgInitializationState.UNSET
    );
    const [svgNode, setSvgNode] = useState<HTMLDivElement | null>(null);

    const { onInitializeContent, hoveredContent, zoomFlag, onReset, onZoom } =
      useSvgMap({
        availableSections,
        selectedSections,
        markedSectionIds,
        onSectionClicked,
        onSectionHovered,
        setSelectedSections,
        focalPoint,
        isZoomEnabled,
        isPanEnabled,
        getColor,
        isHeatMap,
        showDefaultMapColors,
        useRowMap,
      });

    useEffect(() => {
      if (availableSections.length) setBlurMap(false);
    }, [availableSections.length]);

    useEffect(() => {
      if (svgState === SvgInitializationState.UNSET && svgNode && svg) {
        svgNode.innerHTML = svg as string;
        setSvgState(SvgInitializationState.INITIALIZING);
        if (onInitializeContent(svgNode)) {
          setSvgState(SvgInitializationState.INITIALIZED);
        } else {
          setSvgState(SvgInitializationState.UNSET);
        }
      }
    }, [onInitializeContent, svg, svgNode, svgState]);

    return (
      <VenueMapContentContainer isWhiteBackground={isWhiteBackground}>
        <div style={{ position: 'relative', flexGrow: 1, overflow: 'hidden' }}>
          <StyledDiv
            id={'event-detail-map'}
            ref={mergeRefs(setSvgNode, ref)}
            setTransitionAnimation={true}
            blurMap={blurMap}
            isRelocatedStaticInfo={isRelocatedStaticInfo}
          />

          <StyledResetVariant showFiltersOnTop={false}>
            {isZoomEnabled && (
              <>
                {(Boolean(selectedSections?.length) || zoomFlag) && (
                  <div className={styles.resetWrapper}>
                    <StyledIconParentVariant onClick={onReset}>
                      <ResetIcon size={vars.iconSize.s} />
                    </StyledIconParentVariant>
                  </div>
                )}
                <StyledBorderDiv>
                  <StyledHoverDiv>
                    <IconParent onClick={() => onZoom(true)}>
                      <PlusIcon size={vars.iconSize.s} />
                    </IconParent>
                  </StyledHoverDiv>
                  <StyledHoverDiv>
                    <IconParent onClick={() => onZoom(false)}>
                      <MinusIcon size={vars.iconSize.s} />
                    </IconParent>
                  </StyledHoverDiv>
                </StyledBorderDiv>
              </>
            )}
            {infoIconContent && (
              <TooltipPopover
                variant="link"
                contentVariant="dark"
                triggerContent={
                  <InfoSolidIcon withHoverEffect fill={IconsFill.textBrand} />
                }
              >
                {infoIconContent}
              </TooltipPopover>
            )}
          </StyledResetVariant>

          {!statsContent && staticInfoContent && (
            <div
              className={
                isRelocatedStaticInfo
                  ? styles.infoStaticBox.bottom
                  : styles.infoStaticBox.top
              }
            >
              {staticInfoContent}
            </div>
          )}
          {!statsContent && hoveredContent && (
            <div className={styles.infoHoverBox}>{hoveredContent}</div>
          )}
          {statsContent}
        </div>
        {colorBandProps && (
          <div className={styles.colorBandContainer}>
            <ColorBand {...colorBandProps} />
          </div>
        )}
      </VenueMapContentContainer>
    );
  }
);

VenueMapV3Content.displayName = 'VenueMapV3Content';
export default VenueMapV3Content;
