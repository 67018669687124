import { useMutation, UseQueryResult } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useGetVenueZoneMapConfigInfo } from 'src/contexts/EventMapContext/useGetVenueZoneMapConfigInfo';
import { useGetVenueZoneMapInfoOverrides } from 'src/contexts/EventMapContext/useGetVenueZoneMapConfigInfoOverrides';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AutoPricingInputs,
  Event,
  EventConfigScoreOverride,
  PricingClient,
  SectionSectionGroup,
  VenueMapZoneConfigInfo,
  VenueZoneConfigOverride,
  VenueZoneConfigType,
} from 'src/WebApiController';

import { createGetColor } from '../../utils/colorUtils';
import { SectionGroupPanel } from './components/SectionGroupPanel';
import { VenueZoneMap } from './components/VenueZoneMap';
import { VenueZoneMapFooter } from './components/VenueZoneMapFooter';
import { VenueZoneMapSubmitButton } from './components/VenueZoneMapSubmitButton';
import * as styles from './InventoryEventVenueZoneMapSection.css';

export type VenueZoneMapFormType = {
  sectionSectionGroupLookup: { [key: string]: SectionSectionGroup };
  customSectionGroups: SectionSectionGroup[];
  eventScoreOverride?: EventConfigScoreOverride | null;
  customRank: boolean;
};

type InventoryEventVenueZoneMapSectionProps = {
  event: Event;
};

export const InventoryEventVenueZoneMapSection = ({
  event,
}: InventoryEventVenueZoneMapSectionProps) => {
  return <InventoryEventVenueZoneMapSectionBody event={event} />;
};

export const InventoryEventVenueZoneMapSectionBody = ({
  event,
}: {
  event: Event;
}) => {
  const { mapConfigOverridesQuery, isMapLoading } = useEventMapContext();
  const { pricingSettingsQuery } = useGetEventAutoPricingSettings(
    event!,
    false
  );

  const venueZoneMapConfigInfoQuery = useGetVenueZoneMapConfigInfo(
    event!.viagId,
    event!.venueCfgId
  );

  const venueZoneMapConfigOverrideInfoQuery = useGetVenueZoneMapInfoOverrides(
    event!.viagId,
    event!.venueCfgId
  );

  const methods = useForm<VenueZoneMapFormType>({
    defaultValues: {
      sectionSectionGroupLookup: {},
      customSectionGroups: [],
      eventScoreOverride: null,
      customRank: false,
    },
  });

  if (
    mapConfigOverridesQuery.isLoading ||
    isMapLoading ||
    venueZoneMapConfigInfoQuery.isLoading ||
    venueZoneMapConfigOverrideInfoQuery.isLoading ||
    pricingSettingsQuery.isLoading
  ) {
    return <PosSpinner />;
  }

  return (
    <FormProvider {...methods}>
      <InventoryEventZoneMapSectionContent
        event={event}
        pricingSettingsQuery={pricingSettingsQuery}
        venueZoneMapConfigInfoQuery={venueZoneMapConfigInfoQuery}
        venueZoneMapConfigOverrideInfoQuery={
          venueZoneMapConfigOverrideInfoQuery
        }
      />
    </FormProvider>
  );
};

export const InventoryEventZoneMapSectionContent = ({
  event,
  pricingSettingsQuery,
  venueZoneMapConfigInfoQuery,
  venueZoneMapConfigOverrideInfoQuery,
}: {
  event: Event;
  pricingSettingsQuery: UseQueryResult<AutoPricingInputs | null, Error>;
  venueZoneMapConfigInfoQuery: UseQueryResult<
    VenueMapZoneConfigInfo | null,
    Error
  >;
  venueZoneMapConfigOverrideInfoQuery: UseQueryResult<
    {
      [key: string]: VenueZoneConfigOverride;
    } | null,
    Error
  >;
}) => {
  const { reset } = useFormContext<VenueZoneMapFormType>();
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const { venueZoneConfigs } = useEventMapContext();

  const [selectedConfigType, setSelectedConfigType] =
    useState<VenueZoneConfigType | null>(
      pricingSettingsQuery.data?.venueZoneConfigType ??
        venueZoneMapConfigInfoQuery?.data?.defaultVenueZoneConfigType ??
        null
    );

  const [selectedOverrideId, setSelectedOverrideId] = useState<number | null>(
    pricingSettingsQuery.data?.venueZoneOverrideId ?? null
  );

  useEffect(() => {
    if (venueZoneConfigs?.sectionSectionGroupLookup) {
      reset({
        sectionSectionGroupLookup: venueZoneConfigs.sectionSectionGroupLookup,
        customSectionGroups: [],
        eventScoreOverride: null,
        customRank: false,
      });
    }
  }, [reset, venueZoneConfigs]);

  const setVenueZoneConfigTypeOverrideMutation = useMutation({
    mutationFn: (request: {
      eventId?: number | null;
      venueZoneOverrideId?: number | null;
      venueZoneConfigType?: VenueZoneConfigType | null;
    }) => {
      if (request.eventId) {
        return new PricingClient(
          activeAccountWebClientConfig
        ).setVenueZoneSelectedConfigType(
          request.eventId,
          request.venueZoneOverrideId,
          request.venueZoneConfigType,
          false
        );
      }

      return Promise.resolve();
    },
    onError: (err: ErrorTypes, request) => {
      showErrorDialog('PricingClient.setVenueZoneSelectedConfigType', err, {
        trackErrorData: request,
      });
    },
    onSettled: () => {
      pricingSettingsQuery.refetch();
    },
  });

  if (!event) {
    return null;
  }

  return (
    <>
      {/* Header */}
      <Stack direction="column" gap="xl" className={styles.sectionContainer}>
        <Stack direction="column" gap="s">
          <span className={styles.zoneMapHeader}>
            <Content id={ContentId.ZoneMap} />
          </span>
          <span className={styles.zoneMapSubtitle}>
            <Content id={ContentId.ZoneMapDescription} />
          </span>
        </Stack>

        {/* Venue Map */}
        <div className={styles.sectionGroupSettingsContainer}>
          <SectionGroupPanel />
          <div className={styles.rightHandPanelContainer}>
            <VenueZoneMap createGetColor={createGetColor} />
            <VenueZoneMapSubmitButton
              event={event}
              pricingSettingsQuery={pricingSettingsQuery}
              venueZoneMapConfigInfoQuery={venueZoneMapConfigInfoQuery}
              venueZoneMapConfigOverrideInfoQuery={
                venueZoneMapConfigOverrideInfoQuery
              }
              setVenueZoneConfigTypeOverrideMutation={
                setVenueZoneConfigTypeOverrideMutation
              }
              selectedOverrideId={selectedOverrideId}
              setSelectedOverrideId={setSelectedOverrideId}
              setSelectedConfigType={setSelectedConfigType}
            />
          </div>
        </div>

        {/* Footer */}
        <VenueZoneMapFooter
          event={event}
          selectedOverrideId={selectedOverrideId}
          setSelectedOverrideId={setSelectedOverrideId}
          selectedConfigType={selectedConfigType}
          setSelectedConfigType={setSelectedConfigType}
          createGetColor={createGetColor}
          venueZoneMapConfigInfoQuery={venueZoneMapConfigInfoQuery}
          venueZoneMapConfigOverrideInfoQuery={
            venueZoneMapConfigOverrideInfoQuery
          }
          setVenueZoneConfigTypeOverrideMutation={
            setVenueZoneConfigTypeOverrideMutation
          }
        />
      </Stack>
    </>
  );
};
