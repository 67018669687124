import { MouseEvent, useCallback } from 'react';
import { useToggle } from 'react-use';
import { MarketplacePaymentsTab } from 'src/components/MarketplacePayments';
import { MarketplacePaymentDeeplinkListener } from 'src/components/MarketplacePayments/MarketplacePaymentDeeplinkListener';
import { MarketplacePaymentsToolbar } from 'src/components/MarketplacePayments/PaymentsToolbar/MarketplacePaymentsToolbar';
import { Content, useContent } from 'src/contexts/ContentContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import * as Tabs from 'src/core/ui/Tabs';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutContent } from 'src/navigations/LayoutContent';
import {
  DefaultMarketplacePaymentQuery,
  EmptyMarketplacePaymentQuery,
  PaymentTab,
} from 'src/navigations/Routes/Payments/Payments.constants';
import {
  marketplacePaymentQueryValueTransformToUrl,
  marketplacePaymentValueTransformFromUrl,
} from 'src/navigations/Routes/Payments/Payments.utils';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { MarketplacePaymentQuery, UserSetting } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as styles from './Payments.css';

export function Payments() {
  const paymentsTitle = useContent(ContentId.Payments);

  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isColumnModalOpen) {
        toggleColumnModal(true);
      }
    },
    [isColumnModalOpen, toggleColumnModal]
  );

  return (
    <LayoutContent mainRoute={MainRoute.Payments} routeTitle={paymentsTitle}>
      <FilterQueryContextProvider<MarketplacePaymentQuery>
        initialQuery={DefaultMarketplacePaymentQuery}
        viewModeSettingId={UserSetting.PurchasePageViewMode}
        emptyQuery={EmptyMarketplacePaymentQuery}
        queryValueTransformToUrl={marketplacePaymentQueryValueTransformToUrl}
        queryValueTransformFromUrl={marketplacePaymentValueTransformFromUrl}
        saveQueryInUrl
      >
        <MarketplacePaymentDeeplinkListener>
          <Tabs.Root
            defaultValue={PaymentTab.MarketplacePayments}
            className={styles.paymentsPageTabsRoot}
          >
            <Tabs.List className={styles.tabsWrapper}>
              <Tabs.Trigger value={PaymentTab.MarketplacePayments}>
                <Content id={ContentId.MarketplacePayments} />
              </Tabs.Trigger>
            </Tabs.List>

            <MarketplacePaymentsToolbar
              onColumnSettingButtonClickHandler={
                onColumnSettingButtonClickHandler
              }
            />

            <Tabs.Content
              value={PaymentTab.MarketplacePayments}
              className={styles.tabWrapper}
            >
              <MarketplacePaymentsTab />
            </Tabs.Content>
          </Tabs.Root>
          {isColumnModalOpen && (
            <ColumnSettingsModal
              onClose={toggleColumnModal}
              sectionType={SectionType.MarketplacePaymentsTable}
            />
          )}
        </MarketplacePaymentDeeplinkListener>
      </FilterQueryContextProvider>
    </LayoutContent>
  );
}
