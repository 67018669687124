import { clsx } from 'clsx';
import { format } from 'date-fns';
import { Content } from 'src/contexts/ContentContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ContentId } from 'src/utils/constants/contentId';
import { Seating } from 'src/WebApiController';
import * as styles from './SaleWasteInfo.css';

interface SaleWasteInfoProps {
  eventDate: string | null | undefined;
  seating?: Seating;
  quantity: number;
}

export const SaleWasteInfo = ({
  eventDate,
  seating,
  quantity,
}: SaleWasteInfoProps) => {
  const isMobile = useMatchMedia('mobile');

  if (!eventDate || !seating) {
    return null;
  }
  return (
    <div
      className={clsx(styles.grid, { [styles.gridMobileSpacing]: isMobile })}
    >
      <span className={styles.section}>
        <Content id={ContentId.SalesSummary} />
      </span>
      <div>
        <div className={styles.sectionTitle}>
          <Content id={ContentId.SaleDate} />
        </div>
        <div className={styles.sectionContent}>
          {format(new Date(eventDate), 'MMM dd, yyyy')}
        </div>
      </div>
      <div className={styles.expectedProceeds}>
        <div className={styles.sectionTitle}>
          <Content id={ContentId.ExpectedProceeds} />
        </div>
        <div className={styles.sectionContent}>$0</div>
      </div>
      <div className={styles.section}>
        <Content id={ContentId.Tickets} />
      </div>
      <div>
        <div>
          <Content id={ContentId.Section} />
        </div>
        <div className={styles.sectionContent}>{seating.section}</div>
      </div>
      <div>
        <div>
          <Content id={ContentId.Row} />
        </div>
        <div className={styles.sectionContent}> {seating.row}</div>
      </div>
      <div>
        <div>
          <Content id={ContentId.Seat} />
        </div>
        <div className={styles.sectionContent}>{seating.seatFr}</div>
      </div>
      <div>
        <div>
          <Content id={ContentId.Quantity} />
        </div>
        <div className={styles.sectionContent}>x {quantity}</div>
      </div>
    </div>
  );
};
