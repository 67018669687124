import { EventIdQueryParam } from 'src/utils/constants/constants';
import { removeFilters } from 'src/utils/eventQueryUtils';

export const createInventoryEventUrl = (
  eventId: string,
  includeAllSearchParams = true, // current searchParams + EventIdQueryParam
  useEventV2 = false,
  queryParamOverride?: string, // query param to use for eventId
  queryParamsToRemove?: string[] // queryParams to remove from final URL
): string => {
  let searchParams = '';
  if (includeAllSearchParams) {
    const url = new URL(window.location.href);
    url.searchParams.set(queryParamOverride || EventIdQueryParam, eventId);
    (queryParamsToRemove ?? []).forEach((param) =>
      url.searchParams.delete(param)
    );
    searchParams = `?${removeFilters(url.searchParams.toString(), [
      'searchText',
    ])}`;
  }
  return `/inventory/event${useEventV2 ? '/v2' : ''}${searchParams}`;
};
