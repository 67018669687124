import { ListingDetails } from 'src/WebApiController';

import { useAutoPricingForm } from './ListingForms/AutoPricingForm/AutoPricingForm.hooks';
import { useBroadcastForm } from './ListingForms/BroadcastForm';
import { usePricingForm } from './ListingForms/PricingForm';
import { useTagsForm } from './ListingForms/TagsForm';
import { useTicketsForm } from './ListingForms/TicketsForm';

export const useListingDetailsMethods = (
  listing: ListingDetails | null | undefined,
  onSubmitStart: () => void,
  onSubmitDone: (success?: boolean) => Promise<void>
) => {
  const {
    hasChanges: broadcastHasChanges,
    methods: broadcastMethods,
    onSubmit: onSubmitBroadcast,
    onReset: onResetBroadcast,
  } = useBroadcastForm(listing, onSubmitStart, onSubmitDone);
  const {
    hasChanges: ticketsHasChanges,
    methods: ticketsMethods,
    onSubmit: onSubmitTickets,
    onReset: onResetTickets,
  } = useTicketsForm(listing, onSubmitStart, onSubmitDone);
  const {
    hasChanges: tagsHasChanges,
    methods: tagsMethods,
    onSubmit: onSubmitTags,
    onReset: onResetTags,
  } = useTagsForm(listing, onSubmitStart, onSubmitDone);
  const {
    hasChanges: pricingHasChanges,
    methods: pricingMethods,
    onSubmit: onSubmitPricing,
    onReset: onResetPricing,
  } = usePricingForm(listing, onSubmitStart, onSubmitDone);
  const {
    hasChanges: autoPricingHasChanges,
    methods: autoPricingMethods,
    onSubmit: onSubmitAutoPricing,
    onReset: onResetAutoPricing,
  } = useAutoPricingForm(listing, onSubmitStart, onSubmitDone);

  return {
    updated: {
      broadcast: broadcastHasChanges,
      tickets: ticketsHasChanges,
      tags: tagsHasChanges,
      pricing: pricingHasChanges,
      autoPricing: autoPricingHasChanges,
    },
    methods: {
      broadcast: broadcastMethods,
      tickets: ticketsMethods,
      tags: tagsMethods,
      pricing: pricingMethods,
      autoPricing: autoPricingMethods,
    },
    onSubmits: {
      broadcast: onSubmitBroadcast,
      tickets: onSubmitTickets,
      tags: onSubmitTags,
      pricing: onSubmitPricing,
      autoPricing: onSubmitAutoPricing,
    },
    onResets: {
      broadcast: onResetBroadcast,
      tickets: onResetTickets,
      tags: onResetTags,
      pricing: onResetPricing,
      autoPricing: onResetAutoPricing,
    },
  };
};
