import { FormProvider } from 'react-hook-form';
import { useUserHasAutopricingFeature } from 'src/hooks/useUserHasAutopricingFeature';
import { Feature, ListingDetails } from 'src/WebApiController';

import { useListingDetailsContext } from '../../ListingDetailsModalV2Context';
import { ListingAutoPricingSettingsFormContentWrapper } from './components/ListingAutoPricingSettingsFormContentWrapper/ListingAutoPricingSettingsFormContentWrapper';
import { AutoPricingSection } from './Sections/AutoPricingSection';

type ListingDetailsAutoPricingFormProps = {
  listing: ListingDetails;
};

export const ListingDetailsAutoPricingForm: React.FC<
  ListingDetailsAutoPricingFormProps
> = ({ listing }) => {
  const { methods, isLoading, onTabChange } = useListingDetailsContext();

  const hasIntelligibleAutoPricingSettingsFeature =
    useUserHasAutopricingFeature(Feature.IntelligibleAutoPricingSettings);

  return (
    <FormProvider {...methods.autoPricing}>
      {hasIntelligibleAutoPricingSettingsFeature ? (
        <ListingAutoPricingSettingsFormContentWrapper
          listing={listing}
          disabled={isLoading}
          onSwitchToTab={onTabChange}
        />
      ) : (
        <AutoPricingSection
          listing={listing}
          disabled={isLoading}
          onSwitchToTab={onTabChange}
        />
      )}
    </FormProvider>
  );
};
