import { useMemo } from 'react';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Listing, Marketplace } from 'src/WebApiController';

export const useListingHasVenueMapInfo = (
  listing?: Listing | null,
  hasAutoPricingUiGateFeature?: boolean
) => {
  const { venueMapInfo } = useEventMapContext();

  const hasVenueMapInfo = useMemo(() => {
    // If the listing has rowId - we assume it's seating came from a venue map
    if (listing?.seating?.rowId) {
      return true;
    }

    // Otherwise, try to find the section name in the venue map
    const foundSection = venueMapInfo?.sections.find(
      (s) => s.name === listing?.seating.section
    );

    if (foundSection) {
      return true;
    }

    // Otherwise, try to find the overridden section in the venue map
    const overrideSection = listing?.mkpListings?.find(
      (m) => m.mkp === Marketplace.StubHub && m.overrideSection
    )?.overrideSection;

    const foundOverrideSection = venueMapInfo?.sections.find(
      (s) => s.name === overrideSection
    );

    if (foundOverrideSection) {
      return true;
    }

    // If no section mapping is found, just make sure the listing at least has a section value
    if (hasAutoPricingUiGateFeature) {
      return listing?.seating?.section?.trim() ?? false;
    }

    return false;
  }, [
    hasAutoPricingUiGateFeature,
    listing?.mkpListings,
    listing?.seating?.rowId,
    listing?.seating.section,
    venueMapInfo?.sections,
  ]);

  return {
    mapExists: Boolean(venueMapInfo?.sections?.length),
    hasVenueMapInfo,
  };
};
