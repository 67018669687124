import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useState } from 'react';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { SeatMapCard } from 'src/components/Listings/InventoryEventPage/Sections/SeatMapCard';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  EventMapContextProvider,
  useEventMapContext,
} from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { Stack } from 'src/core/ui';
import { Button } from 'src/core/ui';
import { ApplyHeatMapToAllEventsDialog } from 'src/dialogs/ApplyHeatMapToAllEventsDialog';
import { DeleteHeatMapTemplateConfirmDialog } from 'src/dialogs/DeleteHeapMapTemplateConfirmDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { createGetColor } from 'src/hooks/useHeatMapColor';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Event,
  EventConfigScoreOverride,
  PricingClient,
  ScoreModel,
} from 'src/WebApiController';

import { CancellableFormFooter } from '../common';
import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from '../common/EventEntityHeader';
import { EventPricingSeatMap } from '../EventPricingSeatMap';
import { EventSeatMapConfig } from '../EventSeatMapConfig';
import { ModalBody, ModalFooter, ModalProps } from '../Modal';
import * as styles from './EventSeatMapSelection.css';
import { GlobalConfigPayloadSelection } from './GlobalConfigPayloadSelection';
import { NewHeatmapAvailableNotification } from './NewHeatmapAvailableNotification';

type EventSeatMapSelectionProps = {
  event: Event;
  cancelTo?: ModalProps;
  fromTabView?: boolean;
};

export function EventSeatMapSelection({
  event,
  cancelTo,
  fromTabView,
}: EventSeatMapSelectionProps) {
  return (
    <>
      <EventMapContextProvider event={event}>
        <EventSeatMapSelectionContent cancelTo={cancelTo} fromTabView />
      </EventMapContextProvider>
    </>
  );
}

export function EventSeatMapSelectionContent({
  cancelTo,
  fromTabView,
}: {
  cancelTo?: ModalProps;
  fromTabView?: boolean;
}) {
  const { setModal } = useContext(ModalContext);

  const {
    event,
    isMapLoading,
    venueMapInfo,
    venueMapsByScoreModelQuery,
    mapConfigOverridesQuery,
    sellerAccountScoreOverridesQuery,
    activeConfigOverride,
  } = useEventMapContext();

  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { launchDialog, closeDialog, dialogProps } = useBasicDialog();
  const {
    launchDialog: launchSelectDialog,
    closeDialog: closeSelectDialog,
    dialogProps: selectDialogProps,
  } = useBasicDialog();
  const [selectedMapIdToDelete, setSelectedMapIdToDelete] = useState<
    number | null
  >(null);
  const [applyToAllEventsForConfig, setApplyToAllEventsForConfig] = useState<
    EventConfigScoreOverride | undefined
  >();
  const venueConfigId = venueMapInfo?.venueCfgId;

  const { pricingSettings } = useGetEventAutoPricingSettings(event!, true);

  const [selectedRecommendedMap, setSelectedRecommendedMap] =
    useState<ScoreModel | null>(pricingSettings?.scoreModel ?? null);

  const performerVenueSpecific = mapConfigOverridesQuery.data?.filter(
    (o) => o.cfgId == venueConfigId
  );

  const venueSpecific = mapConfigOverridesQuery.data?.filter(
    (o) => o.cfgId != venueConfigId && o.categoryId != null && o.venueId != null
  );

  const setEventSeatMapScoreOverrideMutation = useMutation({
    mutationFn: (request: {
      eventId?: number | null;
      eventConfigScoreOverrideId?: number | null;
      scoreModel?: ScoreModel | null;
    }) => {
      if (request.eventId) {
        return new PricingClient(
          activeAccountWebClientConfig
        ).setEventSeatMapScoreOverride(
          request.eventId,
          request.eventConfigScoreOverrideId,
          request.scoreModel
        );
      }

      return Promise.resolve();
    },
    onError: (err: ErrorTypes, request) => {
      showErrorDialog('PricingClient.setEventSeatMapScoreOverride', err, {
        trackErrorData: request,
      });
    },
    onSettled: () => {
      mapConfigOverridesQuery.refetch();
    },
  });

  const deleteEventSeatMapScoreOverrideMutation = useMutation({
    mutationFn: (request: { eventConfigScoreOverrideId?: number | null }) => {
      if (request.eventConfigScoreOverrideId) {
        return new PricingClient(
          activeAccountWebClientConfig
        ).deleteEventSeatMapScoreOverride(request.eventConfigScoreOverrideId);
      }

      return Promise.resolve();
    },
    onError: (err: ErrorTypes, request) => {
      showErrorDialog('PricingClient.deleteEventSeatMapScoreOverride', err, {
        trackErrorData: request,
      });
    },
    onSettled: () => {
      mapConfigOverridesQuery.refetch();
      sellerAccountScoreOverridesQuery.refetch();
    },
  });

  const isLoading =
    isMapLoading ||
    venueMapsByScoreModelQuery.isLoading ||
    mapConfigOverridesQuery.isPending ||
    mapConfigOverridesQuery.isFetching;

  const initialEventConfigScoreOverrideId = isLoading
    ? undefined
    : activeConfigOverride?.id ?? null;

  const [
    selectedEventConfigScoreOverrideId,
    setSelectedEventConfigScoreOverrideId,
  ] = useState<number | undefined | null>(initialEventConfigScoreOverrideId);

  useEffect(() => {
    if (
      selectedEventConfigScoreOverrideId === undefined &&
      initialEventConfigScoreOverrideId !== undefined
    ) {
      setSelectedEventConfigScoreOverrideId(initialEventConfigScoreOverrideId);
    }
  }, [initialEventConfigScoreOverrideId, selectedEventConfigScoreOverrideId]);

  const onSubmit = useCallback(
    (
      eventConfigScoreOverrideId: number | null,
      scoreModel: ScoreModel | null
    ) => {
      if (eventConfigScoreOverrideId) {
        const selectedConfig = mapConfigOverridesQuery.data?.find(
          ({ id }) => id === eventConfigScoreOverrideId
        );
        if (selectedConfig && (selectedConfig.eventIds?.length ?? 0) >= 2) {
          setApplyToAllEventsForConfig(selectedConfig);
          launchSelectDialog();
          return;
        }
      }
      setEventSeatMapScoreOverrideMutation.mutate({
        eventId: event!.viagId,
        eventConfigScoreOverrideId,
        scoreModel,
      });
      setSelectedEventConfigScoreOverrideId(eventConfigScoreOverrideId);
      setSelectedRecommendedMap(scoreModel);
    },
    [
      event,
      launchSelectDialog,
      mapConfigOverridesQuery.data,
      setEventSeatMapScoreOverrideMutation,
    ]
  );

  const onEditSeatMap = useCallback(
    (
      eventConfigScoreOverride: EventConfigScoreOverride | null,
      defaultConfigPayload?: string | null,
      isOverrideTemplate?: boolean
    ) => {
      setModal({
        children: (
          <EventSeatMapConfig
            event={event!}
            eventConfigScoreOverride={eventConfigScoreOverride}
            defaultConfigPayload={defaultConfigPayload ?? undefined}
            cancelTo={{
              children: (
                <EventSeatMapSelection
                  event={event!}
                  cancelTo={
                    fromTabView
                      ? undefined
                      : {
                          children: <EventPricingSeatMap event={event!} />,
                          clickOutsideToClose: true,
                          size: 'slide-in',
                        }
                  }
                />
              ),
              clickOutsideToClose: true,
              size: 'slide-in',
            }}
            isOverrideTemplate={isOverrideTemplate ?? false}
          />
        ),
        clickOutsideToClose: true,
        size: 'fullscreen',
        fullscreen: true,
      });
    },
    [event, fromTabView, setModal]
  );

  if (!event) {
    return null;
  }

  const disabledButtons =
    isLoading ||
    setEventSeatMapScoreOverrideMutation.isPending ||
    deleteEventSeatMapScoreOverrideMutation.isPending;

  return (
    <>
      <CancellableFormHeader cancelTo={cancelTo} disabled={disabledButtons}>
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.SeatHeatmap} />}
        />
      </CancellableFormHeader>
      <ModalBody>
        {(pricingSettings?.scoreModel == ScoreModel.VenueGeometry ||
          pricingSettings?.scoreModel == ScoreModel.MergedVenueGeometry) && (
          <NewHeatmapAvailableNotification />
        )}
        <Stack direction="column">
          <div className={styles.selectionHeaderText}>
            <Content id={ContentId.StubhubRecommended} />
          </div>
          <div className={styles.venueMapCardsContainer}>
            {!!venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry && (
              <SeatMapCard
                isInteractive={!disabledButtons}
                isSelected={
                  selectedEventConfigScoreOverrideId == null &&
                  selectedRecommendedMap === ScoreModel.AdvancedVenueGeometry
                }
                onClick={() => {
                  onSubmit(null, ScoreModel.AdvancedVenueGeometry);
                }}
                footer={
                  venueMapsByScoreModelQuery.data.AdvancedVenueGeometry.sectionScores?.some(
                    (s) => s.score
                  ) ? (
                    <div>
                      <Content id={ContentId.StubhubRecommended} />
                    </div>
                  ) : (
                    <Content id={ContentId.Default} />
                  )
                }
              >
                <EventVenueMap
                  isZoomEnabled={false}
                  getColor={createGetColor(
                    venueMapsByScoreModelQuery.data.AdvancedVenueGeometry
                      .sectionScores
                  )}
                />
              </SeatMapCard>
            )}
            {pricingSettings?.scoreModel == ScoreModel.MergedVenueGeometry && (
              <SeatMapCard
                isInteractive={!disabledButtons}
                isSelected={
                  selectedEventConfigScoreOverrideId == null &&
                  selectedRecommendedMap === ScoreModel.MergedVenueGeometry
                }
                onClick={() => {
                  onSubmit(null, ScoreModel.MergedVenueGeometry);
                }}
                footer={<Content id={ContentId.MergedVenueGeometry} />}
              >
                <EventVenueMap
                  isZoomEnabled={false}
                  getColor={createGetColor(
                    venueMapsByScoreModelQuery.data?.MergedVenueGeometry
                      ?.sectionScores
                  )}
                />
              </SeatMapCard>
            )}
            {pricingSettings?.scoreModel == ScoreModel.VenueGeometry && (
              <SeatMapCard
                isInteractive={!disabledButtons}
                isSelected={
                  selectedEventConfigScoreOverrideId == null &&
                  selectedRecommendedMap === ScoreModel.VenueGeometry
                }
                onClick={() => {
                  onSubmit(null, ScoreModel.VenueGeometry);
                }}
                footer={<Content id={ContentId.VenueGeometry} />}
              >
                <EventVenueMap
                  isZoomEnabled={false}
                  getColor={createGetColor(
                    venueMapsByScoreModelQuery.data?.VenueGeometry
                      ?.sectionScores
                  )}
                />
              </SeatMapCard>
            )}
          </div>
        </Stack>
        {
          <Stack direction="column">
            <div className={styles.selectionHeaderText}>
              <Content id={ContentId.PerformerVenueSpecific} />
            </div>
            <div className={styles.venueMapCardsContainer}>
              {performerVenueSpecific?.map((eventConfigScoreOverride) => {
                return (
                  <SeatMapCard
                    key={eventConfigScoreOverride.id}
                    isInteractive={!disabledButtons}
                    isSelected={
                      eventConfigScoreOverride.id ===
                      selectedEventConfigScoreOverrideId
                    }
                    onClick={() => {
                      setSelectedEventConfigScoreOverrideId(
                        eventConfigScoreOverride.id
                      );
                      setSelectedRecommendedMap(null);
                      onSubmit(eventConfigScoreOverride.id, null);
                    }}
                    onDelete={
                      disabledButtons
                        ? undefined
                        : () => {
                            if (eventConfigScoreOverride.isTemplate) {
                              setSelectedMapIdToDelete(
                                eventConfigScoreOverride.id
                              );
                              launchDialog();
                            } else {
                              deleteEventSeatMapScoreOverrideMutation.mutate({
                                eventConfigScoreOverrideId:
                                  eventConfigScoreOverride.id,
                              });
                            }
                          }
                    }
                    footer={
                      <>
                        <div>{eventConfigScoreOverride.name}</div>
                        <Button
                          variant="link"
                          size="lg"
                          shape="none"
                          disabled={disabledButtons}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onEditSeatMap(eventConfigScoreOverride);
                          }}
                        >
                          <Content id={ContentId.Edit} />
                        </Button>
                      </>
                    }
                  >
                    <EventVenueMap
                      isZoomEnabled={false}
                      getColor={createGetColor(
                        eventConfigScoreOverride.scoreOverrides
                      )}
                    />
                  </SeatMapCard>
                );
              })}
              <SeatMapCard
                footer={
                  <Button
                    variant="link"
                    size="lg"
                    shape="none"
                    disabled={disabledButtons}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onEditSeatMap(null);
                    }}
                  >
                    <Content id={ContentId.CreateNew} />
                  </Button>
                }
              >
                <EventVenueMap
                  isZoomEnabled={false}
                  getColor={() => ({ fill: 'white', stroke: 'black' })}
                />
              </SeatMapCard>
            </div>
            <div className={styles.selectionHeaderText}>
              <Content id={ContentId.VenueSpecific} />
            </div>
            <div className={styles.venueMapCardsContainer}>
              {venueSpecific?.length == 0 ? (
                <SeatMapCard
                  footer={<Content id={ContentId.NoVenuesMatching} />}
                >
                  <EventVenueMap
                    isZoomEnabled={false}
                    getColor={() => ({ fill: 'white', stroke: 'black' })}
                  />
                </SeatMapCard>
              ) : (
                venueSpecific?.map((eventConfigScoreOverride) => {
                  return (
                    <SeatMapCard
                      key={eventConfigScoreOverride.id}
                      isInteractive={!disabledButtons}
                      isSelected={
                        eventConfigScoreOverride.id ===
                        selectedEventConfigScoreOverrideId
                      }
                      onClick={() => {
                        onEditSeatMap(eventConfigScoreOverride, null, true);
                      }}
                      footer={
                        <>
                          <div>{eventConfigScoreOverride.name}</div>
                          <Button
                            variant="link"
                            size="lg"
                            shape="none"
                            disabled={disabledButtons}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onEditSeatMap(
                                eventConfigScoreOverride,
                                null,
                                true
                              );
                            }}
                          >
                            <Content id={ContentId.Create} />
                          </Button>
                        </>
                      }
                    >
                      <EventVenueMap
                        isZoomEnabled={false}
                        getColor={createGetColor(
                          eventConfigScoreOverride.scoreOverrides
                        )}
                      />
                    </SeatMapCard>
                  );
                })
              )}
            </div>
          </Stack>
        }

        {!isLoading && (
          <GlobalConfigPayloadSelection
            eventConfigScoreOverridesForEvent={
              mapConfigOverridesQuery.data ?? []
            }
            onConfigPayloadSelection={onEditSeatMap}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <CancellableFormFooter cancelTo={cancelTo} disabled={disabledButtons} />
      </ModalFooter>

      <DeleteHeatMapTemplateConfirmDialog
        {...dialogProps}
        onCancel={() => {
          setSelectedMapIdToDelete(null);
          closeDialog();
        }}
        onConfirm={() =>
          deleteEventSeatMapScoreOverrideMutation.mutate(
            {
              eventConfigScoreOverrideId: selectedMapIdToDelete,
            },
            {
              onSuccess: () => {
                setSelectedMapIdToDelete(null);
                closeDialog();
              },
              onError: () => {
                setSelectedMapIdToDelete(null);
                closeDialog();
              },
            }
          )
        }
      />

      <ApplyHeatMapToAllEventsDialog
        {...selectDialogProps}
        event={event}
        eventIds={applyToAllEventsForConfig?.eventIds}
        eventConfigScoreOverrideId={applyToAllEventsForConfig?.id}
        onClose={async () => {
          setSelectedEventConfigScoreOverrideId(applyToAllEventsForConfig?.id);
          setApplyToAllEventsForConfig(undefined);
          closeSelectDialog();
        }}
      />
    </>
  );
}
