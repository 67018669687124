import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { EventDateSelector } from 'src/components/Events/EventDateSelector/EventDateSelector';
import { FilterToolbarItemId } from 'src/components/FilterToolbar';
import { CurrencyFilterMultiSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKET_PLACE_TO_CID,
  MARKETPLACE_PAYMENTS_TIME_FRAME_FILTER_TO_CID,
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  EventTimeFrameFilter,
  Feature,
  Marketplace,
  MarketplacePaymentQuery,
} from 'src/WebApiController';

export const useMarketplacePaymentsFilters = () => {
  const { loginContext } = useAppContext();
  const { tempQuery, setTempQuery, initialQuery, filterQuery } =
    useFilterQueryContext<MarketplacePaymentQuery>();
  const hasDynamicDateTimeFiltersFeature = useUserHasFeature(
    Feature.DynamicDateTimeFilters
  );

  return useMemo(() => {
    return {
      filters: [
        {
          titleContentId: ContentId.Payment,
          type: 'row',
          items: [
            {
              labelContentId: ContentId.PaymentDate,
              filterId: 'paymentDates' as FilterToolbarItemId,
              filterQueryKeys: ['paymentDates'] as FilterToolbarItemId[],
              filterItem: (
                <EventDateSelector
                  timeFrameLabelRecord={
                    MARKETPLACE_PAYMENTS_TIME_FRAME_FILTER_TO_CID
                  }
                  allEventsLabel={ContentId.AllPayments}
                  useRelativePresets={hasDynamicDateTimeFiltersFeature}
                  value={tempQuery.paymentDates}
                  onChange={(eventTimeFrameFilter, dateRange) => {
                    console.log('eventTimeFrameFilter: ', eventTimeFrameFilter);
                    console.log('dateRange: ', dateRange);
                    if (!isEqual(dateRange, tempQuery.paymentDates)) {
                      let isSortDescending = initialQuery.isSortDescending;
                      if (
                        eventTimeFrameFilter !== EventTimeFrameFilter.Future
                      ) {
                        isSortDescending = true;
                      }

                      setTempQuery({
                        ...tempQuery,
                        paymentDates: dateRange ?? null,
                        isSortDescending,
                      });
                    }
                  }}
                />
              ),
            },
            {
              filterId: 'marketplace' as FilterToolbarItemId,
              labelContentId: ContentId.Marketplace,
              filterQueryKeys: ['marketplace'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={tempQuery.marketplace}
                  placeholderText={ContentId.AllMerchants}
                  enableEmptySelection
                  onChange={(marketplace) => {
                    if (marketplace !== tempQuery.marketplace) {
                      setTempQuery({
                        ...tempQuery,
                        marketplace,
                      });
                    }
                  }}
                  valueOptionsContent={(
                    loginContext?.user?.activeAccount?.marketplaceSettings?.map(
                      (m) => m.mkp
                    ) ?? [Marketplace.StubHub]
                  )
                    .concat(Marketplace.Offline)
                    .reduce(
                      (cur, m) => {
                        cur[m] = MARKET_PLACE_TO_CID[m];
                        return cur;
                      },
                      {} as Record<Marketplace, string>
                    )}
                />
              ),
            },
            {
              filterId: 'marketplacePaymentState' as FilterToolbarItemId,
              labelContentId: ContentId.PaymentState,
              filterQueryKeys: [
                'marketplacePaymentState',
              ] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={tempQuery.marketplacePaymentState}
                  enableEmptySelection
                  onChange={(marketplacePaymentState) => {
                    if (
                      marketplacePaymentState !==
                      tempQuery.marketplacePaymentState
                    ) {
                      setTempQuery({
                        ...tempQuery,
                        marketplacePaymentState,
                      });
                    }
                  }}
                  valueOptionsContent={MARKETPLACE_SALE_PAYMENT_STATE_TO_CID}
                />
              ),
            },
            {
              filterId: 'currencyCodes' as FilterToolbarItemId,
              labelContentId: ContentId.Currency,
              filterQueryKeys: ['currencyCodes'] as FilterToolbarItemId[],
              filterItem: (
                <CurrencyFilterMultiSelector
                  values={tempQuery.currencyCodes ?? []}
                  triggerProps={{ style: { width: '100%' } }}
                  placeholderText={ContentId.All}
                  onChange={(newCurrencyCodes) => {
                    if (!isEqual(tempQuery.currencyCodes, newCurrencyCodes)) {
                      setTempQuery({
                        ...tempQuery,
                        currencyCodes: newCurrencyCodes?.length
                          ? newCurrencyCodes
                          : null,
                      });
                    }
                  }}
                  enableEmptySelection
                />
              ),
            },
          ],
        },
      ],
    };
  }, [
    hasDynamicDateTimeFiltersFeature,
    initialQuery.isSortDescending,
    loginContext?.user?.activeAccount?.marketplaceSettings,
    setTempQuery,
    tempQuery,
  ]);
};
