import { Seating } from 'src/WebApiController';

import {
  ComparableListingDisplay,
  ComparableListingDisplayProps,
} from '../../ComparableListingDisplay';
import { ComparableListing } from '../ComparableListings';

export type ConnectedComparableListingDisplayProps = Pick<
  ComparableListingDisplayProps,
  'isCurrentListing'
> & {
  listing: ComparableListing;
};

export const ConnectedComparableListingDisplay: React.FC<
  ConnectedComparableListingDisplayProps
> = ({
  listing: {
    listingId,
    section,
    row,
    price,
    currencyCode,
    isOutlier,
    isAnchor,
    isYourListing,
    isSelectedWithListingGroup,
    validPurchaseQuantities,
    listingNotes,
  },
  ...props
}) => {
  if (price == null || currencyCode == null) {
    return null;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });

  return (
    <ComparableListingDisplay
      {...props}
      listingId={listingId}
      isOutlier={isOutlier}
      isAnchor={isAnchor}
      isYourListing={isYourListing}
      isSelectedWithListingGroup={isSelectedWithListingGroup}
      price={formatter.format(price)}
      validPurchaseQuantities={validPurchaseQuantities}
      listingNotes={listingNotes}
      seating={
        {
          section: section ?? '',
          row: row ?? '',
        } as Seating
      }
    />
  );
};
