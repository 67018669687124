import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { MarketplacePaymentClient } from 'src/WebApiController';

export const useGetMarketplacePaymentLinesWithSales = (
  marketplacePaymentId: number,
  disabled: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled && activeAccountWebClientConfig.activeAccountId != null;

  const paymentLineQuery = useQuery({
    queryKey: [
      'MarketplacePaymentClient.getPaymentLinesWithSales',
      activeAccountWebClientConfig.activeAccountId,
      marketplacePaymentId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      return await new MarketplacePaymentClient(
        activeAccountWebClientConfig
      ).getPaymentLinesWithSales(marketplacePaymentId);
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('MarketplacePaymentClient.getPaymentLinesWithSales', error, {
          marketplacePaymentId,
        });
      },
    },
    enabled: shouldQuery,
  });

  return { paymentLineQuery };
};
