import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { AutoPricingInputs, Event, PricingClient } from 'src/WebApiController';

export const useListingGroupPricingSettings = (
  listingGroupId: string | null | undefined,
  event: Event | null | undefined
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const queryKey = [
    'PricingClient.getListingGroupAutoPricingSettings',
    listingGroupId,
    event?.viagId,
  ];

  const shouldQuery = Boolean(
    activeAccountWebClientConfig.activeAccountId &&
      listingGroupId &&
      event?.viagId
  );
  const listingGroupPricingSettings = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      return await new PricingClient(
        activeAccountWebClientConfig
      ).getListingGroupAutoPricingSettings(listingGroupId!, event!.viagId!);
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getListingGroupAutoPricingSettings', error, {
          listingGroupId,
        });
      },
    },
  });

  const resetListingPricingSettingsMutation = useMutation({
    mutationFn: async () => {
      return await new PricingClient(
        activeAccountWebClientConfig
      ).resetListingAutoPricingSettingsInGroup(listingGroupId!);
    },
    onError: (err: ErrorTypes) => {
      showErrorDialog(
        'PricingClient.resetListingAutoPricingSettingsInGroup',
        err,
        {
          trackErrorData: { listingGroupId },
        }
      );
    },
  });

  const mergeListingGroupPricingSettingsMutation = useMutation({
    mutationFn: async ({
      inputs,
      listingGroupId,
    }: {
      inputs: AutoPricingInputs;
      listingGroupId: string | null;
    }) => {
      return await new PricingClient(
        activeAccountWebClientConfig
      ).mergeListingGroupPricingSettings(listingGroupId ?? undefined, inputs);
    },
    onMutate: ({ inputs }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, inputs);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { inputs }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('PricingClient.mergeListingGroupPricingSettings', err, {
        trackErrorData: { inputs },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const onMergeListingGroupPricingSettings = useCallback(
    async (inputs?: AutoPricingInputs) => {
      if (!listingGroupId || !inputs) {
        return;
      }
      await mergeListingGroupPricingSettingsMutation.mutateAsync({
        listingGroupId,
        inputs,
      });
    },
    [listingGroupId, mergeListingGroupPricingSettingsMutation]
  );

  const onResetListingPricingSettings = useCallback(async () => {
    if (!listingGroupId) {
      return;
    }
    return await resetListingPricingSettingsMutation.mutateAsync();
  }, [listingGroupId, resetListingPricingSettingsMutation]);

  return {
    pricingSettings: listingGroupPricingSettings.data,
    onMergeListingGroupPricingSettings,
    onResetListingPricingSettings,
    isLoading:
      listingGroupPricingSettings.isPending ||
      resetListingPricingSettingsMutation.isPending ||
      mergeListingGroupPricingSettingsMutation.isPending,
    loaded: listingGroupPricingSettings.isFetched,
  };
};
