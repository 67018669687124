import {
  ColumnDef,
  functionalUpdate,
  Row,
  RowSelectionState,
  SortingState,
  TableOptions,
  Updater,
} from '@tanstack/react-table';
import clsx from 'clsx';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EventAccordionItemBodyComponentType } from 'src/components/Accordions';
import { shiftSelect } from 'src/components/Events/EventPage/EventPage.css';
import { useSingleTableShortcutSelection } from 'src/components/Events/EventPage/hooks/useSingleTableShortcutSelection';
import { useAppContext } from 'src/contexts/AppContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { useClearShiftSelectionOnSortingChange } from 'src/contexts/MultiSelectionContext/useClearShiftSelectionOnSortingChange';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { NoData, Table } from 'src/tables/Table';
import {
  defaultSalesColumnsConfig,
  filterColumnsByFeatures,
  getSaleColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { SALE_MAIN_COLUMNS } from 'src/utils/columns/sales/salesColumnUtils.constants';
import { filterCustomColumnsForSale } from 'src/utils/columns/sales/salesCustomColumnUtils';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { generateShimmeringRows } from 'src/utils/dataTableUtils';
import { isInFullPageEventView } from 'src/utils/deepLinkUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  Feature,
  Permission,
  UserSetting,
} from 'src/WebApiController';

import { SalesTableColumnId } from '../../utils/columns/sales/salesColumnUtils.types';
import { useSeatingColumnSorting } from '../common/hooks/useSeatingColumnSorting';
import { usePaginationSettings } from '../Table/usePaginationSettings';
import {
  customColumnDef,
  SALE_TABLE_COLUMNS_CONFIG,
  tagColumnDef,
} from './configs/SaleTableColumnsConfig';
import { SaleWithEvent } from './SalesTable.type';

export type SalesTableProps = EventAccordionItemBodyComponentType;

const PAGE_SIZE = 10;

const disabledPaginationStyles: CSSProperties = {
  position: 'sticky',
  top: '0',
  zIndex: '10',
};

const tableCellStyles: CSSProperties = {
  height: '1px', // Keep this to be able to use height: 100% inside td
};

const getRowId = (row: SaleWithEvent | null, index: number) =>
  (row?.sale?.id ?? index).toString();

export const SalesTable = ({
  event,
  sales,
  salesCnt: salesCount,
  getDataFail: failedToRetrieveData,
  onMount,
  onUnmount,
  initState,
  disablePagination,
  useVirtuoso,
  withOuterPadding = true,
  isSideTable = false,
  onItemSelectionsChanged,
}: SalesTableProps) => {
  const showMainColumnsOnly = false;
  const isItemsLoading = salesCount > 0 && (sales == null || sales.length == 0);
  const data = useMemo(() => {
    const result = sales?.map((sale) => ({ sale, event }));

    return result?.length
      ? result
      : result == null || isItemsLoading
      ? (generateShimmeringRows(salesCount) as null[])
      : [];
  }, [sales, isItemsLoading, salesCount, event]);

  const hasViewProceeds = useUserHasAnyOfPermissions(
    Permission.Sales_ViewProceeds,
    Permission.Sales_ViewRecentProceeds
  );
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );
  const hasFullPageEventViewSidePanelSelectionFilterFeature = useUserHasFeature(
    Feature.FullPageEventViewSidePanelSelectionFilter
  );

  const inFullPageEventView = isInFullPageEventView();

  const salesText = useContent(ContentId.Sales);
  const { appContext, loginContext } = useAppContext();
  const { setGroupItems, getGroupToggleState, getSelection, selectionMode } =
    useMultiSelectionContext();

  const saleSelection = getSelection(event.viagVirtualId);

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || []
  );

  useClearShiftSelectionOnSortingChange({ sortingState: sorting });

  const seatingColumnSorting = useSeatingColumnSorting<SaleWithEvent>(
    SalesTableColumnId.Seating,
    SectionType.Sales
  );

  const { pagination, setPagination } = usePaginationSettings(
    data.length,
    -1,
    PAGE_SIZE,
    disablePagination,
    initState
  );

  const groupId = event.viagVirtualId;
  const rowSelection = useMemo(() => {
    return data
      ?.flatMap((d) => [d?.sale])
      ?.reduce<Record<string, boolean>>((result, sale) => {
        if (sale?.id) {
          result[sale.id] =
            getGroupToggleState(groupId).isGroupSelected &&
            !saleSelection.items.itemIds.length
              ? true
              : (saleSelection?.items.itemIds ?? [])?.includes(
                  String(sale?.id)
                ) ?? false;
        }
        return result;
      }, {});
  }, [data, groupId, getGroupToggleState, saleSelection.items.itemIds]);

  const onRowSelectionChange = useCallback(
    (updater: Updater<RowSelectionState>) => {
      const newSelections =
        typeof updater === 'function' ? updater(rowSelection) : updater;

      const newSelectedIds = Object.keys(newSelections).filter(
        (r) => newSelections[r] === true
      );

      setGroupItems(event.viagVirtualId, newSelections);
      onItemSelectionsChanged?.(
        newSelectedIds
          .filter((id) => newSelections[id])
          .map((id) => parseInt(id))
      );
    },
    [event.viagVirtualId, onItemSelectionsChanged, rowSelection, setGroupItems]
  );

  const showCheckbox =
    selectionMode?.mode === MultiSelectScope.AllGroups ||
    saleSelection.isSingleGroupMultiSelect;

  const {
    lastSelectedRowRefIndex,
    allowShiftClickSelection,
    isShiftKeyPressed,
    isCtrlOrCmdKeyPressed,
    updateSelectionForRowAndSubRows,
  } = useSingleTableShortcutSelection<SaleWithEvent>({ isSideTable });

  const onRowShiftSelect = useCallback(
    (
      tableRows: Row<SaleWithEvent | null>[] | undefined,
      row: Row<SaleWithEvent | null>,
      index: number
    ) => {
      if (!tableRows || !allowShiftClickSelection || showCheckbox) {
        return;
      }
      const rowId = row.id.toString();
      const newIsSelected = !rowSelection[rowId];

      if (isShiftKeyPressed && lastSelectedRowRefIndex.current !== null) {
        const start = Math.min(lastSelectedRowRefIndex.current, index);
        const end = Math.max(lastSelectedRowRefIndex.current, index);

        const newRowSelection = { ...rowSelection };
        for (let i = start; i <= end; i++) {
          const row = tableRows[i];
          if (row) {
            updateSelectionForRowAndSubRows({
              row,
              rowId: row.id.toString(),
              newIsSelected,
              newRowSelection,
            });
          }
        }

        lastSelectedRowRefIndex.current = index;
        onRowSelectionChange(newRowSelection);
      } else if (isCtrlOrCmdKeyPressed) {
        const newRowSelection = { ...rowSelection };
        newRowSelection[rowId] = newIsSelected;
        updateSelectionForRowAndSubRows({
          row,
          rowId,
          newIsSelected, // Select the clicked row
          newRowSelection,
        });
        lastSelectedRowRefIndex.current = index;
        onRowSelectionChange(newRowSelection);
      } else {
        // No modifier key pressed, reset selection
        const newRowSelection = {};

        updateSelectionForRowAndSubRows({
          row,
          rowId,
          newIsSelected,
          newRowSelection,
        });
        lastSelectedRowRefIndex.current = index;
        onRowSelectionChange(newRowSelection);
      }
    },
    [
      allowShiftClickSelection,
      isCtrlOrCmdKeyPressed,
      isShiftKeyPressed,
      lastSelectedRowRefIndex,
      onRowSelectionChange,
      rowSelection,
      showCheckbox,
      updateSelectionForRowAndSubRows,
    ]
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  // Clear selections when selection mode changes
  useEffect(() => {
    onItemSelectionsChanged?.([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionMode?.mode]);

  const {
    value: storedInventoryColumnOrderSetting = defaultSalesColumnsConfig,
  } = useServerUserSetting<SalesTableColumnId[]>({
    id: isSideTable
      ? UserSetting.SalesSideTableColumnOrder
      : UserSetting.SaleColumnOrder,
  });
  const { value: storedSaleColumnsEnabledSetting } = useServerUserSetting<
    string[]
  >({
    id: isSideTable
      ? UserSetting.SalesSideTableColumnsEnabled
      : UserSetting.SaleColumnsEnabled,
  });

  const { value: customSaleColumns = [] } = useServerUserSetting<
    CustomSalesColumn[]
  >({
    id: UserSetting.SaleCustomColumns,
  });
  const { tagsMetadata, tagsMetadataNumeric } = useTagsForEntityType(
    ActionOutboxEntityType.Sale,
    true
  );

  const customSaleColumnsFiltered = filterCustomColumnsForSale(
    customSaleColumns,
    tagsMetadataNumeric
  );

  const displayedColumnsConfig = useMemo(() => {
    const showCheckbox =
      selectionMode?.mode === MultiSelectScope.AllGroups ||
      saleSelection.isSingleGroupMultiSelect;

    if (showMainColumnsOnly) {
      return SALE_MAIN_COLUMNS.filter((c) =>
        c === SalesTableColumnId.Checkbox ? showCheckbox : true
      ).reduce<ColumnDef<SaleWithEvent | null>[]>((acc, columnId) => {
        const columnConfig = Object.values(SALE_TABLE_COLUMNS_CONFIG).find(
          (column) => column.id === columnId
        );
        if (columnConfig) {
          acc.push(columnConfig);
        }
        return acc;
      }, []);
    }

    const saleColumnOrder = storedInventoryColumnOrderSetting.filter(
      // If storedSaleColumnsEnabledSetting has never been set, default to true
      (c) => storedSaleColumnsEnabledSetting?.includes(c) ?? true
    );

    const salesTableColumnsConfig = Object.values(SALE_TABLE_COLUMNS_CONFIG);

    const columns = new Set([
      // Add unconfigurable columns first
      ...salesTableColumnsConfig
        .filter((column) => {
          const columnDef = getSaleColumnConfigById(
            column.id as SalesTableColumnId
          ).personalization;
          const hasFeatureForColumn =
            columnDef.requiredFeatures.length === 0 ||
            hasFeatures(
              loginContext?.user,
              appContext?.features,
              columnDef.requiredFeatures
            );

          let isConfigurable = columnDef.isConfigurable;
          if (
            !hasTablePinActionColumnFeature &&
            column.id === SalesTableColumnId.Actions
          ) {
            isConfigurable = true;
          }

          return !isConfigurable && hasFeatureForColumn;
        })
        .map((item) => item.id as SalesTableColumnId),
      // Add user defined columns next
      ...filterColumnsByFeatures(
        saleColumnOrder,
        SectionType.Sales,
        customSaleColumnsFiltered,
        loginContext?.user,
        appContext?.features,
        tagsMetadata ?? []
      ),
    ]);

    if (showCheckbox) {
      columns.delete(SalesTableColumnId.Actions);
    } else {
      columns.delete(SalesTableColumnId.Checkbox);
    }

    return Array.from(columns).reduce<ColumnDef<SaleWithEvent | null>[]>(
      (acc, columnId) => {
        if (tagsMetadata?.find((t) => t.key === columnId)) {
          acc.push(tagColumnDef(columnId));
        } else if (customSaleColumnsFiltered.find((c) => c.id === columnId)) {
          acc.push(
            customColumnDef(
              columnId,
              customSaleColumnsFiltered.find((c) => c.id === columnId)?.formula
            )
          );
        } else {
          const columnConfig = salesTableColumnsConfig.find(
            (column) => column.id === columnId
          );
          if (columnConfig) acc.push(columnConfig);
        }

        return acc;
      },
      []
    );
  }, [
    appContext?.features,
    customSaleColumnsFiltered,
    hasTablePinActionColumnFeature,
    loginContext?.user,
    saleSelection.isSingleGroupMultiSelect,
    selectionMode?.mode,
    showMainColumnsOnly,
    storedInventoryColumnOrderSetting,
    storedSaleColumnsEnabledSetting,
    tagsMetadata,
  ]);

  const options: Partial<TableOptions<SaleWithEvent | null>> = useMemo(
    () => ({
      data,
      columns: displayedColumnsConfig.filter((c) =>
        c.id === 'proceeds' ? hasViewProceeds : true
      ),
      state: {
        pagination,
        rowSelection,
        sorting,
        columnVisibility: {
          checkbox: showCheckbox,
        },
        columnPinning: {
          right: hasTablePinActionColumnFeature
            ? [SalesTableColumnId.Actions]
            : undefined,
        },
      },
      meta: {
        sectionType: isSideTable ? SectionType.SalesSideTable : undefined,
      },
      getRowId: getRowId,
      onPaginationChange: setPagination,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
      },
      enableRowSelection:
        showCheckbox ||
        (inFullPageEventView &&
          hasFullPageEventViewSidePanelSelectionFilterFeature),
      enableMultiRowSelection: showCheckbox,
      enableSubRowSelection: showCheckbox,
      onRowSelectionChange:
        !allowShiftClickSelection || showCheckbox
          ? onRowSelectionChange
          : undefined,
      sortingFns: { ...seatingColumnSorting },
    }),
    [
      data,
      displayedColumnsConfig,
      pagination,
      rowSelection,
      sorting,
      showCheckbox,
      hasTablePinActionColumnFeature,
      isSideTable,
      setPagination,
      inFullPageEventView,
      hasFullPageEventViewSidePanelSelectionFilterFeature,
      allowShiftClickSelection,
      onRowSelectionChange,
      hasViewProceeds,
      seatingColumnSorting,
    ]
  );

  return failedToRetrieveData ? (
    <SomethingWentWrong
      message={<Content id={ContentId.FailToLoadListContent} />}
    />
  ) : data?.length ? (
    <ColumnResizingContextProvider<SalesTableColumnId>
      userSettingId={UserSetting.SaleColumnWidths}
    >
      <Table
        options={options}
        tableLayout="fixed"
        withOuterPadding={withOuterPadding}
        tableHeadStyle={
          disablePagination ? disabledPaginationStyles : undefined
        }
        className={clsx({ [shiftSelect]: isShiftKeyPressed })}
        tableCellStyle={tableCellStyles}
        useVirtuoso={useVirtuoso}
        onRowShiftSelect={onRowShiftSelect}
      />
    </ColumnResizingContextProvider>
  ) : (
    // We should never see this - because the events are filtered to only those that has sales
    // So this is to prevent a crash - but this should be looked at
    <NoData>
      <FormatContent id={FormatContentId.NoDataAvailable} params={salesText} />
    </NoData>
  );
};
