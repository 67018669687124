import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from 'react-use';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useSyncCenterSettings } from 'src/hooks/api/useSyncCenterSetting';
import {
  AutoPricingCompListingMode,
  AutoPricingOutlierMode,
  AutoPricingUndercutMode,
  CompListingQuantity,
  ListingClient,
  ListingDetailsPricingUpdates,
  ListingDetailsUpdateInput,
  SelectedSectionSettings,
} from 'src/WebApiController';

type AutoPricingPreviewQueryKeyType =
  | {
      listingId: number | undefined;
      eventId: number | null;
      priceUpdates: ListingDetailsPricingUpdates;
    }
  | undefined;

export const useAutoPricingPreview = (
  viagogoEventId: number | null,
  listingId?: number | undefined
) => {
  const { watch } = useFormContext<ListingDetailsUpdateInput>();

  const { data } = useSyncCenterSettings();

  const updatedFields = watch([
    'netProceedsFloor',
    'netProceedsCeiling',
    'autoPricingEnabled',
    'currencyCode',

    'compListingFloor',
    'compListingCeiling',
    'compListingMode',
    'compListingOnlyForSameZoneEnabled',
    'compListingOnlyForSelectedSectionsEnabled',
    'compListingExcludeFanInventory',
    'compListingExcludeDefects',
    'compListingQuantityScoreAdjustmentEnabled',
    'compListingQuantityScoreAdjustmentOverrideJson',
    'compListingSelectedSectionSettings',
    'compListingQuantityFilters',

    'undercutMode',
    'undercutAbsoluteAmount',
    'undercutRelativeAmount',

    'circuitBreakerMaxDiscountVelocityPercent',
    'circuitBreakerMaxDiscountVelocityTicksInHours',
    'circuitBreakerMinCompListingCount',
    'circuitBreakerRelativeCeiling',
    'circuitBreakerRelativeFloor',

    'outlierMode',
    'outlierStandardDeviations',
    'outlierKthLowestLimit',
    'outlierKthLowestLimitRelativeSpacing',
    'outlierKthLowestLimitAbsoluteSpacing',
  ]);

  const getListingDetailsPricingUpdates = useCallback(
    (
      listing: [
        number | null,
        number | null,
        boolean | null,
        string | null,

        // Comp Listing
        number | null,
        number | null,
        AutoPricingCompListingMode | null,
        boolean | null,
        boolean | null,
        boolean | null,
        boolean | null,
        boolean | null,
        string | null,
        SelectedSectionSettings | null,
        CompListingQuantity | null,

        // Undercut
        AutoPricingUndercutMode | null,
        number | null,
        number | null,

        // Circuit Breaker
        number | null,
        number | null,
        number | null,
        number | null,
        number | null,

        // Outlier
        AutoPricingOutlierMode | null,
        number | null,
        number | null,
        number | null,
        number | null,
      ]
    ) => {
      const [
        netProceedsFloor,
        netProceedsCeiling,
        autoPricingEnabled,
        currencyCode,

        compListingFloor,
        compListingCeiling,
        compListingMode,
        compListingOnlyForSameZoneEnabled,
        compListingOnlyForSelectedSectionsEnabled,
        compListingExcludeFanInventory,
        compListingExcludeDefects,
        compListingQuantityScoreAdjustmentEnabled,
        compListingQuantityScoreAdjustmentOverrideJson,
        compListingSelectedSectionSettings,
        compListingQuantityFilters,

        undercutMode,
        undercutAbsoluteAmount,
        undercutRelativeAmount,

        circuitBreakerMaxDiscountVelocityPercent,
        circuitBreakerMaxDiscountVelocityTicksInHours,
        circuitBreakerMinCompListingCount,
        circuitBreakerRelativeCeiling,
        circuitBreakerRelativeFloor,

        outlierMode,
        outlierStandardDeviations,
        outlierKthLowestLimit,
        outlierKthLowestLimitRelativeSpacing,
        outlierKthLowestLimitAbsoluteSpacing,
      ] = listing;

      return {
        netProceedsFloor,
        netProceedsCeiling,
        autoPricingEnabled,
        currencyCode,

        compListingFloor,
        compListingCeiling,
        compListingMode,
        compListingOnlyForSameZoneEnabled,
        compListingOnlyForSelectedSectionsEnabled,
        compListingExcludeFanInventory,
        compListingExcludeDefects,
        compListingQuantityScoreAdjustmentEnabled,
        compListingQuantityScoreAdjustmentOverrideJson,
        compListingSelectedSectionSettings,
        compListingQuantityFilters,

        undercutMode,
        undercutAbsoluteAmount,
        undercutRelativeAmount,

        circuitBreakerMaxDiscountVelocityPercent,
        circuitBreakerMaxDiscountVelocityTicksInHours,
        circuitBreakerMinCompListingCount,
        circuitBreakerRelativeCeiling,
        circuitBreakerRelativeFloor,

        outlierMode,
        outlierStandardDeviations,
        outlierKthLowestLimit,
        outlierKthLowestLimitRelativeSpacing,
        outlierKthLowestLimitAbsoluteSpacing,
      } as ListingDetailsPricingUpdates;
    },
    []
  );

  const priceUpdates = useMemo(() => {
    return getListingDetailsPricingUpdates(updatedFields);
  }, [getListingDetailsPricingUpdates, updatedFields]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [queryKey, setQueryKey] =
    useState<AutoPricingPreviewQueryKeyType>(undefined);

  useDebounce(
    () =>
      setQueryKey({
        eventId: viagogoEventId,
        listingId,
        priceUpdates: priceUpdates,
      }),
    300,
    [viagogoEventId, listingId, JSON.stringify(priceUpdates)]
  );

  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const shouldQuery = Boolean(
    activeAccountWebClientConfig.activeAccountId != null &&
      queryKey?.eventId &&
      queryKey?.listingId &&
      queryKey?.priceUpdates
  );

  const priceCalcQuery = useQuery({
    queryKey: ['ListingClient.getListingPriceCalculation', queryKey],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const r = await new ListingClient(
        activeAccountWebClientConfig
      ).getListingPriceCalculation(
        queryKey?.eventId ?? undefined,
        queryKey?.listingId ?? undefined,
        data?.priceByNetProceeds ?? undefined,
        queryKey?.priceUpdates!
      );
      return r;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'ListingClient.getListingPriceCalculation',
          error,
          priceUpdates
        );
      },
    },
  });

  return { priceCalcQuery, priceUpdates };
};
