import { MarketplacePaymentsDetails } from 'src/modals/MaketplacePayments';
import { ModalProps } from 'src/modals/Modal';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { MarketplacePaymentIdDeeplinkQueryParam } from 'src/utils/constants/constants';
import { getPathFromMainRoute } from 'src/utils/deepLinkUtils';

export const getMarketplacePaymentRelativeUrl = (
  marketplacePaymentId: number
) => {
  return `${getPathFromMainRoute(
    MainRoute.Payments
  )}?${MarketplacePaymentIdDeeplinkQueryParam}=${marketplacePaymentId}`;
};

export const getMarketplacePaymentDetailsModalConfigWithDeepLink = (
  marketplacePaymentId: number
): ModalProps => {
  return {
    ...MarketplacePaymentsDetails,
    deepLinkValue: marketplacePaymentId,
  };
};
