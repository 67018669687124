import { useFormContext } from 'react-hook-form';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { Stack, Switch } from 'src/core/ui';
import { useMarketplaceHandledByProcessor } from 'src/hooks/useMarketplaceHandledByProcessor';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  Feature,
  ListingDetails,
  ListingDetailsBroadcastSectionUpdates,
  Marketplace,
} from 'src/WebApiController';

import * as styles from './EnableNativeSyncSection.css';

export const EnableNativeSyncSection = ({
  disabled,
  listing,
}: {
  disabled?: boolean;
  listing: ListingDetails;
}) => {
  const { watch, setValue } =
    useFormContext<ListingDetailsBroadcastSectionUpdates>();
  const hasEnableNativeSyncFeature = useUserHasFeature(
    Feature.EnableNativeSyncToggle
  );

  const stubHubHandledByProcessor = useMarketplaceHandledByProcessor(
    Marketplace.StubHub
  );

  const enableNativeSync = watch('enableNativeSync');

  const shouldShow =
    hasEnableNativeSyncFeature &&
    // Only show if this listing has StubHub or Ticketmaster enabled and sync-by-processor
    listing.mkpListings?.find(
      (mkp) =>
        mkp.isEnabled &&
        mkp.mkp === Marketplace.StubHub &&
        stubHubHandledByProcessor
    ) != null;

  if (!shouldShow) {
    return null;
  }

  return (
    <Stack gap="l" alignItems="start">
      <Switch
        checked={!!enableNativeSync}
        disabled={disabled}
        onChange={(e) => e.stopPropagation()}
        onCheckedChange={(isChecked) => {
          setValue('enableNativeSync', isChecked, {
            shouldDirty: true,
          });
        }}
      />

      <Stack direction="column">
        <span className={styles.label}>
          <Content id={ContentId.EnableNativeSyncLabel} />
        </span>
        <span className={styles.info}>
          <Content id={ContentId.EnableNativeSyncExplanation} />{' '}
          <FormatContent
            id={FormatContentId.OnlyForMarketplaces}
            params={[Marketplace.StubHub]}
          />
          .
        </span>
      </Stack>
    </Stack>
  );
};
