import {
  Cell,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  HeaderGroup,
  Row,
  RowData,
  SortingFn,
  Table as ReactTable,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import React, {
  ComponentType,
  CSSProperties,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useScroll } from 'react-use';
import { State } from 'react-use/lib/useScroll';
import { SizeFunction, TableVirtuoso } from 'react-virtuoso';
import { useActiveFocusContext } from 'src/contexts/ActiveFocusContext/ActiveFocusContext';
import { useColumnResizingContext } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FormatOptionEntries } from 'src/modals/EditTableColumns';
import { TableTd } from 'src/tables/Table/components/TableTd';
import { PosGroupedVirtuosoTable } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoTable';
import { SectionType } from 'src/utils/types/sectionType';
import { Feature } from 'src/WebApiController';

import { ColumnHeader, PaginationFooter } from './components';
import { PosVirtuosoTable } from './PosVirtuosoTableComponent/PosVirtuosoTable';
import { PosVirtuosoTableContext } from './PosVirtuosoTableComponent/PosVirtuosoTableContext';
import { PosVirtuosoTableFooter } from './PosVirtuosoTableComponent/PosVirtuosoTableFooter';
import { PosVirtuosoTableHead } from './PosVirtuosoTableComponent/PosVirtuosoTableHead';
import { PosVirtuosoTableRow } from './PosVirtuosoTableComponent/PosVirtuosoTableRow';
import * as styles from './Table.css';
import { useTableHotkeys } from './Table.hooks';
import { RowComponentWithRowData, RowWrapper } from './Table.types';
import { itemSizeDefault } from './Table.utils';
import { useColumnVirtualizer } from './useColumnVirtualizer';

const virtuosoTableOverscan = { main: 500, reverse: 500 };
const virtuosoTableStyle = { width: '100%', height: '100%' };
const MIN_COLUMN_COUNT_TO_USE_VIRTUOSO = 10;

export interface VirtuosoScrollEvent {
  scrollState: State;
  element: HTMLElement;
}

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    paddingLeft?: string;
    paddingLeftLargeDesktop?: string;
    styleOverrides?: React.CSSProperties;
  }

  interface TableMeta<TData extends RowData> {
    summaryData?: TData | null | undefined;
    sectionType?: SectionType;
    rowSupportsAccordion?: boolean;
    formatOptionOverrides?: FormatOptionEntries;
  }

  // Used to use sortingFns (custom sorting) in columnDef
  // (because it doesn't other strings but the table definition does)
  interface SortingFns {
    [key: string]: SortingFn<any>;
  }
}

type CustomTrProps = Omit<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >,
  'ref'
>;

export type TableLayout = 'fixed' | 'auto';

export type PosTableData = RowData;

export type TableProps<T extends PosTableData> = {
  tableLayout?: TableLayout;
  options: Partial<TableOptions<T>>;
  /**
   * Custom row wrapper to wrap row content *inside* tr element
   */
  rowWrapper?: RowWrapper<T>;
  /**
   * Custom row component to render each row to *replace tr element* totally
   */
  RowComponent?: ComponentType<RowComponentWithRowData<T>>;
  style?: CSSProperties;
  tableStyle?: CSSProperties;
  tableHeadStyle?: CSSProperties;
  tableHeadCellStyle?: {
    paddingTop?: string | null;
    paddingBottom?: string | null;
    fontSize?: string | null;
    fontWeight?: number | null;
  };
  tableCellStyle?: CSSProperties;
  tableRowStyle?: CSSProperties;
  roundBorders?: boolean;
  showPageSizeOptions?: boolean;
  withOuterPadding?: boolean;
  className?: string;
  useVirtuoso?: boolean;
  onVirtuosoTableScroll?: (virtuosoScrollEvent: VirtuosoScrollEvent) => void;
  useStickyFooter?: boolean;
  virtuosoItemSize?: SizeFunction;
  resizeDisabled?: boolean;
  usePaginationFooter?: boolean;

  // Grouping
  useDataGrouping?: boolean;
  // Table hotkeys
  useTableNavKeys?: boolean;
  onRowSelect?: (row: Row<T>) => void;
  onRowShiftSelect?: (
    tableRows: Row<T>[] | undefined,
    row: Row<T>,
    index: number
  ) => void;
  onVirtuosoEndReached?: (lastItemIndex?: number) => void;
  footerEl?: ReactNode;
};

export const Table = <T extends PosTableData>({
  tableLayout,
  options,
  rowWrapper,
  RowComponent,
  tableStyle,
  tableHeadStyle,
  tableHeadCellStyle,
  tableCellStyle,
  tableRowStyle,
  showPageSizeOptions,
  withOuterPadding,
  className,
  useVirtuoso,
  onVirtuosoTableScroll,
  useStickyFooter,
  virtuosoItemSize,
  resizeDisabled,
  usePaginationFooter = true,
  useDataGrouping,
  useTableNavKeys = false,
  onRowSelect,
  onRowShiftSelect,
  onVirtuosoEndReached,
  footerEl,
  roundBorders,
}: TableProps<T>) => {
  let rowIndex = 0;
  const isLargeDesktop = useMatchMedia('largeDesktop');
  const tableWrapperRef = useRef<HTMLTableElement | null>(null);
  const { isResizeEnabled, customColumnWidths, onColumnSizingChange } =
    useColumnResizingContext();
  const { activeItem, onKeyDownHandler } = useActiveFocusContext();

  const table: ReactTable<T> = useReactTable({
    autoResetPageIndex: false,
    enableColumnResizing: isResizeEnabled && !resizeDisabled,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: useDataGrouping ? getGroupedRowModel() : undefined,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: usePaginationFooter ? getPaginationRowModel() : null,
    onColumnSizingChange: onColumnSizingChange,
    // This is default turned on, but we want to turn it off in most cases
    enableRowSelection: false,
    // This is default turned on, but we want to turn it off in most cases
    enableMultiRowSelection: false,

    // Leave this in false to not reorder columns when grouping
    groupedColumnMode: false,
    ...options,
    state: {
      ...options.state,
      columnSizing: customColumnWidths ?? {},
    },
  } as TableOptions<T>);

  const isGrouping = table.getState().grouping?.length > 0;
  const tableRows = table.getRowModel().rows;
  const footerRow = table.getFooterGroups();
  const hasFooterDef = footerRow.some((row: HeaderGroup<T>) =>
    row.headers.some(
      (header: Header<T, unknown>) => header.column.columnDef.footer != null
    )
  );

  useTableHotkeys(useTableNavKeys, table, onRowSelect);

  useEffect(() => {
    if (!activeItem) return;
    tableRows[activeItem.index]?.toggleSelected(
      Array.from(activeItem.id)[0] !== '-'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows, activeItem]);

  const scrollRef = useRef<HTMLElement | null>(null);
  const scrollState = useScroll(scrollRef);

  const renderRowCell = useCallback(
    (cell: Cell<T, unknown>) => {
      return (
        <TableTd
          key={cell.id}
          isLargeDesktop={isLargeDesktop}
          isGrouping={isGrouping}
          isResizeEnabled={isResizeEnabled}
          cell={cell}
          tableCellStyle={tableCellStyle}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableTd>
      );
    },
    [isGrouping, isLargeDesktop, isResizeEnabled, tableCellStyle]
  );

  const renderRowContent = useCallback(
    (row: Row<T>) => {
      return (
        <>
          {row.getVisibleCells().map((cell) => {
            return renderRowCell(cell);
          })}
        </>
      );
    },
    [renderRowCell]
  );

  const renderHeader = useCallback(
    (header: Header<T, unknown>) => (
      <ColumnHeader
        key={header.id}
        header={header}
        table={table}
        style={tableHeadCellStyle}
      />
    ),
    [table, tableHeadCellStyle]
  );

  const { renderHeaderRow, renderRowContent: renderRowContentVirtualized } =
    useColumnVirtualizer(table, scrollRef, renderHeader, renderRowCell);

  useEffect(() => {
    if ((useVirtuoso || useDataGrouping) && scrollRef.current) {
      onVirtuosoTableScroll?.({
        scrollState,
        element: scrollRef.current,
      });
    }
  }, [onVirtuosoTableScroll, scrollState, useDataGrouping, useVirtuoso]);

  const hasTableColumnVirtualizedFeature = useUserHasFeature(
    Feature.TableColumnVirtualized
  );

  const useColumnVirtualization =
    hasTableColumnVirtualizedFeature &&
    table.getVisibleLeafColumns().length >= MIN_COLUMN_COUNT_TO_USE_VIRTUOSO;

  // We cannot used fixed layout if we're using column virtualization
  if (useColumnVirtualization) {
    tableLayout = 'auto';
  }

  return (
    <div
      ref={tableWrapperRef}
      className={clsx(
        styles.POSTableContainer,
        useVirtuoso && styles.POSTableVirtuosoContainer,
        withOuterPadding && styles.ContainerPadding,
        className
      )}
    >
      {useDataGrouping ? (
        <PosGroupedVirtuosoTable
          renderRowContent={
            useColumnVirtualization
              ? renderRowContentVirtualized
              : renderRowContent
          }
          scrollRef={scrollRef}
          context={{
            tableRows,
            RowComponent,
            table,
            scrollY,
          }}
        />
      ) : useVirtuoso ? (
        <TableVirtuoso<Row<T>, PosVirtuosoTableContext<T>>
          style={virtuosoTableStyle}
          className={clsx({
            [styles.roundBorders]: roundBorders,
          })}
          data={tableRows}
          overscan={virtuosoTableOverscan}
          context={{
            tableLayout,
            tableStyle,
            tableHeadStyle,
            tableRowStyle,
            tableRows,
            rowWrapper,
            RowComponent,
            useStickyFooter,
            onRowShiftSelect,
          }}
          scrollerRef={(el) => {
            if (el instanceof HTMLElement) scrollRef.current = el;
          }}
          endReached={onVirtuosoEndReached}
          itemSize={virtuosoItemSize ?? itemSizeDefault}
          // Always use components declared outside a rendering component
          // or new elements are created causing to lose state
          // https://virtuoso.dev/customize-structure/
          components={{
            Table: PosVirtuosoTable,
            TableHead: PosVirtuosoTableHead,
            TableRow: PosVirtuosoTableRow,
            TableFoot: PosVirtuosoTableFooter,
          }}
          fixedHeaderContent={() => {
            return useColumnVirtualization ? (
              renderHeaderRow()
            ) : (
              <>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <ColumnHeader
                        key={header.id}
                        header={header}
                        table={table}
                        style={tableHeadCellStyle}
                      />
                    ))}
                  </tr>
                ))}
              </>
            );
          }}
          fixedFooterContent={() => (
            <>
              {footerEl}
              {hasFooterDef &&
                footerRow.map((row, index) => {
                  const tableRow = (customProps?: CustomTrProps) => (
                    <tr
                      className={styles.tableFooterRow}
                      key={`footer-${row.id}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onKeyDown={
                        activeItem != null
                          ? (e) => onKeyDownHandler(e, index, tableRows)
                          : undefined
                      }
                      {...customProps}
                      style={tableRowStyle}
                    >
                      {row.headers.map((cell) => (
                        <td
                          key={cell.id}
                          style={{
                            paddingLeft: isLargeDesktop
                              ? cell.column.columnDef.meta
                                  ?.paddingLeftLargeDesktop
                              : cell.column.columnDef.meta?.paddingLeft,
                            ...tableCellStyle,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.footer,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );

                  return tableRow();
                })}
            </>
          )}
          itemContent={(index, row) =>
            useColumnVirtualization
              ? renderRowContentVirtualized(row)
              : renderRowContent(row)
          }
        />
      ) : (
        <div
          style={{ overflow: 'auto' }}
          ref={scrollRef as MutableRefObject<HTMLDivElement | null>}
        >
          <table
            className={clsx(styles.table, {
              [styles.roundBorders]: roundBorders,
            })}
            style={{ tableLayout, ...tableStyle }}
          >
            <thead className={styles.tableHead} style={tableHeadStyle}>
              {useColumnVirtualization
                ? renderHeaderRow()
                : table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <ColumnHeader
                          key={header.id}
                          header={header}
                          table={table}
                          style={tableHeadCellStyle}
                        />
                      ))}
                    </tr>
                  ))}
            </thead>
            <tbody>
              {tableRows.map((row, index) => {
                const rowProps = {
                  className: clsx(styles.tableRow, {
                    [styles.isSelectedRow]: row.getIsSelected(),
                    [styles.isSubRow]: row.depth > 0,
                  }),
                  key: row.id,
                  onClick: (e: React.MouseEvent) => {
                    e.stopPropagation();
                    row.toggleSelected();
                    onRowShiftSelect?.(tableRows, row, index);
                  },
                  onKeyDown: activeItem
                    ? (e: React.KeyboardEvent) =>
                        onKeyDownHandler(e, index, tableRows)
                    : undefined,
                  tabIndex:
                    activeItem && activeItem.id === row.id
                      ? 0
                      : row.getIsSelected()
                      ? 0
                      : -1,
                  style: tableRowStyle,
                };
                const rowContent = useColumnVirtualization
                  ? renderRowContentVirtualized(row)
                  : renderRowContent(row);

                const children = rowWrapper
                  ? rowWrapper(row.original, rowContent, rowIndex++)
                  : rowContent;

                const tableRow = RowComponent ? (
                  <RowComponent {...rowProps} row={row}>
                    {children}
                  </RowComponent>
                ) : (
                  <tr {...rowProps}>{children}</tr>
                );

                return tableRow;
              })}
              {hasFooterDef &&
                footerRow.map((row, index) => {
                  const tableRow = (customProps?: CustomTrProps) => (
                    <tr
                      className={styles.tableFooterRow}
                      key={`footer-${row.id}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onKeyDown={
                        activeItem != null
                          ? (e) => onKeyDownHandler(e, index, tableRows)
                          : undefined
                      }
                      {...customProps}
                      style={tableRowStyle}
                    >
                      {row.headers.map((cell) => (
                        <td
                          key={cell.id}
                          style={{
                            paddingLeft: isLargeDesktop
                              ? cell.column.columnDef.meta
                                  ?.paddingLeftLargeDesktop
                              : cell.column.columnDef.meta?.paddingLeft,
                            ...tableCellStyle,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.footer,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );

                  return tableRow();
                })}
              {footerEl}
            </tbody>
          </table>
        </div>
      )}
      {usePaginationFooter && table.getPageCount() > 1 && (
        <PaginationFooter
          table={table}
          tableWrapperRef={tableWrapperRef}
          showPageSizeOptions={showPageSizeOptions}
        />
      )}
    </div>
  );
};

export default Table;
