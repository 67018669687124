import { useState } from 'react';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { useBulkEditListingGroupSettings } from 'src/components/Listings/InventoryActionDropdown/components/BulkEditListingGroupSettingsAction';
import { LaunchGroupListings } from 'src/components/Listings/InventoryActionDropdown/components/GroupListingsAction';
import { LaunchAutoCompAndAutoGroupSettings } from 'src/components/Listings/InventoryActionDropdown/components/GroupListingsAction';
import { LaunchAddToGroup } from 'src/components/Listings/InventoryActionDropdown/components/GroupListingsAction/LaunchAddToGroup';
import { LaunchAutoGroupListings } from 'src/components/Listings/InventoryActionDropdown/components/GroupListingsAction/LaunchAutoGroupListings';
import { LaunchGroupingWizard } from 'src/components/Listings/InventoryActionDropdown/components/GroupListingsAction/LaunchGroupingWizard';
import { LaunchMoveToGroup } from 'src/components/Listings/InventoryActionDropdown/components/GroupListingsAction/LaunchMoveToGroup';
import { useBulkUngroupListings } from 'src/components/Listings/InventoryActionDropdown/components/LaunchUngroupListingsAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';
import { AutoCompAndAutoGroupMode, EventWithData } from 'src/WebApiController';

interface GroupActionsProps {
  data: EventWithData;
}

export const GroupActions = ({ data }: GroupActionsProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();

  const [isLoading, setIsLoading] = useState(false);

  const { filterQueryWithEventIds } = useInventoryEventBulkActionsState(
    data.event
  );

  const bulkUngroupListings = useBulkUngroupListings(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    data.event
  );

  const bulkEditListingGroupSettings = useBulkEditListingGroupSettings(
    filterQueryWithEventIds,
    data,
    setIsLoading,
    isLoading
  );

  const hasAutoGroupAndCompFeatureV2 = useUserHasFeature(
    Feature.AutoGroupAndCompV2
  );
  const hasMoveToGroupFeature = useUserHasFeature(Feature.MoveToGroup);

  const hasZoneEditMap = useUserHasFeature(Feature.ZoneMapEdit);

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const hasBulkEditsToListingGroupsFeature = useUserHasFeature(
    Feature.BulkEditsToListingGroups
  );

  const hasGroupingWizardFeature = useUserHasFeature(Feature.GroupingWizard);

  return (
    <>
      <PosDropdown
        key="event-pricing-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Group} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {hasBulkEditsToListingGroupsFeature &&
          bulkEditListingGroupSettings.dropDown}

        {hasGroupingWizardFeature ? (
          <LaunchGroupingWizard event={data.event} />
        ) : (
          <LaunchGroupListings events={[data.event]} />
        )}

        {hasAutoPricingFeature &&
        hasAutoGroupAndCompFeatureV2 &&
        !hasZoneEditMap ? (
          <>
            <LaunchAutoGroupListings
              event={data.event}
              disabled={isLoading || !areBulkActionsEnabled}
            />
            <LaunchAutoCompAndAutoGroupSettings
              event={data.event}
              mode={AutoCompAndAutoGroupMode.AutoCompAndAutoGroup}
            />
          </>
        ) : null}
        {hasMoveToGroupFeature ? (
          <LaunchMoveToGroup eventData={data} />
        ) : (
          <LaunchAddToGroup
            eventData={data}
            disabled={isLoading || !areBulkActionsEnabled}
          />
        )}
        {bulkUngroupListings.dropDown}
      </PosDropdown>
    </>
  );
};
