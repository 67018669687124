import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { SectionRowTableDialog } from 'src/dialogs/SectionRowTableDialog/SectionRowTableDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AutoPricingInputs,
  Event,
  PricingClient,
  VenueMapZoneConfigInfo,
  VenueZoneConfigOverride,
  VenueZoneConfigType,
} from 'src/WebApiController';

import { useVenueSectionRow } from '../hooks/useVenueSectionRow';
import { VenueZoneMapFormType } from '../InventoryEventVenueZoneMapSection';
import * as styles from './VenueZoneMapSubmitButton.css';

export const VenueZoneMapSubmitButton = ({
  event,
  pricingSettingsQuery,
  venueZoneMapConfigInfoQuery,
  venueZoneMapConfigOverrideInfoQuery,
  setVenueZoneConfigTypeOverrideMutation,
  selectedOverrideId,
  setSelectedOverrideId,
  setSelectedConfigType,
}: {
  event: Event;
  pricingSettingsQuery: UseQueryResult<AutoPricingInputs | null, Error>;
  venueZoneMapConfigInfoQuery: UseQueryResult<
    VenueMapZoneConfigInfo | null,
    Error
  >;
  venueZoneMapConfigOverrideInfoQuery: UseQueryResult<
    {
      [key: string]: VenueZoneConfigOverride;
    } | null,
    Error
  >;
  setVenueZoneConfigTypeOverrideMutation: UseMutationResult<
    void,
    ErrorTypes,
    {
      eventId?: number | null;
      venueZoneOverrideId?: number | null;
      venueZoneConfigType?: VenueZoneConfigType | null;
    },
    unknown
  >;
  selectedOverrideId: number | null;
  setSelectedOverrideId: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedConfigType: React.Dispatch<
    React.SetStateAction<VenueZoneConfigType | null>
  >;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { venueZoneConfigs } = useEventMapContext();

  const sectionRowDialog = useBasicDialog();

  const { setValue, getValues, formState } =
    useFormContext<VenueZoneMapFormType>();

  const [localName, setLocalName] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (venueZoneConfigs?.name) {
      setLocalName(venueZoneConfigs?.name);
    }
  }, [venueZoneConfigs, setLocalName]);

  const handleNameChange = useCallback(
    (newName: string) => {
      setLocalName(newName);
      setIsDirty(newName !== venueZoneConfigs?.name);
    },
    [venueZoneConfigs?.name]
  );

  const {
    mapConfigOverridesQuery,
    hasScaledSeatScoreFeature,
    scoreOverrides,
    onSectionRowChange,
    venueMapInfo,
  } = useVenueSectionRow({
    event,
    pricingSettingsQuery,
  });

  const hasChanges = useCallback(
    (eventForm: VenueZoneMapFormType) => {
      if (!formState.defaultValues?.sectionSectionGroupLookup) {
        return false;
      }

      return (
        !isEqual(
          formState.defaultValues?.sectionSectionGroupLookup,
          eventForm.sectionSectionGroupLookup
        ) || isDirty
      );
    },
    [formState.defaultValues?.sectionSectionGroupLookup, isDirty]
  );

  const hasVenueRowChanges = useCallback(
    (eventForm: VenueZoneMapFormType) => {
      const defaultValues = formState.defaultValues?.eventScoreOverride;
      if (!defaultValues) {
        return false;
      }

      return !isEqual(defaultValues, eventForm.eventScoreOverride);
    },
    [formState.defaultValues]
  );

  const onSubmit = useCallback(
    async (eventForm: VenueZoneMapFormType) => {
      tryInvokeApi(
        async () => {
          const client = new PricingClient(activeAccountWebClientConfig);
          setIsLoading(true);

          if (event?.viagId && hasChanges(eventForm)) {
            const newId = await client.upsertVenueZoneSectionGroupOverrides({
              viagogoEventId: event.viagId,
              venueZoneOverrideId: selectedOverrideId,
              name: localName ?? 'Default',
              sectionSectionGroupsJson: JSON.stringify({
                sectionSectionGroupLookup: eventForm.sectionSectionGroupLookup,
              }),
              saveAsNewMap: false,
            });

            await venueZoneMapConfigInfoQuery.refetch();
            await venueZoneMapConfigOverrideInfoQuery.refetch();
            await pricingSettingsQuery.refetch();

            setVenueZoneConfigTypeOverrideMutation.mutate({
              eventId: event!.viagId,
              venueZoneOverrideId: newId,
              venueZoneConfigType: null,
            });

            setSelectedOverrideId(newId);
            setSelectedConfigType(null);
          }

          if (
            event?.viagId &&
            eventForm.eventScoreOverride &&
            hasVenueRowChanges(eventForm)
          ) {
            const newId = await client.upsertEventSeatMapScoreOverride({
              ...eventForm.eventScoreOverride,
              viagogoEventId: event.viagId,
              scoreOverrides: getCompleteEventConfigScoreOverrides(
                venueMapInfo?.sectionScores,
                eventForm.eventScoreOverride.scoreOverrides,
                false,
                hasScaledSeatScoreFeature
              )!,
            });

            setValue('eventScoreOverride.id', newId);

            await mapConfigOverridesQuery.refetch();

            await client.setEventSeatMapScoreOverride(
              event.viagId,
              newId,
              null
            );
          }
        },
        (error) => {
          showErrorDialog(
            'PricingClient.UpsertVenueZoneSectionGroupOverrides',
            error,
            {
              trackErrorData: eventForm,
            }
          );
        },
        async () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      event,
      hasChanges,
      hasScaledSeatScoreFeature,
      hasVenueRowChanges,
      localName,
      mapConfigOverridesQuery,
      pricingSettingsQuery,
      selectedOverrideId,
      setSelectedConfigType,
      setSelectedOverrideId,
      setValue,
      setVenueZoneConfigTypeOverrideMutation,
      showErrorDialog,
      venueMapInfo?.sectionScores,
      venueZoneMapConfigInfoQuery,
      venueZoneMapConfigOverrideInfoQuery,
    ]
  );

  const onSubmitHandler = useCallback(() => {
    const override = { ...getValues(), overrideName: localName };
    if (!hasChanges(override)) {
      return;
    }

    onSubmit(override);
  }, [getValues, hasChanges, localName, onSubmit]);

  return (
    <>
      <Stack
        direction="row"
        gap="m"
        alignItems="center"
        justifyContent="end"
        className={styles.flexContainer}
      >
        <Button
          variant="link"
          size="lg"
          onClick={sectionRowDialog.launchDialog}
          className={styles.nowrapButtonText}
        >
          <Content id={ContentId.CheckVenueRows} />
        </Button>
        <div className={styles.textInput}>
          <PosTextField
            type={'text'}
            maxLength={200}
            onChange={(e) => {
              handleNameChange(e.target.value);
            }}
            value={localName ?? ''}
          />
        </div>
        {hasChanges(getValues()) && (
          <Button
            variant="outline"
            size="lg"
            disabled={isLoading || !hasChanges(getValues())}
            onClick={onSubmitHandler}
          >
            <Content id={ContentId.Save} />
          </Button>
        )}
      </Stack>
      <SectionRowTableDialog
        {...sectionRowDialog.dialogProps}
        sections={venueMapInfo?.sections ?? []}
        scoreOverrides={scoreOverrides}
        onClose={sectionRowDialog.closeDialog}
        onSectionRowChange={onSectionRowChange}
      />
    </>
  );
};
